import { Popover } from '@material-ui/core';
import React from 'react';

import { Instruments } from './Instruments';
import { UseInstrumentRefreshR } from './useInstrumentRefresh';

export const InstrumentRefreshPanel = ({
    instrumentRefreshArgs,
}: {
    instrumentRefreshArgs: UseInstrumentRefreshR;
}): JSX.Element | null => {
    if (instrumentRefreshArgs.state === 'ready' && instrumentRefreshArgs.isOpen) {
        const { isOpen, handleCloseClick, onRefreshLayers, activeLayers } = instrumentRefreshArgs;
        return (
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={isOpen}
                // anchorEl={anchor}
                onClose={handleCloseClick}
            >
                <Instruments onClose={handleCloseClick} onRefresh={onRefreshLayers} layers={activeLayers} />
            </Popover>
        );
    }
    return null;
};

export default InstrumentRefreshPanel;
