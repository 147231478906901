import { useState } from 'react';
import useSWR from 'swr';

import { useAuthContext } from '../../auth';
import { getMySubscription } from '../api/bonnyApi';
import { BonnySubscription, GetBonnySubscriptionResponse } from '../api/types';

interface IUseMySubscriptionReturn {
    subscription?: BonnySubscription;
    error: Error | undefined;
    isLoading: boolean;
}

const options = {
    dedupingInterval: 5 * 60 * 1000,
};

const useMySubscription = (): IUseMySubscriptionReturn => {
    const { firebaseUser: fbUser } = useAuthContext();
    const [error] = useState<Error | undefined>(undefined);
    // const { mutate } = useSWRConfig();
    const { error: getMySubscriptionError, data, isLoading } = useSWR<GetBonnySubscriptionResponse, Error>(
        fbUser ? ['/my/subscription', fbUser.uid] : null,
        fbUser ? () => getMySubscription(fbUser) : null,
        options,
    );

    return {
        subscription: data?.subscription,
        isLoading,
        error: error ?? getMySubscriptionError,
    };
};

export default useMySubscription;
