import firebase from 'firebase';
import { last } from 'lodash';
import React, { useState } from 'react';
import { EndOfSessionFeedback, isScheduledWavepath, Wavepath } from 'wavepaths-shared/core';

import * as sessionApi from '../../../common/api/sessionApi';
import { getIntegrationLink } from '../../../formUtils/formUtils';
import UserEvents from '../../../UserEvents';
import { EndOfSessionFeedbackModal } from '../EndOfSessionFeedbackModal';

interface PregenEndOfSessionFeedbackProps {
    currentUser: firebase.User;
    elapsedTimeMs: number;
    sessionId: string;
    wavepaths: Wavepath[];
    onAcknowledged?: () => void;
}

export const PregenEndOfSessionFeedbackContainer: React.FC<PregenEndOfSessionFeedbackProps> = ({
    sessionId,
    currentUser,
    elapsedTimeMs,
    wavepaths,
    onAcknowledged,
}) => {
    const [endOfSessionFeedback, setEndOfSessionFeedback] = useState<EndOfSessionFeedback>();
    const [feedbackDone, setFeedbackDone] = useState(false);

    const sessionDurationMs = last(wavepaths.filter(isScheduledWavepath))?.plan?.toTime ?? Number.MAX_SAFE_INTEGER;
    const timeRemaining = sessionDurationMs - elapsedTimeMs;
    const showModal = timeRemaining < 1 * 60 * 1000 && !feedbackDone;

    const integrationLink = getIntegrationLink(sessionId, currentUser.uid);

    const acknowledge = () => {
        setFeedbackDone(true);
        onAcknowledged && onAcknowledged();
    };

    const onSubmit = () => {
        if (endOfSessionFeedback) {
            sessionApi.sendPreviousSessionFeedback(sessionId, endOfSessionFeedback, currentUser);
            UserEvents.feedbackRecorded(endOfSessionFeedback);
            acknowledge();
        }
    };

    return showModal ? (
        <EndOfSessionFeedbackModal
            integrationLink={integrationLink}
            endOfSessionFeedback={endOfSessionFeedback}
            setEndOfSessionFeedback={setEndOfSessionFeedback}
            onSubmit={onSubmit}
            sessionId={sessionId}
            onSubmitAndEnd={onSubmit}
            onClose={acknowledge}
        />
    ) : (
        <></>
    );
};
