import { partition } from 'lodash';
import React, { useState } from 'react';
import {
    PathType,
    PrePostLudePathScore,
    ScheduledPathScore,
    ScheduledWavepath,
    TherapeuticDirection,
    Wavepath,
} from 'wavepaths-shared/core';

import { SegmentedControl } from '@/component-library';
import { ControlOption } from '@/component-library/components/Control/types';

import { waveEdited, WaveSelection } from '../../../pages/inSession/autoGuide/waveSelection';
import BridgeWaveSelector, { isBridgePathScore } from './BridgeWaveSelector';
import * as infoCopy from './infoCopy';
import PercDeepenWaveSelector, { isDeepenPathScore, isPercussivePathScore } from './PercDeepenWaveSelector';
import SilenceWaveSelector, { isSilencePathScore } from './SilenceWaveSelector';
import SootheWaveSelector, { isSoothePathScore } from './SootheWaveSelector';
import TonalDeepenWaveSelector from './TonalDeepenWaveSelector';

export interface IScheduledWavepathSelectorProps {
    pathScores: ScheduledPathScore[];
    wavepath: ScheduledWavepath;
    waveSelection: WaveSelection;
    onSetWaveSelection: (selection: WaveSelection, force?: boolean) => void;
}

export interface IPrePostludeWavepathSelectorProps {
    pathScores: PrePostLudePathScore[];
    wavepath: Wavepath;
    waveSelection: WaveSelection;
    onSetWaveSelection: (selection: WaveSelection, force?: boolean) => void;
}

const listToOptions = <T extends string>(list: T[]): ControlOption<T>[] =>
    list.map((item) => ({ label: item.toString(), value: item, disabled: false }));

export function ScheduledWavePathSelector(props: IScheduledWavepathSelectorProps): JSX.Element {
    const { wavepath, onSetWaveSelection, pathScores: allPathScores } = props;
    const currentDirection =
        wavepath.pathScore.direction === TherapeuticDirection.DEEPEN
            ? wavepath.pathScore.mode === 'Percussive'
                ? 'Deepen (Percussive)'
                : 'Deepen (Tonal)'
            : wavepath.pathScore.direction;
    const [therapeuticFunction, setFunction] = useState<TherapeuticDirection | string>(currentDirection); // should be current wave value

    const pathScores = allPathScores.filter((p) => p.type !== PathType.CURATED);

    const silencePathScores = pathScores.filter(isSilencePathScore);
    const soothePathScores = pathScores.filter(isSoothePathScore);
    const deepenPathScores = pathScores.filter(isDeepenPathScore);
    const [percDeepenPathScores, tonalDeepenPathScores] = partition(deepenPathScores, isPercussivePathScore);
    const bridgePathScores = pathScores.filter(isBridgePathScore);

    return (
        <>
            <SegmentedControl
                heading={'Function'}
                name="wave-function"
                canSave={false}
                size="large"
                inline
                colour="dark"
                options={listToOptions([
                    TherapeuticDirection.SILENCE,
                    TherapeuticDirection.SOOTHE,
                    'Deepen (Tonal)',
                    'Deepen (Percussive)',
                    TherapeuticDirection.BRIDGE,
                ])}
                value={therapeuticFunction}
                onChange={setFunction}
                info={infoCopy.funktion}
            />
            {therapeuticFunction === TherapeuticDirection.SILENCE && (
                <SilenceWaveSelector
                    onWavepathEditClear={() => onSetWaveSelection(waveEdited(wavepath, wavepath), true)}
                    onWavepathEdited={(editedWavepath) =>
                        onSetWaveSelection(waveEdited(wavepath, editedWavepath), true)
                    }
                    wavepath={wavepath}
                    pathScores={silencePathScores}
                />
            )}
            {therapeuticFunction === TherapeuticDirection.SOOTHE && (
                <SootheWaveSelector
                    onWavepathEditClear={() => onSetWaveSelection(waveEdited(wavepath, wavepath), true)}
                    onWavepathEdited={(editedWavepath) =>
                        onSetWaveSelection(waveEdited(wavepath, editedWavepath), true)
                    }
                    wavepath={wavepath}
                    pathScores={soothePathScores}
                />
            )}
            {therapeuticFunction === 'Deepen (Tonal)' && (
                <TonalDeepenWaveSelector
                    pathScores={tonalDeepenPathScores}
                    onWavepathEditClear={() => onSetWaveSelection(waveEdited(wavepath, wavepath), true)}
                    onWavepathEdited={(editedWavepath) =>
                        onSetWaveSelection(waveEdited(wavepath, editedWavepath), true)
                    }
                    wavepath={wavepath}
                />
            )}
            {therapeuticFunction === 'Deepen (Percussive)' && (
                <PercDeepenWaveSelector
                    pathScores={percDeepenPathScores}
                    onWavepathEditClear={() => onSetWaveSelection(waveEdited(wavepath, wavepath), true)}
                    onWavepathEdited={(editedWavepath) =>
                        onSetWaveSelection(waveEdited(wavepath, editedWavepath), true)
                    }
                    wavepath={wavepath}
                />
            )}
            {therapeuticFunction === TherapeuticDirection.BRIDGE && (
                <BridgeWaveSelector
                    onWavepathEditClear={() => onSetWaveSelection(waveEdited(wavepath, wavepath), true)}
                    onWavepathEdited={(editedWavepath) =>
                        onSetWaveSelection(waveEdited(wavepath, editedWavepath), true)
                    }
                    wavepath={wavepath}
                    pathScores={bridgePathScores}
                />
            )}
        </>
    );
}
