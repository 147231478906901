import styled from '@emotion/styled';
import firebase from 'firebase/app';
import { findLastIndex } from 'lodash';
import React, { useRef, useState } from 'react';
import { animated, config, useSpring } from 'react-spring';
import { useInterval } from 'react-use';
import {
    CoreEmotionalAtmosphere,
    isScheduledWavepath,
    Session,
    SessionFeedback,
    Timestamped,
    Wavepath,
} from 'wavepaths-shared/core';
import { isWavepathActive } from 'wavepaths-shared/domain/wavepath';

import { NotificationStack, TimestampedFeedbackEventType } from '@/components/notifications/NotificationStack';
import Snackbar from '@/components/Snackbar';
import { useSnackbar } from '@/hooks';

import { submitInSessionFeedback } from '../../../common/api/sessionApi';
import LoadingOrb from '../../../common/components/LoadingOrb';
import { SessionAudioPlayer, SessionAudioPlayerControls } from '../../sessions/EndedSessionsTable/SessionAudioPlayer';
import { DepthStateVisualiser } from '../depthSlider/DepthStateVisualiser';
import { Timeline } from '../timeline/Timeline';
import { CeaContainer } from './CeaContainer';
import { CurrentWaveCardContainer } from './CurrentWaveCard';
import { PrecomposedGuideHeader } from './PrecomposedGuideHeader';
import { PregenEndOfSessionFeedbackContainer } from './PregenEndOfSessionFeedback';

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr;
`;

const Main = styled.main`
    display: grid;
    justify-content: stretch;
    align-content: stretch;
`;

const Content = styled.div`
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    grid-template-rows: min-content 1fr;
`;

const Center = styled.div`
    display: grid;
    place-content: center;
`;

const PrecomposedAudioControls = styled(animated.div)`
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 16px;
`;

const PlayPauseButton = styled.button`
    display: grid;
    place-content: center;
    width: 104px;
    height: 104px;
    margin: 0;
    padding: 0;
    border-radius: 52px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    cursor: pointer;
    transition: background-color 0.15s ease;
    &:hover {
        background-color: rgba(255, 255, 255, 0.9);
    }
`;

const WaveJumpButton = styled.button`
    display: grid;
    place-content: center;
    width: 64px;
    height: 64px;
    margin: 0;
    padding: 0px 4px 0px 0px;
    border-radius: 32px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    cursor: pointer;
    transition: background-color 0.15s ease;
    &:first-of-type {
        padding-right: 4px;
    }
    &:last-of-type {
        padding-left: 4px;
    }
    &:disabled {
        cursor: not-allowed;
    }
    &:disabled svg {
        opacity: 0.5;
    }
    &:hover {
        background-color: rgba(255, 255, 255, 0.9);
    }
    &:disabled:hover {
        background-color: rgba(255, 255, 255, 0.6);
    }
`;

const AudioControlIcon = styled.svg`
    fill: #2c3958;
`;

const StyledSnackbar = styled(Snackbar)({
    position: 'absolute',
});

const Bottom = styled(animated.div)({
    display: 'flex',
    justifySelf: 'center',
    flexDirection: 'column',
    minWidth: 280,
    width: '100%',
    maxWidth: 650,
    zIndex: 1,
    paddingBottom: 24,
});

const SessionAudioContainer = styled.div({
    display: 'none',
});

const getCurrentWave = (wavepaths: Wavepath[], elapsedTimeMs: number): Wavepath | undefined => {
    return wavepaths.find(isWavepathActive(elapsedTimeMs));
};

const getCeaAtTime = (wavepaths: Wavepath[], elapsedTimeMs: number): CoreEmotionalAtmosphere | undefined => {
    // this should actually be calculated from the path score stages, not the top level "emotion"
    const activeWave = getCurrentWave(wavepaths, elapsedTimeMs);
    if (!activeWave) return;

    if ('emotion' in activeWave.pathScore) {
        if (typeof activeWave.pathScore.emotion === 'string') return activeWave.pathScore.emotion;
        if (!activeWave.pathScore.emotion) return;
        return activeWave.pathScore.emotion.from;
    }
};

export function PrecomposedGuide({
    session,
    firebaseUser,
}: {
    session: Session;
    firebaseUser: firebase.User;
}): JSX.Element {
    const streamControlsRef = useRef<SessionAudioPlayerControls>(null);

    // todo, navigate back to dashboard when session finishes
    // const [endOfSessionFeedbackAcknowledged, setEndOfSessionFeedbackAcknowledged] = useState(false);

    const { score, variableInputs: variables } = session;

    const [feedback, setFeedback] = useState<TimestampedFeedbackEventType | undefined>(undefined);

    const [volume, setVolume] = useState(1);

    const [isPlaying, setIsPlaying] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentTimeSecs, setCurrentTime] = useState(0);
    const elapsedTimeSecs = currentTimeSecs;

    const [audioGeneratedPositionSecs, setAudioGeneratedPositionSecs] = useState<number | undefined>(0);

    const { content: snackbarContent, setSnackbarContent, closeSnackbar } = useSnackbar();

    const fadeIn = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 250,
        config: config.molasses,
    });

    // TODO: This could be optimised a bit: we update the component every 100ms, but the scheduled wavepath timings don't change that often.
    const scheduledWavepaths = session.score.wavepaths.filter(isScheduledWavepath);
    const scheduledWavepathStartTimesSeconds = scheduledWavepaths.map(
        (p) => (p.plan?.fromTime ?? Number.MAX_SAFE_INTEGER) / 1000,
    );
    const currentWavepathIndex = findLastIndex(scheduledWavepathStartTimesSeconds, (s) => s < currentTimeSecs);
    const isPossibleToGoToPrev = currentWavepathIndex > 0;
    const isPossibleToGoToNext = currentWavepathIndex < scheduledWavepathStartTimesSeconds.length - 1;

    useInterval(() => {
        setAudioGeneratedPositionSecs(streamControlsRef.current?.getLiveSyncPositionSecs());
    }, 1000);

    useInterval(() => setCurrentTime(streamControlsRef.current?.getTime() ?? 0), 1000);

    const onRecordFeedback = (feedback: Timestamped<SessionFeedback>) => {
        submitInSessionFeedback(session.id, feedback, feedback.timestamp, firebaseUser);
    };

    const togglePlaying = () => {
        if (!streamControlsRef.current) return;
        if (isPlaying) {
            streamControlsRef.current.pause();
        } else {
            streamControlsRef.current.play();
        }
    };

    const goToPrev = () => {
        if (currentWavepathIndex <= 0 || !streamControlsRef.current) return;
        const prevStartTime = scheduledWavepathStartTimesSeconds[currentWavepathIndex - 1];
        streamControlsRef.current.setTime(prevStartTime);
    };

    const goToNext = () => {
        if (currentWavepathIndex >= scheduledWavepathStartTimesSeconds.length - 1 || !streamControlsRef.current) return;
        const nextStartTime = scheduledWavepathStartTimesSeconds[currentWavepathIndex + 1];
        streamControlsRef.current.setTime(nextStartTime);
    };

    const targetCea = getCeaAtTime(session.score.wavepaths, currentTimeSecs * 1000);

    return (
        <Container>
            {session ? (
                <>
                    <PrecomposedGuideHeader
                        volume={volume}
                        setVolume={setVolume}
                        elapsedTimeMs={elapsedTimeSecs * 1000}
                        session={session}
                    />
                    <SessionAudioContainer>
                        <SessionAudioPlayer
                            volume={volume}
                            broadcastIdentifier={session.broadcastIdentifier}
                            onPlay={() => setIsPlaying(true)}
                            onPause={() => setIsPlaying(false)}
                            onLoad={() => setLoading(false)}
                            ref={streamControlsRef}
                            errorContext="Pregen Session"
                            onSkipBeyondStreamPosition={() =>
                                setSnackbarContent(
                                    "The music hasn't been generated yet for the point you're trying to skip to, please wait...",
                                )
                            }
                        />
                    </SessionAudioContainer>
                    <Main>
                        <Content>
                            <Timeline
                                score={score}
                                variables={variables}
                                log={[]}
                                session={session}
                                waveSelection={{
                                    selection: 'none',
                                }}
                                audioGeneratedPositionSecs={audioGeneratedPositionSecs}
                                setWaveSelection={() => undefined}
                                elapsedTimeMs={elapsedTimeSecs * 1000}
                                isPlanner
                            />
                            {!loading && (
                                <>
                                    <Center>
                                        <PrecomposedAudioControls style={fadeIn}>
                                            <WaveJumpButton
                                                aria-label="Jump to previous wave"
                                                onClick={goToPrev}
                                                disabled={!isPossibleToGoToPrev}
                                            >
                                                <AudioControlIcon
                                                    width="40"
                                                    height="40"
                                                    viewBox="0 0 40 40"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M9.16667 30V10H11.9444V30H9.16667ZM30.8333 30L16.2223 20L30.8333 10V30Z" />
                                                </AudioControlIcon>
                                            </WaveJumpButton>
                                            <PlayPauseButton onClick={togglePlaying}>
                                                {isPlaying ? (
                                                    <AudioControlIcon
                                                        width="48"
                                                        height="48"
                                                        viewBox="0 0 48 48"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path d="M28.25 38V10H36V38H28.25ZM12 38V10H19.75V38H12Z" />
                                                    </AudioControlIcon>
                                                ) : (
                                                    <AudioControlIcon
                                                        width="48"
                                                        height="48"
                                                        viewBox="0 0 48 48"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path d="M14 38V10L36 24L14 38Z" />
                                                    </AudioControlIcon>
                                                )}
                                            </PlayPauseButton>
                                            <WaveJumpButton
                                                aria-label="Jump to next wave"
                                                onClick={goToNext}
                                                disabled={!isPossibleToGoToNext}
                                            >
                                                <AudioControlIcon
                                                    width="40"
                                                    height="40"
                                                    viewBox="0 0 40 40"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M28.0556 30V10H30.8333V30H28.0556ZM9.16667 30V10L23.7778 20L9.16667 30Z" />
                                                </AudioControlIcon>
                                            </WaveJumpButton>
                                        </PrecomposedAudioControls>
                                    </Center>
                                    <Bottom style={fadeIn}>
                                        <CeaContainer targetCea={targetCea} />
                                        <CurrentWaveCardContainer
                                            activeFeedbackType={feedback?.type}
                                            onLogFeedback={(type) => setFeedback({ type, timestamp: Date.now() })}
                                            currentWave={getCurrentWave(
                                                session.score.wavepaths,
                                                elapsedTimeSecs * 1000,
                                            )}
                                            elapsedTimeSecs={elapsedTimeSecs}
                                        />
                                    </Bottom>
                                    <NotificationStack
                                        feedback={feedback}
                                        onCancel={() => setFeedback(undefined)}
                                        onSubmitFeedback={(fb) => {
                                            onRecordFeedback(fb);
                                            setFeedback(undefined);
                                        }}
                                    />
                                </>
                            )}
                        </Content>
                        <DepthStateVisualiser currentDepth={1} currentCea={targetCea} transitionTimeSecs={30} />
                        <StyledSnackbar
                            type="warning"
                            isLongButton={false}
                            message={snackbarContent}
                            confirmText={'OK'}
                            open={snackbarContent !== null}
                            closeSnackbar={closeSnackbar}
                        />
                    </Main>
                </>
            ) : (
                <LoadingOrb />
            )}

            {firebaseUser && (
                <PregenEndOfSessionFeedbackContainer
                    currentUser={firebaseUser}
                    wavepaths={session.score.wavepaths}
                    elapsedTimeMs={elapsedTimeSecs * 1000}
                    sessionId={session.id}
                />
            )}
        </Container>
    );
}
