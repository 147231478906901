import { uniq } from 'lodash';
import {
    AdministrationRoute,
    DosageLevel,
    ScoreSelectionCriteria,
    SessionScoreAdministration,
    SessionScoreDosage,
    SessionScoreModality,
} from 'wavepaths-shared/core';

import { getAllModalities } from './dataOld/modalities';

type Administration = {
    id: SessionScoreAdministration;
    name: string;
};

export const MEDICINE_LABELS: { [key in SessionScoreModality]: string } = {
    [SessionScoreModality.NONE]: 'No Medicine',
    [SessionScoreModality.KETAMINE]: 'Ketamine',
    [SessionScoreModality.MDMA]: 'MDMA',
    [SessionScoreModality.IBOGAINE]: 'Ibogaine',
    [SessionScoreModality.PSILOCYBIN]: 'Psilocybin',
    [SessionScoreModality.PSYCHOTHERAPY]: 'Psychotherapy',
    [SessionScoreModality.BREATHWORK]: 'Breathwork',
    [SessionScoreModality.FLOATATION]: 'Floatation Tank',
    [SessionScoreModality.WELLNESS]: 'Wellness',
    [SessionScoreModality.CLIENT_PREPARATION]: 'Preparation',
};

export const ADMINISTRATION_LABELS: { [key in SessionScoreAdministration]: string } = {
    [SessionScoreAdministration.Intramuscular]: 'Intramuscular',
    [SessionScoreAdministration.Intravenous]: 'Intravenous',
    [SessionScoreAdministration.Sublingual]: 'Sublingual',
    [SessionScoreAdministration.Nasal]: 'Nasal',
    [SessionScoreAdministration.Oral]: 'Oral',
};

const administrationV1ToV2: { [key in SessionScoreAdministration]: AdministrationRoute } = {
    [SessionScoreAdministration.Intramuscular]: AdministrationRoute.INTRAMUSCULAR,
    [SessionScoreAdministration.Intravenous]: AdministrationRoute.INTRAVENOUS,
    [SessionScoreAdministration.Sublingual]: AdministrationRoute.SUBLINGUAL,
    [SessionScoreAdministration.Nasal]: AdministrationRoute.NASAL,
    [SessionScoreAdministration.Oral]: AdministrationRoute.ORAL,
};

export const mapAdministrationOldToNew = (old: SessionScoreAdministration): AdministrationRoute =>
    administrationV1ToV2[old];

const dosageV1toV2: { [key in SessionScoreDosage]: DosageLevel } = {
    [SessionScoreDosage.LOW]: DosageLevel.LOW,
    [SessionScoreDosage.MEDIUM]: DosageLevel.MEDIUM,
    [SessionScoreDosage.HIGH]: DosageLevel.HIGH,
};

export const mapDosageOldToNew = (old: SessionScoreDosage): DosageLevel => dosageV1toV2[old];

export const getAdministrationsForMedicine = (modality: SessionScoreModality): Administration[] => {
    const medicine = getAllModalities().find(({ name }) => name === modality);
    if (!medicine || medicine.type !== 'PAT') return [];

    return uniq(medicine.administrations.map(({ route }) => route)).map((route) => ({
        id: route,
        name: ADMINISTRATION_LABELS[route],
    }));
};

type Dosage = {
    id: SessionScoreDosage;
    name: string;
};

export const DOSAGE_LABELS: { [key in SessionScoreDosage]: string } = {
    [SessionScoreDosage.LOW]: 'Low',
    [SessionScoreDosage.MEDIUM]: 'Medium',
    [SessionScoreDosage.HIGH]: 'High',
};

export const getDosagesForMedicineAndAdministration = (
    modality: SessionScoreModality,
    administration?: SessionScoreAdministration,
): Dosage[] => {
    const medicine = getAllModalities().find(({ name }) => name === modality);
    if (!medicine || medicine.type !== 'PAT') return [];

    const adminstrations = medicine.administrations.filter(({ route }) => route == administration);

    return adminstrations.map(({ dosage }) => ({ id: dosage, name: DOSAGE_LABELS[dosage] }));
};

export const getDefaultSessionDurationForModality = (
    selectedModality: SessionScoreModality,
    {
        administration,
        dosage,
    }: { administration?: SessionScoreAdministration | null; dosage?: SessionScoreDosage | null } = {},
): number => {
    const modality = getAllModalities().find(({ name }) => name === selectedModality);

    if (!modality) return 35;

    if (modality.type === 'Non-PAT') return modality.durationMins;

    const admin = modality.administrations.find(
        ({ route: adminRoute, dosage: adminDosage }) => adminRoute === administration && dosage === adminDosage,
    );

    return admin?.durationMins ?? 35;
};

export const getMedicineFromScore = (
    selectionCriteria?: Partial<ScoreSelectionCriteria>,
): SessionScoreModality | undefined => {
    const medicine = selectionCriteria?.medicine;
    return Array.isArray(medicine) ? medicine[0] : medicine;
};

export default {
    getDefaultSessionDurationForMedicine: getDefaultSessionDurationForModality,
    getDosagesForMedicineAndAdministration,
    getAdministrationsForMedicine,
    getMedicineFromScore,
};
