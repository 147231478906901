import styled from '@emotion/styled';
import React from 'react';

import TypographyV2 from '../typography/TypographyV2';

export interface SectionHeadingProps {
    text: string;
    moreLink?: {
        label: string;
        action: () => void;
    };
}

const Container = styled.div<{
    moreLink?: string;
}>(
    ({ moreLink }) => `
    display: grid;
    grid-template-columns: ${moreLink ? '1fr auto' : '1fr'};
    align-items: center;
    margin-bottom: 16px;
  `,
);

const SeeAllLink = styled.button`
    display: inline-grid;
    height: 40px;
    align-items: end;
    background: red;
    background: none;
    border: none;
    padding: 0 0 7px 0;
    margin: 0;
    cursor: pointer;
`;

const SectionHeading: React.FC<SectionHeadingProps> = ({ text, moreLink }) => {
    return (
        <Container moreLink={moreLink?.label}>
            <TypographyV2 element={'h3'} font={'tenor-sans'} weight={'regular'} size={'display-xs'} color={'grey-700'}>
                {text}
            </TypographyV2>
            {moreLink && (
                <SeeAllLink onClick={moreLink.action}>
                    <TypographyV2 size={'text-sm'} color={'grey-700'}>
                        {moreLink.label}
                    </TypographyV2>
                </SeeAllLink>
            )}
        </Container>
    );
};

export default SectionHeading;
