import './Modal.scss';

import classNames from 'classnames';
import React from 'react';

interface ModalProps {
    isWide?: boolean;
    className?: string;
}
export const Modal: React.FC<ModalProps> = ({ isWide, className, children }) => {
    return (
        <div className={classNames('modal', className, { isWide })}>
            <div className="modal--content">{children}</div>
        </div>
    );
};
