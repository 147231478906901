import { Card, CardActions, CardContent } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';

import Typography from '../typography/Typography';
import Button from './Button';

type OfferingFeature = { countOrIcon: number | string | JSX.Element; description: string };

export interface UserOfferingCardProps {
    monthlyPriceInDollars: number | string;
    offeringTitle: string;
    ctaCopy: string;
    ctaClickHandler: () => void;
    ctaDisabled: boolean;
    features: {
        highlighted: OfferingFeature[];
        included: OfferingFeature[];
        excluded: OfferingFeature[];
    };
    monthlyAnnualPrice: number | string;
    yearlyAnnualPrice?: number | string;
    highlighted?: boolean;
    footerContent?: ReactNode;
}

const UserOfferingCard = ({
    monthlyPriceInDollars,
    offeringTitle,
    features,
    ctaCopy,
    ctaDisabled,
    ctaClickHandler,
    monthlyAnnualPrice,
    yearlyAnnualPrice,
    highlighted = false,
    footerContent,
}: UserOfferingCardProps): React.ReactElement<UserOfferingCardProps> => {
    return (
        <div>
            <StyledCard highlighted={highlighted}>
                <TopPart>
                    <OfferingName>
                        <div>
                            <Typography variant="h6" style={{ fontWeight: highlighted ? 'bold' : 'normal' }}>
                                {offeringTitle}
                            </Typography>
                        </div>
                    </OfferingName>
                    <Pricing>
                        <Price>
                            <Typography
                                variant="h6"
                                style={{ fontSize: '33.3px', fontWeight: 300, lineHeight: '40.3px' }}
                            >
                                ${monthlyPriceInDollars}
                            </Typography>
                            <Typography variant="subtitle2">/month</Typography>
                        </Price>
                        <PriceSubCopy>
                            <Typography variant="body2" style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                                {yearlyAnnualPrice ? (
                                    <>
                                        <StrikePrice>${monthlyAnnualPrice}</StrikePrice>
                                        <ActualPrice> ${yearlyAnnualPrice}/year</ActualPrice>
                                    </>
                                ) : (
                                    <>${monthlyAnnualPrice}/year</>
                                )}
                            </Typography>
                        </PriceSubCopy>
                    </Pricing>
                    <CardActionsCentered>
                        <Button disabled={ctaDisabled} size="m" variant="solid-blue" onClick={ctaClickHandler}>
                            {ctaCopy}
                        </Button>
                    </CardActionsCentered>
                </TopPart>
                <HighlightedFeatures>
                    {features.highlighted.map(({ countOrIcon, description }) => (
                        <Feature key={description}>
                            <CountOrIcon>
                                <Typography variant="h6">{countOrIcon}</Typography>
                            </CountOrIcon>
                            <Typography variant="subtitle2">{description}</Typography>
                        </Feature>
                    ))}
                </HighlightedFeatures>
                <StyledCardContent>
                    {features.included.map(({ countOrIcon, description }) => (
                        <Feature key={description}>
                            <CountOrIcon>
                                <Typography variant="h6">{countOrIcon}</Typography>
                            </CountOrIcon>
                            <Typography style={{ color: '#646464' }} variant="subtitle2">
                                {description}
                            </Typography>
                        </Feature>
                    ))}
                    <ExcludedFeatures>
                        {features.excluded.map(({ countOrIcon, description }) => (
                            <Feature key={description}>
                                <CountOrIcon>
                                    <Typography variant="h6">{countOrIcon}</Typography>
                                </CountOrIcon>
                                <Typography variant="subtitle2" style={{ color: '#D0D2D5' }}>
                                    {description}
                                </Typography>
                            </Feature>
                        ))}
                    </ExcludedFeatures>
                    {footerContent && <Footer>{footerContent}</Footer>}
                </StyledCardContent>
            </StyledCard>
        </div>
    );
};

export default UserOfferingCard;

const StyledCard = styled(Card)((props: { highlighted: boolean }) => ({
    width: '100%',
    padding: '8px',
    boxShadow: 'rgb(207 214 231) 0px 0px 20px',
    border: '1px solid white',
    borderRadius: '16px',
    background: props.highlighted ? 'white' : 'rgba(255, 255, 255, 0.7)',
}));

const TopPart = styled(CardContent)({
    padding: '22px 16px 0px',
});

const StyledCardContent = styled(CardContent)({
    padding: '0px 16px',
});

const PriceSubCopy = styled('div')({
    minHeight: '32px',
    padding: '6px 0',
});

const CardActionsCentered = styled(CardActions)({
    display: 'flex',
    justifyContent: 'center',
    padding: '14px 0px 20px',
});

const Feature = styled('div')({
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0px',
});

const OfferingName = styled('div')({
    padding: '0 0 14px',
});

const CountOrIcon = styled('div')({
    margin: '0px 32px 0px 0px',
    width: '26px',
    textAlign: 'center',
});

const HighlightedFeatures = styled('div')({
    background: '#F0EFF5',
    borderRadius: '4px',
    padding: '0px 16px',
});

const ExcludedFeatures = styled('div')({
    color: '#D0D2D5',
});

const Pricing = styled('div')({
    padding: '0',
});

const Price = styled('div')({
    display: 'flex',
    alignItems: 'flex-end',
});

const StrikePrice = styled('span')({
    background: 'linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, transparent 52.25%)',
});

const ActualPrice = styled('span')({
    color: 'rgb(44, 57, 88)',
    // fontWeight: 'bold'
});

const Footer = styled('div')({
    display: 'flex',
    marginTop: '16px',
    justifyContent: 'center',
});
