import * as Sentry from '@sentry/browser';
import Hls, { ErrorData } from 'hls.js';
import { partial } from 'lodash';
import React, { useEffect, useImperativeHandle, useRef } from 'react';

import { getHlsErrorContext } from '../../freudConnection/hlsUtils';

interface SessionLogStreamPlayerProps {
    streamUrl: string;
    startPosition: number;
    broadcastIdentifier: string;
    errorReportContext: string;
    className?: string;
}
export interface SessionLogStreamPlayerControls {
    play: () => void;
    pause: () => void;
    setTime: (time: number) => void;
    getTime: () => number;
}
export const SessionLogStreamPlayer = React.forwardRef<SessionLogStreamPlayerControls, SessionLogStreamPlayerProps>(
    ({ streamUrl, startPosition, broadcastIdentifier, errorReportContext, className }, ref) => {
        const elRef = useRef<HTMLAudioElement>(null);

        useImperativeHandle(
            ref,
            () => ({
                play: () => {
                    if (!elRef.current) return;
                    elRef.current.play();
                },
                pause: () => {
                    if (!elRef.current) return;
                    elRef.current.pause();
                },
                setTime: (time: number) => {
                    if (!elRef.current) return;
                    elRef.current.currentTime = time;
                    elRef.current.play();
                },
                getTime: () => {
                    if (!elRef.current) return 0;
                    return elRef.current.currentTime;
                },
            }),
            [],
        );

        useEffect(() => {
            if (!elRef.current) return;
            elRef.current.crossOrigin = 'anonymous';

            if (Hls.isSupported()) {
                const hlsLogger = (level: string, ...msg: string[]) => {
                    Sentry.addBreadcrumb({
                        category: 'streaming',
                        data: { level, msg: msg.join(' ') },
                    });
                };

                const hls = new Hls({
                    liveSyncDurationCount: 3,
                    initialLiveManifestSize: 3,
                    lowLatencyMode: false,
                    debug: {
                        trace: partial(hlsLogger, 'trace'),
                        debug: partial(hlsLogger, 'debug'),
                        log: partial(hlsLogger, 'log'),
                        warn: partial(hlsLogger, 'warn'),
                        info: partial(hlsLogger, 'info'),
                        error: partial(hlsLogger, 'error'),
                    },
                } as any);
                hls.loadSource(streamUrl);
                const retryManifestLoad = (event: 'hlsError', data: ErrorData) => {
                    if (data.details === Hls.ErrorDetails.MANIFEST_LOAD_ERROR) {
                        console.log('HLS Manifest not available, retrying in 1s');
                        setTimeout(() => hls.loadSource(streamUrl), 1000);
                    }
                };
                hls.on(Hls.Events.ERROR, retryManifestLoad);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    console.log('HLS Manifest loaded');
                    hls.off(Hls.Events.ERROR, retryManifestLoad);
                    hls.on(Hls.Events.ERROR, (event, data) => {
                        if (data.fatal) {
                            switch (data.type) {
                                case Hls.ErrorTypes.NETWORK_ERROR:
                                    console.error('fatal network error encountered, try to recover', data);
                                    Sentry.withScope((scope) => {
                                        scope.setExtra('broadcastIdentifier', broadcastIdentifier);
                                        scope.setExtra('context', getHlsErrorContext(data));
                                        Sentry.captureMessage(`Streaming network error in ${errorReportContext}`);
                                    });
                                    hls.startLoad(startPosition);
                                    break;
                                case Hls.ErrorTypes.MEDIA_ERROR:
                                    console.error('fatal media error encountered, try to recover', data);
                                    Sentry.withScope((scope) => {
                                        scope.setExtra('broadcastIdentifier', broadcastIdentifier);
                                        scope.setExtra('context', getHlsErrorContext(data));
                                        Sentry.captureMessage(`Streaming media error in ${errorReportContext}`);
                                    });
                                    hls.recoverMediaError();
                                    break;
                                default:
                                    console.error('fatal streaming error', data);
                                    Sentry.withScope((scope) => {
                                        scope.setExtra('broadcastIdentifier', broadcastIdentifier);
                                        scope.setExtra('context', getHlsErrorContext(data));
                                        Sentry.captureMessage(`Streaming error in ${errorReportContext}`);
                                    });
                                    hls.destroy();
                                    break;
                            }
                        } else if (data.type === Hls.ErrorTypes.MEDIA_ERROR && data.details === 'bufferStalledError') {
                            Sentry.withScope((scope) => {
                                scope.setExtra('broadcastIdentifier', broadcastIdentifier);
                                scope.setExtra('context', getHlsErrorContext(data));
                                Sentry.captureMessage(`Streaming network glitch in ${errorReportContext}`);
                            });
                        }
                    });
                });
                hls.attachMedia(elRef.current as any);
                return () => {
                    hls.destroy();
                };
            } else if (elRef.current.canPlayType('application/vnd.apple.mpegurl')) {
                elRef.current.src = streamUrl;
            }
        }, [streamUrl, startPosition, broadcastIdentifier, errorReportContext]);

        return <audio ref={elRef} className={className} controls />;
    },
);
