import firebase from 'firebase';
import React, { ReactElement } from 'react';

import { Typography } from '@/component-library';
import { Feature, Features } from '@/features';

// import { useHistory } from 'react-router-dom';
import { InSessionTourCard } from './InSessionTourCard';
import { SessionPlannerTourCard } from './SessionPlannerTourCard';

interface ProductTourCarouselProps {
    fbUser: firebase.User;
    setFailed: (failed: boolean) => void;
}

export function ProductTourCarousel({ fbUser, setFailed }: ProductTourCarouselProps): ReactElement {
    // const history = useHistory();

    return (
        <div style={{ paddingBottom: 20, marginTop: 40 }} className="tour-productTours">
            <hr
                style={{
                    opacity: 0.3,
                    borderTop: '#6980B4 1px solid',
                    borderBottom: 'none',
                }}
            />
            <Typography style={{ margin: '16px 0', color: '#2c3958' }} variant="subtitle1">
                Guides
            </Typography>
            <SessionPlannerTourCard />
            <Feature
                name={Features.LIVE_SESSION_RENDERING}
                activeComponent={<InSessionTourCard fbUser={fbUser} setFailed={setFailed} />}
            />
        </div>
    );
}

export default ProductTourCarousel;
