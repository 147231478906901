import styled from '@emotion/styled';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import HomeIcon from '@material-ui/icons/Home';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import WavepathsLogo from '../../../images/wavepaths-logo.svg';
import TypographyV2 from '../typography/TypographyV2';
import EvaIcon from './EvaIcon';

export interface PrimaryNavigationProps {
    currentRoute?: string;
    communityUrl: string;
    userName: string;
    onSignOutClick: () => void;
    showInfiniteLink?: boolean;
    showSavedTemplates: boolean;
    showTemplatePrograms: boolean;
}

const Container = styled.div<{ isMenuOpen: boolean }>(
    ({ isMenuOpen }) => `
    max-width: 280px;
    height: 100%;
    position: relative;
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    gap: 24px;
    background: #293056;
    width: ${isMenuOpen ? '20vw' : '52px'};
    min-width: ${isMenuOpen ? '250px' : '52px'};
    transition: width, min-width 0.2s ease;
    overflow: hidden;

    @media (min-width: 930px) {
        min-width: 250px;
        width: 20vw;
    }
`,
);

const Header = styled.div`
    padding: 20px 24px 20px 52px;
    margin-top: 32px;
`;

const Logo = styled.img`
    display: block;
    height: 28px;
`;

const Body = styled.div`
    display: grid;
`;

const Nav = styled.nav`
    // padding: 0 16px;
    display: grid;
    align-content: start;
    justify-items: stretch;
`;

const NavItem = styled.div<{
    isCurrent?: boolean;
}>(
    ({ isCurrent }) => `
    // border-radius: 4px;
    background: ${isCurrent ? 'rgba(255,255,255,0.05)' : 'transparent'};
  `,
);

const MenuIcon = styled.div<{
    isCurrent?: boolean;
}>(
    ({ isCurrent }) => `
    opacity:  ${isCurrent ? 1 : 0.8};
    display: flex;
  `,
);

const NavLink = styled.button`
    // height: 40px;
    width: 100%;
    padding: 16px;
    display: flex;
    gap: 16px;
    align-items: center;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
`;

const SubNav = styled.div`
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    align-content: start;
    justify-items: stretch;
    gap: 4px;
`;

const SubNavItem = styled.div<{
    isCurrent?: boolean;
}>(
    ({ isCurrent }) => `
    // display: flex;
    width: 100%;
    // align-items: center;
    // padding: 0 16px;
    gap: 16px;
    background: ${isCurrent ? 'rgba(255,255,255,0.05)' : 'transparent'};
  `,
);

const SubNavLink = styled.button`
    height: 40px;
    width: 100%;
    padding: 0 0 0 16px;
    display: flex;
    gap: 32px;
    align-items: center;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
`;

const Footer = styled.div`
    display: grid;
    padding-bottom: 24px;
`;

const CommunityLink = styled.button`
    height: 40px;
    width: 100%;
    padding: 0 16px 0 52px;
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
`;

const ExternalLinkIcon = styled.svg`
    width: 18px;
    height: 18px;
    stroke: white;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
`;

const NavItemAccount = styled.div`
    padding: 24px 0 0 16px;
    margin-top: 12px;
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
`;

const ProfileLink = styled.button`
    height: 40px;
    width: 100%;
    padding: 0;
    display: flex;
    gap: 16px;
    align-items: center;
    text-align: left;
    background: none;
    text-overflow: ellipsis;
    border: none;
    cursor: pointer;
`;

const SignOutLink = styled.button`
    width: 40px;
    height: 40px;
    padding: 0 8px 0 0;
    display: grid;
    place-content: center;
    background: none;
    border: none;
    cursor: pointer;
`;

const SignOutLinkIcon = styled.svg`
    width: 18px;
    height: 18px;
    stroke: white;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
`;

const StyledNavLink = styled(TypographyV2)<{ isCurrent?: boolean }>(
    ({ isCurrent }) => `
        opacity: ${isCurrent ? 1 : 0.8};
        transition: opacity 0.2s;

        &:hover {
            opacity: 1;
        }
    `,
);

const ShowMenuIcon = styled.div`
    padding: 8px;
    width: fit-content;
    margin-left: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.05);
    opacity: 0.8;
    transition: opacity 0.2s;

    @media (min-width: 930px) {
        display: none;
    }

    &:hover {
        opacity: 1;
    }
`;

const PrimaryNavigation: React.FC<PrimaryNavigationProps> = ({
    currentRoute = '/',
    communityUrl,
    userName,
    onSignOutClick,
    showInfiniteLink,
    showSavedTemplates,
    showTemplatePrograms,
}) => {
    const history = useHistory();
    const [showMenu, setShowMenu] = useState(false);
    return (
        <Container isMenuOpen={showMenu}>
            <Header>
                <Logo src={WavepathsLogo} alt="Wavepaths" />
            </Header>
            <Body>
                <Nav>
                    <NavItem isCurrent={false}>
                        <ShowMenuIcon onClick={() => setShowMenu(!showMenu)}>
                            <EvaIcon name="menu" size={20} fill="white" />
                        </ShowMenuIcon>
                    </NavItem>
                    <NavItem isCurrent={currentRoute === '/'} className="tour-home">
                        <NavLink
                            onClick={() => {
                                history.push('/');
                            }}
                        >
                            <MenuIcon isCurrent={currentRoute === '/'}>
                                <HomeIcon style={{ fill: 'white', height: 20, width: 20 }} />
                            </MenuIcon>
                            <StyledNavLink
                                isCurrent={currentRoute === '/'}
                                element={'span'}
                                size={'text-sm'}
                                weight={'semibold'}
                                color={'white'}
                            >
                                Home
                            </StyledNavLink>
                        </NavLink>
                    </NavItem>
                    <NavItem isCurrent={currentRoute.includes('/templates', 0)} className="tour-templates">
                        <NavLink
                            onClick={() => {
                                history.push('/templates');
                            }}
                        >
                            <MenuIcon isCurrent={currentRoute.includes('/templates', 0)}>
                                <EvaIcon size={20} name="music" fill="white" />
                            </MenuIcon>
                            <StyledNavLink
                                isCurrent={currentRoute.includes('/templates', 0)}
                                element={'span'}
                                size={'text-sm'}
                                weight={'semibold'}
                                color={'white'}
                            >
                                Templates
                            </StyledNavLink>
                        </NavLink>
                        <SubNav>
                            {/* <SubNavItem isCurrent={currentRoute === '/templates/recent'}>
                                <SubNavLink
                                    onClick={() => {
                                        history.push('/templates/recent');
                                    }}
                                >
                                    <StyledNavLink isCurrent={currentRoute === '/templates/recent'} element={'span'} size={'text-sm'} weight={'semibold'} color={'white'}>
                                        Recent
                                    </StyledNavLink>
                                </SubNavLink>
                            </SubNavItem> */}
                            {showSavedTemplates && (
                                <SubNavItem isCurrent={currentRoute === '/templates/saved'}>
                                    <SubNavLink
                                        onClick={() => {
                                            history.push('/templates/saved');
                                        }}
                                    >
                                        <MenuIcon isCurrent={currentRoute === '/templates/saved'}>
                                            <EvaIcon size={20} name="bookmark-outline" fill="white" />
                                        </MenuIcon>
                                        <StyledNavLink
                                            isCurrent={currentRoute === '/templates/saved'}
                                            element={'span'}
                                            size={'text-sm'}
                                            weight={'regular'}
                                            color={'white'}
                                        >
                                            Saved
                                        </StyledNavLink>
                                    </SubNavLink>
                                </SubNavItem>
                            )}
                            {showTemplatePrograms && (
                                <SubNavItem isCurrent={currentRoute === '/templates/programs'}>
                                    <SubNavLink
                                        onClick={() => {
                                            history.push('/templates/programs');
                                        }}
                                    >
                                        <MenuIcon isCurrent={currentRoute === '/templates/programs'}>
                                            <EvaIcon size={20} name="grid-outline" fill="white" />
                                        </MenuIcon>
                                        <StyledNavLink
                                            isCurrent={currentRoute === '/templates/programs'}
                                            element={'span'}
                                            size={'text-sm'}
                                            weight={'regular'}
                                            color={'white'}
                                        >
                                            Programs
                                        </StyledNavLink>
                                    </SubNavLink>
                                </SubNavItem>
                            )}
                        </SubNav>
                    </NavItem>
                    <NavItem isCurrent={currentRoute.includes('/sessions', 0)} className="tour-sessions">
                        <NavLink
                            onClick={() => {
                                history.push('/sessions');
                            }}
                        >
                            <MenuIcon isCurrent={currentRoute === '/sessions'}>
                                <EvaIcon size={20} name="list" fill="white" />
                            </MenuIcon>
                            <StyledNavLink
                                isCurrent={currentRoute === '/sessions'}
                                element={'span'}
                                size={'text-sm'}
                                weight={'semibold'}
                                color={'white'}
                            >
                                Sessions
                            </StyledNavLink>
                        </NavLink>
                        {/* <SubNav>
                            <SubNavItem isCurrent={currentRoute === '/sessions/scheduled'}>
                                <SubNavLink
                                    onClick={() => {
                                        history.push('/sessions/scheduled');
                                    }}
                                >
                                    <StyledNavLink  element={'span'} size={'text-sm'} weight={'semibold'} color={'white'}>
                                        Scheduled
                                    </StyledNavLink>
                                </SubNavLink>
                            </SubNavItem>
                            <SubNavItem isCurrent={currentRoute === '/sessions/completed'}>
                                <SubNavLink
                                    onClick={() => {
                                        history.push('/sessions/completed');
                                    }}
                                >
                                    <StyledNavLink  element={'span'} size={'text-sm'} weight={'semibold'} color={'white'}>
                                        Completed
                                    </StyledNavLink>
                                </SubNavLink>
                            </SubNavItem>
                        </SubNav> */}
                    </NavItem>
                    <NavItem isCurrent={currentRoute.includes('/lessons', 0)} className="tour-lessons">
                        <NavLink
                            onClick={() => {
                                history.push('/lessons');
                            }}
                        >
                            <MenuIcon isCurrent={currentRoute.includes('/lessons', 0)}>
                                <EvaIcon size={20} name="book-open-outline" fill="white" />
                            </MenuIcon>
                            <StyledNavLink
                                isCurrent={currentRoute.includes('/lessons', 0)}
                                element={'span'}
                                size={'text-sm'}
                                weight={'semibold'}
                                color={'white'}
                            >
                                Lessons
                            </StyledNavLink>
                        </NavLink>
                    </NavItem>
                    {showInfiniteLink && (
                        <NavItem isCurrent={currentRoute.includes('Infinite', 0)}>
                            <NavLink
                                onClick={() => {
                                    history.push('/session/new/groupInfinite');
                                }}
                            >
                                <MenuIcon isCurrent={currentRoute.includes('/Infinite', 0)}>
                                    <AllInclusiveIcon style={{ fill: 'white', height: 20, width: 20 }} />
                                </MenuIcon>
                                <StyledNavLink
                                    isCurrent={currentRoute.includes('/Infinite', 0)}
                                    element={'span'}
                                    size={'text-sm'}
                                    weight={'semibold'}
                                    color={'white'}
                                >
                                    Infinite
                                </StyledNavLink>
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
            </Body>
            <Footer>
                <Nav>
                    {communityUrl && (
                        <NavItem className="tour-community">
                            <CommunityLink
                                onClick={() => {
                                    window.open(communityUrl, '_blank');
                                }}
                            >
                                <StyledNavLink element={'span'} size={'text-sm'} weight={'semibold'} color={'white'}>
                                    Community
                                </StyledNavLink>
                                <ExternalLinkIcon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5 7.50001L17.5 2.50001M17.5 2.50001H12.5M17.5 2.50001L10 10M8.33333 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V11.6667" />
                                </ExternalLinkIcon>
                            </CommunityLink>
                        </NavItem>
                    )}
                    {userName && (
                        <NavItemAccount className="tour-profile">
                            <ProfileLink
                                onClick={() => {
                                    history.push('/profile');
                                }}
                            >
                                <MenuIcon isCurrent={currentRoute === '/profile'}>
                                    <EvaIcon name="person" size={20} fill="white" />
                                </MenuIcon>
                                <StyledNavLink
                                    isCurrent={currentRoute === '/profile'}
                                    element={'span'}
                                    size={'text-sm'}
                                    weight={'semibold'}
                                    color={'white'}
                                >
                                    {userName}
                                </StyledNavLink>
                            </ProfileLink>
                            <SignOutLink onClick={onSignOutClick} aria-label="Sign out">
                                <SignOutLinkIcon
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    role="img"
                                >
                                    <title>Sign out</title>
                                    <path d="M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5M7.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H7.5" />
                                </SignOutLinkIcon>
                            </SignOutLink>
                        </NavItemAccount>
                    )}
                </Nav>
            </Footer>
        </Container>
    );
};

export default PrimaryNavigation;
