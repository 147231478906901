import axios from 'axios';
import firebase from 'firebase';
import useSWR from 'swr';

import { FilterCriteria, IListScoreTemplatesReturn } from '../api/contentApi';
import { IListSavedTemplatesReturn } from '../api/savedTemplatesApi';
import { FREUD_BASE_URL } from '../api/sessionApi';

const listFetcher = (
    fbUser: firebase.User,
    pagination?: {
        offset?: number;
        limit?: number;
    },
) => (url: string) =>
    fbUser
        .getIdToken()
        .then((token) =>
            axios.get(FREUD_BASE_URL + url, {
                method: 'GET',
                headers: { authorization: `idToken ${token}` },
                params: {
                    limit: pagination?.limit,
                    offset: pagination?.offset,
                },
            }),
        )
        .then((res) => res.data);

export interface IUseTemplatesProps {
    fbUser?: firebase.User;
    resourceUrl: string;
    criteria?: FilterCriteria;
    itemsPerPage?: number;
}

function useListTemplates({ fbUser, resourceUrl, itemsPerPage = 4 }: IUseTemplatesProps) {
    const result = useSWR<IListScoreTemplatesReturn | IListSavedTemplatesReturn, Error>(
        fbUser ? resourceUrl : null,
        fbUser ? listFetcher(fbUser, { limit: itemsPerPage }) : null,
    );

    return result;
}

export default useListTemplates;
