import { makeStyles, Theme } from '@material-ui/core';
import { uniqueId } from 'lodash';
import React, { useMemo } from 'react';
import { ExperimentalWaveFunction, isScheduledGenerativePathScore, PathScore, PathType } from 'wavepaths-shared/core';

import { pointsToSvgPath } from './svgUtils';
import { getCurvePointsFromWave, getProportionOfWaveGenerated, TimelineWaveProperties } from './timelineWaveUtils';

interface ITimelineWaveStylesProps {
    opacity: 'full' | 'medium' | 'low';
    dashArray: number[];
}
const opacities = { full: 1, medium: 0.2, low: 0.1 };
const useStyles = makeStyles<Theme, ITimelineWaveStylesProps>({
    maskPath: {
        stroke: 'white',
        fill: 'none',
        strokeWidth: 2,
        strokeLinejoin: 'round',
        strokeLinecap: 'round',
        vectorEffect: 'non-scaling-stroke',
        opacity: ({ opacity }) => opacities[opacity],
        strokeDasharray: ({ dashArray }) => dashArray.join(','),
    },
});

interface ITimelineWaveProps {
    wave: TimelineWaveProperties;
    opacity: 'full' | 'medium' | 'low';
    padding?: number;
    audioGeneratedPositionSecs?: number;
    onClick?: (wave: TimelineWaveProperties) => void;
}
export const TimelineWave: React.FC<ITimelineWaveProps> = React.memo(
    ({ wave, padding, onClick, opacity = 'full', audioGeneratedPositionSecs }) => {
        const id = useMemo(() => uniqueId('wave'), []);

        const points = getCurvePointsFromWave(wave, padding);
        const dashArray = getLineDashArray(wave.wave.pathScore);
        const styles = useStyles({ opacity, dashArray });
        const svgPath = pointsToSvgPath(points);

        const backgroundPad = 1;

        const proportionOfWaveGenerated = audioGeneratedPositionSecs
            ? getProportionOfWaveGenerated(audioGeneratedPositionSecs, wave.wave.plan)
            : 1;

        const proportionNotGenerated = 1 - proportionOfWaveGenerated;
        const widthNotGenerated = proportionNotGenerated * wave.width;

        return (
            <g transform={`translate(${wave.x} 0)`} onClick={() => onClick?.(wave)}>
                <defs>
                    <linearGradient id={`valenceArousalPathGradient-${id}`}>
                        {points.map(({ x, z }, idx) => (
                            <stop key={'colorStop-' + idx} offset={x / wave.width} stopColor={z} />
                        ))}
                    </linearGradient>

                    <mask id={`lineMask-${id}`}>
                        <path d={svgPath} className={styles.maskPath} style={{ opacity: opacities.medium }} />
                    </mask>
                    <mask id={`lineMaskGeneratingStatus-${id}`}>
                        <path d={svgPath} className={styles.maskPath} />
                        <rect
                            x={wave.width - widthNotGenerated}
                            y="0"
                            width={widthNotGenerated}
                            height="100"
                            fill="black"
                        />
                    </mask>
                </defs>
                <rect
                    x={-backgroundPad}
                    y={-backgroundPad}
                    width={wave.width + 2 * backgroundPad}
                    height={wave.height + 2 * backgroundPad}
                    fill={`url(#valenceArousalPathGradient-${id})`}
                    mask={`url(#lineMaskGeneratingStatus-${id})`}
                />
                <rect
                    x={-backgroundPad}
                    y={-backgroundPad}
                    width={wave.width + 2 * backgroundPad}
                    height={wave.height + 2 * backgroundPad}
                    fill={`url(#valenceArousalPathGradient-${id})`}
                    mask={`url(#lineMask-${id})`}
                />
            </g>
        );
    },
);

function getLineDashArray(pathScore: PathScore) {
    if (pathScore.type === PathType.EXPERIMENTAL) {
        if (pathScore.selectionCriteria?.function === ExperimentalWaveFunction.Percussive) {
            return [4, 4];
        } else if (pathScore.selectionCriteria?.function === ExperimentalWaveFunction.StructuredSoothe) {
            return [1, 3];
        }
    }
    if (isScheduledGenerativePathScore(pathScore) && pathScore.mode === 'Percussive') return [4, 4];
    return [1, 0];
}
