import firebase from 'firebase';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SessionHeader, SessionRenderType, SessionScoreDosage } from 'wavepaths-shared/core';

import { Dialog } from '@/component-library';

import * as api from '../../common/api/sessionApi';
import { isSessionCancelled } from '../../common/domain/session';
import { useSessionTiming } from '../../common/hooks/useSessionTiming';
import { getSessionLink } from '../../common/util/broadcastLinkUtils';
import { formatDateTime } from '../../dateUtilsV2';
import UserEvents from '../../UserEvents';
import { renderSessionName } from './helpers';
import SessionCard, { ISessionAction } from './SessionCard';
import SessionProgressMeter from './SessionProgressMeter';

const DOSAGE_LABELS: { [key: number]: string } = {
    [SessionScoreDosage.LOW]: 'Low',
    [SessionScoreDosage.MEDIUM]: 'Medium',
    [SessionScoreDosage.HIGH]: 'High',
};

interface IProps {
    session: SessionHeader;
    isAdmin: boolean;
    fbUser: firebase.User;
    onLinkClick: () => void;
    copiedLinkId: string | undefined;
    setCopiedLinkId: (id: string) => void;
}

const getSubtitle = (medicine: string, dosage: string | number | undefined): string => {
    if (medicine === 'undefined' || medicine === 'None') return 'Non Drug';
    if (dosage && typeof dosage === 'number') return `${medicine} ${DOSAGE_LABELS[dosage]}`;
    if (dosage) return `${medicine} ${dosage}`;
    return medicine;
};

const getSessionCardInfo = (
    totalDuration: number,
    timeElapsed: number,
    session: SessionHeader,
    minutesRemaining: number,
) => {
    return totalDuration && timeElapsed
        ? [`Started ${formatDateTime(session.startedAt)}`, '•', `${minutesRemaining} minutes remaining`]
        : ['...'];
};

function OngoingSessionCard({
    session,
    isAdmin,
    onLinkClick,
    fbUser,
    copiedLinkId,
    setCopiedLinkId,
}: IProps): ReactElement {
    const history = useHistory();
    const [linkCopyText, setLinkCopyText] = useState<'Copy Client Link' | 'Copied'>('Copy Client Link');
    const { timeElapsed = 0, totalDuration = 0 } = useSessionTiming(session.broadcastIdentifier || session.id);

    const sessionName = renderSessionName(session);

    const minutesRemaining = totalDuration - timeElapsed > 0 ? totalDuration - timeElapsed : 0;
    const sessionCardInfo =
        session?.renderType === SessionRenderType.PRE_RENDERED
            ? ['Generating, please wait...']
            : getSessionCardInfo(totalDuration, timeElapsed, session, minutesRemaining);

    const userInfoText = session?.user?.name ?? '';

    useEffect(() => {
        if (copiedLinkId !== session.id && linkCopyText !== 'Copy Client Link') {
            setLinkCopyText('Copy Client Link');
        }
    }, [copiedLinkId, session.id, linkCopyText]);

    // TODO: it can be a string that says "undefined" - not ideal
    const subtitle = getSubtitle(`${session.score?.selectionCriteria?.medicine}`, session.variableInputs.dosage);

    const [confirmEndOpen, setConfirmEndOpen] = useState(false);

    const handleEndSession = () => {
        setConfirmEndOpen(true);
    };

    const [endSessionAction, setEndSessionAction] = useState<ISessionAction>({
        description: 'End Now',
        action: handleEndSession,
    });

    const onEndSessionConfirmed = () => {
        const isCancelled = isSessionCancelled(timeElapsed, totalDuration);
        const sessionCompletness =
            timeElapsed && totalDuration ? Math.round((100 * timeElapsed) / totalDuration) : undefined;
        if (isCancelled) {
            UserEvents.sessionCancelled(session, { sessionCompletness });
        } else {
            UserEvents.sessionCompleted(session);
        }
        fbUser && api.deleteSession(session.id, 0, fbUser);
        setConfirmEndOpen(false);
        setEndSessionAction({
            description: 'Ending',
            action: () => {
                // do nothing
            },
        });
    };

    const copyClientLink = () => {
        setLinkCopyText('Copied');
        const sessionLink = getSessionLink(session.broadcastIdentifier);
        navigator.clipboard.writeText(sessionLink);
        setCopiedLinkId(session.id);
    };

    const onOpen = () => {
        history.push(`/session/${session.id}`);
        onLinkClick();
    };

    const onClickLog = () => {
        history.push(`/logs/${session.id}`);
    };

    const actions = [
        endSessionAction,
        ...(session.renderType === SessionRenderType.REAL_TIME
            ? [{ description: linkCopyText, action: copyClientLink }]
            : []),
        ...(isAdmin ? [{ description: 'Log', action: onClickLog }] : []),
    ] as ISessionAction[];

    /*
        CARD API
        open: onLinkClick
        title: sessionName
        info: formatDateTime(session.startedAt)
        actions: [
            {description: "End Now", action: handleEndSession }
            {description: "Copy Client Link", action: `copies link getSessionLink(session.broadcastIdentifier)`}
            {description: "Log", action: `links to `/logs/${session.id}` `}
        ]
    */

    return (
        <React.Fragment key={session.id}>
            <Dialog
                fullWidth={true}
                open={confirmEndOpen}
                message={`Are you sure you want to end the "${sessionName}" session?`}
                onClose={() => setConfirmEndOpen(false)}
                onConfirm={onEndSessionConfirmed}
                confirmText={'End Session'}
            />
            <SessionCard
                primaryAction={session.renderType === SessionRenderType.REAL_TIME ? onOpen : undefined}
                title={sessionName}
                subtitle={subtitle}
                userInfoText={isAdmin ? userInfoText : undefined}
                info={sessionCardInfo}
                actions={actions}
            >
                <SessionProgressMeter timeElapsed={timeElapsed} totalDuration={totalDuration} />
            </SessionCard>
        </React.Fragment>
    );
}

export default OngoingSessionCard;
