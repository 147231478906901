import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import firebase from 'firebase';
import { isNil } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    AdministrationRoute,
    ClientVariablesMusicPreference,
    CollectionStatus,
    CoreEmotionalAtmosphere,
    DosageLevel,
    ScoreLibrary,
    Session,
    SessionRenderType,
    SessionScoreEmotionalIntensity,
    SessionScoreModality,
    SessionScoreSessionUse,
    SessionType,
    UserData,
} from 'wavepaths-shared/core';
import { getMinMaxTotalDurationForWavepaths } from 'wavepaths-shared/domain/scores';
import { isAdmin } from 'wavepaths-shared/domain/user';

import TemplateDetail, { SchedulingStyle } from '@/component-library/components/TemplateDetail';
import { SubscribeModal } from '@/components';
import WaveQueueEditor from '@/components/WaveQueueEditor';
import { Features, useFeatures } from '@/features';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useScoreTemplate } from '@/hooks/useScoreTemplate';
import useSessionTemplates from '@/hooks/useSessionTemplates';

import * as audio from '../../audio';
import { useAuthContext } from '../../auth';
import { ScoreTemplate } from '../../common/api/contentApi';
import { ISessionTemplate } from '../../common/api/savedTemplatesApi';
import * as sessionApi from '../../common/api/sessionApi';
import Snackbar from '../../common/components/Snackbar';
import useScoreLibrary from '../../common/hooks/useScoreLibrary';
import { pickFirstFormTemplateAndPostFormResponse } from '../../formUtils/formUtils';
import { LayoutContainer } from '../../LayoutContainer';
import UserEvents from '../../UserEvents';
import { isDiscardingEdits, selectNoWave, WaveSelection } from '../inSession/autoGuide/waveSelection';
import { Timeline } from '../inSession/timeline/Timeline';
import { SessionPlannerTracking } from '../planner/sessionPlannerTracking';
import useScorePlanner from './useScorePlanner';

export interface TemplateDetailParams {
    templateId: string;
}

export const SAVE_BEFORE_COLLAPSE_LABEL = 'Please Cancel or Save your changes before Collapsing this window';

type TemplateInfoCommonProps = {
    firebaseUser: firebase.User;
    userData: UserData;
    scoreLibrary: Pick<ScoreLibrary, 'pathScores' | 'presetScores'>;
};

type TemplateInfoWithSavedTemplateProps = TemplateInfoCommonProps & {
    savedTemplate: ISessionTemplate;
};

type TemplateInfoWithTemplateProps = TemplateInfoCommonProps & {
    template: ScoreTemplate;
};

type TemplateInfoContainerProps = TemplateInfoWithSavedTemplateProps | TemplateInfoWithTemplateProps;

function round5(x: number) {
    return Math.ceil(x / 5) * 5;
}

const LockedFeatureContainer = styled(Box)((props: { isLocked: boolean }) => ({
    flexDirection: 'column',
    display: 'flex',
    opacity: props.isLocked ? 0.5 : 1,
    cursor: props.isLocked ? 'pointer' : 'initial',
}));

export const MISSING_SESSION_TEMPLATE_NAME_ERROR_LABEL = 'Please provide a name to save these settings as a template';
export const ADDING_TEMPLATE_ERROR_LABEL = 'There was an error adding session template';
export const SESSION_CREATION_FAILURE_MESSAGE = 'Sorry, we were unable to create your session, please try again.';
export const SESSION_CREATION_TRAFFIC_TOO_HIGH_MESSAGE =
    'Sorry, we are experiencing an unusually high volume of sessions right now, please try again later.';

export function TemplateInfoContainer(props: TemplateInfoContainerProps) {
    const { scoreLibrary, firebaseUser } = props;
    const savedTemplate = 'savedTemplate' in props ? props.savedTemplate : undefined;

    const resource = 'template' in props ? props.template : props.savedTemplate;

    const query = useQueryParams();
    const isTour = query.has('isTour');
    const queryDuration = Number(query.get('duration'));
    const duration = isNaN(queryDuration) ? undefined : round5(queryDuration);

    const { isEnabled } = useFeatures();

    const history = useHistory();

    const [waveSelection, setWaveSelection] = useState<WaveSelection>(selectNoWave());
    const [enableQuickFades] = useState(false);

    const [contentStatuses, setContentStatuses] = useState<'Approved' | 'Submitted' | 'All'>('Approved');

    const [isStartingSession, setStartingSession] = useState(false);

    const [renderingType, setSessionRenderingType] = useState(
        isEnabled(Features.LIVE_SESSION_RENDERING)
            ? savedTemplate?.renderType ?? SessionRenderType.REAL_TIME
            : SessionRenderType.PRE_RENDERED,
    );

    const [snackbarContent, setSnackbarContent] = useState<string | null>(null);
    const closeSnackbar = useCallback(() => setSnackbarContent(null), []);

    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const initialAttributeInputs = {
        schedulingStyle: 'now' as SchedulingStyle,
        unlisted: savedTemplate && !isNil(savedTemplate.unlisted) ? savedTemplate.unlisted : true,
        canClientStartEarly:
            savedTemplate && !isNil(savedTemplate.canClientStartEarly) ? savedTemplate.canClientStartEarly : false,
    };
    const [sessionAttributeInputs, setSessionAttributeInputs] = useState<{
        unlisted: boolean;
        schedulingStyle: SchedulingStyle;
        canClientStartEarly: boolean;
        scheduledStart?: number;
    }>(initialAttributeInputs);

    const variableInputs = duration ? { totalDuration: duration } : undefined;
    const defaults = 'template' in props ? { template: props.template, variableInputs } : props.savedTemplate;

    const {
        score,
        scoreTemplate,
        updatePathInScore,
        addPathToScore,
        movePathInScore,
        removePathFromScore,
        sessionVariableInputs,
        updateVariableInputs,
    } = useScorePlanner(scoreLibrary, defaults);

    const [minimumPathsDuration, maximumPathsDuration] = getMinMaxTotalDurationForWavepaths(score?.wavepaths);

    const {
        addTemplate,
        error: sessionTemplateError,
        mutationPending: sessionTemplateMutationPending,
    } = useSessionTemplates({ fbUser: firebaseUser });

    const setWaveSelectionOrNotify = (newSelection: WaveSelection, force = false) => {
        if (!force && isDiscardingEdits(newSelection, waveSelection)) {
            setSnackbarContent(SAVE_BEFORE_COLLAPSE_LABEL);
        } else {
            setWaveSelection(newSelection);
        }
    };

    const handleSaveSessionTemplate = async () => {
        if (!isEnabled(Features.SAVE_SESSION_TEMPLATES)) {
            setModalIsOpen(true);
            return;
        }

        UserEvents.sessionTemplateCreated();
        setSnackbarContent(null);
        const sessionTemplateName = String(sessionVariableInputs.name);
        if (!sessionTemplateName || !sessionTemplateName.length) {
            setSnackbarContent(MISSING_SESSION_TEMPLATE_NAME_ERROR_LABEL);
            return;
        }
        if (!score) {
            setSnackbarContent('Duration cannot be empty');
            return;
        }
        const template = {
            name: sessionTemplateName,
            score,
            renderType: renderingType,
            variableInputs: sessionVariableInputs,
            canClientStartEarly: sessionAttributeInputs.canClientStartEarly,
        };

        const result = await addTemplate(template);
        if (result.ok) history.push('/');
    };

    useEffect(() => {
        if (sessionTemplateError) {
            setSnackbarContent(ADDING_TEMPLATE_ERROR_LABEL);
        } else {
            setSnackbarContent(null);
        }
    }, [sessionTemplateError, setSnackbarContent]);

    const handleSubmit = async () => {
        if (!score) return;

        setStartingSession(true);
        setSnackbarContent(null);

        // if (score.wavepaths.some((wp) => wp.pathScore.type === PathType.CURATED)) {
        //     setSnackbarContent(PLAYLIST_WARNING_MESSAGE);
        // }

        try {
            const filteredLayerIds = window.location.search
                ? window.location.search
                      .substring(1)
                      .split(',')
                      .map((id) => +id)
                : [];
            const attributeInputs: Partial<Session> = {
                ...sessionAttributeInputs,
                scheduledStart: getStartTime(
                    sessionAttributeInputs.schedulingStyle,
                    sessionAttributeInputs.scheduledStart,
                ),
            };

            const contentStatusesToInclude: CollectionStatus[] =
                contentStatuses === 'All' ? ['Approved', 'Submitted'] : [contentStatuses];

            const createdSession = await sessionApi.startSession(
                SessionType.ONE_ON_ONE,
                renderingType,
                score,
                sessionVariableInputs,
                attributeInputs,
                [],
                filteredLayerIds,
                firebaseUser,
                contentStatusesToInclude,
                enableQuickFades,
            );

            try {
                await pickFirstFormTemplateAndPostFormResponse(
                    createdSession.id,
                    'postSessionIntegration',
                    firebaseUser,
                );
            } catch (e) {
                Sentry.withScope((scope) => {
                    scope.setExtra('email', firebaseUser.email);
                    scope.setExtra('id', firebaseUser.uid);
                    scope.setExtra('sessionId', createdSession.id);
                    Sentry.captureException('Integration form creation failed');
                });
            }

            const streamStart = createdSession.scheduledStart;
            audio.audioCtx.resume();

            try {
                UserEvents.sessionCreated(createdSession, {
                    scheduleType: sessionAttributeInputs.schedulingStyle,
                    isTemplateCustom: !scoreTemplate,
                });
            } catch (e) {
                console.log(e);
            }

            if (streamStart && streamStart <= Date.now() + 1000 && renderingType === SessionRenderType.REAL_TIME) {
                history.push(`/session/${createdSession.id}${isTour ? '?isTour=true' : ''}`);
            } else if (renderingType === SessionRenderType.PRE_RENDERED) {
                history.push(`/session/precomposed/${createdSession.id}`);
            } else {
                history.push(`/`);
            }
        } catch (e: any) {
            console.error(e);
            if (e instanceof sessionApi.TooMuchTrafficError) {
                // setSnackbarContent(SESSION_CREATION_TRAFFIC_TOO_HIGH_MESSAGE);
                setStartingSession(false);
                return;
            }
            // setSnackbarContent(SESSION_CREATION_FAILURE_MESSAGE);
        }
        setStartingSession(false);
    };

    const handleDisabledContentClick = (e: any) => {
        if (!isEnabled(Features.WAVE_EDITOR)) {
            e.stopPropagation();
            setModalIsOpen(true);
            return;
        }
    };

    return (
        <>
            <TemplateDetail
                id={resource.id}
                emotionalities={resource.emotionalities}
                title={resource.name}
                subtitle={'subtitle' in resource ? resource.subtitle : ''}
                description={'description' in resource ? resource.description : ''}
                intensity={resource.intensity}
                modality={resource.modality}
                administration={'administration' in resource ? resource.administration : undefined}
                dosage={'dosage' in resource ? resource.dosage : undefined}
                renderType={renderingType}
                onRenderTypeChange={setSessionRenderingType}
                duration={Number(sessionVariableInputs.totalDuration)}
                onDurationChange={(totalDuration: number) => updateVariableInputs({ totalDuration })}
                onSubmit={handleSubmit}
                submitDisabled={
                    isStartingSession || !sessionVariableInputs.totalDuration || sessionTemplateMutationPending
                }
                timelineComponent={
                    score ? (
                        <Timeline
                            score={score}
                            setWaveSelection={setWaveSelection}
                            waveSelection={waveSelection}
                            variables={sessionVariableInputs}
                            isScrollable
                            phasesAlwaysVisible={true}
                            isPlanner
                        />
                    ) : (
                        <></>
                    )
                }
                tracklistComponent={
                    score ? (
                        <LockedFeatureContainer
                            isLocked={!isEnabled(Features.WAVE_EDITOR)}
                            onClickCapture={handleDisabledContentClick}
                        >
                            <WaveQueueEditor
                                shouldIncludePrelude
                                addPathAtIndex={addPathToScore}
                                waveSelection={waveSelection}
                                setWaveSelection={setWaveSelectionOrNotify}
                                currentWaveIndex={0}
                                movePathToIndex={movePathInScore}
                                removePathAtIndex={removePathFromScore}
                                setSnackbarContent={() => {
                                    console.log('mmm snacks');
                                }}
                                updatePathAtIndex={updatePathInScore}
                                wavepaths={score.wavepaths}
                                trackingHandlers={SessionPlannerTracking}
                                shouldShow
                            />
                        </LockedFeatureContainer>
                    ) : (
                        <></>
                    )
                }
                minDurationMins={scoreTemplate ? resource.durationMins.min : Math.round(minimumPathsDuration)}
                maxDurationMins={scoreTemplate ? resource.durationMins.max : Math.round(maximumPathsDuration)}
                onBackButtonClick={() => history.goBack()}
                sessionName={sessionVariableInputs.name as string}
                onSessionNameChange={(name) => updateVariableInputs({ name })}
                sessionUse={sessionVariableInputs.sessionUse as SessionScoreSessionUse}
                onSessionUseChange={(sessionUse) => updateVariableInputs({ sessionUse })}
                musicalPreference={sessionVariableInputs.Acousticness as ClientVariablesMusicPreference}
                onMusicalPreferenceChange={(Acousticness: number) => updateVariableInputs({ Acousticness })}
                canSaveTemplates={isEnabled(Features.SAVE_SESSION_TEMPLATES)}
                onSaveTemplate={handleSaveSessionTemplate}
                contentStatuses={contentStatuses}
                onContentStatusChange={setContentStatuses}
                showAdminFeatures={isAdmin(props.userData)}
                schedulingType={sessionAttributeInputs.schedulingStyle}
                onSchedulingTypeChange={(schedulingStyle: SchedulingStyle) => {
                    setSessionAttributeInputs({
                        ...sessionAttributeInputs,
                        schedulingStyle,
                        scheduledStart: schedulingStyle === 'specificTime' ? Date.now() : undefined,
                        canClientStartEarly: false,
                    });
                }}
                canClientStartEarly={sessionAttributeInputs.canClientStartEarly}
                onCanClientStartEarlyChange={(canClientStartEarly: boolean) =>
                    setSessionAttributeInputs({
                        ...sessionAttributeInputs,
                        canClientStartEarly,
                        scheduledStart: canClientStartEarly ? sessionAttributeInputs.scheduledStart : Date.now(),
                    })
                }
                scheduledStart={sessionAttributeInputs.scheduledStart}
                onScheduledStartChange={(scheduledStart: number | undefined) =>
                    setSessionAttributeInputs({
                        ...sessionAttributeInputs,
                        scheduledStart,
                    })
                }
                showVoControl={isEnabled(Features.GUIDED_ONE_ON_ONE)}
                voiceover={sessionVariableInputs.voiceover as string}
                onVoChange={(voiceover) => updateVariableInputs({ voiceover })}
                isRealTimeRenderingEnabled={isEnabled(Features.LIVE_SESSION_RENDERING)}
            />
            <SubscribeModal isOpen={!!modalIsOpen} closeModal={() => setModalIsOpen(false)} />
            <Snackbar
                type={'warning'}
                isLongButton={false}
                message={snackbarContent ?? ''}
                confirmText={'OK'}
                open={snackbarContent !== null}
                closeSnackbar={closeSnackbar}
            />
        </>
    );
}

const pulse = keyframes`
    from, to { 
        opacity: 0.5; 
    }

    50% { 
        opacity: 1; 
    }
`;

const LoadingDiv = styled.div({
    filter: 'blur(3px)',
    animation: `${pulse} 2s ease-in-out infinite`,
});

function TemplateInfoFetchContainer() {
    const { templateId } = useParams<TemplateDetailParams>();

    const { firebaseUser, userData } = useAuthContext();
    const scorelibrary = useScoreLibrary(firebaseUser);

    const { template } = useScoreTemplate({ fbUser: firebaseUser, id: templateId });

    if (!template || scorelibrary.loading || !firebaseUser || !userData)
        return (
            <LoadingDiv>
                <TemplateDetail
                    id={'fake'}
                    emotionalities={{
                        primary: CoreEmotionalAtmosphere.SILENCE,
                        secondary: CoreEmotionalAtmosphere.SILENCE,
                        tertiary: CoreEmotionalAtmosphere.SILENCE,
                    }}
                    title="Loading..."
                    subtitle={'Lorem ipsum'}
                    description={'Description'}
                    intensity={SessionScoreEmotionalIntensity.HIGH}
                    modality={SessionScoreModality.PSILOCYBIN}
                    administration={AdministrationRoute.NASAL}
                    dosage={DosageLevel.HIGH}
                    renderType={SessionRenderType.PRE_RENDERED}
                    onRenderTypeChange={() => {
                        console.log('avoid the void');
                    }}
                    duration={Number(60)}
                    onDurationChange={() => {
                        console.log('avoid the void');
                    }}
                    onSubmit={() => {
                        console.log('avoid the void');
                    }}
                    submitDisabled={true}
                    timelineComponent={<></>}
                    tracklistComponent={<></>}
                    minDurationMins={30}
                    maxDurationMins={60}
                    onBackButtonClick={() => {
                        console.log('avoid the void');
                    }}
                    sessionName={'Example session name'}
                    onSessionNameChange={() => {
                        console.log('avoid the void');
                    }}
                    sessionUse={SessionScoreSessionUse.IN_PERSON}
                    onSessionUseChange={() => {
                        console.log('avoid the void');
                    }}
                    musicalPreference={ClientVariablesMusicPreference.ACOUSTIC}
                    onMusicalPreferenceChange={() => {
                        console.log('avoid the void');
                    }}
                    canSaveTemplates={true}
                    onSaveTemplate={() => {
                        console.log('avoid the void');
                    }}
                    contentStatuses={'All'}
                    onContentStatusChange={() => {
                        console.log('avoid the void');
                    }}
                    showAdminFeatures={false}
                    schedulingType={'now'}
                    onSchedulingTypeChange={() => {
                        console.log('avoid the void');
                    }}
                    canClientStartEarly={false}
                    onCanClientStartEarlyChange={() => {
                        console.log('avoid the void');
                    }}
                    onScheduledStartChange={() => {
                        console.log('avoid the void');
                    }}
                    showVoControl={false}
                    voiceover={''}
                    onVoChange={() => {
                        console.log('avoid the void');
                    }}
                    isRealTimeRenderingEnabled={false}
                />
            </LoadingDiv>
        );

    return (
        <TemplateInfoContainer
            firebaseUser={firebaseUser}
            userData={userData}
            scoreLibrary={scorelibrary}
            template={template}
        />
    );
}

function TemplateDetailWithNav() {
    return (
        <LayoutContainer>
            <TemplateInfoFetchContainer />
        </LayoutContainer>
    );
}

function getStartTime(schedulingStyle: SchedulingStyle, scheduledStart: number | undefined) {
    if (schedulingStyle === 'now') {
        return new Date().getTime();
    }
    if (schedulingStyle === 'later') {
        return undefined;
    }
    if (!scheduledStart) {
        throw new Error('scheduledStart is undefined but schedulingStyle is specificTime');
    }
    return new Date(scheduledStart).getTime();
}

export default TemplateDetailWithNav;
