import { makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, EvaIcon, Typography } from '@/component-library';
import Snackbar from '@/components/Snackbar';
import { useUserData } from '@/hooks';

import { UserInfo } from '../../UserInfoV2';
import { InputGroup, ProfileSection, Text } from './components';
import { SubscriptionInfo } from './SubscriptionInfo';

type ProviderIdType = 'password' | 'google.com' | 'facebook.com' | undefined;

const useStyles = makeStyles({
    profile: {
        minHeight: '100%',
        display: 'grid',
        justifyItems: 'center',
        background: '#f1f3f8',
    },
    container: {
        position: 'relative',
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '0 20px 80px 20px',
        maxWidth: '1024px',
        margin: 'auto',
    },
    header: {
        display: 'grid',
        gridAutoFlow: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '48px 0',
    },
    main: {
        display: 'grid',
        gridAutoFlow: 'row',
        gap: '32px',
    },
    inputGroup: {
        maxWidth: '350px',
        display: 'grid',
        gridAutoFlow: 'row',
        marginBottom: '16px',
    },
});

const Profile: React.FC<{ firebaseUser: firebase.User }> = ({ firebaseUser }) => {
    const { userData, setEmail, mutateError } = useUserData();

    const classes = useStyles();
    const [providerId, setProviderId] = useState<ProviderIdType>(undefined);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showSuccess, setShowSuccess] = useState(false);

    const [isChangingEmail, setIsChangingEmail] = useState(false);
    const [newEmail, setNewEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [mutationPending, setMutationPending] = useState(false);
    const [snackbarContent, setSnackbarContent] = useState<string | null>(null);
    const closeSnackbar = useCallback(() => setSnackbarContent(null), []);

    const history = useHistory();

    useEffect(() => {
        if (firebaseUser) {
            setProviderId(firebaseUser?.providerData[0]?.providerId as ProviderIdType);
        }
    }, [firebaseUser]);

    useEffect(() => {
        if (mutateError === 'email-in-use') {
            setSnackbarContent('Email already in use');
        } else if (mutateError === 'invalidEmail') {
            setSnackbarContent('Email invalid');
        } else if (mutateError === 'requires-recent-login') {
            setSnackbarContent('Session expired, please log in again to continue');
        }
    }, [mutateError]);

    const changePassword = async () => {
        await reauthenticate();
        firebaseUser
            .updatePassword(newPassword)
            .then(() => flashSavedLabel())
            .catch((error) => console.log({ error }));
    };

    const handleChangeEmail = () => {
        if (newEmail !== confirmEmail) {
            return setSnackbarContent('Emails do not match');
        }
        setMutationPending(true);
        setEmail(newEmail)
            .then(() => setIsChangingEmail(false))
            .finally(() => setMutationPending(false));
    };

    const reauthenticate = async () => {
        try {
            if (providerId === 'password') {
                const credential =
                    firebaseUser.email && firebase.auth.EmailAuthProvider.credential(firebaseUser.email, oldPassword);
                if (credential) {
                    await firebaseUser.reauthenticateWithCredential(credential);
                }
            } else {
                throw Error('Wrong provider: ' + providerId);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const flashSavedLabel = () => {
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 1500);
    };

    return (
        <div className={classes.profile}>
            <div className={classes.container}>
                <UserInfo />
                <header className={classes.header}>
                    <Text variant="h2">Your account</Text>
                    <Button
                        variant="solid-blue"
                        size="m"
                        icon={<EvaIcon name="arrow-back" size={16} fill={'#FFF'} />}
                        onClick={() => {
                            history.push('/');
                        }}
                    >
                        <Typography variant="button" style={{ color: 'white' }}>
                            Go back to dashboard
                        </Typography>
                    </Button>
                </header>
                <main className={classes.main}>
                    <SubscriptionInfo fbUser={firebaseUser} />
                    <section>
                        <Text variant="h6">Account details</Text>
                        <ProfileSection>
                            <InputGroup>
                                <label>Name</label>
                                <Text variant="body1">{userData?.name}</Text>
                            </InputGroup>
                            {isChangingEmail ? (
                                <>
                                    <InputGroup>
                                        <label htmlFor="newEmail">New email</label>
                                        <input
                                            value={newEmail}
                                            onChange={(event) => setNewEmail(event.target.value)}
                                            type="email"
                                            name="newEmail"
                                            placeholder="New email"
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <label htmlFor="confirmEmail">Confirm new email</label>
                                        <input
                                            value={confirmEmail}
                                            onChange={(event) => setConfirmEmail(event.target.value)}
                                            type="email"
                                            name="confirmEmail"
                                            placeholder="Confirm email"
                                        />
                                    </InputGroup>
                                    <Button
                                        disabled={mutationPending}
                                        variant="solid-blue"
                                        size="m"
                                        onClick={handleChangeEmail}
                                        style={{ marginRight: 8 }}
                                    >
                                        Confirm
                                    </Button>
                                    <Button
                                        disabled={mutationPending}
                                        variant="outlined"
                                        size="m"
                                        onClick={() => setIsChangingEmail(false)}
                                    >
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <InputGroup>
                                        <label>Email</label>
                                        <Text variant="body1">{userData?.email}</Text>
                                    </InputGroup>
                                    {providerId === 'password' && (
                                        <Button variant="outlined" size="m" onClick={() => setIsChangingEmail(true)}>
                                            Change Email
                                        </Button>
                                    )}
                                </>
                            )}
                        </ProfileSection>
                    </section>
                    {providerId && (
                        <section>
                            <Text variant="h6">Change password</Text>
                            <ProfileSection>
                                {providerId === 'password' ? (
                                    <>
                                        <InputGroup>
                                            <label htmlFor="password">Old Password</label>
                                            <input
                                                value={oldPassword}
                                                onChange={(event) => setOldPassword(event.target.value)}
                                                type="password"
                                                name="password"
                                                placeholder="Old password"
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <label htmlFor="password">New Password</label>
                                            <input
                                                value={newPassword}
                                                onChange={(event) => setNewPassword(event.target.value)}
                                                type="password"
                                                name="password"
                                                placeholder="New password"
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <label htmlFor="confirmPassword">Confirm new password</label>
                                            <input
                                                value={confirmPassword}
                                                onChange={(event) => setConfirmPassword(event.target.value)}
                                                type="password"
                                                name="confirmPassword"
                                                placeholder="Confirm new password"
                                            />
                                        </InputGroup>
                                        <Button variant="outlined" size="m" onClick={changePassword}>
                                            Change password
                                        </Button>
                                    </>
                                ) : (
                                    <p>{`You can change your password in your ${providerId} account settings`}</p>
                                )}
                            </ProfileSection>
                            <Snackbar
                                type={'error'}
                                isLongButton={false}
                                message={snackbarContent ?? ''}
                                confirmText={'OK'}
                                open={snackbarContent !== null}
                                closeSnackbar={closeSnackbar}
                            />
                        </section>
                    )}
                </main>
            </div>
        </div>
    );
};

export default Profile;
