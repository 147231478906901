import firebase from 'firebase';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { FirebaseUser, SessionHeader, SessionRenderType, SessionScoreDosage } from 'wavepaths-shared/core';

import { Features, useFeatures } from '@/features';

import { getSession } from '../../../common/api/sessionApi';
import { convertSessionToSessionTemplate } from '../../../common/api/sessionTemplatesApi';
import { formatDateTime } from '../../../dateUtilsV2';
import { getIntegrationLink } from '../../../formUtils/formUtils';
import UserEvents, { CopiedSessionTab } from '../../../UserEvents';
import { renderSessionName } from '../helpers';
import SessionCard from '../SessionCard';
import evaluateIntegrationFormStatus, { ResponseStatus } from './evaluateIntegrationFormStatus';
import { SessionAudioPlayer } from './SessionAudioPlayer';
import useFormResponses from './useFormResponses';

type FormDataStatus = 'loading' | 'error' | ResponseStatus;
const DOSAGE_LABELS: { [key: number]: string } = {
    [SessionScoreDosage.LOW]: 'Low',
    [SessionScoreDosage.MEDIUM]: 'Medium',
    [SessionScoreDosage.HIGH]: 'High',
};

const getSubtitle = (medicine: string, dosage: string | number | undefined): string => {
    if (medicine === 'undefined' || medicine === 'None') return 'Non Drug';
    if (dosage && typeof dosage === 'number') return `${medicine} ${DOSAGE_LABELS[dosage]}`;
    if (dosage) return `${medicine} ${dosage}`;
    return medicine;
};

export interface Props {
    session: SessionHeader;
    isAdmin: boolean;
    fbUser: firebase.User;
    setCopiedIntegrationLinkId: (sessionId: string) => void;
    copiedIntegrationLinkId: string | undefined;
    signups: { [sessionId: string]: FirebaseUser[] };
}
const EndedSessionCard = ({
    session,
    isAdmin,
    fbUser,
    setCopiedIntegrationLinkId,
    copiedIntegrationLinkId,
}: Props): React.ReactElement => {
    const queryResult = useFormResponses(session.id, fbUser);
    const formDataStatus: FormDataStatus =
        typeof queryResult === 'object' ? evaluateIntegrationFormStatus(queryResult.formResponses) : queryResult;
    const { isEnabled } = useFeatures();

    const showIntegrationLink =
        isEnabled(Features.CLIENT_INTEGRATION) && !['loading', 'error', ResponseStatus.noForm].includes(formDataStatus);
    const history = useHistory();
    const sessionName = renderSessionName(session);

    const userInfoText = session?.user?.name;

    const subtitle = getSubtitle(`${session?.score?.selectionCriteria?.medicine}`, session.variableInputs.dosage);

    const copyIntegrationLink = () => {
        navigator.clipboard.writeText(getIntegrationLink(session.id, fbUser.uid));
        setCopiedIntegrationLinkId(session.id);
        UserEvents.integrationLinkCopied(session.id);
    };

    const openIntegrationInNewTab = () => {
        typeof window !== undefined && window.open(getIntegrationLink(session.id, fbUser.uid), '_blank');
        UserEvents.integrationLinkOpened(session.id);
    };

    const onClickLog = () => {
        history.push(`/logs/${session.id}`);
    };

    const handleMakeCopy = async () => {
        UserEvents.copiedSession(CopiedSessionTab.COMPLETED);
        const fullSession = await getSession(session.id, fbUser);
        const sessionTemplate = convertSessionToSessionTemplate(fullSession);
        history.push({
            pathname: '/session/new/oneOnOne',
            state: { sessionTemplate }, // your data array of objects
        });
    };

    /*
        Interface for SessionCard:
        title: sessionName    
        subtitle: subtitle
        actions: [
            {description: "Log", action: onClickLog `}
        ]
        onOpen: undefined
        subActions: [
            {description: "integration", action `copies link getIntegrationLink(session.id, fbUser.uid)`}
        ]
    */

    return (
        <div>
            <SessionCard
                info={[
                    `${session.renderType === SessionRenderType.PRE_RENDERED ? 'Generated' : 'Ended'} ${formatDateTime(
                        session.endedAt,
                    )}`,
                ]}
                title={sessionName}
                subtitle={subtitle}
                userInfoText={isAdmin && userInfoText ? userInfoText : undefined}
                primaryActionLabel={copiedIntegrationLinkId === session.id ? 'Link Copied' : 'Share Session'}
                primaryAction={showIntegrationLink ? copyIntegrationLink : undefined}
                actions={[
                    ...(showIntegrationLink
                        ? [
                              {
                                  description: 'View Session',
                                  action: openIntegrationInNewTab,
                              },
                          ]
                        : []),
                    ...(isAdmin ? [{ description: 'Log', action: onClickLog }] : []),
                    { description: 'Make A Copy of This Session', action: handleMakeCopy },
                ]}
                footer={
                    <SessionAudioPlayer
                        onPlay={() => UserEvents.completedSessionAudioPlayed({ sessionId: session.id })}
                        broadcastIdentifier={session.broadcastIdentifier}
                        errorContext="Ended Session"
                    />
                }
                subActions={[]}
            />
        </div>
    );
};

export default EndedSessionCard;
