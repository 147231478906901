import arrayMove from 'array-move';
import { isNil, isUndefined } from 'lodash';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    ScoreLibrary,
    SessionScore,
    SessionScoreDosage,
    SessionScoreModality,
    SessionScoreTemplate,
    SessionType,
    SessionVariables,
    Wavepath,
    WavepathType,
} from 'wavepaths-shared/core';
import * as scoreUtils from 'wavepaths-shared/domain/scores';
import {
    planSessionFromPreset as planSessionFromTemplate,
    planSessionWavepaths,
    planSessionWavepathsAdjustingDuration,
} from 'wavepaths-shared/domain/sessionPlanner';
import { insertAtIndex, removeAtIndex, replaceAtIndex } from 'wavepaths-shared/util/arrayUtils';

import { ISessionTemplate } from '../../common/api/sessionTemplatesApi';
import { getDefaultSessionDurationForModality } from '../../common/domain/modalitiesOld';
import { getMedicineFromScore } from '../../common/domain/modalitiesOld';
import { getDefaultVariablesForSession } from '../../common/domain/session';

const REQUIRED_VARIABLE_INPUTS = ['sessionUse'];

const mapScoreTemplateToScore = (scoreTemplate: SessionScoreTemplate): SessionScore => ({
    ...scoreTemplate,
    wavepaths: [],
});

export enum Input {
    Medicine,
    Administration,
    Dosage,
    ScoreTemplate,
    Score,
    Duration,
}

// TODO: replace with ReturnType<...>
interface IUseScorePlannerReturn {
    score: SessionScore;
    scoreTemplate?: SessionScoreTemplate;
    sessionVariableInputs: SessionVariables;
    updateVariableInputs: (updates: SessionVariables) => void;
    missingVariableInputs: string[];
    setSessionScorePreset: (scoreTemplate: SessionScoreTemplate) => void;
    updatePathInScore: (index: number, wp: Pick<Wavepath, 'pathId' | 'pathScore' | 'duration'>) => void;
    addPathToScore: (index: number, wp: Pick<Wavepath, 'pathId' | 'pathScore'>) => void;
    removePathFromScore: (waveIndex: number) => void;
    movePathInScore: (index: number, targetIndex: number) => void;
    setMedicine: (medicine: SessionScoreModality) => void;
    medicine: SessionScoreModality;
    dirtyInputs: Input[];
}

const useScorePlanner = (
    scoreLibrary: ScoreLibrary,
    sessionType: SessionType,
    initialSession?: ISessionTemplate,
): IUseScorePlannerReturn => {
    const initialMedicine = initialSession && getMedicineFromScore(initialSession?.score.selectionCriteria);
    const [medicine, _setMedicine] = useState<SessionScoreModality>(initialMedicine ?? SessionScoreModality.NONE);
    const [interactedInputs, setInteractedInputs] = useState<Input[]>([]);
    const isSavedTemplate =
        interactedInputs.includes(Input.Score) || (initialSession && !interactedInputs.includes(Input.ScoreTemplate));

    const initialSessionScoreTemplate =
        initialSession && scoreLibrary.sessionScores.find((score) => score.name === initialSession.score.name);

    const initialScoreTemplate = initialSessionScoreTemplate
        ? initialSessionScoreTemplate
        : scoreUtils.pickFirstScoreForMedicine(scoreLibrary.sessionScores, medicine)!;

    const initialVariables: SessionVariables = initialSession
        ? (({
              ...initialSession.variableInputs,
              name: initialSession.name,
              sessionUse: undefined,
              useEmotionalStateForms: true,
          } as unknown) as SessionVariables)
        : getDefaultVariablesForSession({
              sessionType,
              medicine,
              score: initialScoreTemplate,
          });

    const plannedPaths = useMemo(
        () => planSessionFromTemplate(initialScoreTemplate, scoreLibrary, initialVariables),
        [],
    );
    const scoreFromPresetPlan = initialSession
        ? initialSession.score
        : scoreUtils.setWavepaths(mapScoreTemplateToScore(initialScoreTemplate), plannedPaths);

    const [score, setScore] = useState<SessionScore>(scoreFromPresetPlan);
    const [scoreTemplate, setScoreTemplate] = useState<SessionScoreTemplate>(initialScoreTemplate);
    const [defaultSessionVariableInputs, setDefaultSessionVariableInputs] = useState<SessionVariables>(
        initialVariables,
    );
    const [dirtySessionVariables, setDirtySessionVariables] = useState<SessionVariables>({});

    const getVariableInputs = () => ({
        ...defaultSessionVariableInputs,
        ...dirtySessionVariables,
    });

    const initialiseWithNewScoreTemplate = (
        initialScoreTemplate: SessionScoreTemplate,
        medicine: SessionScoreModality,
        // TODO: remove this hacky solution which was added because we have a launch event very soon
        /*
         * initialiseWithNewScoreTemplate is called when dosage is updated for psilo which unfortunately resets administration
         * we only want to overwrite administration when medicine is changed, not when dosage is changed, so I added this hack
         * because we are running out of time xd
         */
        dontOverwriteAdministration = false,
    ) => {
        const initialVariables = getDefaultVariablesForSession({
            sessionType,
            medicine,
            score: initialScoreTemplate,
        });

        const plannedPaths = planSessionFromTemplate(initialScoreTemplate, scoreLibrary, initialVariables);
        const scoreFromPresetPlan = scoreUtils.setWavepaths(
            mapScoreTemplateToScore(initialScoreTemplate),
            plannedPaths,
        );
        setDefaultSessionVariableInputs(initialVariables);
        setScore(scoreFromPresetPlan);
        setScoreTemplate(initialScoreTemplate);
        setDirtySessionVariables((prev) => ({
            name: prev.name,
            sessionUse: prev.sessionUse,
            ...(!isNil(prev.administration) && dontOverwriteAdministration && { administration: prev.administration }),
        }));
        setInteractedInputs([Input.Medicine]);
    };

    // TODO: Improve Typings here
    const updateVariableInputs = (updates: { [name: string]: number | string }) => {
        if (updates.dosage) {
            const newDosage = updates.dosage as SessionScoreDosage;
            if (!scoreUtils.templateMatchesDosage(newDosage)(scoreTemplate)) {
                const initialScoreTemplate = scoreUtils.pickFirstScoreForMedicineAndDosage(
                    scoreLibrary.sessionScores,
                    medicine,
                    newDosage,
                );
                // TODO: what if there is no score template for this medicine and dosage
                //@ts-ignore
                initialiseWithNewScoreTemplate(initialScoreTemplate, medicine, true);
                return;
            }
        }
        const newDirtySessionVariables = { ...dirtySessionVariables, ...updates };
        const updatedVariables = { ...getVariableInputs(), ...newDirtySessionVariables };

        if (updates.totalDuration) {
            setDirtySessionVariables(newDirtySessionVariables);

            const hasTemplateBeenEdited = isSavedTemplate;

            if (!hasTemplateBeenEdited) {
                const templatePlan = planSessionFromTemplate(scoreTemplate, scoreLibrary, updatedVariables);
                const scoreFromTemplatePlan = scoreUtils.setWavepaths(
                    mapScoreTemplateToScore(scoreTemplate),
                    templatePlan,
                );
                setScore(scoreFromTemplatePlan);
            } else {
                const plannedWavepaths = planSessionWavepaths(
                    score.wavepaths,
                    scoreLibrary,
                    updates.totalDuration as number,
                );

                if (plannedWavepaths) {
                    const scoreFromPresetPlan = scoreUtils.setWavepaths(score, plannedWavepaths);
                    setScore(scoreFromPresetPlan);
                }
            }

            setInteractedInputs((i) => [...i, Input.Duration]);
        }
        if (updates.dosage || updates.administration) {
            if (updatedVariables.dosage && updatedVariables.administration) {
                // if new duration above

                const sessionVariablesWithDurationUpdate = {
                    ...newDirtySessionVariables,
                    totalDuration: getDefaultSessionDurationForModality(medicine, {
                        dosage: Number(updatedVariables.dosage),
                        administration: Number(updatedVariables.administration),
                    }),
                };
                setDirtySessionVariables({ ...newDirtySessionVariables, ...sessionVariablesWithDurationUpdate }); // reset total duration

                const templatePlan = planSessionFromTemplate(
                    scoreTemplate,
                    scoreLibrary,
                    sessionVariablesWithDurationUpdate,
                );
                const scoreFromTemplatePlan = scoreUtils.setWavepaths(
                    mapScoreTemplateToScore(scoreTemplate),
                    templatePlan,
                );
                setScore(scoreFromTemplatePlan);
            }
            if (updatedVariables.administration) {
                setInteractedInputs([Input.Medicine, Input.Administration]);
            } else if (updatedVariables.dosage) {
                setInteractedInputs([Input.Medicine, Input.Administration, Input.Dosage]);
            }
        }

        if (!updates.totalDuration && !updates.dosage && !updates.administration) {
            setDirtySessionVariables(newDirtySessionVariables);
        }
    };

    const updateWavepathsWith = (update: (wavepaths: Wavepath[], index: number, newPath?: Wavepath) => Wavepath[]) => (
        waveIndex: number,
        newPath?: Wavepath,
    ) => {
        const updatedWavepaths = update(score.wavepaths, waveIndex, newPath);

        const { plannedWavepaths, sessionDuration } = planSessionWavepathsAdjustingDuration(
            updatedWavepaths,
            scoreLibrary,
            getVariableInputs().totalDuration as number,
        );

        const updatedScore = scoreUtils.setWavepaths(score, plannedWavepaths);
        setScore(updatedScore);
        setDirtySessionVariables({ ...dirtySessionVariables, totalDuration: sessionDuration });
        setInteractedInputs((i) => [...i, Input.Score]);
    };

    const movePathInScore = (index: number, targetIndex: number) => {
        const moveToIndex = (wavepaths: Wavepath[], waveIndex: number) => arrayMove(wavepaths, waveIndex, targetIndex);
        updateWavepathsWith(moveToIndex)(index);
    };

    const updatePathInScore = (
        waveIndex: number,
        { pathId, pathScore, duration }: Pick<Wavepath, 'pathId' | 'pathScore' | 'duration'>,
    ) => {
        updateWavepathsWith(replaceAtIndex)(waveIndex, {
            id: uuidv4(),
            pathId,
            pathScore,
            duration,
            type: score.wavepaths[waveIndex].type,
        });
    };

    const addPathToScore = (waveIndex: number, { pathId, pathScore }: Pick<Wavepath, 'pathId' | 'pathScore'>) => {
        updateWavepathsWith(insertAtIndex)(waveIndex, {
            id: uuidv4(),
            pathId,
            pathScore,
            type: WavepathType.SCHEDULED,
        });
    };

    const removePathFromScore = updateWavepathsWith(removeAtIndex);

    const setSessionScorePreset = (scoreTemplate: SessionScoreTemplate) => {
        const newDefaultVariables = getDefaultVariablesForSession({
            sessionType,
            medicine,
            score: scoreTemplate,
        });

        // Resets the totalDuration for Ibogaine templates
        // TODO: Remove this when we remove a temporary solution with ibogaine template splitted into two
        ////////////////////
        const newDirtySessionVariables = {
            ...dirtySessionVariables,
            ...(medicine === SessionScoreModality.IBOGAINE &&
                newDefaultVariables.totalDuration && { totalDuration: newDefaultVariables.totalDuration }),
        };
        setDirtySessionVariables(newDirtySessionVariables);
        ////////////////////

        setDefaultSessionVariableInputs(newDefaultVariables);

        const sessionVariableInputs = {
            ...newDefaultVariables,
            ...newDirtySessionVariables,
            //...dirtySessionVariables,
        };

        const newTemplatePlan = planSessionFromTemplate(scoreTemplate, scoreLibrary, sessionVariableInputs);
        const scoreFromTemplatePlan = scoreUtils.setWavepaths(mapScoreTemplateToScore(scoreTemplate), newTemplatePlan);
        setScore(scoreFromTemplatePlan);
        setScoreTemplate(scoreTemplate);
        setInteractedInputs(() => [Input.Medicine, Input.Administration, Input.Dosage, Input.ScoreTemplate]);
    };

    const sessionVariableInputs = getVariableInputs();
    const missingVariableInputs = REQUIRED_VARIABLE_INPUTS.filter((i) => isUndefined(sessionVariableInputs[i]));

    const setMedicine = (medicine: SessionScoreModality) => {
        _setMedicine(medicine);
        const initialScoreTemplate = scoreUtils.pickFirstScoreForMedicine(scoreLibrary.sessionScores, medicine)!;
        initialiseWithNewScoreTemplate(initialScoreTemplate, medicine);
    };

    return {
        score,
        scoreTemplate: isSavedTemplate ? undefined : scoreTemplate,
        sessionVariableInputs,
        updateVariableInputs,
        missingVariableInputs,
        setSessionScorePreset,
        updatePathInScore,
        addPathToScore,
        removePathFromScore,
        movePathInScore,
        setMedicine,
        medicine,
        dirtyInputs: interactedInputs,
    };
};

export default useScorePlanner;
