import styled from '@emotion/styled';
import Card from '@material-ui/core/Card';
import React from 'react';
import { CoreEmotionalAtmosphere, PathScore, SessionScore, SessionType } from 'wavepaths-shared/core';
import { PathType, Wavepath } from 'wavepaths-shared/core';

import { Button, EvaIcon } from '@/component-library';
import { Typography } from '@/component-library';

import { FeedbackButtons, FeedbackButtonsProps } from '../../../common/components/notifications/FeedbackButtons';
import { Connection, useElapsedTimeSecs } from '../../../common/hooks/useSessionTick';
import { useCEA } from '../ceaButtons/useCEA';
import InstrumentRefreshButton from '../InstrumentRefresh/InstrumentRefreshButton';
import { UseInstrumentRefreshR } from '../InstrumentRefresh/useInstrumentRefresh';
import { WaveEndCountdownV2 } from '../WaveEndCountdownV2';
import StatusBar from './StatusBar/StatusBar';

export type CurrentWaveCardProps = {
    setSnackBarContent: (content: string | null) => void;
    initialised: boolean;
    connection: Connection;
    currentWave: Wavepath | null;
    nextWave?: Wavepath;
    session: { type: SessionType; score: SessionScore } | undefined;
    instrumentRefreshArgs: UseInstrumentRefreshR;
    isExpanded: boolean;
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    disableExpansion: boolean;
    isInIntroSession2?: boolean;
    onSkipWave?: () => void;
} & FeedbackButtonsProps;

const Container = styled.div({
    position: 'relative',
    width: '100%',
});

const StyledCard = styled(Card)<{ isInIntroSession2?: boolean }>(({ isInIntroSession2 }) => ({
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: 'repeat(3, min-content)',
    background: 'rgba(255,255,255,0.9)',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    marginBottom: isInIntroSession2 ? 32 : 0,
}));

const CardContent = styled.div({
    padding: '12px 16px 12px 24px',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr min-content',
    alignItems: 'start',
});

const Buttons = styled.div({
    display: 'inline-grid',
    gridAutoFlow: 'column',
});

const WaveInfo = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
    justifyItems: 'start',
    alignItems: 'end',
    gridTemplateColumns: 'min-content 1fr',
    gap: 16,
    paddingTop: 8,
    paddingRight: 24,
});

const WaveInfoText = styled(Typography)({
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#2C3958',
    lineHeight: 1,
    paddingBottom: 3,
});

const Divider = styled.div({
    height: 1,
    background: '#D3D9E9',
    margin: '0 24px',
});

const QueueToggle = styled(Button)({
    position: 'absolute',
    top: 0,
    right: -48,
    padding: 4,
});

const formatPathName = (path: PathScore, targetCEA?: CoreEmotionalAtmosphere) => {
    const pathIdsWithDifferentNaming = [
        'silence',
        's_cea1_a_calm_river',
        's_cea1_a_calm_waves',
        's_cea1_a_forest_birds',
    ];
    const shouldShowIntensity =
        path.selectionCriteria?.emotionalIntensity && path.type === PathType.GENERATIVE && path.id !== 'silence';
    const shouldUseTargetCea = targetCEA && !pathIdsWithDifferentNaming.includes(path.id);

    const pathName =
        (shouldShowIntensity ? ` ${path.selectionCriteria?.emotionalIntensity} intensity ` : '') +
        (shouldUseTargetCea ? targetCEA : path.name) +
        ' wave';
    return pathName;
};

const truncatePathName = (pathName: string): string => {
    if (pathName.length > 40) {
        return `${pathName.slice(0, 40)}...`;
    } else {
        return pathName;
    }
};

const Body = ({
    connection,
    isPreludeOrPostlude,
    currentWave,
    isLoading,
    onSkipWave,
}: {
    connection: Connection;
    isPreludeOrPostlude: boolean;
    currentWave: Wavepath | null;
    isLoading: boolean;
    onSkipWave?: () => void;
}) => {
    const ceaState = useCEA(connection, () => {
        //do nothing
    });
    const targetCea = ceaState !== 'loading' ? ceaState.targetCea : undefined;

    const shouldExtensionBeDisabled = !currentWave || currentWave.pathScore.type !== PathType.GENERATIVE;

    if (isLoading || !currentWave) {
        return (
            <WaveInfo>
                <WaveInfoText variant={'h6'}>{'Loading session...'}</WaveInfoText>
            </WaveInfo>
        );
    } else if (isPreludeOrPostlude) {
        return (
            <WaveInfo>
                <WaveInfoText variant={'h6'}>{currentWave.pathScore.name}</WaveInfoText>
            </WaveInfo>
        );
    } else if (!isPreludeOrPostlude && currentWave.pathScore.type !== PathType.GENERATIVE) {
        return (
            <WaveInfo>
                <WaveEndCountdownV2
                    wave={currentWave}
                    connection={connection}
                    shouldExtensionBeDisabled={shouldExtensionBeDisabled}
                />
                <WaveInfoText variant={'body2'}>{currentWave.pathScore.name}</WaveInfoText>
            </WaveInfo>
        );
    } else {
        return (
            <WaveInfo aria-label={`Current wave: ${formatPathName(currentWave.pathScore, targetCea)}`}>
                {currentWave.pathScore.type === PathType.EXPERIMENTAL && (
                    <WaveInfoText variant={'h6'} data-test-id="currentWave">
                        {truncatePathName(formatPathName(currentWave.pathScore, targetCea))}
                    </WaveInfoText>
                )}
                <WaveEndCountdownV2
                    wave={currentWave}
                    connection={connection}
                    onSkipWave={onSkipWave}
                    shouldExtensionBeDisabled={shouldExtensionBeDisabled}
                />
            </WaveInfo>
        );
    }
};

export default function CurrentWaveCard({
    setSnackBarContent,
    initialised,
    connection,
    currentWave,
    nextWave,
    activeFeedbackType,
    onLogFeedback,
    onSkipWave,
    instrumentRefreshArgs,
    isExpanded,
    setIsExpanded,
    disableExpansion,
    isInIntroSession2 = false,
}: CurrentWaveCardProps): React.ReactElement {
    const isPrelude = currentWave?.type === 'pre';
    const isPostlude = currentWave?.type === 'post';
    const isPreludeOrPostlude = isPrelude || isPostlude;
    const isLoading = !initialised || !currentWave;
    const elapsedTimeSecs = useElapsedTimeSecs(connection);

    return (
        <Container>
            <StyledCard isInIntroSession2={isInIntroSession2}>
                <CardContent>
                    <Body
                        currentWave={currentWave}
                        connection={connection}
                        isLoading={isLoading}
                        isPreludeOrPostlude={isPreludeOrPostlude}
                        onSkipWave={onSkipWave && (() => onSkipWave())}
                    />
                    <Buttons>
                        <InstrumentRefreshButton instrumentRefreshArgs={instrumentRefreshArgs} />
                        <FeedbackButtons activeFeedbackType={activeFeedbackType} onLogFeedback={onLogFeedback} />
                    </Buttons>
                </CardContent>
                <Divider />
                <StatusBar
                    setSnackbarContent={setSnackBarContent}
                    connection={connection}
                    currentWave={currentWave}
                    currentTimeSecs={elapsedTimeSecs}
                    nextWave={nextWave}
                />
            </StyledCard>
            <>
                {disableExpansion ? null : (
                    <QueueToggle
                        aria-label="Expand intensity slider"
                        onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
                        variant="solid-light"
                        disabled={disableExpansion}
                        className="tour-toggleWaveQueue"
                    >
                        <EvaIcon
                            name="chevron-up"
                            size={24}
                            iconStyle={{
                                transform: isExpanded ? 'rotate(180deg)' : undefined,
                                transition: 'transform 0.3s ease',
                            }}
                        />
                    </QueueToggle>
                )}
            </>
        </Container>
    );
}
