import firebase from 'firebase';
import { useEffect } from 'react';
import useSWRInfinite from 'swr/infinite';

import { FilterCriteria, getScoreTemplates, IListScoreTemplatesReturn } from '../api/contentApi';

export interface IUseTemplatesProps {
    fbUser?: firebase.User;
    criteria?: FilterCriteria;
    itemsPerPage?: number;
}

function useScoreTemplates({ fbUser, criteria, itemsPerPage = 4 }: IUseTemplatesProps) {
    const { data, setSize, size, isValidating } = useSWRInfinite<IListScoreTemplatesReturn, Error>(
        (pageIndex) =>
            fbUser
                ? [
                      `/score-templates/${pageIndex}${itemsPerPage}${criteria}`,
                      pageIndex,
                      itemsPerPage,
                      JSON.stringify(criteria),
                  ]
                : null,
        fbUser
            ? ([, pageIndex]: [string, number]) =>
                  getScoreTemplates({ fbUser, criteria, pagination: { limit: itemsPerPage, offset: pageIndex } })
            : null,
        {
            compare: (a, b) => {
                return JSON.stringify(a) == JSON.stringify(b);
            },
            revalidateOnFocus: false,
        },
    );

    const pageIndex = size;
    const totalCount = data?.[0].totalCount ?? 0;

    const totalPages = Math.ceil(Math.max(totalCount - 1, 0) / itemsPerPage);

    const remainingPages = totalPages - pageIndex;

    useEffect(() => {
        setSize(1);
    }, [JSON.stringify(criteria)]);

    return {
        templates: data?.flatMap(({ entries }) => entries),
        remainingPages,
        isLoading: isValidating,
        loadNext: () => {
            console.log('loading next');
            setSize((size) => size + 1);
        },
    };
}

export default useScoreTemplates;
