import { millisecondsToSeconds } from 'date-fns';
import { isEqual, noop, pick } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { SessionRequestProperties, SessionTick, Wavepath } from 'wavepaths-shared/core';
import { getCurrentWaveIndex } from 'wavepaths-shared/domain/session';

export function useSessionTick(connection: Pick<Connection, 'on' | 'off'>): SessionTick | undefined {
    const existingConnection = !!connection;
    const [tick, setTick] = useState<SessionTick>();
    useEffect(() => {
        return subscribeTick(connection, setTick);
    }, [existingConnection]);
    return tick;
}

export function useElapsedTimeSecs(connection: Pick<Connection, 'on' | 'off'>): number {
    const tick = useSessionTick(connection);

    const elapsedTime = useMemo(() => {
        return tick?.absoluteTime ? Math.floor(millisecondsToSeconds(tick.absoluteTime)) : 0;
    }, [tick?.absoluteTime]);
    return elapsedTime;
}

type SessionDurations = { preludeDuration: number; sessionDuration: number; postludeDuration: number };
export function useSessionDurations(connection?: Connection, zeroTick?: SessionTick) {
    const [durations, setDurations] = useState<SessionDurations>({
        preludeDuration: -1,
        sessionDuration: -1,
        postludeDuration: -1,
    });
    useEffect(() => {
        if (connection) {
            return subscribeTick(connection, (tick) => {
                const newDurations = pick(tick, 'preludeDuration', 'sessionDuration', 'postludeDuration');
                if (!isEqual(newDurations, durations)) {
                    setDurations(newDurations);
                }
            });
        }
    }, [connection, durations]);
    useEffect(() => {
        if (!connection && zeroTick) {
            setDurations(pick(zeroTick, 'preludeDuration', 'sessionDuration', 'postludeDuration'));
        }
    }, [connection, zeroTick]);
    return durations;
}

type SessionState = {
    initialised: boolean;
    started: boolean;
    inPostlude: boolean;
    ended: boolean;
};

export function useSessionState(connection: Connection, zeroTick?: SessionTick): SessionState {
    const [state, setState] = useState<SessionState>({
        initialised: false,
        started: false,
        inPostlude: false,
        ended: false,
    });
    useEffect(() => {
        if (connection) {
            return subscribeTick(connection, (tick) => {
                const nowInitialised = tick.timeSinceInit >= 0;
                const nowStarted = tick.absoluteTime > 0;
                const nowInPostlude = tick.absoluteTime > tick.sessionDuration;
                const nowEnded = tick.absoluteTime > tick.sessionDuration + tick.postludeDuration;
                setState((oldState) => {
                    if (
                        nowInitialised !== oldState.initialised ||
                        nowStarted !== oldState.started ||
                        nowInPostlude !== oldState.inPostlude ||
                        nowEnded !== oldState.ended
                    ) {
                        return {
                            initialised: nowInitialised,
                            started: nowStarted,
                            inPostlude: nowInPostlude,
                            ended: nowEnded,
                        };
                    } else {
                        return oldState;
                    }
                });
            });
        }
    }, [connection]);
    useEffect(() => {
        if (!connection && zeroTick) {
            setState({
                initialised: zeroTick.timeSinceInit >= 0,
                started: zeroTick.absoluteTime >= 0,
                inPostlude: zeroTick.absoluteTime > zeroTick.sessionDuration,
                ended: zeroTick.absoluteTime > zeroTick.sessionDuration + zeroTick.postludeDuration,
            });
        }
    }, [connection, zeroTick]);
    return state;
}

export interface Connection {
    on: (event: string | symbol, listener: (...args: any[]) => void) => void;
    off: (event: string | symbol, listener: (...args: any[]) => void) => void;
    sendRequest: (reqProps: SessionRequestProperties) => void;
    audioAnalyserLeft?: AnalyserNode | null;
    audioAnalyserRight?: AnalyserNode | null;
}

type CurrentWave = { wave: Wavepath | null; index: number };
export function useCurrentWave(
    connection: Connection | undefined,
    wavepaths: Wavepath[] | null,
): { wave: Wavepath | null; index: number } {
    const [currentWave, setCurrentWave] = useState<CurrentWave>({ wave: null, index: -1 });
    useEffect(() => {
        return subscribeTick(connection, (tick) => {
            if (wavepaths) {
                const idx = getCurrentWaveIndex(wavepaths, tick);
                if (currentWave?.index !== idx || currentWave?.wave !== wavepaths[idx]) {
                    setCurrentWave({ index: idx, wave: wavepaths[idx] });
                }
            }
        });
    }, [connection, wavepaths, currentWave]);
    return currentWave;
}

export function subscribeTick(
    connection?: Pick<Connection, 'on' | 'off'>,
    callback?: (tick: SessionTick) => void,
): () => void {
    if (!connection || !callback) return noop;
    connection.on('tick', callback);
    return () => {
        connection.off('tick', callback);
    };
}
