import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { SvgIcon } from '@material-ui/core';
import React from 'react';

const LoaderIcon = (props: any) => (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C11.448 2 11 2.447 11 3V5C11 5.553 11.448 6 12 6C12.552 6 13 5.553 13 5V3C13 2.447 12.552 2 12 2Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 11H19C18.448 11 18 11.447 18 12C18 12.553 18.448 13 19 13H21C21.552 13 22 12.553 22 12C22 11.447 21.552 11 21 11Z"
            fill="white"
            fillOpacity="0.8"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 12C6 11.447 5.552 11 5 11H3C2.448 11 2 11.447 2 12C2 12.553 2.448 13 3 13H5C5.552 13 6 12.553 6 12Z"
            fill="white"
            fillOpacity="0.4"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.2209 5.02822C5.8239 4.64422 5.1919 4.65722 4.8069 5.05322C4.4229 5.45022 4.4339 6.08422 4.8309 6.46722L6.2699 7.85722C6.4649 8.04522 6.7149 8.13722 6.9649 8.13722C7.2269 8.13722 7.4879 8.03522 7.6839 7.83222C8.0679 7.43522 8.0569 6.80122 7.6599 6.41822L6.2209 5.02822Z"
            fill="white"
            fillOpacity="0.3"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.0352 8.13722C17.2852 8.13722 17.5352 8.04422 17.7302 7.85722L19.1692 6.46722C19.5662 6.08322 19.5772 5.44922 19.1932 5.05322C18.8102 4.65722 18.1782 4.64422 17.7792 5.02822L16.3402 6.41722C15.9432 6.80122 15.9322 7.43522 16.3162 7.83122C16.5122 8.03422 16.7732 8.13722 17.0352 8.13722Z"
            fill="white"
            fillOpacity="0.9"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 18C11.448 18 11 18.447 11 19V21C11 21.553 11.448 22 12 22C12.552 22 13 21.553 13 21V19C13 18.447 12.552 18 12 18Z"
            fill="white"
            fillOpacity="0.6"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.7302 16.1431C17.3332 15.7601 16.7002 15.7721 16.3162 16.1681C15.9322 16.5651 15.9432 17.1991 16.3402 17.5821L17.7792 18.9721C17.9742 19.1601 18.2242 19.2521 18.4742 19.2521C18.7362 19.2521 18.9972 19.1501 19.1932 18.9471C19.5772 18.5501 19.5662 17.9161 19.1692 17.5331L17.7302 16.1431Z"
            fill="white"
            fillOpacity="0.7"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.2699 16.1431L4.8309 17.5331C4.4339 17.9161 4.4229 18.5501 4.8069 18.9471C5.0029 19.1501 5.2639 19.2521 5.5259 19.2521C5.7759 19.2521 6.0259 19.1601 6.2209 18.9721L7.6599 17.5821C8.0569 17.1991 8.0679 16.5651 7.6839 16.1681C7.3009 15.7721 6.6679 15.7601 6.2699 16.1431Z"
            fill="white"
            fillOpacity="0.5"
        />
    </SvgIcon>
);

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StyledLoaderIcon = styled(LoaderIcon)({
    animation: `${rotate} 2s linear infinite`,
});

const AnimatedLoaderIcon = (props: any) => <StyledLoaderIcon {...props} />;

export default AnimatedLoaderIcon;
