// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/chevron-right.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../images/arrrow-left.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".sessionsv2{position:relative;min-height:100%;display:grid;grid-auto-flow:row;align-items:start;padding:0 20px 80px 20px;max-width:1024px;margin:auto}.sessionsv2--primaryActions button{margin-bottom:4px;margin-top:4px;margin-right:10px}.sessionsv2--primaryActions button:last-of-type{margin-right:0px}.pageControls{display:flex;flex-direction:row;justify-content:space-between;align-items:center;width:100px;margin-left:12px}.nextPageButton{border:none;outline:none;height:40px;width:40px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:auto;background-position:center}.lastPageButton{border:none;outline:none;height:40px;width:40px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:auto;background-position:center}.errorBox{position:absolute;left:50%;transform:translate(-50%, -50%);top:63px}", ""]);
// Exports
module.exports = exports;
