import { useCallback, useEffect, useState } from 'react';
import { CoreEmotionalAtmosphere, RequestType, SessionEventType } from 'wavepaths-shared/core';

import { Connection, subscribeTick } from '../../../common/hooks/useSessionTick';
import { HLS_BUFFER_LATENCY } from '../../../freudConnection/hlsUtils';
import UserEvents from '../../../UserEvents';
import { Queueable } from '../actionQueue/useActionQueue';

export function useCEA(
    connection: Connection,
    queueFunction: (queueable: Queueable) => void,
):
    | {
          targetCea?: CoreEmotionalAtmosphere;
          transitionTimeSecs?: number;
          setTargetCea: (cea: CoreEmotionalAtmosphere) => void;
          error: string | null;
      }
    | 'loading' {
    const existingConnection = !!connection;
    const [_targetCea, _setTargetCea] = useState<CoreEmotionalAtmosphere | undefined>(undefined);
    const [_ceaChangeError, _setCeaChangeError] = useState<string | null>(null);

    const [transitionTimeSecs, setTransitionTimeSecs] = useState<number | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const setTargetCea = useCallback(
        (cea: CoreEmotionalAtmosphere, whenQueued?: () => void) => {
            _setCeaChangeError(null);
            if (_targetCea !== cea) {
                queueFunction({
                    description: `Transitioning to ${cea}`,
                    callback: () => {
                        connection.sendRequest({ type: RequestType.MoveToCea, cea });
                        whenQueued && whenQueued();
                    },
                    onCancel: () => UserEvents.ceaSelectionCanceled(cea),
                    onSkipQueue: () => UserEvents.ceaSelectionSkippedQueue(cea),
                });
                UserEvents.ceaSelected(cea);
            }
        },
        [connection, queueFunction, _targetCea],
    );

    useEffect(() => {
        return subscribeTick(connection, (tick) => {
            if (tick.musicalContent?.targetCea !== _targetCea) {
                _setTargetCea(tick.musicalContent?.targetCea);
            }
            if (tick.musicalContent?.transitionTimeSecs) {
                const latencyCompensated = tick.musicalContent.transitionTimeSecs + HLS_BUFFER_LATENCY / 1000;
                if (latencyCompensated !== transitionTimeSecs) {
                    setTransitionTimeSecs(latencyCompensated);
                }
            }
            if (isLoading) setIsLoading(false);
        });
    }, [existingConnection, connection, _targetCea, isLoading, transitionTimeSecs]);

    useEffect(() => {
        connection.on(SessionEventType.MoveToCeaRejected, () => {
            _setCeaChangeError(SessionEventType.MoveToCeaRejected);
        });
    }, [connection]);

    if (isLoading) return 'loading';

    return {
        targetCea: _targetCea,
        transitionTimeSecs,
        setTargetCea,
        error: _ceaChangeError,
    };
}
