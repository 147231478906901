import { FormElementType, FormResponse, FormResponseElement } from '../../../formUtils/formTypes';

export enum ResponseStatus {
    noForm = 'no integration form',
    awaitingResponse = 'awaiting response',
    inProgress = 'in progress',
    completed = 'completed',
}

const NON_RESPONSE_ELEMENTS = [FormElementType.HEADER, FormElementType.DESCRIPTION, FormElementType.SESSION_AUDIO];

const evaluateIntegrationFormStatus = (formResponses: FormResponse[]): ResponseStatus => {
    if (formResponses.length === 0) {
        return ResponseStatus.noForm;
    }

    if (formResponses.length === 1) {
        const response = formResponses[0];
        const questions = response.elements.filter((element) => !NON_RESPONSE_ELEMENTS.includes(element.type));

        const isUnanswered = (q: FormResponseElement) => q.value === undefined;

        if (questions.every(isUnanswered)) {
            return ResponseStatus.awaitingResponse;
        }

        if (questions.some(isUnanswered)) {
            return ResponseStatus.inProgress;
        }

        return ResponseStatus.completed;
    }

    throw new Error('more than one form response is currently not supported here');
};

export default evaluateIntegrationFormStatus;
