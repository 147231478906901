import React from 'react';

import { Typography } from '@/component-library';

export const funktion = {
    title: 'Function',
    content: (
        <>
            <Typography variant="body2" color="textSecondary">
                Here you can select the main therapeutic function for the music of this specific wave: Silence, Soothe,
                Deepen or Bridge. The Holding and Releasing functions are accessible using the intensity slider, during
                Soothe or Deepen waves. You can find more detailed descriptions of the therapeutic functions{' '}
                <a rel="noreferrer" target="_blank" href="https://community.wavepaths.com/courses/6881463/content">
                    here
                </a>
                .
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
                Soothe
            </Typography>
            <Typography variant="body2" color="textSecondary">
                This function is primarily concerned with providing a soothing, calming and reassuring climate. Soothing
                music is not emotionally or physiologically activating, instead it reduces the intensity of the
                respective subjective experience.
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
                Deepen
            </Typography>
            <Typography variant="body2" color="textSecondary">
                This function is primarily concerned with intensifying subjective experiences, and with bringing
                feelings, psychological processes and memories more strongly into awareness, and for them to be more
                deeply experienced and expressed. Deepening music is emotionally or physiologically activating, demands
                more of the listener’s attention, and guides the attention towards a narrower range of internal states.
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
                Bridge
            </Typography>
            <Typography variant="body2" color="textSecondary">
                Rather than staying within one emotionality, this music is characterised by dynamically moving from one
                subjective experience to another. Bridges are designed to gently guide the experience of your client
                into a different direction, and facilitate experiences of change and movement.
            </Typography>
        </>
    ),
};

export const sootheMode = {
    title: 'Mode',
    content: (
        <>
            <Typography variant="body2" color="textSecondary">
                The <b>Unstructured</b> mode for Soothe creates loosely structured musical compositions (i.e. no clear
                bpm or "pulse"), whereas the <b>Structured</b> mode is characterised by a clear structure, yet
                maintaining a Soothing characteristic without becoming emotionally activating. We recommend using
                Unstructured as a default setting when using the Soothe function, using Structured when the client is in
                need of more containment, grounding and continuity in their experience. Note: Whether using Unstructured
                or Structured Soothe this music will always be soothing and calming. For playing structured, percussive
                based music that is activating, we recommend Deepen Perussive (see below)
            </Typography>
        </>
    ),
};

export const deepenMode = {
    title: 'Mode',
    content: (
        <>
            <Typography variant="body2" color="textSecondary">
                The <b>Tonal</b> mode for Deepen is characterised by a focus on rich melodic and harmonic musical
                progressions. The <b>Percussive</b> mode for Deepen is characterised by a focus on percussive
                instruments. It features consistent rhythms that can move from low to higher levels of intensity.
            </Typography>
        </>
    ),
};

export const emotion = {
    title: 'Emotionality',
    content: (
        <>
            <Typography variant="subtitle2" color="textPrimary">
                Stillness
            </Typography>
            <Typography variant="body2" color="textSecondary">
                This music helps the listener connect with and express feelings of stillness and peacefulness. The
                atmosphere is calming, gentle, loving, quiet with an absence of tension and supports mindfulness and
                deep meditation.
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
                Bittersweet
            </Typography>
            <Typography variant="body2" color="textSecondary">
                This music helps the listener connect with and express feelings of sadness, pain, loss, and grief. The
                atmosphere is warm, moving, hopeful, compassionate and bittersweet. In the higher intensity levels
                Bittersweet is likely to evoke strong emotional processes in the listener.
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
                Vitality
            </Typography>
            <Typography variant="body2" color="textSecondary">
                This music helps the listener connect with and express feelings of joy and vitality and at the higher
                intensity levels feelings of ecstasy. The atmosphere is gentle, content, grateful and fulfilled with a
                sense of warmth, friendliness, and human connectedness.
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
                Tension
            </Typography>
            <Typography variant="body2" color="textSecondary">
                This music helps the listener connect with and express feelings of tension, fear, conflict and anger.
                The atmosphere is tense, defiant and provides an opportunity to acknowledge and express feelings of
                frustration, anger, fear and at the highest intensity levels, aggression.
            </Typography>
        </>
    ),
};

export const intensity = {
    title: 'Intensity',
    content: (
        <>
            <Typography variant="body2" color="textSecondary">
                Here you can select the desired general emotional intensity of this specific wave.
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
                <b>Acoustic</b> will prioritise instruments such as wind, brass and strings, tuned percussion, as well
                as human voice.
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
                <b>Electronic</b> will prioritise electronic instruments such as synthesisers.
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
                <b>Mixed acoustic/electronic</b> includes both of the above, as well as acoustic instruments that have
                been processed electronically.
            </Typography>
        </>
    ),
};

export const tone = {
    title: 'Tone',
    content: (
        <>
            <Typography variant="body2" color="textSecondary">
                Here you can select the dominant tone colour (or “timbre”) of the music created for this specific wave.
            </Typography>
        </>
    ),
};

export const composition = {
    title: 'Composition',
    content: (
        <>
            <Typography variant="body2" color="textSecondary">
                Here you can select the specific music played for this wave.
            </Typography>
        </>
    ),
};

export const ambience = {
    title: 'Ambience',
    content: (
        <>
            <Typography variant="body2" color="textSecondary">
                When using the Soothe function, you can decide to overlay nature sounds of choice. We recommend using
                this selectively, and have None as the default for most waves within one session.
            </Typography>
        </>
    ),
};

export const duration = {
    title: 'Duration',
    content: (
        <>
            <Typography variant="body2" color="textSecondary">
                Here you can select the desired length in minutes of this specific waves. For soothe and deepen waves,
                you can extend or shorten the duration of the wave at wish within the session, while the wave is
                playing.
            </Typography>
        </>
    ),
};

export const emotionFrom = {
    title: 'Emotionality - From',
    content: (
        <>
            <Typography variant="body2" color="textSecondary">
                The emotionality the bridge will start from
            </Typography>
        </>
    ),
};

export const emotionTo = {
    title: 'Emotionality - To',
    content: (
        <>
            <Typography variant="body2" color="textSecondary">
                The emotionality the bridge will move to
            </Typography>
        </>
    ),
};

export const music = {
    title: 'Music',
    content: (
        <>
            <Typography variant="body2" color="textSecondary">
                Select a specific piece of bridge music
            </Typography>
        </>
    ),
};
