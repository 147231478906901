import { useEffect, useState } from 'react';

import UserEvents from '../../UserEvents';

export const getIsOnline = (): boolean | null => {
    if (typeof window === 'undefined') {
        return null;
    }
    return navigator.onLine;
};

const useIsOnline = (): boolean | null => {
    const initialStatus = getIsOnline();
    const [onlineStatus, setOnlineStatus] = useState<boolean | null>(initialStatus);

    useEffect(() => {
        const handleOffline = () => {
            setOnlineStatus(false);
            UserEvents.goingOffline();
        };
        const handleOnline = () => {
            setOnlineStatus(true);
        };
        if (typeof window !== 'undefined') {
            window.addEventListener('offline', handleOffline);
            window.addEventListener('online', handleOnline);

            return () => {
                window.removeEventListener('offline', handleOffline);
                window.removeEventListener('online', handleOnline);
            };
        }
    }, []);
    return onlineStatus;
};

export default useIsOnline;
