import styled from '@emotion/styled';
import React, { ReactNode, useState } from 'react';

import Typography from '../../typography/Typography';
import EvaIcon from '../EvaIcon';
import InfoDialog from '../InfoDialog';

const HeaderStyled = styled.div<{ inline: boolean }>(({ inline }) => ({
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'start',
    gap: 4,
    textAlign: 'left',
    minWidth: inline ? 104 : undefined,
}));

const HeadingStyled = styled(Typography)<{ colour: string }>(({ colour }) => ({
    color: colour === 'light' ? '#FFFFFF' : '#2C3958',
}));

export interface HeaderProps {
    heading?: string;
    info?: { content: ReactNode; title: string };
    canSave: boolean;
    id?: string;
    inline: boolean;
    size: 'small' | 'large';
    colour: 'dark' | 'light';
}

const Heading: React.FunctionComponent<HeaderProps> = ({
    colour,
    heading,
    inline,
    info,
    size,
    canSave,
    id,
}: HeaderProps): JSX.Element => {
    const [infoOpen, setInfoOpen] = useState(false);

    return (
        <>
            {info && (
                <InfoDialog title={info.title} open={infoOpen} onClose={() => setInfoOpen(false)}>
                    {info.content}
                </InfoDialog>
            )}
            <HeaderStyled onClick={() => setInfoOpen(true)} inline={inline}>
                {!!info && size === 'small' && <EvaIcon size={20} evaSize={20} name="info-outline" fill="#6980B4" />}
                {canSave && <EvaIcon size={20} evaSize={20} name="star-outline" fill="#6980B4" />}
                <HeadingStyled id={id} colour={colour} variant="body2">
                    {heading}
                </HeadingStyled>
                {!!info && size === 'large' && <EvaIcon size={20} evaSize={20} name="info-outline" fill="#6980B4" />}
            </HeaderStyled>
        </>
    );
};

export default Heading;
