import './NotificationToast.scss';

import classNames from 'classnames';
import React from 'react';

import { NotificationHeader } from './NotificationHeader';

interface NotificationToastProps {
    content: string;
    className?: string;
}
export const NotificationToast: React.FC<NotificationToastProps> = ({ content, className }) => {
    return (
        <div className={classNames('notificationToast', className)}>
            <NotificationHeader content={content} />
        </div>
    );
};
