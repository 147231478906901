import React, { useEffect, useState } from 'react';
import Stripe from 'stripe';
import { UserData } from 'wavepaths-shared/core';

import WarningBanner from './common/components/WarningBanner';
import { SubscriptionsState } from './common/hooks/useSubscriptions';
import { createCustomerPortal } from './common/util/stripeUtil';
// import { useLogger } from 'react-use';

interface ISubscriptionsWarnings {
    subscriptionsState: SubscriptionsState;
    userData: UserData;
}

const SubscriptionsWarnings = ({ subscriptionsState, userData }: ISubscriptionsWarnings) => {
    const [subscriptionError, setSubscriptionError] = useState<string | undefined>();
    const [customerPortalUrl, setCustomerPortalUrl] = useState<string | undefined>();

    useEffect(() => {
        if (!subscriptionsState.loading && subscriptionsState.subscriptions) {
            setSubscriptionError(getSubscriptionError(subscriptionsState.subscriptions));
        }
    }, [subscriptionsState]);

    useEffect(() => {
        if (subscriptionError) {
            const getCustomerPortalUrl = async () => {
                const response = await createCustomerPortal({
                    uid: userData.uid,
                });
                console.log(response);
                const customerPortalUrl = response ? response.url : undefined;
                setCustomerPortalUrl(customerPortalUrl);
            };
            getCustomerPortalUrl();
        }
    }, [subscriptionError]);

    // useLogger('Warnings', { subscriptionError, customerPortalUrl });

    if (subscriptionError && customerPortalUrl) {
        return (
            <div>
                <WarningBanner kind="childComponent">
                    <p style={{ color: 'white' }}>
                        {subscriptionError}{' '}
                        <a style={{ color: 'white', fontWeight: 'bold' }} href={customerPortalUrl}>
                            Press here
                        </a>{' '}
                        to manage subscriptions or{' '}
                        <a style={{ color: 'white', fontWeight: 'bold' }} href="mailto:support@wavepaths.com">
                            contact us
                        </a>{' '}
                        for support
                    </p>
                </WarningBanner>
            </div>
        );
    } else {
        return <div />;
    }
};

export default SubscriptionsWarnings;

const getSubscriptionError = (subscriptions: Stripe.Subscription[]): string | undefined => {
    const activeOrTrialingSubscriptions = subscriptions.filter(
        (sub) => sub.status === 'active' || sub.status === 'trialing' || sub.status === 'past_due',
    );
    if (activeOrTrialingSubscriptions.length > 1) {
        return 'It seems that you have multiple active subscriptions. This is not currently supported.';
    } else if (activeOrTrialingSubscriptions.length === 1) {
        const activeSubscription = activeOrTrialingSubscriptions[0];

        if (activeSubscription.status === 'past_due') {
            return 'Your last payment failed.';
        }

        if (activeSubscription.cancel_at_period_end) {
            // Below block is commented out due to a bug causing a negative number of remaining days to display.
            // TODO: Investigate the bug and fix it https://app.clickup.com/t/c4w6cr

            // const cancelData = new Date(activeSubscription.cancel_at);
            // const remainingDays = differenceInDays(cancelData, new Date());
            // if (remainingDays <= 7) return `Your subscription will not renew at the end of billing period and will be cancelled in ${remainingDays} days`;

            return `Your subscription will not renew at the end of billing period and will be cancelled soon`;
        }
    }
};
