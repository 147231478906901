import styled from '@emotion/styled';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Typography } from '@/component-library';

const Container = styled.div({
    display: 'grid',
    gridAutoFlow: 'row',
    gap: '16px',
    padding: '24px 0 24px 0',
});

const CenteredTypography = styled(Typography)({
    textAlign: 'center',
    color: '#2c3958',
});

const NewUsers = (): JSX.Element => {
    const history = useHistory();

    const goToSignUp = () => {
        history.push('/signup');
    };

    return (
        <Container>
            <CenteredTypography variant="body2">
                Create a Wavepaths account and begin creating person-centred music now.
            </CenteredTypography>
            <Button variant="solid-blue" size="m" onClick={goToSignUp}>
                Sign up
            </Button>
        </Container>
    );
};

export default NewUsers;
