import firebase from 'firebase';
import useSWR from 'swr';
import { ScoreLibrary as CoreScoreLibrary, SessionScoreDTO } from 'wavepaths-shared/core';

import * as api from '../../common/api/sessionApi';
import { loadIntroScores } from './introSessionsV2';

export type ScoreLibrary =
    | {
          introScores: SessionScoreDTO[];
          loading: true;
      }
    | ({
          introScores: SessionScoreDTO[];
          loading: false;
      } & CoreScoreLibrary);

export default function useScoreLibrary(fbUser?: firebase.User): ScoreLibrary {
    const { data } = useSWR<CoreScoreLibrary | undefined, string | undefined>(
        !!fbUser ? [`/scores`, fbUser] : null,
        ([, fbUser]: [any, firebase.User]) => api.loadScoreLibrary(fbUser),
        { dedupingInterval: 30 * 60 * 1000 },
    );

    const introScores = loadIntroScores();

    if (data) {
        return {
            introScores,
            loading: false,
            ...data,
        };
    }
    return {
        introScores,
        loading: true,
    };
}
