import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import LoadingOrb from '@/components/LoadingOrb';
import useMySubscription from '@/hooks/useMySubscription';

import { useAuthContext } from './auth';
import events from './UserEvents';

export function PrivateRoute({ children, ...rest }: RouteProps) {
    const { userData, isLoaded } = useAuthContext();

    const { isLoading: subscriptionLoading } = useMySubscription();

    useEffect(
        function clearLoadingState() {
            if (isLoaded && !subscriptionLoading) document.getElementById('loadingscreen')?.remove();
        },
        [isLoaded, subscriptionLoading],
    );

    useEffect(() => {
        if (userData) {
            events.identify(userData);
        }
    }, [userData]);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isLoaded && !subscriptionLoading ? (
                    userData ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: location },
                            }}
                        />
                    )
                ) : (
                    <LoadingOrb />
                )
            }
        />
    );
}
