import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';

import { InfoDialog, Typography } from '@/component-library';

import configs from '../../../configs';
import HelpSectionButton from './HelpSectionButton';

const HelpDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
    const [showTroubleshooting, setTroubleShooting] = useState<boolean>(false);

    return (
        <InfoDialog
            title={'Help'}
            open={open}
            onClose={() => {
                onClose();
                setTimeout(() => setTroubleShooting(false), 500);
            }}
        >
            <Box minWidth={'350px'} display={'flex'} justifyContent={'center'}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    style={{ gap: 12 }}
                    width={'fit-content'}
                    textAlign={'center'}
                >
                    {showTroubleshooting ? <Troubleshooter /> : <InitContent setTroubleShooting={setTroubleShooting} />}
                </Box>
            </Box>
        </InfoDialog>
    );
};

const List = styled.ul({
    padding: 0,
    margin: '0 0 8px',
});

const ListItem = styled.li({
    marginTop: 8,
});

const Troubleshooter = () => {
    const [state, setState] = useState<
        'init' | 'audio-issue' | 'non-audio-issue' | 'issue-not-resolved' | 'network-recommendations'
    >('init');

    if (state === 'non-audio-issue' || state === 'issue-not-resolved') {
        return (
            <Box textAlign={'left'}>
                <Typography variant="body1">
                    Please send a description of the problem you're experiencing to{' '}
                    <a href="mailto:wave@wavepaths.com">support@wavepaths.com</a>.
                </Typography>
                <Typography style={{ marginTop: 8 }} variant="body1">
                    Please also include:
                </Typography>
                <List style={{ paddingLeft: '20px' }}>
                    <ListItem>
                        <Typography variant="body1">The name or link to any affected sessions,</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">Which device and browser you use,</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            The reading from a{' '}
                            <a href="https://www.fast.com/" target="_blank" rel="noreferrer">
                                speed test.
                            </a>
                        </Typography>
                    </ListItem>
                </List>
            </Box>
        );
    }

    if (state === 'audio-issue') {
        return (
            <Box textAlign={'left'}>
                <Typography variant="body1">
                    Firstly, here's a reminder of our recommendations for a high-quality audio experience:
                </Typography>
                <List style={{ paddingLeft: '20px' }}>
                    <ListItem>
                        <Typography variant="body1">
                            Opt for wired connections for sound over Bluetooth (learn more),
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            Ensure your internet connection is stable & reasonably fast (40Mbps or more),
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            Use either a desktop / laptop with Chrome or Safari, or an iPad with Safari,
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">Ensure other tabs and apps are all closed,</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">Disable notifications.</Typography>
                    </ListItem>
                </List>
                <Typography variant="body1">
                    You can read more about our system recommendations{' '}
                    <a href={configs.community.BASE_URL + 'c/start-here/technical-requirements/'}>here</a>.
                </Typography>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginTop={'12px'}>
                    <HelpSectionButton
                        size="m"
                        variant="solid-blue"
                        onClick={() => setState('network-recommendations')}
                    >
                        Next
                    </HelpSectionButton>
                </Box>
            </Box>
        );
    }

    if (state === 'network-recommendations') {
        return (
            <Box textAlign={'left'}>
                <Typography variant="body1">
                    Experiencing abrupt pauses in the music often means that you're current connection isn't fast enough
                    to play <b>adaptable</b> music sessions.
                </Typography>
                <Typography variant="body1">You can try:</Typography>
                <List style={{ paddingLeft: '20px' }}>
                    <ListItem>
                        <Typography variant="body1">
                            <a href="https://fast.com/en/gb/" target="_blank" rel="noreferrer">
                                Running a test
                            </a>{' '}
                            from your client's listening position to ensure you're up to speed,
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            Using <b>Slow Connection mode</b> (
                            <a
                                href={
                                    configs.community.BASE_URL +
                                    'c/announcements-306f6e/new-feature-slow-connection-mode'
                                }
                            >
                                learn more
                            </a>
                            ),
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="body1">
                            Running a session using <b>fixed</b> audio if the issue still persists.
                        </Typography>
                    </ListItem>
                </List>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginTop={'12px'}>
                    <HelpSectionButton size="m" variant="solid-blue" onClick={() => setState('issue-not-resolved')}>
                        I'm still having an issue
                    </HelpSectionButton>
                </Box>
            </Box>
        );
    }

    return (
        <>
            <Typography variant="body1">I'm having a problem with...</Typography>
            <HelpSectionButton size="m" variant="solid-blue" onClick={() => setState('audio-issue')}>
                Music or audio
            </HelpSectionButton>
            <HelpSectionButton size="m" variant="solid-blue" onClick={() => setState('non-audio-issue')}>
                Something else
            </HelpSectionButton>
        </>
    );
};

const InitContent = ({ setTroubleShooting }: { setTroubleShooting: (val: boolean) => void }) => {
    return (
        <>
            <Typography variant="body1">What would you like assistance with?</Typography>
            <HelpSectionButton
                size="m"
                onClick={() => {
                    window.open(configs.community.BASE_URL + 'c/start-here/', '_blank');
                }}
                variant="solid-blue"
            >
                Using Wavepaths in your practice
            </HelpSectionButton>
            <HelpSectionButton size="m" variant="solid-blue" onClick={() => setTroubleShooting(true)}>
                Troubleshooting and Technical Help
            </HelpSectionButton>
            {/* <HelpSectionButton size='m' variant='solid-blue'>Report a Bug</HelpSectionButton> */}
            {/* - Slow connection mode
            - Submit request 
        - Contact */}
        </>
    );
};

export default HelpDialog;
