import * as Sentry from '@sentry/browser';
import EventEmitter from 'events';
import firebase from 'firebase/app';
import Hls, { ErrorData } from 'hls.js';
import { isNumber, partial, some } from 'lodash';
import io from 'socket.io-client';
import {
    Session,
    SessionRequest,
    SessionRequestProperties,
    SessionTick,
    TimestampedSessionEvent,
    VoiceoverTick,
} from 'wavepaths-shared/core';
import { getLayerContentDetails } from 'wavepaths-shared/domain/layerContent';

import * as audio from '../audio';
import * as api from '../common/api/sessionApi';
import { sleep } from '../common/util/asyncUtils';
import configs from '../configs';
import { FreudStreamSilenceDetector } from './FreudStreamSilenceDetector';
import {
    ANALYSIS_FFT_SIZE,
    DEFAULT_HLS_FRAGMENT_BUFFER_COUNT,
    drainAndSummarizeNetworkStats,
    getHlsErrorContext,
    HLS_BUFFER_LATENCY,
    HLS_LONG_BUFFER_LATENCY,
    LONG_BUFFER_FRAG_COUNT,
    NetworkStats,
    promiseTimeout,
    TRACED_AUDIO_EVENTS,
} from './hlsUtils';
import { PendingBehaviourTimer } from './PendingBehaviourTimer';
import { registerCypressTestingHook } from './registerCypressTestingHook';
import unblockerFile from './silence.mp3';
import { VoiceoverPlayer } from './VoiceoverPlayer';
export type AudioState = 'notStarted' | 'initializing' | 'starting' | 'started' | 'blocked';

const NETWORK_STATS_REPORT_INTERVAL = 5 * 60 * 1000;
const BUFFER_STALL_MEDIA_DEVICE_CHANGE_WAIT_PERIOD = 1 * 1000;

type PlaybackSettings = {
    isOnSlowConnection: boolean;
};

export class FreudConnection extends EventEmitter {
    public audioGain: GainNode | null = null;
    public audioAnalyserLeft: AnalyserNode | null = null;
    public audioAnalyserRight: AnalyserNode | null = null;
    public isVolumeControllable = true;
    private _isLongBufferEnabled = false;
    private isLogReplayed = false;
    private audioState: AudioState = 'notStarted';
    private voiceoverPlayer: VoiceoverPlayer;

    private ioConnection: any;
    private hls: Hls | null = null;
    private playerEl: HTMLAudioElement | null = null;
    private streamSrc: MediaElementAudioSourceNode | null = null;
    private receivedLog: TimestampedSessionEvent[] = [];
    private requestQueue: SessionRequest[] = [];
    private closed = false;
    private pendingBehaviours: PendingBehaviourTimer;
    private networkStats: NetworkStats[] = [];
    private networkStatsReporterLoop: NodeJS.Timeout | null = null;
    private lastMediaDeviceChangeAt: number | null = null;
    private silenceDetector: FreudStreamSilenceDetector | null = null;
    private isAudioPlayingOsc: OscillatorNode | null = null; // we need this to prevent Chrome from thinking no audio is playing during Silence

    constructor(private session: Session, private fbUser: firebase.User) {
        super();
        this.setMaxListeners(100);
        this.voiceoverPlayer = new VoiceoverPlayer(session.broadcastIdentifier);
        this.pendingBehaviours = new PendingBehaviourTimer();
        this.pendingBehaviours.on('update', (...b: any) => this.emit('pendingBehavioursUpdate', ...b));
        this.connectToMetadataStream();
        this.networkStatsReporterLoop = global.setInterval(
            () => this.reportNetworkStats(),
            NETWORK_STATS_REPORT_INTERVAL,
        );

        registerCypressTestingHook(this);

        const playbackSettings = JSON.parse(localStorage.getItem('playbackSettings') ?? '{}') as
            | PlaybackSettings
            | undefined;
        this._isLongBufferEnabled = playbackSettings?.isOnSlowConnection ?? false;
    }

    get sessionId() {
        return this.session.id;
    }

    get broadcastIdentifier() {
        return this.session.broadcastIdentifier;
    }

    get isLongBufferEnabled() {
        return this._isLongBufferEnabled;
    }

    get bufferLatency() {
        return this._isLongBufferEnabled ? HLS_LONG_BUFFER_LATENCY : HLS_BUFFER_LATENCY;
    }

    get isStreamSilent() {
        return !!this.silenceDetector?.isSilent();
    }

    private async connectToMetadataStream() {
        // Wait for tab to be visible. Audio will likely get stuck in the audio element otherwise due to background tab restrictions.
        console.log('Awaiting page to become visible before connecting to Freud');
        await nextVisibility();
        console.log('Page visible - proceeding to connect to Freud');

        this.ioConnection = io(`${api.FREUD_BASE_URL}/broadcastMetadata/${this.session.broadcastIdentifier}`, {
            transports: ['websocket'],
            reconnectionDelay: 2000,
            reconnectionDelayMax: 10000,
            randomizationFactor: 1,
        });

        // On initial connection, as well as reconnections
        this.ioConnection.on('connect', async () => {
            // Rejoin as control user with a fresh id token
            this.ioConnection.emit('joinControl', { idToken: await this.fbUser.getIdToken() });
        });

        // When successfullly joined as control user, make sure we have the full log, and drain any control events we may have pending
        this.ioConnection.on('joinedControl', () => {
            this.ioConnection.emit('requestSessionEventReplay', { from: this.receivedLog.length });
            while (this.requestQueue.length > 0) {
                this.ioConnection.emit('controlRequest', this.requestQueue.shift());
            }
        });

        // On control join failure, force refresh our access token and join again
        this.ioConnection.on('failedToJoinControl', async () => {
            this.ioConnection.emit('joinControl', { idToken: await this.fbUser.getIdToken(true) });
        });

        this.ioConnection.on('sessionEventReplay', (events: { index: number; event: TimestampedSessionEvent }[]) => {
            this.onLogEvents(events, true);
            this.isLogReplayed = true;
            this.emit('sessionEventsReplayed');
        });
        this.ioConnection.on('sessionEvent', (event: { index: number; event: TimestampedSessionEvent }) => {
            this.onLogEvents([event], false);
        });
        this.ioConnection.on('tick', (tick: SessionTick) => {
            this.emit('tick', tick, HLS_BUFFER_LATENCY);
            if (tick.voiceover) {
                this.updateVoiceover(tick.voiceover, tick.wallClockTime);
            }
        });
        const connector = setInterval(() => {
            if (this.closed) {
                clearInterval(connector);
            } else if (!this.ioConnection.connected) {
                this.ioConnection.open();
            } else {
                clearInterval(connector);
            }
        }, 1000);

        this.pendingBehaviours.onConnecting();
    }

    toggleLongBuffer(isLongBufferEnabled: boolean) {
        this._isLongBufferEnabled = isLongBufferEnabled;
        this.initializeAudioStream();
        const playbackSettings = JSON.parse(localStorage.getItem('playbackSettings') ?? '{}') as
            | PlaybackSettings
            | undefined;
        localStorage.setItem(
            'playbackSettings',
            JSON.stringify({ ...playbackSettings, isOnSlowConnection: isLongBufferEnabled }),
        );
        this.emit('longBufferToggled', isLongBufferEnabled);
    }

    private updateVoiceover(voiceover: VoiceoverTick, currentTime: number) {
        this.voiceoverPlayer.updateVoiceover(voiceover, currentTime);
    }

    private onLogEvents(events: { index: number; event: TimestampedSessionEvent }[], isReplay: boolean) {
        for (const { index, event } of events) {
            console.log('Received tick event', index, event, event.event);
            this.receivedLog[index] = event;
            this.pendingBehaviours.onEventReceive(event, isReplay);
            if (event.event === 'audioStreamingStarted') {
                this.initializeAudioStream();
            }

            this.emit(event.event, event);
        }

        let currentHoleFrom = -1,
            anyHoles = false;
        for (let i = 0; i < this.receivedLog.length; i++) {
            if (this.receivedLog[i]) {
                if (currentHoleFrom >= 0) {
                    if (this.isLogReplayed) {
                        console.log('Requesting log hole fill', currentHoleFrom, i);
                        this.ioConnection.emit('requestSessionEventReplay', { from: currentHoleFrom, to: i });
                    }
                    currentHoleFrom = -1;
                }
            } else {
                anyHoles = true;
                if (currentHoleFrom < 0) {
                    currentHoleFrom = i;
                }
            }
        }

        if (currentHoleFrom >= 0 && this.isLogReplayed) {
            console.log('Requesting log hole fill', currentHoleFrom);
            this.ioConnection.emit('requestSessionEventReplay', { from: currentHoleFrom });
        }

        if (!anyHoles) {
            this.emit('logUpdate', [...this.receivedLog], [...events.map((e) => e.event)]);

            if (some(events, (e) => e.event.event === 'addStageContent')) {
                logContent(this.receivedLog);
            }
        }
    }

    close() {
        if (this.closed) return;

        this.ioConnection && this.ioConnection.disconnect();
        if (this.hls) {
            this.hls.destroy();
        }
        if (this.playerEl) {
            this.playerEl.removeAttribute('src');
            this.playerEl.load();
            this.playerEl = null;
        }
        if (this.streamSrc && this.audioGain) {
            this.streamSrc.disconnect(this.audioGain);
        }
        if (this.audioGain) {
            this.audioGain.disconnect();
            this.audioGain = null;
        }
        if (this.audioAnalyserLeft) {
            this.audioAnalyserLeft.disconnect();
            this.audioAnalyserLeft = null;
        }
        if (this.audioAnalyserRight) {
            this.audioAnalyserRight.disconnect();
            this.audioAnalyserRight = null;
        }
        if (this.silenceDetector) {
            this.silenceDetector.stop();
            this.silenceDetector = null;
        }
        if (this.isAudioPlayingOsc) {
            this.isAudioPlayingOsc.stop();
            this.isAudioPlayingOsc = null;
        }
        this.voiceoverPlayer.stop();
        if (isNumber(this.networkStatsReporterLoop)) {
            clearTimeout(this.networkStatsReporterLoop);
        }
        this.emit('closed');
        this.closed = true;
    }

    isClosed() {
        return this.closed;
    }

    sendRequest(reqProps: SessionRequestProperties) {
        const req: SessionRequest = {
            ...reqProps,
            fbUser: {
                type: 'firebase',
                firebaseUserId: this.fbUser.uid,
                email: this.fbUser.email ?? '',
                name: this.fbUser.displayName ?? '',
            },
        };
        if (this.ioConnection?.connected) {
            this.ioConnection!.emit('controlRequest', req);
        } else {
            this.requestQueue.push(req);
        }
        this.pendingBehaviours.onRequest(req);
    }

    onStoppingSession() {
        this.pendingBehaviours.onStop();
    }

    async initializeAudioStream() {
        const config = this._isLongBufferEnabled
            ? { fragCount: LONG_BUFFER_FRAG_COUNT }
            : { fragCount: DEFAULT_HLS_FRAGMENT_BUFFER_COUNT };

        console.log('initialising audio stream...');
        if (this.playerEl) {
            console.warn('Received second audio stream start when already started audio stream');
            this.tearDownAudioStream();
        }
        this.updateAudioState('initializing');

        this.playerEl = document.createElement('audio');
        this.playerEl.crossOrigin = 'anonymous';

        this.audioGain = audio.audioCtx.createGain();
        this.streamSrc = audio.audioCtx.createMediaElementSource(this.playerEl);
        this.streamSrc.connect(this.audioGain);
        this.audioGain.connect(audio.audioCtx.destination);

        this.audioAnalyserLeft = audio.audioCtx.createAnalyser();
        this.audioAnalyserRight = audio.audioCtx.createAnalyser();
        this.audioAnalyserLeft.fftSize = ANALYSIS_FFT_SIZE;
        this.audioAnalyserLeft.smoothingTimeConstant = 1.0;
        this.audioAnalyserRight.fftSize = ANALYSIS_FFT_SIZE;
        this.audioAnalyserRight.smoothingTimeConstant = 1.0;
        const split = audio.audioCtx.createChannelSplitter(2);
        this.audioGain.connect(split);
        split.connect(this.audioAnalyserLeft, 0);
        split.connect(this.audioAnalyserRight, 1);

        this.silenceDetector = new FreudStreamSilenceDetector(
            this.audioAnalyserLeft,
            this.audioAnalyserRight,
            ANALYSIS_FFT_SIZE,
        );
        this.silenceDetector.start();

        this.isAudioPlayingOsc = audio.audioCtx.createOscillator();
        this.isAudioPlayingOsc.type = 'sine';

        const silencePreventionOscGain = audio.audioCtx.createGain();
        silencePreventionOscGain.connect(audio.audioCtx.destination);
        silencePreventionOscGain.gain.setValueAtTime(0.001, audio.audioCtx.currentTime);
        this.isAudioPlayingOsc.frequency.setValueAtTime(0.546, audio.audioCtx.currentTime); //
        this.isAudioPlayingOsc.connect(silencePreventionOscGain);
        this.isAudioPlayingOsc.start();

        try {
            // Attempt to unblock Web Audio + both audio elements while we still have the user gesture, lest they get blocked
            await Promise.all([
                this.resumeAudioContext(),
                this.unblockAudioEl(this.playerEl),
                this.voiceoverPlayer.start(),
            ]);
        } catch {
            // We have no permission to start
            this.updateAudioState('blocked');
            return;
        }

        this.detectAdjustableVolume();
        this.connectToAudioStream(config);
    }

    private tearDownAudioStream() {
        this.hls?.detachMedia();
        this.hls?.destroy();
        this.playerEl?.remove();
        // @ts-ignore
        this.playerEl.src = null;
        this.streamSrc?.disconnect();
        this.audioGain?.disconnect();
        this.audioAnalyserLeft?.disconnect();
        this.audioAnalyserRight?.disconnect();
        this.silenceDetector?.stop();
        this.voiceoverPlayer.stop();
    }

    private resumeAudioContext() {
        if (audio.audioCtx.state !== 'running') {
            console.log('trying to resume AudioContext');
            // Need to use timeout because promise may not reject properly when blocked
            // https://github.com/w3c/autoplay/issues/1
            return promiseTimeout(audio.audioCtx.resume(), 100);
        } else {
            return Promise.resolve();
        }
    }

    private unblockAudioEl(audioEl: HTMLAudioElement) {
        audioEl.src = unblockerFile;
        return audioEl.play();
    }

    private detectAdjustableVolume() {
        if (!this.playerEl) return;
        audio.isVolumeControllable(this.playerEl).then((isVolumeControllable) => {
            this.isVolumeControllable = isVolumeControllable;
        });
    }

    private connectToAudioStream(config?: { fragCount?: number }) {
        this.updateAudioState('starting');
        const streamUrl = this.getAudioStreamUrl();

        const onFirstFragPlaying = () => {
            this.pendingBehaviours.onAudioPlaying();
        };

        !!navigator.mediaDevices &&
            !!navigator.mediaDevices.addEventListener &&
            navigator.mediaDevices.addEventListener('devicechange', () => {
                this.lastMediaDeviceChangeAt = Date.now();
                Sentry.addBreadcrumb({
                    category: 'streaming',
                    data: { mediaDeviceEvent: 'devicechange' },
                });
            });

        if (Hls.isSupported()) {
            const hlsLogger = (level: string, ...msg: string[]) => {
                Sentry.addBreadcrumb({
                    category: 'streaming',
                    data: { level, msg: msg.join(' ') },
                });
            };

            const fragCount = config?.fragCount ?? DEFAULT_HLS_FRAGMENT_BUFFER_COUNT;

            this.hls = new Hls({
                liveSyncDurationCount: fragCount,
                initialLiveManifestSize: fragCount,
                startLevel: 0,
                abrEwmaFastLive: 0.5,
                abrEwmaSlowLive: 6,
                lowLatencyMode: false,
                liveDurationInfinity: true, // Important for Safari; it won't end the stream if we temporarily cannot extend its duration
                debug: {
                    trace: partial(hlsLogger, 'trace'),
                    debug: partial(hlsLogger, 'debug'),
                    log: partial(hlsLogger, 'log'),
                    warn: partial(hlsLogger, 'warn'),
                    info: partial(hlsLogger, 'info'),
                    error: partial(hlsLogger, 'error'),
                },
            } as any);

            this.hls.loadSource(streamUrl);

            const retryManifestLoad = (event: 'hlsError', data: ErrorData) => {
                if (data.details === Hls.ErrorDetails.MANIFEST_LOAD_ERROR) {
                    console.log('HLS Manifest not available, retrying in 1s');
                    setTimeout(() => this.hls?.loadSource(streamUrl), 1000);
                }
            };
            this.hls.on(Hls.Events.ERROR, retryManifestLoad);

            this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                console.log('HLS Manifest loaded', new Date());
                this.hls?.off(Hls.Events.ERROR, retryManifestLoad);
                this.hls?.once(Hls.Events.FRAG_CHANGED, onFirstFragPlaying);
                Object.keys(Hls.Events).forEach((e) => {
                    this.hls?.on((Hls.Events as any)[e], async (event: string, data: any) => {
                        if (event === Hls.Events.ERROR) {
                            const errorData = data as ErrorData;
                            if (errorData.fatal) {
                                switch (errorData.type) {
                                    case Hls.ErrorTypes.NETWORK_ERROR:
                                        console.error('fatal network error encountered, try to recover', errorData);
                                        Sentry.withScope((scope) => {
                                            scope.setExtra('broadcastIdentifier', this.session.broadcastIdentifier);
                                            scope.setExtra('context', getHlsErrorContext(errorData));
                                            if (this.silenceDetector)
                                                scope.setExtra('streamSilent', this.silenceDetector.isSilent());
                                            Sentry.captureMessage('Streaming network error');
                                        });
                                        this.hls?.startLoad();
                                        break;
                                    case Hls.ErrorTypes.MEDIA_ERROR:
                                        Sentry.withScope((scope) => {
                                            scope.setExtra('broadcastIdentifier', this.session.broadcastIdentifier);
                                            scope.setExtra('context', getHlsErrorContext(errorData));
                                            if (this.silenceDetector)
                                                scope.setExtra('streamSilent', this.silenceDetector.isSilent());
                                            Sentry.captureMessage('Streaming media error');
                                        });
                                        this.recoverMediaError();
                                        break;
                                    default:
                                        console.error('fatal streaming error', errorData);
                                        Sentry.withScope((scope) => {
                                            scope.setExtra('broadcastIdentifier', this.session.broadcastIdentifier);
                                            scope.setExtra('context', getHlsErrorContext(errorData));
                                            if (this.silenceDetector)
                                                scope.setExtra('streamSilent', this.silenceDetector.isSilent());
                                            Sentry.captureMessage('Streaming error');
                                        });
                                        this.hls?.destroy();
                                        break;
                                }
                            } else if (errorData.type === Hls.ErrorTypes.MEDIA_ERROR) {
                                if (errorData.details === 'bufferStalledError') {
                                    this.emit('networkTooSlow');
                                    const deviceData = await this.getMediaDeviceChange();
                                    Sentry.withScope((scope) => {
                                        scope.setExtra('broadcastIdentifier', this.session.broadcastIdentifier);
                                        scope.setExtra('context', getHlsErrorContext(errorData));
                                        scope.setExtra('mediaDevicesChanged', deviceData.changed);
                                        scope.setExtra('mediaDevices', deviceData.devices);
                                        if (this.silenceDetector)
                                            scope.setExtra('streamSilent', this.silenceDetector.isSilent());
                                        Sentry.captureMessage('Streaming network glitch');
                                    });
                                } else {
                                    const deviceData = await this.getMediaDeviceChange();
                                    Sentry.withScope((scope) => {
                                        scope.setExtra('broadcastIdentifier', this.session.broadcastIdentifier);
                                        scope.setExtra('context', getHlsErrorContext(errorData));
                                        scope.setExtra('mediaDevicesChanged', deviceData.changed);
                                        scope.setExtra('mediaDevices', deviceData.devices);
                                        if (this.silenceDetector)
                                            scope.setExtra('streamSilent', this.silenceDetector.isSilent());
                                        Sentry.captureMessage(`Media error: ${errorData.details}`);
                                    });
                                }
                            } else if (errorData.type === Hls.ErrorTypes.NETWORK_ERROR) {
                                if (errorData.details === 'levelLoadTimeOut') {
                                    this.emit('networkTooSlow');
                                    const deviceData = await this.getMediaDeviceChange();
                                    Sentry.withScope((scope) => {
                                        scope.setExtra('broadcastIdentifier', this.session.broadcastIdentifier);
                                        scope.setExtra('context', getHlsErrorContext(errorData));
                                        scope.setExtra('mediaDevicesChanged', deviceData.changed);
                                        scope.setExtra('mediaDevices', deviceData.devices);
                                        if (this.silenceDetector)
                                            scope.setExtra('streamSilent', this.silenceDetector.isSilent());
                                        Sentry.captureMessage('Streaming network request timeout');
                                    });
                                } else {
                                    const deviceData = await this.getMediaDeviceChange();
                                    Sentry.withScope((scope) => {
                                        scope.setExtra('broadcastIdentifier', this.session.broadcastIdentifier);
                                        scope.setExtra('context', getHlsErrorContext(errorData));
                                        scope.setExtra('mediaDevicesChanged', deviceData.changed);
                                        scope.setExtra('mediaDevices', deviceData.devices);
                                        if (this.silenceDetector)
                                            scope.setExtra('streamSilent', this.silenceDetector.isSilent());
                                        Sentry.captureMessage(`Network error: ${errorData.details}`);
                                    });
                                }
                            }
                        }

                        if (event === Hls.Events.FRAG_BUFFERED) {
                            this.networkStats.push({
                                trequest: data.frag.stats.loading.start,
                                tload: data.frag.stats.buffering.end,
                                bwEstimate: data.frag.stats.bwEstimate,
                                isSilent: this.silenceDetector?.isSilent(),
                            });
                        }
                    });
                });

                for (const evtName of TRACED_AUDIO_EVENTS) {
                    this.playerEl?.addEventListener(evtName, () => {
                        Sentry.addBreadcrumb({
                            category: 'streaming',
                            data: { audioEvent: evtName },
                        });
                    });
                }

                this.hls?.attachMedia(this.playerEl as any);
                const { playerEl } = this;
                if (playerEl) {
                    playerEl
                        .play()
                        .then(() => {
                            this.updateAudioState('started');
                            // If audio gets paused, go back to "blocked" state to have the user explicitly resume the stream again.
                            playerEl.addEventListener('pause', async () => {
                                const streamEnded = await isMediaEnding(playerEl);
                                if (!streamEnded && this.audioState === 'started') {
                                    this.tearDownAudioStream();
                                    this.updateAudioState('blocked');
                                }
                            });
                        })
                        .catch(() => {
                            this.tearDownAudioStream();
                            this.updateAudioState('blocked');
                        });
                }
            });
        } else {
            let metaLoaded = false;
            this.playerEl?.addEventListener('loadedmetadata', () => {
                metaLoaded = true;
                onFirstFragPlaying(); // We don't know when the first fragment plays, the next best thing is when we have stream metadata
            });
            this.playerEl?.addEventListener('error', (evt) => {
                Sentry.withScope((scope) => {
                    scope.setExtra('broadcastIdentifier', this.session.broadcastIdentifier);
                    scope.setExtra('event', evt);
                    Sentry.captureMessage('Player error');
                });
                console.error(evt);
            });
            this.playerEl?.addEventListener('waiting', (evt) => {
                if (metaLoaded) {
                    // This is an audible buffer stall
                    Sentry.withScope((scope) => {
                        scope.setExtra('broadcastIdentifier', this.session.broadcastIdentifier);
                        scope.setExtra('event', evt);
                        Sentry.captureMessage('Buffer stall');
                    });
                    console.warn(evt);
                }
            });
            if (this.playerEl) {
                this.playerEl.src = streamUrl;
                this.playerEl
                    ?.play()
                    .then(() => {
                        this.updateAudioState('started');
                    })
                    .catch((e) => {
                        console.error('Native audio start error', e);
                        this.updateAudioState('blocked');
                    });
            }
        }

        this.playerEl?.addEventListener('ended', () => {
            console.log('session ended');
            this.emit('streamEnded');
        });
    }

    private recoverMediaError() {
        // If we don't get a duration event within a few seconds, fail the in-place recovery and fall back to initialising a new stream
        let timedOut = false;
        const timeoutId = setTimeout(() => {
            console.log('Media error recovery timed out. Reinitialising stream.');
            timedOut = true;
            this.initializeAudioStream();
        }, 5000);

        // Wait for a duration event
        this.playerEl?.addEventListener(
            'durationchange',
            async () => {
                if (timedOut) return;
                clearTimeout(timeoutId);

                if (isNaN(this.playerEl?.duration ?? NaN)) {
                    // Duration may still be NaN after we're supposed to have it (as observed on Safari on Big Sur).
                    // If so, fail the in-place recovery and fall back to initialising a new stream
                    console.log('Did not get a stream duration during media error recovery. Reinitialising stream.');
                    this.initializeAudioStream();
                } else if (this.playerEl?.paused && this.hls) {
                    const pos = await this.getFreshLiveSyncPosition();
                    console.log('Starting stream after media error recovery at', pos);
                    if (this.playerEl) {
                        // Set the stream position back to where it was and restart
                        this.playerEl.currentTime = pos;
                        this.playerEl.play().catch(() => {
                            this.tearDownAudioStream();
                            this.updateAudioState('blocked');
                        });
                    }
                }
            },
            { once: true },
        );

        console.log('Starting media error recovery');
        // Have HLS reattach the media source, eventually triggering the durationchange we're waiting for in the in-place recovery
        this.hls?.recoverMediaError();
    }

    private async getFreshLiveSyncPosition() {
        // Wait for a level load event to try and ensure we have the latest playlist before
        // determining where the live sync position is. We do this because if the device has
        // been asleep we may have obsolete information on this before the playlist loads.
        const levelLoad = new Promise((res) => this.hls?.once(Hls.Events.LEVEL_LOADED, res));
        const timeout = new Promise((res) => setTimeout(res, 2000));
        await Promise.race([levelLoad, timeout]);
        const liveSyncPos = this.hls?.liveSyncPosition;
        const currentTime = this.playerEl?.currentTime;
        const duration = this.playerEl?.duration;
        return Math.min(liveSyncPos ?? currentTime ?? 0, duration ?? 0);
    }

    setVolume(volume: number) {
        // Most browsers
        if (this.audioGain) {
            this.audioGain.gain.value = volume;
        }
        // Where audio can't go through Web Audio (i.e. Safafi)
        // https://bugs.webkit.org/show_bug.cgi?id=180696
        if (this.playerEl) {
            this.playerEl.volume = volume;
        }
        this.voiceoverPlayer.setVolume(volume);
    }

    private getAudioStreamUrl() {
        return `${configs.freud.STREAM_BASE}/${this.session.broadcastIdentifier}/stream.m3u8`;
    }

    private async getMediaDeviceChange() {
        let changed =
            isNumber(this.lastMediaDeviceChangeAt) &&
            this.lastMediaDeviceChangeAt > Date.now() - BUFFER_STALL_MEDIA_DEVICE_CHANGE_WAIT_PERIOD;
        if (!changed) {
            changed = await Promise.race([
                sleep(BUFFER_STALL_MEDIA_DEVICE_CHANGE_WAIT_PERIOD).then(() => false),
                nextMediaDeviceChange().then(() => true),
            ]);
        }
        const allDevices = !!navigator.mediaDevices
            ? await navigator.mediaDevices.enumerateDevices()
            : ([] as MediaDeviceInfo[]);
        const audioDevices = allDevices.filter((d) => d.kind === 'audiooutput').map((d) => d.toJSON());
        return { changed, devices: audioDevices };
    }

    private updateAudioState(newState: AudioState) {
        this.emit('updateAudioState', newState);
        this.audioState = newState;
    }

    isAudioBlocked() {
        return this.audioState === 'blocked';
    }

    private reportNetworkStats() {
        if (this.networkStats.length === 0) return;
        const stats = drainAndSummarizeNetworkStats(this.networkStats);
        Sentry.withScope((scope) => {
            scope.setExtra('broadcastIdentifier', this.session.broadcastIdentifier);
            scope.setExtra('streamingStats', stats);
            Sentry.captureMessage('Streaming stats');
        });
    }
}

const logContent = (log: TimestampedSessionEvent[]) => {
    console.table(getLayerContentDetails(log));
};

const nextVisibility = () => {
    if (document.visibilityState === 'visible') {
        return Promise.resolve();
    } else {
        return new Promise<void>((res) => {
            const onVisibilityChange = () => {
                if (document.visibilityState === 'visible') {
                    document.removeEventListener('visibilitychange', onVisibilityChange);
                    res();
                }
            };
            document.addEventListener('visibilitychange', onVisibilityChange);
        });
    }
};
const nextMediaDeviceChange = () =>
    new Promise<void>(
        (res) =>
            !!navigator.mediaDevices &&
            navigator.mediaDevices.addEventListener('devicechange', () => res(), { once: true }),
    );

function isMediaEnding(el: HTMLMediaElement) {
    const ended = new Promise((res) => el.addEventListener('ended', () => res(true), { once: true }));
    const didNotEnd = new Promise((res) => setTimeout(() => res(false), 500));
    return Promise.race([ended, didNotEnd]);
}
