import { isNil } from 'lodash';
import React, { ReactElement, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import useSWR from 'swr';
import { SessionPageResponse, SessionType } from 'wavepaths-shared/core';

import { useAuthContext } from './auth';
import * as api from './common/api/sessionApi';
import { useFeatures } from './common/features/useFeatures';

interface IProps {
    children: React.ReactChild[] | React.ReactChild;
}

const IntercomWrapper = ({ children }: IProps): ReactElement => {
    const { boot } = useIntercom();
    const { isEnabled, isLoading: isFeaturesLoading } = useFeatures();
    const { userData, firebaseUser, isLoaded: isUserDataLoaded } = useAuthContext();

    const { data: sessionData } = useSWR<SessionPageResponse | undefined, string | undefined>(
        firebaseUser ? [`/sessions/ended`, 1, 1] : null,
        () => (firebaseUser ? api.listEndedSessionsV2(1, 1, firebaseUser) : undefined),
    );

    const { data: introSessionsData } = useSWR<SessionPageResponse | undefined, string | undefined>(
        firebaseUser ? [`/sessions/ended/intro`, 1, 1] : null,
        () => (firebaseUser ? api.listEndedSessionsV2ByType(1, 1, firebaseUser, SessionType.INTRO) : undefined),
    );

    const { data: oneOnOneSessionsData } = useSWR<SessionPageResponse | undefined, string | undefined>(
        firebaseUser ? [`/sessions/ended/oneOnOne`, 1, 1] : null,
        () => (firebaseUser ? api.listEndedSessionsV2ByType(1, 1, firebaseUser, SessionType.ONE_ON_ONE) : undefined),
    );

    useEffect(() => {
        if (
            !isFeaturesLoading &&
            isUserDataLoaded &&
            userData &&
            !isNil(sessionData) &&
            !isNil(introSessionsData) &&
            !isNil(oneOnOneSessionsData)
        ) {
            const intercomEnabled = isEnabled('intercom');
            const { email, name, uid } = userData;
            if (intercomEnabled)
                boot({
                    email,
                    name,
                    userId: uid,
                    customAttributes: {
                        completed_sessions: sessionData.totalCount,
                        completed_intro_sessions: introSessionsData.totalCount,
                        completed_oneOnOne_sessions: oneOnOneSessionsData.totalCount,
                    },
                });
        }
    }, [
        isFeaturesLoading,
        boot,
        isEnabled,
        sessionData,
        introSessionsData,
        oneOnOneSessionsData,
        userData,
        isUserDataLoaded,
    ]);

    return <>{children}</>;
};

export default IntercomWrapper;
