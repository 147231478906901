import styled from '@emotion/styled';
import React from 'react';

export interface ContentAreaProps {
    children: React.ReactChild[] | React.ReactChild;
}

const Container = styled.main`
    width: 100%;
    min-height: 100%;
    overflow-y: auto;
    display: grid;
    justify-items: center;
    background-color: #f8f9fb;
    padding: 36px 24px;
`;

const Content = styled.div`
    position: relative; // Allow for absolute positioning of children relative to content area
    width: 100%;
    max-width: 944px;
    min-width: 0;
`;

const ContentArea: React.FC<ContentAreaProps> = ({ children }) => {
    return (
        <Container>
            <Content>{children}</Content>
        </Container>
    );
};

export default ContentArea;
