import './SessionClock.scss';

import classNames from 'classnames';
import React from 'react';

import { Typography } from '@/component-library';

import { useSessionTick } from '../../common/hooks/useSessionTick';
import { formatDuration } from '../../dateUtilsV2';
import { FreudConnection } from '../../freudConnection/FreudConnection';

interface SessionStageStripProps {
    connection: FreudConnection;
}
export const SessionClock: React.FC<SessionStageStripProps> = ({ connection }) => {
    const tick = useSessionTick(connection);
    const timeElapsedMs = tick
        ? Math.max(0, Math.min(tick.sessionDuration + tick.postludeDuration, tick.absoluteTime))
        : 0;
    return <Clock timeElapsedMs={timeElapsedMs} totalDurationMs={(tick && tick?.sessionDuration) ?? 0} />;
};

export const Clock = ({
    timeElapsedMs,
    totalDurationMs,
}: {
    timeElapsedMs: number;
    totalDurationMs: number;
}): JSX.Element => {
    return (
        <div className={classNames('sessionClock')}>
            <Typography className="timePassed" variant="subtitle1" isFixedWidth>
                {timeElapsedMs > 0 ? formatDuration(timeElapsedMs) : '00:00'}
            </Typography>
            <Typography className="timeDivider" variant="subtitle1" isFixedWidth>
                /
            </Typography>
            <Typography className="timeTotal" variant="subtitle1" isFixedWidth>
                {totalDurationMs > 0 ? formatDuration(totalDurationMs) : '00:00'}
            </Typography>
        </div>
    );
};
