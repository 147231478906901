import { format } from 'date-fns';
import React from 'react';
import { RequestType, Wavepath } from 'wavepaths-shared/core';

import { WaveDurationMenu } from '@/component-library';

import { Connection, useSessionTick } from '../../common/hooks/useSessionTick';
import UserEvents from '../../UserEvents';

export interface WaveEndCountdownProps {
    wave: Wavepath;
    connection: Connection;
    shouldExtensionBeDisabled: boolean;
    onSkipWave?: () => void;
}

export const WaveEndCountdownV2: React.FC<WaveEndCountdownProps> = ({
    wave,
    connection,
    shouldExtensionBeDisabled,
    onSkipWave,
}) => {
    const tick = useSessionTick(connection);

    const extensionOptionsMinutes = [5, 10, 15];

    const waveDuration = wave.type === 'scheduled' && wave.plan ? wave.plan.toTime - wave.plan.fromTime : undefined;

    const pathScoreId = wave.pathScore.id;

    const timeUntilEnd =
        wave.type === 'scheduled' && tick && wave.plan ? wave.plan.toTime - tick.effectiveTime : undefined;

    const onExtensionSelected = (extensionMins: number) => {
        const extensionSeconds = extensionMins * 60;
        UserEvents.currentWaveExtended({
            effectiveTimeSeconds: tick ? tick?.effectiveTime / 1000 : undefined,
            pathScoreId,
            timeUntilWaveEndSeconds: timeUntilEnd ? timeUntilEnd / 1000 : undefined,
            waveDurationSeconds: waveDuration ? waveDuration / 1000 : undefined,
            extendedBySeconds: extensionSeconds,
        });

        connection.sendRequest({ type: RequestType.ExtendCurrentWave, extendBySeconds: extensionSeconds });
    };

    const timeUntilEndText = timeUntilEnd && format(timeUntilEnd, 'mm:ss');

    return (
        <div className="tour-extendWave">
            {timeUntilEndText && (
                <WaveDurationMenu
                    timerText={timeUntilEndText}
                    extensionOptions={extensionOptionsMinutes}
                    onExtensionSelected={onExtensionSelected}
                    isExtensionDisabled={shouldExtensionBeDisabled}
                    onSkipClicked={onSkipWave}
                />
            )}
        </div>
    );
};
