import React from 'react';

type WarningBannerType =
    | {
          kind: 'text';
          message: string;
      }
    | {
          kind: 'childComponent';
          children: React.ReactChild[] | React.ReactChild;
      };

const WarningBanner = (props: WarningBannerType) => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '50px',
            backgroundColor: '#ff6700',
        }}
    >
        {props.kind === 'text' ? <p style={{ color: 'white', fontWeight: 'bold' }}>{props.message}</p> : props.children}
    </div>
);

export default WarningBanner;
