import firebase from 'firebase';
import _ from 'lodash';
import React, { useState } from 'react';
import { SessionHeader } from 'wavepaths-shared/core';

import OngoingSessionCard from './OngoingSessionCard';
import SessionCardSkeleton from './SessionCardSkeleton';

interface OngoingSessionsTableProps {
    sessions: SessionHeader[];
    loading: boolean;
    // TODO: handle errors
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sessionsError: any;
    fbUser: firebase.User;
    isAdmin: boolean;
    onLinkClick: () => void;
}
const OngoingSessionsTableV2: React.FC<OngoingSessionsTableProps> = ({
    sessions,
    loading,
    fbUser,
    isAdmin,
    onLinkClick,
}) => {
    const [copiedLinkId, setCopiedLinkId] = useState<string>();

    const sortedSessions = _.orderBy(sessions, ['startedAt'], ['desc']);

    if (loading) {
        return (
            <div style={{ width: '100%' }}>
                <SessionCardSkeleton />
            </div>
        );
    }

    if (sessions.length === 0) {
        return <></>;
    }

    // TODO: handle sessions loading error

    return (
        <div style={{ width: '100%' }}>
            {sortedSessions.map((session, idx) => (
                <div key={idx} style={{ marginBottom: '16px' }}>
                    <OngoingSessionCard
                        key={session.id}
                        session={session}
                        isAdmin={isAdmin}
                        copiedLinkId={copiedLinkId}
                        setCopiedLinkId={setCopiedLinkId}
                        onLinkClick={onLinkClick}
                        fbUser={fbUser}
                    />
                </div>
            ))}
        </div>
    );
};

export default OngoingSessionsTableV2;
