import InfoIcon from '@material-ui/icons/InfoOutlined';
import React, { useState } from 'react';

import { Button, InfoDialog } from '@/component-library';

interface InfoButtonAndDialogProps {
    title: string;
    className?: string;
}
const InfoButtonAndDialog: React.FC<InfoButtonAndDialogProps> = ({ title, children, className }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Button
                style={{ padding: '0 4px' }}
                className={className}
                icon={<InfoIcon />}
                onClick={() => setOpen(true)}
            />
            <InfoDialog title={title} open={open} onClose={() => setOpen(false)}>
                {children}
            </InfoDialog>
        </>
    );
};

export default InfoButtonAndDialog;
