import { isEqual } from 'lodash';
import { Wavepath } from 'wavepaths-shared/core';

type NoWaveSelected = { selection: 'none' };
type WaveSelected = { selection: 'selected'; wave: Wavepath; editedWave: null };
type WaveSelectedAndEdited = { selection: 'selected'; wave: Wavepath; editedWave: Wavepath };
export type WaveSelection = NoWaveSelected | WaveSelected | WaveSelectedAndEdited;

export function isAnyWaveSelected(selection: WaveSelection): selection is WaveSelected {
    return selection.selection === 'selected';
}

export function isWaveSelected(selection: WaveSelection, waveId: string) {
    return isAnyWaveSelected(selection) && selection.wave.id === waveId;
}

export function isAnyWaveEdited(selection: WaveSelection): selection is WaveSelectedAndEdited {
    return isAnyWaveSelected(selection) && !!selection.editedWave;
}

export function isWaveEdited(selection: WaveSelection, waveId: string): selection is WaveSelectedAndEdited {
    return isAnyWaveEdited(selection) && selection.wave.id === waveId;
}

export function isDiscardingEdits(newSelection: WaveSelection, previousSelection: WaveSelection) {
    if (isAnyWaveEdited(previousSelection)) {
        return !isWaveEdited(newSelection, previousSelection.wave.id);
    }
    return false;
}

export function selectNoWave(): NoWaveSelected {
    return { selection: 'none' };
}

export function selectWave(wave: Wavepath): WaveSelected {
    return { selection: 'selected', wave, editedWave: null };
}

export function waveEdited(wave: Wavepath, editedWave: Wavepath): WaveSelected | WaveSelectedAndEdited {
    const isEdited = !isEqual(wave, editedWave);
    if (isEdited) {
        return { selection: 'selected', wave, editedWave };
    } else {
        return { selection: 'selected', wave, editedWave: null };
    }
}
