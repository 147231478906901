import './ScoreInputs.scss';

import React, { ReactElement } from 'react';
import { ValueType } from 'react-select';
import {
    SESSION_SCORE_MODALITIES,
    SessionScoreAdministration,
    SessionScoreDosage,
    SessionScoreModality,
    SessionScoreTemplate,
} from 'wavepaths-shared/core';
import { isMedicineAvailableForScores } from 'wavepaths-shared/domain/scores';

import { Typography } from '@/component-library';

import Select from '../../common/components/Inputs/Select';
import AdministrationInput from './AdministrationInput';
import DosageInput from './DosageInput';
import ModalityInfoDialog from './infodialogs/ModalityInfoDialog';
import { VariablesGroup, VariablesGroupInput } from './VariablesGroup';

export type ModalityInputsProps = {
    availableSessionScores: SessionScoreTemplate[];
    administration?: SessionScoreAdministration;
    onAdministrationChanged: (administration: SessionScoreAdministration) => void;
    dosage?: SessionScoreDosage;
    onDosageChanged: (dosage: SessionScoreDosage) => void;
    modality: SessionScoreModality;
    onModalityChanged: (medicine: SessionScoreModality) => void;
};

type ModalityOption = {
    label: string;
    value: SessionScoreModality;
};

function MedicineInputs({
    availableSessionScores,
    modality,
    onModalityChanged,
    dosage,
    onDosageChanged,
    administration,
    onAdministrationChanged,
}: ModalityInputsProps): ReactElement {
    const availableModalities = SESSION_SCORE_MODALITIES.filter((modality: SessionScoreModality) =>
        isMedicineAvailableForScores(modality, availableSessionScores),
    );

    const modalityOptions: ModalityOption[] = availableModalities.map((modality: SessionScoreModality) => ({
        label: modality,
        value: modality,
    }));

    const selectedModalityOption = modalityOptions.find((option) => option.value === modality);

    return (
        <VariablesGroup classNames="tour-medicineInputs">
            <VariablesGroupInput>
                <label htmlFor="modalityInput">
                    <Typography variant="body2" component="span">
                        Modality
                        <ModalityInfoDialog />
                    </Typography>
                </label>
                <Select
                    name="Modality"
                    inputId="modalityInput"
                    value={selectedModalityOption}
                    onChange={(selection: ValueType<ModalityOption>) => {
                        // TODO: update ReactSelect so we can avoid their legacy broken typings and remove the workaround
                        // https://github.com/JedWatson/react-select/issues/2902#issuecomment-864424022
                        const { value } = selection as ModalityOption;
                        onModalityChanged(value);
                    }}
                    options={modalityOptions}
                    isSearchable={false}
                />
            </VariablesGroupInput>
            <VariablesGroupInput>
                {administration && (
                    <AdministrationInput
                        administration={administration}
                        modality={modality}
                        onAdministrationChanged={onAdministrationChanged}
                    />
                )}
            </VariablesGroupInput>
            <VariablesGroupInput>
                {administration && dosage && (
                    <DosageInput
                        administration={administration}
                        dosage={dosage}
                        modality={modality}
                        onDosageChanged={onDosageChanged}
                    />
                )}
            </VariablesGroupInput>
        </VariablesGroup>
    );
}

export default MedicineInputs;
