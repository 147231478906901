import styled from '@emotion/styled';
import Box from '@material-ui/core/Box';
import firebase from 'firebase';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { APISessionStatus, SessionType, UserData } from 'wavepaths-shared/core';
import { isAdmin as checkAdmin } from 'wavepaths-shared/domain/user';

import { Typography } from '@/component-library';
import PageHeading from '@/component-library/components/PageHeading';
import { ErrorBox, SearchBar, SubscribeModal, TabLabelWithCount, Tabs } from '@/components';
import HelpButton from '@/components/HelpButton';
import Snackbar from '@/components/Snackbar';
import { Features, useFeatures } from '@/features';
import { useDebounce, useSnackbar } from '@/hooks';

import * as audio from '../../audio';
import { AuthContext } from '../../auth';
import { LayoutContainer } from '../../LayoutContainer';
import EndedSessionsTable from '../sessions/EndedSessionsTable';
import OngoingSessionsTable from '../sessions/OngoingSessionsTable';
import ScheduledSessionsTable from '../sessions/ScheduledSessionsTable';
import useSessions from '../sessions/useSessions';

enum TabIndex {
    SCHEDULED = 0,
    ACTIVE = 1,
    COMPLETED = 2,
}

const TabContent = styled.div({
    paddingTop: '24px',
});

const LockedSessionsContainer = styled(Box)((props: { isLocked: boolean }) => ({
    flexDirection: 'column',
    display: 'flex',
    opacity: props.isLocked ? 0.5 : 1,
    cursor: props.isLocked ? 'pointer' : 'initial',
}));

const NoResults: React.FC = () => (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Typography variant="body2" style={{ color: '#2c3958' }}>
            No sessions found
        </Typography>
    </div>
);

const Container = styled.div`
    position: relative;
    min-height: 100%;
    display: grid;
    grid-auto-flow: row;
    align-items: start;
    padding: 0 20px 80px 20px;
    max-width: 1024px;
    margin: auto;

    &--primaryActions {
        button {
            margin-bottom: 4px;
            margin-top: 4px;
            margin-right: 10px;
            &:last-of-type {
                margin-right: 0px;
            }
        }
    }
`;

const Sessions: React.FC = () => {
    const { isEnabled } = useFeatures();

    const authCtx = useContext(AuthContext);
    const fbUser = authCtx.firebaseUser as firebase.User; // page will not have loaded if firebase user is undefined
    const isAdmin = checkAdmin(authCtx.userData as UserData);

    const sessionSignups = {};
    const [searchTerm, setSearchTerm] = useState('');
    const [activeTab, setActiveTab] = useState(TabIndex.ACTIVE);
    const [userTouchedTabs, setUserTouchedTabs] = useState(false);

    const { closeSnackbar, content: snackbarContent } = useSnackbar();

    const [modalIsOpen, setModalIsOpen] = useState<boolean | undefined>(undefined);

    const scheduled = useSessions({
        fbUser,
        name: useDebounce(searchTerm),
        sessionState: APISessionStatus.SCHEDULED,
        type: isAdmin ? undefined : SessionType.ONE_ON_ONE,
        paginated: false,
    });
    const ongoing = useSessions({
        fbUser,
        name: useDebounce(searchTerm),
        sessionState: APISessionStatus.ACTIVE,
        type: isAdmin ? undefined : SessionType.ONE_ON_ONE,
        paginated: false,
    });
    const ended = useSessions({
        fbUser,
        name: useDebounce(searchTerm),
        sessionState: APISessionStatus.ENDED,
        type: isAdmin ? undefined : SessionType.ONE_ON_ONE,
        paginated: true,
    });

    if (!fbUser) {
        throw new Error('no logged in user on sessions screen');
    }

    useEffect(() => {
        if (!userTouchedTabs && !ongoing.loading) {
            if (ongoing.sessions.length > 0) {
                setActiveTab(TabIndex.ACTIVE);
            } else if (!scheduled.loading) {
                if (scheduled.sessions.length > 0) {
                    setActiveTab(TabIndex.SCHEDULED);
                } else if (!ended.loading && ended.totalCount > 0) {
                    setActiveTab(TabIndex.COMPLETED);
                }
            }
        }
    }, [scheduled.loading, ongoing.loading, ended, ongoing.sessions, scheduled.sessions, userTouchedTabs]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sessionStartFailed, setSessionStartFailed] = useState<boolean>(false);

    const enableAudio = useCallback(() => {
        audio.audioCtx.resume();
    }, []);

    const onTabChange = (newTab: number) => {
        if (!userTouchedTabs) setUserTouchedTabs(true);
        setActiveTab(newTab);
    };

    const handleDisabledContentClick = (e: any) => {
        if (!isEnabled(Features.SESSION_DASHBOARD)) {
            e.stopPropagation();
            setModalIsOpen(true);
            return;
        }
    };

    return (
        <Box minHeight="100vh">
            <PageHeading text={'Your sessions'} />
            <Container className="sessionsv2">
                {sessionStartFailed && (
                    <div className="errorbox">
                        <ErrorBox
                            message={'Unable to start the session. Please check your network connection and try again'}
                        />
                    </div>
                )}
                <LockedSessionsContainer
                    isLocked={!isEnabled(Features.SESSION_DASHBOARD)}
                    onClickCapture={handleDisabledContentClick}
                >
                    <SearchBar
                        value={searchTerm}
                        label="Find sessions"
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                        onClear={() => {
                            setSearchTerm('');
                        }}
                    />
                    <Tabs
                        className="tour-sessionTables"
                        activeTab={activeTab}
                        onChange={onTabChange}
                        content={[
                            {
                                identifier: 'scheduled',
                                label: (
                                    <TabLabelWithCount
                                        name="Scheduled"
                                        count={scheduled.sessions?.length ?? 0}
                                        loading={scheduled.loading}
                                    />
                                ),
                                body: (
                                    <TabContent>
                                        {!scheduled.loading && scheduled.sessions?.length === 0 ? (
                                            <NoResults />
                                        ) : (
                                            <ScheduledSessionsTable
                                                sessions={scheduled.sessions}
                                                loading={scheduled.loading}
                                                isAdmin={isAdmin}
                                                onLinkClick={enableAudio}
                                                //@ts-ignore
                                                firebaseUser={fbUser}
                                            />
                                        )}
                                    </TabContent>
                                ),
                            },
                            {
                                identifier: 'active',
                                label: (
                                    <TabLabelWithCount
                                        name="Active"
                                        count={ongoing.sessions?.length ?? 0}
                                        loading={ongoing.loading}
                                    />
                                ),
                                body: (
                                    <TabContent>
                                        {!ongoing.loading && ongoing.sessions?.length === 0 ? (
                                            <NoResults />
                                        ) : (
                                            <OngoingSessionsTable
                                                sessions={ongoing.sessions}
                                                loading={ongoing.loading}
                                                sessionsError={ongoing.error}
                                                fbUser={fbUser}
                                                isAdmin={isAdmin}
                                                onLinkClick={enableAudio}
                                            />
                                        )}
                                    </TabContent>
                                ),
                            },
                            {
                                identifier: 'completed',
                                label: (
                                    <TabLabelWithCount
                                        name="Completed"
                                        count={ended.totalCount ?? 0}
                                        loading={ended.loading}
                                    />
                                ),
                                body: (
                                    <TabContent>
                                        {!ended.loading && ended.sessions?.length === 0 ? (
                                            <NoResults />
                                        ) : (
                                            <EndedSessionsTable
                                                endedSessionsInterface={ended}
                                                signups={sessionSignups}
                                                isAdmin={isAdmin}
                                                fbUser={fbUser}
                                            />
                                        )}
                                    </TabContent>
                                ),
                            },
                        ]}
                    />
                </LockedSessionsContainer>
                {/* TODO: Refactor and Replace Intro Session with Product Tour carousel */}
                <SubscribeModal isOpen={!!modalIsOpen} closeModal={() => setModalIsOpen(false)} />
                <Snackbar
                    type="error"
                    isLongButton={false}
                    message={snackbarContent}
                    confirmText={'OK'}
                    open={snackbarContent !== null}
                    closeSnackbar={closeSnackbar}
                />
            </Container>

            <HelpButton />
        </Box>
    );
};

function SessionsWithNav() {
    return (
        <LayoutContainer>
            <Sessions />
        </LayoutContainer>
    );
}

export default SessionsWithNav;
