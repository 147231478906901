import styled from '@emotion/styled';
import { Switch } from '@material-ui/core';
import React from 'react';

const Container = styled.div`
    width: 100vw;
    background: var(--primary-200, #e9d7fe);
    padding: 8px;
    text-align: center;
    color: #7f56d9;
`;

function SwitchUIBanner({ showNewUi, setShowNewUI }: { showNewUi?: boolean; setShowNewUI: (val: boolean) => void }) {
    const handleClick = () => {
        setShowNewUI(!showNewUi);
    };

    return (
        <Container>
            {showNewUi
                ? 'You are currently viewing the new version of Wavepaths, click here to switch to the old design'
                : 'You are currently viewing the old version of Wavepaths, click here to switch to the new design'}
            <Switch checked={showNewUi} onClick={handleClick} />
        </Container>
    );
}

export default SwitchUIBanner;
