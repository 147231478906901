import './SliderInput.scss';

import { isEmpty } from 'lodash';
import React from 'react';
import { ReactElement } from 'react';
import { ValueType } from 'react-select';
import { SessionScoreAdministration, SessionScoreDosage, SessionScoreModality } from 'wavepaths-shared/core';

import { Typography } from '@/component-library';

import Select from '../../common/components/Inputs/Select';
import { getDosagesForMedicineAndAdministration } from '../../common/domain/modalitiesOld';
import DosageInfoDialog from './infodialogs/DosageInfoDialog';

export interface IDosageInputProps {
    dosage: SessionScoreDosage;
    modality: SessionScoreModality;
    administration: SessionScoreAdministration;
    onDosageChanged: (dosage: SessionScoreDosage) => void;
}

interface IDosageOption {
    label: string;
    value: SessionScoreDosage | '';
}

function DosageInput({ dosage, modality, administration, onDosageChanged }: IDosageInputProps): ReactElement {
    const dosages = getDosagesForMedicineAndAdministration(modality, administration);

    const dosageOptions: IDosageOption[] = dosages.map(({ id, name }) => ({
        value: id,
        label: name,
    }));

    const selectedDosage = dosageOptions.find((option) => option.value === dosage);

    if (isEmpty(dosages)) return <></>;
    return (
        <>
            <label htmlFor="dosageInput">
                <Typography variant="body2" component="span">
                    Dosage
                    <DosageInfoDialog />
                </Typography>
            </label>

            <Select
                data-testid="dosage-select"
                name="DosageInput"
                inputId="dosageInput"
                className="input inputSelect inputSelect--small"
                value={selectedDosage}
                isSearchable={false}
                options={dosageOptions}
                // placeholder={placeholder}
                onChange={(option: ValueType<IDosageOption>) => {
                    // TODO: update ReactSelect so we can avoid their legacy broken typings and remove the workaround
                    // https://github.com/JedWatson/react-select/issues/2902#issuecomment-864424022
                    onDosageChanged((option as IDosageOption).value as SessionScoreDosage);
                }}
            />
        </>
    );
}

export default DosageInput;
