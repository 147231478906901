import { useSpring } from '@react-spring/core';
import { clamp } from 'lodash';
import { useEffect } from 'react';
import { Interpolation } from 'react-spring';
import { SessionTick, Wavepath } from 'wavepaths-shared/core';

import { Connection, subscribeTick } from '../../../common/hooks/useSessionTick';
import { TimelineWaveProperties } from './timelineWaveUtils';

interface UseTimelineTransformProps {
    timelinePosition: 'fixed' | 'moving';
    currentWave: Wavepath | null;
    selectedWave: TimelineWaveProperties | null;
    pixelsPerMillis: number;
    wrapperWidth: number;
    sessionDuration: number;
    connection?: Connection;
}
export function useTimelineTransform({
    timelinePosition,
    currentWave,
    selectedWave,
    pixelsPerMillis,
    wrapperWidth,
    sessionDuration,
    connection,
}: UseTimelineTransformProps): Interpolation<number, string> {
    const timelineWidth = Math.max(pixelsPerMillis * sessionDuration, 0);
    const [timelineX, setTimelineX] = useSpring(() => ({ x: 0 }));

    useEffect(() => {
        if (timelinePosition === 'fixed') {
            setTimelineX({ x: 0, immediate: true });
            return;
        }

        const getTimelineXShift = (playheadX: number) => {
            const desiredCenterX = selectedWave ? selectedWave.x + selectedWave.width / 2 : playheadX;
            const wrapperCenterX = wrapperWidth / 2;
            const xShiftNeededForDesiredCenter = wrapperCenterX - desiredCenterX;
            const minXShift = -(timelineWidth - wrapperWidth);
            const maxXShift = 0;
            return clamp(xShiftNeededForDesiredCenter, minXShift, maxXShift);
        };

        if (!currentWave || currentWave.type === 'pre') {
            setTimelineX({ x: getTimelineXShift(0) });
        } else if (currentWave.type === 'post') {
            setTimelineX({ x: getTimelineXShift(pixelsPerMillis * sessionDuration) });
        } else {
            const onTick = (tick: SessionTick) => {
                const clampedEffectiveTime = Math.min(tick.effectiveTime, tick.sessionDuration);
                const x = getTimelineXShift(clampedEffectiveTime * pixelsPerMillis);
                const delta = Math.abs(timelineX.x.get() - x);
                const immediate = delta < 30;
                setTimelineX({ x, immediate });
            };
            return subscribeTick(connection, onTick);
        }
    }, [
        timelinePosition,
        currentWave,
        selectedWave,
        pixelsPerMillis,
        wrapperWidth,
        timelineWidth,
        sessionDuration,
        connection,
    ]);

    return timelineX.x.to((x) => `translateX(${x}px)`);
}
