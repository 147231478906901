import './VariablesGroup.scss';

import React from 'react';

interface VariablesGroupProps {
    title?: string;
    children: React.ReactNode;
    classNames?: string;
}
export const VariablesGroup: React.FC<VariablesGroupProps> = ({ title, children, classNames }) => {
    return (
        <div className={classNames ? `variablesGroup ${classNames}` : 'variablesGroup'}>
            {title && <h2>{title}</h2>}
            <div className="variablesGroupInner">{children}</div>
        </div>
    );
};

export const VariablesGroupInput: React.FC = ({ children }) => {
    return (
        <div className="variablesGroupInput">
            <div className="variablesGroupInputInner">{children}</div>
        </div>
    );
};
