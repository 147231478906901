import { SessionScoreAdministration, SessionScoreDosage, SessionScoreModality } from 'wavepaths-shared/core';

import { NonPATModality, PATModality } from './types';

const KETAMINE: PATModality = {
    id: 'ketamine',
    name: SessionScoreModality.KETAMINE,
    type: 'PAT',
    administrations: [
        {
            route: SessionScoreAdministration.Intramuscular,
            dosage: SessionScoreDosage.LOW,
            durationMins: 55,
            phases: [],
        },
        {
            route: SessionScoreAdministration.Intramuscular,
            dosage: SessionScoreDosage.MEDIUM,
            durationMins: 65,
            phases: [],
        },
        {
            route: SessionScoreAdministration.Intramuscular,
            dosage: SessionScoreDosage.HIGH,
            durationMins: 75,
            phases: [],
        },
        {
            route: SessionScoreAdministration.Intravenous,
            dosage: SessionScoreDosage.LOW,
            durationMins: 35,
            phases: [],
        },
        {
            route: SessionScoreAdministration.Intravenous,
            dosage: SessionScoreDosage.MEDIUM,
            durationMins: 60,
            phases: [],
        },
        {
            route: SessionScoreAdministration.Intravenous,
            dosage: SessionScoreDosage.HIGH,
            durationMins: 75,
            phases: [],
        },
        {
            route: SessionScoreAdministration.Sublingual,
            dosage: SessionScoreDosage.LOW,
            durationMins: 65,
            phases: [],
        },
        {
            route: SessionScoreAdministration.Sublingual,
            dosage: SessionScoreDosage.MEDIUM,
            durationMins: 110,
            phases: [],
        },
        {
            route: SessionScoreAdministration.Sublingual,
            dosage: SessionScoreDosage.HIGH,
            durationMins: 120,
            phases: [],
        },
        {
            route: SessionScoreAdministration.Nasal,
            dosage: SessionScoreDosage.LOW,
            durationMins: 45,
            phases: [],
        },
        {
            route: SessionScoreAdministration.Nasal,
            dosage: SessionScoreDosage.MEDIUM,
            durationMins: 55,
            phases: [],
        },
        {
            route: SessionScoreAdministration.Nasal,
            dosage: SessionScoreDosage.HIGH,
            durationMins: 65,
            phases: [],
        },
    ],
};

const PSILOCYBIN: PATModality = {
    id: 'psilo',
    type: 'PAT',
    name: SessionScoreModality.PSILOCYBIN,
    administrations: [
        {
            route: SessionScoreAdministration.Oral,
            dosage: SessionScoreDosage.LOW,
            durationMins: 240,
            phases: [],
        },
        {
            route: SessionScoreAdministration.Oral,
            dosage: SessionScoreDosage.MEDIUM,
            durationMins: 300,
            phases: [],
        },
        {
            route: SessionScoreAdministration.Oral,
            dosage: SessionScoreDosage.HIGH,
            durationMins: 360,
            phases: [],
        },
    ],
};

const MDMA: PATModality = {
    id: 'mdma',
    type: 'PAT',
    name: SessionScoreModality.MDMA,
    administrations: [
        {
            route: SessionScoreAdministration.Oral,
            dosage: SessionScoreDosage.LOW,
            durationMins: 240,
            phases: [],
        },
        {
            route: SessionScoreAdministration.Oral,
            dosage: SessionScoreDosage.MEDIUM,
            durationMins: 300,
            phases: [],
        },
        {
            route: SessionScoreAdministration.Oral,
            dosage: SessionScoreDosage.HIGH,
            durationMins: 360,
            phases: [],
        },
    ],
};

const IBOGAINE: PATModality = {
    id: 'ibogaine',
    type: 'PAT',
    name: SessionScoreModality.IBOGAINE,
    administrations: [
        {
            route: SessionScoreAdministration.Oral,
            dosage: SessionScoreDosage.MEDIUM,
            durationMins: 524,
            phases: [],
        },
    ],
};

const FLOATATION: NonPATModality = {
    id: 'float',
    type: 'Non-PAT',
    name: SessionScoreModality.FLOATATION,
    durationMins: 60,
};

const PSYCHOTHERAPY: NonPATModality = {
    id: 'psychotherapy',
    type: 'Non-PAT',
    name: SessionScoreModality.PSYCHOTHERAPY,
    durationMins: 50,
};

const BREATHWORK: NonPATModality = {
    id: 'breathwork',
    type: 'Non-PAT',
    name: SessionScoreModality.BREATHWORK,
    durationMins: 14,
};

const NO_MEDICINE: NonPATModality = {
    id: 'no-med',
    type: 'Non-PAT',
    name: SessionScoreModality.NONE,
    durationMins: 35,
};

export const MODALITIES = [KETAMINE, PSILOCYBIN, MDMA, IBOGAINE, PSYCHOTHERAPY, FLOATATION, BREATHWORK, NO_MEDICINE];
export const MODALITIES_V2 = [KETAMINE, PSILOCYBIN, MDMA, IBOGAINE, PSYCHOTHERAPY, FLOATATION, BREATHWORK];

export const getAllModalities = () => MODALITIES;
