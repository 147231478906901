import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

import { Typography } from '@/component-library';

import { VariablesGroup, VariablesGroupInput } from './VariablesGroup';

const Input = styled.input({
    borderColor: '#2C3958',
});

interface SessionNameInputsProps {
    sessionName: string;
    onSessionNameChanged: (name: string) => void;
}

export function SessionNameInput({ sessionName, onSessionNameChanged }: SessionNameInputsProps) {
    const [internalName, setInternalName] = useState(sessionName);
    useEffect(() => setInternalName(sessionName), [sessionName]);

    return (
        <VariablesGroup classNames="tour-sessionNameInput">
            <VariablesGroupInput>
                <label htmlFor="sessionNameInput">
                    <Typography variant="body2" component="span">
                        Session Name (optional)
                    </Typography>
                </label>
                <Input
                    maxLength={100}
                    type="text"
                    id={'sessionNameInput'}
                    value={internalName ?? ''}
                    onChange={(evt) => setInternalName(evt.target.value)}
                    onBlur={(evt) => onSessionNameChanged(evt.target.value)}
                />
            </VariablesGroupInput>
        </VariablesGroup>
    );
}
