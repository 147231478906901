import styled from '@emotion/styled';
import React from 'react';
import DateTimePicker from 'react-datetime-picker';

const DateTimeComponent = styled(DateTimePicker)<{ disabled: boolean }>(({ disabled }) => ({
    '&.react-datetime-picker': {
        '&:hover': {
            border: 'none',
        },
        '&:focus': {
            border: 'none',
        },
    },
    opacity: disabled ? 0.3 : 1,
    '.react-datetime-picker__wrapper': {
        height: '44px',
        borderRadius: '4px',
        border: '1px solid #2c3958',
        paddingTop: 0,
        paddingBottom: 0,
        background: 'transparent',
    },
    '.react-datetime-picker__inputGroup__input': {
        paddingTop: 0,
        paddingBottom: 0,
    },
    '.react-datetime-picker__calendar': {
        zIndex: 2,
    },
    '.react-datetime-picker__clock': {
        zIndex: 2,
    },
    '.react-datetime-picker__inputGroup__amPm': {
        display: 'inline',
        width: '24px',
    },
}));

function DateTimeInput(props: any) {
    return (
        <DateTimeComponent
            monthAriaLabel="Month"
            dayAriaLabel="Day"
            yearAriaLabel="Year"
            hourAriaLabel="Hour"
            minuteAriaLabel="Minute"
            amPmAriaLabel="Select AM/PM"
            {...props}
        />
    );
}

export default DateTimeInput;
