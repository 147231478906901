import { isPast } from 'date-fns';
import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SessionHeader, SessionScoreDosage } from 'wavepaths-shared/core';

import { Dialog } from '@/component-library';

import * as api from '../../common/api/sessionApi';
import { convertSessionToSessionTemplate } from '../../common/api/sessionTemplatesApi';
import { getSessionLink } from '../../common/util/broadcastLinkUtils';
import { formatDateTime } from '../../dateUtilsV2';
import UserEvents, { CopiedSessionTab } from '../../UserEvents';
import { isOngoing, renderSessionName } from './helpers';
import SessionCard, { ISessionAction } from './SessionCard';

type LoadingSessions = { [key: string]: undefined | boolean };

const isSessionStarting = (session: SessionHeader, loadingSessions: { [id: string]: boolean | undefined }) => {
    return loadingSessions[session.id] || (!!session.scheduledStart && isPast(session.scheduledStart));
};

const DOSAGE_LABELS: { [key: number]: string } = {
    [SessionScoreDosage.LOW]: 'Low',
    [SessionScoreDosage.MEDIUM]: 'Medium',
    [SessionScoreDosage.HIGH]: 'High',
};

const getSubtitle = (medicine: string, dosage: string | number | undefined): string => {
    if (medicine === 'undefined' || medicine === 'None') return 'Non Drug';
    if (dosage && typeof dosage === 'number') return `${medicine} ${DOSAGE_LABELS[dosage]}`;
    if (dosage) return `${medicine} ${dosage}`;
    return medicine;
};

interface IProps {
    session: SessionHeader;
    loadingSessions: LoadingSessions;
    setLoadingSessions: (loadingSessions: LoadingSessions) => void;
    isAdmin: boolean;
    onLinkClick: () => void;
    setCopiedLinkId: (copiedLinkId?: string) => void;
    copiedLinkId?: string;
    firebaseUser: firebase.User;
}

const ScheduledSessionCard = ({
    session,
    loadingSessions,
    setLoadingSessions,
    isAdmin,
    onLinkClick,
    copiedLinkId,
    setCopiedLinkId,
    firebaseUser,
}: IProps): JSX.Element => {
    const history = useHistory();
    const [linkCopyText, setLinkCopyText] = useState<'Copy Client Link' | 'Copied'>('Copy Client Link');
    const [confirmStartOpen, setConfirmStartOpen] = useState(false);
    const [confirmCancelOpen, setConfirmCancelOpen] = useState(false);

    const sessionName = renderSessionName(session);

    const onCancelSessionConfirmed = () => {
        firebaseUser && api.deleteSession(session.id, 0, firebaseUser);
        setConfirmCancelOpen(false);
    };

    const onCloseConfirmCancel = () => {
        setConfirmCancelOpen(false);
    };

    const handleDelete = () => {
        setConfirmCancelOpen(true);
    };

    const handleMakeCopy = async () => {
        UserEvents.copiedSession(CopiedSessionTab.SCHEDULED);
        const fullSession = await api.getSession(session.id, firebaseUser);
        const sessionTemplate = convertSessionToSessionTemplate(fullSession);
        history.push({
            pathname: '/session/new/oneOnOne',
            state: { sessionTemplate }, // your data array of objects
        });
    };

    const startSessionEarly = () => {
        setLoadingSessions({ ...loadingSessions, [session.id]: true });
        api.startSessionEarly(session.id, firebaseUser).then(() =>
            setLoadingSessions({ ...loadingSessions, [session.id]: false }),
        );
    };
    const handleStartNow = () => {
        setConfirmStartOpen(true);
    };

    const onOpen = () => {
        history.push(`/session/${session.id}`);
        onLinkClick();
    };

    const onClickLog = () => {
        history.push(`/logs/${session.id}`);
    };

    const onCloseConfirmStart = () => {
        setConfirmStartOpen(false);
    };

    const onConfirmStart = () => {
        setConfirmStartOpen(false);
        startSessionEarly();
    };

    const copyClientLink = () => {
        const link = getSessionLink(session.broadcastIdentifier);
        if (link) {
            setLinkCopyText('Copied');
            navigator.clipboard.writeText(link);
            setCopiedLinkId(session.id);
        }
    };

    const onStartSessionInPrelude = isSessionStarting(session, loadingSessions)
        ? () => {
              //do nothing
          }
        : handleStartNow;

    useEffect(() => {
        if (copiedLinkId !== session.id && linkCopyText !== 'Copy Client Link') {
            setLinkCopyText('Copy Client Link');
        }
    }, [copiedLinkId, session.id, linkCopyText]);

    const userInfoText = session?.user?.name;

    const subtitle = getSubtitle(`${session?.score?.selectionCriteria?.medicine}`, session.variableInputs.dosage);

    const actions = [
        { description: linkCopyText, action: copyClientLink },
        { description: 'Delete', action: handleDelete },
        ...(isAdmin ? [{ description: 'Log', action: onClickLog }] : []),
        { description: 'Make A Copy of This Session', action: handleMakeCopy },
    ] as ISessionAction[];

    return (
        <React.Fragment key={session.id}>
            <Dialog
                fullWidth={true}
                open={confirmCancelOpen}
                onClose={onCloseConfirmCancel}
                onConfirm={onCancelSessionConfirmed}
                message={`Are you sure you want to delete the "${sessionName}" session?`}
                confirmText={'Delete Session'}
            />
            <Dialog
                fullWidth={true}
                open={confirmStartOpen}
                onClose={onCloseConfirmStart}
                onConfirm={onConfirmStart}
                message={`Are you sure you want to start "${sessionName}" now?`}
                confirmText={'Start Session'}
            />
            <SessionCard
                title={sessionName}
                subtitle={subtitle}
                userInfoText={isAdmin ? userInfoText : undefined}
                primaryActionLabel={isOngoing(session) ? 'Open' : 'Start Session in Prelude'}
                primaryAction={isOngoing(session) ? onOpen : onStartSessionInPrelude}
                actions={actions}
                info={[
                    isSessionStarting(session, loadingSessions)
                        ? 'Starting'
                        : session.scheduledStart
                        ? `Starts ${formatDateTime(session.scheduledStart)}`
                        : 'Saved for later',
                ]}
            />
        </React.Fragment>
    );
};
export default ScheduledSessionCard;
