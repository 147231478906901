/* eslint-disable simple-import-sort/imports */
import { Feature, Features } from '@/features';
import { Typography } from '@/component-library';
import React from 'react';
import { ValueType } from 'react-select';
import { ClientVariablesMusicPreference } from 'wavepaths-shared/core';

import Select from '../../common/components/Inputs/Select';
import MusicalPreferenceInfoDialog from './infodialogs/MusicalPreferenceInfoDialog';
import SessionScoreSelector, { SessionScoreSelectorProps } from './SessionScoreSelector';
import { TemplateRecommenderBanner } from './TemplateRecommenderBanner';
import { VariablesGroup, VariablesGroupInput } from './VariablesGroup';

type MusicalPreferenceOption = {
    label: string;
    value: ClientVariablesMusicPreference;
};

export const MusicPreferenceLabels: [any, string][] = [
    [ClientVariablesMusicPreference.ACOUSTIC, 'Prefer Acoustic'],
    [ClientVariablesMusicPreference.MIXED, 'Mixed Acoustic/Electronic'],
    [ClientVariablesMusicPreference.ELECTRONIC, 'Prefer Electronic'],
];

type MusicalInputsProps = {
    musicalPreference: ClientVariablesMusicPreference;
    onMusicalPreferenceChanged: (musicalPreference: ClientVariablesMusicPreference) => void;
} & SessionScoreSelectorProps;

export function MusicalInputs({
    currentSessionTemplate,
    onSessionScoreChange,
    availableSessionScores,
    musicalPreference,
    onMusicalPreferenceChanged,
}: MusicalInputsProps): JSX.Element {
    const musicalPreferenceOptions = MusicPreferenceLabels.map(([value, label]) => ({ label, value }));
    const selectedMusicalPreference = musicalPreferenceOptions.find((option) => option.value === musicalPreference);

    return (
        <>
            <Feature activeComponent={<TemplateRecommenderBanner />} name={Features.TEMPLATE_RECOMMENDER} />
            <VariablesGroup title="" classNames="tour-musicalInputs">
                <VariablesGroupInput>
                    <SessionScoreSelector
                        currentSessionTemplate={currentSessionTemplate}
                        availableSessionScores={availableSessionScores}
                        onSessionScoreChange={onSessionScoreChange}
                    />
                </VariablesGroupInput>
                <VariablesGroupInput>
                    <label htmlFor="musicalPreferenceInput">
                        <Typography variant="body2" component="span">
                            Musical Preference
                            <MusicalPreferenceInfoDialog />
                        </Typography>
                    </label>
                    <Select
                        name="Musical Preference"
                        options={musicalPreferenceOptions}
                        inputId="musicalPreferenceInput"
                        value={selectedMusicalPreference}
                        isSearchable={false}
                        onChange={(selection: ValueType<MusicalPreferenceOption>) => {
                            const { value } = selection as MusicalPreferenceOption;
                            onMusicalPreferenceChanged(+value);
                        }}
                    />
                </VariablesGroupInput>
            </VariablesGroup>
        </>
    );
}
