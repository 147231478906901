import { compact, flatten } from 'lodash';
import { CoreEmotionalAtmosphere } from 'wavepaths-shared/core';

import { PendingBehaviour } from '../../../../freudConnection/PendingBehaviourTimer';
import { StatusBarListItem } from './types';

export const INTENSITY_LABEL = 'intensity';
export const createIntensityChangeMessage = (stageDepth: number, currentDepth?: number): string | undefined => {
    if (!currentDepth) return `changing ${INTENSITY_LABEL}`;
    if (stageDepth < currentDepth) return `increasing ${INTENSITY_LABEL}`;
    if (stageDepth > currentDepth) return `decreasing ${INTENSITY_LABEL}`;
    return;
};

export const createCEAChangeMessage = (
    currentCEA: CoreEmotionalAtmosphere,
    upcomingCEA: CoreEmotionalAtmosphere,
): string | undefined => {
    if (currentCEA !== upcomingCEA) return `changing to ${upcomingCEA}`;
};

export const createInstrumentRefreshMessage = (numberOfInstrumentsToRefresh: number | null): string | undefined => {
    if (!numberOfInstrumentsToRefresh) return;
    if (numberOfInstrumentsToRefresh === 1) return 'replacing 1 sound';
    return `replacing ${numberOfInstrumentsToRefresh} sounds`;
};

export const combineStrings = (first?: string, second?: string): string | undefined => {
    if (!first || !second) {
        return first || second || undefined;
    }
    return `${first} and ${second}`;
};

export const combineNStrings = (strings: (string | undefined)[]): string | undefined => {
    const remainingStrings = compact(strings);
    if (remainingStrings.length === 0) return undefined;
    const stringsWithSeparators = flatten(
        remainingStrings.map((string, index) => {
            if (index === remainingStrings.length - 1) return [string];
            if (index === remainingStrings.length - 2) return [string, ' and '];
            return [string, ', '];
        }),
    );
    const combinedStrings = stringsWithSeparators.reduce((prev, current) => (prev += current));
    return combinedStrings;
};

export const stagesToStatusBarItems = (
    stages: {
        cea: CoreEmotionalAtmosphere;
        intensity: number;
        timeStamp: number;
        numberOfInstrumentsToRefresh: number | null;
    }[],
    currentDepth: number,
    currentCea: CoreEmotionalAtmosphere,
    currentTime: number,
): StatusBarListItem[] =>
    compact(
        stages.map((stage, index, stages) => {
            const ceaChangeMessage = createCEAChangeMessage(
                index === 0 ? currentCea : stages[index - 1].cea,
                stage.cea,
            );
            const message: string | undefined = combineNStrings([
                createIntensityChangeMessage(index === 0 ? currentDepth : stages[index - 1].intensity, stage.intensity),
                ceaChangeMessage,
                ceaChangeMessage ? undefined : createInstrumentRefreshMessage(stage.numberOfInstrumentsToRefresh),
            ]);
            if (!message) return;
            return {
                message,
                timeUntilActive: stage.timeStamp - currentTime,
                key: String(stage.timeStamp),
            };
        }),
    );

export function getMessageSequence(pendingBehaviour?: PendingBehaviour): string[] {
    if (!pendingBehaviour) return [];
    switch (pendingBehaviour.behaviourMessage) {
        case 'launch':
            return ['Preparing session'];
        case 'audioBuffering':
            return ['Connecting to audio'];
        case 'initialFadeIn':
            return ['Slowly fading in music'];
        case 'start':
            return ['Starting session'];
        case 'manualOn':
            return ['Showing manual adaptation controls'];
        case 'manualOff':
            return ['Hiding manual adaptation controls'];
        case 'autoOn':
            return ['Switching back to automatic guiding'];
        case 'autoOff':
            return ['Switching automatic music changes off'];
        case 'silence':
            return ['Fading into silence'];
        case 'resumeFromSilence':
            return ['Fading back in'];
        case 'goToNewVATarget':
            return ['Slowly transitioning music to new emotional target'];
        case 'goToNewStage':
            return ['Slowly transitioning music to new depth'];
        case 'increasingIntensity':
            return ['Increasing intensity'];
        case 'decreasingIntensity':
            return ['Decreasing intensity'];
        case 'changingIntensity':
            return ['Changing intensity'];
        case 'changeCea':
            return ['Changing emotionality'];
        case 'goToNewVAAndStage':
            return ['Slowly transitioning music to new emotionality and depth'];
        case 'refreshMusic':
            return ['Switching sounds'];
        case 'reviseSessionPlan':
            return ['Adjusting session'];
        case 'jumpForward':
            return ['Jumping forward'];
        case 'skipToWave':
            return ['Skipping to wave'];
        case 'stop':
            return ['Ending session'];
        default:
            return ['Processing'];
    }
}
