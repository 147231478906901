import './UnsupportedBanner.scss';

import React, { ReactElement } from 'react';
import { isChrome, isDesktop, isIPad13, isMobileSafari, isSafari } from 'react-device-detect';

import { Banner } from './Banner';

function UnsupportedBanner(): ReactElement {
    const isSupportedDesktop = isDesktop && (isSafari || isChrome);
    const isSupportedTablet = isIPad13 && !isChrome && (isSafari || isMobileSafari);

    const isSupported = isSupportedDesktop || isSupportedTablet;
    return (
        <>
            {!isSupported && (
                <Banner className="unsupportedBanner">
                    <h4>Warning</h4>
                    <p>You are using an unsupported device or browser.</p>
                    <p>
                        We are working hard to bring you cross-platform support, but for now, please use <b>Chrome</b>{' '}
                        or <b>Safari</b> on a <b>desktop or laptop</b>, or <b>Safari</b> on a recent <b>iPad</b>.
                    </p>
                </Banner>
            )}
        </>
    );
}

export default UnsupportedBanner;
