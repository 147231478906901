import './FeedbackTags.scss';

import classNames from 'classnames';
import { difference, includes, union } from 'lodash';
import React, { useEffect, useState } from 'react';

interface FeedbackTagsProps {
    allTags: string[];
    selectedTags: string[];
    isExclusive?: boolean;
    onToggleTag: (tag: string, on: boolean) => void;
}

export const FeedbackTags: React.FC<FeedbackTagsProps> = ({ allTags, selectedTags, isExclusive, onToggleTag }) => {
    const [selections, setSelections] = useState([] as string[]);
    useEffect(() => {
        setSelections(selectedTags);
    }, [selectedTags]);

    const toggleTag = (tag: string) => {
        const wasSelected = includes(selections, tag);
        setSelections((s) => (wasSelected ? difference(s, [tag]) : isExclusive ? [tag] : union(s, [tag])));
        onToggleTag(tag, !wasSelected);
    };

    return (
        <div
            className={classNames('feedbackTags', { taggingModeRadio: isExclusive, taggingModeCheckbox: !isExclusive })}
        >
            {allTags.map((tag) => (
                <button
                    key={tag}
                    className={classNames('feedbackTag', { isSelected: includes(selections, tag) })}
                    onClick={() => toggleTag(tag)}
                >
                    {tag}
                </button>
            ))}
        </div>
    );
};
