import { Card, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles({
    card: {
        backgroundColor: 'rgba(255,255,255,0.5)',
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        height: 136,
        alignSelf: 'stretch',
        justifyContent: 'space-between',
        boxShadow: 'none',
    },
    skeleton: {
        borderRadius: 3,
    },
    verticalSpacer: {
        height: 8,
    },
});

const SessionCardSkeleton = (): JSX.Element => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <div>
                <Skeleton className={classes.skeleton} variant="rect" width={211} height={24} />
                <div className={classes.verticalSpacer} />
                <Skeleton className={classes.skeleton} variant="rect" width={155} height={16} />
                <div className={classes.verticalSpacer} />
                <Skeleton className={classes.skeleton} variant="rect" width={137} height={16} />
            </div>
            <Skeleton className={classes.skeleton} variant="rect" width={137} height={14} />
        </Card>
    );
};

export default SessionCardSkeleton;
