import { invertBy, isNil, max, min } from 'lodash';
import { Depth, TickDepth } from 'wavepaths-shared/core';

export const DEPTH_MAPPING: { [systemDepth: string]: number } = {
    [0]: 0,
    [1]: 1,
    [2]: 1,
    [3]: 1,
    [4]: 2,
    [5]: 2,
    [6]: 3,
    [7]: 3,
    [8]: 3,
    [9]: 4,
    [10]: 4,
    [11]: 5,
    [12]: 5,
    [13]: 6,
};
export const MIN_UI_DEPTH = min(Object.values(DEPTH_MAPPING))!;
export const MAX_UI_DEPTH = max(Object.values(DEPTH_MAPPING))!;
export const MIN_SERVER_DEPTH = min(Object.keys(DEPTH_MAPPING).map((k) => +k))!;
export const MAX_SERVER_DEPTH = max(Object.keys(DEPTH_MAPPING).map((k) => +k))!;

export const mapServerDepthToUIDepth = (depth?: TickDepth): number | undefined => {
    return !isNil(depth) ? DEPTH_MAPPING[depth] : undefined;
};

export const mapUiDepthToServerDepth = (depth: number): Depth => {
    const val = invertBy(DEPTH_MAPPING)[depth][0];
    return Number(val);
};

export const MIN_DEEPEN_DEPTH = 6;
export const isInDeepen = (depth?: TickDepth): boolean => !!depth && depth >= MIN_DEEPEN_DEPTH;
