// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../images/iconNotificationConfirm.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../images/iconNotificationCancel.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../images/iconNotificationQuestion.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".notificationHeader{position:relative;display:grid;grid-auto-flow:column;grid-template-columns:auto 1fr auto;gap:5px}.notificationHeaderIcon{width:20px;height:20px;background-size:100% auto;background-position:center center;background-repeat:no-repeat}.feedbackLogged .notificationHeaderIcon,.feedbackCategorised .notificationHeaderIcon,.feedbackTextAdded .notificationHeaderIcon,.feedbackConcluded .notificationHeaderIcon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.feedbackCancelled .notificationHeaderIcon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.feedbackDialog .notificationHeaderIcon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.notificationHeaderText{height:20px;font-size:12px;font-weight:600;line-height:20px}.notificationHeaderAction{display:inline-block;padding:0;margin:0 0 0 10px;background:none;border:none;font-size:12px;font-weight:500;color:rgba(0,0,0,.6);cursor:pointer}.notificationHeaderAction:focus{outline:none}.notificationHeaderCloseButton{display:inline-grid;place-content:center;width:20px;height:20px;background:rgba(0,0,0,0);border:none;padding:0;margin:0;cursor:pointer}.notificationHeaderCloseButton:focus{outline:none}.notificationHeaderCloseButton img{display:block;width:100%}", ""]);
// Exports
module.exports = exports;
