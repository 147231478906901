import styled from '@emotion/styled';
import React from 'react';
import { DosageLevel } from 'wavepaths-shared/core';

export interface DosageIconProps {
    dosage: DosageLevel | 'none';
}

const Container = styled.div`
    width: 16px;
    height: 16px;
    display: grid;
    place-content: center;
    // padding-bottom: 2px;
`;

const Icon = styled.svg`
    width: 16px;
    height: 16px;
`;

const MarkerL = styled.rect<{
    dosage: DosageIconProps['dosage'];
}>(
    ({ dosage }) => `
    fill: ${
        dosage === DosageLevel.LOW || dosage === DosageLevel.MEDIUM || dosage === DosageLevel.HIGH
            ? '#667085'
            : '#D0D5DD'
    };
  `,
);

const MarkerCenter = styled.rect<{
    dosage: DosageIconProps['dosage'];
}>(
    ({ dosage }) => `
    fill: ${dosage === DosageLevel.MEDIUM || dosage === DosageLevel.HIGH ? '#667085' : '#D0D5DD'};
  `,
);

const MarkerR = styled.rect<{
    dosage: DosageIconProps['dosage'];
}>(
    ({ dosage }) => `
    fill: ${dosage === DosageLevel.HIGH ? '#667085' : '#D0D5DD'};
  `,
);

const DosageIcon: React.FC<DosageIconProps> = ({ dosage }) => {
    return (
        <Container>
            <Icon viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <clipPath id="round-corners">
                        <rect x="1" y="6" width="14" height="4" rx="2" ry="2" />
                    </clipPath>
                </defs>
                <MarkerL x="1" y="6" width="4" height="4" dosage={dosage} clipPath="url(#round-corners)" />
                <MarkerCenter x="6" y="6" width="4" height="4" dosage={dosage} />
                <MarkerR x="11" y="6" width="4" height="4" dosage={dosage} clipPath="url(#round-corners)" />
            </Icon>
        </Container>
    );
};

export default DosageIcon;
