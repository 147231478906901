import firebase from 'firebase';
import { first } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Session,
    SessionRenderType,
    SessionScoreDTO,
    SessionScoreSessionUse,
    SessionType,
    SessionVariables,
} from 'wavepaths-shared/core';
import { getScoreDurationMinutes } from 'wavepaths-shared/domain/scores';

import { Button, Typography } from '@/component-library';

import * as api from '../../../common/api/sessionApi';
import { pickFirstFormTemplateAndPostFormResponse } from '../../../formUtils/formUtils';
import UserEvents from '../../../UserEvents';
import { loadJumpRightInScores } from './jumpRightInScore';

export type NewSessionConfig = {
    type: SessionType;
    score: SessionScoreDTO;
    variableInputs: SessionVariables;
    filteredLayerIds: number[];
};

export const buildJumpRightInSessionFromScore = (score: SessionScoreDTO): NewSessionConfig => ({
    type: SessionType.ONE_ON_ONE,
    score,
    variableInputs: {
        preludeDuration: 0,
        totalDuration: getScoreDurationMinutes(score.wavepaths),
        sessionUse: SessionScoreSessionUse.IN_PERSON,
    },
    filteredLayerIds: [],
});

interface IJumpRightInProps {
    fbUser: firebase.User;
    disabled: boolean;
    setSnackbarContent: (content: string) => void;
}

const JumpRightIn = ({ fbUser, disabled, setSnackbarContent }: IJumpRightInProps): JSX.Element => {
    const [createdSession, setCreatedSession] = useState<Session | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (createdSession) {
            history.push(`/session/${createdSession.id}`);
        }
    }, [createdSession, history, fbUser]);

    const sessionScoreDTO = first(loadJumpRightInScores());

    if (!sessionScoreDTO) return <></>;

    const { type, score, variableInputs, filteredLayerIds } = buildJumpRightInSessionFromScore(sessionScoreDTO);

    const onClickPlaySessionNow = async () => {
        setLoading(true);

        try {
            const sessionResponse = await api.startSession(
                type,
                SessionRenderType.REAL_TIME,
                score,
                variableInputs,
                { scheduledStart: Date.now() - 300 },
                [],
                filteredLayerIds,
                fbUser,
            );
            setCreatedSession(sessionResponse);
            pickFirstFormTemplateAndPostFormResponse(sessionResponse.id, 'postSessionIntegration', fbUser);
            UserEvents.sessionStarted(sessionResponse);
            UserEvents.jumpedRightIn();
        } catch (e) {
            setLoading(false);
            if (e instanceof api.TooMuchTrafficError) {
                setSnackbarContent(
                    'We are experiencing an unusually high volume of sessions right now please try again later',
                );
                return;
            }
            console.log('setting snackbar content');
            setSnackbarContent('Unable to start the session. Please check your network connection and try again');
        }
    };

    return (
        <Button variant="solid-blue" size="m" onClick={onClickPlaySessionNow} disabled={disabled || loading}>
            <Typography variant="button" style={{ color: 'white' }}>
                Jump Right In
            </Typography>
        </Button>
    );
};

export default JumpRightIn;
