import styled from '@emotion/styled';
import { MenuItem } from '@material-ui/core';

export interface OptionProps {
    colour: string;
    selected: boolean;
    disabled?: boolean;
    size: 'small' | 'large';
}

const baseStyles = ({ colour, disabled, selected }: { colour: string; selected: boolean; disabled: boolean }) => ({
    display: 'grid',
    alignItems: 'center',
    width: '100%',
    padding: 0,
    margin: 0,
    fontSize: '13.33px',
    fontWeight: selected ? 600 : 500,
    color:
        colour === 'light' && !disabled
            ? 'rgba(255,255,255,1)'
            : colour === 'light' && disabled
            ? 'rgba(255,255,255,0.3)'
            : colour === 'dark' && !disabled
            ? 'rgba(44,57,88,1)'
            : colour === 'dark' && disabled
            ? 'rgba(44,57,88,0.3)'
            : 'rgba(44,57,88,1)',
    backgroundColor: selected ? 'rgba(216, 220, 232, 0.4)  !important' : 'transparent',
    transition: 'background-color 0.15s ease',
    cursor: disabled ? 'not-allowed' : 'pointer',
    '&:hover': {
        background: disabled ? undefined : '#F1F3F8',
    },
});

export const OptionSegmentedControl = styled.button<OptionProps>(({ colour, selected, disabled = false }) => ({
    ...baseStyles({ colour, disabled, selected }),
    justifyContent: 'center',
    border: 'none',
    borderRight: '1px solid #ADB9D6',
    '&:first-of-type': {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
    },
    '&:last-of-type': {
        borderRight: 'none',
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
    },
}));

export const OptionDropdown = styled(MenuItem)<OptionProps>(({ colour, selected, disabled = false }) => ({
    ...baseStyles({ colour, disabled, selected }),
    display: 'block',
    minHeight: 0,
    padding: '8px 12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

export default Option;
