import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import React, { CSSProperties, ReactNode } from 'react';

import Typography from '../typography/Typography';
import { FONT_SIZES } from '../typography/variables';
import Button from './Button';
import CheckboxCheckedIcon from './icons/CheckboxCheckedIcon';
import CheckboxIcon from './icons/CheckboxIcon';

const useStyles = makeStyles({
    paper: {
        width: 380,
        maxWidth: '100%',
        borderRadius: 8,
    },
});

export const defaultValues = {
    MESSAGE: 'Are you sure?',
    CONFIRM_TEXT: 'Ok',
    CANCEL_TEXT: 'Cancel',
};
export interface DialogProps {
    open: boolean;
    onConfirm: () => void;
    message?: string;
    confirmText?: string;
    cancelText?: string;
    secondaryActionText?: string;
    onSecondaryAction?: () => void;
    onClose?: () => void;
    title?: string;
    fullWidth?: boolean;
    disableBackdropClick?: boolean;
    icon?: ReactNode;
    contentStyle?: CSSProperties;
    checkboxLabel?: string;
    handleCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Confirm = ({
    open,
    title,
    message = defaultValues.MESSAGE,
    confirmText = defaultValues.CONFIRM_TEXT,
    cancelText = defaultValues.CANCEL_TEXT,
    secondaryActionText,
    onSecondaryAction,
    onConfirm,
    onClose,
    fullWidth = false,
    disableBackdropClick = false,
    icon,
    checkboxLabel,
    handleCheckboxChange,
}: DialogProps): JSX.Element => {
    const classes = useStyles();
    return (
        <Dialog
            disableBackdropClick={disableBackdropClick}
            disableEscapeKeyDown={disableBackdropClick}
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={fullWidth}
            classes={{ paper: classes.paper }}
            maxWidth={'xs'}
        >
            <StyledContent>
                {(title || icon) && (
                    <StyledSubTitle disableTypography withIcon={!!icon}>
                        {icon && <div style={{ marginBottom: 8 }}>{icon}</div>}
                        {icon && title && <div style={{ height: 6 }} />}
                        {title && <Typography variant="h6">{title}</Typography>}
                    </StyledSubTitle>
                )}
                <StyledContentText>{message}</StyledContentText>
                {checkboxLabel && handleCheckboxChange && (
                    <div
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyItems: 'center' }}
                    >
                        <StyledCheckbox
                            onChange={handleCheckboxChange}
                            icon={CheckboxIcon}
                            checkedIcon={CheckboxCheckedIcon}
                        />
                        <StyledCheckboxText>{checkboxLabel}</StyledCheckboxText>
                    </div>
                )}
                <StyledActions>
                    <Button variant="solid-dark" size="s" onClick={onConfirm}>
                        {confirmText}
                    </Button>
                    {secondaryActionText && onSecondaryAction && (
                        <Button variant="outlined" size="s" onClick={onSecondaryAction}>
                            {secondaryActionText}
                        </Button>
                    )}
                    {onClose && (
                        <Button variant="clear" size="s" onClick={onClose}>
                            {cancelText}
                        </Button>
                    )}
                </StyledActions>
            </StyledContent>
        </Dialog>
    );
};

export default Confirm;

const StyledSubTitle = styled((props) => <DialogTitle {...props} />)({
    textAlign: (props: { withIcon?: boolean }) => (props.withIcon ? 'center' : 'left'),
    fontSize: FONT_SIZES.medium,
    padding: '0',
    margin: '0 0 16px 0',
});

const StyledCheckbox = styled(Checkbox)({
    margin: '0px',
});

const StyledContent = styled(DialogContent)({
    display: 'grid',
    gridAutoFlow: 'row',
    justifyItems: 'center',
    textAlign: 'center',
    margin: '0',
    padding: '24px !important',
});

const StyledContentText = styled(DialogContentText)({
    fontSize: FONT_SIZES.small,
    margin: '0 0 4px 0',
    padding: '0',
});

const StyledCheckboxText = styled(DialogContentText)({
    fontSize: FONT_SIZES.small,
    margin: '0',
    padding: '0',
    color: 'black',
});

const StyledActions = styled(DialogActions)({
    width: '100%',
    display: 'grid',
    gridAutoFlow: 'row',
    justifyContent: 'stretch',
    gap: '8px',
    margin: '12px 0 0 0',
    padding: '0',
});
