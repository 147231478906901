import React from 'react';

import LoadingTemplateCard from './LoadingTemplateCard';

export interface LoadingTemplateGridProps {
    columns?: number;
}

const LoadingCardRow: React.FC<LoadingTemplateGridProps> = ({ columns = 4 }) => {
    return (
        <>
            {Array(columns)
                .fill(0)
                .map((_, idx) => (
                    <LoadingTemplateCard key={'loading-' + idx} />
                ))}
        </>
    );
};

export default LoadingCardRow;
