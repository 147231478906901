import styled from '@emotion/styled';
import { setSeconds } from 'date-fns';
import { ReactElement, ReactNode } from 'react';
import React from 'react';
import { SessionRenderType } from 'wavepaths-shared/core';

import { EvaIcon, Typography } from '@/component-library';
import { Features, useFeatures } from '@/features';

import DateTimeInput from '../../common/components/Inputs/DateTimeInput';
import Select from '../../common/components/Inputs/Select';
import CanClientStartEarlyInfoDialog from './infodialogs/CanClientStartEarlyInfoDialog';
import RenderingTypeInfoDialog from './infodialogs/RenderingTypeInfoDialog';
import SchedulingTypeInfoDialog from './infodialogs/SchedulingTypeInfoDialog';

const Container = styled.div({
    marginBottom: '28px',
});

const SelectWrapper = styled.div({
    marginBottom: '28px',
});

const SelectLabel = styled.label({
    display: 'inline-block',
    marginBottom: '8px',
});

const DateTimeWrapper = styled.div({
    marginTop: '16px',
});

const CheckboxWrapper = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    gridTemplateColumns: 'min-content 1fr',
    gap: '8px',
    marginTop: '16px',
});

const CheckboxLabel = styled.label({
    margin: '0',
});

export type SchedulingStyle = 'now' | 'later' | 'specificTime';
type SchedulingStyleOption = { value: SchedulingStyle; label: string };

type RenderingTypeOption = { value: SessionRenderType; label: ReactNode; isDisabled?: boolean };

export interface SessionSchedulingOptionsProps {
    schedulingStyle: SchedulingStyle;
    scheduledStart?: number;
    onSchedulingStyleChange: (schedulingStyle: SchedulingStyle) => void;
    onScheduledStartChange: (time: number | undefined) => void;
    canClientStartEarly: boolean;
    onCanClientStartEarlyChange: (canStart: boolean) => void;
    preludeDuration: number;
    renderingType: SessionRenderType;
    onRenderTypeChange: (type: SessionRenderType) => void;
}

export const RENDERING_TYPE_LABEL = 'How would you like this music to be created?';

export function SessionSchedulingOptions({
    renderingType,
    onRenderTypeChange,
    schedulingStyle,
    onSchedulingStyleChange,
    scheduledStart,
    onScheduledStartChange,
    canClientStartEarly,
    onCanClientStartEarlyChange,
}: SessionSchedulingOptionsProps): ReactElement {
    const { isEnabled } = useFeatures();
    const schedulingStyles: { [key in SchedulingStyle]: SchedulingStyleOption } = {
        now: { value: 'now', label: 'Now' },
        later: { value: 'later', label: 'An unspecified time (Save for later)' },
        specificTime: { value: 'specificTime', label: 'A specific time' },
    };

    const renderingTypes: { [key in SessionRenderType]: RenderingTypeOption } = {
        realTime: {
            value: SessionRenderType.REAL_TIME,
            label: (
                <div style={{ display: 'flex', gap: '4px' }}>
                    Adaptable Audio (with real-time music controls)
                    {isEnabled(Features.LIVE_SESSION_RENDERING) ? (
                        ''
                    ) : (
                        <EvaIcon name="lock-outline" size={16} fill={'#555'} />
                    )}
                </div>
            ),
            isDisabled: !isEnabled(Features.LIVE_SESSION_RENDERING),
        },
        preRendered: {
            value: SessionRenderType.PRE_RENDERED,
            label: 'Fixed Audio (with a longer audio buffer)',
        },
    };

    return (
        <Container>
            <SelectWrapper className="tour-renderingType">
                <SelectLabel htmlFor="renderingTypeSelect">
                    <Typography variant="body2" component="span">
                        {RENDERING_TYPE_LABEL}
                    </Typography>
                </SelectLabel>
                <RenderingTypeInfoDialog title={RENDERING_TYPE_LABEL} />
                <Select
                    name="renderingTypeSelect"
                    inputId="renderingTypeSelect"
                    value={renderingTypes[renderingType]}
                    options={Object.values(renderingTypes)}
                    onChange={(v: any) => onRenderTypeChange(v.value)}
                    isSearchable={false}
                ></Select>
            </SelectWrapper>
            {renderingType === SessionRenderType.REAL_TIME && (
                <>
                    <SelectWrapper className="tour-schedulingInput">
                        <SelectLabel htmlFor="schedulingTypeSelect">
                            <Typography variant="body2" component="span">
                                When Is This Session For?
                            </Typography>
                        </SelectLabel>
                        <SchedulingTypeInfoDialog />
                        <Select
                            name="schedulingTypeSelect"
                            inputId="schedulingTypeSelect"
                            value={schedulingStyles[schedulingStyle]}
                            options={Object.values(schedulingStyles)}
                            onChange={(v: any) => onSchedulingStyleChange(v.value)}
                            isSearchable={false}
                        ></Select>
                    </SelectWrapper>
                    {schedulingStyle === 'specificTime' && (
                        <DateTimeWrapper>
                            <DateTimeInput
                                data-testId="scheduled-start-picker"
                                value={scheduledStart && setSeconds(new Date(scheduledStart), 0)}
                                disabled={schedulingStyle !== 'specificTime'}
                                onChange={(d: Date) => onScheduledStartChange(d ? d.getTime() : undefined)}
                                clearIcon={null}
                            />
                        </DateTimeWrapper>
                    )}
                    {schedulingStyle !== 'now' && (
                        <CheckboxWrapper>
                            <input
                                id="canClientStartEarlyCheckbox"
                                type="checkbox"
                                checked={canClientStartEarly}
                                onChange={(event) => {
                                    onCanClientStartEarlyChange(event.target.checked);
                                }}
                            ></input>
                            <CheckboxLabel htmlFor="canClientStartEarlyCheckbox">
                                <Typography variant="body2" component="span">
                                    The client can start the session on their own
                                    <CanClientStartEarlyInfoDialog />
                                </Typography>
                            </CheckboxLabel>
                        </CheckboxWrapper>
                    )}
                </>
            )}
        </Container>
    );
}
