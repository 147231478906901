import styled from '@emotion/styled';
import React from 'react';

import { StatusBarItem, Typography } from '@/component-library';

import { formatDuration } from '../../../../dateUtilsV2';
import { StatusBarListItem } from './types';

const Container = styled.div({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: '16px 8px',
});

const Item = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
});

function StatusBarList({ listItems }: { listItems: StatusBarListItem[] }): JSX.Element {
    return (
        <Container>
            {listItems.map(({ message, separatorMessage, timeUntilActive, key }) => (
                <Item key={key}>
                    {separatorMessage && <Typography variant="body3">{separatorMessage}</Typography>}
                    <StatusBarItem
                        message={message}
                        countdownSecs={timeUntilActive ? formatDuration(timeUntilActive) : undefined}
                    />
                </Item>
            ))}
        </Container>
    );
}

export default StatusBarList;
