import { isArray } from 'lodash';
import React from 'react';
import { Session, SessionScoreDosage } from 'wavepaths-shared/core';

import { Typography } from '@/component-library';
import { CustomTypographyProps } from '@/component-library/typography/Typography';

import { DOSAGE_LABELS, MEDICINE_LABELS } from '../../common/domain/modalitiesOld';

interface SessionNameProps {
    session: Session;
    variant?: CustomTypographyProps['variant'];
}
export const SessionName: React.FC<SessionNameProps> = ({ session, variant = 'subtitle2' }) => (
    <Typography style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} variant={variant}>
        {getSessionName(session)}
    </Typography>
);

export function getSessionName(session: Session): string | undefined {
    const renderedName = (session.variableInputs?.name as string | undefined) || session.score.name;
    if (renderedName?.length > 0) {
        return renderedName;
    } else {
        const medicine = getMedicineLabels(session);
        const dosage =
            session.variableInputs.dosage && DOSAGE_LABELS[session.variableInputs.dosage as SessionScoreDosage];
        const dur = session.variableInputs.totalDuration;
        let name = medicine;
        if (dosage) name += ` | ${dosage}`;
        if (dur) name += ` | ${dur} mins`;
        return name;
    }
}

function getMedicineLabels(session: Session) {
    if (session.score.selectionCriteria?.medicine) {
        const medicines = isArray(session.score.selectionCriteria.medicine)
            ? session.score.selectionCriteria.medicine
            : [session.score.selectionCriteria.medicine];
        return medicines.map((m) => MEDICINE_LABELS[m]).join(', ');
    }
}
