import styled from '@emotion/styled';
import React from 'react';
import { EVENTS, Step } from 'react-joyride';

import { Typography } from '@/component-library';
import { ProductTour } from '@/components/ProductTour';
import { Features, useFeatures } from '@/features';

import ProfileImage from '../../images/mendel-profile.png';

const ProfileImg = styled.img({
    height: 100,
    width: 100,
    borderRadius: 100,
});

const ProfileContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '1em',
});

const List = styled.ul({
    marginTop: '0.5em',
});

const ListItem = styled.li({
    marginTop: '0.5em',
});

const IntroVideo = () => {
    return (
        <div style={{ position: 'relative', paddingBottom: '60.810810810810814%', marginBottom: 12, height: 0 }}>
            <iframe
                src="https://www.loom.com/embed/b9bfaf15519240d6a821f39e0f760b9b?sid=bc177c4e-69d1-4c8d-b578-ad4e83fa54ee"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            ></iframe>
        </div>
    );
};

export const getSteps = (isEnabled: (ft: Features) => boolean): Step[] => {
    return [
        {
            content: (
                <>
                    <ProfileContainer>
                        <ProfileImg src={ProfileImage} />
                    </ProfileContainer>
                    <Typography variant="body2">Hi,</Typography>
                    <Typography variant="body2">
                        I'm Mendel, CEO and founder of Wavepaths, wishing you a very warm welcome to our music platform
                        for Care-Providers.
                    </Typography>
                    <Typography variant="body2">
                        We’ve made it easy for you to start providing person-centred music for your clients now with the
                        help of a few simple resources.
                    </Typography>
                    <Typography variant="body2">
                        Firstly, we recommend you get acquainted with some of our key concepts:
                    </Typography>
                    <IntroVideo />
                    <Typography variant="body2">
                        Please continue on for a few useful tips about set-up before you get started.
                    </Typography>
                    <Typography variant="body2">
                        Warmly, <br />
                        Mendel Kaelen <br />
                        Founder & CEO of Wavepaths
                    </Typography>
                </>
            ),
            placement: 'center',
            target: 'body',
        },
        {
            title: 'Your setup',
            content: (
                <>
                    <Typography variant="body2">
                        First things first, here’s how to ensure a high quality audio experience:
                    </Typography>
                    <Typography variant="body2">
                        <List>
                            <ListItem>Avoid using bluetooth or wireless devices for sound 🔊</ListItem>
                            <ListItem>
                                Ensure your internet connection is stable, reliable & reasonably fast 📶
                            </ListItem>
                            <ListItem>
                                Use either a desktop / laptop with Chrome or Safari, or an iPad with Safari, to conduct
                                any sessions 🖥
                            </ListItem>
                        </List>
                    </Typography>
                    <Typography variant="body2">
                        If you’re ready to start making music, continue on for our interactive product tour, during
                        which you’ll design and run your first Wavepaths session.
                    </Typography>
                </>
            ),
            placement: 'center',
            target: 'body',
        },
        {
            target: '.tour-home',
            content: (
                <>
                    <Typography variant="body2">
                        Home will show you content tailored to your profile, such as templates, lessons and product
                        updates.
                    </Typography>
                </>
            ),
        },
        {
            target: '.tour-templates',
            content: (
                <>
                    <Typography variant="body2">
                        Here you can search our entire library of templates, filtered by modality, desired duration and
                        other variables of choice. Templates that you have saved for easy access or customised will also
                        appear here.
                    </Typography>
                </>
            ),
        },
        {
            target: '.tour-sessions',
            content: (
                <>
                    <Typography variant="body2">
                        Here you can view all your completed, scheduled and currently running sessions.
                    </Typography>
                </>
            ),
        },
        {
            target: '.tour-lessons',
            content: (
                <>
                    <Typography variant="body2">
                        Lessons and guides for how to use Wavepaths can be found here.
                    </Typography>
                </>
            ),
        },
        {
            target: '.tour-community',
            content: (
                <>
                    <Typography variant="body2">
                        Here you can access our community, for more educational content, product updates and more.
                    </Typography>
                </>
            ),
        },
        {
            target: '.tour-profile',
            content: (
                <>
                    <Typography variant="body2">Here you can manage your subscription and profile details.</Typography>
                </>
            ),
        },
        {
            target: 'body',
            placement: 'center',
            content: (
                <>
                    <Typography variant="body2">
                        Finally, we'd recommend either diving into our templates and hearing what music we provide for
                        your practice
                        {isEnabled(Features.LIVE_SESSION_RENDERING) && (
                            <>, or learn how to adapt music live with the subsequent lesson</>
                        )}
                        .
                    </Typography>
                </>
            ),
        },
    ];
};

export function WelcomeProductTour({
    setShowIntroProductTour,
}: {
    setShowIntroProductTour?: (val: boolean) => void;
}): JSX.Element {
    const { isEnabled } = useFeatures();
    const steps = getSteps(isEnabled);

    return (
        <ProductTour
            steps={steps}
            callback={(joyride) => {
                const { type } = joyride;
                switch (type) {
                    case EVENTS.TOUR_END:
                        return setShowIntroProductTour && setShowIntroProductTour(false);
                }
            }}
        />
    );
}
