import React, { ReactElement } from 'react';

import { useFeatures } from './useFeatures';

interface IFeatureProps {
    name: string;
    activeComponent?: JSX.Element | ReactElement;
    loadingComponent?: JSX.Element | ReactElement;
    inactiveComponent?: JSX.Element | ReactElement;
}

export function Feature({
    activeComponent = <></>,
    loadingComponent = <></>,
    inactiveComponent = <></>,
    name,
}: IFeatureProps): JSX.Element {
    const { isEnabled, isLoading } = useFeatures();

    if (isLoading) return loadingComponent;

    if (isEnabled(name)) return activeComponent;

    return inactiveComponent;
}
