import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { animated, config, useTransition } from 'react-spring';

import { Button } from '@/component-library';
interface IStartEndSessionButtonProps {
    isPrelude: boolean;
    isPostlude: boolean;
    onStartSession: () => void;
    onEndSession: () => void;
}

export const START_LABEL = 'Start Session';
const StartButton = ({ onStartSession }: { onStartSession: () => void }) => {
    const [clicked, setClicked] = useState(false);

    const handleStartSession = () => {
        setClicked(true);
        onStartSession();
    };

    return (
        <Button
            className="tour-startSession"
            variant="solid-blue"
            size="l"
            disabled={clicked}
            onClick={handleStartSession}
        >
            {START_LABEL}
        </Button>
    );
};

export const END_LABEL = 'End Session';
const EndButton = ({ onEndSession }: { onEndSession: () => void }) => {
    const [clicked, setClicked] = useState(false);

    const handleEndSession = () => {
        setClicked(true);
        onEndSession();
    };

    return (
        <Button variant="solid-blue" size="l" disabled={clicked} onClick={handleEndSession}>
            {END_LABEL}
        </Button>
    );
};

const useStyles = makeStyles({
    container: {
        display: 'inline-grid',
        placeContent: 'center',
    },
});

export default function StartEndSessionButton({
    isPrelude,
    isPostlude,
    onStartSession,
    onEndSession,
}: IStartEndSessionButtonProps): JSX.Element {
    const classes = useStyles();

    const isPreludeOrPostlude = isPostlude || isPrelude;

    const transition = useTransition(isPreludeOrPostlude, {
        from: { height: 'auto' },
        enter: { height: 'auto' },
        leave: { height: 'auto' },
        config: config.slow,
    });

    return transition(
        (style, isPreludeOrPostlude) =>
            isPreludeOrPostlude && (
                <animated.div className={classes.container} style={style as any}>
                    {isPrelude && <StartButton onStartSession={onStartSession} />}
                    {isPostlude && <EndButton onEndSession={onEndSession} />}
                </animated.div>
            ),
    );
}
