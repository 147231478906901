import React from 'react';
import { ScheduledWavepath } from 'wavepaths-shared/core';

import { TimelineWave } from './TimelineWave';
import { getWaveViewbox, TimelineWaveProperties } from './timelineWaveUtils';

const PIXELS_PER_MINUTE = 8;
const PIXELS_PER_MILLIS = PIXELS_PER_MINUTE / (60 * 1000);
const MAX_HEIGHT = 16;

interface WaveSparklineProps {
    wave: ScheduledWavepath;
    previousWave?: ScheduledWavepath;
}
export const WaveSparkline: React.FC<WaveSparklineProps> = React.memo(({ wave, previousWave }) => {
    const width = wave.plan ? (wave.plan.toTime - wave.plan.fromTime) * PIXELS_PER_MILLIS : 0;
    const height = MAX_HEIGHT;
    const waveProps: TimelineWaveProperties = {
        wave,
        previousWavePathScore: previousWave?.pathScore,
        x: 0,
        width,
        height,
    };
    const viewBox = getWaveViewbox(waveProps);
    return (
        <svg
            viewBox={`${viewBox.left} ${viewBox.top} ${viewBox.width} ${viewBox.height}`}
            width={viewBox.width}
            height={viewBox.height}
            style={{ overflow: 'visible' }}
        >
            <TimelineWave wave={waveProps} opacity="full" />
        </svg>
    );
});
