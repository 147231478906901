export const FONT_SIZES = {
    small: '13.3px',
    medium: '16px',
    extraSmall: '11.1px',
};

export const FONT_WEIGHTS = {
    medium: 500,
};

export const FONT_HEIGHTS = {
    medium: '24px',
};
