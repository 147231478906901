import firebase from 'firebase';
import useSWR from 'swr';

import { FormResponse } from '../../../formUtils/formTypes';
import { getSessionFormResponses } from '../../../formUtils/formUtils';

type ResponseData = { formResponses: FormResponse[] };
type NetworkResponse = 'loading' | 'error' | ResponseData;

const useFormResponses = (sessionId: string, fbUser: firebase.User): NetworkResponse => {
    const { data, error } = useSWR<{ formResponses: FormResponse[] }>(
        [`formResponses/session/ended`, sessionId],
        () => getSessionFormResponses(sessionId, fbUser),
        { dedupingInterval: 60000 },
    );

    if (error) {
        return 'error';
    }
    if (data) {
        return data;
    }

    return 'loading';
};

export default useFormResponses;
