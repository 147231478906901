import styled from '@emotion/styled';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import MUITabs from '@material-ui/core/Tabs';
import React from 'react';

import { TabPanel } from '@/components';

const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
});

const useStyles = makeStyles(() => ({
    indicator: {
        background: '#2c3958',
    },
    textColorInherit: {
        opacity: 1,
    },
    tabRoot: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
}));

const Container = styled.div({
    flexGrow: 1,
    width: '100%',
});

interface TabContent {
    identifier: string;
    label: React.ReactNode;
    body: React.ReactElement;
}

interface ITabsProps {
    content: TabContent[];
    className?: string;
    activeTab: number;
    onChange: (newActiveTab: number) => void;
}

export const Tabs = ({ content, activeTab, onChange, className }: ITabsProps): React.ReactElement => {
    const classes = useStyles();

    return (
        <Container className={className}>
            <AppBar elevation={0} color="transparent" position="static">
                <MUITabs
                    value={activeTab}
                    onChange={(event, value) => onChange(value)}
                    variant="fullWidth"
                    aria-label="tabs"
                    classes={{ indicator: classes.indicator }}
                >
                    {content.map((c, idx) => (
                        <Tab
                            classes={{ root: classes.tabRoot, textColorInherit: classes.textColorInherit }}
                            label={c.label}
                            key={c.identifier + idx}
                            {...a11yProps(0)}
                        />
                    ))}
                </MUITabs>
            </AppBar>
            {content.map((c, idx) => (
                <TabPanel value={activeTab} key={c.identifier + idx} index={idx}>
                    {c.body}
                </TabPanel>
            ))}
        </Container>
    );
};
