import styled from '@emotion/styled';
import React from 'react';

import TypographyV2 from '../typography/TypographyV2';
import EvaIcon from './EvaIcon';

export type PillOptionProps = {
    name: string;
    selected: boolean;
    onClick: () => void;
};

const Container = styled.div<{ selected: boolean }>(
    ({ selected }) => `
    display: flex;
    height: 36px;
    width: fit-content;
    padding: 0px 12px;
    box-sizing: border-box;
    align-items: center;
    border-radius: 30px;
    cursor: pointer;
    gap: 2px;
    border: ${selected ? '1px solid var(--primary-200, #e9d7fe)' : '1px solid var(--gray-200, #eaecf0)'};
    background: ${selected ? 'var(--primary-50, #f9f5ff)' : 'var(--gray-50, #f9fafb)'};
`,
);

function PillOption(props: PillOptionProps) {
    return (
        <Container selected={props.selected} onClick={props.onClick}>
            <TypographyV2 size="text-sm" weight="medium" color={props.selected ? 'purple-600' : 'grey-600'}>
                {props.name}
            </TypographyV2>
            {props.selected && <EvaIcon name="close-outline" size={16} iconStyle={{ fill: '#6941C6' }} />}
        </Container>
    );
}

export default PillOption;
