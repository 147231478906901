import './NotificationHeader.scss';

import React from 'react';

import iconCloseSmall from '../../../../src/images/iconClose--small.svg';

interface NotificationHeaderProps {
    content: string;
    actions?: string[];
    onAction?: (action: string) => void;
    onClose?: () => void;
}
export const NotificationHeader: React.FC<NotificationHeaderProps> = ({ content, actions, onAction, onClose }) => {
    return (
        <div className="notificationHeader">
            <div className="notificationHeaderIcon"></div>
            <div className="notificationHeaderText">
                {content}
                {actions?.map((action) => (
                    <button className="notificationHeaderAction" key={action} onClick={() => onAction?.(action)}>
                        {action}
                    </button>
                ))}
            </div>
            {onClose && (
                <button className="notificationHeaderCloseButton" onClick={onClose}>
                    <img src={iconCloseSmall} alt="Close" />
                </button>
            )}
        </div>
    );
};
