import config from '../../configs';
import axios from '../util/axios';
import localFeatures from './features.local';

const shouldUseLocalFeatures = (featuresUrl: string) => featuresUrl === 'LOCAL';

export const TIMEOUT = 60000;

export const getFeaturesForUser = async ({ userId, email }: { userId?: string; email?: string | null } = {}): Promise<
    string[]
> => {
    if (shouldUseLocalFeatures(config.features.BASE_URL)) {
        console.log('Using local features: ', localFeatures);
        return localFeatures;
    } else {
        try {
            const url = `${config.features.BASE_URL}${
                userId ? `?userId=${userId}` : email ? `?email=${encodeURIComponent(email)}` : ''
            }`;
            const res = await axios(url, { timeout: TIMEOUT });
            const { features } = await res.data;
            return features;
        } catch (e) {
            console.log(e);
        }
        return [];
    }
};
