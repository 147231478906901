import React from 'react';

import { Typography } from '@/component-library';

import InfoButtonAndDialog from './InfoButtonAndDialog';

const RenderingTypeInfoDialog: React.FC<{ title: string }> = ({ title }: { title: string }) => (
    <InfoButtonAndDialog title={title}>
        <Typography variant="body2" color="textSecondary">
            <strong>Adaptable</strong> will create music on the fly, allowing you to change the music during the
            session, attuning the music to your patients needs. To ensure the changes feel responsive, the buffer is
            quite short, and so is only appropriate for fast and stable internet connections.
        </Typography>
        <Typography variant="body2" color="textSecondary">
            <strong>Fixed</strong> will generate music for the entire session immediately. This can be useful for users
            with very slow and highly variable internet connections, as it provides a much longer buffer.
        </Typography>
    </InfoButtonAndDialog>
);

export default RenderingTypeInfoDialog;
