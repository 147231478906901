import styled from '@emotion/styled';

const Container = styled.div<{ inline: boolean }>(({ inline }) => ({
    position: 'relative',
    display: 'grid',
    gap: 8,
    gridAutoFlow: inline ? 'row' : 'column',
    gridTemplateColumns: inline ? 'min-content minmax(0, 1fr)' : '1fr',
    gridTemplateRows: inline ? 'min-content' : 'min-content min-content',
}));

export default Container;
