import { SvgIcon } from '@material-ui/core';
import React from 'react';
const CheckboxCheckedIcon = (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path
            d="M12.25,7,9.1,11.14,7.74,9.4a.83.83,0,0,0-1.31,1l2,2.59a.81.81,0,0,0,.66.32h0A.81.81,0,0,0,9.77,13l3.81-5a.82.82,0,0,0-.16-1.16A.83.83,0,0,0,12.25,7m3.58,8a.83.83,0,0,1-.83.83H5A.83.83,0,0,1,4.17,15V5A.83.83,0,0,1,5,4.17H15a.83.83,0,0,1,.83.83ZM15,2.5H5A2.5,2.5,0,0,0,2.5,5V15A2.5,2.5,0,0,0,5,17.5H15A2.5,2.5,0,0,0,17.5,15V5A2.5,2.5,0,0,0,15,2.5Z"
            fill="black"
            fillRule="evenodd"
        />
    </SvgIcon>
);

export default CheckboxCheckedIcon;
