import { createContext, useEffect, useState } from 'react';
import Stripe from 'stripe';
import { UserData } from 'wavepaths-shared/core';

import { listSubscriptionsForUser } from '../util/stripeUtil';

export type SubscriptionsState = {
    subscriptions: Stripe.Subscription[] | undefined;
    loading: boolean;
    error: Error | undefined;
};

const initialState: SubscriptionsState = {
    subscriptions: undefined,
    loading: false,
    error: undefined,
};

export const SubscriptionsContext = createContext(initialState);

export const useSubscriptions = (userData?: UserData): SubscriptionsState => {
    const [subscriptions, setSubscriptions] = useState<Stripe.Subscription[] | undefined>(initialState.subscriptions);
    const [loading, setLoading] = useState<boolean>(initialState.loading);
    const [error, setError] = useState<Error | undefined>(initialState.error);

    const fetchSubscriptions = () => {
        if (userData) {
            if (!!userData.customerId) {
                setLoading(true);
                listSubscriptionsForUser({ customerId: userData.customerId, status: 'all' })
                    .then(({ subscriptions }) => {
                        setSubscriptions(subscriptions);
                        setLoading(false);
                        setError(undefined);
                    })
                    .catch((err) => {
                        setSubscriptions(undefined);
                        setLoading(false);
                        setError(err);
                    });
            } else {
                setSubscriptions([]);
                setLoading(false);
                setError(undefined);
            }
        } else {
            setSubscriptions(undefined);
            setLoading(false);
            setError(undefined);
        }
    };

    useEffect(() => {
        fetchSubscriptions();
    }, [userData]);

    return {
        subscriptions,
        loading,
        error,
    };
};
