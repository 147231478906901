import React from 'react';
import styled from 'styled-components';

import { Button, Typography } from '@/component-library';

import configs from '../../configs';

const Container = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    justifyContent: 'center',
    marginBottom: 16,
    background: 'white',
    borderRadius: 5,
});

export function TemplateRecommenderBanner(): JSX.Element {
    return (
        <Container>
            <Typography style={{ display: 'flex', alignItems: 'baseline' }} variant="body3">
                <Button
                    onClick={() => {
                        window.open(configs.templateRecommender.BASE_URL, '_blank');
                    }}
                    size="xs"
                    variant="clear-underlined"
                >
                    Try our new Template Recommender,
                </Button>
                &nbsp;or select a template below to get started:
            </Typography>
        </Container>
    );
}
