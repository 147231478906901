import styled from '@emotion/styled';
import React from 'react';
import { SessionScoreEmotionalIntensity } from 'wavepaths-shared/core';

export interface IntensityIconProps {
    intensity: SessionScoreEmotionalIntensity | 'none';
}

const Container = styled.div`
    width: 12px;
    height: 12px;
    display: grid;
    place-content: center;
    // padding-bottom: 2px;
`;

const Icon = styled.svg`
    width: 12px;
    height: 12px;
`;

const MarkerLow = styled.rect<{
    intensity: IntensityIconProps['intensity'];
}>(
    ({ intensity }) => `
    fill: ${
        intensity === SessionScoreEmotionalIntensity.LOW ||
        intensity === SessionScoreEmotionalIntensity.MEDIUM ||
        intensity === SessionScoreEmotionalIntensity.HIGH
            ? '#667085'
            : '#D0D5DD'
    };
  `,
);

const MarkerMedium = styled.rect<{
    intensity: IntensityIconProps['intensity'];
}>(
    ({ intensity }) => `
    fill: ${
        intensity === SessionScoreEmotionalIntensity.MEDIUM || intensity === SessionScoreEmotionalIntensity.HIGH
            ? '#667085'
            : '#D0D5DD'
    };
  `,
);

const MarkerHigh = styled.rect<{
    intensity: IntensityIconProps['intensity'];
}>(
    ({ intensity }) => `
    fill: ${intensity === SessionScoreEmotionalIntensity.HIGH ? '#667085' : '#D0D5DD'};
  `,
);

const IntensityIcon: React.FC<IntensityIconProps> = ({ intensity }) => {
    return (
        <Container>
            <Icon viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <MarkerHigh width="12" height="2" rx="1" intensity={intensity} />
                <MarkerMedium x="2" y="4" width="8" height="2" rx="1" intensity={intensity} />
                <MarkerLow x="4" y="8" width="4" height="2" rx="1" intensity={intensity} />
            </Icon>
        </Container>
    );
};

export default IntensityIcon;
