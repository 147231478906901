import { Icon, IconTypeMap } from '@material-ui/core';
import * as eva from 'eva-icons';
import React, { useEffect } from 'react';

interface IEvaIconProps {
    name: string;
    fill?: string;
    size?: string | number;
    iconStyle?: React.CSSProperties;
    evaSize?: string | number; // you normally should not need it
}

export type EvaAndMaterialProps = IEvaIconProps & IconTypeMap['props'];

function EvaIcon({
    name,
    fill = '#000',
    size = '24px',
    iconStyle = {},
    evaSize = '100%',
    ...rest
}: EvaAndMaterialProps): JSX.Element {
    useEffect(() => {
        eva.replace();
    }, [name, fill, size, iconStyle, rest]);

    return (
        <Icon
            style={{
                ...{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    pointerEvents: 'none',
                    width: size,
                    height: size,
                    fill,
                },
                ...iconStyle,
            }}
            {...rest}
        >
            <i data-eva={name} data-eva-height={evaSize} data-eva-width={evaSize} />
        </Icon>
    );
}

export default EvaIcon;
