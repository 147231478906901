import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';

import { FreudConnection } from '../../freudConnection/FreudConnection';
import UserEvents from '../../UserEvents';

const useIsNetworkSlow = (connection: FreudConnection): boolean => {
    const [isNetworkSlow, setIsNetworkSlow] = useState<boolean>(false);
    const [errorCount, setErrorCount] = useState(0);
    const [lastErrorTimestamp, setLastErrorTimestamp] = useState<number | undefined>();

    useEffect(() => {
        if (isNetworkSlow) UserEvents.streamingNetworkGlitch();
    }, [isNetworkSlow]);

    useEffect(() => {
        if (errorCount >= 2) {
            setIsNetworkSlow(true);
        }
    }, [errorCount]);

    useEffect(() => {
        if (!!connection) {
            const handleNetworkTooSlow = () => {
                setErrorCount((errorCount) => errorCount + 1);
                setLastErrorTimestamp(Date.now());
            };
            connection.on('networkTooSlow', handleNetworkTooSlow);

            return () => {
                connection.removeListener('networkTooSlow', handleNetworkTooSlow);
            };
        }
    }, [connection]);

    useInterval(
        () => {
            if (isNetworkSlow && Date.now() - (lastErrorTimestamp ?? 0) > 30000) {
                setIsNetworkSlow(false);
                setErrorCount(0);
            }
        },
        isNetworkSlow ? 5000 : null,
    );

    return isNetworkSlow;
};

export default useIsNetworkSlow;
