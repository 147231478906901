import '@fontsource/tenor-sans';
import '@fontsource/inter';
import '@fontsource/roboto-mono/500.css';

import { Typography as MaterialTypography, TypographyProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import classNames from 'classnames';
import React from 'react';

import { FONT_HEIGHTS, FONT_SIZES, FONT_WEIGHTS } from './variables';

type CustomVariant = Variant | 'body3' | 'subtitleFixedWidth' | 'h6FixedWidth';
export type CustomTypographyProps = {
    variant?: CustomVariant;
    color?: TypographyProps['color'] | 'warning';
    isFixedWidth?: boolean;
};

const VARIANT_FONT_SIZE: { [variant in CustomVariant]: string } = {
    h1: '47.8px',
    h2: '39.8px',
    h3: '33.2px',
    h4: '27.6px',
    h5: '23px',
    h6: '19.2px',
    h6FixedWidth: '18px',
    subtitle1: FONT_SIZES.medium,
    subtitle2: FONT_SIZES.small,
    subtitleFixedWidth: FONT_SIZES.small,
    body1: FONT_SIZES.medium,
    body2: FONT_SIZES.small,
    body3: '11.1px',
    caption: FONT_SIZES.small,
    button: FONT_SIZES.small,
    overline: '10px',
};

const VARIANT_LINE_HEIGHT: { [variant in CustomVariant]: string } = {
    h1: '56px',
    h2: '48px',
    h3: '40px',
    h4: '32px',
    h5: '28px',
    h6: '24px',
    h6FixedWidth: '18px',
    subtitle1: '24px',
    subtitle2: '24px',
    subtitleFixedWidth: '24px',
    body1: '24px',
    body2: '20px',
    body3: '14px',
    caption: '20px',
    button: '',
    overline: '19.2px',
};

const VARIANT_FONT_WEIGHT: { [variant in CustomVariant]: number } = {
    h1: 400,
    h2: 400,
    h3: 400,
    h4: 400,
    h5: 500,
    h6: 500,
    h6FixedWidth: 400,
    subtitle1: 400,
    subtitle2: 500,
    subtitleFixedWidth: 500,
    body1: 400,
    body2: 500,
    body3: 500,
    caption: 500,
    button: 600,
    overline: 500,
};

const getVariantFamily = (variant: CustomVariant | 'inherit' | undefined, isFixedWidth?: boolean): string => {
    if (variant === 'subtitleFixedWidth' || variant === 'h6FixedWidth' || isFixedWidth) {
        return 'Roboto Mono';
    } else if (variant && ['h1', 'h2', 'h3', 'h4'].includes(variant as string)) {
        return 'Tenor Sans';
    } else {
        return 'Inter';
    }
};

const useStyles = makeStyles({
    root: ({ variant, isFixedWidth }: CustomTypographyProps) => ({
        fontSize: variant ? VARIANT_FONT_SIZE[variant] : FONT_SIZES.medium,
        lineHeight: variant ? VARIANT_LINE_HEIGHT[variant] : FONT_HEIGHTS.medium,
        fontWeight: variant ? VARIANT_FONT_WEIGHT[variant] : FONT_WEIGHTS.medium,
        fontFamily: getVariantFamily(variant, isFixedWidth),
    }),
});

const getStyleAndColor = (color: CustomTypographyProps['color'], style: React.CSSProperties = {}) => {
    switch (color) {
        case 'warning':
            return { style: { color: '#FFC700', ...style } };
        case 'primary':
            return { style: { color: '#2C3958', ...style } };
        default:
            return { color, style };
    }
};

type RestrictedTypographyProps = Omit<TypographyProps, 'variant' | 'variantMapping' | 'color'>;

interface OverrideableComponentProps<C extends React.ElementType> {
    component?: C;
}

function StyledTypography<C extends React.ElementType = 'p'>({
    variant,
    isFixedWidth = false,
    color,
    style,
    className,
    ...other
}: CustomTypographyProps & RestrictedTypographyProps & OverrideableComponentProps<C>): JSX.Element {
    const classes = useStyles({ variant, isFixedWidth });
    return (
        <MaterialTypography
            className={classNames(classes.root, className)}
            {...getStyleAndColor(color, style)}
            {...other}
        />
    );
}

export default StyledTypography;
