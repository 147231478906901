import { useSpring } from '@react-spring/core';
import { useEffect } from 'react';
import { SpringValue } from 'react-spring';
import { SessionTick, Wavepath } from 'wavepaths-shared/core';

import { Connection, subscribeTick } from '../../../common/hooks/useSessionTick';

interface UsePlayheadPositionProps {
    currentWave: Wavepath | null;
    pixelsPerMillis: number;
    sessionDuration: number;
    connection?: Connection;
}
export function usePlayheadPosition({
    currentWave,
    pixelsPerMillis,
    sessionDuration,
    connection,
}: UsePlayheadPositionProps): SpringValue<number> {
    const [playheadPosition, setPlayheadPosition] = useSpring(() => ({ x: 0 }));

    useEffect(() => {
        if (!currentWave || currentWave.type === 'pre') {
            setPlayheadPosition({ x: 0 });
        } else if (currentWave.type === 'post') {
            setPlayheadPosition({ x: pixelsPerMillis * sessionDuration });
        } else {
            const onTick = (tick: SessionTick) => {
                const clampedEffectiveTime = Math.min(tick.effectiveTime, tick.sessionDuration);
                const x = clampedEffectiveTime * pixelsPerMillis;
                const delta = Math.abs(playheadPosition.x.get() - x);
                const immediate = delta < 30;
                setPlayheadPosition({ x, immediate });
            };
            return subscribeTick(connection, onTick);
        }
    }, [currentWave, pixelsPerMillis, sessionDuration, connection]);

    return playheadPosition.x;
}

export function usePlayheadPositionV2({
    sessionDurationMs,
    elapsedTimeMs,
    pixelsPerMillis,
}: {
    sessionDurationMs: number;
    elapsedTimeMs: number;
    pixelsPerMillis: number;
}): SpringValue<number> {
    const [playheadPosition, setPlayheadPosition] = useSpring(() => ({ x: 0 }));

    useEffect(() => {
        const clampedEffectiveTime = Math.min(elapsedTimeMs, sessionDurationMs);
        const x = clampedEffectiveTime * pixelsPerMillis;
        const delta = Math.abs(playheadPosition.x.get() - x);
        const immediate = delta < 30;
        setPlayheadPosition({ x, immediate });
    }, [sessionDurationMs, elapsedTimeMs]);

    return playheadPosition.x;
}
