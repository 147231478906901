import styled from 'styled-components';

import { Button } from '@/component-library';

const HelpSectionButton = styled(Button)({
    paddingLeft: '20px',
    paddingRight: '20px',
});

export default HelpSectionButton;
