import { Dialog } from '@material-ui/core';
import { includes } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import TypographyV2 from '../typography/TypographyV2';
import Button from './Button';
import PillOption from './PillOption';
import TextInput from './TextInput';

type Option<ValueType> = {
    label: string;
    value: ValueType;
};

export type FilterInputsProps<ModalityType, IntensityType, AdministrationType, DosageType> = {
    modalityOptions: Option<ModalityType>[];
    modalityValue?: ModalityType;
    onModalityValueChange: (newValue?: ModalityType) => void;

    intensityOptions: Option<IntensityType>[];
    intensityValues: IntensityType[];
    onIntensityValuesChange: (newValues: IntensityType[]) => void;

    administrationOptions: Option<AdministrationType>[];
    administration?: AdministrationType;
    onAdministrationChange: (newValue?: AdministrationType) => void;

    dosageOptions: Option<DosageType>[];
    dosage?: DosageType;
    onDosageChange: (newValue?: DosageType) => void;

    durationValue?: number | null;
    onDurationChange: (duration: number | undefined) => void;
    isOpen: boolean;
    onClose: () => void;
};

const Content = styled.div`
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const PillOptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 8px;
    gap: 8px;
`;

const MultiInputContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const DurationContainer = styled.div``;

const DurationInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    gap: 8px;
`;

function MultiInput<ValueType>(props: {
    title: string;
    options: Option<ValueType>[];
    values: ValueType[];
    onItemClick?: (itemValue: ValueType) => void;
}) {
    return (
        <MultiInputContainer>
            <TypographyV2 color="grey-700" size="text-sm" weight="semibold">
                {props.title}
            </TypographyV2>
            <PillOptionsContainer>
                {props.options.map((opt) => {
                    const isSelected = includes(props.values, opt.value);
                    return (
                        <PillOption
                            key={`${opt.value}`}
                            name={opt.label}
                            selected={isSelected}
                            onClick={() => props.onItemClick && props.onItemClick(opt.value)}
                        />
                    );
                })}
            </PillOptionsContainer>
        </MultiInputContainer>
    );
}

function FilterInputDialog<
    ModalityType extends unknown,
    IntensityType extends unknown,
    AdministrationType extends unknown,
    DosageType extends unknown
>({
    modalityOptions,
    modalityValue,
    onModalityValueChange,
    intensityOptions,
    intensityValues,
    onIntensityValuesChange,
    administrationOptions,
    administration,
    onAdministrationChange,
    dosageOptions,
    dosage,
    onDosageChange,
    durationValue,
    onDurationChange,
    isOpen,
    onClose,
}: FilterInputsProps<ModalityType, IntensityType, AdministrationType, DosageType>) {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth={'sm'}
        >
            <Content>
                <TypographyV2 color="grey-700" size="text-lg" weight="semibold">
                    Filters
                </TypographyV2>
                <MultiInput
                    title="Modality"
                    options={modalityOptions}
                    values={modalityValue ? [modalityValue] : []}
                    onItemClick={(val) => onModalityValueChange(modalityValue === val ? undefined : val)}
                />
                {administrationOptions.length ? (
                    <MultiInput
                        title="Administration"
                        options={administrationOptions}
                        values={administration ? [administration] : []}
                        onItemClick={(val) => onAdministrationChange(administration === val ? undefined : val)}
                    />
                ) : null}
                {dosageOptions.length ? (
                    <MultiInput
                        title="Dosage"
                        options={dosageOptions}
                        values={dosage ? [dosage] : []}
                        onItemClick={(val) => onDosageChange(dosage === val ? undefined : val)}
                    />
                ) : null}
                <DurationContainer>
                    <TypographyV2 color="grey-700" size="text-sm" weight="semibold">
                        Duration
                    </TypographyV2>
                    <DurationInputContainer>
                        <TextInput
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            style={{ width: 70 }}
                            variant="outlined"
                            value={durationValue ?? ''}
                            size="small"
                            onChange={(newDuration) => {
                                onDurationChange(
                                    newDuration.target.value ? Number(newDuration.target.value) : undefined,
                                );
                            }}
                        />
                        <TypographyV2 color="grey-700" size="text-sm" weight="medium">
                            mins
                        </TypographyV2>
                    </DurationInputContainer>
                </DurationContainer>
                <MultiInput
                    title="Intensity"
                    options={intensityOptions}
                    values={intensityValues}
                    onItemClick={(val) =>
                        onIntensityValuesChange(
                            intensityValues.includes(val)
                                ? intensityValues.filter((v) => v !== val)
                                : [...intensityValues, val],
                        )
                    }
                />
                <Button size="m" variant="solid-blue" onClick={onClose}>
                    Show results
                </Button>
            </Content>
        </Dialog>
    );
}

export default FilterInputDialog;
