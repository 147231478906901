import React, { useState } from 'react';
import {
    CoreEmotionalAtmosphere,
    PathScore,
    ScheduledExperimentalBridgePathScore,
    ScheduledWavepath,
    TherapeuticDirection,
    Wavepath,
} from 'wavepaths-shared/core';

import { DropdownControl, SegmentedControl } from '@/component-library';
import { ControlOption } from '@/component-library/components/Control/types';

import * as infoCopy from './infoCopy';
import { convertListToOptions } from './pathScoreFns';

interface SelectorState {
    fromEmotion?: CoreEmotionalAtmosphere;
    toEmotion?: CoreEmotionalAtmosphere;
    music: string;
}

const selectorStateDefault: SelectorState = {
    fromEmotion: undefined,
    toEmotion: undefined,
    music: '',
};

interface BridgeWaveSelectorProps {
    pathScores: ScheduledExperimentalBridgePathScore[];
    wavepath: ScheduledWavepath;
    onWavepathEditClear: () => void;
    onWavepathEdited: (wavepath: Wavepath) => void;
}

export const isBridgePathScore = (p: PathScore): p is ScheduledExperimentalBridgePathScore =>
    p.direction === TherapeuticDirection.BRIDGE;

const filterScoresByFromEmotion = (
    scores: ScheduledExperimentalBridgePathScore[],
    fromEmotion?: CoreEmotionalAtmosphere,
) => scores.filter((score) => (fromEmotion && score.emotion.from ? score.emotion.from === fromEmotion : true));

const filterScoresByToEmotion = (scores: ScheduledExperimentalBridgePathScore[], toEmotion?: CoreEmotionalAtmosphere) =>
    scores.filter((score) => (toEmotion && score.emotion.to ? score.emotion.to === toEmotion : true));

const filterScoresByMusic = (scores: ScheduledExperimentalBridgePathScore[], music?: string) =>
    scores.filter((score) => (music ? score.music === music : true));

function BridgeWaveSelector({
    pathScores,
    wavepath,
    onWavepathEdited,
    onWavepathEditClear,
}: BridgeWaveSelectorProps): JSX.Element {
    const [selectorState, setSelectorState] = useState<SelectorState>({
        ...selectorStateDefault,
        ...(isBridgePathScore(wavepath.pathScore) && {
            fromEmotion: wavepath.pathScore.emotion.from ?? undefined,
            toEmotion: wavepath.pathScore.emotion.to ?? undefined,
            music: wavepath.pathScore.music ?? '',
        }),
    });

    const extractFromEmotionsFromScores = (scores: ScheduledExperimentalBridgePathScore[]) => {
        const emotions = new Set<CoreEmotionalAtmosphere | undefined>();
        for (const score of scores) {
            emotions.add(score.emotion.from);
        }
        return emotions;
    };

    const extractToEmotionsFromScores = (scores: ScheduledExperimentalBridgePathScore[]) => {
        const emotions = new Set<CoreEmotionalAtmosphere | undefined>();
        for (const score of scores) {
            emotions.add(score.emotion.to);
        }
        return emotions;
    };

    const extractMusicFromScores = (scores: ScheduledExperimentalBridgePathScore[]) => {
        const musics = new Set<string>();
        for (const score of scores) {
            musics.add(score.music);
        }
        return musics;
    };

    const fromEmotionFilteredScores = filterScoresByFromEmotion(pathScores, selectorState.fromEmotion);
    const toEmotionFilteredScores = filterScoresByToEmotion(fromEmotionFilteredScores, selectorState.toEmotion);

    const musicOptions = [...extractMusicFromScores(toEmotionFilteredScores)].map(
        (value): ControlOption<string> => ({
            label: value,
            value: value,
            disabled: false,
        }),
    );

    const handleMusicChange = (music: string) => {
        const musicFilteredScores = filterScoresByMusic(toEmotionFilteredScores, music);

        const pathScore = musicFilteredScores[0];

        setSelectorState({
            ...selectorStateDefault,
            fromEmotion: selectorState.fromEmotion,
            toEmotion: selectorState.toEmotion,
            music,
        });
        onWavepathEdited({
            ...wavepath,
            duration: undefined,
            pathScore,
            pathId: pathScore.id,
        });
    };

    return (
        <>
            <SegmentedControl
                name="wave-fromEmotion"
                heading={'From'}
                canSave={false}
                size="small"
                colour="dark"
                options={convertListToOptions(
                    [
                        CoreEmotionalAtmosphere.SILENCE,
                        CoreEmotionalAtmosphere.STILLNESS,
                        CoreEmotionalAtmosphere.VITALITY,
                        CoreEmotionalAtmosphere.BITTERSWEET,
                        CoreEmotionalAtmosphere.TENSION,
                    ],
                    extractFromEmotionsFromScores(pathScores),
                )}
                value={selectorState.fromEmotion}
                onChange={(value?: CoreEmotionalAtmosphere) => {
                    setSelectorState({
                        ...selectorStateDefault,
                        fromEmotion: value,
                    });
                    onWavepathEditClear();
                }}
                info={infoCopy.emotionFrom}
            />
            <SegmentedControl
                name="wave-toEmotion"
                heading={'To'}
                canSave={false}
                size="small"
                colour="dark"
                options={convertListToOptions(
                    [
                        CoreEmotionalAtmosphere.SILENCE,
                        CoreEmotionalAtmosphere.STILLNESS,
                        CoreEmotionalAtmosphere.VITALITY,
                        CoreEmotionalAtmosphere.BITTERSWEET,
                        CoreEmotionalAtmosphere.TENSION,
                    ],
                    extractToEmotionsFromScores(fromEmotionFilteredScores),
                )}
                value={selectorState.toEmotion}
                onChange={(value?: CoreEmotionalAtmosphere) => {
                    setSelectorState({
                        ...selectorStateDefault,
                        fromEmotion: selectorState.fromEmotion,
                        toEmotion: value,
                    });
                    onWavepathEditClear();
                }}
                disabled={!selectorState.fromEmotion}
                info={infoCopy.emotionTo}
            />
            <DropdownControl
                name="wave-music"
                heading={'Music'}
                canSave={false}
                size="small"
                colour="dark"
                options={musicOptions}
                value={selectorState.music}
                onChange={handleMusicChange}
                disabled={!selectorState.toEmotion}
                info={infoCopy.music}
            />
        </>
    );
}

export default BridgeWaveSelector;
