import { useCallback, useEffect, useState } from 'react';
export interface Queueable {
    description: string;
    callback: () => void;
    onCancel?: () => void;
    onSkipQueue?: () => void;
}

export type QueuedFunction = Queueable & {
    queueTime: number;
    timeUntilExecutionInSeconds: number;
    hasFinishedQueueing: boolean;
};

export const useActionQueue = (): {
    queuedFunction: null | QueuedFunction;
    onSkipQueue: () => void;
    onCancel: () => void;
    hasQueue: boolean;
    queueFunction: (queueable: Queueable) => void;
} => {
    const [queuedFunction, _setQueuedFunction] = useState<null | QueuedFunction>(null);

    const cancelCallback: (() => void) | undefined = queuedFunction?.onCancel;
    const skipQueueCallback: (() => void) | undefined = queuedFunction?.onSkipQueue;

    const queueFunction = useCallback(
        (queueable: Queueable): void => {
            // If the queueing feature is not enabled, invoke immediately instead of queueing
            // if something has already been queued do nothing
            if (queuedFunction !== null) {
                return;
            }
            const queueTime = 5;
            _setQueuedFunction({
                ...queueable,
                queueTime,
                timeUntilExecutionInSeconds: queueTime,
                hasFinishedQueueing: false,
            });
        },
        [queuedFunction],
    );

    useEffect(() => {
        const countdownQueuedFunction = () => {
            _setQueuedFunction((_queuedFunction) => {
                if (_queuedFunction === null) {
                    return null;
                }

                const timeUntilExecutionInSeconds = _queuedFunction.timeUntilExecutionInSeconds - 1;
                if (timeUntilExecutionInSeconds <= -1) {
                    return null;
                }

                return {
                    ..._queuedFunction,
                    timeUntilExecutionInSeconds,
                    hasFinishedQueueing: timeUntilExecutionInSeconds <= 0,
                };
            });

            if (queuedFunction?.timeUntilExecutionInSeconds === 1) {
                queuedFunction.callback();
            }
        };

        const to = setTimeout(countdownQueuedFunction, 1000);
        return () => {
            clearTimeout(to);
        };
    }, [queuedFunction]);

    const onCancel = useCallback(() => {
        _setQueuedFunction((f) => (f ? { ...f, hasFinishedQueueing: true } : f));
        setTimeout(() => _setQueuedFunction(null), 1000);
        cancelCallback && cancelCallback();
    }, [cancelCallback]);

    const onSkipQueue = useCallback(() => {
        _setQueuedFunction((f) => (f ? { ...f, hasFinishedQueueing: true } : f));
        setTimeout(() => _setQueuedFunction(null), 1000);
        skipQueueCallback && skipQueueCallback();
        queuedFunction && queuedFunction.callback();
    }, [queuedFunction]);

    const hasQueue = queuedFunction !== null;

    return {
        queueFunction,
        queuedFunction,
        onCancel,
        onSkipQueue,
        hasQueue,
    };
};
