import { useEffect, useState } from 'react';

import { Connection } from '../../../../common/hooks/useSessionTick';
import { PendingBehaviour } from '../../../../freudConnection/PendingBehaviourTimer';

function usePendingBehaviours(
    connection: Pick<Connection, 'on'>,
): {
    pendingBehaviours: PendingBehaviour[];
} {
    const [pendingBehaviours, setPendingBehaviours] = useState<PendingBehaviour[]>([]);

    useEffect(() => {
        if (connection) {
            connection.on('pendingBehavioursUpdate', (pendingBehaviours: PendingBehaviour[]) => {
                setPendingBehaviours(pendingBehaviours ?? []);
            });
        }
    }, [connection]);

    return {
        pendingBehaviours,
    };
}

export default usePendingBehaviours;
