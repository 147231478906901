import React from 'react';
import { useLocation } from 'react-router-dom';
import { isAdmin } from 'wavepaths-shared/domain/user';

import Layout from '@/component-library/components/Layout';
import { Features, useFeatures } from '@/features';

import { signOut, useAuthContext } from './auth';
import configs from './configs';

export const LayoutContainer = ({ children }: { children: React.ReactChild[] | React.ReactChild }) => {
    const location = useLocation();
    const { userData } = useAuthContext();

    const { isEnabled } = useFeatures();

    return (
        <Layout
            currentRoute={location.pathname}
            communityUrl={configs.community.BASE_URL}
            userName={userData?.name ?? ''}
            onSignOutClick={signOut}
            isAdmin={!!userData && isAdmin(userData)}
            showSavedTemplates={isEnabled(Features.SAVE_SESSION_TEMPLATES)}
            showTemplatePrograms={isEnabled(Features.TEMPLATE_PROGRAMS)}
        >
            {children}
        </Layout>
    );
};
