import React from 'react';
import { CoreEmotionalAtmosphere } from 'wavepaths-shared/core';

import { IntensitySlider as IntensitySliderComponent } from '@/component-library';

import { getCEAColour } from '../../../common/util/ceaColours';
import { MAX_SERVER_DEPTH, MIN_DEEPEN_DEPTH, MIN_SERVER_DEPTH } from './depthUtils';

export interface IDepthSliderProps {
    targetIntensity?: number;
    currentIntensity?: number;
    currentIntensityTransitionTimeSecs?: number;
    cea?: CoreEmotionalAtmosphere;
    ceaTransitionTimeSecs?: number;
    disabled?: boolean;
    onDepthChange: (depth: number) => void;
    onClickWhenDisabled?: () => void;
    setValueRefs?: (valueRefs: { value: number; ref: React.RefObject<HTMLDivElement> }[]) => void;
}
function IntensitySlider({
    targetIntensity,
    currentIntensity,
    currentIntensityTransitionTimeSecs,
    cea,
    ceaTransitionTimeSecs,
    setValueRefs,
    onDepthChange,
    onClickWhenDisabled,
    disabled = false,
}: IDepthSliderProps): JSX.Element {
    const ceaColour = cea ? getCEAColour(cea) : '#D3D3D3';
    return (
        <div
            style={{
                minHeight: '300px',
                flexDirection: 'column',
                alignItems: 'center',
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
            }}
        >
            <IntensitySliderComponent
                setValueRefs={setValueRefs}
                min={MIN_SERVER_DEPTH}
                max={MAX_SERVER_DEPTH}
                divideAt={MIN_DEEPEN_DEPTH}
                targetIntensity={targetIntensity}
                currentIntensity={currentIntensity}
                currentIntensityTransitionTimeSecs={currentIntensityTransitionTimeSecs}
                colorHex={ceaColour}
                colorHexTransitionTimeSecs={ceaTransitionTimeSecs}
                disabled={disabled}
                onChange={onDepthChange}
                onClickWhenDisabled={onClickWhenDisabled}
            />
        </div>
    );
}

export default React.memo(IntensitySlider);
