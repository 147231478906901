import React, { CSSProperties } from 'react';
import ReactSelect, { OptionTypeBase, Props as SelectProps, StylesConfig } from 'react-select';

interface Props<A extends OptionTypeBase> extends SelectProps<A> {
    name: string;
}

const combineStyles = ({
    container,
    valueContainer,
    control,
    indicatorSeparator,
    dropdownIndicator,
    groupHeading,
    option,
    ...rest
}: StylesConfig = {}): StylesConfig => ({
    container: (provided: CSSProperties, state) => ({
        ...provided,
        fontFamily: 'acumin-pro, sans-serif',
        fontSize: '14px',
        color: '#2C3958',
        ...(container && container(provided, state)),
    }),
    valueContainer: (provided: CSSProperties, state) => ({
        ...provided,
        height: '30px',
        lineHeight: '2',
        ...(valueContainer && valueContainer(provided, state)),
    }),
    control: (provided: CSSProperties, state) => ({
        ...provided,
        height: '44px',
        background: 'transparent',
        borderColor: '#2C3958',
        borderRadius: '4px',
        ...(control && control(provided, state)),
    }),
    indicatorSeparator: (provided: CSSProperties, state) => ({
        ...provided,
        backgroundColor: '#2C3958',
        ...(indicatorSeparator && indicatorSeparator(provided, state)),
    }),
    dropdownIndicator: (provided: CSSProperties, state) => ({
        ...provided,
        color: '#2C3958',
        ...(dropdownIndicator && dropdownIndicator(provided, state)),
    }),
    groupHeading: (provided, state) => ({
        ...provided,
        fontSize: '14px',
        fontWeight: 600,
        color: '#2C3958',
        //background: '#F1F3F8',
        borderBottom: '1px solid #2C3958',
        margin: '0 13px 13px 13px',
        padding: '0px 0px 8px 0px',
        textTransform: 'none',
        ...(groupHeading && groupHeading(provided, state)),
    }),
    option: (provided, state) => ({
        ...provided,
        fontFamily: 'acumin-pro, sans-serif',
        fontSize: '14px',
        color: state.isSelected ? '#2C3958' : '#2C3958',
        opacity: state.isDisabled ? 0.4 : 1,
        '&:hover': {
            backgroundColor: state.isSelected ? '#ADB9D6' : '#F1F3F8',
        },
        ...(option && option(provided, state)),
    }),
    ...rest,
});

function Select<A>({ styles, ...rest }: Props<A>) {
    return (
        <ReactSelect
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: '#ADB9D6', // Selected colour
                    primary25: '#F1F3F8', // Hover colour
                },
            })}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), ...combineStyles(styles) }}
            {...rest}
        />
    );
}

export default Select;
