import { intersectionBy, zipWith } from 'lodash';
import { TimeStampedIntensityChange } from 'wavepaths-shared/domain/wavepath';

const hasPlannedIntensitiesChanged = (
    newTimestampedIntensityStages: TimeStampedIntensityChange[],
    oldTimestampedIntensityStages: TimeStampedIntensityChange[],
): boolean => {
    const commonIntensityStagesWithNewValues = intersectionBy(
        newTimestampedIntensityStages,
        oldTimestampedIntensityStages,
        'timeStamp',
    );
    const commonIntensityStagesWithOldValues = intersectionBy(
        oldTimestampedIntensityStages,
        newTimestampedIntensityStages,
        'timeStamp',
    );

    const intensityChangeDifferences = zipWith(
        commonIntensityStagesWithNewValues,
        commonIntensityStagesWithOldValues,
        (newStage, oldStage) => {
            return newStage.intensity - oldStage.intensity;
        },
    );

    return !!intensityChangeDifferences.find((diff) => Math.abs(diff) > 0);
};

export default hasPlannedIntensitiesChanged;
