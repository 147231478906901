import styled from '@emotion/styled';
import Card from '@material-ui/core/Card';
import { capitalize } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import { useEvent, useKey } from 'react-use';
import useMeasure from 'react-use-measure';
import { isScheduledWavepath, PathType, Wavepath } from 'wavepaths-shared/core';

import { Button, EvaIcon, Typography } from '@/component-library';

import {
    isWaveEdited,
    isWaveSelected,
    selectNoWave,
    selectWave,
    WaveSelection,
} from '../../../pages/inSession/autoGuide/waveSelection';
import { WaveSparkline } from '../../../pages/inSession/timeline/WaveSparkline';
import UserEvents from '../../../UserEvents';
import { Pannable } from '../Pannable';
import WavePathSelector from '../WavePathSelector';
import WaveCardTopBar from './WaveCardTopBar';
import { WaveCardWaveDuration } from './WaveCardWaveDuration';

export interface IWaveCardProps {
    wave: Wavepath;
    previousWave?: Wavepath;
    waveSelection: WaveSelection;
    onUpdateWave: (updatedWavepath: Wavepath) => void;
    onAddWave: () => void;
    onSkipToWave?: () => void;
    onRemoveWave: () => void;
    onMoveWaveUp: () => void;
    onMoveWaveDown: () => void;
    onSetWaveSelection: (waveSelection: WaveSelection, force?: boolean) => void;
    onHeightChanged: (waveId: string, newHeight: number) => void;
}

export const CONFIRM_LABEL = 'Save';
export const CANCEL_LABEL = 'Cancel Changes';

const Container = styled.div<{ isOpen?: boolean }>(({ isOpen }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: isOpen ? '12px 2px 24px 16px' : '0 10px 0 24px',
    transition: 'padding 0.1s ease',
}));

const StyledCard = styled(Card)({
    width: '100%',
    height: 'auto',
    display: 'grid',
    gridAutoFlow: 'row',
    justifyItems: 'center',
    padding: '0px 16px 0 12px',
    borderRadius: 4,
    background: 'rgba(255,255,255,1)',
    boxShadow: 'none',
    position: 'relative',
    zIndex: 'auto',
    overflow: 'visible',

    '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        borderRadius: 4,
        boxShadow: '0px 0px 20px rgba(0,0,0,0.1)',
    },
});

const Header = styled.div({
    width: '100%',
    height: '44px',
    display: 'grid',
    placeContent: 'center',
    cursor: 'pointer',
});

const HeaderContent = styled.div({
    width: '568px',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateAreas: '"icon title sparkline intensity duration"',
    gridTemplateColumns: '20px 1fr 150px 45px 60px',
    alignItems: 'center',
    gap: '8px',
});

const WaveIcon = styled.div({
    gridArea: 'icon',
    display: 'grid',
    placeContent: 'center',
    height: 16,
    width: 16,
});

const WaveTitle = styled(Typography)({
    gridArea: 'title',
    position: 'relative',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: '#2c3958',
});

const SparklineWrapper = styled(Pannable)({
    gridArea: 'sparkline',
    height: '100%',
    position: 'relative',
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'start',
    overflow: 'hidden',
});

const SparklineOverflow = styled.div({
    width: '100%',
    height: '100%',
    padding: '4px', // Preventing overflow issue
});

const Intensity = styled(Typography)({
    gridArea: 'intensity',
    textAlign: 'center',
    color: '#2c3958',
});

const Duration = styled(Typography)({
    gridArea: 'duration',
    textAlign: 'right',
    color: '#2c3958',
});

const ExpandedContentContainer = styled.div({
    width: '568px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
});

const SeparatorLine = styled.div({
    height: '1px',
    alignSelf: 'stretch',
    backgroundColor: '#D3D9E9',
});

const ActionButtons = styled.div({
    width: '100%',
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    marginTop: '8px',
    justifyContent: 'end',
    gap: '8px',
});

function WaveCard({
    wave,
    previousWave,
    waveSelection,
    onUpdateWave,
    onAddWave,
    onSkipToWave,
    onRemoveWave,
    onMoveWaveUp,
    onMoveWaveDown,
    onSetWaveSelection,
    onHeightChanged,
}: IWaveCardProps): JSX.Element {
    const isOpen = isWaveSelected(waveSelection, wave.id);
    const isEdited = isWaveEdited(waveSelection, wave.id);
    const { pathScore } = wave;
    const { name, selectionCriteria } = pathScore;
    const emotionalIntensity = selectionCriteria?.emotionalIntensity;
    const waveIcon = pathScore.type === PathType.CURATED ? 'list' : 'activity-outline';

    const onSaveEdits = () => {
        if (isWaveEdited(waveSelection, wave.id)) {
            onUpdateWave(waveSelection.editedWave);
        }
        onSetWaveSelection(selectNoWave(), true);
    };

    const onCancelEdits = () => {
        onSetWaveSelection(selectNoWave(), true);
    };

    const onCardClick = () => {
        if (isOpen) {
            onSetWaveSelection(selectNoWave());
            UserEvents.closeWaveViaQueue();
        } else {
            onSetWaveSelection(selectWave(wave));
            UserEvents.openWaveViaQueue();
        }
    };

    const handleHideDropdown = useCallback(() => {
        if (isOpen) {
            onSetWaveSelection(selectNoWave());
        }
    }, [isOpen, onSetWaveSelection]);
    useKey('Escape', handleHideDropdown, { event: 'keydown' }, [handleHideDropdown]);

    const containerRef = useRef<HTMLDivElement | null>(null);
    const [containerMeasureRef, { height: containerHeight }] = useMeasure();

    const handleClickOutside = (event: Event) => {
        if (isOpen && containerRef.current && !containerRef.current.contains(event.target as Node)) {
            onSetWaveSelection(selectNoWave());
        }
    };
    const main = document.querySelector('main');
    const dialog = document.querySelector('[role="dialog"]');

    useEvent('click', handleClickOutside, main);
    useEvent('click', handleClickOutside, dialog);

    useEffect(() => {
        onHeightChanged(wave.id, containerHeight);
    }, [wave.id, containerHeight, onHeightChanged]);

    return (
        <Container
            isOpen={isOpen}
            ref={(e) => {
                containerRef.current = e;
                containerMeasureRef(e);
            }}
        >
            <WaveCardTopBar
                isOpen={isOpen}
                isEdited={isEdited}
                pathScore={pathScore}
                onAddWave={onAddWave}
                onSkipToWave={onSkipToWave}
                onRemoveWave={onRemoveWave}
                onMoveWaveUp={onMoveWaveUp}
                onMoveWaveDown={onMoveWaveDown}
            />
            <StyledCard>
                <Header aria-label="Edit upcoming wave" onClick={onCardClick}>
                    <HeaderContent>
                        <WaveIcon>
                            <EvaIcon key={waveIcon} name={waveIcon} size={16} fill="#6980b4" />
                        </WaveIcon>
                        <WaveTitle variant="body2">{name}</WaveTitle>
                        {isScheduledWavepath(wave) && wave.plan && (
                            <SparklineWrapper onPanStart={UserEvents.dragSparkline}>
                                <SparklineOverflow>
                                    <WaveSparkline
                                        wave={wave}
                                        previousWave={
                                            previousWave && isScheduledWavepath(previousWave) ? previousWave : undefined
                                        }
                                    />
                                </SparklineOverflow>
                            </SparklineWrapper>
                        )}
                        <Intensity variant="body3">{capitalize(emotionalIntensity)}</Intensity>
                        <Duration variant="body3">
                            <WaveCardWaveDuration wave={wave} />
                        </Duration>
                    </HeaderContent>
                </Header>
                {isOpen && (
                    <ExpandedContentContainer>
                        <SeparatorLine />
                        <WavePathSelector
                            waveSelection={waveSelection}
                            wavepath={wave}
                            onSetWaveSelection={onSetWaveSelection}
                        />
                    </ExpandedContentContainer>
                )}
            </StyledCard>
            {isOpen &&
                (isEdited ? (
                    <ActionButtons>
                        <Button onClick={onCancelEdits} variant="clear" size="s">
                            <Typography variant="body2">{CANCEL_LABEL}</Typography>
                        </Button>
                        <Button onClick={onSaveEdits} variant="solid-dark" size="s">
                            <Typography style={{ color: 'white' }} variant="body2">
                                {CONFIRM_LABEL}
                            </Typography>
                        </Button>
                    </ActionButtons>
                ) : (
                    <div style={{ height: 0 }} />
                ))}
        </Container>
    );
}

export default WaveCard;
