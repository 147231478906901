import styled from '@emotion/styled';
import { intervalToDuration } from 'date-fns';
import { isNumber } from 'lodash';
import React from 'react';

import { Typography } from '@/component-library';

const zeroPad = (num?: number) => String(num).padStart(2, '0');

const formatSessionHours = (sessionHours: number) => {
    const duration = intervalToDuration({ start: 0, end: sessionHours * 60 * 60 * 1000 });

    return `${zeroPad(Math.floor(sessionHours))}:${zeroPad(duration.minutes)}`;
};

const Text = styled(Typography)({
    color: '#2c3958',
});

type UsageInfoProps = {
    usage?: number;
    quota?: number;
};

export function UsageInfo({ usage, quota }: UsageInfoProps) {
    return (
        <>
            <label>Current Usage</label>
            {isNumber(usage) && isNumber(quota) ? (
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Text variant="h6">{formatSessionHours(usage) + ' / ' + formatSessionHours(quota)}</Text>
                    <Text variant="body3">&nbsp; hours of music generated</Text>
                </div>
            ) : (
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography color="textSecondary" variant="h6">
                        Loading...
                    </Typography>
                </div>
            )}
        </>
    );
}
