import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/tenor-sans/400.css';
import '@fontsource/roboto-mono/300.css';

import styled from '@emotion/styled';
import React from 'react';

export interface TypographyV2Props {
    element?: 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    display?: 'inline' | 'block';
    truncated?: boolean;
    font?: 'inter' | 'tenor-sans' | 'roboto-mono';
    weight?: 'light' | 'regular' | 'medium' | 'semibold' | 'bold';
    size?: 'text-xs' | 'text-sm' | 'text-md' | 'text-lg' | 'text-xl' | 'display-xs' | 'display-sm';
    color?: 'black' | 'white' | 'grey-900' | 'grey-700' | 'grey-600' | 'grey-400' | 'purple-600';
    children: React.ReactChild[] | React.ReactChild;
    className?: string;
}

const StyledElement = styled.div<{
    element?: 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    display?: 'inline' | 'block';
    truncated?: boolean;
    font?: 'inter' | 'tenor-sans' | 'roboto-mono';
    weight?: 'light' | 'regular' | 'medium' | 'semibold' | 'bold';
    size?: 'text-xs' | 'text-sm' | 'text-md' | 'text-lg' | 'text-xl' | 'display-xs' | 'display-sm';
    color?: 'black' | 'white' | 'grey-900' | 'grey-700' | 'grey-600' | 'grey-400' | 'purple-600';
}>(
    ({ element, display, truncated, font, weight, size, color }) => `
    display: ${display};
    width: ${truncated ? '100%' : 'auto'};
    white-space: ${truncated ? 'nowrap' : 'normal'};
    overflow: ${truncated ? 'hidden' : 'visible'};
    text-overflow: ${truncated ? 'ellipsis' : 'clip'};
    margin: ${element === 'p' ? '0 0 1em 0' : 0};
    font-family: ${
        font === 'inter'
            ? 'Inter'
            : font === 'tenor-sans'
            ? 'Tenor Sans'
            : font === 'roboto-mono'
            ? 'Roboto Mono'
            : 'Inter'
    };
    font-weight: ${
        weight === 'light'
            ? 300
            : weight === 'regular'
            ? 400
            : weight === 'medium'
            ? 500
            : weight === 'semibold'
            ? 600
            : weight === 'bold'
            ? 700
            : 400
    };
    font-style: normal;
    font-size: ${
        size === 'text-xs'
            ? '12px'
            : size === 'text-sm'
            ? '14px'
            : size === 'text-md'
            ? '16px'
            : size === 'text-lg'
            ? '18px'
            : size === 'text-xl'
            ? '20px'
            : size === 'display-xs'
            ? '24px'
            : size === 'display-sm'
            ? '30px'
            : '16px'
    };
    line-height: ${
        size === 'text-xs'
            ? '18px'
            : size === 'text-sm'
            ? '20px'
            : size === 'text-md'
            ? '24px'
            : size === 'text-lg'
            ? '28px'
            : size === 'text-xl'
            ? '30px'
            : size === 'display-xs'
            ? '32px'
            : size === 'display-sm'
            ? '38px'
            : '24px'
    };
    color: ${
        color === 'black'
            ? '#000000'
            : color === 'white'
            ? '#FFFFFF'
            : color === 'grey-900'
            ? '#101828'
            : color === 'grey-600'
            ? '#475467'
            : color === 'grey-700'
            ? '#344054'
            : color === 'grey-400'
            ? '#98A2B3'
            : color === 'purple-600'
            ? '#7F56D9'
            : '#101828'
    };
  `,
);

const TypographyV2: React.FC<TypographyV2Props> = ({
    element = 'span',
    display = 'block',
    font = 'inter',
    weight = 'regular',
    size = 'text-md',
    color = 'grey-900',
    children,
    className,
}) => {
    const Element = StyledElement.withComponent(element);
    return (
        <Element
            className={className}
            element={element}
            display={display}
            font={font}
            weight={weight}
            size={size}
            color={color}
        >
            {children}
        </Element>
    );
};

export default TypographyV2;
