import { noop } from 'lodash';

import WaveQueueEditorTrackingHandlers from '../../common/components/WaveQueueEditor/WaveQueueEditorTrackingHandlers';
import UserEvents from '../../UserEvents';

export const SessionPlannerTracking: WaveQueueEditorTrackingHandlers = {
    onAddWave: UserEvents.addWaveInEditor,
    skipToWave: noop,
    removeWave: UserEvents.removeWaveInEditor,
    moveWaveDown: UserEvents.moveWaveDownInEditor,
    moveWaveUp: UserEvents.moveWaveUpInEditor,
    updateWave: UserEvents.updateWaveInEditor,
    waveCannotBeMovedFurtherUp: UserEvents.waveCannotBeMovedFurtherUpInEditor,
    waveCannotBeMovedFurtherDown: UserEvents.waveCannotBeMovedFurtherDownInEditor,
};
