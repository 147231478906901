import * as Sentry from '@sentry/browser';
import { useState } from 'react';
import { useTimeoutFn } from 'react-use';
import { Session } from 'wavepaths-shared/core';

export const useMonitoringForSessionInit = (
    session: Session | undefined,
    initialised: boolean,
    timeout = 40_000,
): void => {
    const [alerted, setAlerted] = useState(false);

    const scheduledStart = session?.scheduledStart ?? Date.now();

    const alertIfNotInitialisedBy = scheduledStart + timeout;
    const timeUntilAlert = Math.max(0, alertIfNotInitialisedBy - Date.now());

    useTimeoutFn(() => {
        if (session && !initialised && !alerted) {
            Sentry.withScope((scope) => {
                scope.setExtra('sessionId', session?.id);
                scope.setExtra('broadcastIdentifier', session?.broadcastIdentifier);
                scope.setExtra('scheduledStart', scheduledStart);
                scope.setExtra('timeout', timeout);
                scope.setTag('sessionInitialisationTimeout', 'true');
                Sentry.captureMessage('Session has taken too long to initialise');
            });
            setAlerted(true);
        }
    }, timeUntilAlert);
};
