import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PathScore, RequestType, SessionScore } from 'wavepaths-shared/core';

import { Connection } from '../../../common/hooks/useSessionTick';
import UserEvents from '../../../UserEvents';
import { Queueable } from '../actionQueue/useActionQueue';
import useSession from './useSession';

export type UseSessionScoreReturn =
    | {
          sessionScore: SessionScore;
          updatePathAtIndex: (
              index: number,
              { pathId, pathScore, duration }: { pathId: string; pathScore: PathScore; duration?: number },
          ) => void;
          addPathAtIndex: (
              index: number,
              { pathId, pathScore, duration }: { pathId: string; pathScore: PathScore; duration?: number },
          ) => void;
          removePathAtIndex: (index: number, name?: string) => void;
          movePathToIndex: (index: number, targetIndex: number) => void;
          skipToWave: (index: number) => void;
      }
    | 'loading';

export function useSessionScore(
    sessionId: string,
    connection: Connection,
    queueFunction: (queueable: Queueable) => void,
): UseSessionScoreReturn {
    const { session, loading } = useSession(sessionId, connection);

    const updatePathAtIndex = useCallback(
        (
            index: number,
            { pathId, pathScore, duration }: { pathId: string; pathScore: PathScore; duration?: number },
        ): void => {
            connection?.sendRequest({
                type: RequestType.UpdatePathAtIndex,
                index,
                pathId,
                pathScore,
                duration,
            });
        },
        [connection, queueFunction],
    );

    const addPathAtIndex = useCallback(
        (
            index: number,
            { pathId, pathScore, duration }: { pathId: string; pathScore: PathScore; duration?: number },
        ): void => {
            connection?.sendRequest({
                id: uuidv4(),
                type: RequestType.AddPathAtIndex,
                index,
                pathId,
                pathScore,
                duration,
            });
        },
        [connection, queueFunction],
    );

    const skipToWave = useCallback(
        (index: number): void => {
            queueFunction({
                description: `Skipping to wave`,
                callback: () =>
                    connection?.sendRequest({
                        type: RequestType.SkipWave,
                        index,
                    }),
                onCancel: UserEvents.cancelWaveUpdate,
                onSkipQueue: UserEvents.skipQueueWaveUpdate,
            });
        },
        [connection, queueFunction],
    );

    const removePathAtIndex = useCallback(
        (index: number): void => {
            connection?.sendRequest({
                type: RequestType.RemovePathAtIndex,
                index,
            });
        },
        [connection, queueFunction],
    );

    const movePathToIndex = useCallback(
        (index: number, targetIndex: number): void => {
            connection?.sendRequest({
                type: RequestType.MovePathToIndex,
                index,
                targetIndex,
            });
        },
        [connection, queueFunction],
    );

    if (loading || !session) return 'loading';

    return {
        sessionScore: session.score,
        updatePathAtIndex,
        addPathAtIndex,
        removePathAtIndex,
        movePathToIndex,
        skipToWave,
    };
}
