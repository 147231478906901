import styled from '@emotion/styled';
import React from 'react';

import TypographyV2 from '../typography/TypographyV2';

export interface PageHeadingProps {
    text: string;
}

const Container = styled.div`
    padding: 8px 0 48px 0;
`;

const PageHeading: React.FC<PageHeadingProps> = ({ text }) => {
    return (
        <Container>
            <TypographyV2 element={'h2'} font={'tenor-sans'} weight={'regular'} size={'display-sm'} color={'grey-700'}>
                {text}
            </TypographyV2>
        </Container>
    );
};

export default PageHeading;
