import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';

// import styled, { css } from '@emotion/styled';
interface ISearchBar {
    label: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string;
    onClear: () => void;
}

export const SearchBar = ({ value, label, onChange, onClear }: ISearchBar): React.ReactElement => {
    const theme = useTheme();
    const useStyles = makeStyles({
        root: {
            margin: '0.5em 0',
        },
        inputLabel: {
            color: theme.palette.action.disabled,
        },
    });
    const { inputLabel, root } = useStyles();
    const empty = value === '';

    return (
        <>
            <TextField
                classes={{ root }}
                label={label}
                aria-label={label}
                variant="outlined"
                onChange={onChange}
                value={value}
                InputLabelProps={{
                    className: inputLabel,
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton disabled={empty} onClick={onClear} color="primary">
                                {empty ? <SearchIcon /> : <ClearIcon />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </>
    );
};
