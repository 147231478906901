import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopyRounded';
import React from 'react';

import { Button, Typography } from '@/component-library';

interface SessionLogRecordingDownloadDialogProps {
    sessionId: string;
    streamUrl: string;
    open: boolean;
    onClose: () => void;
}
export const SessionLogRecordingDownloadDialog: React.FC<SessionLogRecordingDownloadDialogProps> = ({
    sessionId,
    streamUrl,
    open,
    onClose,
}) => {
    const ffmpegCommand = `ffmpeg -i ${streamUrl} -map p:1 -acodec copy ${sessionId}.m4a`;
    const copyFfmpegCommand = () => {
        navigator.clipboard.writeText(ffmpegCommand);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm">
            <DialogContent>
                <Typography variant="subtitle1">Download Session Recording</Typography>
                <Typography variant="subtitle2" color="textSecondary" style={{ marginTop: 20 }}>
                    First{' '}
                    <a href="https://ffmpeg.org/download.html" target="_blank" rel="noopener noreferrer nofollow">
                        install the ffmpeg tool
                    </a>
                    . Then run the following command in a terminal window to download an MP4 recording of this session
                    in 256kbps quality:
                </Typography>
                <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    isFixedWidth
                    style={{ marginTop: 20, position: 'relative' }}
                >
                    {ffmpegCommand}
                    <Button
                        variant="clear"
                        size="xs"
                        onClick={copyFfmpegCommand}
                        icon={<CopyIcon fontSize="small" />}
                        aria-label="Copy to clipboard"
                        style={{ position: 'absolute', right: 0, top: 0 }}
                    />
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>OK</Button>
            </DialogActions>
        </Dialog>
    );
};
