import { ActiveLayerDetails } from 'wavepaths-shared/core';

export const DEEPEN_LAYERS = [8, 9, 10, 16];

export const filterToRefreshableLayers = (activeLayers: ActiveLayerDetails[]): ActiveLayerDetails[] =>
    activeLayers.filter((row) => {
        const isAudible = row.audible;
        const isSensible = row.layer < 15;
        const isDeepen = DEEPEN_LAYERS.includes(row.layer);
        const isRefreshable = isAudible && isSensible && !isDeepen;
        return isRefreshable;
    });
