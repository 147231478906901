import styled from '@emotion/styled';
import React, { ReactElement } from 'react';
import { ScoreLibrary, SessionScoreTemplate, Wavepath } from 'wavepaths-shared/core';
import * as Scores from 'wavepaths-shared/domain/scores';

import { Typography } from '@/component-library';

import SessionDurationInfoDialog from './infodialogs/SessionDurationInfoDialog';

const Wrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '700px',
    margin: '0 auto',
    fontSize: '12px',
});

const Label = styled.label({
    marginBottom: '0',
});

const Input = styled.input({
    WebkitAppearance: 'none',
    height: '21px',
    width: '100%',
    margin: '0',
    padding: '0',
    background: 'transparent',
    border: 'none',
    ':focus': {
        outline: 'none',
    },
    '::-webkit-slider-runnable-track': {
        width: '100%',
        height: '2px',
        cursor: 'pointer',
        animate: '0.2s',
        background: '#6980b4',
        borderRadius: '3px',
        border: 'none',
    },
    '::-webkit-slider-thumb': {
        border: 'none',
        height: '15px',
        width: '15px',
        borderRadius: '15px',
        background: '#2C3958',
        cursor: 'pointer',
        WebkitAppearance: 'none',
        transform: 'translateY(-6.5px)',
    },
});

const BoundsLabels = styled.div({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
});

const BoundsLabel = styled.div({
    display: 'flex',
});

interface SessionDurationProps {
    wavepaths: Wavepath[];
    pathScoreLibrary: ScoreLibrary['pathScores'];
    template?: SessionScoreTemplate;
    currentSessionDuration: number;
    onSessionDurationChanged: (duration: number) => void;
}

function SessionDuration({
    template,
    pathScoreLibrary,
    wavepaths,
    currentSessionDuration,
    onSessionDurationChanged,
}: SessionDurationProps): ReactElement {
    const [minimumPathsDuration, maximumPathsDuration] = Scores.getMinMaxTotalDurationForWavepaths(wavepaths);
    const templateDurationRange =
        template && Scores.getMinMaxTotalDurationForTemplateWavepaths(template.wavepaths, pathScoreLibrary);

    const minValue = templateDurationRange?.min ?? minimumPathsDuration;
    const maxValue = templateDurationRange?.max ?? maximumPathsDuration;
    const min = Math.ceil(minValue);
    const max = Math.floor(maxValue);

    const canScoreDurationBeChanged = minValue !== maxValue;

    return (
        <Wrapper>
            <Label htmlFor="sessionDurationSlider">
                <Typography variant="body2" component="span">
                    Session Duration: {Math.round(currentSessionDuration)} min
                    <SessionDurationInfoDialog />
                </Typography>
            </Label>
            {canScoreDurationBeChanged ? (
                <>
                    <Input
                        type="range"
                        min={min}
                        max={max}
                        name="Session Duration"
                        step={1}
                        id="sessionDurationSlider"
                        value={Math.round(currentSessionDuration)}
                        onChange={(event) => onSessionDurationChanged(+event.target.value)}
                    />
                    <BoundsLabels>
                        <BoundsLabel>{min}</BoundsLabel>
                        <BoundsLabel>{max}</BoundsLabel>
                    </BoundsLabels>
                </>
            ) : (
                <div></div>
            )}
        </Wrapper>
    );
}

export default SessionDuration;
