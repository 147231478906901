import { intersection } from 'lodash';
import React, { useState } from 'react';

import { Dialog } from '@/component-library';

import { Input } from './useScorePlanner';

export const useOverwriteConfirmation = (dirtyInputs: Input[]) => {
    const [confirmingOverwrite, setConfirmingOverwrite] = useState<{ callback: () => void } | null>(null);
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const confirmOverwrite = <C extends (...args: any[]) => void>(inputDependencies: Input[], callbackFn: C) => {
        const confirmingCallback = (...args: Parameters<C>) => {
            const needsConfirmation = intersection(inputDependencies, dirtyInputs).length > 0;
            if (needsConfirmation) {
                const callback = () => callbackFn(...args);
                setConfirmingOverwrite({ callback });
            } else {
                callbackFn(...args);
            }
        };
        return confirmingCallback;
    };
    const onCommitConfirmingOverwrite = () => {
        confirmingOverwrite?.callback();
        setConfirmingOverwrite(null);
    };
    const onCancelConfirmingOverwrite = () => {
        setConfirmingOverwrite(null);
    };

    return {
        confirmOverwrite,
        onCommitConfirmingOverwrite,
        onCancelConfirmingOverwrite,
        confirmOverwriteDialog: (
            <Dialog
                open={!!confirmingOverwrite}
                onConfirm={onCommitConfirmingOverwrite}
                onClose={onCancelConfirmingOverwrite}
                title="Overwrite settings?"
                message="If you make this change it could remove, delete, or overwrite other settings that you've applied for this session; as they may no longer be applicable."
                confirmText="Overwrite settings"
            />
        ),
    };
};
