import { IconButton, ListItemSecondaryAction } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { isEmpty, truncate } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, EvaIcon, Typography } from '@/component-library';
import useSessionTemplates, { IUseSessionTemplatesProps } from '@/hooks/useSessionTemplates';

import { ISessionTemplate } from '../../common/api/sessionTemplatesApi';
import UserEvents from '../../UserEvents';

type ISessionTemplatesProps = IUseSessionTemplatesProps;

export const BUTTON_LABEL = 'Plan from favourite';
export function StartSessionFromTemplateButton({ fbUser }: ISessionTemplatesProps): JSX.Element {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { sessionTemplates, deleteTemplate } = useSessionTemplates({ fbUser });

    const handlePlanQuickSession = (event: React.MouseEvent<HTMLElement>) => {
        UserEvents.newSessionFromTemplateClicked();
        setAnchorEl(event.currentTarget);
    };

    const handleCloseQuickSessionPopUp = () => {
        setAnchorEl(null);
    };

    const handleDeleteSessionTemplate = (templateId: string) => {
        UserEvents.sessionTemplateDeleted();
        handleCloseQuickSessionPopUp();
        deleteTemplate(templateId);
    };

    const handleSelectTemplate = (template: ISessionTemplate) => {
        UserEvents.sessionTemplateSelected();
        history.push({
            pathname: '/session/new/oneOnOne',
            state: { sessionTemplate: template },
        });
    };

    return (
        <>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseQuickSessionPopUp}
            >
                {sessionTemplates &&
                    sessionTemplates.map((template, index) => (
                        <MenuItem key={`${template.id}${index}`} onClick={() => handleSelectTemplate(template)}>
                            <Typography variant="body2">{truncate(template.name, { length: 50 })}</Typography>
                            <ListItemSecondaryAction>
                                <IconButton
                                    onClick={() => handleDeleteSessionTemplate(template.id)}
                                    edge="end"
                                    aria-label="delete"
                                >
                                    <EvaIcon name="trash-2-outline" size={16} fill={'#000'} />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </MenuItem>
                    ))}
            </Menu>

            {!isEmpty(sessionTemplates) ? (
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    variant="solid-blue"
                    size="m"
                    icon={<EvaIcon name="copy-outline" size={16} fill={'#FFF'} />}
                    onClick={handlePlanQuickSession}
                >
                    <Typography variant="button" style={{ color: 'white' }}>
                        {BUTTON_LABEL}
                    </Typography>
                </Button>
            ) : null}
        </>
    );
}
