import { findLast } from 'lodash';
import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import {
    RequestType,
    ReviseSessionPlanEvent,
    Session,
    SessionEventType,
    TimestampedSessionEvent,
} from 'wavepaths-shared/core';

import { useAuthContext } from '../../../auth';
import * as sessionApi from '../../../common/api/sessionApi';
import { Connection } from '../../../common/hooks/useSessionTick';
import UserEvents from '../../../UserEvents';

function useSession(
    sessionId: string,
    connection: Connection | null | undefined,
): {
    session?: Session;
    loading: boolean;
    error: boolean;
    startSession: () => void;
} {
    const { firebaseUser } = useAuthContext();
    const [session, setSession] = useState<Session | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [logReplayed, setLogReplayed] = useState(false);

    useEffect(() => {
        // not using SWR to fetch as we don't need caching or revalidation (i.e. all updates come via the session log)
        (async () => {
            if (!firebaseUser) return;
            try {
                const sessionResult = await sessionApi.getSession(sessionId, firebaseUser);
                setSession((s) => ({
                    ...s,
                    ...sessionResult,
                    score: s?.score ?? sessionResult.score,
                    variableInputs: s?.variableInputs ?? sessionResult.variableInputs,
                }));
            } catch (e: any) {
                setError(e);
            }
        })();
    }, [sessionId, firebaseUser]);

    useEffect(() => {
        if (connection) {
            connection.on('logUpdate', (_, newEvents: TimestampedSessionEvent[]) => {
                const reviseSessionPlanEvent = findLast(
                    newEvents,
                    (evt: TimestampedSessionEvent) => evt.event === SessionEventType.ReviseSessionPlanEvent,
                ) as ReviseSessionPlanEvent | undefined;
                //TODO: handle the possibility if session is currently undefined (eg due to some race condition)
                if (reviseSessionPlanEvent) {
                    setSession((s) => ({
                        //TODO: get rid of the typecast
                        ...(s as Session),
                        score: reviseSessionPlanEvent.revisedScore,
                        variableInputs: reviseSessionPlanEvent.revisedVariables,
                    }));
                }
            });
            connection.on('sessionEventsReplayed', () => {
                setLogReplayed(true);
            });
        }
    }, [connection]);

    const loaded = error || (session && logReplayed);

    const startSession = useCallback(() => {
        if (session) {
            UserEvents.sessionStarted(session);
        }
        if (!connection) return;
        connection.sendRequest({
            type: RequestType.StartSessionTimers,
        });
    }, [connection, session]);

    return {
        session,
        loading: !loaded,
        error: !!error,
        startSession,
    };
}

export default useSession;
