import styled from '@emotion/styled';
import { compact } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createEnumArrayParam, createEnumParam, NumberParam, useQueryParam } from 'use-query-params';
import {
    AdministrationRoute,
    DosageLevel,
    SESSION_SCORE_EMOTIONAL_INTENSITIES,
    SessionScoreEmotionalIntensity,
    SessionScoreModality,
} from 'wavepaths-shared/core';

import { Button } from '@/component-library';
import FilterInputDialog from '@/component-library/components/FilterInputs';
import LoadingCardRow from '@/component-library/components/LoadingTemplateCardRow';
import PageHeading from '@/component-library/components/PageHeading';
import PillValue from '@/component-library/components/PillValue';
import TemplateCard from '@/component-library/components/TemplateCard';
import TemplateGrid from '@/component-library/components/TemplateGrid';
import TypographyV2 from '@/component-library/typography/TypographyV2';
import { MODALITIES_V2 } from '@/domain/data/modalities';
import {
    getAdministrationsForMedicine,
    getDefaultSessionDurationForModality,
    getDosagesForMedicineAndAdministration,
} from '@/domain/modalities';
import useScoreTemplates from '@/hooks/useScoreTemplates';

import { useAuthContext } from '../../../auth';
import { ListScoreTemplatesItem } from '../../../common/api/contentApi';
import { LayoutContainer } from '../../../LayoutContainer';

const NoResultsContainer = styled.div`
    width: 100%;
    grid-column: 1/-1;
    text-align: center;
`;

const Templates = React.memo(
    ({
        templates,
        isLoading,
        duration,
    }: {
        templates?: ListScoreTemplatesItem[];
        duration?: number | null;
        isLoading: boolean;
    }) => {
        const history = useHistory();

        return (
            <TemplateGrid>
                <>
                    {templates &&
                        (templates.length ? (
                            templates.map((t) => (
                                <TemplateCard
                                    key={'template-' + t.id}
                                    onCardClick={() => {
                                        history.push({
                                            pathname: '/templates/' + t.id,
                                            search: duration ? `?duration=${duration}` : '',
                                        });
                                    }}
                                    title={t.name}
                                    subtitle={t.subtitle}
                                    intensity={t.intensity}
                                    modality={t.modality}
                                    minDurationMins={Math.ceil(t.durationMins.min)}
                                    maxDurationMins={Math.floor(t.durationMins.max)}
                                    emotionalities={t.emotionalities}
                                    id={t.id}
                                ></TemplateCard>
                            ))
                        ) : (
                            <NoResultsContainer>
                                <TypographyV2 color="grey-400" size="text-md">
                                    Sorry, we couldn't find any templates for these filters
                                </TypographyV2>
                            </NoResultsContainer>
                        ))}
                    {isLoading && <LoadingCardRow columns={4} />}
                </>
            </TemplateGrid>
        );
    },
);

const FilterInputsContainer = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    flex-direction: row;
    margin-bottom: 32px;
`;

const FilterInputsListContainer = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    flex-direction: row;
    overflow: scroll;
    padding: 8px 0;
`;

const LoadMoreButtonContainer = styled.div({
    width: '100%',
    gridColumn: '1/-1',
    textAlign: 'center',
});

const FilterLabel = styled(TypographyV2)({
    whiteSpace: 'nowrap',
});

const modalityOptions = MODALITIES_V2.map(({ name }) => ({
    label: name,
    value: name,
}));

function TemplateContainer() {
    const { firebaseUser } = useAuthContext();

    const [isDialogOpen, setDialogIsOpen] = useState(false);

    const [durationMins, setDuration] = useQueryParam<number | null | undefined>('duration', NumberParam);

    const [_intensityValues, setIntensityValues] = useQueryParam(
        'intensity',
        createEnumArrayParam(Object.values(SessionScoreEmotionalIntensity)),
    );
    const intensityValues = _intensityValues ?? [];
    const intensityOptions = SESSION_SCORE_EMOTIONAL_INTENSITIES.map((intensity) => ({
        label: intensity,
        value: intensity,
    }));

    const [modality, setModality] = useQueryParam('modality', createEnumParam(Object.values(SessionScoreModality)));

    const [administration, _setAdministration] = useQueryParam(
        'administration',
        createEnumParam(Object.values(AdministrationRoute)),
    );
    const administrationOptions = modality
        ? getAdministrationsForMedicine(modality).map(({ name, id }) => ({
              label: name,
              value: id,
          }))
        : [];

    const [dosage, setDosage] = useQueryParam('dosage', createEnumParam(Object.values(DosageLevel)));
    const dosageOptions =
        modality && administration
            ? getDosagesForMedicineAndAdministration(modality, administration).map(({ name, id }) => ({
                  label: name,
                  value: id,
              }))
            : [];

    const setAdministration = (administration?: AdministrationRoute | null) => {
        _setAdministration(administration);
        setDosage(undefined);
    };

    const filterCriteria = {
        modalities: modality && [modality],
        administrations: administration && [administration],
        dosages: dosage && [dosage],
        durationMins,
        intensities: intensityValues,
    };

    const handleSetModality = (modality?: SessionScoreModality | null) => {
        setModality(modality);
        const availableAdministrations = modality ? getAdministrationsForMedicine(modality) : [];
        setAdministration(availableAdministrations.length === 1 ? availableAdministrations[0].id : undefined);
        setDosage(undefined);
    };

    useEffect(() => {
        if (dosage && modality) {
            setDuration(getDefaultSessionDurationForModality(modality, { administration, dosage }));
        }
    }, [dosage, modality]);

    const { templates, loadNext, isLoading, remainingPages } = useScoreTemplates({
        fbUser: firebaseUser,
        itemsPerPage: 16,
        criteria: filterCriteria,
    });

    return (
        <>
            <PageHeading text={'Explore All Templates'} />
            <FilterInputsContainer>
                <FilterLabel size={'text-sm'} color={'grey-700'}>
                    Filter by
                </FilterLabel>
                <FilterInputsListContainer>
                    <PillValue
                        options={modalityOptions}
                        onPillClick={() => setDialogIsOpen(true)}
                        name="Modality"
                        values={compact([modality])}
                        onItemClick={() => setDialogIsOpen(true)}
                        onItemDelete={() => handleSetModality(undefined)}
                    />
                    <PillValue
                        options={[{ value: durationMins, label: `${durationMins} mins` }]}
                        onPillClick={() => setDialogIsOpen(true)}
                        name="Duration"
                        values={durationMins ? [durationMins] : []}
                        onItemClick={() => setDialogIsOpen(true)}
                        onItemDelete={() => setDuration(undefined)}
                    />
                    <PillValue
                        options={intensityOptions}
                        onPillClick={() => setDialogIsOpen(true)}
                        name="Intensity"
                        values={intensityValues}
                        onItemClick={(itemValue) =>
                            setIntensityValues(intensityValues.filter((val) => itemValue !== val))
                        }
                        onItemDelete={(itemValue) =>
                            setIntensityValues(intensityValues.filter((val) => itemValue !== val))
                        }
                    />
                    {!!administrationOptions.length ? (
                        <PillValue
                            options={administrationOptions}
                            onPillClick={() => setDialogIsOpen(true)}
                            name="Administration"
                            values={compact([administration])}
                            onItemClick={() => setDialogIsOpen(true)}
                            onItemDelete={() => setAdministration(undefined)}
                        />
                    ) : null}
                    {!!dosageOptions.length ? (
                        <PillValue
                            options={dosageOptions}
                            onPillClick={() => setDialogIsOpen(true)}
                            name="Dosage"
                            values={compact([dosage])}
                            onItemClick={() => setDialogIsOpen(true)}
                            onItemDelete={() => setDosage(undefined)}
                        />
                    ) : null}
                </FilterInputsListContainer>
            </FilterInputsContainer>
            <FilterInputDialog
                isOpen={isDialogOpen}
                modalityOptions={modalityOptions}
                modalityValue={modality}
                onModalityValueChange={handleSetModality}
                intensityOptions={intensityOptions}
                intensityValues={intensityValues}
                onIntensityValuesChange={setIntensityValues}
                administrationOptions={administrationOptions}
                administration={administration}
                onAdministrationChange={setAdministration}
                dosageOptions={dosageOptions}
                dosage={dosage}
                onDosageChange={setDosage}
                onDurationChange={setDuration}
                durationValue={durationMins}
                onClose={() => setDialogIsOpen(false)}
            />
            <Templates isLoading={isLoading} templates={templates} duration={durationMins} />
            {!isLoading && remainingPages > 0 && (
                <LoadMoreButtonContainer>
                    <Button onClick={loadNext} variant="clear-underlined">
                        Load more
                    </Button>
                </LoadMoreButtonContainer>
            )}
        </>
    );
}

function TemplatesWithNav() {
    return (
        <LayoutContainer>
            <TemplateContainer />
        </LayoutContainer>
    );
}

export default TemplatesWithNav;
