import * as Sentry from '@sentry/browser';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { isNumber } from 'lodash';

const axios = Axios.create({
    timeout: 40000,
    headers: { 'Content-Type': 'application/json' },
});

export const onRejected = (error: AxiosError): Promise<any> => {
    const status = error.response?.status;
    if (!(isNumber(status) && status < 500)) {
        Sentry.withScope((scope) => {
            error.config.url && scope.setTag('request.url', error.config.url);
            error.config.method && scope.setTag('request.method', error.config.method);
            Sentry.captureException(error);
        });
    }
    return Promise.reject(error);
};

axios.interceptors.response.use((response: AxiosResponse) => response, onRejected);

export default axios;
