import _ from 'lodash';

export const getPathAndParameter = (page: string): { path: string; parameter?: string } => {
    const pageTrimmed = page === '/' ? '/' : _.trimEnd(page, '/');
    if (pageTrimmed.includes('/logs/')) {
        const path = '/logs/';
        const parameter = _.split(pageTrimmed, /.*logs\//)[1];
        return { path, parameter };
    } else if (pageTrimmed.includes('/session/') && !pageTrimmed.includes('new')) {
        const path = '/session/';
        const parameter = _.split(pageTrimmed, /.*session\//)[1];
        return { path, parameter };
    } else {
        return { path: pageTrimmed };
    }
};
