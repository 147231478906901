import { Dialog, DialogContent, Popover } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import VolumeIcon from '@material-ui/icons/VolumeMuteRounded';
import React, { useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { Session } from 'wavepaths-shared/core';

import { Button, Menu } from '@/component-library';

import { Header } from '../../../common/components/Header';
import { Clock } from '../SessionClock';
import { SessionInfo } from '../SessionInfo';
import { SessionName } from '../SessionName';
import { VolumeMeter } from '../VolumeMeter';

interface PrecomposedGuideHeader {
    session: Session;
    elapsedTimeMs: number;
    volume: number;
    setVolume: (newVol: number) => void;
}

const useStyles = makeStyles<Theme>(() => ({
    paper: { maxWidth: 398 },
}));

export const PrecomposedGuideHeader: React.FC<PrecomposedGuideHeader> = React.memo(
    ({ volume, setVolume, session, elapsedTimeMs }) => {
        const history = useHistory();
        const [sessionInfoOpen, setSessionInfoOpen] = useState(false);
        const [volumeAnchor, setVolumeAnchor] = useState<Element>();
        const classes = useStyles();

        const menuActions = [
            {
                element: 'Session Info',
                icon: <InfoIcon />,
                onClick: () => setSessionInfoOpen(true),
            },
            {
                element: 'Go Back To Dashboard',
                icon: <ArrowBackIcon />,
                onClick: () => history.push('/'),
            },
        ];

        return (
            <Header
                left={<SessionName session={session} />}
                center={
                    <Clock
                        timeElapsedMs={elapsedTimeMs}
                        totalDurationMs={Number(session.variableInputs.totalDuration) * 1000 * 60}
                    />
                }
                right={
                    <div className="tour-sessionOptions" style={{ display: 'flex', flexDirection: 'row' }}>
                        <>
                            {isBrowser ? (
                                <>
                                    <Button
                                        variant="clear"
                                        size="xs"
                                        onClick={(evt) => setVolumeAnchor(evt.currentTarget)}
                                        icon={<VolumeIcon fontSize="small" />}
                                        aria-label="Volume"
                                    />
                                    <Popover
                                        open={!!volumeAnchor}
                                        anchorEl={volumeAnchor}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        onClose={() => setVolumeAnchor(undefined)}
                                    >
                                        <VolumeMeter volume={volume} onSetVolume={setVolume} />
                                    </Popover>
                                </>
                            ) : null}
                        </>
                        <Menu
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            options={menuActions}
                        />
                        <Dialog
                            classes={{ paper: classes.paper }}
                            open={sessionInfoOpen}
                            onClose={() => setSessionInfoOpen(false)}
                        >
                            <DialogContent>
                                <SessionInfo closeDialog={() => setSessionInfoOpen(false)} session={session} />
                            </DialogContent>
                        </Dialog>
                    </div>
                }
            />
        );
    },
);
