import { useEffect, useState } from 'react';

export const useDebounce = <T,>(value: T, delay = 666) => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const debouncer = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => clearTimeout(debouncer);
    }, [value, delay]);

    return debouncedValue;
};
