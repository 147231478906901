import { useCallback, useState } from 'react';

export type UseSnackbarReturn = {
    content: string | null;
    setSnackbarContent: (content: string | null, onDismiss?: () => void) => void;
    closeSnackbar: () => void;
};

export const useSnackbar = (): UseSnackbarReturn => {
    const [snackbarState, setSnackbarContent] = useState<{ content: string | null; onDismiss?: () => void }>({
        content: null,
    });

    const closeSnackbar = useCallback(() => {
        setSnackbarContent({ content: null });
        snackbarState.onDismiss && snackbarState.onDismiss();
    }, [snackbarState]);

    const setSnackbarContentHandler = useCallback(
        (content: string | null, onDismiss?: () => void) => setSnackbarContent({ content, onDismiss }),
        [],
    );

    return {
        content: snackbarState.content,
        setSnackbarContent: setSnackbarContentHandler,
        closeSnackbar,
    };
};
