import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

export const TabLabelWithCount = ({
    name,
    count,
    loading = false,
}: {
    name: string;
    count: number;
    loading?: boolean;
}): React.ReactElement => (
    <Box display="flex" justifyContent="centre" color="#2c3958">
        <Box component="span" fontWeight={600}>
            {name}
        </Box>
        <Box component="span" fontWeight={500} width={'1em'} pl={'0.5em'}>
            {loading ? <CircularProgress size={'0.8em'} /> : count}
        </Box>
    </Box>
);
