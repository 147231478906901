import React from 'react';

interface TabPanelProps {
    children: React.ReactNode;
    index: number;
    value: number;
}

export const TabPanel = ({ children, value, index, ...other }: TabPanelProps): React.ReactElement => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {value === index && <div>{children}</div>}
    </div>
);
