import firebase from 'firebase';
import { SHA3 } from 'sha3';

import configs from '../configs';
import { FormResponse, FormResponseElement, FormTemplate, FormTemplateElement } from './formTypes';

const templateToResponseElement = (element: FormTemplateElement): FormResponseElement => {
    if (!['header', 'description'].includes(element.type)) {
        return {
            ...element,
            value: undefined,
        };
    } else {
        return element;
    }
};

export const formTemplateToResponseObject = (sessionId: string, formTemplate: FormTemplate): FormResponse => {
    return {
        templateReferences: [formTemplate.id],
        dateCreated: new Date(),
        sessionId: sessionId,
        responderEmail: undefined,
        type: formTemplate.type,
        anonymous: formTemplate.anonymous,
        elements: formTemplate.elements.map((elem: FormTemplateElement) => templateToResponseElement(elem)),
    };
};

export const getFormTemplates = async (
    type: string,
    fbUser: firebase.User,
): Promise<{ formTemplates: FormTemplate[] }> => {
    try {
        const idToken = await fbUser.getIdToken();
        const response = await fetch(`${configs.cloudFunctions.URL}/formTemplates?type=${type}`, {
            method: 'GET',
            headers: { Authorization: `idToken ${idToken}`, 'Cache-Control': 'no-cache, no-store' },
        });
        return response.json();
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const postFormResponse = async (formResponse: FormResponse, fbUser: firebase.User): Promise<FormResponse> => {
    try {
        const idToken = await fbUser.getIdToken();
        const response = await fetch(`${configs.cloudFunctions.URL}/formResponses`, {
            method: 'POST',
            headers: {
                Authorization: `idToken ${idToken}`,
                'Cache-Control': 'no-cache, no-store',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formResponse),
        });
        return response.json();
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const pickFirstFormTemplateAndPostFormResponse = async (
    sessionId: string,
    type: string,
    fbUser: firebase.User,
): Promise<void> => {
    const { formTemplates } = await getFormTemplates(type, fbUser);
    if (formTemplates[0]) {
        const formResponse = formTemplateToResponseObject(sessionId, formTemplates[0]);
        await postFormResponse(formResponse, fbUser);
    }
};

const generateSecurityHash = (sessionId: string, creatorId: string): string => {
    const hashFunction = new SHA3(512);
    hashFunction.update(sessionId + creatorId);
    const hash = hashFunction.digest('hex');
    /*   console.log("sessionId", sessionId, "creatorId", creatorId, "hash", hash); */
    return hash;
};

export const getIntegrationLink = (sessionId: string, creatorId: string): string => {
    const baseURL = configs.playerWebsite.BASE_URL;
    const hash = generateSecurityHash(sessionId, creatorId);
    const url = `${baseURL}/clientIntegration?sessionId=${sessionId}&hash=${hash}`;

    return url;
};

export const getSessionFormResponses = async (
    sessionId: string,
    fbUser: firebase.User,
): Promise<{ formResponses: FormResponse[] }> => {
    try {
        const idToken = await fbUser.getIdToken();
        const response = await fetch(
            `${configs.cloudFunctions.URL}/formResponses?sessionId=${sessionId}&hash=${generateSecurityHash(
                sessionId,
                fbUser.uid,
            )}`,
            {
                method: 'GET',
                headers: { Authorization: `idToken ${idToken}`, 'Cache-Control': 'no-cache, no-store' },
            },
        );
        return response.json();
    } catch (e) {
        console.error(e);
        throw e;
    }
};
