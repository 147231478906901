import React, { useEffect, useState } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

import LoadingOrb from '@/components/LoadingOrb';

import { useAuthContext } from './auth';

// without this component, the signup page would navigate to the '/' homepage on auth user change,
// before navigating back to the 'onboarding' page. This component prevents this by only taking the
// initial value of the user into account.
export function UnauthedRoute({ children, ...rest }: RouteProps) {
    const { userData, isLoaded } = useAuthContext();
    const [initLoad, setInitLoad] = useState<
        undefined | Pick<ReturnType<typeof useAuthContext>, 'userData' | 'isLoaded'>
    >(undefined);

    const history = useHistory();

    useEffect(() => {
        if (isLoaded && !initLoad) setInitLoad({ userData, isLoaded });
    }, [isLoaded]);

    useEffect(() => {
        if (initLoad?.userData) history.push('/');
    }, [initLoad?.userData]);

    return <Route {...rest}>{initLoad?.isLoaded || initLoad?.userData ? children : <LoadingOrb />}</Route>;
}
