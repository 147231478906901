import { Stripe } from 'stripe';

import { BonnySubscription } from '../api/types';

export const hasActiveSubscriptionOld = (subscriptions: Stripe.Subscription[] = []): boolean => {
    const activeSubscriptions = subscriptions.filter((sub) => ['active', 'past_due', 'trialing'].includes(sub.status));
    return activeSubscriptions.length > 0;
};

export const isSubscriptionActive = (subscription: BonnySubscription) =>
    subscription && subscription.status !== 'inactive';

export default { hasActiveSubscription: hasActiveSubscriptionOld };
