import React from 'react';
import { Redirect, RouteProps } from 'react-router-dom';

import LoadingOrb from '@/components/LoadingOrb';
import { isSubscriptionActive } from '@/domain/userUtils';
import { Feature, Features } from '@/features';
import useMySubscription from '@/hooks/useMySubscription';

import { PrivateRoute } from './PrivateRoute';

export function PaidRoute({ children, ...rest }: RouteProps) {
    const { subscription, isLoading } = useMySubscription();
    const userHasActiveSubscription = subscription ? isSubscriptionActive(subscription) : false;

    return (
        <PrivateRoute {...rest}>
            {!isLoading ? (
                <Feature
                    name={Features.FREE_ACCESS}
                    activeComponent={<>{children}</>}
                    inactiveComponent={userHasActiveSubscription ? <>{children}</> : <Redirect to="/subscriptions" />}
                    loadingComponent={<LoadingOrb />}
                />
            ) : (
                <LoadingOrb />
            )}
        </PrivateRoute>
    );
}
