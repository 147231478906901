import { sample } from 'lodash';
import React, { useState } from 'react';
import {
    Acousticness,
    BaseScheduledGenerativePathScore,
    CoreEmotionalAtmosphere,
    isScheduledGenerativePathScore,
    PathScore,
    PathScoreModes,
    ScheduledExperimentalPathScore,
    ScheduledGenerativeDeepenPathScore,
    SessionScoreEmotionalIntensity,
    TherapeuticDirection,
    Wavepath,
} from 'wavepaths-shared/core';

import { DropdownControl, SegmentedControl } from '@/component-library';
import { ControlOption } from '@/component-library/components/Control/types';

import * as infoCopy from './infoCopy';
import * as pathScoreFns from './pathScoreFns';

export interface SelectorState {
    mode?: PathScoreModes;
    emotion?: CoreEmotionalAtmosphere;
    tone?: Acousticness;
    intensity?: SessionScoreEmotionalIntensity;
    music?: string;
    duration: number | string;
}

const selectorStateDefault: SelectorState = {
    emotion: undefined,
    tone: undefined,
    intensity: undefined,
    music: '',
    duration: '',
};

export type DeepenPathScore =
    | BaseScheduledGenerativePathScore
    | ScheduledGenerativeDeepenPathScore
    | ScheduledExperimentalPathScore;

interface TonalDeepenWaveSelectorProps {
    pathScores: DeepenPathScore[];
    wavepath: Wavepath;
    onWavepathEdited: (wavepath: Wavepath) => void;
    onWavepathEditClear: () => void;
}

export const isDeepenPathScore = (p: PathScore): p is DeepenPathScore => p.direction === TherapeuticDirection.DEEPEN;

function TonalDeepenWaveSelector({
    pathScores,
    wavepath,
    onWavepathEdited,
    onWavepathEditClear,
}: TonalDeepenWaveSelectorProps): JSX.Element {
    const [selectorState, setSelectorState] = useState<SelectorState>({
        ...selectorStateDefault,
        ...(isDeepenPathScore(wavepath.pathScore) &&
            wavepath.pathScore.mode === 'Tonal' && {
                emotion: typeof wavepath.pathScore.emotion === 'string' ? wavepath.pathScore.emotion : undefined,
                intensity: wavepath.pathScore.selectionCriteria?.emotionalIntensity,
                tone: 'acousticness' in wavepath.pathScore ? wavepath.pathScore.acousticness : undefined,
                music: 'music' in wavepath.pathScore ? wavepath.pathScore.music : '',
                duration: wavepath.duration ?? pathScoreFns.AUTO_VALUE,
            }),
    });

    const filteredPathScores = pathScores.filter(isScheduledGenerativePathScore);

    const emotionFilteredScores = pathScoreFns.filterScoresByEmotion(filteredPathScores, selectorState.emotion);

    const toneColourFilteredScores = pathScoreFns.filterScoresByTone(emotionFilteredScores, selectorState.tone);
    const intensityFilteredScores = pathScoreFns.filterScoresByIntensity(
        toneColourFilteredScores,
        selectorState.intensity,
    );
    const postIntensityColourFilteredScores = pathScoreFns.filterScoresByMusic(
        intensityFilteredScores,
        selectorState.music,
    );

    const durationOptions: ControlOption<number | string>[] = [
        ...[...pathScoreFns.extractDurationsFromScores(postIntensityColourFilteredScores)].map(
            (value): ControlOption<number | string> => ({
                label: value === pathScoreFns.AUTO_VALUE ? value : `${value} minutes`,
                value: value,
                disabled: false,
            }),
        ),
    ];

    const handleDurationChange = (duration: number | string) => {
        setSelectorState({
            ...selectorStateDefault,
            emotion: selectorState.emotion,
            tone: selectorState.tone,
            intensity: selectorState.intensity,
            music: selectorState.music,
            duration,
        });
        const durationFilteredScores = pathScoreFns.filterScoresByDuration(postIntensityColourFilteredScores, duration);
        const pathScore = sample(durationFilteredScores);
        if (pathScore) {
            onWavepathEdited({
                ...wavepath,
                duration: typeof duration === 'number' ? duration : undefined,
                pathScore,
                pathId: pathScore.id,
            });
        }
    };

    return (
        <>
            <SegmentedControl
                name="wave-emotion"
                heading={'Emotionality'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    [
                        CoreEmotionalAtmosphere.STILLNESS,
                        CoreEmotionalAtmosphere.VITALITY,
                        CoreEmotionalAtmosphere.BITTERSWEET,
                        CoreEmotionalAtmosphere.TENSION,
                    ],
                    pathScoreFns.extractEmotionsFromScores(filteredPathScores),
                )}
                value={selectorState.emotion}
                onChange={(value?: CoreEmotionalAtmosphere) => {
                    setSelectorState({
                        ...selectorStateDefault,
                        emotion: value,
                    });
                    onWavepathEditClear();
                }}
                info={infoCopy.emotion}
            />
            <SegmentedControl
                name="wave-tone"
                heading={'Tone'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    ['Acoustic', 'Mixed', 'Electronic'] as Acousticness[],
                    pathScoreFns.extractToneColoursFromScores(emotionFilteredScores),
                )}
                value={selectorState.tone}
                onChange={(value?: Acousticness) => {
                    setSelectorState({
                        ...selectorStateDefault,
                        emotion: selectorState.emotion,
                        tone: value,
                    });
                    onWavepathEditClear();
                }}
                disabled={!selectorState.emotion}
                info={infoCopy.tone}
            />
            <SegmentedControl
                name="wave-intensity"
                heading={'Intensity'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    [
                        SessionScoreEmotionalIntensity.LOW,
                        SessionScoreEmotionalIntensity.MEDIUM,
                        SessionScoreEmotionalIntensity.HIGH,
                        SessionScoreEmotionalIntensity.ALL,
                    ],
                    pathScoreFns.extractIntensitiesFromScores(toneColourFilteredScores),
                )}
                value={selectorState.intensity}
                onChange={(value) => {
                    setSelectorState({
                        ...selectorStateDefault,
                        tone: selectorState.tone,
                        emotion: selectorState.emotion,
                        intensity: value,
                    });
                    onWavepathEditClear();
                }}
                disabled={!selectorState.tone}
                info={infoCopy.intensity}
            />
            <DropdownControl
                name="wave-music"
                heading={'Music'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    [...pathScoreFns.extractMusicOptionsFromScores(intensityFilteredScores)],
                    pathScoreFns.extractMusicOptionsFromScores(intensityFilteredScores),
                )}
                value={selectorState.music}
                onChange={(value?: string) => {
                    setSelectorState({
                        ...selectorStateDefault,
                        emotion: selectorState.emotion,
                        tone: selectorState.tone,
                        intensity: selectorState.intensity,
                        music: value,
                    });
                    onWavepathEditClear();
                }}
                disabled={!selectorState.intensity}
                info={infoCopy.composition}
            />
            <DropdownControl
                name="wave-duration"
                heading={'Duration'}
                canSave={false}
                size="small"
                colour="dark"
                options={durationOptions}
                value={durationOptions.length ? selectorState.duration : ''}
                onChange={handleDurationChange}
                disabled={!selectorState.music}
                info={infoCopy.duration}
            />
        </>
    );
}

export default TonalDeepenWaveSelector;
