import styled from '@emotion/styled';
import React, { ReactElement } from 'react';
import { CoreEmotionalAtmosphere } from 'wavepaths-shared/core';

import { Typography } from '@/component-library';

import { getCEAColour } from '../../../../common/util/ceaColours';

interface IProps {
    emotion: CoreEmotionalAtmosphere;
    name: string;
}

const Container = styled.div({
    display: 'inline-grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(3, min-content)',
    gap: 4,
    alignItems: 'center',
});

const CEAIcon = styled.div<{ emotion: CoreEmotionalAtmosphere }>(({ emotion }) => ({
    width: 8,
    height: 8,
    borderRadius: 4,
    background: getCEAColour(emotion),
}));

const StatusBarMessageWithIcon = ({ emotion, name }: IProps): ReactElement => (
    <Container>
        <Typography variant="body3">to</Typography>
        <CEAIcon emotion={emotion} />
        <Typography variant="body3">{name} wave</Typography>
    </Container>
);

export default StatusBarMessageWithIcon;
