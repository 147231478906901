import styled from '@emotion/styled';
import React from 'react';

import PageHeading from '@/component-library/components/PageHeading';
import TypographyV2 from '@/component-library/typography/TypographyV2';

import { LayoutContainer } from '../../LayoutContainer';
import { Program } from './Program';
import { useTemplatePrograms } from './useTemplatePrograms';

const Description = styled.div`
    margin-bottom: 32px;
`;

const Programs = styled.div``;

const Content = styled.div`
    max-width: 700px;
    margin: 0 auto;
`;

function ProgramsContainer() {
    const { programs } = useTemplatePrograms();

    return (
        <Content>
            <PageHeading text={'Template Programs'} />
            <Description>
                <TypographyV2 color="grey-600" size="text-sm">
                    Our programs are collections of musical templates that we curated to offer a suggested sequence of
                    templates that you can select and tailor to your client's therapy sessions.
                </TypographyV2>
                <br />
                <TypographyV2 color="grey-600" size="text-sm">
                    These programs are suggestions, a starting point from which you can customise and tailor the
                    experience to suit your client's unique needs and preferences. Your client's input and feedback
                    always remains an invaluable compass to guide your template selection and music adaptation.
                </TypographyV2>
                <br />
                <TypographyV2 color="grey-600" size="text-sm">
                    <strong>Important: </strong>Ensure you select the template that is right for your way of working,
                    for example the route of administration and dosage when working with Ketamine, by identifying the
                    appropriate template templates in the search. For easy access, we recommend you to save the
                    templates you wish to work with, and name them accordingly.
                </TypographyV2>
            </Description>
            <Programs>
                {programs.map((p) => (
                    <Program key={p.id} program={p} />
                ))}
            </Programs>
        </Content>
    );
}

function ProgramsWithNav() {
    return (
        <LayoutContainer>
            <ProgramsContainer />
        </LayoutContainer>
    );
}

export default ProgramsWithNav;
