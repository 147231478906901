import './Banner.scss';

import React, { ReactElement, useState } from 'react';

interface IBannerProps {
    children: React.ReactChild[] | React.ReactChild;
    className?: string;
    buttonText?: string;
}

export function Banner({ children, className, buttonText = 'OK' }: IBannerProps): ReactElement {
    const [isVisible, setIsVisible] = useState<boolean>(true);

    return (
        <>
            {isVisible && (
                <div className={'infoBanner' + ' ' + className}>
                    <div className="infoBanner--content">
                        {children}
                        <div className="infoBanner--button" onClick={() => setIsVisible(false)}>
                            {buttonText}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
