import React from 'react';

import IntroSessionCard from './ProductTourCard';

type IWelcomeTourProps = {
    setShowIntroProductTour: (val: boolean) => void;
};

export function WelcomeTourCard({ setShowIntroProductTour }: IWelcomeTourProps): JSX.Element {
    return (
        <>
            <IntroSessionCard
                title={'Welcome to Wavepaths'}
                info={`A short introduction`}
                duration={'1 min'}
                onClickPlaySessionNow={() => setShowIntroProductTour(true)}
            />
        </>
    );
}
