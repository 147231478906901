import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useMeasure } from 'react-use';
import { useDrag } from 'react-use-gesture';

const useStyles = makeStyles(() => ({
    overlay: {
        position: 'absolute',
        top: 0,
        width: '20px',
        height: '100%',
        pointerEvents: 'none',
    },
    overlayLeft: {
        left: 0,
        background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
    },
    overlayRight: {
        right: 0,
        background: 'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
    },
}));

interface PannableProps {
    className?: string;
    onPanStart?: () => void;
}
export const Pannable: React.FC<PannableProps> = ({ className, children, onPanStart }) => {
    const styles = useStyles();
    const [containerRef, { width: containerWidth }] = useMeasure<HTMLDivElement>();
    const [pannableRef, { width: pannableWidth }] = useMeasure<HTMLDivElement>();
    const [translate, setTranslate] = useState(0);
    const dragRef = useRef<HTMLDivElement | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const bind = useDrag(
        ({ offset: [x], event, first, last }) => {
            event?.preventDefault();
            setTranslate(x);
            if (first) {
                onPanStart && onPanStart();
                setIsDragging(true);
            }
            if (last) requestAnimationFrame(() => setIsDragging(false));
        },
        {
            axis: 'x',
            bounds: { left: -(pannableWidth - containerWidth), right: 0 },
            eventOptions: { passive: false },
            filterTaps: true,
            domTarget: dragRef,
        },
    );

    const needsDrag = pannableWidth > containerWidth;
    useEffect(() => {
        if (needsDrag) {
            return bind();
        } else {
            setTranslate(0);
        }
    }, [bind, needsDrag]);

    useEffect(() => {
        if (isDragging) {
            // Capture and prevent clicks everywhere during the drag
            const onClick = (evt: MouseEvent) => {
                evt.stopPropagation();
            };
            document.addEventListener('click', onClick, { capture: true });
            return () => {
                document.removeEventListener('click', onClick, {
                    capture: true,
                });
            };
        }
    }, [isDragging]);

    const hasLeftOverflow = translate < 0;
    const hasRightOverflow = pannableWidth + translate > containerWidth;

    return (
        <div className={className} ref={containerRef}>
            <div
                ref={(e: HTMLDivElement) => {
                    pannableRef(e);
                    dragRef.current = e;
                }}
                style={{ transform: `translateX(${translate}px)` }}
            >
                {children}
            </div>
            {hasLeftOverflow && <div className={classNames(styles.overlay, styles.overlayLeft)} />}
            {hasRightOverflow && <div className={classNames(styles.overlay, styles.overlayRight)} />}
        </div>
    );
};
