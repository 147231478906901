import configs from '../../../configs';
import UserEvents from '../../../UserEvents';
import * as icons from './icons';

const pricingTiers = {
    prod: {
        starter: {
            monthly: { usd: 'price_1MigySIV98tzk5SgoDh5KaDY', gbp: 'price_1MigzMIV98tzk5SgqhrPobKI' },
            yearly: { usd: 'price_1MiypfIV98tzk5SgYPLlCb0w', gbp: 'price_1Mih2JIV98tzk5SgtcmVV28r' },
        },
        advanced: {
            monthly: { usd: 'price_1LdBrAIV98tzk5Sg5lAvQfaK', gbp: 'price_1LdBt0IV98tzk5SgiWrz91q0' },
            yearly: { usd: 'price_1MigvSIV98tzk5SgIqbuC8Or', gbp: 'price_1LdBrtIV98tzk5SgZa4zQExm' },
        },
        pro: {
            monthly: { usd: 'price_1Jugw5IV98tzk5Sg2kqgvKFj', gbp: 'price_1KKSJHIV98tzk5SgBpEZj8bi' },
            yearly: { usd: 'price_1Jz3VoIV98tzk5SgYItYS3Hx', gbp: 'price_1KKSJrIV98tzk5Sgi4DkenaP' },
        },
    },
    dev: {
        starter: {
            monthly: { usd: 'price_1MhbQGIV98tzk5Sg3yVeDimX', gbp: 'price_1KLXTdIV98tzk5Sg6EcUET7D' },
            yearly: { usd: 'price_1MhbQGIV98tzk5SgHqu8Ag6V', gbp: 'price_1KLXTsIV98tzk5SgBYOaMrPJ' },
        },
        advanced: {
            monthly: { usd: 'price_1JxDHTIV98tzk5SgQzLqML6z', gbp: 'price_1KLXTdIV98tzk5Sg6EcUET7D' },
            yearly: { usd: 'price_1JxDHTIV98tzk5Sg9O9l5aMN', gbp: 'price_1KLXTsIV98tzk5SgBYOaMrPJ' },
        },
        pro: {
            monthly: { usd: 'price_1JxDHTIV98tzk5SgQzLqML6z', gbp: 'price_1KLXTdIV98tzk5Sg6EcUET7D' },
            yearly: { usd: 'price_1JxDHTIV98tzk5Sg9O9l5aMN', gbp: 'price_1KLXTsIV98tzk5SgBYOaMrPJ' },
        },
    },
};

const prices = configs.freud.ENV === 'prod' ? pricingTiers.prod : pricingTiers.dev;

const offering0Core = {
    offeringTitle: 'Starter',
    features: {
        highlighted: [
            { countOrIcon: 12, description: 'Hours of music' },
            { countOrIcon: 1, description: 'User' },
        ],
        included: [
            {
                countOrIcon: icons.bookIcon.enabled,
                description: 'Library of use-case specific templates',
            },
            {
                countOrIcon: icons.optionsIcon.enabled,
                description: 'Select from a range of music journeys',
            },
            {
                countOrIcon: icons.musicIcon.enabled,
                description: 'Adapt music to medicine and timbre',
            },
        ],
        excluded: [
            {
                countOrIcon: icons.activityIcon.disabled,
                description: 'Live music adaptation',
            },
            {
                countOrIcon: icons.editIcon.disabled,
                description: 'Detailed session customisation',
            },
            {
                countOrIcon: icons.saveIcon.disabled,
                description: 'Save and re-use custom templates',
            },
            {
                countOrIcon: icons.communityIcon.disabled,
                description: 'Community access and training workshops',
            },
            {
                countOrIcon: icons.onboardingIcon.disabled,
                description: '1:1 Onboarding and account management',
            },
        ],
    },
    ctaCopy: 'Try For Free',
};

const offering1Core = {
    offeringTitle: 'Advanced',
    features: {
        highlighted: [
            { countOrIcon: '48', description: 'Hours of music' },
            { countOrIcon: 1, description: 'User' },
        ],
        included: [
            {
                countOrIcon: icons.bookIcon.enabled,
                description: 'Library of use-case specific templates',
            },
            {
                countOrIcon: icons.optionsIcon.enabled,
                description: 'Select from a range of music journeys',
            },
            {
                countOrIcon: icons.musicIcon.enabled,
                description: 'Adapt music to medicine and timbre',
            },
            {
                countOrIcon: icons.activityIcon.enabled,
                description: 'Live music adaptation',
            },
            {
                countOrIcon: icons.editIcon.enabled,
                description: 'Detailed session customisation',
            },
            {
                countOrIcon: icons.saveIcon.enabled,
                description: 'Save and re-use custom templates',
            },
            {
                countOrIcon: icons.communityIcon.enabled,
                description: 'Community access and training workshops',
            },
        ],
        excluded: [
            {
                countOrIcon: icons.onboardingIcon.disabled,
                description: '1:1 Onboarding and account management',
            },
        ],
    },
    ctaCopy: 'Try For Free',
};

export const offering0Monthly = (handleSubscribe: (priceID: string) => Promise<void>, useGBP: boolean) => ({
    ...offering0Core,
    monthlyPriceInDollars: 30,
    monthlyAnnualPrice: 100 * 12,
    ctaClickHandler: () => {
        const priceId = useGBP ? prices.starter.monthly.gbp : prices.starter.monthly.usd;
        UserEvents.subscribeClicked({ priceId });
        handleSubscribe(priceId);
    },
});

export const offering0Annual = (handleSubscribe: (priceID: string) => Promise<void>, useGBP: boolean) => ({
    ...offering0Core,
    monthlyPriceInDollars: 24,
    monthlyAnnualPrice: 12 * 30,
    yearlyAnnualPrice: 12 * 24,
    ctaClickHandler: () => {
        const priceId = useGBP ? prices.starter.yearly.gbp : prices.starter.yearly.usd;
        UserEvents.subscribeClicked({ priceId });
        handleSubscribe(priceId);
    },
});

export const offering1Monthly = (handleSubscribe: (priceID: string) => Promise<void>, useGBP: boolean) => ({
    ...offering1Core,
    monthlyPriceInDollars: 100,
    monthlyAnnualPrice: 100 * 12,
    ctaClickHandler: () => {
        const priceId = useGBP ? prices.advanced.monthly.gbp : prices.advanced.monthly.usd;
        UserEvents.subscribeClicked({ priceId });
        handleSubscribe(priceId);
    },
});

export const offering1Annual = (handleSubscribe: (priceID: string) => Promise<void>, useGBP: boolean) => ({
    ...offering1Core,
    monthlyPriceInDollars: 80,
    monthlyAnnualPrice: 12 * 100,
    yearlyAnnualPrice: 12 * 80,
    ctaClickHandler: () => {
        const priceId = useGBP ? prices.advanced.yearly.gbp : prices.advanced.yearly.usd;
        UserEvents.subscribeClicked({ priceId });
        handleSubscribe(priceId);
    },
});

const offering2Core = {
    offeringTitle: 'Pro',
    features: {
        highlighted: [
            { countOrIcon: '114+', description: 'Hours of music' },
            { countOrIcon: '4+', description: 'Users' },
        ],
        included: [
            {
                countOrIcon: icons.bookIcon.enabled,
                description: 'Library of use-case specific templates',
            },
            {
                countOrIcon: icons.optionsIcon.enabled,
                description: 'Select from a range of music journeys',
            },
            {
                countOrIcon: icons.musicIcon.enabled,
                description: 'Adapt music to medicine and timbre',
            },
            {
                countOrIcon: icons.activityIcon.enabled,
                description: 'Live music adaptation',
            },
            {
                countOrIcon: icons.editIcon.enabled,
                description: 'Detailed session customisation',
            },
            {
                countOrIcon: icons.saveIcon.enabled,
                description: 'Save and re-use custom templates',
            },
            {
                countOrIcon: icons.communityIcon.enabled,
                description: 'Community access and training workshops',
            },
            {
                countOrIcon: icons.onboardingIcon.enabled,
                description: '1:1 Onboarding and account management',
            },
        ],
        excluded: [],
    },
    ctaCopy: 'Try For Free',
};

export const offering2Monthly = (handleSubscribe: (priceID: string) => Promise<void>, useGBP: boolean) => ({
    ...offering2Core,
    monthlyPriceInDollars: '200+',
    monthlyAnnualPrice: 200 * 12 + '+',
    ctaClickHandler: () => {
        const priceId = useGBP ? prices.pro.monthly.gbp : prices.pro.monthly.usd;
        UserEvents.subscribeClicked({ priceId });
        handleSubscribe(priceId);
    },
});

export const offering2Annual = (handleSubscribe: (priceID: string) => Promise<void>, useGBP: boolean) => ({
    ...offering2Core,
    monthlyPriceInDollars: '160+',
    monthlyAnnualPrice: 12 * 200,
    yearlyAnnualPrice: 12 * 160 + '+',
    ctaClickHandler: () => {
        const priceId = useGBP ? prices.pro.yearly.gbp : prices.pro.yearly.usd;
        UserEvents.subscribeClicked({ priceId });
        handleSubscribe(priceId);
    },
});

const offerings = (handleSubscribe: (priceID: string) => Promise<void>, useGBP: boolean) => ({
    offering0: {
        monthly: offering0Monthly(handleSubscribe, useGBP),
        annual: offering0Annual(handleSubscribe, useGBP),
        highlighted: false,
    },
    offering1: {
        monthly: offering1Monthly(handleSubscribe, useGBP),
        annual: offering1Annual(handleSubscribe, useGBP),
        highlighted: true,
    },
    offering2: {
        monthly: offering2Monthly(handleSubscribe, useGBP),
        annual: offering2Annual(handleSubscribe, useGBP),
        highlighted: false,
    },
});

export default offerings;
