import React, { useState } from 'react';

import PageHeading from '@/component-library/components/PageHeading';
import { ErrorBox } from '@/components';

import { useAuthContext } from '../../auth';
import { LayoutContainer } from '../../LayoutContainer';
import { InSessionTourCard } from './InSessionTourCard';
import { WelcomeProductTour } from './WelcomeProductTour';
import { WelcomeTourCard } from './WelcomeTourCard';

function TemplateContainer() {
    const { firebaseUser } = useAuthContext();

    const [sessionStartFailed, setSessionStartFailed] = useState<boolean>(false);

    const [showIntroProductTour, setShowIntroProductTour] = useState(false);

    if (!firebaseUser) return <></>;

    return (
        <>
            <PageHeading text={'Learn Wavepaths'} />
            {sessionStartFailed && (
                <div className="errorbox">
                    <ErrorBox
                        message={'Unable to start the session. Please check your network connection and try again'}
                    />
                </div>
            )}
            {showIntroProductTour && <WelcomeProductTour setShowIntroProductTour={setShowIntroProductTour} />}
            <WelcomeTourCard setShowIntroProductTour={setShowIntroProductTour} />
            <InSessionTourCard fbUser={firebaseUser} setFailed={setSessionStartFailed} />
        </>
    );
}

function TemplatesWithNav() {
    return (
        <LayoutContainer>
            <TemplateContainer />
        </LayoutContainer>
    );
}

export default TemplatesWithNav;
