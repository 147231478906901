import React from 'react';
import { useParams } from 'react-router-dom';

import { useSavedTemplate } from '@/hooks/useSavedTemplate';

import { useAuthContext } from '../../auth';
import useScoreLibrary from '../../common/hooks/useScoreLibrary';
import { LayoutContainer } from '../../LayoutContainer';
import { TemplateInfoContainer } from '.';

export interface TemplateDetailParams {
    templateId: string;
}

export const SAVE_BEFORE_COLLAPSE_LABEL = 'Please Cancel or Save your changes before Collapsing this window';

function SavedTemplateInfoFetchContainer() {
    const { templateId } = useParams<TemplateDetailParams>();

    const { firebaseUser, userData } = useAuthContext();
    const scorelibrary = useScoreLibrary(firebaseUser);

    const { template } = useSavedTemplate({ fbUser: firebaseUser, id: templateId });

    if (!template || scorelibrary.loading || !firebaseUser || !userData) return <></>;

    return (
        <TemplateInfoContainer
            userData={userData}
            firebaseUser={firebaseUser}
            scoreLibrary={scorelibrary}
            savedTemplate={template}
        />
    );
}

function TemplateDetailWithNav() {
    return (
        <LayoutContainer>
            <SavedTemplateInfoFetchContainer />
        </LayoutContainer>
    );
}

export default TemplateDetailWithNav;
