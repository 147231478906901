import styled from '@emotion/styled';
import { isNumber } from 'lodash';
import React from 'react';
import { TimestampedSessionEvent } from 'wavepaths-shared/core';
import { getLayerContentDetails } from 'wavepaths-shared/domain/layerContent';

import { Typography } from '@/component-library';

interface SessionLogLayerTableProps {
    log: TimestampedSessionEvent[];
    atEventIndex: number;
    condensed?: boolean;
    className?: string;
}
export const SessionLogLayerTable: React.FC<SessionLogLayerTableProps> = ({
    log,
    atEventIndex,
    condensed,
    className,
}) => {
    const logTilThis = log.slice(0, atEventIndex + 1);
    const table = getLayerContentDetails(logTilThis);
    return (
        <Typography variant={condensed ? 'body3' : 'body2'} component="div" className={className}>
            <Table>
                <thead>
                    <tr>
                        {!condensed && <TH scope="col" width={50}></TH>}
                        <TH scope="col" width={50}>
                            Layer
                        </TH>
                        {!condensed && <TH scope="col">Layer ID</TH>}
                        <TH scope="col">Collection</TH>
                        <TH scope="col">Composer</TH>
                        {!condensed && <TH scope="col">Collection Status</TH>}
                        {!condensed && <TH scope="col">Layer Type</TH>}
                        <TH scope="col" width={50}>
                            Gain
                        </TH>
                        {!condensed && (
                            <TH scope="col" width={50}>
                                Fade Time
                            </TH>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {table.map((row, idx) => (
                        <TR key={idx} audible={row.audible}>
                            {!condensed && <TD>{row.new && <>NEW</>}</TD>}
                            <TD>{row.layer}</TD>
                            {!condensed && <TD>{isNumber(row.layerId) ? row.layerId : ''}</TD>}
                            <TD>{row.collection}</TD>
                            <TD>{row.composer}</TD>
                            {!condensed && <TD>{row.collectionStatus}</TD>}
                            {!condensed && <td>{row.layerType}</td>}
                            <TD>{isNumber(row.volume) ? row.volume : ''}</TD>
                            {!condensed && <TD>{isNumber(row.fadeTime) ? row.fadeTime : ''}</TD>}
                        </TR>
                    ))}
                </tbody>
            </Table>
        </Typography>
    );
};

const Table = styled.table`
    width: 100%;
    table-layout: fixed;
`;

const TR = styled.tr<{ audible?: boolean }>`
    opacity: ${({ audible }) => (audible ? 1 : 0.25)};
`;

const TH = styled.th<{ width?: number }>`
    width: ${({ width }) => (isNumber(width) ? `${width}px` : 'auto')};
`;

const TD = styled.td`
    overflow: hidden;
    text-overflow: ellipsis;
`;
