import { AxiosError } from 'axios';
import firebase from 'firebase/app';
import {
    EndOfSessionFeedback,
    FirebaseUser,
    PresetScore,
    ScoreLibrary,
    Session,
    SessionHeader,
    SessionLog,
    SessionLogMetadata,
    SessionPageResponse,
    SessionPlan,
    SessionScore,
    SessionScoreDTO,
    SessionType,
    SessionVariables,
} from 'wavepaths-shared/core';
import { CollectionStatus, SessionFeedback, SessionRenderType } from 'wavepaths-shared/types/sessions/core';

import configs from '../../configs';
import axios from '../util/axios';

export const FREUD_BASE_URL = configs.freud.BASE_URL;

export class TooMuchTrafficError extends Error {
    constructor() {
        super('Too Much Traffic Error');
        Object.setPrototypeOf(this, TooMuchTrafficError.prototype);
    }
}

export async function loadScoreLibrary(fbUser: firebase.User): Promise<ScoreLibrary> {
    return axios(`${FREUD_BASE_URL}/scores`, {
        headers: {
            Authorization: `idToken ${await fbUser.getIdToken()}`,
            'Cache-Control': 'no-cache', // the server should control this but this is to bust it for clients with caches from previously, who might never hit the server otherwise
        },
    }).then((res) => res.data);
}

export async function listEndedSessionsByType(type: SessionType, fbUser: firebase.User): Promise<SessionHeader[]> {
    return axios(`${FREUD_BASE_URL}/sessions/ended?type=${type}`, {
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => (res.status === 200 ? res.data : null));
}

export async function listEndedSessionsV2(
    maxNumber: number,
    page: number,
    fbUser: firebase.User,
): Promise<SessionPageResponse> {
    const offset = maxNumber * page;
    return axios(`${FREUD_BASE_URL}/v2/sessions/ended?max=${maxNumber}&offset=${offset}`, {
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => (res.status === 200 ? res.data : null));
}

export async function listEndedSessionsV2ByType(
    maxNumber: number,
    page: number,
    fbUser: firebase.User,
    type: string,
): Promise<SessionPageResponse> {
    const offset = maxNumber * page;
    return axios(`${FREUD_BASE_URL}/v2/sessions/ended?max=${maxNumber}&offset=${offset}&type=${type}`, {
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => (res.status === 200 ? res.data : null));
}

export async function listSessionsAwaitingFeedback(fbUser: firebase.User): Promise<Session[]> {
    return axios(`${FREUD_BASE_URL}/sessions/awaiting_feedback`, {
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => (res.status === 200 ? res.data : null));
}

export async function submitInSessionFeedback(
    sessionId: string,
    feedback: SessionFeedback,
    dspTimeMs: number,
    fbUser: firebase.User,
): Promise<boolean> {
    return axios(`${FREUD_BASE_URL}/sessions/${sessionId}/in-session-feedback`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `idToken ${await fbUser.getIdToken()}` },
        data: JSON.stringify({
            fbUser,
            feedback,
            dspTimeMs,
        }),
    }).then((res) => res.status === 200);
}

export async function sendPreviousSessionFeedback(
    sessionId: string,
    feedback: EndOfSessionFeedback,
    fbUser: firebase.User,
): Promise<boolean> {
    return axios(`${FREUD_BASE_URL}/sessions/awaiting_feedback/${sessionId}/feedback`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `idToken ${await fbUser.getIdToken()}` },
        data: JSON.stringify(feedback),
    }).then((res) => res.status === 200);
}

export async function dismissPreviousSessionFeedback(sessionId: string, fbUser: firebase.User): Promise<boolean> {
    return axios(`${FREUD_BASE_URL}/sessions/awaiting_feedback/${sessionId}/feedback`, {
        method: 'POST',
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => res.status === 200);
}

export async function listSessionSignups(sessionId: string, fbUser: firebase.User): Promise<FirebaseUser[]> {
    return axios(`${FREUD_BASE_URL}/sessions/${sessionId}/signups`, {
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => (res.status === 200 ? res.data : null));
}

export async function deleteSessionSignup(
    sessionId: string,
    firebaseUserId: string,
    fbUser: firebase.User,
): Promise<FirebaseUser[]> {
    return axios(`${FREUD_BASE_URL}/sessions/${sessionId}/signups/${firebaseUserId}`, {
        method: 'DELETE',
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => (res.status === 200 ? res.data : null));
}

export async function getSession(id: string, fbUser: firebase.User): Promise<Session> {
    return axios(`${FREUD_BASE_URL}/sessions/${id}`, {
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => (res.status === 200 ? res.data : null));
}

export async function startSessionEarly(sessionId: string, fbUser: firebase.User): Promise<Session> {
    return axios(`${FREUD_BASE_URL}/sessions/${sessionId}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', Authorization: `idToken ${await fbUser.getIdToken()}` },
        data: JSON.stringify({ scheduledStart: Date.now() - 300 }),
    }).then((res) => res.data);
}

export async function startSession(
    type: SessionType,
    renderType: SessionRenderType,
    sessionScore: SessionScoreDTO,
    sessionVariableInputs: SessionVariables,
    attributeInputs: Partial<Session>,
    plan: SessionPlan,
    filteredLayerIds: number[],
    fbUser: firebase.User,
    contentStatusesToInclude: CollectionStatus[] = ['Approved'],
    quickFadesEnabled = false,
): Promise<Session> {
    return axios(`${FREUD_BASE_URL}/sessions`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `idToken ${await fbUser.getIdToken()}` },
        data: JSON.stringify({
            type,
            renderType,
            sessionScore,
            sessionVariableInputs: {
                ...sessionVariableInputs,
                // Note: prelude duration is becoming less of a thing as we give therapists and users
                // the ability to start their sessions early, so we're just hardcoding at 240 for
                // one-on-one sessions as a reasonably long default value. - Willis Plummer, 02.02.21
                preludeDuration:
                    renderType === SessionRenderType.PRE_RENDERED
                        ? 0
                        : type === SessionType.ONE_ON_ONE
                        ? 240
                        : sessionVariableInputs.preludeDuration,
                postludeDuration:
                    renderType === SessionRenderType.PRE_RENDERED ? 0 : sessionVariableInputs.postludeDuration,
            },
            plan: [],
            filteredLayerIds,
            contentStatusesToInclude,
            quickFadesEnabled,
            ...attributeInputs,
        }),
    })
        .then((res) => res.data)
        .catch((error: AxiosError) => {
            if (error.response?.status === 503) throw new TooMuchTrafficError();
            throw new Error('Network Error');
        });
}

export async function startSessionWithCustomScores(
    type: SessionType,
    renderType: SessionRenderType,
    sessionScore: SessionScore,
    presetScores: PresetScore[],
    sessionVariableInputs: SessionVariables,
    filteredLayerIds: number[],
    isBroadcast: boolean,
    fbUser: firebase.User,
): Promise<Session> {
    return axios(`${FREUD_BASE_URL}/sessions`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `idToken ${await fbUser.getIdToken()}` },
        data: JSON.stringify({
            type,
            renderType,
            sessionScore,
            presetScores,
            sessionVariableInputs,
            filteredLayerIds,
            isBroadcast,
        }),
    }).then((res) => res.data);
}

export async function deleteSession(sessionId: string, audioLatency: number, fbUser: firebase.User): Promise<void> {
    await axios(`${FREUD_BASE_URL}/sessions/${sessionId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `idToken ${await fbUser.getIdToken()}`,
        },
        data: { audioLatency },
    });
}

export async function listLogs(fbUser: firebase.User): Promise<SessionLogMetadata[]> {
    return axios(`${FREUD_BASE_URL}/session_logs`, {
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => res.data);
}

export async function getLog(sessionId: string, fbUser: firebase.User): Promise<SessionLog> {
    return axios(`${FREUD_BASE_URL}/session_logs/${sessionId}`, {
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    }).then((res) => res.data);
}
