import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import classNames from 'classnames';
import React from 'react';

import Typography, { CustomTypographyProps } from '../typography/Typography';

export interface IOption<ValueType> {
    label: string;
    value: ValueType;
}

export interface IOptionButtonGroupProps<ValueType> {
    width?: number | string;
    height?: number | string;
    textVariant?: CustomTypographyProps['variant'];
    selected: ValueType;
    setSelected: (value: ValueType) => void;
    options: IOption<ValueType>[];
}

interface IStyleProps {
    width: number | string;
    height: number | string;
}

const useStyles = makeStyles({
    container: ({ width }: IStyleProps) => ({
        width,
    }),
    button: ({ width, height }: IStyleProps) => ({
        height,
        width,
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 14,
        textTransform: 'none',
        border: 'none',
    }),
    unselected: {
        opacity: 0.5,
    },
});

const OptionButtonGroup = <ValueType extends unknown>({
    selected,
    setSelected,
    options,
    width = 'auto',
    height = 30,
    textVariant = 'body2',
}: IOptionButtonGroupProps<ValueType>): JSX.Element => {
    const classes = useStyles({ width, height });
    return (
        <ButtonGroup className={classes.container}>
            {options.map(({ label, value }, index) => (
                <Button
                    key={`${label}${index}`}
                    className={classNames(classes.button, { [classes.unselected]: !(selected === value) })}
                    onClick={() => setSelected(value)}
                >
                    <Typography variant={textVariant}>{label}</Typography>
                </Button>
            ))}
        </ButtonGroup>
    );
};

export default OptionButtonGroup;
