import { useEffect, useState } from 'react';
import { TimestampedSessionEvent } from 'wavepaths-shared/core';

import { AudioState, FreudConnection } from '../../freudConnection/FreudConnection';
import { PendingBehaviour } from '../../freudConnection/PendingBehaviourTimer';

export type SessionState = {
    log: TimestampedSessionEvent[];
    pendingBehaviours: PendingBehaviour[];
};

export const useAudioState = ({ connection }: { connection: FreudConnection | null }): AudioState => {
    const [audioState, setAudioState] = useState<AudioState>('notStarted');

    useEffect(() => {
        if (!!connection) {
            const handleUpdateAudioState = (newState: AudioState) => {
                setAudioState(newState);
            };
            connection.on('updateAudioState', handleUpdateAudioState);

            return () => {
                console.log('Removing updateAudioState');
                connection.removeListener('updateAudioState', handleUpdateAudioState);
            };
        }
    }, [connection]);

    return audioState;
};

export default useAudioState;
