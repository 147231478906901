import styled from '@emotion/styled';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, EvaIcon } from '@/component-library';

import configs from '../src/configs';
import { AuthContext, signOut } from './auth';
import { Header } from './common/components/Header';
import Logo from './images/WavepathBlueLogo.svg';

const StyledHeader = styled(Header)({
    padding: '24px 0',
    gridTemplateColumns: 'auto auto auto',
});

const StyledLogo = styled.img({
    display: 'block',
    height: '22px',
    width: 'auto',
    marginTop: '4px',
});

const Actions = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
    gap: '12px',
});

const ButtonClear = styled(Button)({
    color: '#2c3958',
});

const ButtonOutlined = styled(Button)({
    color: '#2c3958',
    borderColor: '#ADB9D6',
});

export const UserInfo: React.FC = () => {
    // TODO: delete?
    const { userData } = useContext(AuthContext);

    const history = useHistory();
    return (
        <StyledHeader
            left={<StyledLogo src={Logo} alt="Wavepaths" />}
            right={
                <Actions>
                    {userData && (
                        <ButtonClear
                            className="tour-profileInfo"
                            variant="clear"
                            size="s"
                            onClick={() => {
                                history.push('/profile');
                            }}
                        >
                            <EvaIcon name="person" size={16} />
                            {userData.name}
                        </ButtonClear>
                    )}
                    <ButtonOutlined
                        variant="outlined"
                        size="s"
                        onClick={() => {
                            window.open(configs.community.BASE_URL, '_blank');
                        }}
                    >
                        Community
                    </ButtonOutlined>
                    <ButtonOutlined variant="outlined" size="s" onClick={signOut}>
                        Sign out
                    </ButtonOutlined>
                </Actions>
            }
        />
    );
};
