import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, EvaIcon } from '@/component-library';
import TypographyV2 from '@/component-library/typography/TypographyV2';

import { TemplateProgram } from './useTemplatePrograms';

const ProgramContainer = styled.div<{ isExpanded: boolean }>(({ isExpanded }) => ({
    backgroundColor: 'white',
    // width: 280,
    // height: 150,
    // marginRight: 30,
    // display: 'flex',
    // flexDirection
    // position: 'relative',
    // userSelect: 'none',
    height: isExpanded ? 'auto' : '58px',
    overflow: 'hidden',
    padding: '0 32px 16px',
    opacity: '100%',
    position: 'relative',
    boxShadow: '0px 0px 10px #CFD6E7',
    border: '1px solid white',
    borderRadius: '8px',
    color: 'rgb(44, 57, 88)',
    cursor: 'pointer',
    marginBottom: 20,
}));

const Title = styled(TypographyV2)({
    paddingTop: 12,
    paddingBottom: 12,
    marginBottom: 12,
    borderBottom: '1px solid rgba(17, 17, 17, 0.1)',
});
const Content = styled(TypographyV2)({
    marginBottom: 16,
});
const SectionHeading = styled(TypographyV2)({});
const ExpandIconContainer = styled.div<{ isExpanded: boolean }>`
    position: absolute;
    left: 6px;
    top: 17px;
`;

const TemplateList = styled.ol`
    margin: 0;
    padding-left: 28px;
`;

const TemplateItem = styled.li`
    margin-top: 4px;
`;

const ButtonContainer = styled.div`
    margin-top: 12px;
    display: flex;
    gap: 8px;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

type IProgramProps = {
    program: TemplateProgram;
};

export function Program({ program }: IProgramProps) {
    const [isExpanded, setIsExpanded] = useState(false);
    const history = useHistory();

    console.log({ program: program.templates });
    const { actions } = program;

    return (
        <ProgramContainer isExpanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)}>
            <ExpandIconContainer
                style={{ transform: isExpanded ? 'rotate(90deg)' : 'rotate(0)' }}
                isExpanded={isExpanded}
            >
                <EvaIcon name="chevron-right-outline" fill="#475467" size={24} />
            </ExpandIconContainer>
            <Title element={'h4'} font={'tenor-sans'} weight={'regular'} size={'display-xs'} color={'grey-700'}>
                {program.name}
            </Title>
            <SectionHeading color="grey-600" size="text-md" weight="medium">
                Use Case
            </SectionHeading>
            <Content color="grey-600" size="text-sm">
                {program.useCase}
            </Content>
            <SectionHeading color="grey-600" size="text-md" weight="medium">
                Description
            </SectionHeading>
            <Content color="grey-600" size="text-sm">
                {program.description}
            </Content>
            <SectionHeading color="grey-600" size="text-md" weight="medium">
                Template Sequence
            </SectionHeading>
            <TemplateList>
                {program.templates.map((t, idx) => (
                    <TemplateItem key={t.name + idx}>
                        <TypographyV2 size="text-sm" color="grey-600">
                            {t.name}
                        </TypographyV2>
                    </TemplateItem>
                ))}
            </TemplateList>
            <ButtonContainer>
                {actions.map(({ link, title }) => (
                    <Button key={link} variant="outlined" onClick={() => history.push(link)}>
                        {title}
                    </Button>
                ))}
            </ButtonContainer>
        </ProgramContainer>
    );
}
