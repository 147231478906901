import firebase from 'firebase';
import { toString } from 'lodash';
import { Session, SessionScore } from 'wavepaths-shared/core';

import axios from '../util/axios';
import { FREUD_BASE_URL } from './sessionApiV2';

export type SessionTemplateRequest = Partial<Session> & {
    name: string;
    score: SessionScore;
};

export interface ISessionTemplate extends SessionTemplateRequest {
    id: string;
    creatorId: string;
    createdAt: Date;
}

interface IAddSessionTemplate {
    fbUser: firebase.User;
    template: SessionTemplateRequest;
}

const MY_SESSION_TEMPLATES_URL = `${FREUD_BASE_URL}/sessionTemplates/my`;

export const convertSessionToSessionTemplate = (session: Session): ISessionTemplate => ({
    ...session,
    name: session.variableInputs?.name ? `${toString(session.variableInputs?.name)} - copy` : '',
    creatorId: 'convertedFromSession',
    createdAt: new Date(),
});

export const addSessionTemplate = async ({ fbUser, template }: IAddSessionTemplate): Promise<ISessionTemplate> => {
    const fbIdToken = await fbUser.getIdToken();
    const response = await axios(MY_SESSION_TEMPLATES_URL, {
        headers: { authorization: `idToken ${fbIdToken}` },
        method: 'POST',
        data: template,
    });

    if (response.status === 200) return response.data;
    else throw new Error(`Failed to add favourite: ${response.status}`);
};

export interface IGetSessionTemplatesReturn {
    entries: ISessionTemplate[];
}

export const getSessionTemplates = async ({
    fbUser,
}: {
    fbUser: firebase.User;
}): Promise<IGetSessionTemplatesReturn> => {
    const fbIdToken = await fbUser.getIdToken();
    const response = await axios(MY_SESSION_TEMPLATES_URL, {
        method: 'GET',
        headers: { authorization: `idToken ${fbIdToken}` },
    });

    if (response.status === 200) return response.data;
    else throw new Error('Failed to get session templates');
};

interface IDeleteSessionTemplate {
    fbUser: firebase.User;
    templateId: string;
}

export const deleteSessionTemplate = async ({ fbUser, templateId }: IDeleteSessionTemplate): Promise<void> => {
    const fbIdToken = await fbUser.getIdToken();
    const response = await axios(`${MY_SESSION_TEMPLATES_URL}/${templateId}`, {
        method: 'DELETE',
        headers: { authorization: `idToken ${fbIdToken}` },
    });

    switch (response.status) {
        case 200:
            return;
        case 403:
            throw new Error('You do not have permission to delete this favourite.');
        default:
            throw new Error('There was an error deleting this favourite.');
    }
};
