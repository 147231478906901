import { inRange, isArray, isNumber, range } from 'lodash';
import React, { useState } from 'react';
import { PathScore, ScheduledGenerativePathScore, TherapeuticDirection, Wavepath } from 'wavepaths-shared/core';

import { DropdownControl } from '@/component-library';
import { ControlOption } from '@/component-library/components/Control/types';

import * as infoCopy from './infoCopy';
import * as pathScoreFns from './pathScoreFns';

interface SelectorState {
    duration: number | string;
    name?: string;
}

const selectorStateDefault: SelectorState = {
    duration: '',
    name: '',
};

export type SilencePathScore = ScheduledGenerativePathScore & {
    therapeuticDirection: TherapeuticDirection.SILENCE;
};

const AUTO_VALUE = 'Auto';

interface SilenceWaveSelectorProps {
    pathScores: SilencePathScore[];
    wavepath: Wavepath;
    onWavepathEditClear: () => void;
    onWavepathEdited: (wavepath: Wavepath) => void;
}

export const isSilencePathScore = (p: PathScore): p is SilencePathScore => p.direction === TherapeuticDirection.SILENCE;

function SilenceWaveSelector({
    pathScores,
    wavepath,
    onWavepathEdited,
    onWavepathEditClear,
}: SilenceWaveSelectorProps): JSX.Element {
    const [selectorState, setSelectorState] = useState<SelectorState>({
        ...selectorStateDefault,
        ...(isSilencePathScore(wavepath.pathScore) && {
            duration: wavepath.duration ?? pathScoreFns.AUTO_VALUE,
            name: 'name' in wavepath.pathScore ? wavepath.pathScore.name : '',
        }),
    });

    const filterScoresByDuration = (scores: SilencePathScore[]) =>
        scores.filter((score) => {
            if (selectorState.duration === '' || selectorState.duration === AUTO_VALUE) return true;

            if (isArray(score.duration)) {
                // ie its generative
                if (isNumber(selectorState.duration)) {
                    return inRange(selectorState.duration, score.duration[0], score.duration[1]);
                }
            } else {
                return Math.round(score.duration) === selectorState.duration;
            }
        });

    const extractDurationsFromScores = (scores: SilencePathScore[]) => {
        const durations = new Set<number | string>();
        for (const score of scores) {
            if ('duration' in score) {
                if (typeof score.duration === 'number') {
                    durations.add(Math.round(score.duration));
                } else if (score.duration instanceof Array) {
                    const min = score.duration[0];
                    const max = score.duration[1];
                    range(min, max + 1).forEach((d) => {
                        durations.add(d);
                    });
                    durations.add(AUTO_VALUE);
                }
            }
        }
        return [...durations];
    };

    const nameFilteredScores = pathScoreFns.filterScoresByName(pathScores, selectorState.name);
    const durationFilteredScores = filterScoresByDuration(nameFilteredScores);

    const durationOptions = [
        ...extractDurationsFromScores(nameFilteredScores).map(
            (value): ControlOption<number | string> => ({
                label: value === AUTO_VALUE ? value : `${value} minutes`,
                value: value,
                disabled: false,
            }),
        ),
    ];

    const handleDurationChange = (duration: number | string) => {
        const pathScore = durationFilteredScores[0];
        setSelectorState({
            ...selectorStateDefault,
            ...selectorState,
            duration,
        });
        onWavepathEdited({
            ...wavepath,
            duration: typeof duration === 'number' ? duration : undefined,
            pathScore,
            pathId: pathScore.id,
        });
    };

    const nameOptions = pathScoreFns.extractNameOptionsFromScores(pathScores);

    return (
        <>
            <DropdownControl
                name="wave-silence-type"
                heading={'Type'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(nameOptions, new Set(nameOptions))}
                value={selectorState.name}
                onChange={(value?: string) => {
                    setSelectorState({
                        ...selectorStateDefault,
                        name: value,
                    });
                    onWavepathEditClear();
                }}
                info={infoCopy.composition}
            />
            <DropdownControl
                name="wave-silence"
                heading={'Duration'}
                canSave={false}
                size="small"
                colour="dark"
                options={durationOptions}
                value={selectorState.duration}
                onChange={handleDurationChange}
                info={infoCopy.duration}
                disabled={!selectorState.name}
            />
        </>
    );
}

export default SilenceWaveSelector;
