import { useCallback, useEffect, useState } from 'react';
import { Depth, RequestType, SessionEventType, TickDepth } from 'wavepaths-shared/core';

import { Connection, subscribeTick } from '../../../common/hooks/useSessionTick';
import { HLS_BUFFER_LATENCY } from '../../../freudConnection/hlsUtils';
import UserEvents from '../../../UserEvents';
import { Queueable } from '../actionQueue/useActionQueue';

type ReadProps = {
    targetDepth?: TickDepth;
    currentDepth?: TickDepth;
    transitionTimeSecs?: number;
};

type ReadAndWrite = ReadProps & { setTargetDepth?: (depth: Depth) => void; error?: string | null };

export function useDepth(connection: Connection): ReadProps | 'loading';
export function useDepth(
    connection: Connection,
    queueFunction: (queueable: Queueable) => void,
): ReadAndWrite | 'loading';

export function useDepth(
    connection: Connection,
    queueFunction?: (queueable: Queueable) => void,
): ReadAndWrite | 'loading' {
    const existingConnection = !!connection;
    const [_targetDepth, _setTargetDepth] = useState<Depth | null | undefined>(undefined);
    const [_currentDepth, _setCurrentDepth] = useState<Depth | null | undefined>(undefined);
    const [_depthChangeError, _setDepthChangeError] = useState<string | null>(null);

    const [transitionTimeSecs, setTransitionTimeSecs] = useState<number | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const readProps = {
        targetDepth: _targetDepth,
        currentDepth: _currentDepth,
        error: _depthChangeError,
        transitionTimeSecs,
    };

    const setTargetDepth = useCallback(
        (depth: Depth) => {
            _setDepthChangeError(null);
            if (_targetDepth !== depth && queueFunction) {
                queueFunction({
                    description: 'Transitioning to new intensity',
                    callback: () => connection.sendRequest({ type: RequestType.MoveToDepth, depth }),
                    onCancel: () => {
                        _setTargetDepth(depth);
                        UserEvents.depthChangeCanceled(depth);
                    },
                    onSkipQueue: () => UserEvents.depthChangeSkippedQueue(depth),
                });
                UserEvents.depthChanged(depth);
            }
        },
        [connection, queueFunction, _targetDepth],
    );

    useEffect(() => {
        return subscribeTick(connection, (tick) => {
            if (isLoading) setIsLoading(false);
            if (tick.musicalContent?.targetDepth !== _targetDepth) {
                _setTargetDepth(tick.musicalContent?.targetDepth);
            }
            if (tick.musicalContent?.currentDepth !== _currentDepth) {
                _setCurrentDepth(tick.musicalContent?.currentDepth);
            }
            if (
                tick.musicalContent?.transitionTimeSecs &&
                tick.musicalContent?.transitionTimeSecs !== transitionTimeSecs
            ) {
                setTransitionTimeSecs(tick.musicalContent?.transitionTimeSecs + HLS_BUFFER_LATENCY / 1000);
            }
        });
    }, [existingConnection, connection, isLoading, _targetDepth, _currentDepth, transitionTimeSecs]);

    useEffect(() => {
        connection.on(SessionEventType.MoveToDepthRejected, (event: { rejectedDepth: number }) => {
            _setDepthChangeError(SessionEventType.MoveToDepthRejected);
            _setTargetDepth(event.rejectedDepth);
        });
    }, [connection]);

    if (isLoading) return 'loading';

    if (!queueFunction) return readProps;

    return {
        ...readProps,
        setTargetDepth,
    };
}
