import './app.scss';

import { createBrowserHistory } from 'history';
import React, { useEffect, useMemo } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import { Feature, Features, useFeatures } from '@/features';
import { useUserData } from '@/hooks';

import { AuthContext } from './auth';
import { SubscriptionsContext, useSubscriptions } from './common/hooks/useSubscriptions';
import IntercomWrapper from './IntercomWrapper';
import ActiveSessions from './pages/activeSessions';
import Home from './pages/home';
import { GuideContainer } from './pages/inSession/GuideContainer';
import { PrecomposedGuideContainer } from './pages/inSession/Precomposed/PrecomposedGuideContainer';
import Lessons from './pages/lessons';
import { SessionLog } from './pages/log/SessionLog';
import { Login } from './pages/login/Login';
import { GuidePlannerContainer } from './pages/planner/GuidePlannerContainer';
import Profile from './pages/profile/Profile';
import TemplatePrograms from './pages/programs';
import ResetPassword from './pages/resetPassword/ResetPassword';
import Sessions from './pages/sessions';
import { Signup } from './pages/signup/Signup';
import { TherapistInfo } from './pages/signup/TherapistInfo';
import Subscriptions from './pages/subscriptions';
import TemplateDetail from './pages/templateInfo';
import SavedTemplateDetail from './pages/templateInfo/savedTemplateInfo';
import Templates from './pages/templates/all';
import SavedTemplates from './pages/templates/saved';
import { Terms } from './pages/terms/Terms';
import { PaidRoute } from './PaidRoute';
import { PrivateRoute } from './PrivateRoute';
import SubscriptionsWarnings from './SubscriptionsWarnings';
import SwitchUIBanner from './SwitchUIBanner';
import { UnauthedRoute } from './UnauthedRoute';
import UnsupportedPlatformBanner from './UnsupportedBanner';
import { withTracker } from './withTracker';

const TrackedSignup = withTracker(Signup);
const TrackedTherapistInfo = withTracker(TherapistInfo);
const TrackedLogin = withTracker(Login);
const TrackedResetPassword = withTracker(ResetPassword);
const TrackedGuide = withTracker(GuideContainer);
const TrackedGuidePlanner = withTracker(GuidePlannerContainer);
const TrackedSessionLog = withTracker(SessionLog);
const TrackedHome = withTracker(Home);
const TrackedTemplates = withTracker(Templates);
const TrackedActiveSessions = withTracker(ActiveSessions);
const TrackedTemplateDetail = withTracker(TemplateDetail);
const TrackedSavedTemplates = withTracker(SavedTemplates);
const TrackedSavedTemplateDetail = withTracker(SavedTemplateDetail);
const TrackedPrograms = withTracker(TemplatePrograms);
const TrackedSessions = withTracker(Sessions);
const TrackedLessons = withTracker(Lessons);
const TrackedTerms = withTracker(Terms);
const TrackedProfile = withTracker(Profile);
const TrackedSubscriptions = withTracker(Subscriptions);
const TrackedPrecomposedGuided = withTracker(PrecomposedGuideContainer);

export const App: React.FC = () => {
    const history = useMemo(() => {
        const h = createBrowserHistory();
        if (h.location.state) {
            h.replace({ ...h.location, state: undefined });
        }
        return h;
    }, []);

    useEffect(function clearLoadingState() {
        document.getElementById('loadingscreen')?.remove();
    }, []);

    const { userData, firebaseUser, isLoaded } = useUserData();

    const subscriptionsState = useSubscriptions(userData);
    const [showNewUi, setShowNewUI] = useLocalStorage('showNewUi', true);

    const onChangeUI = (toggleOn: boolean) => {
        setShowNewUI(toggleOn);
        history.push('/');
    };

    return (
        <AuthContext.Provider
            value={{
                userData,
                firebaseUser,
                isLoaded,
            }}
        >
            <IntercomWrapper>
                <SubscriptionsWarnings subscriptionsState={subscriptionsState} userData={userData} />
                <SubscriptionsContext.Provider value={{ ...subscriptionsState }}>
                    <UnsupportedPlatformBanner />
                    <Router history={history}>
                        <QueryParamProvider adapter={ReactRouter5Adapter}>
                            <ProtectedPages
                                firebaseUser={firebaseUser}
                                userData={userData}
                                showNewUi={showNewUi}
                                onChangeUI={onChangeUI}
                            />
                            <UnprotectedPages />
                        </QueryParamProvider>
                    </Router>
                </SubscriptionsContext.Provider>
            </IntercomWrapper>
        </AuthContext.Provider>
    );
};

const UnprotectedPages = () => {
    return (
        <Switch>
            <UnauthedRoute path="/signup">
                <TrackedSignup />
            </UnauthedRoute>
            <UnauthedRoute path="/login">
                <TrackedLogin />
            </UnauthedRoute>
            <UnauthedRoute path="/reset-password">
                <TrackedResetPassword />
            </UnauthedRoute>
            <Route exact path="/terms">
                <TrackedTerms />
            </Route>
        </Switch>
    );
};

const ProtectedPages = ({
    firebaseUser,
    showNewUi,
    onChangeUI,
}: Pick<ReturnType<typeof useUserData>, 'firebaseUser' | 'userData'> & {
    showNewUi?: boolean;
    onChangeUI: (on: boolean) => void;
}) => {
    const { isEnabled } = useFeatures();
    const displayNewUi = showNewUi && isEnabled(Features.SESSION_LAUNCHING_V2);

    return (
        <Switch>
            {/* Paid routes */}
            <PaidRoute exact path="/session/new/:sessionType">
                <>
                    <Feature
                        activeComponent={<SwitchUIBanner showNewUi={showNewUi} setShowNewUI={onChangeUI} />}
                        name={Features.SESSION_LAUNCHING_V2}
                    />
                    <TrackedGuidePlanner />
                </>
            </PaidRoute>
            <PaidRoute path="/session/precomposed/:sessionId">
                <TrackedPrecomposedGuided />
            </PaidRoute>
            <PaidRoute path="/session/:sessionId">
                <TrackedGuide />
            </PaidRoute>
            <PaidRoute path="/lessons">
                <Feature
                    activeComponent={<SwitchUIBanner showNewUi={showNewUi} setShowNewUI={onChangeUI} />}
                    name={Features.SESSION_LAUNCHING_V2}
                />
                <TrackedLessons />
            </PaidRoute>
            <PaidRoute exact path="/">
                <>
                    <Feature
                        activeComponent={<SwitchUIBanner showNewUi={showNewUi} setShowNewUI={onChangeUI} />}
                        name={Features.SESSION_LAUNCHING_V2}
                    />
                    {displayNewUi ? <TrackedHome firebaseUser={firebaseUser} /> : <TrackedSessions />}
                </>
            </PaidRoute>
            <PaidRoute path="/templates/saved/:templateId">
                <>
                    <Feature
                        activeComponent={<SwitchUIBanner showNewUi={showNewUi} setShowNewUI={onChangeUI} />}
                        name={Features.SESSION_LAUNCHING_V2}
                    />
                    <TrackedSavedTemplateDetail />
                </>
            </PaidRoute>
            <PaidRoute path="/templates/saved">
                <>
                    <Feature
                        activeComponent={<SwitchUIBanner showNewUi={showNewUi} setShowNewUI={onChangeUI} />}
                        name={Features.SESSION_LAUNCHING_V2}
                    />
                    <TrackedSavedTemplates />
                </>
            </PaidRoute>
            <PaidRoute path="/templates/programs">
                <Feature
                    activeComponent={<SwitchUIBanner showNewUi={showNewUi} setShowNewUI={onChangeUI} />}
                    name={Features.SESSION_LAUNCHING_V2}
                />
                <TrackedPrograms />
            </PaidRoute>
            <PaidRoute path="/templates/:templateId">
                <>
                    <Feature
                        activeComponent={<SwitchUIBanner showNewUi={showNewUi} setShowNewUI={onChangeUI} />}
                        name={Features.SESSION_LAUNCHING_V2}
                    />
                    <TrackedTemplateDetail />
                </>
            </PaidRoute>
            <PaidRoute path="/templates">
                <>
                    <Feature
                        activeComponent={<SwitchUIBanner showNewUi={showNewUi} setShowNewUI={onChangeUI} />}
                        name={Features.SESSION_LAUNCHING_V2}
                    />
                    <TrackedTemplates />
                </>
            </PaidRoute>
            <PaidRoute path="/sessions">
                <>
                    <Feature
                        activeComponent={<SwitchUIBanner showNewUi={showNewUi} setShowNewUI={onChangeUI} />}
                        name={Features.SESSION_LAUNCHING_V2}
                    />
                    <TrackedActiveSessions />
                </>
            </PaidRoute>
            {/* Unpaid */}
            <PrivateRoute path="/onboarding">
                <TrackedTherapistInfo fbUser={firebaseUser} />
            </PrivateRoute>
            <PrivateRoute path="/logs/:sessionId">
                <TrackedSessionLog firebaseUser={firebaseUser} />
            </PrivateRoute>
            <PrivateRoute path="/profile">
                <TrackedProfile firebaseUser={firebaseUser} />
            </PrivateRoute>
            <PrivateRoute path="/guide">
                <Redirect to="/" />
            </PrivateRoute>
            <PrivateRoute exact path="/subscriptions">
                <TrackedSubscriptions />
            </PrivateRoute>
        </Switch>
    );
};
