import { makeStyles, Theme } from '@material-ui/core';
import useResizeObserver from '@react-hook/resize-observer';
import colorConvert from 'color-convert';
import { clamp, first, isNumber, isUndefined, last, minBy, range } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { animated, config, useSpring, useTransition } from 'react-spring';
import { useGesture } from 'react-use-gesture';

import Typography from '../../typography/Typography';
import EvaIcon from '../EvaIcon';
import SliderStepContainer from './SliderStepContainer';
import ValueIndicator from './ValueIndicator';

interface ISliderStep {
    size: number;
    value: number;
    ref: React.RefObject<HTMLDivElement>;
}

export interface IIntensitySliderProps {
    min: number;
    max: number;
    divideAt: number;
    currentIntensity?: number;
    currentIntensityTransitionTimeSecs?: number;
    targetIntensity?: number;
    colorHex: string;
    colorHexTransitionTimeSecs?: number;
    disabled?: boolean;
    onChange: (depth: number) => void;
    onClickWhenDisabled?: () => void;
    setValueRefs?: (valueRefs: { value: number; ref: React.RefObject<HTMLDivElement> }[]) => void;
}
const MIN_STEP_DIAMETER = 8;
const MAX_STEP_DIAMETER = 24;

const STEP_WIDTH = 26;
const STEP_HEIGHT = 12;
const SLIDER_PADDING = 6;
const SLIDER_WIDTH = SLIDER_PADDING * 2 + STEP_WIDTH;

const stepToPosition = (step?: ISliderStep): number | undefined => {
    return step?.ref.current ? step.ref.current.offsetTop + step.ref.current.offsetHeight / 2 : undefined;
};

function findClosestStepFromPosition(steps: ISliderStep[], position?: number): ISliderStep | undefined {
    if (!position) return;
    return minBy(steps, (step: ISliderStep) => {
        const stepPosition = stepToPosition(step);
        if (stepPosition) {
            return Math.abs(stepPosition - position);
        } else {
            return Infinity;
        }
    });
}

interface IStylesProps {
    disabled: boolean;
    colorLight: string;
    colorMid: string;
    colorDark: string;
    colorShadow: string;
    colorHighlight: string;
    colorTransitionTimeSecs: number;
}

const useStyles = makeStyles<Theme, IStylesProps>({
    container: {
        position: 'relative',
        opacity: ({ disabled }) => (disabled ? 0.5 : 1),
        filter: ({ colorShadow }) => `drop-shadow(0 0 20px ${colorShadow})`,
        willChange: 'filter',
        transition: ({ colorTransitionTimeSecs }: { colorTransitionTimeSecs: number }) =>
            `filter ${colorTransitionTimeSecs}s ease-in-out`,
        pointerEvents: ({ disabled }) => (disabled ? 'none' : 'inherit'),
        '&:hover $labels': {
            opacity: 1,
        },
    },
    endIconContainer: {
        width: SLIDER_WIDTH,
        height: SLIDER_WIDTH,
        display: 'grid',
        placeContent: 'center',
        background: 'white',
        borderRadius: SLIDER_WIDTH / 2,
    },
    //@ts-ignore
    endIconBackground: {
        position: 'relative',
        width: STEP_WIDTH,
        height: STEP_WIDTH,
        display: 'grid',
        placeContent: 'center',
        backgroundColor: ({ colorLight }) => colorLight,
        willChange: 'background-color',
        transition: ({ colorTransitionTimeSecs }: { colorTransitionTimeSecs: number }) =>
            `background-color ${colorTransitionTimeSecs}s ease-in-out`,
        borderRadius: STEP_WIDTH / 2,
        cursor: 'pointer',
        '&:after': {
            content: `""`,
            display: 'block',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            position: 'absolute',
            top: '0',
            left: '0',
            backgroundColor: ({ colorHighlight }: { colorHighlight: string }) => colorHighlight,
            willChange: 'background-color',
            transition: ({ colorTransitionTimeSecs }: { colorTransitionTimeSecs: number }) =>
                `background-color ${colorTransitionTimeSecs}s ease-in-out, opacity 0.15s ease`,
            mixBlendMode: 'multiply',
            opacity: 0,
        },
        '&:hover:after': {
            opacity: 1,
        },
    },
    innerTrack: {
        display: 'inline-grid',
        gridAutoFlow: 'row',
        alignContent: 'start',
        borderRadius: SLIDER_WIDTH / 2,
    },
    sliderStepContainer: {
        position: 'relative',
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: SLIDER_PADDING,
        paddingRight: SLIDER_PADDING,
        overflow: 'hidden',
        background: 'white',
    },
    segmentStart: {
        paddingTop: SLIDER_PADDING + 1,
        borderTopLeftRadius: SLIDER_WIDTH / 2,
        borderTopRightRadius: SLIDER_WIDTH / 2,
        '& $sliderStep': {
            borderTopLeftRadius: STEP_WIDTH / 2,
            borderTopRightRadius: STEP_WIDTH / 2,
        },
    },
    segmentEnd: {
        paddingBottom: SLIDER_PADDING + 1,
        borderBottomLeftRadius: SLIDER_WIDTH / 2,
        borderBottomRightRadius: SLIDER_WIDTH / 2,
        '& $sliderStep': {
            borderBottomLeftRadius: STEP_WIDTH / 2,
            borderBottomRightRadius: STEP_WIDTH / 2,
        },
    },
    //@ts-ignore
    sliderStep: {
        position: 'relative',
        width: STEP_WIDTH,
        height: STEP_HEIGHT,
        backgroundColor: ({ colorLight }) => colorLight,
        willChange: 'background-color, transform', // does not actually change transform, but needed to work around a bug in Safari https://stackoverflow.com/a/66443837
        transition: ({ colorTransitionTimeSecs }: { colorTransitionTimeSecs: number }) =>
            `background-color ${colorTransitionTimeSecs}s ease-in-out`,
        overflow: 'hidden',
        cursor: 'pointer',
        '&:after': {
            content: `""`,
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            backgroundColor: ({ colorHighlight }: { colorHighlight: string }) => colorHighlight,
            willChange: 'background-color',
            transition: ({ colorTransitionTimeSecs }: { colorTransitionTimeSecs: number }) =>
                `background-color ${colorTransitionTimeSecs}s ease-in-out, opacity 0.15s ease`,
            opacity: 0,
        },
        '&:hover:after': {
            opacity: 1,
        },
    },
    sliderStepActive: {
        position: 'absolute',
        width: STEP_WIDTH,
        height: STEP_HEIGHT,
        willChange: 'transform, background-color',
        transformOrigin: 'bottom',
        backgroundColor: ({ colorMid }) => colorMid,
        transition: ({ colorTransitionTimeSecs }: { colorTransitionTimeSecs: number }) =>
            `background-color ${colorTransitionTimeSecs}s ease-in-out`,
    },
    spacer: {
        position: 'relative',
        width: SLIDER_WIDTH,
        height: STEP_WIDTH - 4,
        overflow: 'visible',
        display: 'grid',
        placeContent: 'center',
    },
    spacerImage: {
        display: 'block',
        position: 'absolute',
        top: '50%',
        left: 0,
        width: SLIDER_WIDTH,
        transform: 'translateY(-50%)',
        margin: 0,
        zIndex: -1,
    },
    iconHandle: {
        position: 'absolute',
    },
    iconHandleInner: {
        width: SLIDER_WIDTH,
        height: STEP_HEIGHT,
        display: 'grid',
        placeContent: 'center',
        '&:before': {
            content: `""`,
            zIndex: -1,
            width: STEP_WIDTH,
            height: STEP_WIDTH,
            borderRadius: SLIDER_WIDTH / 2,
            border: ({ colorDark }: { colorDark: string }) => `${STEP_HEIGHT / 4}px solid ${colorDark}`,
            willChange: 'border-color',
            transition: ({ colorTransitionTimeSecs }: { colorTransitionTimeSecs: number }) =>
                `border-color ${colorTransitionTimeSecs}s ease-in-out`,
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            top: '50%',
            left: '50%',
            display: 'grid',
            placeContent: 'center',
            pointerEvents: 'none',
        },
    },
    iconHandleMarker: {
        width: SLIDER_WIDTH,
        height: STEP_HEIGHT / 3,
        borderRadius: STEP_HEIGHT / 6,
        backgroundImage: ({ colorDark }: { colorDark: string }) =>
            `linear-gradient(to right, ${colorDark} 20%, transparent 0 80%, ${colorDark} 0 100% )`,
    },
    defaultHandle: {
        position: 'absolute',
    },
    defaultHandleInner: {
        width: SLIDER_WIDTH,
        height: STEP_HEIGHT,
        display: 'grid',
        placeContent: 'center',
        '&:before': {
            content: `""`,
            zIndex: -1,
            width: STEP_WIDTH,
            height: STEP_WIDTH,
            borderRadius: SLIDER_WIDTH / 2,
            border: ({ colorDark }: { colorDark: string }) => `${STEP_HEIGHT / 6}px solid ${colorDark}`,
            willChange: 'border-color',
            transition: ({ colorTransitionTimeSecs }: { colorTransitionTimeSecs: number }) =>
                `border-color ${colorTransitionTimeSecs}s ease-in-out, opacity 0.15s ease`,
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            top: '50%',
            left: '50%',
            display: 'grid',
            placeContent: 'center',
            pointerEvents: 'none',
            opacity: 0,
        },
        '&:hover:before': {
            opacity: '1',
        },
    },
    defaultHandleMarker: {
        width: SLIDER_WIDTH,
        height: STEP_HEIGHT / 3,
        borderRadius: STEP_HEIGHT / 6,
        backgroundColor: ({ colorDark }: { colorDark: string }) => colorDark,
        willChange: 'background-color',
        transition: ({ colorTransitionTimeSecs }: { colorTransitionTimeSecs: number }) =>
            `background-color ${colorTransitionTimeSecs}s ease-in-out`,
    },
    labels: {
        position: 'absolute',
        top: 0,
        right: 0,
        transform: 'translateX(100%)',
        bottom: 0,
        width: 'auto',
        height: '100%',
        display: 'grid',
        gridAutoFlow: 'row',
        alignItems: 'start',
        gap: 36,
        padding: '6px 8px 6px 16px',
        pointerEvents: 'none',
        opacity: 0,
        transition: 'opacity 0.5s ease',
    },
    section: {
        display: 'grid',
        placeContent: 'center',
        borderLeftWidth: 1,
        borderLeftStyle: 'solid',
        borderLeftColor: ({ colorDark }: { colorDark: string }) => colorDark,
        willChange: 'border-left-color',
        transition: ({ colorTransitionTimeSecs }: { colorTransitionTimeSecs: number }) =>
            `border-left-color ${colorTransitionTimeSecs}s ease-in-out`,
        paddingLeft: 8,
    },
    release: {
        height: 26,
    },
    deepen: {
        height: 94,
    },
    soothe: {
        height: 69,
    },
    silence: {
        height: 26,
    },
    label: {
        color: ({ colorDark }: { colorDark: string }) => colorDark,
        willChange: 'color',
        transition: ({ colorTransitionTimeSecs }: { colorTransitionTimeSecs: number }) =>
            `color ${colorTransitionTimeSecs}s ease-in-out`,
    },
});

export default function IntensitySlider({
    min,
    max,
    divideAt,
    currentIntensity,
    currentIntensityTransitionTimeSecs,
    colorHexTransitionTimeSecs,
    targetIntensity: targetIntensityProp,
    colorHex,
    disabled = false,
    onChange,
    setValueRefs,
    onClickWhenDisabled,
}: IIntensitySliderProps): JSX.Element {
    const value = isUndefined(targetIntensityProp)
        ? targetIntensityProp
        : Math.min(Math.max(targetIntensityProp, min), max);
    const maxRef = useRef<HTMLDivElement>(null);
    const minRef = useRef<HTMLDivElement>(null);
    const [activeStep, setActiveStep] = useState<ISliderStep>();
    const [isDragging, setIsDragging] = useState(false);
    const [indicatorPosition, setIndicatorPosition] = useState<number | undefined>();

    const innerSteps = buildStepsForValueRange(min + 1, max);
    const minStep = { ref: minRef, value: min, size: 0 };
    const maxStep = { ref: maxRef, value: max, size: 0 };
    const steps = [minStep, ...innerSteps, maxStep];

    const [hue, saturation, lightness] = colorConvert.hex.hsl(colorHex);

    // Take initial CEA HSL colour values and create complementary tints and shades
    const colorLight = `hsl(${hue},${saturation}%,${lightness + 20}%)`;
    const colorMid = `hsl(${hue},${saturation}%,${lightness - 10}%)`;
    const colorDark = `hsl(${hue},${saturation}%,${lightness - 50}%)`;
    const colorHighlight = `hsl(${hue},${saturation + 60}%,${lightness - 30}%)`;
    const colorShadow = `hsla(${hue},${saturation}%,${lightness - 30}%,0.3)`;

    const styles = useStyles({
        disabled,
        colorLight,
        colorMid,
        colorDark,
        colorShadow,
        colorHighlight,
        colorTransitionTimeSecs: colorHexTransitionTimeSecs ?? 0,
    });

    useEffect(() => {
        setIndicatorPosition(stepToPosition(activeStep));
    }, [activeStep]);
    useResizeObserver(document.documentElement, () => {
        setIndicatorPosition(stepToPosition(activeStep));
    });

    useEffect(() => {
        setValueRefs && setValueRefs(steps);
    }, []);

    const closestStep = findClosestStepFromPosition(steps, indicatorPosition);

    const handleChange = (step: ISliderStep) => {
        if (disabled) return;
        setActiveStep(step);
        onChange(step.value);
    };

    useEffect(() => {
        setActiveStep(steps.find((step: ISliderStep) => step.value === value));
    }, [value]);

    useEffect(() => {
        const activeStepPosition = activeStep && stepToPosition(activeStep);
        !isDragging && setIndicatorPosition(activeStepPosition);
    }, [activeStep]);

    const [currentIntensitySpring, updateCurrentIntensitySpring] = useSpring(() => ({
        currentIntensity: currentIntensity ?? 0,
    }));
    useEffect(() => {
        updateCurrentIntensitySpring({
            currentIntensity: currentIntensity ?? 0,
            config: { duration: (currentIntensityTransitionTimeSecs ?? 0) * 1000 },
        });
    }, [currentIntensity, currentIntensityTransitionTimeSecs, updateCurrentIntensitySpring]);

    const indicatorIconTransitions = useTransition(closestStep?.ref === maxRef || closestStep?.ref === minRef, {
        from: { scale: 0 },
        enter: { scale: 1 },
        leave: { scale: 0 },
        delay: 200,
        config: config.gentle,
    });

    const bind = useGesture(
        {
            onDrag: ({ _movement: [, y], event }) => {
                if (!disabled) {
                    event?.preventDefault();
                    setIsDragging(true);
                    const activeStepPosition = activeStep && stepToPosition(activeStep);
                    if (activeStepPosition) {
                        const minPosition = stepToPosition(last(steps)) ?? 0;
                        const maxPosition = stepToPosition(first(steps)) ?? Number.MAX_SAFE_INTEGER;
                        const indicatorPosition = clamp(activeStepPosition + y, minPosition, maxPosition);
                        setIndicatorPosition(indicatorPosition);
                    }
                }
            },
            onDragEnd: () => {
                const newStep = findClosestStepFromPosition(steps, indicatorPosition);
                newStep && handleChange(newStep);
                setIsDragging(false);
            },
        },
        {
            eventOptions: { passive: false },
        },
    );

    const indicatorIcon = indicatorIconTransitions(({ scale }, displayReleaseOrMuteIcon) =>
        displayReleaseOrMuteIcon ? (
            <animated.div className={styles.iconHandle} style={{ scale }}>
                <div className={styles.iconHandleInner}>
                    <div className={styles.iconHandleMarker} />
                </div>
            </animated.div>
        ) : (
            <animated.div className={styles.defaultHandle} style={{ scale }}>
                <div className={styles.defaultHandleInner}>
                    <div className={styles.defaultHandleMarker} />
                </div>
            </animated.div>
        ),
    );
    return (
        <div
            aria-label="Musical Intensity Slider"
            role="slider"
            aria-valuemax={max}
            aria-valuemin={min}
            aria-valuenow={value}
            className={styles.container}
            onClick={disabled ? onClickWhenDisabled : undefined}
        >
            <div
                className={styles.endIconContainer}
                onClick={() => handleChange(maxStep)}
                ref={maxRef}
                aria-label={`Musical Intensity ${max} (Release)`}
            >
                <div className={styles.endIconBackground}>
                    <EvaIcon name="sun-outline" fill={colorDark} size="20px" />
                </div>
            </div>
            <div className={styles.spacer}>
                <svg className={styles.spacerImage} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <path
                        d="M32,36.03v-.05c-4.85-3.65-8-9.44-8-15.97s3.15-12.32,8-15.97v-.05c1.5-1.13,2.84-2.47,3.98-3.97H4.02c1.13,1.5,2.47,2.84,3.98,3.97v.05c4.85,3.65,8,9.44,8,15.97s-3.15,12.32-8,15.97v.05c-1.5,1.13-2.84,2.47-3.98,3.97h31.96c-1.13-1.5-2.47-2.84-3.98-3.97Z"
                        fill="#fff"
                    />
                </svg>
            </div>
            <div className={styles.innerTrack}>
                {innerSteps
                    .reverse()
                    .map((step: { size: number; value: number; ref: React.RefObject<HTMLDivElement> }) => (
                        <React.Fragment key={step.value}>
                            <SliderStepContainer
                                // stepValue={step.value}
                                elementRef={step.ref}
                                className={`${styles.sliderStepContainer} ${
                                    step.value == min + 1 || step.value == divideAt ? styles.segmentEnd : ''
                                } ${step.value == max - 1 || step.value == divideAt - 1 ? styles.segmentStart : ''}`}
                            >
                                <div
                                    className={styles.sliderStep}
                                    aria-label={`Musical Intensity ${step.value}`}
                                    onClick={() => handleChange(step)}
                                >
                                    <animated.div
                                        className={styles.sliderStepActive}
                                        style={{
                                            transform: currentIntensitySpring.currentIntensity.to(
                                                (i) => `scaleY(${lerp(i, step.value - 1, step.value, 0, 1)})`,
                                            ),
                                        }}
                                    />
                                </div>
                            </SliderStepContainer>
                            {step.value === divideAt && (
                                <div className={styles.spacer}>
                                    <svg
                                        className={styles.spacerImage}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 40 40"
                                    >
                                        <path
                                            d="M32,36.03v-.05c-4.85-3.65-8-9.44-8-15.97s3.15-12.32,8-15.97v-.05c1.5-1.13,2.84-2.47,3.98-3.97H4.02c1.13,1.5,2.47,2.84,3.98,3.97v.05c4.85,3.65,8,9.44,8,15.97s-3.15,12.32-8,15.97v.05c-1.5,1.13-2.84,2.47-3.98,3.97h31.96c-1.13-1.5-2.47-2.84-3.98-3.97Z"
                                            fill="#fff"
                                        />
                                    </svg>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
            </div>
            <div className={styles.spacer}>
                <svg className={styles.spacerImage} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                    <path
                        d="M32,36.03v-.05c-4.85-3.65-8-9.44-8-15.97s3.15-12.32,8-15.97v-.05c1.5-1.13,2.84-2.47,3.98-3.97H4.02c1.13,1.5,2.47,2.84,3.98,3.97v.05c4.85,3.65,8,9.44,8,15.97s-3.15,12.32-8,15.97v.05c-1.5,1.13-2.84,2.47-3.98,3.97h31.96c-1.13-1.5-2.47-2.84-3.98-3.97Z"
                        fill="#fff"
                    />
                </svg>
            </div>
            <div
                className={styles.endIconContainer}
                onClick={() => handleChange(minStep)}
                ref={minRef}
                aria-label={`Musical Intentsity ${min} (Silence)`}
            >
                <div className={styles.endIconBackground}>
                    <EvaIcon name="volume-off-outline" fill={colorDark} size="16px" />
                </div>
            </div>
            {isNumber(indicatorPosition) && isNumber(value) && (
                <ValueIndicator
                    width={SLIDER_WIDTH}
                    height={STEP_HEIGHT}
                    isDragging={isDragging}
                    bind={bind}
                    top={indicatorPosition}
                    icon={indicatorIcon}
                />
            )}
            <div className={styles.labels}>
                <div className={`${styles.section} ${styles.release}`}>
                    <Typography variant="body3" className={styles.label}>
                        Release
                    </Typography>
                </div>
                <div className={`${styles.section} ${styles.deepen}`}>
                    <Typography variant="body3" className={styles.label}>
                        Deepen
                    </Typography>
                </div>
                <div className={`${styles.section} ${styles.soothe}`}>
                    <Typography variant="body3" className={styles.label}>
                        Soothe
                    </Typography>
                </div>
                <div className={`${styles.section} ${styles.silence}`}>
                    <Typography variant="body3" className={styles.label}>
                        Silence
                    </Typography>
                </div>
            </div>
        </div>
    );
}

const buildStepsForValueRange = (min: number, max: number) => {
    const totalInnerSteps = max - 1 - min;
    const stepDotSizeDiff = (MAX_STEP_DIAMETER - MIN_STEP_DIAMETER) / totalInnerSteps;
    return range(min, max).map((value: number, idx: number) => ({
        size: MIN_STEP_DIAMETER + idx * stepDotSizeDiff,
        value,
        ref: useRef<HTMLDivElement>(null),
    }));
};

const lerp = (value: number, fromMin: number, fromMax: number, toMin: number, toMax: number) => {
    const fromRange = fromMax - fromMin;
    const toRange = toMax - toMin;
    const fromValue = value - fromMin;
    const toValue = (fromValue * toRange) / fromRange + toMin;
    return toValue;
};
