import styled from '@emotion/styled';
import { Dialog, DialogContent } from '@material-ui/core';
import { isEqual } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { SessionScore, SessionVariables, Wavepath } from 'wavepaths-shared/core';

import { Button, EvaIcon } from '@/component-library';
import { Typography } from '@/component-library';
import { Features, useFeatures } from '@/features';

import WaveQueueEditor from '../../common/components/WaveQueueEditor';
import UserEvents from '../../UserEvents';
import { isAnyWaveEdited, isDiscardingEdits, selectNoWave, WaveSelection } from '../inSession/autoGuide/waveSelection';
import { Timeline } from '../inSession/timeline/Timeline';
import { SAVE_BEFORE_COLLAPSE_LABEL } from './OneOnOneSessionPlanner';
import { SessionPlannerTracking } from './sessionPlannerTracking';
import { SessionTemplateCard } from './SessionTemplateCard';

export type SessionTemplateProps = {
    score: SessionScore;
    variables: SessionVariables;
    snackbarContent: string | null;
    updatePathInScore: (index: number, wp: Pick<Wavepath, 'pathId' | 'pathScore' | 'duration'>) => void;
    addPathToScore: (index: number, wp: Pick<Wavepath, 'pathId' | 'pathScore'>) => void;
    removePathFromScore: (waveIndex: number) => void;
    movePathInScore: (index: number, targetIndex: number) => void;
    setSnackbarContent: (content: string | null) => void;
    sessionDuration: number;
    openSubscribeModal: () => void;
};

const Fixed = styled.div({
    padding: '36px 48px 0 48px',
    background: 'rgb(241, 243, 248)',
});

const StyledDialogContent = styled(DialogContent)({
    zIndex: 1,
    padding: '24px 24px 48px 38px', //padding left to offset scrollbar
    background: 'rgb(241, 243, 248)',
});

const Header = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
    justifyItems: 'space-between',
    paddingBottom: 16,
});

const Heading = styled(Typography)({
    color: 'rgb(44, 57, 88)',
    margin: '6px 0 0 0',
});

const CollapseButton = styled(Button)({
    justifySelf: 'end',
});

const Duration = styled(Typography)({
    margin: '16px 0',
    textAlign: 'center',
    color: 'rgb(44, 57, 88)',
});

const Divider = styled.div({
    height: 1,
    width: '100%',
    background: '#ADB9D6',
});

export const SessionTemplate: FC<SessionTemplateProps> = ({
    score,
    variables,
    snackbarContent,
    updatePathInScore,
    addPathToScore,
    removePathFromScore,
    movePathInScore,
    setSnackbarContent,
    sessionDuration,
    openSubscribeModal,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [waveSelection, setWaveSelection] = useState<WaveSelection>(selectNoWave());

    const { isEnabled } = useFeatures();

    const handleClose = () => {
        if (isAnyWaveEdited(waveSelection)) {
            setSnackbarContent(SAVE_BEFORE_COLLAPSE_LABEL);
        } else {
            UserEvents.closeSessionEditorModal();
            setIsOpen(false);
        }
    };

    const handleOpen = (waveSelection?: WaveSelection) => {
        if (!isEnabled(Features.WAVE_EDITOR)) {
            openSubscribeModal();
            return;
        }

        UserEvents.openSessionEditorModal();
        setIsOpen(true);
        if (waveSelection) {
            setWaveSelection(waveSelection);
        }
    };

    useEffect(() => {
        if (isEqual(waveSelection, selectNoWave()) && snackbarContent === SAVE_BEFORE_COLLAPSE_LABEL) {
            setSnackbarContent(null);
        }
    }, [snackbarContent, waveSelection, setSnackbarContent]);

    const setWaveSelectionOrNotify = (newSelection: WaveSelection, force = false) => {
        if (!force && isDiscardingEdits(newSelection, waveSelection)) {
            setSnackbarContent(SAVE_BEFORE_COLLAPSE_LABEL);
        } else {
            setWaveSelection(newSelection);
        }
    };

    return (
        <>
            <SessionTemplateCard onOpen={handleOpen} score={score} variables={variables} />
            <Dialog open={isOpen} maxWidth="md">
                <Fixed>
                    <Header>
                        <Heading variant="h5" data-testid="wave-queue-editor-dialog-title">
                            Customise Music
                        </Heading>
                        <CollapseButton
                            icon={<EvaIcon fill="white" name="collapse" />}
                            variant="solid-blue"
                            size="m"
                            onClick={handleClose}
                        >
                            Collapse
                        </CollapseButton>
                    </Header>
                    <Timeline
                        score={score}
                        setWaveSelection={setWaveSelection}
                        waveSelection={waveSelection}
                        variables={variables}
                        isScrollable
                        phasesAlwaysVisible
                        isPlanner
                    />
                    <Duration variant="subtitle2">Session duration: {Math.round(sessionDuration)} min</Duration>
                    <Divider />
                </Fixed>
                <StyledDialogContent>
                    <WaveQueueEditor
                        shouldIncludePrelude
                        addPathAtIndex={addPathToScore}
                        waveSelection={waveSelection}
                        setWaveSelection={setWaveSelectionOrNotify}
                        currentWaveIndex={0}
                        movePathToIndex={movePathInScore}
                        removePathAtIndex={removePathFromScore}
                        setSnackbarContent={setSnackbarContent}
                        shouldShow
                        updatePathAtIndex={updatePathInScore}
                        wavepaths={score.wavepaths}
                        trackingHandlers={SessionPlannerTracking}
                    />
                </StyledDialogContent>
            </Dialog>
        </>
    );
};
