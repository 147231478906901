import styled from '@emotion/styled';
import { SnackbarContent } from '@material-ui/core';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import React from 'react';

import Button from './Button';
export interface IWarningProps {
    text?: string;
    icon?: JSX.Element;
    open: boolean;
    anchorOrigin?: SnackbarOrigin;
    onDismiss?: () => void;
}

const StyledSnackbarContent = styled(SnackbarContent)({
    backgroundColor: `#FD3A57`,
    width: '64px',
});

const Warning = ({ text = '', icon, open, anchorOrigin, onDismiss }: IWarningProps): JSX.Element => {
    if (!text) return <></>;

    const messageDiv = (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {icon && (
                <>
                    {icon}
                    <div style={{ width: '16px' }} />
                </>
            )}

            <div>{text}</div>
        </div>
    );

    const action = onDismiss ? (
        <Button size="s" onClick={onDismiss} style={{ color: 'white' }}>
            Dismiss
        </Button>
    ) : undefined;

    return (
        <Snackbar itemType="error" anchorOrigin={anchorOrigin} open={open}>
            <StyledSnackbarContent message={messageDiv} action={action} />
        </Snackbar>
    );
};

export default Warning;
