import WaveQueueEditorTrackingHandlers from '../../../common/components/WaveQueueEditor/WaveQueueEditorTrackingHandlers';
import UserEvents from '../../../UserEvents';

export const AutoGuideTracking: WaveQueueEditorTrackingHandlers = {
    onAddWave: UserEvents.addWave,
    skipToWave: UserEvents.skipToWave,
    removeWave: UserEvents.removeWave,
    moveWaveDown: UserEvents.moveWaveDown,
    moveWaveUp: UserEvents.moveWaveUp,
    updateWave: UserEvents.updateWave,
    waveCannotBeMovedFurtherUp: UserEvents.waveCannotBeMovedFurtherUp,
    waveCannotBeMovedFurtherDown: UserEvents.waveCannotBeMovedFurtherDown,
};
