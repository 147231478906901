import styled from '@emotion/styled';
import React from 'react';
import { Step } from 'react-joyride';

import { Typography } from '@/component-library';
import { ProductTour } from '@/components/ProductTour';
import { Feature, Features, useFeatures } from '@/features';

import ProfileImage from '../../images/mendel-profile.png';

const ProfileImg = styled.img({
    height: 100,
    width: 100,
    borderRadius: 100,
});

const ProfileContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '1em',
});

const List = styled.ul({
    marginTop: '0.5em',
});

const ListItem = styled.li({
    marginTop: '0.5em',
});

const IntroVideo = () => {
    return (
        <div style={{ position: 'relative', paddingBottom: '60.810810810810814%', marginBottom: 12, height: 0 }}>
            <iframe
                src="https://www.loom.com/embed/b9bfaf15519240d6a821f39e0f760b9b?sid=bc177c4e-69d1-4c8d-b578-ad4e83fa54ee"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            ></iframe>
        </div>
    );
};

export const getSteps = (isEnabled: (ft: Features) => boolean): Step[] => {
    return [
        {
            content: (
                <>
                    <ProfileContainer>
                        <ProfileImg src={ProfileImage} />
                    </ProfileContainer>
                    <Typography variant="body2">Hi,</Typography>
                    <Typography variant="body2">
                        I'm Mendel, CEO and founder of Wavepaths, wishing you a very warm welcome to our music platform
                        for Care-Providers.
                    </Typography>
                    <Typography variant="body2">
                        We’ve made it easy for you to start providing person-centred music for your clients now with the
                        help of a few simple resources.
                    </Typography>
                    <Typography variant="body2">
                        Firstly, we recommend you get acquainted with some of our key concepts:
                    </Typography>
                    <IntroVideo />
                    <Typography variant="body2">
                        Please continue on for a few useful tips about set-up before you get started.
                    </Typography>
                    <Typography variant="body2">
                        Warmly, <br />
                        Mendel Kaelen <br />
                        Founder & CEO of Wavepaths
                    </Typography>
                </>
            ),
            placement: 'center',
            target: 'body',
        },
        {
            title: 'Your setup',
            content: (
                <>
                    <Typography variant="body2">
                        First things first, here’s how to ensure a high quality audio experience:
                    </Typography>
                    <Typography variant="body2">
                        <List>
                            <ListItem>Avoid using bluetooth or wireless devices for sound 🔊</ListItem>
                            <ListItem>
                                Ensure your internet connection is stable, reliable & reasonably fast 📶
                            </ListItem>
                            <ListItem>
                                Use either a desktop / laptop with Chrome or Safari, or an iPad with Safari, to conduct
                                any sessions 🖥
                            </ListItem>
                        </List>
                    </Typography>
                    <Typography variant="body2">
                        If you’re ready to start making music, continue on for our interactive product tour, during
                        which you’ll design and run your first Wavepaths session.
                    </Typography>
                </>
            ),
            placement: 'center',
            target: 'body',
        },
        {
            target: '.tour-profileInfo',
            content: (
                <>
                    <Typography variant="body2">
                        You can view your profile here, for changing your password or to manage your subscription.
                    </Typography>
                </>
            ),
        },
        {
            target: '.tour-launchButtons',
            content: (
                <>
                    <Typography variant="body2">
                        {isEnabled(Features.LIVE_SESSION_RENDERING) && (
                            <>
                                <b>Jump Right In</b> will take you straight to a pre-planned Wavepaths session, while{' '}
                            </>
                        )}
                        <b>Plan New Session</b> allows you to customise the music to you or your clients' needs.
                    </Typography>
                </>
            ),
        },
        {
            target: '.tour-sessionTables',
            content: (
                <>
                    <Typography variant="body2">
                        All of your created sessions will appear in one of these tabs, depending on whether the music is
                        yet to start, currently live, or has finished.
                    </Typography>
                    <Feature
                        name={Features.LIVE_SESSION_RENDERING}
                        inactiveComponent={
                            <Typography variant="body2">This feature is only available on higher tiers.</Typography>
                        }
                    />
                </>
            ),
        },
        {
            target: '.tour-productTours',
            content: (
                <>
                    <Typography variant="body2">
                        Interactive product guides appear here. Please start with <b>Planning a Session</b> where we
                        will help you to  create your first Wavepaths composition.
                    </Typography>
                </>
            ),
        },
    ];
};

export function DashboardProductTour(): JSX.Element {
    const { isEnabled } = useFeatures();
    const steps = getSteps(isEnabled);

    return <ProductTour steps={steps} />;
}
