import React from 'react';
import { MidSessionFeedbackType } from 'wavepaths-shared/core';

import { Features } from '../../features/features.local';
import { useFeatures } from '../../features/useFeatures';
import { FeedbackButton } from './FeedbackButton';

export interface FeedbackButtonsProps {
    activeFeedbackType?: MidSessionFeedbackType;
    onLogFeedback: (type: MidSessionFeedbackType) => void;
}
export const FeedbackButtons: React.FC<FeedbackButtonsProps> = ({ activeFeedbackType, onLogFeedback }) => {
    const { isEnabled } = useFeatures();
    return (
        <>
            <FeedbackButton
                ariaLabel="Helpful Music"
                icon="helpfulMusic"
                onClick={() => onLogFeedback('helpfulMusic')}
                isActive={activeFeedbackType === 'helpfulMusic'}
                isDisabled={!!activeFeedbackType}
            />
            <FeedbackButton
                ariaLabel="Unhelpful Music"
                icon="unhelpfulMusic"
                onClick={() => onLogFeedback('unhelpfulMusic')}
                isActive={activeFeedbackType === 'unhelpfulMusic'}
                isDisabled={!!activeFeedbackType}
            />
            {isEnabled(Features.THERAPIST_NOTES) && (
                <FeedbackButton
                    ariaLabel="Leave Note"
                    icon="leaveNote"
                    onClick={() => onLogFeedback('note')}
                    isActive={activeFeedbackType === 'note'}
                    isDisabled={!!activeFeedbackType}
                />
            )}
            <FeedbackButton
                classNames="tour-feedback"
                ariaLabel="Report Issue"
                icon="reportIssue"
                onClick={() => onLogFeedback('issue')}
                isActive={activeFeedbackType === 'issue'}
                isDisabled={!!activeFeedbackType}
            />
        </>
    );
};
