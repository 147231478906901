import { Checkbox, Divider, FormControl, FormControlLabel, FormGroup, makeStyles } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import React from 'react';

import { Typography } from '@/component-library';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: `${theme.spacing(3)}px ${theme.spacing(0)}px ${theme.spacing(1)}px`,
        width: '100%',
    },
}));

const MarginlessLabel = styled(FormControlLabel)({
    marginBottom: 0,
});

export interface Selectable<A> {
    id: A;
    name: string;
}

const Checklist = <A extends string | number>({
    elements,
    handleSelectionChange,
    isSelected,
    isDisabled,
}: {
    elements: Selectable<A>[];
    handleSelectionChange: (id: A) => void;
    isSelected: (e: Selectable<A>) => boolean;
    isDisabled: (e: Selectable<A>) => boolean;
}): React.ReactElement => {
    const classes = useStyles();

    return (
        <div>
            <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                    {elements.map((e) => (
                        <div key={e.id}>
                            <MarginlessLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={isSelected(e)}
                                        disabled={isDisabled(e)}
                                        onChange={() => handleSelectionChange(e.id)}
                                    />
                                }
                                label={<Typography variant="body2">{e.name}</Typography>}
                            />
                            <Divider />
                        </div>
                    ))}
                </FormGroup>
            </FormControl>
        </div>
    );
};

export default Checklist;
