import React from 'react';
import styled from 'styled-components';
import {
    isPostludePathScore,
    isPostludeWavepath,
    isPreludePathScore,
    isPreludeWavepath,
    isScheduledPathScore,
    isScheduledWavepath,
    PrePostLudePathScore,
    ScheduledPathScore,
    ScheduledWavepath,
    Wavepath,
} from 'wavepaths-shared/core';

import { AnimatedLoaderIcon, DropdownControl } from '@/component-library';
import { ControlOption } from '@/component-library/components/Control/types';
import useScoreLibrary from '@/hooks/useScoreLibrary';

import { useAuthContext } from '../../../auth';
import { isWaveEdited, waveEdited, WaveSelection } from '../../../pages/inSession/autoGuide/waveSelection';
import { ScheduledWavePathSelector } from './ScheduledWavePathSelector';

/**
 * todo
 * [ ] info boxes
 * [ ] tests
 * [ ] performance jankiness
 * [x] if multiple path scores, pick randomly from list
 * [x] test in-session works
 * [x] if multiple path scores, pick randomly from list
 * [x] deepen duration folding
 * [x] add CEA to percussive contenFt
 * [x] pre + postlude
 * [x] display current selected wave
 * [x] deepen
 * [x] bridge
 * [x] wavepath edit clear: remove the cancel and save changes buttons when the user changes their mind further up the field tree
 * [✔️] add disable option to select and segmented controller
 * [✔️] - remove session scores and presetscores
 * [✔️] - determine appropriate disabling logic (in terms of ux)
 * [✔️] - create logic to wipe subsequent answers when an answer higher in the tree is selected
 * [✔️] - create logic to disable row if previous row has not been answered
 * [✔️] - duration drop down
 */

export interface SegmentedControlOption<ValueType> {
    label: string;
    value: ValueType;
    disabled: boolean;
}

export interface IWavePathSelectorProps {
    wavepath: Wavepath;
    waveSelection: WaveSelection;
    onSetWaveSelection: (selection: WaveSelection, force?: boolean) => void;
}

export interface IScheduledWavepathSelectorProps {
    pathScores: ScheduledPathScore[];
    wavepath: ScheduledWavepath;
    waveSelection: WaveSelection;
    onSetWaveSelection: (selection: WaveSelection, force?: boolean) => void;
}

const Container = styled.div({
    display: 'grid',
    rowGap: '16px',
    padding: '24px 0',
});

export const LOADING_ICON_LABEL = 'Loading waves';
function WavePathSelector(props: IWavePathSelectorProps): JSX.Element {
    const { wavepath, waveSelection } = props;
    const currentWavepath = isWaveEdited(waveSelection, wavepath.id) ? waveSelection.editedWave : wavepath;

    const { firebaseUser } = useAuthContext();
    const scores = useScoreLibrary(firebaseUser);

    if (scores.loading === true) return <AnimatedLoaderIcon aria-label={LOADING_ICON_LABEL} />;

    const { pathScores: allPathScores } = scores;

    const scheduledPathScores = allPathScores.filter(isScheduledPathScore);
    const preludePathScores = allPathScores.filter(isPreludePathScore);
    const postludePathScores = allPathScores.filter(isPostludePathScore);

    if (isScheduledWavepath(currentWavepath)) {
        return (
            <Container>
                <ScheduledWavePathSelector {...props} wavepath={currentWavepath} pathScores={scheduledPathScores} />
            </Container>
        );
    } else if (isPreludeWavepath(currentWavepath)) {
        return (
            <Container>
                <PrePostludePathSelector {...props} wavepath={currentWavepath} pathScores={preludePathScores} />
            </Container>
        );
    } else if (isPostludeWavepath(currentWavepath)) {
        return (
            <Container>
                <PrePostludePathSelector {...props} wavepath={currentWavepath} pathScores={postludePathScores} />
            </Container>
        );
    }

    return <></>;
}
export default WavePathSelector;

export interface IPrePostludeWavepathSelectorProps {
    pathScores: PrePostLudePathScore[];
    wavepath: Wavepath;
    waveSelection: WaveSelection;
    onSetWaveSelection: (selection: WaveSelection, force?: boolean) => void;
}

function PrePostludePathSelector(props: IPrePostludeWavepathSelectorProps): JSX.Element {
    const { wavepath, onSetWaveSelection, pathScores } = props;

    const waveOptions: ControlOption<string>[] = pathScores.map((p) => ({
        label: p.name,
        value: p.id,
        disabled: false,
    }));

    const handleChange = (pathId: string) => {
        const pathScore = pathScores.find((p) => p.id === pathId);
        pathScore && onSetWaveSelection(waveEdited(wavepath, { ...wavepath, pathId, pathScore }));
    };

    return (
        <Container>
            <DropdownControl
                name="wave-function"
                heading={'Wave'}
                canSave={false}
                size="small"
                colour="dark"
                options={waveOptions}
                value={wavepath.pathId}
                onChange={handleChange}
            />
        </Container>
    );
}
