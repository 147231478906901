import './Sessions.scss';

import styled from '@emotion/styled';
import Box from '@material-ui/core/Box';
import firebase from 'firebase';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { APISessionStatus, SessionType, UserData } from 'wavepaths-shared/core';
import { isAdmin as checkAdmin } from 'wavepaths-shared/domain/user';

import { Button, EvaIcon, Typography } from '@/component-library';
import { ErrorBox, SearchBar, SubscribeModal, TabLabelWithCount, Tabs } from '@/components';
import HelpButton from '@/components/HelpButton';
import Snackbar from '@/components/Snackbar';
import { Feature, Features, useFeatures } from '@/features';
import { useDebounce, useSnackbar } from '@/hooks';

import * as audio from '../../audio';
import { AuthContext } from '../../auth';
import { UserInfo } from '../../UserInfoV2';
import { DashboardProductTour } from './DashboardProductTour';
import EndedSessionsTable from './EndedSessionsTable';
import ProductTourCarousel from './IntroSessions/ProductTourCarousel';
// import IntroSessionsCarousel from './IntroSessions/IntroSessionsCarousel';
import JumpRightIn from './JumpRightIn/JumpRightIn';
import OngoingSessionsTable from './OngoingSessionsTable';
import ScheduledSessionsTable from './ScheduledSessionsTable';
import { StartSessionFromTemplateButton } from './StartSessionFromTemplateButton';
import useSessions from './useSessions';

enum TabIndex {
    SCHEDULED = 0,
    ACTIVE = 1,
    COMPLETED = 2,
}

const TabContent = styled.div({
    paddingTop: '24px',
});

const PageTitle = styled(Typography)({
    color: '#2c3958',
});

const LockedAdminPageContainer = styled(Box)((props: { isLocked: boolean }) => ({
    flexDirection: 'column',
    display: 'flex',
    opacity: props.isLocked ? 0.5 : 1,
    cursor: props.isLocked ? 'pointer' : 'initial',
}));

const NoResults: React.FC = () => (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Typography variant="body2" style={{ color: '#2c3958' }}>
            No sessions found
        </Typography>
    </div>
);

const AdminPage: React.FC = () => {
    const history = useHistory();
    const { isLoading: featuresLoading, isEnabled } = useFeatures();

    const authCtx = useContext(AuthContext);
    const fbUser = authCtx.firebaseUser as firebase.User; // page will not have loaded if firebase user is undefined
    const isAdmin = checkAdmin(authCtx.userData as UserData);

    const sessionSignups = {};
    const [searchTerm, setSearchTerm] = useState('');
    const [activeTab, setActiveTab] = useState(TabIndex.ACTIVE);
    const [userTouchedTabs, setUserTouchedTabs] = useState(false);

    const { setSnackbarContent, closeSnackbar, content: snackbarContent } = useSnackbar();

    const [modalIsOpen, setModalIsOpen] = useState<boolean | undefined>(undefined);

    const scheduled = useSessions({
        fbUser,
        name: useDebounce(searchTerm),
        sessionState: APISessionStatus.SCHEDULED,
        type: isAdmin ? undefined : SessionType.ONE_ON_ONE,
        paginated: false,
    });
    const ongoing = useSessions({
        fbUser,
        name: useDebounce(searchTerm),
        sessionState: APISessionStatus.ACTIVE,
        type: isAdmin ? undefined : SessionType.ONE_ON_ONE,
        paginated: false,
    });
    const ended = useSessions({
        fbUser,
        name: useDebounce(searchTerm),
        sessionState: APISessionStatus.ENDED,
        type: isAdmin ? undefined : SessionType.ONE_ON_ONE,
        paginated: true,
    });

    const endedIntroSessions = useSessions({
        fbUser,
        sessionState: APISessionStatus.ENDED,
        paginated: true,
    });

    const totalSessionCount =
        scheduled.totalCount + ongoing.totalCount + ended.totalCount + endedIntroSessions.totalCount;

    const shouldDisplayTour =
        !scheduled.loading &&
        !ongoing.loading &&
        !ended.loading &&
        !endedIntroSessions.loading &&
        totalSessionCount === 0;

    if (!fbUser) {
        throw new Error('no logged in user on sessions screen');
    }

    useEffect(() => {
        if (!userTouchedTabs && !ongoing.loading) {
            if (ongoing.sessions.length > 0) {
                setActiveTab(TabIndex.ACTIVE);
            } else if (!scheduled.loading) {
                if (scheduled.sessions.length > 0) {
                    setActiveTab(TabIndex.SCHEDULED);
                } else if (!ended.loading && ended.totalCount > 0) {
                    setActiveTab(TabIndex.COMPLETED);
                }
            }
        }
    }, [scheduled.loading, ongoing.loading, ended, ongoing.sessions, scheduled.sessions, userTouchedTabs]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [sessionStartFailed, setSessionStartFailed] = useState<boolean>(false);

    const enableAudio = useCallback(() => {
        audio.audioCtx.resume();
    }, []);

    const onTabChange = (newTab: number) => {
        if (!userTouchedTabs) setUserTouchedTabs(true);
        setActiveTab(newTab);
    };

    const handleDisabledContentClick = (e: any) => {
        if (!isEnabled(Features.SESSION_DASHBOARD)) {
            e.stopPropagation();
            setModalIsOpen(true);
            return;
        }
    };

    return (
        <Box bgcolor="#f1f3f8" minHeight="100vh">
            <div className="sessionsv2">
                {sessionStartFailed && (
                    <div className="errorbox">
                        <ErrorBox
                            message={'Unable to start the session. Please check your network connection and try again'}
                        />
                    </div>
                )}
                {shouldDisplayTour && <DashboardProductTour />}

                <UserInfo />
                <Box
                    display="flex"
                    width="100%"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent={'space-between'}
                    padding="48px 0"
                >
                    <PageTitle variant="h2">Your sessions</PageTitle>
                    <div className="sessionsv2--primaryActions tour-launchButtons">
                        <Feature
                            name={Features.LIVE_SESSION_RENDERING}
                            activeComponent={
                                <JumpRightIn
                                    fbUser={fbUser}
                                    disabled={featuresLoading}
                                    setSnackbarContent={setSnackbarContent}
                                />
                            }
                        />
                        <Button
                            variant="solid-blue"
                            size="m"
                            icon={<EvaIcon name="plus" size={16} fill={'#FFF'} />}
                            onClick={() => {
                                history.push('/session/new/oneOnOne');
                            }}
                        >
                            <Typography variant="button" style={{ color: 'white' }}>
                                Plan new session
                            </Typography>
                        </Button>
                        <Feature
                            name={Features.SAVE_SESSION_TEMPLATES}
                            activeComponent={<StartSessionFromTemplateButton fbUser={fbUser} />}
                        />
                        {isAdmin && (
                            <>
                                <Button
                                    variant="solid-blue"
                                    size="m"
                                    icon={<EvaIcon name="plus" size={16} fill={'#FFF'} />}
                                    onClick={() => {
                                        history.push('/session/new/groupInfinite');
                                    }}
                                >
                                    <Typography variant="button" style={{ color: 'white' }}>
                                        New infinite Jung session
                                    </Typography>
                                </Button>
                            </>
                        )}
                    </div>
                </Box>
                <LockedAdminPageContainer
                    isLocked={!isEnabled(Features.SESSION_DASHBOARD)}
                    onClickCapture={handleDisabledContentClick}
                >
                    <SearchBar
                        value={searchTerm}
                        label="Find sessions"
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                        onClear={() => {
                            setSearchTerm('');
                        }}
                    />
                    <Tabs
                        className="tour-sessionTables"
                        activeTab={activeTab}
                        onChange={onTabChange}
                        content={[
                            {
                                identifier: 'scheduled',
                                label: (
                                    <TabLabelWithCount
                                        name="Scheduled"
                                        count={scheduled.sessions?.length ?? 0}
                                        loading={scheduled.loading}
                                    />
                                ),
                                body: (
                                    <TabContent>
                                        {!scheduled.loading && scheduled.sessions?.length === 0 ? (
                                            <NoResults />
                                        ) : (
                                            <ScheduledSessionsTable
                                                sessions={scheduled.sessions}
                                                loading={scheduled.loading}
                                                isAdmin={isAdmin}
                                                onLinkClick={enableAudio}
                                                //@ts-ignore
                                                firebaseUser={fbUser}
                                            />
                                        )}
                                    </TabContent>
                                ),
                            },
                            {
                                identifier: 'active',
                                label: (
                                    <TabLabelWithCount
                                        name="Active"
                                        count={ongoing.sessions?.length ?? 0}
                                        loading={ongoing.loading}
                                    />
                                ),
                                body: (
                                    <TabContent>
                                        {!ongoing.loading && ongoing.sessions?.length === 0 ? (
                                            <NoResults />
                                        ) : (
                                            <OngoingSessionsTable
                                                sessions={ongoing.sessions}
                                                loading={ongoing.loading}
                                                sessionsError={ongoing.error}
                                                fbUser={fbUser}
                                                isAdmin={isAdmin}
                                                onLinkClick={enableAudio}
                                            />
                                        )}
                                    </TabContent>
                                ),
                            },
                            {
                                identifier: 'completed',
                                label: (
                                    <TabLabelWithCount
                                        name="Completed"
                                        count={ended.totalCount ?? 0}
                                        loading={ended.loading}
                                    />
                                ),
                                body: (
                                    <TabContent>
                                        {!ended.loading && ended.sessions?.length === 0 ? (
                                            <NoResults />
                                        ) : (
                                            <EndedSessionsTable
                                                endedSessionsInterface={ended}
                                                signups={sessionSignups}
                                                isAdmin={isAdmin}
                                                fbUser={fbUser}
                                            />
                                        )}
                                    </TabContent>
                                ),
                            },
                        ]}
                    />
                </LockedAdminPageContainer>
                {/* TODO: Refactor and Replace Intro Session with Product Tour carousel */}
                <ProductTourCarousel fbUser={fbUser} setFailed={setSessionStartFailed} />
                <SubscribeModal isOpen={!!modalIsOpen} closeModal={() => setModalIsOpen(false)} />
                <Snackbar
                    type="error"
                    isLongButton={false}
                    message={snackbarContent}
                    confirmText={'OK'}
                    open={snackbarContent !== null}
                    closeSnackbar={closeSnackbar}
                />
            </div>

            <HelpButton />
        </Box>
    );
};

export default AdminPage;
