import '../../Guide.scss';

import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { Typography } from '@/component-library';

import { AuthContext } from '../../auth';
import LoadingOrb from '../../common/components/LoadingOrb';
import { FreudConnection } from '../../freudConnection/FreudConnection';
import useSession from './autoGuide/useSession';
import { Guide, GuideParams } from './Guide';
import useFreudConnection from './useFreudConnection';

export const DISABLED_LABEL = 'This is disabled until you start your session';

export const GuideContainer: React.FC = () => {
    const { sessionId } = useParams<GuideParams>();

    const { firebaseUser } = useContext(AuthContext);

    const [connection, setConnection] = useState<FreudConnection | null>(null);

    const { session } = useSession(sessionId, connection);
    const freudConnection = useFreudConnection({ session, firebaseUser });

    // hacky work around for circular dependency between useSession and useFreudConnection
    useEffect(() => {
        setConnection(freudConnection);
    }, [freudConnection]);
    return connection ? (
        <Guide connection={connection} />
    ) : (
        // TODO: why does it have a different background color until it switches over to the guidewithconnection loader and
        <LoadingOrb>
            <Typography variant={'body3'}>
                Your session is being prepared. Please wait on this page; it will load as soon as it is ready.
            </Typography>
            <br />
            <Typography variant={'body3'}>
                If you are concerned about the loading time, please email us at{' '}
                <a href="mailto:support@wavepaths.com">support@wavepaths.com</a>
            </Typography>
        </LoadingOrb>
    );
};
