import styled from 'styled-components';

import { Typography } from '@/component-library';

export const ProfileSection = styled.div({
    marginTop: '16px',
    maxWidth: '600px',
});

export const Text = styled(Typography)({
    color: '#2c3958',
});

export const InputGroup = styled.div({
    maxWidth: '350px',
    display: 'grid',
    gridAutoFlow: 'row',
    marginBottom: '16px',
});
