import * as Sentry from '@sentry/browser';
import React, { useCallback } from 'react';

import { Dialog } from '@/component-library';

import { AudioState, FreudConnection } from '../../freudConnection/FreudConnection';

interface EnableAudioDialogProps {
    audioState: AudioState;
    connection: FreudConnection;
}

export const EnableAudioDialog: React.FC<EnableAudioDialogProps> = ({ audioState, connection }) => {
    const connectAudio = useCallback(() => {
        Sentry.withScope((scope) => {
            scope.setExtra('broadcastIdentifier', connection.broadcastIdentifier);
            Sentry.captureMessage('Audio enabled by user');
        });
        connection.initializeAudioStream();
    }, [connection]);

    return (
        <Dialog
            open={audioState === 'blocked'}
            onConfirm={connectAudio}
            message="In order to play sound through this tab, you first need to enable audio"
            confirmText="Enable Audio"
            title="Enable Audio"
            disableBackdropClick={true}
        />
    );
};
