import React from 'react';

import { Typography } from '@/component-library';

import InfoButtonAndDialog from './InfoButtonAndDialog';

const AdministrationInfoDialog: React.FC = () => (
    <InfoButtonAndDialog title="Administration">
        <Typography variant="body2" color="textSecondary">
            Here you select the way the medicine is being administered.
        </Typography>
        <Typography variant="body2" color="textSecondary">
            This selection will inform the timeline of the session and the adaptation of the music to the different
            phases within the timeline.
        </Typography>
    </InfoButtonAndDialog>
);

export default AdministrationInfoDialog;
