import styled from '@emotion/styled';
import React, { useState } from 'react';

import { InfoDialog, Typography } from '@/component-library';
import { Feature, Features } from '@/features';

import CeaSelector, { ICeaSelectorProps } from '../ceaButtons/CeaSelector';

const CEAWrapper = styled.div({
    opacity: 0.5,
    marginBottom: 16,
});

function UnavailableDialog({ isOpen, closeModal }: { isOpen: boolean; closeModal: () => void }) {
    return (
        <InfoDialog title={'Adaptation feature'} open={isOpen} onClose={closeModal}>
            <Typography variant="body2">
                Adapting the emotionality in real-time during a session is only available for music generated live. To
                enabled this feature please select <b>Adaptable</b> instead of <b>Fixed</b> in the session-planner.
            </Typography>
            <Feature
                name={Features.LIVE_SESSION_RENDERING}
                inactiveComponent={
                    <Typography variant="body2">
                        You'll need to upgrade your subscription to access this feature. You can manage your
                        subscription from your <a href="/profile">profile</a>.
                    </Typography>
                }
            />
        </InfoDialog>
    );
}

export function CeaContainer({ targetCea }: Pick<ICeaSelectorProps, 'targetCea'>) {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    return (
        <CEAWrapper>
            <UnavailableDialog isOpen={isDialogOpen} closeModal={() => setIsDialogOpen(false)} />
            <CeaSelector targetCea={targetCea} transitionTimeSecs={30} onChange={() => setIsDialogOpen(true)} />
        </CEAWrapper>
    );
}
