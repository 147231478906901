import './EndOfSessionFeedbackModal.scss';

import React from 'react';
import { EndOfSessionFeedback } from 'wavepaths-shared/core';

import { Button, Typography } from '@/component-library';
import { Feature, Features } from '@/features';

import {
    EndOfSessionFeedbackInput,
    isValidEndOfSessionFeedback,
} from '../../common/components/EndOfSessionFeedbackInput';
import { Modal } from '../../common/components/Modal';
import iconClose from '../../images/iconClose.svg';
import UserEvents from '../../UserEvents';

type EndOfSessionFeedbackModalProps = {
    endOfSessionFeedback?: EndOfSessionFeedback;
    setEndOfSessionFeedback: (fb: EndOfSessionFeedback) => void;
    onSubmit: () => void;
    onSubmitAndEnd: () => void;
    onClose: () => void;
    sessionId: string;
    sessionEnded?: boolean;
    integrationLink: string;
};

export function EndOfSessionFeedbackModal({
    endOfSessionFeedback,
    setEndOfSessionFeedback,
    onSubmit,
    onSubmitAndEnd,
    onClose,
    sessionId,
    sessionEnded = true,
    integrationLink,
}: EndOfSessionFeedbackModalProps) {
    return (
        <Modal className="guideEndOfSessionFeedback">
            <EndOfSessionFeedbackInput
                title={`How would you rate this session?`}
                feedback={endOfSessionFeedback}
                onUpdateFeedback={setEndOfSessionFeedback}
            >
                <Feature
                    name={Features.CLIENT_INTEGRATION}
                    activeComponent={
                        <Typography variant="body3" style={{ marginBottom: 16 }}>
                            The audio from this session, along with questions for reflection, can be shared with your
                            client{' '}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={integrationLink}
                                onClick={() => UserEvents.integrationLinkOpened(sessionId)}
                            >
                                here
                            </a>
                            .
                        </Typography>
                    }
                />
                <Button
                    variant="solid-blue"
                    onClick={onSubmitAndEnd}
                    disabled={!isValidEndOfSessionFeedback(endOfSessionFeedback)}
                >
                    Submit and end session
                </Button>
                {!sessionEnded && (
                    <Button
                        style={{ marginTop: 16 }}
                        variant="clear-underlined"
                        size="s"
                        onClick={onSubmit}
                        disabled={!isValidEndOfSessionFeedback(endOfSessionFeedback)}
                    >
                        Submit and return to session
                    </Button>
                )}
            </EndOfSessionFeedbackInput>
            <Button variant="solid-blue" className="closeModal" onClick={onClose}>
                <img src={iconClose} alt="Close" />
            </Button>
        </Modal>
    );
}
