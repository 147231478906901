import { FreudConnection } from './FreudConnection';

export const registerCypressTestingHook = (freudConnectionInstance: FreudConnection): void => {
    if (!('Cypress' in window)) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).wavepaths = {
        testing: {
            freudConnection: freudConnectionInstance,
        },
    };
};
