import React from 'react';

import { Typography } from '@/component-library';

import InfoButtonAndDialog from './InfoButtonAndDialog';

const TherapeuticTemplateInfoDialog: React.FC = () => (
    <InfoButtonAndDialog title="Music Template">
        <Typography variant="body2" color="textSecondary">
            The templates available in the system provide pre-designed sessions that consist of different combinations
            of emotional qualities and intensities.
        </Typography>
        <Typography variant="body2" color="textSecondary">
            The templates are categorized into low, medium and high intensity options. These options service different
            therapeutic functions. To read more about these, see{' '}
            <a
                href="https://community.wavepaths.com/c/person-centred-music-5e205a/sections/28756/lessons/87837
                "
                target="_blank"
                rel="noreferrer"
            >
                this article
            </a>{' '}
            on our communicty platform
        </Typography>
    </InfoButtonAndDialog>
);

export default TherapeuticTemplateInfoDialog;
