import React, { ReactNode } from 'react';

function ValueIndicator({
    width,
    height,
    isDragging,
    top,
    icon,
    bind,
}: {
    width: number;
    height: number;
    isDragging: boolean;
    top: number;
    icon?: ReactNode;
    bind: (...args: any[]) => any;
}): JSX.Element {
    return (
        <div
            {...bind()}
            style={{
                position: 'absolute',
                cursor: 'grab',
                width: `${width}px`,
                height: `${height}px`,
                top: `${top}px`,
                backgroundColor: 'transparent',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                transform: 'translateY(-50%)',
                transition: isDragging ? 'none' : 'top 0.5s ease',
                zIndex: 2,
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    transform: 'scaleY(2)',
                    touchAction: 'none',
                }}
            />
            {icon}
        </div>
    );
}

export default ValueIndicator;
