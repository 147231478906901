import styled from '@emotion/styled';
import { Select } from '@material-ui/core';

interface ContentProps {
    colour: string;
    disabled?: boolean;
    size: 'small' | 'large';
}

const baseStyles = ({ colour, disabled = false, size }: ContentProps) => ({
    border: colour === 'light' ? '1px solid #FFFFFF' : '1px solid #ADB9D6',
    borderRadius: 4,
    cursor: disabled ? 'not-allowed' : 'pointer',
    height: size === 'small' ? 30 : 42,
    fontSize: '13.33px',
    fontWeight: 500,
    color:
        colour === 'light' && !disabled
            ? 'rgba(255,255,255,1)'
            : colour === 'light' && disabled
            ? 'rgba(255,255,255,0.3)'
            : colour === 'dark' && !disabled
            ? 'rgba(44,57,88,1)'
            : colour === 'dark' && disabled
            ? 'rgba(44,57,88,0.3)'
            : 'rgba(44,57,88,1)',
});

export const ContentSegmented = styled.div<ContentProps & { optionCount: number }>(
    ({ optionCount, colour, disabled, size }) => ({
        ...baseStyles({ colour, disabled, size }),
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: 'repeat(' + optionCount + ', minmax(0, 1fr))',
    }),
);

export const ContentDropdown = styled(Select)<ContentProps>(({ colour, disabled, size }) => ({
    ...baseStyles({ colour, disabled, size }),
    '> .MuiSelect-icon': {
        top: '50%',
        transform: 'translateY(-50%)',
        right: size === 'small' ? 4 : 10,
    },
    '> .MuiSelect-select': {
        paddingRight: 30,
    },
    '> .MuiSelect-select:focus': {
        background: 'none',
    },
    textIndent: 12,
}));
