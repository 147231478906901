import { startOfMonth, subMonths } from 'date-fns';
import firebase from 'firebase';
import { useMemo, useState } from 'react';
import useSWR from 'swr';

import { getSessionUsage, IGetSessionUsageResponse } from '../api/usageApi';
import useMySubscription from './useMySubscription';

interface IUserSessionTemplatesReturn {
    sessionHours?: number;
    error: Error | undefined;
}

export interface IUseSessionUsageProps {
    fbUser: firebase.User;
}

const useSessionUsage = ({ fbUser }: IUseSessionUsageProps): IUserSessionTemplatesReturn => {
    const [error] = useState<Error | undefined>(undefined);
    const { subscription: sub } = useMySubscription();

    const from = useMemo(
        () =>
            sub?.status === 'active'
                ? subMonths(sub.currentPeriodEndTimestamp, 1).getTime()
                : startOfMonth(new Date()).getTime(),
        [sub?.status],
    );
    const to = useMemo(() => Date.now(), []);

    // const { mutate } = useSWRConfig();
    const { error: getSessionTemplatesError, data } = useSWR<IGetSessionUsageResponse, Error>(
        fbUser ? [`/session-usage`, from, to] : null,
        fbUser
            ? ([, fromTimestamp, toTimestamp]: [any, number, number]) =>
                  getSessionUsage({ fbUser, fromTimestamp, toTimestamp })
            : null,
    );

    return {
        sessionHours: data?.sessionHours,
        error: error ?? getSessionTemplatesError,
    };
};

export default useSessionUsage;
