import './Terms.scss';

import React from 'react';

export const Terms: React.FC = () => (
    <div className="terms">
        <h2>WAVEPATHS</h2>
        <h1>Terms of Service</h1>
        <p>
            Wavepaths provides our website located at guide.wavepaths.com (the “Site”) and music, accessible via the
            Site, for psychedelic therapy services.
        </p>
        <p>
            To make the following Terms easier to read, we refer to the Site and our services collectively as the
            “Services.” Please read these Terms of Service (the “Terms”) carefully because they govern your use of our
            Services.
        </p>
        <p>
            After you have read them, please click on “accept” to agree that you will use our Services in accordance
            with these Terms.
        </p>
        <p>
            Psychedelics remain illegal in many places. Wavepaths does not endorse illegal activities. Nothing in our
            Services is intended to promote illegal behaviour.
        </p>
        <h3>Using the Services</h3>
        <p>
            By using the Services, psychedelic therapists can select from a variety of programs to create customized
            musical experiences for patients undergoing psychedelic therapy.
        </p>
        <p>
            You understand that Wavepaths is developing purely experimental technologies, and that Wavepaths is not in a
            position to offer professional or medical advice with regard to treating medical conditions, mental health
            disorders, or other conditions. Wavepaths does not represent or warrant that its Services can or will bring
            about improvements in patient well-being.
        </p>
        <p>
            You understand that psychedelic therapy involves risks including, but not limited to, the potential for
            subjectively powerful experiences that may lead to emotional upheaval for the patient in care and/or
            therapist, as well as third parties. You also understand that the use of music provided through Wavepaths
            Services is intended to be emotionally evocative, and you therefore agree to undertake all risks and
            responsibilities involved in guiding psychedelic therapy experiences with music provided through our
            Services. You agree that Wavepaths cannot be held responsible for any difficulties whatsoever that may arise
            for you, for the patient in care, or for third parties as a result of your use of the Services. Furthermore,
            you understand and agree that Wavepaths is not responsible for your well-being, or for that of a patient in
            care or a third party, and that Wavepaths cannot be held liable for any effects whatsoever that may arise
            during, or as a result of, your use of these Services.
        </p>
        <p>
            You represent and warrant that you will not use our Services while under the influence of drugs or alcohol.
            Should you violate this term of our agreement with you, you will be held entirely responsible for any risks,
            injuries, or damage, known or unknown, which you, the patient in care, or anyone else may incur as a result
            of this use.
        </p>
        <p>
            You agree that you have read the above limitations of Wavepaths’ liability and fully understand their
            contents. You voluntarily agree to the terms and conditions stated above in their entirety, and agree to
            abide by these Terms when using our Services.
        </p>
        <h3>Content and Content Rights</h3>
        <p>For purposes of these Terms,</p>
        <ol>
            <li>
                “Content” means text, graphics, images, music, software, audio, video, works of authorship of any kind,
                and any and all information or other materials that are made available through the Services; and
            </li>
            <li>
                “User Content” means any Content that users, including you, provide to be made available through the
                Services. Content includes, without any limitation, User Content.
            </li>
        </ol>
        <p>
            Wavepaths’ “Music Guide” (the “App”) is an app designed to support psychedelic therapy with generative
            music. Wavepaths does not grant any intellectual property rights or other proprietary rights to the Content
            being used via the App.
        </p>
        <h3>Rights in Content Granted by Wavepaths</h3>
        <p>
            Subject to your compliance with these Terms, Wavepaths grants you a limited, non-exclusive, non-transferable
            license to access the Content solely for the purpose of your permitted use of the Services, and solely for
            your personal and non-commercial use.
        </p>
        <h3>Rights in App Granted by Wavepaths</h3>
        <p>
            Subject to your compliance with these Terms, Wavepaths grants you a limited non-exclusive, non-transferable
            license to access the App on a mobile device or computer that you own or control. Wavepaths reserves all
            rights in and to the App not expressly granted to you under these Terms. Except as expressly permitted in
            these Terms, you may not:
        </p>
        <ol>
            <li>copy, modify, or create derivative works based on the App;</li>
            <li>distribute, transfer, sub-license, lease, lend, or rent the App to any third party;</li>
            <li>reverse engineer, decompile, or disassemble the App; or</li>
            <li>make the functionality of the App available to multiple users through any means whatsoever.</li>
        </ol>
        <h3>Prohibitions</h3>
        <p>You agree not to do any of the following:</p>
        <ul>
            <li>
                Use, display, mirror, or frame the Services, or any individual element within the Services, including,
                but not limited to, Wavepath’s name, any Wavepaths trademark, logo, or other proprietary information, or
                the layout and design of any page or form contained on a page, without Wavepaths’ express written
                consent;
            </li>
            <li>
                Access, tamper with, or use non-public areas of the Services, Wavepaths’ computer systems, or the
                technical delivery systems of Wavepaths’ providers;
            </li>
            <li>
                Attempt to probe, scan, or test the vulnerability of any Wavepaths system or network, or breach any
                security or authentication measures for any purpose whatsoever;
            </li>
            <li>
                Avoid, bypass, remove, deactivate, impair, descramble, or otherwise circumvent any technological measure
                implemented by Wavepaths or any of Wavepaths providers or any other third party (including another user)
                to protect the Services;
            </li>
            <li>
                Attempt to access or search the Services or record or download Content from the Services through the use
                of any engine, software, tool, agent, device or mechanism (including spiders, robots, crawlers, data
                mining tools or the like) other than the software and/or search agents provided by Wavepaths or other
                generally available third party web browsers;
            </li>
            <li>
                Use any meta tags or other hidden text or metadata utilizing a Wavepaths trademark, logo URL, or product
                name without Wavepaths express written consent;
            </li>
            <li>
                Use the Services for any commercial purpose or the benefit of any third party, or in any manner
                whatsoever not permitted by these Terms;
            </li>
            <li>
                Attempt to decipher, decompile, disassemble, or reverse engineer any of the software used to provide the
                Services;
            </li>
            <li>
                Interfere with, or attempt to interfere with, the access of any user, host, or network, including,
                without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services;
            </li>
            <li>
                Collect or store any personally identifiable information from the Services from other users of the
                Services without their express permission;
            </li>
            <li>Impersonate or misrepresent your affiliation with any person or entity;</li>
            <li>Violate any applicable law or regulation; or</li>
            <li>Encourage or enable any other individual to do any of the foregoing.</li>
            <h3>Warranty Disclaimers</h3>
            <p>
                The Services, Products, and Content are provided “as is,” without warranty of any kind. In particular,
                Wavepaths makes no warranty that the Services or Products will meet your requirements or that they will
                be available on an uninterrupted, secure, or error-free basis. We make no warranty regarding the
                quality, accuracy, timeliness, truthfulness, completeness, or reliability of our Services or Content.
            </p>
            <h3>INDEMNITY</h3>
            <p>
                You will indemnify and hold harmless Wavepaths and its officers, directors, employees, contractors,
                consultants, and agents, from and against any claims, disputes, demands, liabilities, damages, losses,
                and costs and expenses, including, without limitation, reasonable legal and accounting fees arising out
                of or in any way connected with
            </p>
            <ol>
                <li>your access to or use of the Services or Content or</li>
                <li>your violation of these Terms.</li>
            </ol>
            <h3>LIMITATION OF LIABILITY</h3>
            <p>
                Neither Wavepaths nor any other party involved in creating, producing, or delivering the Services or
                Content will be liable for any incidental, special, exemplary, or consequential damages, including, but
                not limited to, lost profits, loss of data or goodwill, service interruption, computer damage or system
                failure, or from the use of, or inability to, use the Services or Content, whether based on warranty or
                contract, and whether or not Wavepaths has been informed of the possibility of such damages.
            </p>
            <h3>Governing Law</h3>
            <p>These Terms and any action related thereto will be governed by the laws of England and Wales.</p>
            <h3>Privacy</h3>
            <p>
                Protecting your privacy of utmost importance to us. We do not collect any personally identifiable
                information. We only store information required for the delivery of our Services, i.e. login
                credentials. Please click here to view our Privacy Policy.
            </p>
            <h3>Acknowledgement</h3>
            <p>
                If you use our Services in the course of research, you must credit Wavepaths in any research outputs, as
                well as notifying Wavepaths in advance of publication of any such outputs. Research outputs may be
                written, verbally presented, audio/visual, electronic, or other types of outputs, and they include, but
                are not limited to, the following:
            </p>
            <table>
                <thead>
                    <tr>
                        <th>Written</th>
                        <th>Verbal</th>
                        <th>Audio/visual</th>
                        <th>Electronic</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Research papers and abstracts</td>
                        <td>Oral presentations</td>
                        <td>Research posters</td>
                        <td>Websites</td>
                    </tr>
                    <tr>
                        <td>Press releases</td>
                        <td>Speaking to the media, including media interviews</td>
                        <td>Presentation slides</td>
                        <td>Blog posts</td>
                    </tr>
                    <tr>
                        <td>Research reports</td>
                        <td>Speaking at events</td>
                        <td></td>
                        <td>Newsletters</td>
                    </tr>
                    <tr>
                        <td>Books or book chapters</td>
                        <td></td>
                        <td></td>
                        <td>Social media</td>
                    </tr>
                </tbody>
            </table>
        </ul>
    </div>
);
