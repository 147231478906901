import styled from '@emotion/styled';
import React from 'react';

import TypographyV2 from '../typography/TypographyV2';
import EvaIcon from './EvaIcon';

type Option<ValueType> = { label: string; value: ValueType };

export type PillValueProps<ValueType> = {
    name: string;
    values: ValueType[];
    options: Option<ValueType>[];
    onPillClick?: () => void;
    onItemClick?: (itemValue: ValueType) => void;
    onItemDelete?: (itemValue: ValueType) => void;
};

const Container = styled.div`
    display: flex;
    height: 36px;
    width: fit-content;
    padding: 2px;
    box-sizing: border-box;
    align-items: center;
    border-radius: 30px;
    cursor: pointer;
    border: 1px solid var(--gray-200, #eaecf0);
    background: var(--gray-50, #f9fafb);
`;
const LabelContainer = styled.div`
    padding: 0 8px;
`;
const FilterValueContainer = styled.div`
    display: flex;
    padding: 4px 6px 4px 12px;
    align-items: center;
    cursor: pointer;
    gap: 2px;
    box-sizing: border-box;
    border-radius: 16px;
    border: 1px solid var(--primary-200, #e9d7fe);
    background: var(--primary-50, #f9f5ff);
`;

const FilterValuesContainer = styled.div`
    display: flex;
    gap: 2px;
`;

const IconContainer = styled.div``;

const StyledTypography = styled(TypographyV2)({
    whiteSpace: 'nowrap',
});

function PillValue<ValueType extends unknown>(props: PillValueProps<ValueType>) {
    const { values, onPillClick, onItemDelete, onItemClick, options } = props;
    return (
        <Container onClick={onPillClick}>
            <LabelContainer>
                <TypographyV2 size="text-sm" weight="medium" color="grey-600">
                    {props.name}
                </TypographyV2>
            </LabelContainer>
            <FilterValuesContainer>
                {values.map((itemValue) => (
                    <FilterValueContainer onClick={() => onItemClick && onItemClick(itemValue)} key={`${itemValue}`}>
                        <StyledTypography size="text-sm" weight="medium" color="purple-600">
                            {options.find(({ value }) => value === itemValue)?.label ?? ''}
                        </StyledTypography>
                        <IconContainer
                            onClick={(e) => {
                                e.stopPropagation();
                                onItemDelete && onItemDelete(itemValue);
                            }}
                        >
                            <EvaIcon name="close-outline" size={16} iconStyle={{ fill: '#6941C6' }} />
                        </IconContainer>
                    </FilterValueContainer>
                ))}
            </FilterValuesContainer>
        </Container>
    );
}

export default PillValue;
