import { formatISO } from 'date-fns';
import { get, isEqual, isNumber, isObject, isUndefined } from 'lodash';
import {
    ActiveLayerDetails,
    CoreEmotionalAtmosphere,
    Depth,
    EndOfSessionFeedback,
    isPrePostLudePathScore,
    LayerNumber,
    pathScoreEmotionToString,
    Session,
    SessionHeader,
    UserData,
    Wavepath,
} from 'wavepaths-shared/core';
import { getDuration } from 'wavepaths-shared/domain/wavepath';
import { millisecondsToMinutes } from 'wavepaths-shared/util/dateUtils';

import { getMedicineFromScore } from './common/domain/modalitiesOld';
import configs from './configs';
import itly, { ClickProperties } from './itly';
import { SchedulingStyle } from './pages/planner/SessionSchedulingOptions';

interface IExtraSessionData {
    sessionCompletness?: number;
    introSessionId?: string;
    scheduleType?: SchedulingStyle;
    isTemplateCustom?: boolean;
}

export interface IWaveTrackingParams {
    index: number;
    noOfWaves: number;
    wave: Wavepath;
}

interface ICurrentWaveExtendedParams {
    effectiveTimeSeconds?: number;
    pathScoreId: string;
    timeUntilWaveEndSeconds?: number;
    waveDurationSeconds?: number;
    extendedBySeconds: number;
}

export enum CopiedSessionTab {
    SCHEDULED = 'Scheduled',
    COMPLETED = 'Completed',
}

// TODO refactor later;
const translateAcousticness = (acc: number): string => {
    switch (acc) {
        case 0:
            return 'Mostly Electronic';
        case 0.5:
            return 'Mixed';
        case 1:
            return 'Mostly Acoustic';
        default:
            return '';
    }
};

const translateUse = (use: string | number): string => {
    const useParsed = typeof use === 'string' ? parseInt(use) : use;
    switch (useParsed) {
        case 1:
            return 'Testing (no client)';
        case 2:
            return 'In-person client';
        case 3:
            return 'Remote client';
        case 4:
            return 'Remote group';
        case 5:
            return 'Other';
        default:
            return 'undefined';
    }
};

const getCeaId = (cea: CoreEmotionalAtmosphere): string | undefined => {
    return Object.keys(CoreEmotionalAtmosphere).find(
        (key: string) => CoreEmotionalAtmosphere[key as keyof typeof CoreEmotionalAtmosphere] === cea,
    );
};

const events = {
    init: (): void => {
        itly.load({ environment: configs.iteratively.ENVIRONMENT });

        listenToGlobalClick();
    },
    click: (data: ClickProperties): void => {
        itly.click(data);
    },
    integrationLinkCopied: (sessionId: string): void => {
        itly.integrationLinkCopied({ sessionId });
    },
    integrationLinkOpened: (sessionId: string): void => {
        itly.integrationLinkOpened({ sessionId });
    },
    identify: (userData: UserData): void => {
        const { uid, name, email, groups, roles } = userData;
        itly.identify(uid, {
            userId: uid,
            name,
            $email: email,
            groups: groups ?? [],
            roles: roles ?? [],
            //TODO: Fill remaining ones
            membershipStarted: undefined,
            trialStarted: undefined,
            currentMembershipName: undefined,
            membershipStatus: undefined,
            // TODO: Review tracking plan with Yalcin,
            // and decide which information we can/should provide and what it refers to
        });
    },
    viewPage: (pageViewData: { parameter?: string; path: string }): void => {
        itly.pageViewed(pageViewData);
    },
    signUp: (): void => {
        itly.signUp();
    },
    signIn: (): void => {
        itly.signIn();
    },
    signOut: (): void => {
        itly.signOut();
    },
    subscribeClicked: (data: { priceId: string }): void => {
        itly.subscribeClicked(data);
    },
    sessionCreated: (session: Session, extraSessionData?: IExtraSessionData): void => {
        itly.sessionCreated({
            // TODO: Review tracking plan with Yalcin,
            // and decide which information we can/should provide and what it refers to medicine,
            use: translateUse(session.variableInputs.sessionUse),
            therapeuticTemplate: session.score.name, // @us: change to ID? / slug
            name: session.variableInputs.name as string,
            scheduleTime: !isUndefined(session.scheduledStart)
                ? formatISO(session.scheduledStart as number)
                : undefined, // @Yalcin: what format should this be tracked as?
            scheduleType: extraSessionData?.scheduleType, // @Yalcin: what format should this be tracked as?
            renderType: session.renderType,
            duration: Number(session.variableInputs.totalDuration),
            musicalPreference: translateAcousticness(session.variableInputs.Acousticness as number), // number that is either 0, 0,5, 1 (Mostly Electronic, Mixed, Mostly Acoustic)
            medicine: getMedicineFromScore(session.score.selectionCriteria), //TODO helper function that converts array into string
            type: session.type,
            introSessionId: extraSessionData?.introSessionId,
            isTemplateCustom: !!extraSessionData?.isTemplateCustom,
        });
    },
    sessionStarted: (session: Session, extraSessionData?: IExtraSessionData): void => {
        itly.sessionStarted({
            // TODO: Review tracking plan with Yalcin,
            // and decide which information we can/should provide and what it refers to medicine,
            use: translateUse(session.variableInputs.sessionUse),
            therapeuticTemplate: session.score.name, // @us: change to ID? / slug
            name: session.variableInputs.name as string,
            scheduleTime: !isUndefined(session.scheduledStart)
                ? formatISO(session.scheduledStart as number)
                : undefined, // @Yalcin: what format should this be tracked as?
            duration: Number(session.variableInputs.totalDuration),
            renderType: session.renderType,
            musicalPreference: translateAcousticness(session.variableInputs.Acousticness as number), // number that is either 0, 0,5, 1 (Mostly Electronic, Mixed, Mostly Acoustic)
            medicine: getMedicineFromScore(session.score.selectionCriteria), //TODO helper function that converts array into string
            type: session.type,
            introSessionId: extraSessionData?.introSessionId,
        });
    },
    sessionCompleted: (session: Session | SessionHeader, extraSessionData?: IExtraSessionData): void => {
        itly.sessionCompleted({
            use: translateUse(session.variableInputs.sessionUse),
            therapeuticTemplate: session.score?.name,
            name: session.variableInputs.name as string,
            scheduleTime: formatISO(session.scheduledStart as number),
            renderType: session.renderType,
            duration: Number(session.variableInputs.totalDuration),
            musicalPreference: translateAcousticness(session.variableInputs.Acousticness as number),
            medicine: getMedicineFromScore(session.score?.selectionCriteria),
            type: session.type,
            introSessionId: extraSessionData?.introSessionId,
        });
    },
    sessionCancelled: (session: Session | SessionHeader, extraSessionData?: IExtraSessionData): void => {
        // TODO: Review tracking plan with Yalcin,
        // and decide which information we can/should provide and what it refers to
        itly.sessionCanceled({
            use: translateUse(session.variableInputs.sessionUse),
            therapeuticTemplate: session.score?.name,
            name: session.variableInputs.name as string,
            scheduleTime: formatISO(session.scheduledStart as number),
            sessionEndTime: undefined,
            renderType: session.renderType,
            duration: Number(session.variableInputs.totalDuration),
            musicalPreference: translateAcousticness(session.variableInputs.Acousticness as number),
            medicine: getMedicineFromScore(session.score?.selectionCriteria),
            type: session.type,
            sessionCompletness: extraSessionData?.sessionCompletness,
            introSessionId: extraSessionData?.introSessionId,
        });
    },
    ceaSelected: (cea: CoreEmotionalAtmosphere): void => {
        itly.ceaSelected({ coreEmotionalAtmosphere: getCeaId(cea) ?? 'unknown' });
    },
    ceaSelectionCanceled: (cea: CoreEmotionalAtmosphere): void => {
        itly.ceaSelectionCanceled({ coreEmotionalAtmosphere: getCeaId(cea) ?? 'unknown' });
    },
    ceaSelectionSkippedQueue: (cea: CoreEmotionalAtmosphere): void => {
        itly.ceaSelectionSkippedQueue({ coreEmotionalAtmosphere: getCeaId(cea) ?? 'unknown' });
    },
    depthChanged: (depth: Depth): void => {
        itly.depthChanged({ depth });
    },
    depthChangeCanceled: (depth: Depth): void => {
        itly.depthChangeCanceled({ depth });
    },
    depthChangeSkippedQueue: (depth: Depth): void => {
        itly.depthChangeSkippedQueue({ depth });
    },
    instrumentsRefreshed: (layers: ActiveLayerDetails[]): void => {
        itly.instrumentsRefreshed({ layers });
    },
    instrumentsRefreshCanceled: (layers: LayerNumber[]): void => {
        itly.instrumentRefreshCanceled({ layers });
    },
    instrumentsRefreshSkippedQueue: (layers: LayerNumber[]): void => {
        itly.instrumentsRefreshSkippedQueue({ layers });
    },
    feedbackRecorded: ({
        id,
        feedback,
        rating,
        technicalQualityRating,
        musicQualityRating,
        easeOfUseRating,
        type,
    }: EndOfSessionFeedback): void => {
        itly.feedbackRecorded({
            id,
            feedback,
            rating,
            technicalQualityRating,
            musicQualityRating,
            easeOfUseRating,
            type,
        });
    },
    waveSkipped: ({ waveIndex }: { waveIndex?: number }): void => {
        itly.waveSkipped({ waveIndex });
    },
    copiedSession: (tab: CopiedSessionTab): void => {
        itly.copiedSession({ tab });
    },
    addWave: ({ index, noOfWaves }: { index: number; noOfWaves: number }): void => {
        itly.addWave({
            index,
            totalNumberOfWavesInSession: noOfWaves,
        });
    },
    addWaveInEditor: ({ index, noOfWaves }: { index: number; noOfWaves: number }): void => {
        itly.addWaveInEditor({
            index,
            totalNumberOfWavesInSession: noOfWaves,
        });
    },
    removeWave: ({ index, noOfWaves, wave: { pathScore, type, pathId } }: IWaveTrackingParams): void =>
        itly.removeWave({
            index,
            totalNumberOfWavesInSession: noOfWaves,
            type: pathScore.type,
            phase: type,
            direction: pathScore.direction,
            name: pathScore.name,
            emotion: isPrePostLudePathScore(pathScore) || isObject(pathScore.emotion) ? undefined : pathScore.emotion,
            pathId,
            intensity: pathScore.selectionCriteria?.emotionalIntensity,
        }),
    removeWaveInEditor: ({ index, noOfWaves, wave: { pathScore, type, pathId } }: IWaveTrackingParams): void =>
        itly.removeWaveInEditor({
            index,
            totalNumberOfWavesInSession: noOfWaves,
            type: pathScore.type,
            phase: type,
            direction: pathScore.direction,
            name: pathScore.name,
            emotion: isPrePostLudePathScore(pathScore) || isObject(pathScore.emotion) ? undefined : pathScore.emotion,
            pathId,
            intensity: pathScore.selectionCriteria?.emotionalIntensity,
        }),
    skipToWave: ({
        skipToIndex,
        currentWaveIndex,
        wavepaths,
    }: {
        skipToIndex: number;
        currentWaveIndex: number;
        wavepaths: Wavepath[];
    }): void => {
        const skippedToWave = wavepaths[skipToIndex];
        const currentWave = wavepaths[currentWaveIndex];
        const { pathScore, type, pathId } = skippedToWave;
        const lostTime = (skippedToWave.plan?.fromTime ?? 0) - (currentWave.plan?.fromTime ?? 0);

        itly.skipToWave({
            index: skipToIndex,
            totalNumberOfWavesInSession: wavepaths.length,
            type: pathScore.type,
            phase: type,
            direction: pathScore.direction,
            name: pathScore.name,
            emotion: isPrePostLudePathScore(pathScore) || isObject(pathScore.emotion) ? undefined : pathScore.emotion,
            pathId,
            lostMinutes: Math.abs(millisecondsToMinutes(lostTime)),
            intensity: pathScore.selectionCriteria?.emotionalIntensity,
        });
    },
    moveWaveUp: ({ index, noOfWaves, wave: { pathScore, type, pathId } }: IWaveTrackingParams): void =>
        itly.moveWaveUp({
            index,
            totalNumberOfWavesInSession: noOfWaves,
            type: pathScore.type,
            phase: type,
            direction: pathScore.direction,
            name: pathScore.name,
            emotion: isPrePostLudePathScore(pathScore) || isObject(pathScore.emotion) ? undefined : pathScore.emotion,
            pathId,
            intensity: pathScore.selectionCriteria?.emotionalIntensity,
        }),
    moveWaveUpInEditor: ({ index, noOfWaves, wave: { pathScore, type, pathId } }: IWaveTrackingParams): void =>
        itly.moveWaveUpInEditor({
            index,
            totalNumberOfWavesInSession: noOfWaves,
            type: pathScore.type,
            phase: type,
            direction: pathScore.direction,
            name: pathScore.name,
            emotion: isPrePostLudePathScore(pathScore) || isObject(pathScore.emotion) ? undefined : pathScore.emotion,
            pathId,
            intensity: pathScore.selectionCriteria?.emotionalIntensity,
        }),
    moveWaveDown: ({ index, noOfWaves, wave: { pathScore, type, pathId } }: IWaveTrackingParams): void =>
        itly.moveWaveDown({
            index,
            totalNumberOfWavesInSession: noOfWaves,
            type: pathScore.type,
            phase: type,
            direction: pathScore.direction,
            name: pathScore.name,
            emotion: isPrePostLudePathScore(pathScore) || isObject(pathScore.emotion) ? undefined : pathScore.emotion,
            pathId,
            intensity: pathScore.selectionCriteria?.emotionalIntensity,
        }),
    moveWaveDownInEditor: ({ index, noOfWaves, wave: { pathScore, type, pathId } }: IWaveTrackingParams): void =>
        itly.moveWaveDownInEditor({
            index,
            totalNumberOfWavesInSession: noOfWaves,
            type: pathScore.type,
            phase: type,
            direction: pathScore.direction,
            name: pathScore.name,
            emotion: isPrePostLudePathScore(pathScore) || isObject(pathScore.emotion) ? undefined : pathScore.emotion,
            pathId,
            intensity: pathScore.selectionCriteria?.emotionalIntensity,
        }),
    updateWave: ({
        newWave,
        oldWave,
        noOfWaves,
        index,
    }: {
        index: number;
        noOfWaves: number;
        newWave: Wavepath;
        oldWave: Wavepath;
    }): void => {
        const newPathscore = newWave.pathScore;
        const oldPathscore = oldWave.pathScore;
        const newName = newPathscore.name;
        const oldName = oldPathscore.name;
        const newDirection = newPathscore.direction;
        const oldDirection = oldPathscore.direction;
        const newPathId = newWave.pathId;
        const oldPathId = oldWave.pathId;
        const newType = newPathscore.type;
        const oldType = oldPathscore.type;
        const newEmotionValue = get(newPathscore, 'emotion');
        const newEmotion = newEmotionValue ? pathScoreEmotionToString(newEmotionValue) : undefined;
        const oldEmotionValue = get(oldPathscore, 'emotion');
        const oldEmotion = oldEmotionValue ? pathScoreEmotionToString(oldEmotionValue) : undefined;
        const oldDuration = oldWave.duration ?? Math.round(millisecondsToMinutes(getDuration(oldWave.plan!)));
        const newPathScoreDuration =
            !isPrePostLudePathScore(newPathscore) && isNumber(newPathscore.duration)
                ? Math.round(newPathscore.duration)
                : 'Auto';
        const newDuration = newWave.duration ?? newPathScoreDuration;
        const newIntensity = newPathscore.selectionCriteria?.emotionalIntensity;
        const oldIntensity = oldPathscore.selectionCriteria?.emotionalIntensity;

        itly.updateWave({
            index,
            totalNumberOfWavesInSession: noOfWaves,
            ...(isEqual(newDirection, oldDirection) ? {} : { newDirection, oldDirection }),
            ...(isEqual(newName, oldName) ? {} : { newName, oldName }),
            ...(isEqual(newEmotion, oldEmotion) ? {} : { newEmotion, oldEmotion }),
            ...(isEqual(newDuration, oldDuration)
                ? {}
                : { newDuration: `${newDuration}`, oldDuration: `${oldDuration}` }),
            ...(isEqual(newPathId, oldPathId) ? {} : { newPathId, oldPathId }),
            ...(isEqual(newIntensity, oldIntensity) ? {} : { newIntensity, oldIntensity }),
            ...(isEqual(newType, oldType) ? {} : { newType, oldType }),
        });
    },
    updateWaveInEditor: ({
        newWave,
        oldWave,
        noOfWaves,
        index,
    }: {
        index: number;
        noOfWaves: number;
        newWave: Wavepath;
        oldWave: Wavepath;
    }): void => {
        const newPathscore = newWave.pathScore;
        const oldPathscore = oldWave.pathScore;
        const newName = newPathscore.name;
        const oldName = oldPathscore.name;
        const newDirection = newPathscore.direction;
        const oldDirection = oldPathscore.direction;
        const newPathId = newWave.pathId;
        const oldPathId = oldWave.pathId;
        const newType = newPathscore.type;
        const oldType = oldPathscore.type;
        const newEmotionValue = get(newPathscore, 'emotion');
        const newEmotion = newEmotionValue ? pathScoreEmotionToString(newEmotionValue) : undefined;
        const oldEmotionValue = get(oldPathscore, 'emotion');
        const oldEmotion = oldEmotionValue ? pathScoreEmotionToString(oldEmotionValue) : undefined;
        const oldDuration = oldWave.duration ?? Math.round(millisecondsToMinutes(getDuration(oldWave.plan!)));
        const newPathScoreDuration =
            !isPrePostLudePathScore(newPathscore) && isNumber(newPathscore.duration)
                ? Math.round(newPathscore.duration)
                : 'Auto';
        const newDuration = newWave.duration ?? newPathScoreDuration;
        const newIntensity = newPathscore.selectionCriteria?.emotionalIntensity;
        const oldIntensity = oldPathscore.selectionCriteria?.emotionalIntensity;

        itly.updateWaveInEditor({
            index,
            totalNumberOfWavesInSession: noOfWaves,
            ...(isEqual(newDirection, oldDirection) ? {} : { newDirection, oldDirection }),
            ...(isEqual(newName, oldName) ? {} : { newName, oldName }),
            ...(isEqual(newEmotion, oldEmotion) ? {} : { newEmotion, oldEmotion }),
            ...(isEqual(newDuration, oldDuration)
                ? {}
                : { newDuration: `${newDuration}`, oldDuration: `${oldDuration}` }),
            ...(isEqual(newPathId, oldPathId) ? {} : { newPathId, oldPathId }),
            ...(isEqual(newIntensity, oldIntensity) ? {} : { newIntensity, oldIntensity }),
            ...(isEqual(newType, oldType) ? {} : { newType, oldType }),
        });
    },
    cancelWaveUpdate: (): void => itly.cancelWaveUpdate(),
    skipQueueWaveUpdate: (): void => itly.skipQueueWaveUpdate(),
    openWaveViaQueue: (): void => itly.openWaveViaQueue(),
    closeWaveViaQueue: (): void => itly.closeWaveViaQueue(),
    waveCannotBeMovedFurtherDown: ({
        index,
        noOfWaves,
        wave: { pathScore, type, pathId },
    }: IWaveTrackingParams): void =>
        itly.waveCannotBeMovedFurtherDown({
            index,
            totalNumberOfWavesInSession: noOfWaves,
            type: pathScore.type,
            phase: type,
            direction: pathScore.direction,
            name: pathScore.name,
            emotion: isPrePostLudePathScore(pathScore) || isObject(pathScore.emotion) ? undefined : pathScore.emotion,
            pathId,
            intensity: pathScore.selectionCriteria?.emotionalIntensity,
        }),
    waveCannotBeMovedFurtherDownInEditor: ({
        index,
        noOfWaves,
        wave: { pathScore, type, pathId },
    }: IWaveTrackingParams): void =>
        itly.waveCannotBeMovedFurtherDownInEditor({
            index,
            totalNumberOfWavesInSession: noOfWaves,
            type: pathScore.type,
            phase: type,
            direction: pathScore.direction,
            name: pathScore.name,
            emotion: isPrePostLudePathScore(pathScore) || isObject(pathScore.emotion) ? undefined : pathScore.emotion,
            pathId,
            intensity: pathScore.selectionCriteria?.emotionalIntensity,
        }),
    waveCannotBeMovedFurtherUp: ({ index, noOfWaves, wave: { pathScore, type, pathId } }: IWaveTrackingParams): void =>
        itly.waveCannotBeMovedFurtherUp({
            index,
            totalNumberOfWavesInSession: noOfWaves,
            type: pathScore.type,
            phase: type,
            direction: pathScore.direction,
            name: pathScore.name,
            emotion: isPrePostLudePathScore(pathScore) || isObject(pathScore.emotion) ? undefined : pathScore.emotion,
            pathId,
            intensity: pathScore.selectionCriteria?.emotionalIntensity,
        }),
    waveCannotBeMovedFurtherUpInEditor: ({
        index,
        noOfWaves,
        wave: { pathScore, type, pathId },
    }: IWaveTrackingParams): void =>
        itly.waveCannotBeMovedFurtherUpInEditor({
            index,
            totalNumberOfWavesInSession: noOfWaves,
            type: pathScore.type,
            phase: type,
            direction: pathScore.direction,
            name: pathScore.name,
            emotion: isPrePostLudePathScore(pathScore) || isObject(pathScore.emotion) ? undefined : pathScore.emotion,
            pathId,
            intensity: pathScore.selectionCriteria?.emotionalIntensity,
        }),
    clickWaveInfo: (): void => itly.clickWaveTypeInfo(),
    clickExperimentalWaveFunctionInfo: (): void => itly.clickExperimentalWaveFunctionInfo(),
    dragSparkline: (): void => itly.dragsIndividualSparklinesInQueue(),
    openWaveViaSparkline: (): void => itly.openWaveViaSparkline(),
    closeWaveViaSparkline: (): void => itly.closeWaveViaSparkline(),
    overwriteSettingsDialogTriggeredInEditor: (): void => itly.overwriteSettingsDialogTriggeredInEditor(),
    openSessionEditorModal: (): void => itly.openSessionEditorModal(),
    closeSessionEditorModal: (): void => itly.closeSessionEditorModal(),
    streamingNetworkGlitch: (): void => itly.streamingNetworkGlitch(),
    goingOffline: (): void => itly.goingOffline(),
    jumpedRightIn: (): void => itly.jumpedRightIn(),
    currentWaveExtended: ({
        effectiveTimeSeconds,
        pathScoreId,
        timeUntilWaveEndSeconds,
        waveDurationSeconds,
        extendedBySeconds,
    }: ICurrentWaveExtendedParams): void =>
        itly.currentWaveExtended({
            effectiveTimeSeconds,
            pathScoreId,
            timeUntilWaveEndSeconds,
            waveDurationSeconds,
            extendedBySeconds,
        }),
    newSessionFromTemplateClicked: itly.newSessionFromTemplateClicked,
    sessionTemplateSelected: itly.sessionTemplateSelected,
    sessionTemplateDeleted: itly.sessionTemplateDeleted,
    sessionTemplateCreated: itly.sessionTemplateCreated,
    completedSessionAudioPlayed: itly.completedSessionAudioPlayed,
};

const getFiberPaths = (fiber: any) => {
    let currentFiber = fiber;
    const pathStrings: string[] = [];
    let counter = 0;
    do {
        counter++;

        let componentName;
        if (currentFiber.type) {
            if (currentFiber.type.displayName) {
                componentName = currentFiber.type.displayName;
            } else if (currentFiber.type.name) {
                componentName = currentFiber.type.name;
            } else {
                //console.log("ups not sure what this is");
            }
        } else if (currentFiber.memoizedState && currentFiber.memoizedState.element) {
            currentFiber = currentFiber.memoizedState.element;
            continue;
        }

        if (currentFiber.key) {
            componentName += '(' + currentFiber.key + ')';
        }

        if (componentName == 'Router') {
            break; // enough is enough
        }

        if (componentName && componentName.indexOf('Styled(') == -1 && componentName.indexOf('TypographyV2') == -1) {
            pathStrings.push(componentName);
        }

        currentFiber = currentFiber.return;
    } while (currentFiber || counter > 150);
    return pathStrings;
};

function findReactPaths(dom: any) {
    const key = Object.keys(dom).find((key) => {
        return (
            key.startsWith('__reactFiber$') || // react 17+
            key.startsWith('__reactInternalInstance$')
        ); // react <17
    });
    if (!key) return undefined;
    const domFiber = dom[key];
    if (domFiber == null) return undefined;

    const paths = getFiberPaths(domFiber);

    return paths;
}

const listenToGlobalClick = () => {
    let clickCounter = 0;
    document.addEventListener('click', function (event) {
        console.log('Click event : ' + clickCounter++);
        console.log(event);

        if (!event.target) return;

        const componentTree = findReactPaths(event.target);
        const componentTreeText = componentTree?.join(' < ');
        console.log(componentTree);
        const target: any = event.target || null;
        const label =
            target.id ||
            target.name ||
            target.innerText ||
            target.ariaLabel ||
            (target.attributes.placeholder && target.attributes.placeholder.nodeValue) ||
            '';
        const id = target.id || '';
        const name = target.name || '';
        const innerText = target.innerText || '';
        const ariaLabel = target.ariaLabel || '';
        const nodeName = target.nodeName || '';
        const baseURI = target.baseURI || '';
        const x = event.x;
        const y = event.y;

        const props: ClickProperties = {
            label,
            componentTree,
            componentTreeText,
            nodeName,
            baseURI,
            id,
            name,
            innerText,
            ariaLabel,
            x,
            y,
        };

        console.log(props);
        events.click(props);
    });
};

export default events;
