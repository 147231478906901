import styled from '@emotion/styled';
import { animated } from '@react-spring/web';
import { findIndex } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import {
    isPreludeWavepath,
    isScheduledWavepath,
    ScheduledWavepath,
    Session,
    SessionScore,
    SessionVariables,
    TimestampedSessionEvent,
    Wavepath,
} from 'wavepaths-shared/core';
import { makeScoreExpressionEvaluator } from 'wavepaths-shared/scoreExpressions';

import { Connection, useCurrentWave, useSessionDurations } from '../../../common/hooks/useSessionTick';
import UserEvents from '../../../UserEvents';
import { getZeroTick } from '../../planner/SessionVariableInputs';
import { isWaveSelected, selectNoWave, selectWave, WaveSelection } from '../autoGuide/waveSelection';
import { PHASE_GAP, PIXELS_PER_MILLIS } from './constants';
import { TimelinePhase } from './TimelinePhase';
import { TimelinePlayhead } from './TimelinePlayhead';
import { TimelineWave } from './TimelineWave';
import { TimelineWaveProperties } from './timelineWaveUtils';
import { useTimelineTransform } from './useTimelineTransform';

const Container = styled.div<{
    isScrollable: boolean;
}>(
    ({ isScrollable }) => `
    width: 100%;
    display: grid;
    place-content: center;
    overflow: ${isScrollable ? 'auto' : undefined};
`,
);

const Overflow = styled.div<{
    timelineHeight: number;
    timelinePosition: 'fixed' | 'moving';
}>(
    ({ timelineHeight, timelinePosition }) => `
    position: relative;
    height: ${timelineHeight + 54}px;
    min-width: 600px;
    max-width: 100%;
    padding: 0 22px; 
    display: inline-grid;
    align-content: center;
    justify-content: ${timelinePosition === 'fixed' ? 'center' : 'start'};
`,
);

const SVG = styled(animated.svg)`
    overflow: visible;
    cursor: pointer;
    will-change: transform;
`;

const Phases = styled.div<{
    width: number;
}>(
    ({ width }) => `
    width: ${width}px;
    height: 0;
    display: grid;
    grid-auto-flow: column;
    gap: ${PHASE_GAP}px;
`,
);

interface TimelineProps {
    score: SessionScore;
    variables: SessionVariables;
    log?: TimestampedSessionEvent[];
    waveSelection: WaveSelection;
    setWaveSelection: (newSelection: WaveSelection) => void;
    isScrollable?: boolean;
    connection?: Connection;
    audioGeneratedPositionSecs?: number;
    phasesAlwaysVisible?: boolean;
    session?: Session;
    elapsedTimeMs?: number;
    isPlanner?: boolean;
}

const defaultLog: TimestampedSessionEvent[] = [];

export const Timeline: React.FC<TimelineProps> = React.memo(
    ({
        score,
        connection,
        variables,
        log = defaultLog,
        waveSelection,
        setWaveSelection,
        audioGeneratedPositionSecs,
        isScrollable = false,
        phasesAlwaysVisible,
        elapsedTimeMs,
        isPlanner,
    }) => {
        const plan = score.wavepaths;
        const scheduledPlan = useMemo(() => plan.filter(isScheduledWavepath), [plan]);
        const zeroTick = useMemo(() => getZeroTick(score, variables), [score, variables]);
        const { sessionDuration } = useSessionDurations(connection, zeroTick);
        const { wave: currentWave } = useCurrentWave(connection, score.wavepaths);
        const currentWaveIndex = getCurrentWaveIndex(currentWave, scheduledPlan);
        const selectedWaveIndex = findIndex(scheduledPlan, (i) => isWaveSelected(waveSelection, i.id));

        const [wrapperRef, { width: wrapperWidth }] = useMeasure<HTMLDivElement>();
        const timelineWidth = Math.max(PIXELS_PER_MILLIS * sessionDuration, 0);
        const timelineHeight = 36;
        const waves = useMemo(
            () =>
                scheduledPlan.map((item, idx) => ({
                    wave: item,
                    previousWavePathScore: scheduledPlan[idx - 1]?.pathScore,
                    x: item.plan!.fromTime! * PIXELS_PER_MILLIS,
                    width: (item.plan!.toTime! - item.plan!.fromTime!) * PIXELS_PER_MILLIS,
                    height: timelineHeight,
                })),
            [scheduledPlan, timelineHeight],
        );
        const selectedWave = selectedWaveIndex >= 0 ? waves[selectedWaveIndex] : null;
        const timelinePosition = timelineWidth <= wrapperWidth ? 'fixed' : 'moving';

        const timelineTransform = useTimelineTransform({
            timelinePosition,
            currentWave,
            selectedWave,
            pixelsPerMillis: PIXELS_PER_MILLIS,
            wrapperWidth,
            sessionDuration,
            connection,
        });

        const phases = useMemo(() => getSessionPhases(score, variables, log), [score, variables, log]);
        const [phasesVisible, setPhasesVisible] = useState(false);

        const getWaveSparklineOpacity = (index: number) => {
            if (selectedWaveIndex === index) {
                return 'full';
            } else if (selectedWaveIndex >= 0) {
                return 'low';
            } else if (currentWaveIndex > index) {
                return 'medium';
            } else {
                return 'full';
            }
        };

        const onWaveClick = useCallback(
            (wave: TimelineWaveProperties) => {
                if (isWaveSelected(waveSelection, wave.wave.id)) {
                    setWaveSelection(selectNoWave());
                    UserEvents.closeWaveViaSparkline();
                } else {
                    setWaveSelection(selectWave(wave.wave));
                    UserEvents.openWaveViaSparkline();
                }
            },
            [waveSelection, setWaveSelection],
        );

        return (
            <Container className="tour-sparkline" ref={wrapperRef} isScrollable={isScrollable}>
                <Overflow timelinePosition={timelinePosition} timelineHeight={timelineHeight}>
                    <Phases width={timelineWidth}>
                        {phases.map((phase, idx) => (
                            <TimelinePhase
                                position={idx % 2 === 0 ? 'top' : 'bottom'}
                                phasesVisible={phasesVisible || phasesAlwaysVisible}
                                key={'phaseSection' + idx}
                                phase={phase}
                                width={Math.round(phase.duration * PIXELS_PER_MILLIS) - PHASE_GAP}
                                state={
                                    elapsedTimeMs &&
                                    elapsedTimeMs >= phase.startTime &&
                                    elapsedTimeMs < phase.startTime + phase.duration
                                        ? 'current'
                                        : elapsedTimeMs && elapsedTimeMs > phase.startTime + phase.duration
                                        ? 'past'
                                        : 'future'
                                }
                                isPlanner={isPlanner}
                            />
                        ))}
                    </Phases>
                    <SVG
                        style={{
                            width: timelineWidth,
                            height: timelineHeight,
                            transform: timelineTransform,
                        }}
                        viewBox={`0 0 ${timelineWidth} ${timelineHeight}`}
                        preserveAspectRatio="none"
                        onMouseEnter={() => setPhasesVisible(true)}
                        onMouseLeave={() => setPhasesVisible(false)}
                    >
                        {waves.map((wave, idx) => (
                            <TimelineWave
                                key={wave.wave.id}
                                wave={wave}
                                audioGeneratedPositionSecs={audioGeneratedPositionSecs}
                                opacity={getWaveSparklineOpacity(idx)}
                                onClick={onWaveClick}
                            />
                        ))}
                        {elapsedTimeMs && (
                            <TimelinePlayhead
                                waves={waves}
                                sessionDurationMs={Number(variables.totalDuration) * 60 * 1000}
                                elapsedTimeMs={elapsedTimeMs}
                            />
                        )}
                    </SVG>
                </Overflow>
            </Container>
        );
    },
);

function getCurrentWaveIndex(currentWave: Wavepath | null, scheduledPlan: ScheduledWavepath[]) {
    if (!currentWave || isPreludeWavepath(currentWave)) {
        return -1;
    } else if (isScheduledWavepath(currentWave)) {
        return scheduledPlan.indexOf(currentWave);
    } else {
        return Number.MAX_SAFE_INTEGER;
    }
}

function getSessionPhases(score: SessionScore, variables: SessionVariables, log: TimestampedSessionEvent[]) {
    const result: { startTime: number; duration: number; phase: string }[] = [];
    if (score.timingPlan) {
        const exprFn = makeScoreExpressionEvaluator(score, variables, log);
        let cumulativeTime = 0;
        for (const phase of score.timingPlan) {
            let phaseDuration = 0;
            if (phase.type === 'regular') {
                phaseDuration = exprFn.safe(phase.durationVariable) * 60 * 1000;
                result.push({
                    startTime: cumulativeTime,
                    duration: phaseDuration,
                    phase: phase.name,
                });
            }
            cumulativeTime += phaseDuration;
        }
        return result;
    } else {
        return [];
    }
}

Timeline.displayName = 'Timeline';
