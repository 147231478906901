import { useEffect, useState } from 'react';
import { TimestampedSessionEvent } from 'wavepaths-shared/core';

import { FreudConnection } from '../../freudConnection/FreudConnection';
import { PendingBehaviour } from '../../freudConnection/PendingBehaviourTimer';

export type SessionState = {
    log: TimestampedSessionEvent[];
    pendingBehaviours: PendingBehaviour[];
};

export const useSessionState = ({ connection }: { connection: FreudConnection | null }): SessionState => {
    const emptySessionState: SessionState = {
        log: [],
        pendingBehaviours: [],
    };
    const [sessionState, setSessionState] = useState<SessionState>(emptySessionState);

    useEffect(() => {
        if (!!connection) {
            const handleLogUpdate = (log: TimestampedSessionEvent[]) => {
                setSessionState((s) => ({ ...s, log }));
            };
            connection.on('logUpdate', handleLogUpdate);
            const handlePendingBehavioursUpdate = (pendingBehaviours: PendingBehaviour[]) => {
                setSessionState((s) => ({ ...s, pendingBehaviours }));
            };
            connection.on('pendingBehavioursUpdate', handlePendingBehavioursUpdate);

            return () => {
                console.log('Removing logUpdate and pendingBehavioursUpdate listeners');
                connection.removeListener('logUpdate', handleLogUpdate);
                connection.removeListener('pendingBehavioursUpdate', handlePendingBehavioursUpdate);
            };
        }
    }, [connection]);

    return sessionState;
};

export default useSessionState;
