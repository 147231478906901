import { Checkbox, Dialog, DialogContent, Popover } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBackRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import LinkIcon from '@material-ui/icons/LinkRounded';
import VolumeIcon from '@material-ui/icons/VolumeMuteRounded';
import * as Sentry from '@sentry/browser';
import React, { useCallback, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { Session } from 'wavepaths-shared/core';

import { Button, Menu, Typography } from '@/component-library';

import { Header } from '../../common/components/Header';
import Snackbar from '../../common/components/Snackbar';
import { useFeatures } from '../../common/features/useFeatures';
import { getSessionLink } from '../../common/util/broadcastLinkUtils';
import { FreudConnection } from '../../freudConnection/FreudConnection';
import { SessionClock } from './SessionClock';
import { SessionInfo } from './SessionInfo';
import { SessionName } from './SessionName';
import { VolumeMeter } from './VolumeMeter';

interface GuideHeaderProps {
    session: Session;
    connection: FreudConnection;
    onEndSession: () => void;
}

const useStyles = makeStyles<Theme>(() => ({
    paper: { maxWidth: 398 },
}));

const LongBufferSwitch = React.memo(({ connection }: { connection: FreudConnection }) => {
    const [isLowConnectionModeEnabled, setLowConnectionMode] = useState<boolean>(connection.isLongBufferEnabled);

    const handleToggleLongBuffer = () => {
        const newVal = !isLowConnectionModeEnabled;
        Sentry.configureScope((scope) => {
            scope.setTag('usingSlowConnectionMode', `${newVal}`);
        });

        connection.toggleLongBuffer(newVal);
        setLowConnectionMode(newVal);
    };

    return (
        <div
            onClick={handleToggleLongBuffer}
            style={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
            <Typography variant="body3">I'm on a slow connection</Typography>
            <Checkbox style={{ padding: 0, margin: '0 8px' }} size="small" checked={isLowConnectionModeEnabled} />
        </div>
    ); // tried using <FormGroup/> as in the docs but got some weird double firing of the onChange handler...
});

export const GuideHeader: React.FC<GuideHeaderProps> = React.memo(({ session, connection, onEndSession }) => {
    const history = useHistory();
    const [volume, setVolume] = useState(1);
    const [clientLinkCopied, setClientLinkCopied] = useState(false);
    const [sessionInfoOpen, setSessionInfoOpen] = useState(false);
    const [volumeAnchor, setVolumeAnchor] = useState<Element>();
    const classes = useStyles();
    const { isEnabled } = useFeatures();

    const updateVolume = (newVolume: number) => {
        connection.setVolume(newVolume);
        setVolume(newVolume);
    };

    const copyClientLink = useCallback(() => {
        navigator.clipboard.writeText(getSessionLink(session.broadcastIdentifier));
        setClientLinkCopied(true);
        setTimeout(() => setClientLinkCopied(false), 5000);
    }, [session, isEnabled]);
    const closeSnackbar = useCallback(() => {
        setClientLinkCopied(false);
    }, []);

    const menuActions = [
        {
            element: 'Copy Client Link',
            icon: <LinkIcon />,
            onClick: copyClientLink,
        },
        {
            element: 'Session Info',
            icon: <InfoIcon />,
            onClick: () => setSessionInfoOpen(true),
        },
        {
            element: 'Go Back To Dashboard',
            icon: <ArrowBackIcon />,
            onClick: () => history.push('/'),
        },
        {
            element: 'End Session for All',
            icon: <CloseIcon />,
            onClick: onEndSession,
        },
    ];

    return (
        <Header
            left={<SessionName session={session} />}
            center={<SessionClock connection={connection} />}
            right={
                <div className="tour-sessionOptions" style={{ display: 'flex', flexDirection: 'row' }}>
                    <LongBufferSwitch connection={connection} />
                    <>
                        {connection.isVolumeControllable && isBrowser ? (
                            <>
                                <Button
                                    variant="clear"
                                    size="xs"
                                    onClick={(evt) => setVolumeAnchor(evt.currentTarget)}
                                    icon={<VolumeIcon fontSize="small" />}
                                    aria-label="Volume"
                                />
                                <Popover
                                    open={!!volumeAnchor}
                                    anchorEl={volumeAnchor}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    onClose={() => setVolumeAnchor(undefined)}
                                >
                                    {connection?.audioGain &&
                                    connection.audioAnalyserLeft &&
                                    connection.audioAnalyserRight ? (
                                        <VolumeMeter
                                            analyserLeft={connection.audioAnalyserLeft}
                                            analyserRight={connection.audioAnalyserRight}
                                            volume={volume}
                                            onSetVolume={updateVolume}
                                        />
                                    ) : null}
                                </Popover>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                    <Menu
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        options={menuActions}
                    />
                    <Snackbar
                        type="success"
                        isLongButton={false}
                        message={'Client link copied'}
                        confirmText={'OK'}
                        open={clientLinkCopied}
                        closeSnackbar={closeSnackbar}
                    />
                    <Dialog
                        classes={{ paper: classes.paper }}
                        open={sessionInfoOpen}
                        onClose={() => setSessionInfoOpen(false)}
                    >
                        <DialogContent>
                            <SessionInfo
                                closeDialog={() => setSessionInfoOpen(false)}
                                session={session}
                                connection={connection}
                            />
                        </DialogContent>
                    </Dialog>
                </div>
            }
        />
    );
});
