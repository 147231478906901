import { isNil, isNumber } from 'lodash';
import React, { useEffect } from 'react';
import { ReactElement } from 'react';
import { animated, SpringValue } from 'react-spring';

import { Connection } from '../../../common/hooks/useSessionTick';
import { UseSnackbarReturn } from '../../../common/hooks/useSnackbar';
import { Queueable } from '../actionQueue/useActionQueue';
import { useCEA } from '../ceaButtons/useCEA';
import { DepthStateVisualiser } from './DepthStateVisualiser';
import { mapServerDepthToUIDepth } from './depthUtils';
import IntensitySlider from './IntensitySlider';
import { useDepth } from './useDepth';

export interface IIntensityControllerContainer {
    connection: Connection;
    opacity: SpringValue<number> | number;
    queueFunction: (queueable: Queueable) => void;
    setSnackbarContent: UseSnackbarReturn['setSnackbarContent'];
    sliderVisible: boolean;
}

export const SNACKBAR_ERROR_COPY =
    'You are too close to the end of your current wave to make that change. Please wait or skip to the next wave and then make your adjustment';

export const SNACKBAR_DISABLED_COPY =
    'You cannot change intensity while in this wave. To change intensity, move to a new emotionality, or skip to a generative wave.';

function IntensityController({
    connection,
    opacity,
    queueFunction,
    setSnackbarContent,
    sliderVisible,
    ...props
}: IIntensityControllerContainer): ReactElement | null {
    const depthState = useDepth(connection, queueFunction);
    const depthStateError: string | null | undefined = depthState !== 'loading' ? depthState.error : null;
    const cea = useCEA(connection, queueFunction);

    useEffect(() => {
        if (depthStateError) {
            setSnackbarContent(SNACKBAR_ERROR_COPY);
        }
    }, [depthStateError, setSnackbarContent]);

    if (depthState === 'loading' || cea === 'loading') return null;

    const { targetDepth, currentDepth, transitionTimeSecs, setTargetDepth } = depthState;

    const handleDepthChange = (depth: number) => {
        setTargetDepth && setTargetDepth(depth);
    };

    const handleDisabledClick = () => setSnackbarContent(SNACKBAR_DISABLED_COPY);

    // Don't let an invisible controller capture any pointer events
    const opacityToPointerEvents = (opacity: number) => (opacity == 1 ? 'initial' : 'none');
    const pointerEvents = isNumber(opacity) ? opacityToPointerEvents(opacity) : opacity.to(opacityToPointerEvents);

    return (
        <>
            <DepthStateVisualiser
                // TODO: what if currentDepth is undefined? Can we add a test for that as well?
                currentDepth={mapServerDepthToUIDepth(currentDepth)}
                currentCea={cea.targetCea}
                transitionTimeSecs={transitionTimeSecs}
            />
            {sliderVisible && (
                <animated.div
                    className={'tour-intensityController'}
                    style={
                        {
                            opacity,
                            pointerEvents,
                        } as any
                    }
                >
                    <IntensitySlider
                        setValueRefs={() => {
                            return;
                        }}
                        // TODO: makes types more sensible
                        // replace null with undefined so component library is happy when strict typechecking is enabled
                        targetIntensity={targetDepth ?? undefined}
                        currentIntensity={currentDepth ?? undefined}
                        currentIntensityTransitionTimeSecs={transitionTimeSecs}
                        cea={cea.targetCea}
                        ceaTransitionTimeSecs={cea.transitionTimeSecs}
                        disabled={isNil(targetDepth)}
                        onDepthChange={handleDepthChange}
                        onClickWhenDisabled={handleDisabledClick}
                        {...props}
                    />
                </animated.div>
            )}
        </>
    );
}

export default React.memo(IntensityController);
