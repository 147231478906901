import styled from '@emotion/styled';
import { groupBy, isEmpty, orderBy } from 'lodash';
import React, { PropsWithChildren, ReactElement } from 'react';
import { components, GroupedOptionsType, OptionProps, ValueType } from 'react-select';
import { SessionScoreEmotionalIntensity, SessionScoreTemplate } from 'wavepaths-shared/core';

import { Typography } from '@/component-library';

import Select from '../../common/components/Inputs/Select';
import TherapeuticTemplateInfoDialog from './infodialogs/TherapeuticTemplateInfoDialog';

const OptionTitle = styled.div({
    fontWeight: 600,
});

const OptionDescription = styled.div({
    marginTop: 4,
    color: '#2C3958',
    fontSize: 12,
    lineHeight: 1.5,
});

export interface SessionScoreSelectorProps {
    currentSessionTemplate?: SessionScoreTemplate;
    availableSessionScores: SessionScoreTemplate[];
    onSessionScoreChange: (score: SessionScoreTemplate) => void;
}

type ScoreOptionType = {
    label: string;
    value: SessionScoreTemplate;
};

type CustomOptionType = {
    label: 'Custom';
    value?: undefined;
};

type OptionType = ScoreOptionType | CustomOptionType;

const Option = (props: PropsWithChildren<OptionProps<OptionType>>) => {
    return (
        <components.Option {...props}>
            <OptionTitle>{props.data.value.name}</OptionTitle>
            <OptionDescription>{props.data.value.description}</OptionDescription>
        </components.Option>
    );
};

const EMOTIONALITY_DESCRIPTIONS: { [emotionality in SessionScoreEmotionalIntensity]: string } = {
    [SessionScoreEmotionalIntensity.LOW]: 'Soothe',
    [SessionScoreEmotionalIntensity.MEDIUM]: 'Soothe and Mildly Deepen',
    [SessionScoreEmotionalIntensity.HIGH]: 'Deepen',
    [SessionScoreEmotionalIntensity.ALL]: '',
};

const EMOTIONALITY_LABELS: { [emotionality in SessionScoreEmotionalIntensity]: string } = {
    [SessionScoreEmotionalIntensity.LOW]: 'Low',
    [SessionScoreEmotionalIntensity.MEDIUM]: 'Medium',
    [SessionScoreEmotionalIntensity.HIGH]: 'High',
    [SessionScoreEmotionalIntensity.ALL]: 'High',
};

function SessionScoreSelector({
    currentSessionTemplate,
    availableSessionScores,
    onSessionScoreChange,
}: SessionScoreSelectorProps): ReactElement {
    const orderedScores = orderBy(availableSessionScores, 'name');
    const groupedScores = groupBy(orderedScores, 'selectionCriteria.emotionalIntensity');

    const options: GroupedOptionsType<ScoreOptionType> = (Object.keys(
        SessionScoreEmotionalIntensity,
    ) as (keyof typeof SessionScoreEmotionalIntensity)[])
        .map((emotionality: keyof typeof SessionScoreEmotionalIntensity) => ({
            label: `${EMOTIONALITY_LABELS[SessionScoreEmotionalIntensity[emotionality]]} Intensity: ${
                EMOTIONALITY_DESCRIPTIONS[SessionScoreEmotionalIntensity[emotionality]]
            }`,
            options: groupedScores[SessionScoreEmotionalIntensity[emotionality]]?.map((scoreTemplate) => ({
                label: `${scoreTemplate.name}`,
                value: scoreTemplate,
            })),
        }))
        .filter((option) => !isEmpty(option.options));

    const selectedTemplate =
        (currentSessionTemplate &&
            options
                .find((optionGroup) =>
                    optionGroup.options.some(
                        ({ value: scoreTemplate }) => currentSessionTemplate.name === scoreTemplate.name,
                    ),
                )
                ?.options.find((option) => option.value.name === currentSessionTemplate.name)) ??
        undefined;

    const handleOnChange = (selectedOption: ValueType<OptionType>) => {
        const selected = selectedOption as OptionType;
        if (selected.value) {
            onSessionScoreChange(selected.value);
        }
    };

    const defaultOption: CustomOptionType = { label: 'Custom' };

    return (
        <>
            <label htmlFor={'sessionScoreDropdown'}>
                <Typography variant="body2" component="span">
                    Music Template
                    <TherapeuticTemplateInfoDialog />
                </Typography>
            </label>
            <Select
                name={'Session Score'}
                inputId={'sessionScoreDropdown'}
                value={selectedTemplate ?? defaultOption}
                onChange={handleOnChange}
                components={{ Option }}
                isSearchable={false}
                options={options}
            />
        </>
    );
}

export default SessionScoreSelector;
