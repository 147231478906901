import firebase from 'firebase';
import useSWR from 'swr';

import { getTemplateById, IGetScoreTemplateReturn } from '../api/contentApi';

export interface TemplateDetailParams {
    templateId: string;
}

type IUseScoreTemplateProps = {
    id: string;
    fbUser?: firebase.User;
};

export function useScoreTemplate({ fbUser, id }: IUseScoreTemplateProps) {
    const { data } = useSWR<IGetScoreTemplateReturn, Error>(
        fbUser ? [`/score-template`, id] : null,
        fbUser ? () => getTemplateById({ fbUser, id }) : null,
    );

    return {
        template: data,
    };
}
