import firebase from 'firebase/app';
import { useEffect, useState } from 'react';
import { Session } from 'wavepaths-shared/core';

import { FreudConnection } from '../../freudConnection/FreudConnection';

export type UseFreudConnectionProps = {
    session?: Session;
    firebaseUser?: firebase.User;
};

type UseFreudConnectionReturn = FreudConnection | null;

export const useFreudConnection = ({ session, firebaseUser }: UseFreudConnectionProps): UseFreudConnectionReturn => {
    const [state, setState] = useState<UseFreudConnectionReturn>(null);

    useEffect(() => {
        if (!!session && !!firebaseUser && !state) {
            const conn = new FreudConnection(session, firebaseUser);
            setState(conn);
        }
    }, [session, firebaseUser, state]);

    useEffect(() => {
        if (state) {
            return () => {
                console.log('Closing connection');
                state.removeAllListeners();
                state.close();
            };
        }
    }, [state]);

    return state;
};

export default useFreudConnection;
