import styled from '@emotion/styled';
import React, { ReactElement } from 'react';

import Button from '../Button';
import AnimatedBackground from './AnimatedBackground';

export interface CEAButtonProps {
    transitionTimeSecs: number;
    isActive: boolean;
    colour: string;
    label: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Container = styled.div({
    position: 'relative',
    display: 'inline-grid',
    backgroundColor: 'rgba(255,255,255,0.1)',
    borderRadius: 8,
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
});

const StyledButton = styled(Button)({
    padding: 0,
    background: 'transparent',
    borderRadius: 8,
    border: '1px solid rgba(255,255,255,0.5)',
});

const ButtonContent = styled.div({
    minWidth: 140,
    minHeight: 38,
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    paddingRight: 2,
});

const Icon = styled.div<{ colour?: string }>(({ colour }) => ({
    width: 12,
    height: 12,
    borderRadius: 6,
    border: '1px solid white',
    background: colour,
}));

const Text = styled.div({
    color: '#2C3958',
    textAlign: 'center',
});

function CEAButton({ transitionTimeSecs, isActive, colour, label, onClick, ...props }: CEAButtonProps): ReactElement {
    return (
        <Container
            style={{
                transition: `${transitionTimeSecs}s linear`,
            }}
            {...props}
        >
            <AnimatedBackground isActive={isActive} colour={colour} transitionTimeSecs={transitionTimeSecs} />
            <StyledButton aria-label={isActive ? 'active' : ''} size="m" onClick={onClick}>
                <ButtonContent>
                    <Icon colour={colour} />
                    <Text>{label}</Text>
                </ButtonContent>
            </StyledButton>
        </Container>
    );
}

export default CEAButton;
