import 'react-h5-audio-player/src/styles.scss';
import './SessionAudioPlayer.scss';

import * as Sentry from '@sentry/browser';
import Hls, { ErrorData, Events } from 'hls.js';
import React, { RefObject, useEffect, useImperativeHandle, useRef, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import { useInterval } from 'react-use';
import styled, { css, keyframes } from 'styled-components';

import configs from '../../../configs';
import { drainAndSummarizeNetworkStats, getHlsErrorContext, getHlsEventData } from '../../../freudConnection/hlsUtils';

interface ISessionAudioPlayerProps {
    broadcastIdentifier: string;
    onPlay?: () => void;
    onPause?: () => void;
    onLoad?: () => void;
    volume?: number;
    errorContext?: string;
    onSkipBeyondStreamPosition?: () => void;
}

const pulse = keyframes`
  0% { opacity: 0.6 }
  50% { opacity: 0.3; }
  100% { opacity: 0.6; }
`;

const Container = styled.div<{ loading: boolean }>`
    position: relative;
    ${(props) =>
        props.loading &&
        css`
            animation: ${pulse} 2s infinite;
        `}
`;

const Disabled = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
`;

export interface SessionAudioPlayerControls {
    play: () => void;
    pause: () => void;
    setTime: (time: number) => void;
    getTime: () => number;
    getLiveSyncPositionSecs: () => number;
}

type NetworkStats = {
    trequest: number;
    tload: number;
    bwEstimate: number;
};

const NETWORK_STATS_REPORT_INTERVAL = 5 * 60 * 1000;

const reportNetworkStats = (networkStats: NetworkStats[], broadcastId: string) => {
    if (networkStats.length === 0) return;
    const stats = drainAndSummarizeNetworkStats(networkStats);
    Sentry.withScope((scope) => {
        scope.setExtra('broadcastIdentifier', broadcastId);
        scope.setExtra('streamingStats', stats);
        Sentry.captureMessage('Streaming stats');
    });
};

export const SessionAudioPlayer = React.forwardRef(
    (
        {
            broadcastIdentifier,
            volume = 1,
            onPlay,
            onPause,
            onLoad,
            errorContext,
            onSkipBeyondStreamPosition,
        }: ISessionAudioPlayerProps,
        ref,
    ) => {
        const streamUrl = `${configs.freud.STREAM_BASE}/${broadcastIdentifier}/stream.m3u8`;
        const elRef = useRef<AudioPlayer>();
        const startPosition = 0;
        const hlsRef = useRef<Hls>();
        const [networkStats, setNetworkStats] = useState<NetworkStats[]>([]);

        useInterval(() => {
            reportNetworkStats(networkStats, broadcastIdentifier);
            setNetworkStats([]);
        }, NETWORK_STATS_REPORT_INTERVAL);

        useImperativeHandle(
            ref,
            () => ({
                play: () => {
                    if (!elRef?.current?.audio.current) return;
                    elRef.current.audio.current.play();
                },
                pause: () => {
                    if (!elRef?.current?.audio.current) return;
                    elRef.current.audio.current.pause();
                },
                setTime: (time: number) => {
                    if (!elRef?.current?.audio.current) return;

                    if (time > (hlsRef.current?.liveSyncPosition ?? time))
                        onSkipBeyondStreamPosition && onSkipBeyondStreamPosition();

                    const timeToSkipTo = Math.min(time, hlsRef.current?.liveSyncPosition ?? time);
                    elRef.current.audio.current.currentTime = timeToSkipTo;
                    elRef.current.audio.current.play();
                },
                getTime: () => {
                    return elRef?.current?.audio?.current?.currentTime;
                },
                getLiveSyncPositionSecs: () => {
                    return hlsRef.current?.liveSyncPosition;
                },
            }),
            [],
        );

        useEffect(() => {
            if (!elRef?.current?.audio.current) return;
            elRef.current.audio.current.volume = volume;
        }, [volume]);

        const [loading, setLoading] = useState(true);

        const errorReportContext = errorContext + ` Audio Player`;

        useEffect(() => {
            if (elRef?.current?.audio.current) {
                elRef.current.audio.current.crossOrigin = 'anonymous';

                if (Hls.isSupported()) {
                    const hls = new Hls({
                        liveSyncDurationCount: 3,
                        initialLiveManifestSize: 3,
                        lowLatencyMode: false,
                        maxBufferSize: 1000 * 1000,
                        startPosition,
                    });
                    hls.loadSource(streamUrl);
                    hls.attachMedia(elRef.current?.audio.current);

                    hlsRef.current = hls;

                    const retryManifestLoad = (event: 'hlsError', data: ErrorData) => {
                        if (data.details === Hls.ErrorDetails.MANIFEST_LOAD_ERROR) {
                            console.log('HLS Manifest not available, retrying in 1s');
                            setTimeout(() => hls.loadSource(streamUrl), 1000);
                        }
                    };
                    hls.on(Hls.Events.ERROR, retryManifestLoad);
                    hls.on(Hls.Events.FRAG_BUFFERED, (e, data: any) => {
                        setNetworkStats([
                            ...networkStats,
                            {
                                trequest: data.frag.stats.loading.start,
                                tload: data.frag.stats.buffering.end,
                                bwEstimate: data.frag.stats.bwEstimate,
                            },
                        ]);
                    });
                    hls.on(Hls.Events.MANIFEST_PARSED, () => {
                        setLoading(false);
                        onLoad && onLoad();
                        console.log('HLS Manifest loaded');
                        hls.off(Hls.Events.ERROR, retryManifestLoad);
                        Object.values(Hls.Events).forEach((e) => {
                            hls.on(e, (event: Events, data: any) => {
                                const evtData = getHlsEventData(event, data);
                                if (evtData) {
                                    Sentry.addBreadcrumb({
                                        category: 'streaming',
                                        data: evtData,
                                    });
                                }
                            });
                        });
                        hls.on(Hls.Events.ERROR, (event, data) => {
                            if (data.fatal) {
                                switch (data.type) {
                                    case Hls.ErrorTypes.NETWORK_ERROR:
                                        console.error('fatal network error encountered, try to recover', data);
                                        Sentry.withScope((scope) => {
                                            scope.setExtra('broadcastIdentifier', broadcastIdentifier);
                                            scope.setExtra('context', getHlsErrorContext(data));
                                            Sentry.captureMessage(`Streaming network error in ${errorReportContext}`);
                                        });
                                        hls.startLoad(startPosition);
                                        break;
                                    case Hls.ErrorTypes.MEDIA_ERROR:
                                        console.error('fatal media error encountered, try to recover', data);
                                        Sentry.withScope((scope) => {
                                            scope.setExtra('broadcastIdentifier', broadcastIdentifier);
                                            scope.setExtra('context', getHlsErrorContext(data));
                                            Sentry.captureMessage(`Streaming media error in ${errorReportContext}`);
                                        });
                                        hls.recoverMediaError();
                                        break;
                                    default:
                                        console.error('fatal streaming error', data);
                                        Sentry.withScope((scope) => {
                                            scope.setExtra('broadcastIdentifier', broadcastIdentifier);
                                            scope.setExtra('context', getHlsErrorContext(data));
                                            Sentry.captureMessage(`Streaming error in ${errorReportContext}`);
                                        });
                                        hls.destroy();
                                        break;
                                }
                            } else if (
                                data.type === Hls.ErrorTypes.MEDIA_ERROR &&
                                data.details === 'bufferStalledError'
                            ) {
                                Sentry.withScope((scope) => {
                                    scope.setExtra('broadcastIdentifier', broadcastIdentifier);
                                    scope.setExtra('context', getHlsErrorContext(data));
                                    Sentry.captureMessage(`Streaming network glitch in ${errorReportContext}`);
                                });
                            }
                        });
                    });
                    return () => {
                        hls.destroy();
                        hlsRef.current = undefined;
                    };
                } else if (elRef.current.audio.current.canPlayType('application/vnd.apple.mpegurl')) {
                    elRef.current.audio.current.src = streamUrl;
                    elRef.current.audio.current.currentTime = 0;
                }
            }
        }, [streamUrl]);

        return (
            <Container loading={loading} className="audioFilePlayer" data-testid="player">
                <AudioPlayer
                    ref={elRef as RefObject<AudioPlayer>}
                    layout="horizontal-reverse"
                    showJumpControls={false}
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    onPlay={onPlay}
                    onPause={onPause}
                />
                {/* CY 01/23: Ideally the Audio player would have a 'disabled' prop, but thats not the case... */}
                {loading && <Disabled />}
            </Container>
        );
    },
);
