import { styled } from '@material-ui/core/styles';

import { Button } from '@/component-library';

const IconButton = styled(Button)({
    padding: '7px',
    color: 'transparent',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    width: '36px',
    maxWidth: '36px',
    minWidth: '36px',
    height: '36px',
    borderRadius: '50px',
});

export default IconButton;
