const SAMPLING_INTERVAL_MS = 100;
const SILENCE_INTERVAL_MS = 5000;

type AnalysisSource = {
    getByteTimeDomainData: (to: Uint8Array) => void;
};

export class FreudStreamSilenceDetector {
    private sampleHistory = new Float32Array((SILENCE_INTERVAL_MS / SAMPLING_INTERVAL_MS) * 2);
    private sampleHistoryIndex = 0;
    private running = false;
    private timeDomainDataArray: Uint8Array;

    constructor(private analyserLeft: AnalysisSource, private analyserRight: AnalysisSource, fftSize: number) {
        this.timeDomainDataArray = new Uint8Array(fftSize);
    }

    start() {
        this.running = true;
        this.sample();
    }

    stop() {
        this.running = false;
    }

    isSilent() {
        for (let i = 0; i < this.sampleHistory.length; i++) {
            const sampleSilent = this.sampleHistory[i] > -0.01 && this.sampleHistory[i] < 0.01;
            if (!sampleSilent) {
                return false;
            }
        }
        return true;
    }

    private sample() {
        if (!this.running) return;

        this.analyserLeft.getByteTimeDomainData(this.timeDomainDataArray);
        const leftSample = this.timeDomainDataArray[0] / 128 - 1.0;
        this.analyserRight.getByteTimeDomainData(this.timeDomainDataArray);
        const rightSample = this.timeDomainDataArray[0] / 128 - 1.0;

        this.sampleHistory[this.sampleHistoryIndex] = leftSample;
        this.sampleHistory[this.sampleHistoryIndex + 1] = rightSample;
        this.sampleHistoryIndex += 2;
        this.sampleHistoryIndex %= this.sampleHistory.length;

        setTimeout(() => {
            this.sample();
        }, SAMPLING_INTERVAL_MS);
    }
}
