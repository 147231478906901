import { useStripe } from '@stripe/react-stripe-js';
import { useContext, useState } from 'react';

import useMySubscription from '@/hooks/useMySubscription';

import { AuthContext } from '../../auth';
import { getTrialPeriod } from '../../common/domain/subscriptions';
import { createCheckoutSession } from '../../common/util/stripeUtil';

function useStripeCheckout(): {
    isSubscribing: boolean;
    error: string | undefined;
    handleSubscribe: (priceID: string) => Promise<void>;
    dismissError: () => void;
} {
    const { firebaseUser, userData } = useContext(AuthContext);

    const { subscription } = useMySubscription();

    const stripe = useStripe();
    const [isSubscribing, setIsSubscribing] = useState(false);
    const [error, setSubscribingError] = useState<string | undefined>(undefined);
    const trialPeriod = getTrialPeriod(subscription);

    const handleSubscribe = async (priceId: string) => {
        if (stripe && firebaseUser && userData) {
            setIsSubscribing(true);
            try {
                const response = await createCheckoutSession({
                    priceId,
                    customerId: userData.customerId,
                    uid: firebaseUser.uid,
                    email: firebaseUser.email!,
                    ...(trialPeriod && { trialPeriodDays: trialPeriod }),
                });

                if (response?.sessionId) {
                    await stripe.redirectToCheckout({ sessionId: response?.sessionId });
                } else {
                    setSubscribingError("We're having trouble connecting to our payment provider, please try again.");
                }
            } catch (e) {
                setSubscribingError("We're having trouble connecting to our payment provider, please try again.");
                console.log(e);
            }
            setIsSubscribing(false);
        }
    };

    return { isSubscribing, error, dismissError: () => setSubscribingError(undefined), handleSubscribe };
}

export default useStripeCheckout;
