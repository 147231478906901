export function analyseVolume(analyser: AnalyserNode, array: Uint8Array, fftSize: number): number {
    analyser.getByteTimeDomainData(array);
    let total = 0;
    for (let i = 0; i < fftSize; i++) {
        const float = array[i] / 0x80 - 1;
        total += float * float;
    }
    const rms = Math.sqrt(total / fftSize);
    let db = 20 * (Math.log(rms) / Math.log(10));
    db = Math.max(-48, Math.min(db, 0));
    return 1 + db * 0.02083;
}
