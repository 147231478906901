import { styled } from '@material-ui/core/styles';
import React, { FC, useState } from 'react';

import { Button, Typography } from '@/component-library';

import HelpDialog from './HelpDialog';

const HelpButtonBase = styled(Button)({
    position: 'fixed',
    bottom: 20,
    right: 20,
    zIndex: 100,
    boxShadow: '0px 2px 8px rgba(0,14,51,0.4)',
});

const HelpIconLabel = styled(Typography)({
    color: 'white',
    paddingInlineStart: '.3em',
});

// TODO: replace the design once we can access shared components
// If we use a labelled button, we'll probably need to add a new variant
// (secondary/warning/important/danger or a way of overriding the main accent
// colour)
const HelpButton: FC = () => {
    // const { show: handleClick } = useIntercom();
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <>
            <HelpButtonBase variant="solid-blue" size="m" icon={<>🛟</>} onClick={() => setOpenDialog(true)}>
                <HelpIconLabel variant="button">Help</HelpIconLabel>
            </HelpButtonBase>
            <HelpDialog open={openDialog} onClose={() => setOpenDialog(false)} />
        </>
    );
};

export default HelpButton;
