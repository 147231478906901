import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React, { ReactElement } from 'react';
import { CoreEmotionalAtmosphere } from 'wavepaths-shared/core';

import { CEAButton } from '@/component-library';

import { getCEAColour } from '../../../common/util/ceaColours';

export const SupportedCEAs = [
    CoreEmotionalAtmosphere.STILLNESS,
    CoreEmotionalAtmosphere.VITALITY,
    CoreEmotionalAtmosphere.BITTERSWEET,
    CoreEmotionalAtmosphere.TENSION,
];
export interface ICeaSelectorProps {
    targetCea?: CoreEmotionalAtmosphere;
    transitionTimeSecs?: number;
    onChange: (cea: CoreEmotionalAtmosphere) => void;
    disabled?: boolean;
}

const useStyles = makeStyles({
    container: {
        width: 650,
        display: 'grid',
        gridAutoFlow: 'column',
        gridTemplateColumns: 'repeat(4, 150px)',
        justifyContent: 'space-between',
    },
});

function CeaSelector({
    targetCea,
    transitionTimeSecs = 45,
    onChange,
    disabled = false,
}: ICeaSelectorProps): ReactElement {
    const classes = useStyles();
    return (
        <div className={cn(classes.container, 'tour-ceaController')}>
            {SupportedCEAs.map((cea) => (
                <CEAButton
                    key={cea}
                    aria-label={`${cea}-button`}
                    colour={getCEAColour(cea)}
                    label={cea}
                    transitionTimeSecs={transitionTimeSecs}
                    isActive={cea === targetCea}
                    onClick={() => !disabled && onChange(cea)}
                />
            ))}
        </div>
    );
}

export default CeaSelector;
