import { Box } from '@material-ui/core';
import * as eva from 'eva-icons';
import { lowerCase, startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ActiveLayerDetails, LAYER_DESCRIPTIONS_ABV, LayerNumber } from 'wavepaths-shared/core';

import { Button, Typography } from '@/component-library';

import Checklist, { Selectable } from './Checklist';
import { filterToRefreshableLayers } from './filterToRefreshableLayers';

const presentLayer = (layer: ActiveLayerDetails): string =>
    startCase(layer.instrument) + ' (' + lowerCase(LAYER_DESCRIPTIONS_ABV[layer.layer]) + ') by ' + layer.composer;

const DEEPEN_LAYERS: LayerNumber[] = [8, 9, 10, 16];

export const Instruments = ({
    onClose,
    onRefresh,
    layers,
}: {
    onClose: () => void;
    onRefresh: (layerNumbers: LayerNumber[]) => void;
    layers: ActiveLayerDetails[];
}): React.ReactElement => {
    useEffect(() => eva.replace(), []);

    const instruments = filterToRefreshableLayers(layers);
    const [selections, setSelections] = useState<LayerNumber[]>([]);
    const [showError, setShowError] = useState<boolean>(false);

    const handleSelectionChange = (layer: LayerNumber): void => {
        setShowError(false);
        const isDeselecting = selections.includes(layer);
        const isDeepenLayer = DEEPEN_LAYERS.includes(layer);
        const targets = isDeepenLayer ? DEEPEN_LAYERS : [layer];
        const update = isDeselecting ? selections.filter((x) => !targets.includes(x)) : selections.concat(targets);
        setSelections(update);
    };

    const submitDisabled = selections.length === 0;

    const handleRefreshClick = () => {
        if (submitDisabled) {
            setShowError(true);
        } else {
            onRefresh(selections);
            onClose();
        }
    };

    const isSwitchingEnabledForLayer = (layer: LayerNumber): boolean => {
        return !DEEPEN_LAYERS.includes(layer);
    };

    return (
        <Box zIndex={5} padding={3} width="400px" maxWidth="400px">
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                <Typography variant="h6">Instruments &amp; Sounds</Typography>
                <Button
                    variant="clear"
                    size="xs"
                    onClick={onClose}
                    icon={
                        <i data-eva="close-outline" data-eva-fill="inherit" data-eva-height="24" data-eva-width="24" />
                    }
                    aria-label="Close Refresh Instruments popover"
                />
            </Box>
            <Typography color="secondary" variant="body2">
                Replace unwanted sounds or instruments
            </Typography>
            <Checklist
                handleSelectionChange={handleSelectionChange}
                isSelected={(e: Selectable<LayerNumber>) => selections.includes(e.id)}
                isDisabled={(e: Selectable<LayerNumber>) => !isSwitchingEnabledForLayer(e.id)}
                elements={instruments.map((i) => ({ id: i.layer as LayerNumber, name: presentLayer(i) }))}
            />
            <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={1}>
                <div style={{ maxWidth: '250px' }}>
                    {showError && (
                        <Typography color="error" variant="body2">
                            Please select a sound or instrument to replace
                        </Typography>
                    )}
                </div>

                <Button
                    variant="solid-dark"
                    size="s"
                    onClick={handleRefreshClick}
                    icon={
                        <Box pr={0.5} display="flex" alignItems="center">
                            <i
                                data-eva="flip-2-outline"
                                data-eva-fill={'currentColor'}
                                data-eva-height="16"
                                data-eva-width="16"
                            />
                        </Box>
                    }
                    aria-label="Replace The Selected Instruments"
                >
                    Replace
                </Button>
            </Box>
        </Box>
    );
};
