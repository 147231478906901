import React from 'react';

import { Typography } from '@/component-library';

import InfoButtonAndDialog from './InfoButtonAndDialog';

const ModalityInfoDialog: React.FC = () => (
    <InfoButtonAndDialog title="Modality">
        <Typography variant="body2" color="textSecondary">
            Here you select the type of modality you work with in this session.
        </Typography>
        <Typography variant="body2" color="textSecondary">
            This selection will provide a set of templates to choose from, and form the timeline and pharmacokinetic
            phases of the session.
        </Typography>
    </InfoButtonAndDialog>
);

export default ModalityInfoDialog;
