import React from 'react';
import { useHistory } from 'react-router-dom';

import LoadingCardRow from '@/component-library/components/LoadingTemplateCardRow';
import PageHeading from '@/component-library/components/PageHeading';
import TemplateCard from '@/component-library/components/TemplateCard';
import TemplateGrid from '@/component-library/components/TemplateGrid';
import useSavedTemplates from '@/hooks/useSavedTemplates';

import { useAuthContext } from '../../../auth';
import { ListScoreTemplatesItem } from '../../../common/api/contentApi';
import { LayoutContainer } from '../../../LayoutContainer';

type SavedTemplatesProps = {
    templates?: ListScoreTemplatesItem[];
};

const SavedTemplates = ({ templates }: SavedTemplatesProps) => {
    const history = useHistory();

    return (
        <TemplateGrid>
            {templates ? (
                templates.map((t) => (
                    <TemplateCard
                        key={'template-' + t.id}
                        onCardClick={() => {
                            history.push({
                                pathname: '/templates/saved/' + t.id,
                            });
                        }}
                        title={t.name}
                        subtitle={t.subtitle}
                        intensity={t.intensity}
                        minDurationMins={Math.ceil(t.durationMins.min)}
                        maxDurationMins={Math.floor(t.durationMins.max)}
                        emotionalities={t.emotionalities}
                        id={t.id}
                    ></TemplateCard>
                ))
            ) : (
                <LoadingCardRow columns={4} />
            )}
        </TemplateGrid>
    );
};

function SavedTemplatesContainer() {
    const { firebaseUser } = useAuthContext();

    const { savedTemplates } = useSavedTemplates({ fbUser: firebaseUser });

    return (
        <>
            <PageHeading text={'Saved Templates'} />
            <SavedTemplates templates={savedTemplates} />
        </>
    );
}

function SavedTemplatesWithNav() {
    return (
        <LayoutContainer>
            <SavedTemplatesContainer />
        </LayoutContainer>
    );
}

export default SavedTemplatesWithNav;
