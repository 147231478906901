import { makeStyles, Theme } from '@material-ui/core/styles';
import cn from 'classnames';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { RequestType, SessionFeedback } from 'wavepaths-shared/core';
import { millisecondsToMinutes } from 'wavepaths-shared/util/dateUtils';

import { AnimatedLoaderIcon, Dialog, Typography, Warning } from '@/component-library';

import { AuthContext } from '../../auth';
import * as sessionApi from '../../common/api/sessionApi';
import LoadingOrb from '../../common/components/LoadingOrb';
import Snackbar from '../../common/components/Snackbar';
import { isSessionCancelled } from '../../common/domain/session';
import { useMonitoringForSessionInit } from '../../common/hooks/useMonitoringForSessionInit';
import { useSessionState, useSessionTick } from '../../common/hooks/useSessionTick';
import { useSnackbar } from '../../common/hooks/useSnackbar';
import { FreudConnection } from '../../freudConnection/FreudConnection';
import UserEvents from '../../UserEvents';
import QueuedItem from './actionQueue/QueuedItem';
import { useActionQueue } from './actionQueue/useActionQueue';
import { AutoGuide } from './autoGuide/AutoGuide';
import useSession from './autoGuide/useSession';
import { useSessionScore } from './autoGuide/useSessionScore';
import { EnableAudioDialog } from './EnableAudioDialog';
import { GuideEndOfSessionFeedbackContainer } from './GuideEndOfSessionFeedback';
import { GuideHeader } from './GuideHeader';
import useAudioState from './useAudioState';
import useIsNetworkSlow from './useIsNetworkSlow';
import useIsOnline from './useIsOnline';
// TODO: the two useState hooks could maybe be one big hook or these could be named more specifically
import useSessionState2 from './useSessionState';

const useStyles = makeStyles<Theme>({
    snackbar: {
        position: 'absolute',
    },
    warning: {
        position: 'absolute',
    },
    guide: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateRows: 'auto 1fr',
    },
    main: {
        position: 'relative',
        display: 'grid',
        justifyContent: 'stretch',
        alignContent: 'stretch',
    },
});

export interface GuideParams {
    sessionId: string;
}

export const USER_OFFLINE_LABEL = 'You are offline. Please reconnect.';
export const NETWORK_SLOW_LABEL = 'Your internet connection appears to be unstable or too slow.';

export const Guide: FC<{
    connection: FreudConnection;
}> = ({ connection }) => {
    const { sessionId } = useParams<GuideParams>();

    const authCtx = useContext(AuthContext);
    const history = useHistory();

    const audioState = useAudioState({ connection });

    const tick = useSessionTick(connection);
    const [eventTrackingDone, setEventTrackingDone] = useState(false);
    const [showConfirmEndSession, setShowConfirmEndSession] = useState(false);
    const [endOfSessionFeedbackAcknowledged, setEndOfSessionFeedbackAcknowledged] = useState(false);
    const { content: snackbarContent, setSnackbarContent, closeSnackbar } = useSnackbar();
    const [warningContent, setWarningContent] = useState<string | undefined>(undefined);

    const isOnline = useIsOnline();
    const isNetworkSlow = useIsNetworkSlow(connection);

    useEffect(() => {
        if (isOnline === false) {
            setWarningContent(USER_OFFLINE_LABEL);
        } else if (warningContent === USER_OFFLINE_LABEL) {
            setWarningContent(undefined);
        }
    }, [isOnline, setWarningContent, warningContent]);

    useEffect(() => {
        if (isNetworkSlow) {
            setWarningContent(NETWORK_SLOW_LABEL);
        } else if (warningContent === NETWORK_SLOW_LABEL) {
            setWarningContent(undefined);
        }
    }, [isNetworkSlow, setWarningContent]);

    const sessionState = useSessionState2({ connection });
    const classes = useStyles();

    const { queuedFunction, onCancel: onCancelQueuedFunction, onSkipQueue, queueFunction } = useActionQueue();

    const { session, error: sessionError, startSession } = useSession(sessionId, connection);
    const sessionScoreState = useSessionScore(sessionId, connection, queueFunction);

    const timeElapsed = tick
        ? Math.max(0, Math.min(tick.sessionDuration + tick.postludeDuration, tick.absoluteTime))
        : null;

    const sessionDuration = tick?.sessionDuration;

    //@ts-ignore
    const { inPostlude: sessionInPostlude, ended: sessionEnded, initialised: sessionInitialised } = useSessionState(
        connection,
    );
    useMonitoringForSessionInit(session, sessionInitialised);

    useEffect(() => {
        if ((session && session.endedAt) || sessionError) {
            history.push('/');
        }
    }, [history, session, sessionError]);

    const recordSessionFeedback = useCallback(
        (feedback: SessionFeedback) => {
            connection?.sendRequest({
                type: RequestType.RecordFeedback,
                feedback,
            });
        },
        [connection],
    );

    const timeElapsedInMinutes = timeElapsed ? Math.round(millisecondsToMinutes(timeElapsed)) : null;
    const sessionDurationInMinutes = sessionDuration ? Math.round(millisecondsToMinutes(sessionDuration)) : undefined;
    const trackEndedSession = useCallback(
        ({
            overwriteAsCompleted,
            sessionCompletness,
        }: {
            overwriteAsCompleted?: boolean;
            sessionCompletness?: number;
        }) => {
            if (eventTrackingDone || !session) return;
            setEventTrackingDone(true);
            if (isSessionCancelled(timeElapsedInMinutes, sessionDurationInMinutes) && !overwriteAsCompleted) {
                UserEvents.sessionCancelled(session, { sessionCompletness });
            } else {
                UserEvents.sessionCompleted(session);
            }
        },
        [eventTrackingDone, session, sessionDurationInMinutes, timeElapsedInMinutes],
    );

    const sessionCompletness =
        sessionDuration && timeElapsed ? Math.round((100 * timeElapsed) / sessionDuration) : undefined;

    const stopSession = useCallback(() => {
        if (!!session) {
            /* this session score truncation should happen in the BE, 
            i.e. create request handler in request.ts that updates the session score when it receives a stopSession event, 
            which is emitted in deleteSession.ts */
            onSkipWave(session.score.wavepaths.length - 1); // this is ugly AF. This is essentially saying: skip to postlude
            connection?.onStoppingSession();
            if (!session || !authCtx.firebaseUser) return;
            trackEndedSession({ sessionCompletness });
            sessionApi.deleteSession(session.id, connection?.bufferLatency, authCtx.firebaseUser);
        }
    }, [connection, session, authCtx.firebaseUser, trackEndedSession, sessionCompletness]);

    const endSession = useCallback(
        (doConfirm: boolean) => {
            if (!doConfirm) {
                stopSession();
            } else {
                setShowConfirmEndSession(true);
            }
        },
        [connection, session, stopSession],
    );
    const endSessionWithConfirm = useCallback(() => {
        const needToConfirm = !sessionInPostlude && !sessionEnded;
        endSession(needToConfirm);
    }, [endSession, sessionInPostlude, sessionEnded]);

    const endSessionFromConfirm = () => {
        setShowConfirmEndSession(false);
        stopSession();
    };

    useEffect(() => {
        if (!authCtx.firebaseUser && sessionState) {
            stopSession();
        }
    }, [stopSession, sessionState, authCtx]);

    useEffect(() => {
        if (sessionEnded) {
            connection?.close();
            if (endOfSessionFeedbackAcknowledged) {
                history.push('/');
            }
        }
    }, [sessionEnded, endOfSessionFeedbackAcknowledged, connection, history]);

    const sessionScore = sessionScoreState === 'loading' ? null : sessionScoreState.sessionScore;

    // TOOD: delete when we've refactored stopSession();
    const onSkipWave = useCallback(
        (waveIndex: number): void => {
            connection?.sendRequest({
                type: RequestType.SkipWave,
                index: waveIndex,
            });
            UserEvents.waveSkipped({ waveIndex });
        },
        [connection],
    );
    return (
        <div className={cn(classes.guide, 'tour-guideBody')}>
            <EnableAudioDialog audioState={audioState} connection={connection} />
            <Dialog
                fullWidth={true}
                open={showConfirmEndSession}
                message={'Are you sure you want to end this session?'}
                onClose={() => setShowConfirmEndSession(false)}
                onConfirm={endSessionFromConfirm}
                confirmText={'End Session'}
            />
            {sessionState && sessionScoreState !== 'loading' && connection && session ? (
                <>
                    <GuideHeader session={session} connection={connection} onEndSession={endSessionWithConfirm} />
                    <main className={classes.main}>
                        {sessionScore && (
                            <AutoGuide
                                session={session}
                                log={sessionState.log}
                                variables={session.variableInputs}
                                sessionScoreState={sessionScoreState}
                                score={sessionScore}
                                connection={connection}
                                audioState={audioState}
                                onStartSession={startSession}
                                onEndSession={stopSession}
                                snackbarContent={snackbarContent}
                                setSnackbarContent={setSnackbarContent}
                                queueFunction={queueFunction}
                                onRecordFeedback={recordSessionFeedback}
                                sessionPlan={sessionScoreState.sessionScore.wavepaths}
                            />
                        )}
                    </main>
                    <QueuedItem
                        queuedFunction={queuedFunction}
                        onCancel={onCancelQueuedFunction}
                        onSkipQueue={onSkipQueue}
                    />
                    {/* TODO: Move snackbar to upmost parent component and create context and hook for it,
                       so it can be called from anywhere in the app without prop drilling */}
                    <Snackbar
                        type="warning"
                        isLongButton={false}
                        message={snackbarContent}
                        confirmText={'OK'}
                        open={snackbarContent !== null}
                        closeSnackbar={closeSnackbar}
                        className={classes.snackbar}
                    />
                    <Warning
                        icon={<AnimatedLoaderIcon />}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        text={warningContent}
                        open={!!warningContent}
                        onDismiss={() => setWarningContent(undefined)}
                    />
                </>
            ) : (
                <LoadingOrb>
                    <Typography variant={'body3'}>
                        Your session is being prepared. Please wait on this page; it will load as soon as it is ready.
                    </Typography>
                    <br />
                    <Typography variant={'body3'}>
                        If you are concerned about the loading time, please email us at{' '}
                        <a href="mailto:support@wavepaths.com">support@wavepaths.com</a>
                    </Typography>
                </LoadingOrb>
            )}

            {connection && sessionScoreState !== 'loading' && (
                <>
                    {authCtx.firebaseUser && (
                        <GuideEndOfSessionFeedbackContainer
                            sessionId={sessionId}
                            currentUser={authCtx.firebaseUser}
                            trackEndedSession={trackEndedSession}
                            connection={connection}
                            sessionPlan={sessionScoreState.sessionScore.wavepaths}
                            onAcknowledged={() => setEndOfSessionFeedbackAcknowledged(true)}
                            onEndSession={() => endSession(false)}
                        />
                    )}
                </>
            )}
        </div>
    );
};
