import React from 'react';

import { Typography } from '@/component-library';

import InfoButtonAndDialog from './InfoButtonAndDialog';

const SessionDurationInfoDialog: React.FC = () => (
    <InfoButtonAndDialog title="Session Duration">
        <Typography variant="body2" color="textSecondary">
            The system will create a session of a certain length based on your prior choices. You can use this slider to
            adjust the total session time as required.
        </Typography>
    </InfoButtonAndDialog>
);

export default SessionDurationInfoDialog;
