import React from 'react';
import { ValueType } from 'react-select';
import { SessionScoreSessionUse } from 'wavepaths-shared/core';

import { Typography } from '@/component-library';

import Select from '../../common/components/Inputs/Select';
import { Feature } from '../../common/features/Feature';
import { Features } from '../../common/features/features.local';
import SessionUseInfoDialog from './infodialogs/SessionUseInfoDialog';
import { VariablesGroup, VariablesGroupInput } from './VariablesGroup';

type SessionUseOption = {
    label: string;
    value: SessionScoreSessionUse;
};

export const SessionUseLabels: [any, string][] = [
    [SessionScoreSessionUse.TESTING, 'Testing (no client)'],
    [SessionScoreSessionUse.IN_PERSON, 'In-person client'],
    [SessionScoreSessionUse.REMOTE_CLIENT, 'Remote client'],
    [SessionScoreSessionUse.REMOTE_GROUP, 'Remote group'],
    [SessionScoreSessionUse.OTHER, 'Other'],
];

type VoiceoverOption = {
    label: string;
    value: string;
};

const voiceoverOptions: VoiceoverOption[] = [
    {
        label: 'None',
        value: 'none',
    },
    {
        label: 'Josie Taylor Long (en-GB)',
        value: 'josie-taylor',
    },
    {
        label: 'Josie Taylor Short 01 (en-GB)',
        value: 'josie-taylor-short-01',
    },
    {
        label: 'Josie Taylor Short 02 (en-GB)',
        value: 'josie-taylor-short-02',
    },
];

interface SessionInputsProps {
    sessionUse: SessionScoreSessionUse | undefined;
    onSessionUseChanged: (sessionUse: SessionScoreSessionUse) => void;
    voiceover: string | undefined;
    onVoiceoverChanged: (voiceover: string) => void;
}

export function SessionInputs({
    sessionUse,
    onSessionUseChanged,
    voiceover,
    onVoiceoverChanged,
}: SessionInputsProps): JSX.Element {
    const sessionUseOptions = SessionUseLabels.map(([value, label]) => ({ label, value }));
    const selectedSessionUse = sessionUseOptions.find((option) => option.value === sessionUse);
    const selectedVoiceover = voiceoverOptions.find((option) => option.value === voiceover);

    return (
        <VariablesGroup classNames="tour-sessionUseInput">
            <VariablesGroupInput>
                <label htmlFor="sessionUseInput" className="requiredFieldLabel">
                    <Typography variant="body2" component="span">
                        Session Use
                        <SessionUseInfoDialog />
                    </Typography>
                </label>
                <Select
                    name="Session Use"
                    aria-label="Session Use"
                    id="sessionUseInput"
                    options={sessionUseOptions}
                    isSearchable={false}
                    value={selectedSessionUse}
                    onChange={(selected: ValueType<SessionUseOption>) => {
                        const { value } = selected as SessionUseOption;
                        onSessionUseChanged(+value);
                    }}
                />
            </VariablesGroupInput>
            <Feature
                name={Features.GUIDED_ONE_ON_ONE}
                activeComponent={
                    <VariablesGroupInput>
                        <label htmlFor="voiceoverInput">
                            <Typography variant="body2" component="span">
                                Guide Voiceover
                            </Typography>
                        </label>
                        <Select
                            name="Voiceover"
                            id={'voiceoverInput'}
                            value={selectedVoiceover}
                            disabled={false}
                            title={'voiceover'}
                            onChange={(selection: ValueType<VoiceoverOption>) => {
                                onVoiceoverChanged((selection as VoiceoverOption).value);
                            }}
                            isSearchable={false}
                            isClearable={false}
                            options={voiceoverOptions}
                        />
                    </VariablesGroupInput>
                }
            />
        </VariablesGroup>
    );
}
