import '../../../Guide.scss';

import React, { useState } from 'react';
import { useParams } from 'react-router';

import { useAuthContext } from '../../../auth';
import LoadingOrb from '../../../common/components/LoadingOrb';
import { FreudConnection } from '../../../freudConnection/FreudConnection';
import useSession from '../autoGuide/useSession';
import { PrecomposedGuide } from './PrecomposedGuide';

export interface PrecomposedGuideParams {
    sessionId: string;
}

export const PrecomposedGuideContainer: React.FC = () => {
    const { sessionId } = useParams<PrecomposedGuideParams>();
    const { firebaseUser } = useAuthContext();
    const [connection] = useState<FreudConnection | null>(null);

    const { session } = useSession(sessionId, connection);
    // hacky work around for circular dependency between useSession and useFreudConnection
    return session && firebaseUser ? (
        <PrecomposedGuide session={session} firebaseUser={firebaseUser} />
    ) : (
        // TODO: why does it have a different background color until it switches over to the guidewithconnection loader and
        <LoadingOrb />
    );
};
