import configs from '../../configs';

export function getSessionLink(broadcastIdentifier: string): string {
    const baseURL = configs.playerWebsite.BASE_URL;

    if (!baseURL) throw new Error('Missing baseURL.');

    const baseURLFormatted = baseURL.endsWith('/') ? baseURL : baseURL + '/';

    return `${baseURLFormatted}session?session=${broadcastIdentifier}`;
}
