import React from 'react';
// import { Typography } from '@/component-library';
// import InfoButtonAndDialog from './InfoButtonAndDialog';

const SessionUseInfoDialog: React.FC = () => (
    /* TODO: Awaiting copy from Mendel https://docs.google.com/document/d/1Oo_Sh1ZF_tLMBYXNurvK7KuvOS5ITZxoi8HfulpYdYE/edit#heading=h.kp4uvnutykax
<InfoButtonAndDialog title="Session Use">
        <Typography variant="subtitle2" color="textSecondary">
        </Typography>
    </InfoButtonAndDialog> */ <>

    </>
);

export default SessionUseInfoDialog;
