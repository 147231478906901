import styled from '@emotion/styled';
import React from 'react';
import { AdministrationRoute } from 'wavepaths-shared/core';

export interface AdministrationIconProps {
    administration: AdministrationRoute;
}

const Container = styled.div`
    width: 16px;
    height: 16px;
    display: grid;
    place-content: center;
`;

const StyledSvg = styled.svg`
    width: 16px;
    height: 16px;
`;

const SyringeIcon = () => {
    return (
        <StyledSvg viewBox="6 6 36 36" xmlns="http://www.w3.org/2000/svg">
            <path
                fill="#667085"
                clipRule="evenodd"
                d="m6.81815 13.3639c-.45187.4519-.45187 1.1845 0 1.6364.45187.4518 1.18449.4519 1.63636 0l.8187-.8188 4.09039 4.0905-3.2727 3.2728c-.45191.4519-.45191 1.1845 0 1.6364.4518.4519 1.1844.4519 1.6363 0l1.636-1.6361 13.9095 13.9098c1.6774 1.6775 4.1288 2.1095 6.2048 1.2961l1.1586 1.1586c.6059.6059 1.4641.8056 2.2363.5991l2.6731 2.6732c.4518.4519 1.1844.4519 1.6363 0s.4519-1.1845 0-1.6364l-2.6735-2.6736c.2059-.7719.0061-1.6295-.5994-2.2351l-1.1585-1.1585c.8136-2.0762.3816-4.5278-1.2959-6.2054l-13.9095-13.9098 1.6367-1.6367c.4519-.4519.4519-1.1845 0-1.6364-.4518-.45189-1.1845-.45188-1.6363 0l-3.2728 3.2728-4.0903-4.09045.8176-.81767c.4519-.45188.4519-1.18452 0-1.6364-.4518-.45188-1.1844-.45188-1.6363 0zm10.04025 1.4131-4.0903-4.0904-2.0807 2.0807 4.0904 4.0905zm3.2724.0004-5.3534 5.3535 4.406 4.406 5.3534-5.3535zm.6889 11.3959 2.4546 2.4547 1.8587-1.8587c.4519-.4519 1.1845-.4519 1.6364 0 .4519.4518.4519 1.1845 0 1.6364l-1.8587 1.8587 1.6359 1.636 1.8589-1.8589c.4519-.4519 1.1845-.4519 1.6364 0 .4518.4519.4518 1.1845 0 1.6364l-1.8589 1.8589.5039.5039c1.0981 1.0981 2.703 1.3805 4.0618.8472l2.1388-2.1389c.5333-1.3588.2509-2.9637-.8472-4.0619l-7.8672-7.8673zm15.7027 9.9094c-.0084-.011-.0177-.0216-.0277-.0317l-.8241-.8241c-.0696.0775-.1416.1535-.2161.228-.0746.0746-.1507.1467-.2283.2163l.8241.8242c.0102.0101.0209.0194.032.0279.0505-.0898.114-.1742.1904-.2506.0762-.0762.1603-.1395.2497-.19z"
                fillRule="evenodd"
            />
        </StyledSvg>
    );
};

const DripIcon = () => {
    return (
        <StyledSvg viewBox="0 0 763 1064" xmlns="http://www.w3.org/2000/svg">
            <path
                fill="#667085"
                d="M67.517 1045.333H0v-1024h762.937V88.85H67.517zm427.604 0h-67.515V914.8h67.516zm0-819.2h-67.515V55.09h67.516zm227.306 204.8H551.384v-67.516h171.042zm0 130.532H591.894V493.95h130.532zm0 130.532H551.384V624.48h171.042zM175.543 192.375h580.642V658.24c-.18 160.267-130.053 290.14-290.304 290.32h-.016c-160.268-.18-290.142-130.053-290.32-290.304v-.017zm513.125 67.516h-445.61V658.24c.155 122.99 99.816 222.65 222.79 222.804h.016c122.99-.154 222.65-99.815 222.804-222.79v-.015z"
            />
        </StyledSvg>
    );
};

const TongueIcon = () => {
    return (
        <StyledSvg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <path
                fill="#667085"
                strokeWidth="4"
                d="m62 9s-14.282 3.232-30 3.232c-15.717 0-30-3.232-30-3.232 0 4.567 2.309 8.851 6.176 12.311-.174 1.856-.175 4.355-.175 7.7 0 17.302 10.745 25.989 23.999 25.989 13.256 0 24.001-8.687 24.001-25.99 0-3.346 0-5.846-.173-7.702 3.865-3.459 6.172-7.742 6.172-12.308m-30 43.546c-10.433 0-21.58-6.183-21.58-23.536 0-4.693 0-8.084.549-9.505.158-.412.309-.8 2.742-.8 1.26 0 2.857.108 4.879.246 2.988.203 7.004.472 12.078.514l1.332 27.218 1.332-27.218c5.076-.042 9.095-.311 12.083-.514 2.022-.137 3.62-.246 4.88-.246 2.433 0 2.581.387 2.74.798.546 1.417.546 4.631.546 9.507 0 17.353-11.147 23.536-21.581 23.536"
            />
        </StyledSvg>
    );
};

const MouthIcon = () => {
    return (
        <StyledSvg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
                <path d="m0 0h24v24h-24z" fillRule="nonzero" />
                <path
                    d="m20.3143 8.03775c-1.4069-.96467-3.3727-1.95965-5.3876-2.03582-.806-.03047-1.4822.30533-2.1669.6454-.192.09536-.3846.19105-.581.27911-.1552.06954-.2024.06954-.3576 0-.1964-.08805-.389-.18374-.581-.27909-.6847-.34007-1.36086-.67587-2.16687-.64539-2.0149.07619-3.98072 1.07115-5.38762 2.03581-.92244.63248-2.68571 1.64593-2.68571 2.96293 0 1.4515 1.9368 3.1164 2.91411 3.9487 1.81558 1.5463 4.53704 3.050602 8.08589 3.050602 3.5489 0 6.2703-1.504302 8.0859-3.050602 1.016-.8653 2.9141-2.4364 2.9141-3.9487 0-1.27761-1.794-2.35155-2.6857-2.96295zm-7.3174.71376c-.2423.10866-.6017.24964-.9969.24964-.3951 0-.7545-.14098-.9969-.24964-.5843-.26193-1.19205-.77594-1.85418-.75091-1.91219.07231-3.74183 1.14208-5.19384 2.3324 1.86305.7501 4.8902 1.167 8.04492 1.167 3.1548 0 6.1819-.4169 8.045-1.167-1.452-1.19034-3.2817-2.26014-5.1939-2.33243-.649-.02453-1.282.49444-1.8542.75094zm-8.69594 3.81329c2.16172.6403 4.97583.9352 7.69904.9352 2.7233 0 5.5374-.2949 7.6991-.9352-2.0005 2.1085-4.7663 3.4352-7.6991 3.4352-2.93278 0-5.69858-1.3267-7.69904-3.4352z"
                    fill="#667085"
                />
            </g>
        </StyledSvg>
    );
};

const NoseIcon = () => {
    return (
        <StyledSvg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill="none">
                <path d="m0 0h24v24h-24z" />
                <path
                    d="m9.5 4c0-.55228-.44772-1-1-1s-1 .44772-1 1c0 1.82724-.45557 3.67507-1.39443 5.55279-.4512.90241-1.08756 1.60081-1.78993 2.56881-.65043.8963-1.31564 1.991-1.31564 3.3784 0 1.933 1.567 3.5 3.5 3.5.57231 0 1.51211-.3759 2.05279-.1056.39867.1994.65575.6595.95712.9791.51919.5507 1.26419 1.1265 2.49009 1.1265s1.9709-.5758 2.4901-1.1265c.2946-.3125.5621-.7816.9571-.9791.5407-.2703 1.4805.1056 2.0528.1056 1.933 0 3.5-1.567 3.5-3.5 0-1.3874-.6652-2.4821-1.3156-3.3784-.7024-.9679-1.3388-1.6664-1.79-2.56881-.9388-1.87772-1.3944-3.72555-1.3944-5.55279 0-.55228-.4477-1-1-1s-1 .44772-1 1c0 2.17276.5444 4.32493 1.6056 6.4472.5487 1.0976 1.4124 2.0944 1.96 2.849.5996.8262.9344 1.4815.9344 2.2038 0 .8284-.6716 1.5-1.5 1.5-1.0163 0-1.9668-.3847-2.9472.1056-.6414.3206-1.0399.8889-1.5179 1.3959-.3058.3243-.5608.4985-1.0349.4985s-.7291-.1742-1.0349-.4985c-.4753-.5041-.8809-1.0774-1.51789-1.3959-.98046-.4903-1.93089-.1056-2.94721-.1056-.82843 0-1.5-.6716-1.5-1.5 0-.7223.33479-1.3776.93436-2.2038.54761-.7547 1.41128-1.7514 1.96007-2.849 1.06114-2.12227 1.60557-4.27444 1.60557-6.4472z"
                    fill="#667085"
                />
            </g>
        </StyledSvg>
    );
};

const Icon = ({ admin }: { admin: AdministrationRoute }) => {
    switch (admin) {
        case AdministrationRoute.ORAL:
            return <MouthIcon />;
        case AdministrationRoute.NASAL:
            return <NoseIcon />;
        case AdministrationRoute.SUBLINGUAL:
            return <TongueIcon />;
        case AdministrationRoute.INTRAVENOUS:
            return <DripIcon />;
        case AdministrationRoute.INTRAMUSCULAR:
            return <SyringeIcon />;
    }
    return null;
};

const AdministrationIcon: React.FC<AdministrationIconProps> = ({ administration }) => {
    return (
        <Container>
            <Icon admin={administration} />
        </Container>
    );
};

export default AdministrationIcon;
