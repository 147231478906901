import styled from '@emotion/styled';
import * as eva from 'eva-icons';
import React, { FC } from 'react';
import { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ScoreLibrary, SessionType } from 'wavepaths-shared/core';

import { Button } from '@/component-library';

import { AuthContext } from '../../auth';
import { Header } from '../../common/components/Header';
import useScoreLibrary from '../../common/hooks/useScoreLibrary';
import LogoImg from '../../images/WavepathBlueLogo.svg';
import { GuidePlanner } from './GuidePlanner';
import { OneOnOneSessionPlanner } from './OneOnOneSessionPlanner';

const Container = styled.div({
    background: 'rgb(241, 243, 248)',
    minHeight: '100%',
});

const StyledHeader = styled(Header)({
    padding: '12px 16px',
    backgroundColor: 'rgba(255,255,255,0.4)',
});

const Logo = styled.img({
    width: '140px',
    marginLeft: '32px', // offset 'beta' text to visually center logo
});

interface GuidePlannerParams {
    sessionType: SessionType;
}

export interface GuidePlannerPageProps {
    scoreLibrary: ScoreLibrary;
    sessionType: SessionType;
}

export const GuidePlannerContainer: FC = () => {
    const { sessionType } = useParams<GuidePlannerParams>() as GuidePlannerParams;
    const history = useHistory();
    const authCtx = useContext(AuthContext);
    const scoreLibrary = useScoreLibrary(authCtx.firebaseUser);
    useEffect(() => eva.replace(), []);
    return (
        <Container>
            <StyledHeader
                center={<Logo src={LogoImg} alt="Wavepaths" />}
                right={
                    <Button
                        variant="clear"
                        size="xs"
                        onClick={() => history.push('/')}
                        icon={
                            <i
                                data-eva="close-outline"
                                data-eva-fill="inherit"
                                data-eva-height="22"
                                data-eva-width="22"
                            />
                        }
                        aria-label="Close session planner"
                    />
                }
            />
            {authCtx.userData && scoreLibrary.loading === false && (
                <>
                    {sessionType === SessionType.ONE_ON_ONE ? (
                        <OneOnOneSessionPlanner scoreLibrary={scoreLibrary} />
                    ) : (
                        <GuidePlanner scoreLibrary={scoreLibrary} sessionType={sessionType} />
                    )}
                </>
            )}
        </Container>
    );
};
