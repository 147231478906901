import React from 'react';

import { Typography } from '@/component-library';

import InfoButtonAndDialog from './InfoButtonAndDialog';

const MusicalPreferenceInfoDialog: React.FC = () => (
    <InfoButtonAndDialog title="Musical Preference">
        <Typography variant="subtitle2" color="textSecondary">
            Here you can select the dominant tone colour (or “timbre”) of the music created for your session.
        </Typography>
        <Typography variant="body2" color="textSecondary"></Typography>
        <Typography variant="body2" color="textSecondary">
            <strong>Acoustic</strong> will prioritise instruments such as wind, brass and strings, tuned percussion, as
            well as human voice.
        </Typography>
        <Typography variant="body2" color="textSecondary">
            <strong>Electronic</strong> will prioritise electronic instruments such as synthesizers.
        </Typography>
        <Typography variant="body2" color="textSecondary">
            <strong>Mixed acoustic/electronic</strong> includes both of the above, as well as acoustic instruments that
            have been processed electronically.
        </Typography>
    </InfoButtonAndDialog>
);

export default MusicalPreferenceInfoDialog;
