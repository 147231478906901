import React from 'react';

import Container from './Container';
import { ContentSegmented } from './Content';
import Heading from './Heading';
import { OptionSegmentedControl } from './Option';
import { ControlProps } from './types';

export type SegmentedControlProps<V> = ControlProps<V>;

export const SegmentedControl = <ValueType extends unknown>({
    heading: headerText,
    info,
    canSave,
    size,
    inline: inlineProp,
    colour,
    options,
    value,
    name,
    onChange,
    disabled: allDisabled = false,
}: SegmentedControlProps<ValueType>): JSX.Element => {
    const labelId = name && `${name}-segmented-label`;

    const inline = inlineProp ?? size === 'small';

    return (
        <Container inline={inline}>
            {headerText && (
                <Heading
                    size={size}
                    id={labelId}
                    heading={headerText}
                    info={info}
                    canSave={canSave}
                    inline={inline}
                    colour={colour}
                >
                    {headerText}
                </Heading>
            )}
            {options && (
                <ContentSegmented
                    role="radiogroup"
                    aria-labelledby={labelId}
                    optionCount={options.length}
                    colour={colour}
                    disabled={allDisabled}
                    size={size}
                >
                    {options.map(({ label, value: optionValue, disabled }, index) => (
                        <OptionSegmentedControl
                            role="radio"
                            aria-checked={value === optionValue ? 'true' : 'false'}
                            key={`${label}${index}`}
                            size={size}
                            colour={colour}
                            disabled={disabled || allDisabled}
                            selected={value === optionValue}
                            onClick={() => onChange(optionValue)}
                        >
                            {label}
                        </OptionSegmentedControl>
                    ))}
                </ContentSegmented>
            )}
        </Container>
    );
};
