import styled from '@emotion/styled';
import React, { FC } from 'react';
import { SessionScore, SessionVariables } from 'wavepaths-shared/core';

import { Button, EvaIcon } from '@/component-library';
import { Feature, Features } from '@/features';

import { selectNoWave, WaveSelection } from '../inSession/autoGuide/waveSelection';
import { Timeline } from '../inSession/timeline/Timeline';

const Container = styled.div({
    minWidth: '688px',
    display: 'inline-grid',
    placeContent: 'center',
    placeItems: 'center',
    gap: '24px',
    backgroundColor: 'rgba(255,255,255,0.5)',
    border: '1px solid white',
    borderRadius: '4px',
    boxShadow: '0px 0px 20px #CFD6E7',
    padding: '16px 8px',
    margin: '0 24px',
});

const Wrapper = styled.div({
    display: 'inline-grid',
    placeContent: 'center',
    alignItems: 'center',
});

export const SessionTemplateCard: FC<{
    score: SessionScore;
    variables: SessionVariables;
    onOpen: (waveSelection?: WaveSelection) => void;
}> = ({ score, onOpen, variables }) => {
    return (
        <Container>
            <Wrapper>
                <Timeline
                    score={score}
                    setWaveSelection={onOpen}
                    waveSelection={selectNoWave()}
                    variables={variables}
                    isScrollable
                    phasesAlwaysVisible
                    isPlanner
                />
            </Wrapper>
            <Button
                style={{ position: 'relative' }}
                variant="solid-blue"
                size="s"
                onClick={() => onOpen()}
                aria-label="Customise Musical Journey"
            >
                Customise Music
                <Feature
                    name={Features.WAVE_EDITOR}
                    inactiveComponent={
                        <EvaIcon
                            name="lock-outline"
                            size={16}
                            fill={'#aaa'}
                            iconStyle={{
                                position: 'absolute',
                                right: '-20px',
                                top: '8px',
                            }}
                        />
                    }
                />
            </Button>
        </Container>
    );
};
