import React from 'react';

import { Typography } from '@/component-library';

import InfoButtonAndDialog from './InfoButtonAndDialog';

const CanClientStartEarlyInfoDialog: React.FC = () => (
    <InfoButtonAndDialog title="The client can start the session">
        <Typography variant="body2" color="textSecondary">
            Selecting this option will allow the session to be started from the audio streaming page accessed from the
            client link.
        </Typography>
    </InfoButtonAndDialog>
);

export default CanClientStartEarlyInfoDialog;
