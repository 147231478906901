const AudioCtx = 'AudioContext' in window ? window.AudioContext : (window as any).webkitAudioContext;
export const audioCtx: AudioContext = new AudioCtx({ latencyHint: 'playback', sampleRate: 48000 });

console.log('Audio latency: ', audioCtx.baseLatency, 'sample rate', audioCtx.sampleRate);

/**
 * In case the OS or browser suspends the AudioContext (in e.g. output device change), attempt to automatically resume.
 */
audioCtx.addEventListener('statechange', () => {
    console.log('AudioContext state changed to ', audioCtx.state);
    if (audioCtx.state === 'suspended') {
        setTimeout(() => {
            console.log('AudioContext auto-resume attempt...');
            audioCtx
                .resume()
                .then(() => {
                    console.log('AudioContext auto-resumed.');
                })
                .catch((e) => {
                    console.error('AudioContext auto-resume prevented', e);
                });
        });
    }
});

// See if volume actually changes on the media element when we change it.
// Needed for non-desktop Safaris that ignore volume changes.
export function isVolumeControllable(audioEl = new Audio()) {
    const originalVolume = audioEl.volume;
    const result = Promise.race([
        new Promise<boolean>((res) => audioEl?.addEventListener('volumechange', () => res(true), { once: true })),
        new Promise<boolean>((res) => setTimeout(() => res(false), 100)),
    ]).finally(() => {
        audioEl.volume = originalVolume;
    });
    audioEl.volume = 0;
    return result;
}
