import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { noop } from 'lodash';
import React from 'react';
import { CoreEmotionalAtmosphere } from 'wavepaths-shared/core';

import TemplateCard from './TemplateCard';

export interface LoadingTemplateCardProps {
    title?: string;
}

const pulse = keyframes`
    from, to { 
        opacity: 0.5; 
    }

    50% { 
        opacity: 1; 
    }
`;

const StyledCard = styled(TemplateCard)({
    filter: 'blur(3px)',
    animation: `${pulse} 2s ease-in-out infinite`,
});

const LoadingTemplateCard: React.FC<LoadingTemplateCardProps> = ({ title }) => {
    return (
        <StyledCard
            onCardClick={noop}
            id={'loading'}
            title={title ?? 'Waiting...'}
            subtitle={''}
            intensity={'none'}
            minDurationMins={0}
            maxDurationMins={0}
            emotionalities={{
                primary: CoreEmotionalAtmosphere.SILENCE,
                secondary: CoreEmotionalAtmosphere.SILENCE,
                tertiary: CoreEmotionalAtmosphere.SILENCE,
            }}
        />
    );
};

export default LoadingTemplateCard;
