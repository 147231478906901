import styled from '@emotion/styled';
import React, { ReactElement, useEffect, useState } from 'react';

const Container = styled.div<{ state: States; transitionTimeSecs: number; slant: number }>(
    ({ state, transitionTimeSecs, slant }) => ({
        width: '100%',
        height: '100%',
        position: 'absolute',
        borderRadius: 8,
        background: 'white',
        transition: `clip-path ${transitionTimeSecs}s linear`,
        clipPath: clipPathPolygon(state, slant),
    }),
);

interface AnimatedBackground {
    isActive: boolean;
    colour: string;
    transitionTimeSecs: number;
    // progress: number; // 0 - 100
}

const clipPathPolygon = (state: States, slant: number) => {
    if (state === 'entering') {
        const t = 0;
        return `polygon(${t}% 0, ${t + 100 + slant}% 0%, ${t + 100}% 100%, ${t - slant}% 100%)`;
    } else if (state === 'active') {
        const t = 0;
        return `polygon(${t}% 0, ${t + 100 + slant}% 0%, ${t + 100}% 100%, ${t - slant}% 100%)`;
    } else if (state === 'leaving') {
        const t = -(100 + slant);
        return `polygon(${t}% 0, ${t + 100 + slant}% 0%, ${t + 100}% 100%, ${t - slant}% 100%)`;
    } else if (state === 'inActive') {
        const t = -(100 + slant);
        return `polygon(${t}% 0, ${t + 100 + slant}% 0%, ${t + 100}% 100%, ${t - slant}% 100%)`;
    } else {
        return undefined;
    }
};

type States = 'active' | 'inActive' | 'entering' | 'leaving';
function AnimatedBackground({ isActive, transitionTimeSecs }: AnimatedBackground): ReactElement {
    const slant = 0;
    const [state, setState] = useState<States>(isActive ? 'active' : 'inActive');
    const [timeoutHandler, setTimeoutHandler] = useState<number | undefined>(undefined); // Timeout apparently a number https://github.com/Microsoft/TypeScript/issues/30128
    const changeStateTo = (nextState: States) => {
        setState(nextState);
    };
    useEffect(() => {
        if (isActive) {
            switch (state) {
                case 'inActive':
                case 'leaving':
                    changeStateTo('entering');
                    timeoutHandler && clearTimeout(timeoutHandler);
                    const timeout = setTimeout(() => {
                        changeStateTo('active');
                        setTimeoutHandler(undefined);
                    }, transitionTimeSecs * 1000);
                    setTimeoutHandler(+timeout);
                    break;
            }
        } else {
            switch (state) {
                case 'active':
                case 'entering':
                    changeStateTo('leaving');
                    timeoutHandler && clearTimeout(timeoutHandler);
                    const timeout = setTimeout(() => {
                        changeStateTo('inActive');
                        setTimeoutHandler(undefined);
                    }, transitionTimeSecs * 1000);
                    setTimeoutHandler(+timeout);
                    break;
            }
        }
    }, [isActive]);

    return <Container state={state} slant={slant} transitionTimeSecs={transitionTimeSecs}></Container>;
}

export default AnimatedBackground;
