import {
    CoreEmotionalAtmosphere,
    PathType,
    SessionScoreDTO,
    SessionScoreEmotionalIntensity,
    SessionScoreModality,
    SessionType,
    TherapeuticDirection,
    UserRoles,
    WavepathType,
} from 'wavepaths-shared/core';

const jumpRightInScore1: SessionScoreDTO = {
    id: '2.1_cea1_medium',
    name: '2.1 Stillness',
    approved: true,
    variables: {
        inputs: [
            {
                acl: [UserRoles.ADMIN],
                name: 'preferSubmittedContent',
                type: 'boolean',
                group: 'content',
                label: 'Prefer Submitted content over Approved',
                defaultValue: 0,
            },
            {
                name: 'name',
                type: 'string',
                defaultToTimestamp: true,
            },
            {
                name: 'sessionUse',
                type: 'enum',
                options: [
                    {
                        label: 'Testing (no client)',
                        value: 1,
                    },
                    {
                        label: 'In-person client',
                        value: 2,
                    },
                    {
                        label: 'Remote client',
                        value: 3,
                    },
                    {
                        label: 'Remote group',
                        value: 4,
                    },
                    {
                        label: 'Other',
                        value: 5,
                    },
                ],
            },
            {
                name: 'Acousticness',
                type: 'enum',
                label: 'Music Preference',
                options: [
                    {
                        label: 'More Acoustic',
                        value: 1,
                    },
                    {
                        label: 'Mixed Acoustic/Electronic',
                        value: 0.5,
                    },
                    {
                        label: 'More Electronic',
                        value: 0,
                    },
                ],
                defaultValue: 0.5,
            },
            {
                name: 'dosage',
                type: 'enum',
                options: [
                    {
                        label: 'Low',
                        value: 1,
                    },
                    {
                        label: 'Medium',
                        value: 2,
                    },
                    {
                        label: 'High',
                        value: 3,
                    },
                ],
                defaultValue: 2,
            },
            {
                name: 'administration',
                type: 'enum',
                options: [
                    {
                        label: 'Intramuscular',
                        value: 1,
                    },
                    {
                        label: 'Intravenous',
                        value: 2,
                    },
                    {
                        label: 'Sublingual',
                        value: 3,
                    },
                    {
                        label: 'Nasal',
                        value: 4,
                    },
                ],
                defaultValue: 1,
            },
            {
                name: 'totalDuration',
                type: 'number',
                group: 'timing',
                label: 'Session Duration (mins)',
                defaultLookup: [
                    {
                        value: 55,
                        expression: 'dosage == 1 and administration == 1',
                    },
                    {
                        value: 35,
                        expression: 'dosage == 1 and administration == 2',
                    },
                    {
                        value: 65,
                        expression: 'dosage == 1 and administration == 3',
                    },
                    {
                        value: 45,
                        expression: 'dosage == 1 and administration == 4',
                    },
                    {
                        value: 65,
                        expression: 'dosage == 2 and administration == 1',
                    },
                    {
                        value: 60,
                        expression: 'dosage == 2 and administration == 2',
                    },
                    {
                        value: 110,
                        expression: 'dosage == 2 and administration == 3',
                    },
                    {
                        value: 55,
                        expression: 'dosage == 2 and administration == 4',
                    },
                    {
                        value: 75,
                        expression: 'dosage == 3 and administration == 1',
                    },
                    {
                        value: 75,
                        expression: 'dosage == 3 and administration == 2',
                    },
                    {
                        value: 120,
                        expression: 'dosage == 3 and administration == 3',
                    },
                    {
                        value: 65,
                        expression: 'dosage == 3 and administration == 4',
                    },
                ],
            },
            {
                name: 'preludeDuration',
                type: 'number',
                hidden: true,
                defaultValue: 240,
            },
            {
                name: 'postludeDuration',
                type: 'number',
                hidden: true,
                defaultValue: 240,
            },
        ],
        derived: [
            {
                name: 'preOnsetDuration',
                lookup: [
                    {
                        value: 'totalDuration * 0.073',
                        expression: 'dosage == 1 and administration == 1',
                    },
                    {
                        value: 'totalDuration * 0.057',
                        expression: 'dosage == 1 and administration == 2',
                    },
                    {
                        value: 'totalDuration * 0.231',
                        expression: 'dosage == 1 and administration == 3',
                    },
                    {
                        value: 'totalDuration * 0.111',
                        expression: 'dosage == 1 and administration == 4',
                    },
                    {
                        value: 'totalDuration * 0.046',
                        expression: 'dosage == 2 and administration == 1',
                    },
                    {
                        value: 'totalDuration * 0.033',
                        expression: 'dosage == 2 and administration == 2',
                    },
                    {
                        value: 'totalDuration * 0.136',
                        expression: 'dosage == 2 and administration == 3',
                    },
                    {
                        value: 'totalDuration * 0.091',
                        expression: 'dosage == 2 and administration == 4',
                    },
                    {
                        value: 'totalDuration * 0.04',
                        expression: 'dosage == 3 and administration == 1',
                    },
                    {
                        value: 'totalDuration * 0.013',
                        expression: 'dosage == 3 and administration == 2',
                    },
                    {
                        value: 'totalDuration * 0.125',
                        expression: 'dosage == 3 and administration == 3',
                    },
                    {
                        value: 'totalDuration * 0.031',
                        expression: 'dosage == 3 and administration == 4',
                    },
                ],
            },
            {
                name: 'onsetDuration',
                lookup: [
                    {
                        value: 'totalDuration * 0.291',
                        expression: 'dosage == 1 and administration == 1',
                    },
                    {
                        value: 'totalDuration * 0.371',
                        expression: 'dosage == 1 and administration == 2',
                    },
                    {
                        value: 'totalDuration * 0.231',
                        expression: 'dosage == 1 and administration == 3',
                    },
                    {
                        value: 'totalDuration * 0.222',
                        expression: 'dosage == 1 and administration == 4',
                    },
                    {
                        value: 'totalDuration * 0.262',
                        expression: 'dosage == 2 and administration == 1',
                    },
                    {
                        value: 'totalDuration * 0.217',
                        expression: 'dosage == 2 and administration == 2',
                    },
                    {
                        value: 'totalDuration * 0.136',
                        expression: 'dosage == 2 and administration == 3',
                    },
                    {
                        value: 'totalDuration * 0.182',
                        expression: 'dosage == 2 and administration == 4',
                    },
                    {
                        value: 'totalDuration * 0.227',
                        expression: 'dosage == 3 and administration == 1',
                    },
                    {
                        value: 'totalDuration * 0.187',
                        expression: 'dosage == 3 and administration == 2',
                    },
                    {
                        value: 'totalDuration * 0.125',
                        expression: 'dosage == 3 and administration == 3',
                    },
                    {
                        value: 'totalDuration * 0.123',
                        expression: 'dosage == 3 and administration == 4',
                    },
                ],
            },
            {
                name: 'peakDuration',
                lookup: [
                    {
                        value: 'totalDuration * 0.273',
                        expression: 'dosage == 1 and administration == 1',
                    },
                    {
                        value: 'totalDuration * 0.286',
                        expression: 'dosage == 1 and administration == 2',
                    },
                    {
                        value: 'totalDuration * 0.308',
                        expression: 'dosage == 1 and administration == 3',
                    },
                    {
                        value: 'totalDuration * 0.444',
                        expression: 'dosage == 1 and administration == 4',
                    },
                    {
                        value: 'totalDuration * 0.308',
                        expression: 'dosage == 2 and administration == 1',
                    },
                    {
                        value: 'totalDuration * 0.417',
                        expression: 'dosage == 2 and administration == 2',
                    },
                    {
                        value: 'totalDuration * 0.364',
                        expression: 'dosage == 2 and administration == 3',
                    },
                    {
                        value: 'totalDuration * 0.455',
                        expression: 'dosage == 2 and administration == 4',
                    },
                    {
                        value: 'totalDuration * 0.333',
                        expression: 'dosage == 3 and administration == 1',
                    },
                    {
                        value: 'totalDuration * 0.333',
                        expression: 'dosage == 3 and administration == 2',
                    },
                    {
                        value: 'totalDuration * 0.375',
                        expression: 'dosage == 3 and administration == 3',
                    },
                    {
                        value: 'totalDuration * 0.538',
                        expression: 'dosage == 3 and administration == 4',
                    },
                ],
            },
            {
                name: 'returnDuration',
                lookup: [
                    {
                        value: 'totalDuration * 0.364',
                        expression: 'dosage == 1 and administration == 1',
                    },
                    {
                        value: 'totalDuration * 0.286',
                        expression: 'dosage == 1 and administration == 2',
                    },
                    {
                        value: 'totalDuration * 0.231',
                        expression: 'dosage == 1 and administration == 3',
                    },
                    {
                        value: 'totalDuration * 0.222',
                        expression: 'dosage == 1 and administration == 4',
                    },
                    {
                        value: 'totalDuration * 0.385',
                        expression: 'dosage == 2 and administration == 1',
                    },
                    {
                        value: 'totalDuration * 0.333',
                        expression: 'dosage == 2 and administration == 2',
                    },
                    {
                        value: 'totalDuration * 0.364',
                        expression: 'dosage == 2 and administration == 3',
                    },
                    {
                        value: 'totalDuration * 0.273',
                        expression: 'dosage == 2 and administration == 4',
                    },
                    {
                        value: 'totalDuration * 0.4',
                        expression: 'dosage == 3 and administration == 1',
                    },
                    {
                        value: 'totalDuration * 0.467',
                        expression: 'dosage == 3 and administration == 2',
                    },
                    {
                        value: 'totalDuration * 0.375',
                        expression: 'dosage == 3 and administration == 3',
                    },
                    {
                        value: 'totalDuration * 0.308',
                        expression: 'dosage == 3 and administration == 4',
                    },
                ],
            },
        ],
    },
    wavepaths: [
        {
            id: 'e71dd3f7-fe86-4951-adb6-1a29785d9691',
            type: WavepathType.PRE,
            pathId: 's_cea1_pre',
            pathScore: {
                id: 's_cea1_pre',
                name: 'Prelude Music',
                type: PathType.GENERATIVE,
                stages: [
                    {
                        name: 'Prelude',
                        stage: '1',
                        preset: 'Soothe Peaceful',
                        description: 'Played at the start of the session, before administration.',
                        contentSwitch: {
                            layers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                            activateAtTimeRange: [8, 10],
                            activateAtStageEntry: true,
                            musicAttributeTargets: {
                                Arousal: -0.7,
                                'Valence Potential': 0.5,
                            },
                            numberOfLayersToSwitch: 16,
                        },
                    },
                ],
                direction: TherapeuticDirection.SOOTHE,
                repeatable: true,
                description: 'Calming minimal music for before the session starts.',
                selectionCriteria: {
                    phases: ['Prelude'],
                },
            },
        },
        {
            id: 'ab391bce-a4c8-4198-b59a-a55db2853733',
            plan: {
                toTime: 450000,
                fromTime: 0,
            },
            type: WavepathType.SCHEDULED,
            pathId: 's_cea1_a',
            pathScore: {
                id: 's_cea1_a',
                name: 'Stillness',
                type: PathType.GENERATIVE,
                stages: [
                    {
                        name: 'Soothe 1',
                        stage: '1',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.2',
                            from: 0,
                        },
                        contentSwitch: {
                            layers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                            activateAtStageEntry: true,
                            musicAttributeTargets: {
                                Arousal: -0.7,
                                'Valence Potential': 0.6,
                            },
                            numberOfLayersToSwitch: 16,
                        },
                    },
                    {
                        name: 'Soothe 2',
                        stage: '2',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.5',
                            from: 'waveDuration * 0.2',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.6,
                                'Valence Potential': 0.8,
                            },
                        },
                    },
                    {
                        name: 'Soothe 3',
                        stage: '3',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.8',
                            from: 'waveDuration * 0.5',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.5,
                                'Valence Potential': 0.7,
                            },
                        },
                    },
                    {
                        name: 'Soothe 2',
                        stage: '2',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration',
                            from: 'waveDuration * 0.8',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.5,
                                'Valence Potential': 0.7,
                            },
                        },
                    },
                ],
                emotion: CoreEmotionalAtmosphere.STILLNESS,
                duration: [4, 8],
                direction: TherapeuticDirection.SOOTHE,
                repeatable: true,
                description:
                    'Sparse tones of bells, piano and other instruments, combined with slowly breathing harmonious chords',
                selectionCriteria: {
                    phases: ['Pre-onset', 'Onset', 'Peak', 'Return'],
                    medicine: [
                        SessionScoreModality.KETAMINE,
                        SessionScoreModality.MDMA,
                        SessionScoreModality.PSILOCYBIN,
                        SessionScoreModality.NONE,
                    ],
                    emotionalIntensity: SessionScoreEmotionalIntensity.LOW,
                },
            },
        },
        {
            id: '9d67aee0-8418-42ca-8235-7d380ad7bdd1',
            plan: {
                toTime: 1350000,
                fromTime: 450000,
            },
            type: WavepathType.SCHEDULED,
            pathId: 's_cea1_b',
            pathScore: {
                id: 's_cea1_b',
                name: 'Stillness',
                type: PathType.GENERATIVE,
                stages: [
                    {
                        name: 'Soothe 2',
                        stage: '2',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.2',
                            from: 0,
                        },
                        contentSwitch: {
                            layers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                            activateAtStageEntry: true,
                            musicAttributeTargets: {
                                Arousal: -0.7,
                                'Valence Potential': 0.7,
                            },
                            numberOfLayersToSwitch: 16,
                        },
                    },
                    {
                        name: 'Soothe 3',
                        stage: '3',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.4',
                            from: 'waveDuration * 0.2',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.5,
                                'Valence Potential': 0.7,
                            },
                        },
                    },
                    {
                        name: 'Soothe 4',
                        stage: '4',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.6',
                            from: 'waveDuration * 0.4',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.3,
                                'Valence Potential': 0.9,
                            },
                        },
                    },
                    {
                        name: 'Soothe 5',
                        stage: '5',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.8',
                            from: 'waveDuration * 0.6',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.4,
                                'Valence Potential': 0.9,
                            },
                        },
                    },
                    {
                        name: 'Soothe 3',
                        stage: '3',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration',
                            from: 'waveDuration * 0.8',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.3,
                                'Valence Potential': 0.9,
                            },
                        },
                    },
                ],
                emotion: CoreEmotionalAtmosphere.STILLNESS,
                duration: [8, 16],
                direction: TherapeuticDirection.SOOTHE,
                repeatable: true,
                description: 'Bells, piano and other instruments, combined with more moving slow chords',
                selectionCriteria: {
                    phases: ['Pre-onset', 'Onset', 'Peak', 'Return'],
                    medicine: [
                        SessionScoreModality.KETAMINE,
                        SessionScoreModality.MDMA,
                        SessionScoreModality.PSILOCYBIN,
                        SessionScoreModality.NONE,
                    ],
                    emotionalIntensity: SessionScoreEmotionalIntensity.MEDIUM,
                },
            },
        },
        {
            id: 'f52035de-5ea7-4aeb-a228-456fd5877e85',
            plan: {
                toTime: 1800000,
                fromTime: 1350000,
            },
            type: WavepathType.SCHEDULED,
            pathId: 's_cea1_a',
            pathScore: {
                id: 's_cea1_a',
                name: 'Stillness',
                type: PathType.GENERATIVE,
                stages: [
                    {
                        name: 'Soothe 1',
                        stage: '1',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.2',
                            from: 0,
                        },
                        contentSwitch: {
                            layers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                            activateAtStageEntry: true,
                            musicAttributeTargets: {
                                Arousal: -0.7,
                                'Valence Potential': 0.6,
                            },
                            numberOfLayersToSwitch: 16,
                        },
                    },
                    {
                        name: 'Soothe 2',
                        stage: '2',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.5',
                            from: 'waveDuration * 0.2',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.6,
                                'Valence Potential': 0.8,
                            },
                        },
                    },
                    {
                        name: 'Soothe 3',
                        stage: '3',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.8',
                            from: 'waveDuration * 0.5',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.5,
                                'Valence Potential': 0.7,
                            },
                        },
                    },
                    {
                        name: 'Soothe 2',
                        stage: '2',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration',
                            from: 'waveDuration * 0.8',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.5,
                                'Valence Potential': 0.7,
                            },
                        },
                    },
                ],
                emotion: CoreEmotionalAtmosphere.STILLNESS,
                duration: [4, 8],
                direction: TherapeuticDirection.SOOTHE,
                repeatable: true,
                description:
                    'Sparse tones of bells, piano and other instruments, combined with slowly breathing harmonious chords',
                selectionCriteria: {
                    phases: ['Pre-onset', 'Onset', 'Peak', 'Return'],
                    medicine: [
                        SessionScoreModality.KETAMINE,
                        SessionScoreModality.MDMA,
                        SessionScoreModality.PSILOCYBIN,
                        SessionScoreModality.NONE,
                    ],
                    emotionalIntensity: SessionScoreEmotionalIntensity.LOW,
                },
            },
        },
        {
            id: '072ef09e-8545-4f02-b741-56dbf815a121',
            plan: {
                toTime: 2700000,
                fromTime: 1800000,
            },
            type: WavepathType.SCHEDULED,
            pathId: 's_cea1_b',
            pathScore: {
                id: 's_cea1_b',
                name: 'Stillness',
                type: PathType.GENERATIVE,
                stages: [
                    {
                        name: 'Soothe 2',
                        stage: '2',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.2',
                            from: 0,
                        },
                        contentSwitch: {
                            layers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                            activateAtStageEntry: true,
                            musicAttributeTargets: {
                                Arousal: -0.7,
                                'Valence Potential': 0.7,
                            },
                            numberOfLayersToSwitch: 16,
                        },
                    },
                    {
                        name: 'Soothe 3',
                        stage: '3',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.4',
                            from: 'waveDuration * 0.2',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.5,
                                'Valence Potential': 0.7,
                            },
                        },
                    },
                    {
                        name: 'Soothe 4',
                        stage: '4',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.6',
                            from: 'waveDuration * 0.4',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.3,
                                'Valence Potential': 0.9,
                            },
                        },
                    },
                    {
                        name: 'Soothe 5',
                        stage: '5',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.8',
                            from: 'waveDuration * 0.6',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.4,
                                'Valence Potential': 0.9,
                            },
                        },
                    },
                    {
                        name: 'Soothe 3',
                        stage: '3',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration',
                            from: 'waveDuration * 0.8',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.3,
                                'Valence Potential': 0.9,
                            },
                        },
                    },
                ],
                emotion: CoreEmotionalAtmosphere.STILLNESS,
                duration: [8, 16],
                direction: TherapeuticDirection.SOOTHE,
                repeatable: true,
                description: 'Bells, piano and other instruments, combined with more moving slow chords',
                selectionCriteria: {
                    phases: ['Pre-onset', 'Onset', 'Peak', 'Return'],
                    medicine: [
                        SessionScoreModality.KETAMINE,
                        SessionScoreModality.MDMA,
                        SessionScoreModality.PSILOCYBIN,
                        SessionScoreModality.NONE,
                    ],
                    emotionalIntensity: SessionScoreEmotionalIntensity.MEDIUM,
                },
            },
        },
        {
            id: '68a02228-a1d5-4c1e-be01-fa2febed5998',
            plan: {
                toTime: 3150000,
                fromTime: 2700000,
            },
            type: WavepathType.SCHEDULED,
            pathId: 's_cea1_a',
            pathScore: {
                id: 's_cea1_a',
                name: 'Stillness',
                type: PathType.GENERATIVE,
                stages: [
                    {
                        name: 'Soothe 1',
                        stage: '1',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.2',
                            from: 0,
                        },
                        contentSwitch: {
                            layers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                            activateAtStageEntry: true,
                            musicAttributeTargets: {
                                Arousal: -0.7,
                                'Valence Potential': 0.6,
                            },
                            numberOfLayersToSwitch: 16,
                        },
                    },
                    {
                        name: 'Soothe 2',
                        stage: '2',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.5',
                            from: 'waveDuration * 0.2',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.6,
                                'Valence Potential': 0.8,
                            },
                        },
                    },
                    {
                        name: 'Soothe 3',
                        stage: '3',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.8',
                            from: 'waveDuration * 0.5',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.5,
                                'Valence Potential': 0.7,
                            },
                        },
                    },
                    {
                        name: 'Soothe 2',
                        stage: '2',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration',
                            from: 'waveDuration * 0.8',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.5,
                                'Valence Potential': 0.7,
                            },
                        },
                    },
                ],
                emotion: CoreEmotionalAtmosphere.STILLNESS,
                duration: [4, 8],
                direction: TherapeuticDirection.SOOTHE,
                repeatable: true,
                description:
                    'Sparse tones of bells, piano and other instruments, combined with slowly breathing harmonious chords',
                selectionCriteria: {
                    phases: ['Pre-onset', 'Onset', 'Peak', 'Return'],
                    medicine: [
                        SessionScoreModality.KETAMINE,
                        SessionScoreModality.MDMA,
                        SessionScoreModality.PSILOCYBIN,
                        SessionScoreModality.NONE,
                    ],
                    emotionalIntensity: SessionScoreEmotionalIntensity.LOW,
                },
            },
        },
        {
            id: '3b3a0f0b-e699-4516-96d9-61d42b038069',
            plan: {
                toTime: 3600000,
                fromTime: 3150000,
            },
            type: WavepathType.SCHEDULED,
            pathId: 's_cea1_a',
            pathScore: {
                id: 's_cea1_a',
                name: 'Stillness',
                type: PathType.GENERATIVE,
                stages: [
                    {
                        name: 'Soothe 1',
                        stage: '1',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.2',
                            from: 0,
                        },
                        contentSwitch: {
                            layers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                            activateAtStageEntry: true,
                            musicAttributeTargets: {
                                Arousal: -0.7,
                                'Valence Potential': 0.6,
                            },
                            numberOfLayersToSwitch: 16,
                        },
                    },
                    {
                        name: 'Soothe 2',
                        stage: '2',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.5',
                            from: 'waveDuration * 0.2',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.6,
                                'Valence Potential': 0.8,
                            },
                        },
                    },
                    {
                        name: 'Soothe 3',
                        stage: '3',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration * 0.8',
                            from: 'waveDuration * 0.5',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.5,
                                'Valence Potential': 0.7,
                            },
                        },
                    },
                    {
                        name: 'Soothe 2',
                        stage: '2',
                        preset: 'Soothe Peaceful',
                        timing: {
                            to: 'waveDuration',
                            from: 'waveDuration * 0.8',
                        },
                        contentSwitch: {
                            activateAtStageEntry: false,
                            musicAttributeTargets: {
                                Arousal: -0.5,
                                'Valence Potential': 0.7,
                            },
                        },
                    },
                ],
                emotion: CoreEmotionalAtmosphere.STILLNESS,
                duration: [4, 8],
                direction: TherapeuticDirection.SOOTHE,
                repeatable: true,
                description:
                    'Sparse tones of bells, piano and other instruments, combined with slowly breathing harmonious chords',
                selectionCriteria: {
                    phases: ['Pre-onset', 'Onset', 'Peak', 'Return'],
                    medicine: [
                        SessionScoreModality.KETAMINE,
                        SessionScoreModality.MDMA,
                        SessionScoreModality.PSILOCYBIN,
                        SessionScoreModality.NONE,
                    ],
                    emotionalIntensity: SessionScoreEmotionalIntensity.LOW,
                },
            },
        },
        {
            id: '05e57899-f5da-4c98-9eab-2671ad5d400a',
            type: WavepathType.POST,
            pathId: 's_cea1_post',
            pathScore: {
                id: 's_cea1_post',
                name: 'Postlude - Stillness',
                type: PathType.GENERATIVE,
                stages: [
                    {
                        name: 'Postlude',
                        stage: '1',
                        preset: 'Soothe Peaceful',
                        description: 'Played at the end of the session.',
                        contentSwitch: {
                            layers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                            activateAtTimeRange: [8, 10],
                            activateAtStageEntry: true,
                            musicAttributeTargets: {
                                Arousal: -0.8,
                                'Valence Potential': 0.6,
                            },
                            numberOfLayersToSwitch: 16,
                        },
                    },
                ],
                direction: TherapeuticDirection.SOOTHE,
                repeatable: true,
                description: 'Calming minimal music for at the end of the session',
                selectionCriteria: {
                    phases: ['Postlude'],
                },
            },
        },
    ],
    showInMenu: true,
    timingPlan: [
        {
            name: 'Prelude',
            type: 'pre',
        },
        {
            name: 'Pre-onset',
            type: 'regular',
            durationVariable: 'preOnsetDuration',
        },
        {
            name: 'Onset',
            type: 'regular',
            durationVariable: 'onsetDuration',
        },
        {
            name: 'Peak',
            type: 'regular',
            durationVariable: 'peakDuration',
        },
        {
            name: 'Return',
            type: 'regular',
            durationVariable: 'returnDuration',
        },
        {
            name: 'Postlude',
            type: 'post',
        },
    ],
    description: 'Primarily Stillness',
    sessionTypes: [SessionType.ONE_ON_ONE],
    selectionCriteria: {
        medicine: SessionScoreModality.KETAMINE,
        emotionalIntensity: SessionScoreEmotionalIntensity.MEDIUM,
    },
};

export const loadJumpRightInScores = () => [jumpRightInScore1];
