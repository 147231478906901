import styled from '@emotion/styled';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { animated, useChain, useSpring, useSpringRef } from 'react-spring';
import { useMeasure } from 'react-use';
import {
    Session,
    SessionFeedback,
    SessionPlan,
    SessionScore,
    SessionType,
    SessionVariables,
    TimestampedSessionEvent,
} from 'wavepaths-shared/core';

import { useQueryParams } from '@/hooks/useQueryParams';

import {
    NotificationStack,
    TimestampedFeedbackEventType,
} from '../../../common/components/notifications/NotificationStack';
import WaveQueueEditor from '../../../common/components/WaveQueueEditor';
import { Connection, useCurrentWave, useElapsedTimeSecs, useSessionState } from '../../../common/hooks/useSessionTick';
import { AudioState } from '../../../freudConnection/FreudConnection';
import { getZeroTick } from '../../planner/SessionVariableInputs';
import { Queueable } from '../actionQueue/useActionQueue';
import CeaController from '../ceaButtons/CeaController';
import IntensityControllerContainer from '../depthSlider/IntensityController';
import { useDepth } from '../depthSlider/useDepth';
import { InSessionProductTour } from '../InSessionProductTour';
import { InstrumentRefreshPanel } from '../InstrumentRefresh/InstrumentRefreshPanel';
import { useInstrumentRefresh } from '../InstrumentRefresh/useInstrumentRefresh';
import { Timeline } from '../timeline/Timeline';
import { AutoGuideTracking } from './autoGuideTracking';
import CurrentWaveCard from './CurrentWaveCard';
import StartEndSessionButton from './StartEndSessionButton';
import { UseSessionScoreReturn } from './useSessionScore';
import { isDiscardingEdits, selectNoWave, WaveSelection } from './waveSelection';

export type AutoGuideProps = {
    onStartSession: () => void;
    onEndSession: () => void;
    session: Session;
    log: TimestampedSessionEvent[];
    audioState: AudioState;
    connection: Connection;
    score: SessionScore;
    sessionScoreState: UseSessionScoreReturn;
    snackbarContent: string | null;
    setSnackbarContent: (content: string | null) => void;
    variables: SessionVariables;
    queueFunction: (queueable: Queueable) => void;
    sessionPlan: SessionPlan;
    onRecordFeedback: (feedback: SessionFeedback) => void;
};

const Container = styled.div({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
});

const Top = styled.div({
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
});

const Middle = styled(animated.div)({
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    display: 'grid',
    placeContent: 'center',
    transition: 'height 0.15s ease',
});

const Bottom = styled(animated.div)({
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    bottom: 0,
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: 'min-content 1fr',
    justifyItems: 'center',
});

const BottomContent = styled.div({
    width: '100%',
    display: 'grid',
    justifyItems: 'center',
});

const CurrentWaveCardWrapper = styled.div<{ isInIntroSession2?: boolean; shouldShowQueue?: boolean }>(
    ({ isInIntroSession2, shouldShowQueue }) => ({
        display: 'flex',
        flexDirection: 'row',
        minWidth: 280,
        width: '100%',
        maxWidth: 650,
        zIndex: 1,
        position: isInIntroSession2 ? 'absolute' : undefined,
        bottom: isInIntroSession2 ? 40 : undefined,
        paddingBottom: shouldShowQueue ? 0 : 24,
    }),
);

const CEASelectorWrapper = styled.div({
    margin: '0 0 16px 0',
});

export const SAVE_OR_CANCEL_LABEL = 'Please save or cancel your changes first.';

export function AutoGuide({
    session,
    connection,
    log,
    score,
    sessionScoreState,
    variables,
    audioState,
    onStartSession,
    onEndSession,
    snackbarContent,
    setSnackbarContent,
    queueFunction,
    onRecordFeedback,
    sessionPlan,
}: AutoGuideProps): JSX.Element {
    const params = useQueryParams();
    const isTour = params.has('isTour');

    const { wave: currentWave, index: currentWaveIndex } = useCurrentWave(connection, score.wavepaths);
    const nextWave = score.wavepaths[currentWaveIndex + 1];
    const isInIntroSession2 = session.type === SessionType.INTRO && session.score.id === 'intro-score-2';
    const [showDepthController, setShowDepthController] = useState(true);
    const isPrelude = currentWave?.type === 'pre';
    const isPostlude = currentWave?.type === 'post';
    const zeroTick = useMemo(() => getZeroTick(score, variables), [score, variables]);
    const { initialised: sessionInitialised } = useSessionState(connection, zeroTick);
    const isPreludeOrPostlude = !currentWave || isPrelude || isPostlude;
    const shouldShowQueue = !isPreludeOrPostlude && !!currentWave?.plan && !showDepthController;
    const [waveSelection, setWaveSelection] = useState<WaveSelection>(selectNoWave());
    const depthState = useDepth(connection, queueFunction);

    const elapsedTimeSecs = useElapsedTimeSecs(connection);

    const [feedback, setFeedback] = useState<TimestampedFeedbackEventType | undefined>(undefined);

    const instrumentRefreshArgs = useInstrumentRefresh({
        connection,
        queueFunction,
        sessionPlan,
        setSnackbarContent,
        depth: depthState !== 'loading' ? depthState.targetDepth : undefined,
    });

    const audioInitialised = audioState === 'started';
    // const { activeFeedbackType, onLogFeedback, state: notificationsState, onNewEvent } = useNotifications({
    //     sessionLog: log,
    //     onRecordFeedback,
    // });

    const initialised = audioInitialised && sessionInitialised;

    useEffect(() => {
        if (isEqual(waveSelection, selectNoWave()) && snackbarContent === SAVE_OR_CANCEL_LABEL) {
            setSnackbarContent(null);
        }
    }, [snackbarContent, waveSelection]);

    const setWaveSelectionOrNotify = useCallback(
        (newSelection: WaveSelection, force = false) => {
            if (!force && isDiscardingEdits(newSelection, waveSelection)) {
                setSnackbarContent(SAVE_OR_CANCEL_LABEL);
            } else if (!isPreludeOrPostlude && !!currentWave?.plan) {
                setShowDepthController(false);
                setWaveSelection(newSelection);
            }
        },
        [waveSelection, isPreludeOrPostlude, currentWave, setSnackbarContent],
    );

    const [containerRef, { height: containerHeight }] = useMeasure<HTMLDivElement>();
    const [bottomContentRef, { height: bottomContentHeight }] = useMeasure<HTMLDivElement>();
    const middleExpanded = isPreludeOrPostlude || showDepthController;
    const sparklineHeight = 83 + 24;
    const bottomHeight = middleExpanded ? bottomContentHeight : containerHeight - sparklineHeight;
    const middleHeight = middleExpanded ? containerHeight - sparklineHeight - bottomHeight : 0;
    const sliderOpacitySpringRef = useSpringRef();
    const sliderOpacitySpring = useSpring({
        opacity: showDepthController && !isPreludeOrPostlude && initialised ? 1 : 0,
        config: {
            tension: 80,
            friction: 14,
        },
        ref: sliderOpacitySpringRef,
    });
    const bottomTranslateSpringRef = useSpringRef();
    const bottomTranslateSpring = useSpring({
        transform: `translateY(${middleHeight}px)`,
        config: {
            tension: 80,
            friction: 14,
        },
        ref: bottomTranslateSpringRef,
    });
    const middleBottomSpringRef = useSpringRef();
    const middleBottomSpring = useSpring({
        bottom: bottomHeight,
        config: { duration: 0 },
        ref: middleBottomSpringRef,
    });
    useChain(
        middleExpanded
            ? [bottomTranslateSpringRef, middleBottomSpringRef, sliderOpacitySpringRef]
            : [sliderOpacitySpringRef, middleBottomSpringRef, bottomTranslateSpringRef],
        [0, 0.5, 0.5],
    );

    const productTourState = isPrelude ? 'not-started' : shouldShowQueue ? 'started-queue-open' : 'started';

    const [renderProductTour, setRenderProductTour] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setRenderProductTour(true);
        }, 1000);
    }, [productTourState]);

    return (
        <Container ref={containerRef}>
            {isTour && renderProductTour && <InSessionProductTour state={productTourState} />}

            <Top>
                <Timeline
                    score={score}
                    variables={variables}
                    log={log}
                    waveSelection={waveSelection}
                    setWaveSelection={setWaveSelectionOrNotify}
                    connection={connection}
                    session={session}
                    elapsedTimeMs={elapsedTimeSecs * 1000}
                />
            </Top>
            <Middle style={{ top: sparklineHeight, bottom: middleBottomSpring.bottom }}>
                <StartEndSessionButton
                    isPrelude={isPrelude}
                    isPostlude={isPostlude}
                    onStartSession={onStartSession}
                    onEndSession={onEndSession}
                />
                <IntensityControllerContainer
                    opacity={sliderOpacitySpring.opacity}
                    connection={connection}
                    queueFunction={queueFunction}
                    setSnackbarContent={setSnackbarContent}
                    sliderVisible={!isPrelude && !isPostlude}
                />
            </Middle>
            <Bottom style={{ top: sparklineHeight, transform: bottomTranslateSpring.transform }}>
                <BottomContent ref={bottomContentRef}>
                    <CEASelectorWrapper>
                        <CeaController
                            connection={connection}
                            setSnackbarContent={setSnackbarContent}
                            queueFunction={queueFunction}
                        />
                    </CEASelectorWrapper>
                    <CurrentWaveCardWrapper isInIntroSession2={isInIntroSession2} shouldShowQueue={shouldShowQueue}>
                        <CurrentWaveCard
                            setSnackBarContent={setSnackbarContent}
                            initialised={initialised}
                            connection={connection}
                            currentWave={currentWave}
                            nextWave={nextWave}
                            activeFeedbackType={feedback?.type}
                            onLogFeedback={(type) => setFeedback({ type, timestamp: Date.now() })}
                            session={session}
                            instrumentRefreshArgs={instrumentRefreshArgs}
                            isExpanded={!showDepthController}
                            setIsExpanded={setShowDepthController}
                            disableExpansion={isPreludeOrPostlude}
                            onSkipWave={
                                sessionScoreState !== 'loading'
                                    ? () => sessionScoreState.skipToWave(currentWaveIndex + 1)
                                    : undefined
                            }
                        />
                    </CurrentWaveCardWrapper>
                </BottomContent>
                {sessionScoreState !== 'loading' && currentWave?.plan && (
                    <WaveQueueEditor
                        hasBackground
                        shouldDisplayHeader
                        shouldShow={shouldShowQueue}
                        wavepaths={score.wavepaths}
                        waveSelection={waveSelection}
                        setWaveSelection={setWaveSelectionOrNotify}
                        updatePathAtIndex={sessionScoreState.updatePathAtIndex}
                        addPathAtIndex={sessionScoreState.addPathAtIndex}
                        removePathAtIndex={sessionScoreState.removePathAtIndex}
                        skipToWave={sessionScoreState.skipToWave}
                        movePathToIndex={sessionScoreState.movePathToIndex}
                        currentWaveIndex={currentWaveIndex}
                        setSnackbarContent={setSnackbarContent}
                        trackingHandlers={AutoGuideTracking}
                    />
                )}
            </Bottom>
            <NotificationStack
                feedback={feedback}
                onCancel={() => setFeedback(undefined)}
                onSubmitFeedback={(fb) => {
                    onRecordFeedback(fb);
                    setFeedback(undefined);
                }}
            />
            <InstrumentRefreshPanel instrumentRefreshArgs={instrumentRefreshArgs} />
        </Container>
    );
}
