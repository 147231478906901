import firebase from 'firebase';
import { isEmpty } from 'lodash';

import configs from '../../configs';
import axios from '../util/axios';
import { GetBonnySubscriptionResponse, GetMyDetailsResponse } from './types';

export const signUp = async (
    fbUser: firebase.User,
    { name, utmTags, retries = 2 }: { name: string; retries?: number; utmTags?: { [key: string]: string } },
): Promise<void> => {
    const url = configs.cloudFunctions.URL + '/signUp';
    try {
        await axios(url, {
            method: 'POST',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
            data: {
                name,
                ...(!isEmpty(utmTags) && { utmTags }),
            },
        });
    } catch (error: any) {
        const status = error.status || (error.response && error.response.status);
        switch (status) {
            default:
                if (retries > 0) return signUp(fbUser, { name, utmTags, retries: retries - 1 });
                throw new Error('UnknownSignUpError');
        }
    }
};

export const saveTherapistLeadInfo = async (
    fbUser: firebase.User,
    therapistInfo: {
        modality: string;
        medicine?: string;
        userType: string;
        wantsIndividualTraining: boolean;
        wantsTailoredOnboarding: boolean;
        sessionCount: string;
    },
) => {
    const url = configs.cloudFunctions.URL + '/signUp/info';
    try {
        await axios(url, {
            method: 'POST',
            headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
            data: therapistInfo,
        });
    } catch (e) {
        throw e;
    }
};

export const getMySubscription = async (fbUser: firebase.User): Promise<GetBonnySubscriptionResponse> => {
    const url = configs.cloudFunctions.URL + '/bonnyApi/my/subscription';
    const response = await axios(url, {
        method: 'GET',
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    });

    if (response.status === 200) return response.data;
    else throw new Error('Failed to fetch subscription');
};

export const getMyDetails = async (fbUser: firebase.User): Promise<GetMyDetailsResponse> => {
    const url = configs.cloudFunctions.URL + '/bonnyApi/my/details';
    const response = await axios(url, {
        method: 'GET',
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
    });

    if (response.status >= 200 && response.status < 300) return response.data;
    else throw new Error('Failed to fetch details');
};

export const setMyEmail = async (fbUser: firebase.User, email: string): Promise<GetMyDetailsResponse> => {
    const url = configs.cloudFunctions.URL + '/bonnyApi/my/email';
    const response = await axios(url, {
        method: 'PUT',
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
        data: {
            email,
        },
    });

    if (response.status >= 200 && response.status < 300) return response.data;
    else throw new Error('Failed to update email');
};
