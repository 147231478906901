import styled from '@emotion/styled';
import { setSeconds } from 'date-fns';
import { capitalize, head, last, range } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
    AdministrationRoute,
    ClientVariablesMusicPreference,
    CoreEmotionalAtmosphere,
    DosageLevel,
    SessionRenderType,
    SessionScoreEmotionalIntensity,
    SessionScoreModality,
    SessionScoreSessionUse,
} from 'wavepaths-shared/core';

import DateTimeInput from '@/components/Inputs/DateTimeInput';

import TypographyV2 from '../typography/TypographyV2';
import Button from './Button';
import { DropdownControl, SegmentedControl } from './Control';
import { ControlOption } from './Control/types';
import EvaIcon from './EvaIcon';
import AdministrationIcon from './icons/AdministrationIcon';
import DosageIcon from './icons/DosageIcon';
import IntensityIcon from './icons/IntensityIcon';
import ModalityIcon from './icons/ModalityIcon';
import TemplateArtwork from './TemplateArtwork';
import TextInput from './TextInput';

export interface TemplateDetailProps {
    imageUrl?: string;
    id: string;
    title: string;
    subtitle: string;
    description: string;
    intensity: SessionScoreEmotionalIntensity;
    emotionalities: {
        primary: CoreEmotionalAtmosphere;
        secondary: CoreEmotionalAtmosphere;
        tertiary: CoreEmotionalAtmosphere;
    };
    minDurationMins: number;
    maxDurationMins: number;
    onBackButtonClick: () => void;
    tracklistComponent: JSX.Element;
    timelineComponent: JSX.Element;
    duration: number;
    onDurationChange: (duration: number) => void;
    renderType: SessionRenderType;
    onRenderTypeChange: (value: SessionRenderType) => void;
    sessionName: string;
    onSessionNameChange: (name: string) => void;
    sessionUse: SessionScoreSessionUse;
    onSessionUseChange: (value: SessionScoreSessionUse) => void;
    contentStatuses: 'Approved' | 'Submitted' | 'All';
    onContentStatusChange: (value: 'Approved' | 'Submitted' | 'All') => void;
    musicalPreference: ClientVariablesMusicPreference;
    onMusicalPreferenceChange: (val: ClientVariablesMusicPreference) => void;
    schedulingType: SchedulingStyle;
    onSchedulingTypeChange: (val: SchedulingStyle) => void;
    canClientStartEarly: boolean;
    onCanClientStartEarlyChange: (val: boolean) => void;
    scheduledStart?: number;
    onScheduledStartChange: (val?: number) => void;
    showVoControl: boolean;
    onVoChange: (vo: string) => void;
    voiceover: string;
    onSubmit: () => void;
    submitDisabled: boolean;
    modality: SessionScoreModality;
    administration?: AdministrationRoute;
    dosage?: DosageLevel;
    canSaveTemplates?: boolean;
    onSaveTemplate: () => void;
    showAdminFeatures: boolean;
    isRealTimeRenderingEnabled: boolean;
}

const Container = styled.div`
    display: grid;
    grid-template-areas: 'header' 'info' 'actions' 'music' 'footer';
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, min-content);
    gap: 20px;
    margin-top: 64px;

    @media (min-width: 1260px) {
        grid-template-areas: 'header header' 'info actions' 'music actions' 'footer footer';
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3, min-content);
        gap: 20px 48px;
    }
`;

const Header = styled.div`
    grid-area: header;
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    gap: 24px;
    margin-bottom: 28px;
`;

const Artwork = styled.div`
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 6px;
    background: #f9fafb; // Grey 50
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.15s ease;
`;

// const Image = styled.img`
//     display: block;
//     width: 100%;
//     height: 100%;
//     border-radius: 6px;
//     background: #f9fafb; // Grey 50
//     box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
//     transition: box-shadow 0.15s ease;
// `;

const TitleInfo = styled.div`
    display: grid;
    gap: 8px;
`;

const Description = styled.div``;
const DescriptionText = styled(TypographyV2)({
    whiteSpace: 'pre-line',
});

const Meta = styled.div`
    display: flex;
    grid-area: meta;
    justify-content: flex-start;
    gap: 16px;
`;

const MetaIcon = styled.div``;

const MetaItem = styled.div`
    align-items: center;
    display: flex;
    gap: 4px;
    margin-bottom: 4px;
`;

const Timeline = styled.div`
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid white;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 8px;
    // box-shadow: 0px 0px 20px #CFD6E7;
`;

const TrackList = styled.div``;

const Actions = styled.div`
    grid-area: actions;
    display: grid;
    align-items: start;
    gap: 16px;
`;

const StickyActions = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    gap: 16px;
    flex-direction: column;
`;

const Footer = styled.div`
    grid-area: footer;
`;

const BackButton = styled.button`
    position: absolute;
    top: 8px;
    left: -12px;
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    display: inline-grid;
    place-content: center;

    @media (min-width: 1366px) {
        left: -68px;
    }
    cursor: pointer;
`;

const BackButtonIcon = styled.svg`
    width: 20px;
    height: 20px;
    stroke: white;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
`;

const Music = styled.div`
    grid-area: music;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const Info = styled.div`
    grid-area: info;
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: flex-start;
`;

function round5(x: number) {
    return Math.ceil(x / 5) * 5;
}

const SAVE_AS_SESSION_TEMPLATE_LABEL = 'Save Template';

const findNearestValue = (options: { value: number }[], valueToFind: number) => {
    const firstOption = head(options);
    const lastOption = last(options);
    if (!firstOption || !lastOption) return undefined;

    if (firstOption.value >= valueToFind) return firstOption;

    if (lastOption.value <= valueToFind) return lastOption;

    return options.find(({ value }, idx) => {
        if (value === valueToFind) return true;

        const nextOption = options[idx + 1];
        if (!nextOption) return true;
        if (value > valueToFind && valueToFind < nextOption.value) return true;
        return false;
    });
};

export type SchedulingStyle = 'now' | 'later' | 'specificTime';

const TemplateDetail: React.FC<TemplateDetailProps> = ({
    id,
    // imageUrl,
    emotionalities,
    title,
    subtitle,
    description,
    intensity,
    minDurationMins,
    maxDurationMins,
    onBackButtonClick,
    tracklistComponent,
    timelineComponent,
    duration,
    onDurationChange,
    renderType,
    onRenderTypeChange,
    onSessionNameChange,
    sessionName,
    contentStatuses,
    onContentStatusChange,
    sessionUse,
    musicalPreference,
    onMusicalPreferenceChange,
    onSessionUseChange,
    schedulingType,
    onSchedulingTypeChange,
    canClientStartEarly,
    onCanClientStartEarlyChange,
    scheduledStart,
    onScheduledStartChange,
    showVoControl,
    onVoChange,
    voiceover,
    onSubmit,
    submitDisabled,
    dosage,
    modality,
    administration,
    onSaveTemplate,
    canSaveTemplates,
    showAdminFeatures,
    isRealTimeRenderingEnabled,
}) => {
    const minRounded = round5(minDurationMins);
    const maxRounded = round5(maxDurationMins);

    const durationValues = new Set([minDurationMins, ...range(minRounded, maxRounded + 1, 5), maxDurationMins]);

    const durationOptions = [
        ...[...durationValues].map((value) => ({
            value,
            label: `${value} minutes`,
        })),
    ];

    useEffect(() => {
        const durationOption = durationOptions.find(({ value }) => value === duration);
        if (!durationOption) {
            const nearestValue = findNearestValue(durationOptions, duration);

            if (nearestValue) onDurationChange(nearestValue.value);
        }
    }, [duration, minDurationMins, maxDurationMins, onDurationChange]);

    const renderTypeOptions: ControlOption<SessionRenderType>[] = [
        {
            value: SessionRenderType.REAL_TIME,
            label: 'Realtime Contol',
            // label: (
            //     <div style={{ display: 'flex', gap: '4px' }}>
            //         Realtime Control
            //         {isRealTimeRenderingEnabled ? (
            //             ''
            //         ) : (
            //             <EvaIcon name="lock-outline" size={16} fill={'#555'} />
            //         )}
            //     </div>
            // ),
            disabled: !isRealTimeRenderingEnabled,
        },
        {
            value: SessionRenderType.PRE_RENDERED,
            label: 'Playback Only',
        },
    ];

    const sessionUseOptions = [
        {
            value: SessionScoreSessionUse.IN_PERSON,
            label: 'With Client',
        },
        {
            value: SessionScoreSessionUse.TESTING,
            label: 'Testing Only',
        },
    ];

    const contentStatusOptions: { label: string; value: 'Approved' | 'Submitted' | 'All' }[] = [
        {
            value: 'Approved',
            label: 'Approved',
        },
        {
            value: 'Submitted',
            label: 'Submitted',
        },
        {
            value: 'All',
            label: 'All',
        },
    ];

    const musicalPreferenceOptions = [
        {
            value: ClientVariablesMusicPreference.ACOUSTIC,
            label: 'Acoustic',
        },
        {
            value: ClientVariablesMusicPreference.MIXED,
            label: 'Mixed',
        },
        {
            value: ClientVariablesMusicPreference.ELECTRONIC,
            label: 'Electronic',
        },
    ];

    const schedulingOptions: { value: SchedulingStyle; label: string }[] = [
        {
            value: 'now',
            label: 'Now',
        },
        {
            value: 'later',
            label: 'An unspecified time in the future',
        },
        {
            value: 'specificTime',
            label: 'Later at a specific time',
        },
    ];

    const clientStartingTypeOptions: { value: number; label: string }[] = [
        {
            value: 0,
            label: 'Care-Provider Only',
        },
        {
            value: 1,
            label: 'Client or Care-Provider',
        },
    ];

    const voiceoverOptions: { value: string; label: string }[] = [
        {
            label: 'None',
            value: 'none',
        },
        {
            label: 'Josie Taylor Long (en-GB)',
            value: 'josie-taylor',
        },
        {
            label: 'Josie Taylor Short 01 (en-GB)',
            value: 'josie-taylor-short-01',
        },
        {
            label: 'Josie Taylor Short 02 (en-GB)',
            value: 'josie-taylor-short-02',
        },
    ];

    const [viewMoreOptions, setViewMore] = useState(false);

    return (
        <Container>
            <Header>
                {/* <Artwork>
                    <Image src={imageUrl} alt={title} />
                </Artwork> */}
                <Artwork>
                    <TemplateArtwork
                        templateId={id}
                        intensity={intensity}
                        primaryEmotion={emotionalities.primary}
                        secondaryEmotion={emotionalities.secondary}
                        tertiaryEmotion={emotionalities.tertiary}
                    />
                </Artwork>
                <TitleInfo>
                    <TypographyV2
                        element={'h3'}
                        display={'block'}
                        font={'tenor-sans'}
                        size={'display-sm'}
                        weight={'regular'}
                        truncated
                    >
                        {title}
                    </TypographyV2>
                    <TypographyV2 display={'block'} size={'text-md'} weight={'regular'} color={'grey-600'} truncated>
                        {subtitle}
                    </TypographyV2>
                </TitleInfo>
            </Header>
            {/* <Content> */}
            <Info>
                <Meta>
                    <MetaItem>
                        <MetaIcon>
                            <IntensityIcon intensity={intensity} />
                        </MetaIcon>
                        <TypographyV2 size={'text-sm'} weight={'regular'} color={'grey-400'}>
                            {intensity} intensity
                        </TypographyV2>
                    </MetaItem>
                    <MetaItem>
                        <MetaIcon>
                            <EvaIcon name="clock-outline" size={16} iconStyle={{ fill: '#667085' }} />
                        </MetaIcon>
                        <TypographyV2
                            display={'block'}
                            size={'text-sm'}
                            weight={'regular'}
                            color={'grey-400'}
                            truncated
                        >
                            {minDurationMins}-{maxDurationMins} mins
                        </TypographyV2>
                    </MetaItem>
                    {modality && (
                        <MetaItem>
                            <MetaIcon>
                                <ModalityIcon modality={modality} />
                            </MetaIcon>
                            <TypographyV2 size={'text-sm'} weight={'regular'} color={'grey-400'}>
                                {modality}
                            </TypographyV2>
                        </MetaItem>
                    )}
                    {administration && (
                        <MetaItem>
                            <MetaIcon>
                                <AdministrationIcon administration={administration} />
                            </MetaIcon>
                            <TypographyV2 size={'text-sm'} weight={'regular'} color={'grey-400'}>
                                {capitalize(administration)}
                            </TypographyV2>
                        </MetaItem>
                    )}
                    {dosage && (
                        <MetaItem>
                            <MetaIcon>
                                <DosageIcon dosage={dosage} />
                            </MetaIcon>
                            <TypographyV2 size={'text-sm'} weight={'regular'} color={'grey-400'}>
                                {capitalize(dosage)} dose
                            </TypographyV2>
                        </MetaItem>
                    )}
                </Meta>
                <Description>
                    <DescriptionText display={'block'} size={'text-md'} weight={'regular'} color={'grey-600'}>
                        {description}
                    </DescriptionText>
                </Description>
            </Info>
            <Music>
                <Timeline>{timelineComponent}</Timeline>
                <TrackList>{tracklistComponent}</TrackList>
            </Music>
            {/* </Content> */}
            <Actions>
                <StickyActions>
                    <DropdownControl
                        info={{ content: null, title: 'Duration' }}
                        canSave={false}
                        name="session_duration"
                        heading="Session Duration"
                        size={'large'}
                        colour={'dark'}
                        options={durationOptions}
                        value={duration}
                        onChange={onDurationChange}
                    />
                    <SegmentedControl
                        canSave={false}
                        name="Session Type"
                        heading="Session Type"
                        size={'large'}
                        colour={'dark'}
                        options={renderTypeOptions}
                        value={renderType}
                        onChange={onRenderTypeChange}
                        info={sessionTypeInfo}
                    />
                    <TextInput
                        variant="outlined"
                        size="small"
                        name="session_name"
                        heading="Session Name"
                        onChange={(e) => onSessionNameChange(e.target.value)}
                        value={sessionName}
                    />
                    <Button variant="clear-underlined" onClick={() => setViewMore(!viewMoreOptions)}>
                        {viewMoreOptions ? 'Fewer' : 'More'} options
                    </Button>
                    {viewMoreOptions ? (
                        <>
                            <SegmentedControl
                                canSave={false}
                                size={'large'}
                                heading="Musical Preference"
                                name="Musical Preference"
                                colour={'dark'}
                                info={musicalPreferenceInfo}
                                options={musicalPreferenceOptions}
                                value={musicalPreference}
                                onChange={onMusicalPreferenceChange}
                            />
                            <SegmentedControl
                                canSave={false}
                                size={'large'}
                                heading="Session Use"
                                name="Session Use"
                                colour={'dark'}
                                info={sessionUseInfo}
                                options={sessionUseOptions}
                                value={sessionUse}
                                onChange={onSessionUseChange}
                            />
                            {renderType === SessionRenderType.REAL_TIME && (
                                <DropdownControl
                                    canSave={false}
                                    size={'large'}
                                    heading="When is this session for?"
                                    name="scheduling"
                                    colour={'dark'}
                                    info={scheduleTypeInfo}
                                    options={schedulingOptions}
                                    value={schedulingType}
                                    onChange={onSchedulingTypeChange}
                                />
                            )}
                            {schedulingType === 'specificTime' && renderType === SessionRenderType.REAL_TIME && (
                                <DateTimeInput
                                    className="scheduled-start-picker"
                                    value={scheduledStart && setSeconds(new Date(scheduledStart), 0)}
                                    disabled={schedulingType !== 'specificTime'}
                                    onChange={(d: Date) => onScheduledStartChange(d ? d.getTime() : undefined)}
                                    clearIcon={null}
                                />
                            )}
                            {schedulingType !== 'now' && (
                                <DropdownControl
                                    canSave={false}
                                    size={'large'}
                                    heading="Who is able to start the session?"
                                    name="starting"
                                    info={clientControlInfo}
                                    colour={'dark'}
                                    options={clientStartingTypeOptions}
                                    value={canClientStartEarly ? 1 : 0} // thanks MUI
                                    onChange={(val) => onCanClientStartEarlyChange(!!val)}
                                />
                            )}
                            {showVoControl && (
                                <DropdownControl
                                    canSave={false}
                                    size={'large'}
                                    heading="Guided Voiceover"
                                    name="guided"
                                    colour={'dark'}
                                    options={voiceoverOptions}
                                    value={voiceover}
                                    onChange={onVoChange}
                                />
                            )}
                            {showAdminFeatures ? (
                                <>
                                    <SegmentedControl
                                        canSave={false}
                                        size={'large'}
                                        heading="Statuses to include"
                                        colour={'dark'}
                                        options={contentStatusOptions}
                                        value={contentStatuses}
                                        onChange={onContentStatusChange}
                                    />
                                </>
                            ) : null}
                        </>
                    ) : null}
                    <Button disabled={submitDisabled} variant="solid-blue" size="m" onClick={onSubmit}>
                        {schedulingType !== 'now' ? 'Schedule Session' : 'Start Session'}
                    </Button>
                    <Button
                        disabled={submitDisabled}
                        variant="outlined"
                        size="m"
                        style={{ opacity: canSaveTemplates ? 1 : 0.5 }}
                        icon={
                            canSaveTemplates ? (
                                <EvaIcon name="save-outline" size={16} fill={'#000'} />
                            ) : (
                                <EvaIcon name="lock-outline" size={16} fill={'#000'} />
                            )
                        }
                        onClick={onSaveTemplate}
                    >
                        {SAVE_AS_SESSION_TEMPLATE_LABEL}
                    </Button>
                </StickyActions>
            </Actions>
            <Footer></Footer>
            <BackButton onClick={onBackButtonClick}>
                <BackButtonIcon viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.8334 9.99935H4.16669M4.16669 9.99935L10 15.8327M4.16669 9.99935L10 4.16602"
                        stroke="#475467"
                    />
                </BackButtonIcon>
            </BackButton>
        </Container>
    );
};

export default TemplateDetail;

const sessionTypeInfo = {
    title: 'Session Type',
    content: (
        <>
            <TypographyV2 size="text-sm" color="grey-700">
                <strong>Adaptable</strong> will create music on the fly, allowing you to change the music during the
                session, attuning the music to your patients needs. To ensure the changes feel responsive, the buffer is
                quite short, and so is only appropriate for fast and stable internet connections.
            </TypographyV2>
            <br />
            <TypographyV2 size="text-sm" color="grey-700">
                <strong>Fixed</strong> will generate music for the entire session immediately. This can be useful for
                users with very slow and highly variable internet connections, as it provides a much longer buffer.
            </TypographyV2>
            <br />
        </>
    ),
};

const musicalPreferenceInfo = {
    title: 'Musical Preference',
    content: (
        <>
            <TypographyV2 element="p" size="text-sm" color="grey-700">
                Here you can select the dominant tone colour (or “timbre”) of the music created for your session.
            </TypographyV2>
            <TypographyV2 element="p" size="text-sm" color="grey-700">
                <strong>Acoustic</strong> will prioritise instruments such as wind, brass and strings, tuned percussion,
                as well as human voice.
            </TypographyV2>
            <TypographyV2 element="p" size="text-sm" color="grey-700">
                <strong>Electronic</strong> will prioritise electronic instruments such as synthesizers.
            </TypographyV2>
            <TypographyV2 element="p" size="text-sm" color="grey-700">
                <strong>Mixed acoustic/electronic</strong> includes both of the above, as well as acoustic instruments
                that have been processed electronically.
            </TypographyV2>
            <br />
        </>
    ),
};

const sessionUseInfo = {
    title: 'Session Use',
    content: (
        <>
            <TypographyV2 element="p" size="text-sm" color="grey-700">
                <strong>With client</strong> sessions are the usual, fully functional session, wheras{' '}
                <strong>Testing</strong> sessions have reduced functionality and don't count towards your monthly usage.
            </TypographyV2>
            <br />
        </>
    ),
};

const scheduleTypeInfo = {
    title: 'When is this session for',
    content: (
        <>
            <TypographyV2 element="p" size="text-sm" color="grey-700">
                <strong>Now</strong> will start the system in prelude mode which plays low intensity Stillness music for
                up to four hours. When in prelude mode you can start the session at any time.
            </TypographyV2>
            <TypographyV2 element="p" size="text-sm" color="grey-700">
                <strong>Save for later</strong> will add the session to the ‘scheduled for later’ tab in your sessions
                dashboard.
            </TypographyV2>
            <TypographyV2 element="p" size="text-sm" color="grey-700">
                <strong>A specific time</strong> will allow you to set a date and time for this session to start
                automatically.
            </TypographyV2>
            <br />
        </>
    ),
};

const clientControlInfo = {
    title: 'Who is able to start this session?',
    content: (
        <>
            <TypographyV2 size="text-sm" color="grey-700">
                For sessions saved for a future time, this determines whether the client is able to start the session
                from the remote streaming link, or whether the session can only be started from within the
                Care-Providers tool.
            </TypographyV2>
        </>
    ),
};
