import React, { useEffect } from 'react';
import { ReactElement } from 'react';
import { CoreEmotionalAtmosphere, TickDepth } from 'wavepaths-shared/core';

import { Connection, useSessionState } from '../../../common/hooks/useSessionTick';
import { Queueable } from '../actionQueue/useActionQueue';
import { isInDeepen } from '../depthSlider/depthUtils';
import { useDepth } from '../depthSlider/useDepth';
import CeaSelector from './CeaSelector';
import { useCEA } from './useCEA';

export interface ICeaControllerContainer {
    connection: Connection;
    setSnackbarContent: (message: string) => void;
    queueFunction: (queueable: Queueable) => void;
}

function CeaControllerContainer({ connection, queueFunction, ...props }: ICeaControllerContainer): ReactElement | null {
    const ceaState = useCEA(connection, queueFunction);
    const depthState = useDepth(connection, queueFunction);
    const { started } = useSessionState(connection);

    if (ceaState === 'loading' || depthState === 'loading') return null;

    return <CeaController {...ceaState} depth={depthState.targetDepth} {...props} sessionStarted={started} />;
}

interface ICeaController {
    targetCea?: CoreEmotionalAtmosphere;
    depth?: TickDepth;
    transitionTimeSecs?: number;
    sessionStarted: boolean;
    setTargetCea: (cea: CoreEmotionalAtmosphere, onQueueCallback?: () => void) => void;
    setSnackbarContent: (message: string) => void;
    error: string | null;
}

export const DISABLED_LABEL = 'This is disabled until you start your session';

export const DEEPEN_TRANSITION_LABEL = 'Automatically decreasing intensity as we move to ';

export const SNACKBAR_ERROR_COPY =
    'You are too close to the end of your current wave to make that change. Please wait or skip to the next wave and then make your adjustment';

const getDeepenLabel = (targetCea: CoreEmotionalAtmosphere) => DEEPEN_TRANSITION_LABEL + targetCea;

const CeaController = React.memo(
    ({
        targetCea,
        setTargetCea,
        transitionTimeSecs,
        sessionStarted,
        depth,
        setSnackbarContent,
        error,
    }: ICeaController): ReactElement => {
        useEffect(() => {
            if (error) {
                setSnackbarContent(SNACKBAR_ERROR_COPY);
            }
        }, [error, setSnackbarContent]);

        const changeCea = (cea: CoreEmotionalAtmosphere) => {
            if (!sessionStarted) {
                return setSnackbarContent(DISABLED_LABEL);
            }
            const onQueueCallback = isInDeepen(depth)
                ? () => {
                      setSnackbarContent(getDeepenLabel(cea));
                  }
                : undefined;
            setTargetCea(cea, onQueueCallback);
        };

        return <CeaSelector targetCea={targetCea} transitionTimeSecs={transitionTimeSecs} onChange={changeCea} />;
    },
);

export default CeaControllerContainer;
