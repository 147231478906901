import firebase from 'firebase';
import { take } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { APISessionStatus, SessionScoreModality, SessionType } from 'wavepaths-shared/core';

import { Button } from '@/component-library';
import LoadingCardRow from '@/component-library/components/LoadingTemplateCardRow';
import SectionHeading from '@/component-library/components/SectionHeading';
import TemplateCard from '@/component-library/components/TemplateCard';
import TemplateGrid from '@/component-library/components/TemplateGrid';
import TypographyV2 from '@/component-library/typography/TypographyV2';
import useListTemplates from '@/hooks/useListTemplates';

import { useAuthContext } from '../../auth';
import { LayoutContainer } from '../../LayoutContainer';
import { WelcomeProductTour } from '../lessons/WelcomeProductTour';
import useSessions from '../sessions/useSessions';
import { Section } from './types';
import { useHomeContent } from './useHomeContent';

type HomeSectionProps = {
    section: Section;
};

const getPathForListResource = (resource: string): string | undefined => {
    switch (resource) {
        case '/my/templates/saved': {
            return '/templates/saved';
        }
        case `/my/home/popular?modality=${SessionScoreModality.KETAMINE}`: {
            return `/templates?modality=${SessionScoreModality.KETAMINE}`;
        }
        case `/my/home/popular?modality=${SessionScoreModality.BREATHWORK}`: {
            return `/templates?modality=${SessionScoreModality.BREATHWORK}`;
        }
        case `/my/home/popular?modality=${SessionScoreModality.MDMA}`: {
            return `/templates?modality=${SessionScoreModality.MDMA}`;
        }
        case `/my/home/popular?modality=${SessionScoreModality.PSILOCYBIN}`: {
            return `/templates?modality=${SessionScoreModality.PSILOCYBIN}`;
        }
    }
};

const getPathBaseForEntityResource = (resource: Section['resourceType']): string | undefined => {
    switch (resource) {
        case 'savedTemplates': {
            return '/templates/saved/';
        }
        case 'templates': {
            return '/templates/';
        }
    }
};

const NoResultsContainer = styled.div`
    width: 100%;
    grid-column: 1/-1;
    text-align: center;
    height: 254px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

function HomeSection({ section }: HomeSectionProps) {
    const { firebaseUser } = useAuthContext();
    const { data, isLoading } = useListTemplates({ fbUser: firebaseUser, resourceUrl: section.resourceUrl });
    const templates = take(data?.entries, 4);
    const history = useHistory();

    const seeAllUrl = getPathForListResource(section.resourceUrl);
    const cardUrl = getPathBaseForEntityResource(section.resourceType);

    return (
        <>
            <SectionHeading
                text={section.title}
                moreLink={seeAllUrl ? { label: 'See All', action: () => history.push(seeAllUrl) } : undefined}
            />
            <TemplateGrid>
                {!isLoading ? (
                    templates.length ? (
                        templates.map((t) => (
                            <TemplateCard
                                id={t.id}
                                emotionalities={t.emotionalities}
                                key={'template-' + t.id}
                                onCardClick={() => {
                                    history.push(cardUrl + t.id);
                                }}
                                title={t.name}
                                subtitle={t.subtitle}
                                intensity={t.intensity}
                                modality={t.modality}
                                minDurationMins={t.durationMins.min}
                                maxDurationMins={t.durationMins.max}
                            ></TemplateCard>
                        ))
                    ) : (
                        <NoResultsContainer>
                            <TypographyV2 color="grey-400" size="text-md">
                                Sorry, there aren't any templates here...
                            </TypographyV2>
                            <Button size="l" onClick={() => history.push('/templates')} variant="clear-underlined">
                                Explore templates
                            </Button>
                        </NoResultsContainer>
                    )
                ) : (
                    <LoadingCardRow columns={4} />
                )}
            </TemplateGrid>
        </>
    );
}

function Home({ firebaseUser }: { firebaseUser: firebase.User }) {
    const homeContent = useHomeContent({ fbUser: firebaseUser });

    const scheduled = useSessions({
        fbUser: firebaseUser,
        sessionState: APISessionStatus.SCHEDULED,
        type: SessionType.ONE_ON_ONE,
        paginated: false,
    });

    const ongoing = useSessions({
        fbUser: firebaseUser,
        sessionState: APISessionStatus.ACTIVE,
        type: SessionType.ONE_ON_ONE,
        paginated: false,
    });

    const ended = useSessions({
        fbUser: firebaseUser,
        sessionState: APISessionStatus.ENDED,
        type: SessionType.ONE_ON_ONE,
        paginated: true,
    });

    const endedIntroSessions = useSessions({
        fbUser: firebaseUser,
        sessionState: APISessionStatus.ENDED,
        paginated: true,
    });

    const totalSessionCount =
        scheduled.totalCount + ongoing.totalCount + ended.totalCount + endedIntroSessions.totalCount;

    const shouldDisplayTour =
        !scheduled.loading &&
        !ongoing.loading &&
        !ended.loading &&
        !endedIntroSessions.loading &&
        totalSessionCount === 0;

    return (
        <>
            {homeContent?.sections.map((section) => (
                <HomeSection key={section.resourceUrl} section={section} />
            ))}
            {shouldDisplayTour && <WelcomeProductTour />}
        </>
    );
}

function HomeWithNav({ firebaseUser }: { firebaseUser: firebase.User }) {
    return (
        <LayoutContainer>
            <Home firebaseUser={firebaseUser} />
        </LayoutContainer>
    );
}

export default HomeWithNav;
