import styled from '@emotion/styled';
import { Card, CardContent } from '@material-ui/core';
import React from 'react';

import { Button, Typography } from '@/component-library';

import breathingLines from '../../images/BreathingLines.svg';

interface IIntroSessionCard {
    title: string;
    info: string;
    duration: string;
    disabled?: boolean;
    onClickPlaySessionNow: () => void;
}

const Container = styled(Card)({
    backgroundImage: `url(${breathingLines})`,
    backgroundSize: 'contatain',
    width: 280,
    height: 150,
    marginRight: 30,
    display: 'inline-block',
    position: 'relative',
    userSelect: 'none',
    opacity: '100%',
    boxShadow: '0px 0px 10px #CFD6E7',
    border: '1px solid white',
    borderRadius: '8px',
    color: 'rgb(44, 57, 88)',
});

const Text = styled(Typography)({
    color: '#2c3958',
});

const IntroSessionCard = ({
    title,
    duration,
    info,
    disabled = false,
    onClickPlaySessionNow,
}: IIntroSessionCard): JSX.Element => {
    return (
        <Container>
            <CardContent>
                <Text variant="h6">{title}</Text>
            </CardContent>
            <div style={{ padding: '0px 16px', height: '11.1px' }}>
                <Text variant="body3">{info}</Text>
            </div>
            <div style={{ position: 'absolute', bottom: '25px', padding: '0px 16px', height: '11.1px' }}>
                <Text variant="body3">{duration}</Text>
            </div>
            <div
                style={{
                    position: 'absolute',
                    right: 16,
                    bottom: 16,
                    height: '30px',
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <>
                    {!disabled ? (
                        <Button variant={'solid-blue'} onClick={onClickPlaySessionNow}>
                            Go
                        </Button>
                    ) : (
                        <Button variant="solid-light" disabled={true} onClick={onClickPlaySessionNow}>
                            Starting
                        </Button>
                    )}
                </>
            </div>
        </Container>
    );
};

export default IntroSessionCard;
