import { format, isThisMonth, isThisWeek, isThisYear, isToday, isYesterday } from 'date-fns';
import firebase from 'firebase';
import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { FirebaseUser, SessionHeader } from 'wavepaths-shared/core';

import { Typography } from '@/component-library';

import { isLastWeek, weekStartsOn } from '../../../dateUtilsV2';
import SessionCardSkeleton from '../SessionCardSkeleton';
import EndedSessionCard from './EndedSessionCard';
import { IEndedSessions } from './useEndedSessions';
interface EndedSessionsTableProps {
    endedSessionsInterface: IEndedSessions;
    fbUser: firebase.User;
    signups: { [sessionId: string]: FirebaseUser[] };
    isAdmin: boolean;
}

interface IHeaderAndSession {
    header: string;
    sessions: SessionHeader[];
}

export const genArrOfHeadersAndSessions = (sessions: SessionHeader[]): IHeaderAndSession[] => {
    const sessionsToday = sessions.filter((session) => session.endedAt && isToday(session.endedAt));
    const sessionsYesterday = sessions.filter((session) => session.endedAt && isYesterday(session.endedAt));
    const sessionsThisWeek = sessions.filter(
        (session) => session.endedAt && isThisWeek(session.endedAt, { weekStartsOn }),
    );
    const sessionsLastWeek = sessions.filter((session) => session.endedAt && isLastWeek(session.endedAt));
    const sessionsThisMonth = sessions.filter((session) => session.endedAt && isThisMonth(session.endedAt));
    const sessionsThisYear = sessions.filter((session) => session.endedAt && isThisYear(session.endedAt));

    const restOfThisWeek: SessionHeader[] = _.differenceWith(
        sessionsThisWeek,
        _.flatten([sessionsToday, sessionsYesterday]),
        _.isEqual,
    );

    const restOfThisMonth: SessionHeader[] = _.differenceWith(
        sessionsThisMonth,
        _.flatten([sessionsYesterday, sessionsThisWeek, sessionsLastWeek]),
        _.isEqual,
    );

    const restOfTheYear = _.differenceWith(
        sessionsThisYear,
        _.flatten([sessionsYesterday, sessionsThisWeek, sessionsLastWeek, sessionsThisMonth]),
        _.isEqual,
    );
    const restOfTheYearGroupedByMonth = _.groupBy(
        restOfTheYear,
        (session) => session.endedAt && format(session.endedAt, 'MMMM'),
    );
    const restOfTheYearMapped = _.map(restOfTheYearGroupedByMonth, (sessions, key) => ({ header: key, sessions }));

    const restOfSessions = _.differenceWith(sessions, sessionsThisYear, _.isEqual);
    const restOfSessionsGroupedByMonthAndYear = _.groupBy(
        restOfSessions,
        (session) => session.endedAt && format(session.endedAt, "MMM ''yy"),
    );
    const restOfSessionsMapped = _.map(restOfSessionsGroupedByMonthAndYear, (sessions, key) => ({
        header: key,
        sessions,
    }));

    return [
        { header: 'Today', sessions: sessionsToday },
        { header: 'Yesterday', sessions: sessionsYesterday },
        { header: 'Earlier this Week', sessions: restOfThisWeek },
        { header: 'Last Week', sessions: sessionsLastWeek },
        { header: 'Earlier this Month', sessions: restOfThisMonth },
        ...restOfTheYearMapped,
        ...restOfSessionsMapped,
    ];
};

const EndedSessionsTable: React.FC<EndedSessionsTableProps> = ({
    endedSessionsInterface: { sessions, previousPage, nextPage, totalPageCount, currentPageIndex, loading },
    signups,
    isAdmin,
    fbUser,
}) => {
    const [copiedIntegrationLinkId, setCopiedIntegrationLinkId] = useState<string>();
    const [disableHide, setDisableHide] = useState<boolean>(false);
    useEffect(() => {
        if (totalPageCount && totalPageCount > 0) {
            setDisableHide(true);
        }
    }, [totalPageCount]);

    if (loading) {
        return (
            <div style={{ width: '100%' }}>
                <SessionCardSkeleton />
            </div>
        );
    }

    if (!disableHide && sessions.length === 0) {
        return <></>;
    }

    const headersAndSessions = genArrOfHeadersAndSessions(sessions).filter(({ sessions }) => sessions.length !== 0);

    const currentPage = currentPageIndex + 1;
    return (
        <>
            <div style={{ width: '100%' }}>
                {headersAndSessions.map(({ header, sessions }, idx) => (
                    <Fragment key={`${header}${idx}`}>
                        <div style={{ marginBottom: '17px' }}>
                            <Typography variant="subtitle1">{header}</Typography>
                        </div>
                        {sessions.map((session) => (
                            <div key={`${session.id}`} style={{ marginBottom: '16px' }}>
                                <EndedSessionCard
                                    key={session.id}
                                    session={session}
                                    fbUser={fbUser}
                                    setCopiedIntegrationLinkId={setCopiedIntegrationLinkId}
                                    copiedIntegrationLinkId={copiedIntegrationLinkId}
                                    signups={signups}
                                    isAdmin={isAdmin}
                                />
                            </div>
                        ))}
                        {idx !== headersAndSessions.length - 1 && <div style={{ height: '52px' }} />}
                    </Fragment>
                ))}

                {totalPageCount > 1 && (
                    <div className="pageControls">
                        <button
                            className="lastPageButton"
                            data-testid="lastPageButton"
                            onClick={previousPage}
                            disabled={currentPage <= 1}
                        ></button>
                        <Typography
                            variant="body1"
                            data-testid="displayedPageNumber"
                        >{`${currentPage}/${totalPageCount}`}</Typography>
                        <button
                            data-testid="nextPageButton"
                            className="nextPageButton"
                            onClick={nextPage}
                            disabled={currentPage >= totalPageCount}
                        ></button>
                    </div>
                )}
            </div>
        </>
    );
};

export default EndedSessionsTable;
