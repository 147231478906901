import { makeStyles } from '@material-ui/core/styles';
import React, { PropsWithChildren } from 'react';

export interface SessionProgressMeterProps {
    timeElapsed: number;
    totalDuration: number;
}

const useStyles = makeStyles({
    wrapper: {
        height: 'auto',
        transition: 'height: 0.15s ease',
    },
    totalTime: {
        width: '100%',
        margin: '16px 0',
        backgroundColor: 'rgba(215,224,245,0.6)',
        borderRadius: 4,
    },
    elapsedTime: {
        width: 'auto',
        height: '4px',
        backgroundColor: 'rgba(176,190,223,1)',
        boxShadow: '0px 0px 6px rgba(176,190,223,0.75)',
        borderRadius: 4,
        transition: 'width 0.3s ease-out',
    },
});

const SessionProgressMeter = ({
    timeElapsed,
    totalDuration,
}: PropsWithChildren<SessionProgressMeterProps>): JSX.Element => {
    const classes = useStyles();
    const percentComplete = Math.round((timeElapsed / totalDuration) * 100);
    const percentCompleteClamped = Math.min(100, percentComplete);

    return (
        <div className={classes.wrapper}>
            {timeElapsed > 0 && (
                <div className={classes.totalTime}>
                    <div className={classes.elapsedTime} style={{ width: `${percentCompleteClamped}%` }} />
                </div>
            )}
        </div>
    );
};

export default SessionProgressMeter;
