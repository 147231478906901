import firebase from 'firebase';
import useSWR from 'swr';

import { getSavedTemplateById, IGetSavedTemplateReturn } from '../api/savedTemplatesApi';

export interface TemplateDetailParams {
    templateId: string;
}

type IUseSavedTemplateProps = {
    id: string;
    fbUser?: firebase.User;
};

export function useSavedTemplate({ fbUser, id }: IUseSavedTemplateProps) {
    const { data } = useSWR<IGetSavedTemplateReturn, Error>(
        fbUser ? [`/saved-template`, id] : null,
        fbUser ? () => getSavedTemplateById({ fbUser, id }) : null,
    );

    return {
        template: data,
    };
}
