export type TemplateProgram = {
    id: string;
    name: string;
    useCase: string;
    description: string;
    actions: { link: string; title: string }[];
    templates: { id?: string; name: string }[];
};

export const useTemplatePrograms = (): { programs: TemplateProgram[] } => {
    return {
        programs: PROGRAMS,
    };
};

const PROGRAMS = [
    {
        id: 'k-prog-1',
        name: 'Ketamine Program 1',
        useCase:
            'This program is specifically designed to support patients experiencing elevated levels of anxiety, distress or hypervigilance. More broadly, this program is created to offer a sequence of musical experiences that is primarily gentle and calm.',
        description:
            'Rooted in compassion and safety, this therapeutic journey embraces a gentle and nurturing approach, providing a sanctuary of calm and re-assurance. Featuring Stillness, Vitality and Bittersweet, and with the emotional intensity intentionally kept low to medium, this Program ensures a soothing experience throughout.',
        actions: [
            {
                link: '/templates?modality=Ketamine&intensity=Low&intensity=Medium&administration=INTRAMUSCULAR',
                title: 'Intramuscular Templates',
            },
            {
                link: '/templates?modality=Ketamine&intensity=Low&intensity=Medium&administration=INTRAVENOUS',
                title: 'IV Templates',
            },
            {
                link: '/templates?modality=Ketamine&intensity=Low&intensity=Medium&administration=NASAL',
                title: 'Nasal Templates',
            },
            {
                link: '/templates?modality=Ketamine&intensity=Low&intensity=Medium&administration=SUBLINGUAL',
                title: 'Sublingual Templates',
            },
        ],
        templates: [
            {
                name: 'K1.1 Journey',
            },
            {
                name: 'K1.8 Journey Into Tranquility',
            },
            {
                name: 'K2.10 Open Heart',
            },
            {
                name: 'K2.1 Journey',
            },
            {
                name: 'K2.8 Bridging Journey',
            },
            {
                name: 'K2.1 Journey',
            },
        ],
    },
    {
        id: 'k-prog-2',
        name: 'Ketamine Program 2',
        useCase: 'This program is tailored to support patients expressing depressive features or hypovigilance.',
        description:
            'Beginning with a gentle emotional activation, gradually guiding patients towards a place of comfort over the course of the first three sessions. As the program progresses, from the third to the fifth sessions, we introduce music of higher emotional intensity. These templates aim to gently stir and uplift emotions, providing a therapeutic space for patients to explore, express and process their subjective experiences with empathy and understanding. The program ends with the sixth session, on a deeply soothing note.',
        actions: [
            {
                link: '/templates?modality=Ketamine&intensity=Low&intensity=Medium&administration=INTRAMUSCULAR',
                title: 'Intramuscular Templates',
            },
            {
                link: '/templates?modality=Ketamine&intensity=Low&intensity=Medium&administration=INTRAVENOUS',
                title: 'IV Templates',
            },
            {
                link: '/templates?modality=Ketamine&intensity=Low&intensity=Medium&administration=NASAL',
                title: 'Nasal Templates',
            },
            {
                link: '/templates?modality=Ketamine&intensity=Low&intensity=Medium&administration=SUBLINGUAL',
                title: 'Sublingual Templates',
            },
        ],
        templates: [
            {
                name: 'K2.1 Journey',
            },
            {
                name: 'K2.3 Percussive',
            },
            {
                name: 'K2.8 Bridging Journey',
            },
            {
                name: 'K3.8 Bridging Journey',
            },
            {
                name: 'K3.1 Journey',
            },
            {
                name: 'K2.10 Open Heart',
            },
        ],
    },
    {
        id: 'k-prog-3',
        name: 'Ketamine Program 3',
        useCase:
            'This program is specifically designed to support patients experiencing elevated levels of anxiety, distress or hypervigilance. More broadly, this program is created to offer a sequence of musical experiences that is primarily gentle and calm. Program 3 represents an even more gentle alternative to Program 1.',
        description:
            'This therapeutic journey offers a peaceful atmosphere throughout. Featuring Stillness, Vitality and Bittersweet, and with the emotional intensity intentionally kept low to medium, this Program offers the most soothing non-directive musical guidance.',
        actions: [
            {
                link: '/templates?modality=Ketamine&intensity=Low&intensity=Medium&administration=INTRAMUSCULAR',
                title: 'Intramuscular Templates',
            },
            {
                link: '/templates?modality=Ketamine&intensity=Low&intensity=Medium&administration=INTRAVENOUS',
                title: 'IV Templates',
            },
            {
                link: '/templates?modality=Ketamine&intensity=Low&intensity=Medium&administration=NASAL',
                title: 'Nasal Templates',
            },
            {
                link: '/templates?modality=Ketamine&intensity=Low&intensity=Medium&administration=SUBLINGUAL',
                title: 'Sublingual Templates',
            },
        ],
        templates: [
            {
                name: 'K1.1 Journey',
            },
            {
                name: 'K1.8 Journey Into Tranquility',
            },
            {
                name: 'K2.10 Open Heart',
            },
            {
                name: 'K2.1 Journey',
            },
            {
                name: 'K2.8 Bridging Journey',
            },
            {
                name: 'K2.1 Journey',
            },
        ],
    },
    {
        id: 'client-prep-prog-3',
        name: 'Client Preparation Program',
        useCase:
            "This program is created to help increase your clients' readiness for their first therapy session by the use of deep listening. Originally this program was developed in the context for Psychedelic Therapy, but it can be used for other use-cases too.",
        description:
            'This Program offers 3 distinct listening experiences, with the first session being short and low in emotional intensity, primarily serving as an initial toe-dipping experience to experience what it is like to lie with an eye-mask listening to music. The second session is longer, but still primarily soft, with the third session offering a long introspective session including more emotional intense music.',
        actions: [
            {
                link: '/templates?modality=Preparation',
                title: 'See Templates',
            },
        ],
        templates: [
            {
                name: 'Client Preparation 1',
            },
            {
                name: 'Client Preparation 2',
            },
            {
                name: 'Client Preparation 3',
            },
        ],
    },
    {
        id: 'psilo-prog-1',
        name: 'Psilocybin Program 1',
        useCase:
            'This program is created for facilitators planning to run more than one session with their client using Psilocybin. This program is tailored to support patients expressing depressive features or hypovigilance.',
        description:
            'For the first session we suggest beginning with our Journey 1 template. This template presents a diverse and dynamic musical experience that engages emotions deeply. Notably, it intentionally omits the emotionality of Tension, which can sometimes be perceived as more challenging.\n\nHowever, please be aware that the Journey template does feature emotionally intense compositions. We recommend reviewing and customizing the template as necessary to align it with your client’s unique needs.For a second session, we recommend to select one of the four templates that display a focus on one of the four emotionalities: Stillness, Bittersweet, Tension or Vitality - Based on what is most present for your client to process. We also recommend reviewing and customising these to align with your client’s unique therapeutic needs.',
        actions: [
            {
                link: '/templates?modality=Psilocybin',
                title: 'See Templates',
            },
        ],
        templates: [
            {
                name: '3.1 Journey',
            },
            {
                name: 'Stillness, Bittersweet, Tension or Vitality',
            },
        ],
    },
    {
        id: 'psilo-prog-2',
        name: 'Psilocybin Program 2',
        useCase:
            'This program is created for facilitators planning to run more than one session with their client using Psilocybin. This program is specifically designed to support patients experiencing elevated levels of anxiety, distress or hypervigilance. More broadly, this program is created to offer a sequence of musical experiences that starts the most gentle and calm.',
        description:
            "For your initial session, we suggest choosing one of the templates that spotlight the themes of Stillness or Vitality. These templates cultivate peacefulness and reassuring emotions, providing a gentle introduction to the experience. Based on your client's experience, you can change the emotionality and intensity of subsequent waves as the session progresses. Moving forward, we recommend the Journey 1 template for your second session. This template presents a diverse and dynamic musical array, featuring more deepening waves and bridges, and is designed to more strongly invite emotional expression. Notably, the Journey 1 template does not incorporate the emotionality Tension, yet still includes moments that may be experienced as more challenging. We encourage you to take the time to review and customise the template as needed, ensuring it aligns approporiately with your client's experience",
        actions: [
            {
                link: '/templates?modality=Psilocybin',
                title: 'See Templates',
            },
        ],
        templates: [
            {
                name: 'Stillness or Vitality',
            },
            {
                name: '3.1 Journey',
            },
        ],
    },
    {
        id: 'mdma-prog-1',
        name: 'MDMA Program 1',
        useCase:
            'This program is created for facilitators planning to run more than one session with their client using MDMA. This program is tailored to support patients expressing depressive features or hypovigilance.',
        description:
            'For the first session we suggest beginning with our Journey 1 template. This template presents a diverse and dynamic musical experience that engages emotions deeply. Notably, it intentionally omits the emotionality of Tension, which can sometimes be perceived as more challenging.\n\nHowever, please be aware that the Journey template does feature emotionally intense compositions. We recommend reviewing and customizing the template as necessary to align it with your client’s unique needs.\n\nFor a second session, we recommend to select one of the four templates that display a focus on one of the four emotionalities: Stillness, Bittersweet, Tension or Vitality - Based on what is most present for your client to process. We also recommend reviewing and customising these to align with your client’s unique therapeutic needs.',
        actions: [
            {
                link: '/templates?modality=MDMA',
                title: 'See Templates',
            },
        ],
        templates: [
            {
                name: '3.1 Journey',
            },
            {
                name: 'Stillness, Bittersweet, Tension or Vitality',
            },
        ],
    },
    {
        id: 'mdma-prog-2',
        name: 'MDMA Program 2',
        useCase:
            'This program is created for facilitators planning to run more than one session with their client using MDMA. This program is specifically designed to support patients experiencing elevated levels of anxiety, distress or hypervigilance. More broadly, this program is created to offer a sequence of musical experiences that commences with the utmost gentleness and tranquillity.',
        description:
            "For your initial session, we suggest choosing one of the templates that spotlight the themes of Stillness or Vitality. These templates cultivate peacefulness and reassuring emotions, providing a gentle introduction to the experience. Based on your client's experience, you can change the emotionality and intensity of subsequent waves as the session progresses.\n\nMoving forward, we recommend the Journey 1 template for your second session. This template presents a diverse and dynamic musical array, featuring more deepening waves and bridges, and is designed to more strongly invite emotional expression. \n\nNotably, the Journey 1 template does not incorporate the emotionality Tension, yet still includes moments that may be experienced as more challenging. We encourage you to take the time to review and customise the template as needed, ensuring it aligns approporiately with your client's experience.",
        actions: [
            {
                link: '/templates?modality=MDMA',
                title: 'See Templates',
            },
        ],
        templates: [
            {
                name: 'Stillness or Vitality',
            },
            {
                name: '3.1 Journey',
            },
        ],
    },
];
