import styled from '@emotion/styled';
import { findIndex, negate, range } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import useMeasure from 'react-use-measure';
import {
    CoreEmotionalAtmosphere,
    isPrePostLudePathScore,
    isScheduledWavepath,
    PathScore,
    SessionScoreEmotionalIntensity,
    Wavepath,
} from 'wavepaths-shared/core';

import { Typography } from '@/component-library';
import useScoreLibrary from '@/hooks/useScoreLibrary';

import { useAuthContext } from '../../../auth';
import {
    isAnyWaveSelected,
    isWaveSelected,
    selectNoWave,
    selectWave,
    WaveSelection,
} from '../../../pages/inSession/autoGuide/waveSelection';
import WaveCard from './WaveCard';
import WaveQueueEditorTrackingHandlers from './WaveQueueEditorTrackingHandlers';

export type IWaveQueueEditorProps = {
    shouldShow: boolean;
    shouldIncludePrelude?: boolean;
    shouldDisplayHeader?: boolean;
    currentWaveIndex: number;
    wavepaths: Wavepath[];
    waveSelection: WaveSelection;
    setWaveSelection: (selection: WaveSelection, force?: boolean) => void;
    skipToWave?: (index: number) => void;
    updatePathAtIndex: (index: number, wp: Pick<Wavepath, 'pathId' | 'pathScore' | 'duration'>) => void;
    addPathAtIndex: (index: number, wp: Pick<Wavepath, 'pathId' | 'pathScore' | 'duration'>) => void;
    removePathAtIndex: (index: number, name?: string) => void;
    movePathToIndex: (index: number, targetIndex: number) => void;
    setSnackbarContent: (content: string) => void;
    trackingHandlers: WaveQueueEditorTrackingHandlers;
    hasBackground?: boolean;
};

const doesPathMatchEmotion = (emotionalAtmosphere: CoreEmotionalAtmosphere) => (pathScore: PathScore) => {
    return 'emotion' in pathScore ? pathScore.emotion === emotionalAtmosphere : false;
};

const doesPathMatchIntensity = (emotionalIntensity: SessionScoreEmotionalIntensity) => (pathScore: PathScore) => {
    return pathScore?.selectionCriteria?.emotionalIntensity === emotionalIntensity;
};

export const DEFAULT_WAVE_TO_ADD_ID = 's_cea1_a';
export const NEXT_UP_HEADER_TEXT = 'Next up';

const CARD_HEIGHT_PADDING = 8;

const Container = styled.div<{ shouldShow?: boolean; hasBackground?: boolean }>(({ shouldShow, hasBackground }) => ({
    overflowY: 'auto',
    scrollbarGutter: 'stable',
    opacity: shouldShow ? 1 : 0,
    height: shouldShow ? 'auto' : 0,
    transition: 'opacity 0.15s ease',
    margin: '0 auto',
    width: '100%',
    maxWidth: 650,
    background: hasBackground ? 'rgba(255,255,255,0.6)' : 'transparent',
    marginTop: hasBackground ? -8 : undefined,
    paddingTop: hasBackground ? 8 : undefined,
}));

const Heading = styled.div({
    boxSizing: 'border-box',
    padding: '16px 8px 16px 24px',
    width: '100%',
});

const WaveCardWrapper = styled(animated.div)({});

const WaveQueueEditor = ({
    shouldShow,
    currentWaveIndex,
    wavepaths,
    waveSelection,
    setWaveSelection,
    skipToWave,
    updatePathAtIndex,
    removePathAtIndex,
    movePathToIndex,
    addPathAtIndex,
    setSnackbarContent,
    shouldIncludePrelude,
    trackingHandlers,
    shouldDisplayHeader,
    hasBackground,
}: IWaveQueueEditorProps): JSX.Element | null => {
    const { firebaseUser } = useAuthContext();
    const scorelibrary = useScoreLibrary(firebaseUser);

    const pathScores = scorelibrary.loading === false ? scorelibrary.pathScores : [];

    const defaultPathScoreToAdd: PathScore =
        pathScores.find((pathScore: PathScore) => pathScore.id === DEFAULT_WAVE_TO_ADD_ID) ??
        pathScores
            .filter(negate(isPrePostLudePathScore))
            .filter(doesPathMatchIntensity(SessionScoreEmotionalIntensity.LOW))
            .filter(doesPathMatchEmotion(CoreEmotionalAtmosphere.STILLNESS))[0];

    const scheduledWavepaths = wavepaths.filter(isScheduledWavepath);

    useEffect(() => {
        if (isAnyWaveSelected(waveSelection)) {
            const selectedWaveIndex = findIndex(wavepaths, (w) => isWaveSelected(waveSelection, w.id));
            // TODO: clean up, so we don't have this logic in two places
            if (
                (shouldIncludePrelude && selectedWaveIndex < currentWaveIndex) ||
                (!shouldIncludePrelude && selectedWaveIndex <= currentWaveIndex)
            )
                setWaveSelection(selectNoWave(), true);
        }
    }, [wavepaths, currentWaveIndex, waveSelection]);

    const [wavecardHeights, setWavecardHeights] = useState<{ [id: string]: number }>({});
    const wavepathsWithHeights = wavepaths.map((wavepath) => ({
        ...wavepath,
        height: wavecardHeights[wavepath.id] ?? 0,
    }));
    const onUpdateWavecardHeight = useCallback((id: string, height: number) => {
        setWavecardHeights((prevHeights) => ({ ...prevHeights, [id]: height + CARD_HEIGHT_PADDING }));
    }, []);

    const transitions = useTransition(wavepathsWithHeights, {
        from: { opacity: 0, height: 0 },
        enter: (item: Wavepath & { height: number }) => [{ height: item.height }, { opacity: 1 }],
        update: (item: Wavepath & { height: number }) => ({
            opacity: 1,
            height: item.height,
        }),
        leave: [{ opacity: 0 }, { height: 0 }],
        keys: (item: Wavepath) => item.id,
    });

    const containerRef = useRef<HTMLDivElement>(null);
    const [headerRef, { height: headerHeight }] = useMeasure();
    useEffect(() => {
        if (isAnyWaveSelected(waveSelection)) {
            const selectedWaveIndex = findIndex(wavepaths, (w) => isWaveSelected(waveSelection, w.id));
            const boxIndexesToScroll = range(currentWaveIndex + 1, selectedWaveIndex);
            const waveIdsToScroll = boxIndexesToScroll.map((i) => wavepaths[i]?.id);
            const heightToScroll = waveIdsToScroll.reduce((acc, id) => acc + (wavecardHeights[id] ?? 0), 0);
            const scrollTo = headerHeight + heightToScroll;
            containerRef.current?.scrollTo?.({ top: scrollTo, behavior: 'smooth' });
        }
    }, [waveSelection, wavepaths, wavecardHeights, currentWaveIndex, headerHeight]);

    const onUpdateWave = (index: number, oldWave: Wavepath) => (newWave: Wavepath) => {
        updatePathAtIndex(index, newWave);

        trackingHandlers.updateWave({ index, newWave, oldWave, noOfWaves: wavepaths.length });
    };

    const onAddWave = (index: number, wave: Wavepath) => () => {
        setWaveSelection(selectNoWave());
        addPathAtIndex(
            index,
            defaultPathScoreToAdd
                ? {
                      pathId: defaultPathScoreToAdd.id,
                      pathScore: defaultPathScoreToAdd,
                  }
                : {
                      pathId: wave.pathId,
                      pathScore: wave.pathScore,
                  },
        );
        trackingHandlers.onAddWave({ index, noOfWaves: wavepaths.length });
    };

    const onSkipToWave = (skipToIndex: number, currentWaveIndex: number) => () => {
        if (!skipToWave) return;
        skipToWave(skipToIndex);
        trackingHandlers.skipToWave({ skipToIndex, currentWaveIndex, wavepaths });
    };

    const onRemoveWave = (index: number, wave: Wavepath) => () => {
        removePathAtIndex(index, wave.pathScore.name);
        trackingHandlers.removeWave({ index, wave, noOfWaves: wavepaths.length });
    };

    const onMoveWaveUp = (index: number, currentWaveIndex: number, wave: Wavepath) => () => {
        if (index > currentWaveIndex + 1) {
            movePathToIndex(index, index - 1);
            trackingHandlers.moveWaveUp({ index, wave, noOfWaves: wavepaths.length });
        } else {
            setSnackbarContent('You can’t move this wave any further up the list');
            trackingHandlers.waveCannotBeMovedFurtherUp({ index, wave, noOfWaves: wavepaths.length });
        }
    };

    const onMoveWaveDown = (index: number, wave: Wavepath) => () => {
        if (index < scheduledWavepaths.length) {
            movePathToIndex(index, index + 1);
            trackingHandlers.moveWaveDown({ index, wave, noOfWaves: wavepaths.length });
        } else {
            setSnackbarContent('You can’t move this wave any further down the list');
            trackingHandlers.waveCannotBeMovedFurtherDown({ index, wave, noOfWaves: wavepaths.length });
        }
    };

    return (
        <Container
            className={'tour-waveQueue'}
            shouldShow={shouldShow}
            ref={containerRef}
            hasBackground={hasBackground}
        >
            {shouldDisplayHeader && wavepaths.length > 0 && (
                <Heading ref={headerRef}>
                    <Typography variant="subtitle2">{NEXT_UP_HEADER_TEXT}</Typography>
                </Heading>
            )}
            {transitions((style, wavepath, transition, index) => {
                const shouldDisplay = shouldIncludePrelude ? index >= currentWaveIndex : index > currentWaveIndex;
                return shouldDisplay ? (
                    <WaveCardWrapper key={wavepath.id} style={{ ...(style as any) }}>
                        <WaveCard
                            onUpdateWave={onUpdateWave(index, wavepath)}
                            onAddWave={onAddWave(index, wavepath)}
                            onSkipToWave={skipToWave ? onSkipToWave(index, currentWaveIndex) : undefined}
                            onRemoveWave={onRemoveWave(index, wavepath)}
                            onMoveWaveUp={onMoveWaveUp(index, currentWaveIndex, wavepath)}
                            onMoveWaveDown={onMoveWaveDown(index, wavepath)}
                            key={`${wavepath.id}`}
                            wave={wavepath}
                            previousWave={index > 0 ? wavepaths[index - 1] : undefined}
                            waveSelection={transition.phase === 'leave' ? selectWave(wavepath) : waveSelection}
                            onSetWaveSelection={setWaveSelection}
                            onHeightChanged={onUpdateWavecardHeight}
                        />
                    </WaveCardWrapper>
                ) : null;
            })}
        </Container>
    );
};

export default React.memo(WaveQueueEditor);
WaveQueueEditor.displayName = 'WaveQueueEditor';
