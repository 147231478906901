import { findLast, first, get, isNumber } from 'lodash';
import {
    CoreEmotionalAtmosphere,
    EnterStageEvent,
    PathScore,
    PathType,
    SessionContentSwitchConfig,
    SessionStagePreset,
    TimestampedSessionEvent,
} from 'wavepaths-shared/core';
import { convertVaToCea } from 'wavepaths-shared/domain/wavepath';

import { getCEAColour } from '../../common/util/ceaColours';

export const getRelativeTime = (evt: TimestampedSessionEvent, sessionLog: TimestampedSessionEvent[]) => {
    const firstEvent = first(sessionLog)!;
    const firstTime = firstEvent.dspTimeMs ?? firstEvent.timestamp;
    const thisTime = evt.dspTimeMs ?? evt.timestamp;
    return thisTime - firstTime;
};

export const getCEAAtIndex = (
    evtIndex: number,
    log: TimestampedSessionEvent[],
): CoreEmotionalAtmosphere | undefined => {
    const latestAttributesChange = findLast(
        log,
        (e) => hasMusicAttributes(e) || isPlaylistEnterEvent(e) || isExperimentalEnterEvent(e),
        evtIndex,
    ) as { contentSwitch: SessionContentSwitchConfig } | undefined;
    const musicAttributes = latestAttributesChange?.contentSwitch?.musicAttributeTargets;
    if (musicAttributes && isNumber(musicAttributes['Valence Potential']) && isNumber(musicAttributes.Arousal)) {
        return convertVaToCea(musicAttributes['Valence Potential'], musicAttributes.Arousal);
    } else {
        return undefined;
    }
};

export const getCEAColourAtIndex = (evtIndex: number, log: TimestampedSessionEvent[]) => {
    const cea = getCEAAtIndex(evtIndex, log);
    if (cea) {
        return getCEAColour(cea);
    } else {
        return '#666';
    }
};

export const formatPathName = (path: PathScore) => {
    if (path.type === PathType.GENERATIVE && path.selectionCriteria?.emotionalIntensity && path.name !== 'Silence') {
        return `${path.name} - ${path.selectionCriteria.emotionalIntensity} Intensity`;
    } else {
        return `${path.name}`;
    }
};

export const getPathNameAtIndex = (evtIndex: number, log: TimestampedSessionEvent[]) => {
    const lastEntry = findLast(log, (e) => e.event === 'enterStage', evtIndex) as EnterStageEvent | undefined;
    if (lastEntry) {
        const { pathScore } = lastEntry.sessionStageIndex;
        return formatPathName(pathScore);
    } else {
        return '';
    }
};

const hasMusicAttributes = (e: TimestampedSessionEvent): boolean =>
    !!get(e, 'contentSwitch.musicAttributeTargets', false);

const isPlaylistEnterEvent = (e: TimestampedSessionEvent): boolean =>
    'stagePreset' in e && isStagePresetPlaylist(e.stagePreset);

const isExperimentalEnterEvent = (e: TimestampedSessionEvent): boolean =>
    'stagePreset' in e && isStagePresetExperimental(e.stagePreset);

export const isStagePresetPlaylist = (sessionStagePreset: SessionStagePreset): boolean =>
    sessionStagePreset.preset === 'Release';

export const isStagePresetExperimental = (sessionStagePreset: SessionStagePreset): boolean =>
    sessionStagePreset.preset === 'Experimental';
