import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import classNames from 'classnames';
import map from 'lodash/map';
import React, { useState } from 'react';

import { Button, Typography, UserOfferingCard } from '@/component-library';
import useMySubscription from '@/hooks/useMySubscription';

import { signOut } from '../../auth';
import Snackbar from '../../common/components/Snackbar';
import logoDrugScience from '../../images/logo-drug-science.png';
import logoFluence from '../../images/logo-fluence.png';
import logoImperial from '../../images/logo-imperial-college-london.png';
import logoMonash from '../../images/logo-monash.png';
import logoMcGill from '../../images/logo-university-mcgill.png';
import logoWashington from '../../images/logo-university-washington.png';
import offerings from './data/offerings';
import useStripeCheckout from './useStripeCheckout';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        padding: '60px 24px 120px 24px',
        '& input[type=checkbox]': {
            height: '100%',
        },
        background: '#f1f3f8',
        '& a': {
            color: '#000',
            ':active': {
                color: '#000',
            },
        },
    },
    header: {
        display: 'flex',
        width: '100%',
        maxWidth: '1200px',
        justifyContent: 'space-between',
    },
    flexRow: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
    },
    section: {
        width: '100%',
        maxWidth: '1200px',
    },
    sectionWide: {
        width: '100%',
        maxWidth: '1362px',
    },
    periodToggle: {
        display: 'flex',
        alignItems: 'center',
    },
    priceCards: {
        marginTop: '20px',
        display: 'grid',
        justifyContent: 'center',
        gap: '16px',
        height: '100%',
        placeItems: 'center',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
        [theme.breakpoints.between('md', 'lg')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
        },
    },
    smallPrint: {},
    trustedBy: {
        display: 'grid',
        gridAutoFlow: 'row',
        gap: '32px',
        textAlign: 'center',
    },
    logos: {
        display: 'grid',
        justifyItems: 'center',
        justifyContent: 'center',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'auto auto auto',
        gap: '8px 32px',
        [theme.breakpoints.between('md', 'lg')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridTemplateRows: 'auto auto',
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(6, 1fr)',
            gridTemplateRows: 'auto',
        },
    },
    logo: {
        height: '70px',
    },
    card: {
        padding: '24px',
        background: 'rgba(255, 255, 255, 0.7)',
        borderRadius: '16px',
    },
    trial: {},
    trialHeading: {
        marginBottom: '1rem',
    },
    testimonials: {},
    quote: {
        textAlign: 'center',
    },
    citation: {
        fontStyle: 'normal',
        textAlign: 'center',
        marginTop: '1em',
        color: 'rgba(0,0,0,0.5)',
    },
    switchThumb: {
        color: 'rgb(44, 57, 88)',
    },
    switchTrack: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
}));

const Subscriptions = (): React.ReactElement => {
    const [showAnnual, setShowAnnual] = useState(false);
    const { subscription } = useMySubscription();
    const { handleSubscribe, isSubscribing, error, dismissError } = useStripeCheckout();

    const classes = useStyles();

    const showGBP = subscription?.currency === 'gbp';

    return (
        <div className={classes.root}>
            <header className={classes.header}>
                <Typography variant="h3">Your Subscription</Typography>
                <Button variant="outlined" size="s" onClick={signOut}>
                    Sign out
                </Button>
            </header>
            <section className={classNames(classes.section, classes.card)}>
                <div className={classes.trial}>
                    <Typography variant="h5" className={classes.trialHeading}>
                        Start making person-centred music now with your 14 day free trial
                    </Typography>
                    <Typography variant="subtitle2">
                        Once your trial ends, your subscription will begin. You can cancel any time.
                    </Typography>
                </div>
            </section>
            <section className={classes.section}>
                <div className={classes.flexRow}>
                    <div className={classes.periodToggle}>
                        <Typography variant="subtitle2">Monthly</Typography>
                        <Switch
                            classes={{ thumb: classes.switchThumb, track: classes.switchTrack }}
                            checked={showAnnual}
                            size="medium"
                            onChange={() => setShowAnnual((showAnnual) => !showAnnual)}
                        />
                        <Typography variant="subtitle2">
                            Yearly <span style={{ fontSize: 'smaller' }}>(20% discount)</span>
                        </Typography>
                    </div>
                    <Typography variant="subtitle2">
                        <i>Plans ended during the trial period will not be billed</i>
                    </Typography>
                </div>
            </section>
            <section className={classNames(classes.section, classes.priceCards)}>
                {map(offerings(handleSubscribe, showGBP), (o, k) => {
                    const offeringProps = showAnnual ? o.annual : o.monthly;
                    const highlighted = o.highlighted;
                    return (
                        <div style={{ translate: highlighted ? '0 -20px' : 0 }}>
                            <UserOfferingCard
                                key={k}
                                {...offeringProps}
                                highlighted={highlighted}
                                ctaDisabled={isSubscribing}
                                footerContent={
                                    <Typography variant="body2">
                                        Compare our plans in detail{' '}
                                        <a href="https://wavepaths.com/music-for-psychedelic-therapies-2#pricing-tiers">
                                            here
                                        </a>
                                        .
                                    </Typography>
                                }
                            />
                        </div>
                    );
                })}
            </section>
            <section className={classNames(classes.section, classes.smallPrint)}>
                <Typography variant="subtitle2">
                    Not quite ready to subscribe? You can opt in to receiving updates from Wavepaths{' '}
                    <a href="https://wavepaths.com/waitlist">here.</a>
                </Typography>
                <br />
                <Typography variant="subtitle2">
                    If you manage multiple clinics, please{' '}
                    <a href="https://meetings.hubspot.com/antonia-mills/antonia" target="_blank" rel="noreferrer">
                        book a slot in our diary
                    </a>{' '}
                    to discuss your requirements.
                </Typography>
            </section>
            <section className={classNames(classes.sectionWide, classes.trustedBy)}>
                <Typography variant="h5">Wavepaths is trusted by</Typography>
                <div className={classes.logos}>
                    <img className={classes.logo} src={logoImperial} alt="Imperial College London logo" />
                    <img className={classes.logo} src={logoMonash} alt="Monash Univeristy logo" />
                    <img className={classes.logo} src={logoMcGill} alt="McGill University logo" />
                    <img className={classes.logo} src={logoWashington} alt="University of Washington logo" />
                    <img className={classes.logo} src={logoFluence} alt="Fluence logo" />
                    <img className={classes.logo} src={logoDrugScience} alt="Drug Science logo" />
                </div>
            </section>
            <Snackbar
                type="error"
                isLongButton={true}
                message={error ?? ''}
                confirmText={'OK'}
                open={error !== undefined}
                closeSnackbar={dismissError}
            />
        </div>
    );
};

export default Subscriptions;
