import styled from '@emotion/styled';
import { secondsToMilliseconds } from 'date-fns';
import { isNumber, uniqueId } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { animated, config, useSpring } from 'react-spring';
import { usePrevious } from 'react-use';
import { CoreEmotionalAtmosphere, isPrePostLudePathScore, TickDepth, Wavepath } from 'wavepaths-shared/core';
import {
    getRemainingTimeStampedStagesForWave,
    getTimeStampedIntensityChanges,
    TimeStampedIntensityChange,
} from 'wavepaths-shared/domain/wavepath';

import { Button, EvaIcon } from '@/component-library';
import useScoreLibrary from '@/hooks/useScoreLibrary';

import { useAuthContext } from '../../../../auth';
import { Connection } from '../../../../common/hooks/useSessionTick';
import { useCEA } from '../../ceaButtons/useCEA';
import { useDepth } from '../../depthSlider/useDepth';
import hasPlannedIntensitiesChanged from './hasPlannedIntensitiesChanged';
import StatusBarList from './StatusBarList';
import StatusBarMessageWithIcon from './StatusBarMessageWithIcon';
import { StatusBarListItem } from './types';
import usePendingBehaviours from './usePendingBehaviours';
import { getMessageSequence, stagesToStatusBarItems } from './utils';

interface IStatusBarProps {
    currentWave: Wavepath | null;
    nextWave?: Wavepath;
    currentTimeSecs: number;
    connection: Connection;
    setSnackbarContent: (content: string | null) => void;
}

export const SNACKBAR_REPLACED_QUEUE_ITEM_TEXT =
    'Your previous queued adjustment has been replaced with your latest change';

const Container = styled(animated.div)({
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr min-content',
    alignContent: 'start',
    padding: '16px 16px 16px 24px',
});

const List = styled.div({
    minHeight: 24,
});

const Toggle = styled.div({
    width: 44,
    height: 24,
    position: 'relative',
});

const ToggleButton = styled(Button)({
    width: 44,
    height: 44,
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    border: 0,
});

export const formatNextWaveMessage = (nextWave: Wavepath, currentTimeMs: number): StatusBarListItem => {
    const { pathScore, plan, id } = nextWave;
    if (!isPrePostLudePathScore(pathScore) && pathScore.emotion && typeof pathScore.emotion === 'string') {
        const messageComponent = <StatusBarMessageWithIcon name={pathScore.name} emotion={pathScore.emotion} />;
        return {
            separatorMessage: 'then',
            message: messageComponent,
            timeUntilActive: plan ? plan.fromTime - currentTimeMs : undefined,
            key: id,
        };
    }
    return {
        separatorMessage: 'then',
        message: `to ${pathScore.name}`,
        timeUntilActive: plan ? plan.fromTime - currentTimeMs : undefined,
        key: id,
    };
};

const isItemInFuture = ({ timeUntilActive }: StatusBarListItem) => !timeUntilActive || timeUntilActive > 0;

function buildUpcomingMessages(
    pendingBehavioursExist: boolean,
    timeStampedIntensityChanges: TimeStampedIntensityChange[],
    currentTime: number,
    nextWave: Wavepath | null,
    currentDepth?: TickDepth,
    currentCea?: CoreEmotionalAtmosphere,
): StatusBarListItem[] {
    const nextWaveMessage = nextWave && formatNextWaveMessage(nextWave, currentTime);
    return [
        //TODO these need to be mixed and sorted...
        ...(isNumber(currentDepth) && currentCea
            ? stagesToStatusBarItems(timeStampedIntensityChanges, currentDepth, currentCea, currentTime)
            : []),
        ...(nextWaveMessage ? [nextWaveMessage] : []),
    ]
        .filter(({ message }) => !!message)
        .filter(isItemInFuture)
        .map((message, idx) => ({
            ...message,
            separatorMessage: pendingBehavioursExist || idx !== 0 ? 'then' : undefined,
        }));
}

export function StatusBar({
    connection,
    currentWave,
    currentTimeSecs,
    nextWave,
    setSnackbarContent,
}: IStatusBarProps): JSX.Element {
    const { pendingBehaviours } = usePendingBehaviours(connection);
    const { firebaseUser } = useAuthContext();
    const scoreLibrary = useScoreLibrary(firebaseUser);
    const depthState = useDepth(connection);
    const ceaState = useCEA(connection, () => {
        return;
    });
    const [isOpen, setIsOpen] = useState(false);
    const [pendingBehaviourMessages, setPendingBehaviourMessages] = useState<StatusBarListItem[]>([]);

    const listRef = useRef<HTMLDivElement>(null);
    const { maxHeight } = useSpring({
        maxHeight: isOpen && listRef.current ? listRef.current.scrollHeight + 32 : 56,
        config: config.stiff,
    });

    const currentTime = secondsToMilliseconds(currentTimeSecs);

    useEffect(() => {
        setPendingBehaviourMessages(
            pendingBehaviours
                .flatMap((behaviour) =>
                    getMessageSequence(behaviour).map((message) => ({
                        message,
                        key: uniqueId(),
                    })),
                )
                .map((item, index) => ({ ...item, separatorMessage: index === 0 ? 'Now' : 'and' })),
        );
    }, [pendingBehaviours]);

    const remainingStages = currentWave ? getRemainingTimeStampedStagesForWave(currentWave, currentTime) : [];

    const timeStampedIntensityChanges =
        scoreLibrary.loading === false
            ? getTimeStampedIntensityChanges(remainingStages, scoreLibrary.presetScores)
            : [];

    const oldTimeStampedIntensityChanges = usePrevious(timeStampedIntensityChanges);

    useEffect(() => {
        const hasUpcomingStageChangeUpdated = hasPlannedIntensitiesChanged(
            timeStampedIntensityChanges,
            oldTimeStampedIntensityChanges ?? [],
        );
        if (hasUpcomingStageChangeUpdated) return setSnackbarContent(SNACKBAR_REPLACED_QUEUE_ITEM_TEXT);
    }, [timeStampedIntensityChanges, oldTimeStampedIntensityChanges, setSnackbarContent]);

    const statusBarMessages: StatusBarListItem[] = [
        ...pendingBehaviourMessages,
        ...buildUpcomingMessages(
            !!pendingBehaviourMessages.length,
            timeStampedIntensityChanges,
            currentTime,
            nextWave ?? null,
            depthState === 'loading' ? undefined : depthState.currentDepth,
            ceaState === 'loading' ? undefined : ceaState.targetCea,
        ),
    ];

    return (
        <Container style={{ maxHeight }}>
            <List ref={listRef}>
                <StatusBarList listItems={statusBarMessages} />
            </List>
            <Toggle>
                {listRef?.current && listRef.current.scrollHeight > 34 && (
                    <ToggleButton aria-label="Expand status bar" onClick={() => setIsOpen(!isOpen)} variant="outlined">
                        <EvaIcon
                            name="chevron-down"
                            size={24}
                            iconStyle={{
                                transform: isOpen ? 'rotate(180deg)' : undefined,
                                transition: 'transform 0.3s ease',
                            }}
                        />
                    </ToggleButton>
                )}
            </Toggle>
        </Container>
    );
}

export default React.memo(StatusBar);
