import './FeedbackText.scss';

import React from 'react';

interface FeedbackTextProps {
    text: string;
    onSetFeedbackText: (text: string) => void;
}

export const FeedbackText: React.FC<FeedbackTextProps> = ({ text, onSetFeedbackText }) => {
    return (
        <div className="feedbackText">
            <textarea
                className="feedbackTextTextarea"
                placeholder="Describe further (optional):"
                value={text}
                onChange={(evt) => onSetFeedbackText(evt.target.value)}
            ></textarea>
        </div>
    );
};
