import { animated } from '@react-spring/web';
import { clamp, sortedIndex, sortedIndexBy } from 'lodash';
import React, { useRef } from 'react';

import { PIXELS_PER_MILLIS } from './constants';
import { pointsToSvgPath, sampleSvgPathPoints } from './svgUtils';
import { getCurvePointsFromWave, lerp, TimelineWaveProperties } from './timelineWaveUtils';
import { usePlayheadPositionV2 } from './usePlayheadPosition';

const RADIUS = 4;

interface TimelinePlayheadProps {
    waves: TimelineWaveProperties[];
    elapsedTimeMs: number;
    sessionDurationMs: number;
}
export const TimelinePlayhead: React.FC<TimelinePlayheadProps> = React.memo(
    ({ waves, elapsedTimeMs, sessionDurationMs }) => {
        const playheadPosition = usePlayheadPositionV2({
            pixelsPerMillis: PIXELS_PER_MILLIS,
            sessionDurationMs,
            elapsedTimeMs,
        });

        const position = playheadPosition;

        const playheadRef = useRef<SVGCircleElement>(null);

        const waveXs = waves.map((w) => w.x);
        const waveSvgPathPoints = waves.map((wave) => {
            const points = getCurvePointsFromWave(wave, 0);
            const svgPath = pointsToSvgPath(points);
            return sampleSvgPathPoints(svgPath);
        });

        const getPathPointY = (px: number) => {
            if (waves.length === 0) return 0;
            const waveIndex = clamp(sortedIndex(waveXs, px) - 1, 0, waves.length - 1);
            const wave = waves[waveIndex];
            const svgPathPoints = waveSvgPathPoints[waveIndex];
            const idx = clamp(
                sortedIndexBy(svgPathPoints, { x: px - wave.x, y: 0 }, (p) => p.x),
                0,
                svgPathPoints.length - 1,
            );
            if (idx > 0) {
                const lhs = svgPathPoints[idx - 1];
                const rhs = svgPathPoints[idx];
                const d = (px - wave.x - lhs.x) / (rhs.x - lhs.x);
                return lerp(lhs.y, rhs.y, d);
            }
            return svgPathPoints[idx].y;
        };

        const getTransform = (x: number) => {
            return `translate(${x} ${getPathPointY(x)})`;
        };

        return (
            <animated.circle
                ref={playheadRef}
                cx={0}
                cy={0}
                r={RADIUS}
                fill="black"
                transform={position.to(getTransform)}
            />
        );
    },
);
