import { useTheme } from '@material-ui/core/styles';
import InApp from 'detect-inapp';
import * as EmailValidator from 'email-validator';
import firebase from 'firebase';
import PasswordValidator from 'password-validator';
import qs from 'qs';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Typography } from '@/component-library';

// import { useLogger } from 'react-use';
import * as auth from '../../auth';
import FacebookIcon from '../../images/facebook.svg';
import GoogleIcon from '../../images/google.png';
import InSessionView from '../../images/guide_in_session.png';
import Logo from '../../images/WavepathBlueLogo.svg';

const inapp =
    typeof window !== 'undefined' ? new InApp(window.navigator.userAgent || window.navigator.vendor) : undefined;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    background: #f1f3f8;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    @media (min-width: 769px) {
        flex-direction: row;
    }
`;

const BodyTextBase = styled(Typography)({
    color: '#2c3958',
});

const InputGroup = styled.div({
    display: 'grid',
    gridAutoFlow: 'row',
    gap: '8px',
});

const InputLabel = styled.label({
    fontSize: '13.3px',
    fontWeight: 500,
    color: '#2C3958',
    marginBottom: 0,
});

const StyledInput = styled.input({
    height: '44px',
    padding: '0 16px',
    background: 'transparent',
    border: '1px solid #6980B4',
    borderRadius: '4px',
    fontSize: '16px',
    color: '#2C3958',
    '&:focus': {
        borderColor: '#2C3958',
    },
});

const Icon = styled('img')({
    height: '16px',
    width: '16px',
    marginRight: '4px',
});

const LoginError = styled.div<{ backgroundColor: string }>(({ backgroundColor }) => ({
    backgroundColor,
    marginTop: 8,
    color: 'white',
    padding: '10px',
    borderRadius: '4px',
    '& a': {
        color: 'white',
        textDecoration: 'underline',
    },
}));

const FormContainer = styled.div`
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid white;
    box-shadow: 0px 0px 20px #cfd6e7;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    overflow: scroll;
    padding: 40px 60px 20px;

    @media (min-width: 769px) {
        align-items: flex-start;
        padding-top: 8vh;
    }
`;

const FormContent = styled.div`
    display: grid;
    gridautoflow: row;
    gap: 24px;
    max-width: 440px;
    width: 90%;
`;

const Footer = styled.footer({
    color: '#2C3958',
});

const FooterTypography = styled(BodyTextBase)({
    textAlign: 'center',
});

const FooterLink = styled(Link)({
    color: '#2C3958',
});

const Social = styled.div({
    display: 'grid',
    gridAutoFlow: 'row',
    gap: '8px',
});

const SocialButton = styled(Button)({
    color: '#2C3958',
    borderColor: '#ADB9D6',
    '&:hover': {
        borderColor: '#6980B4',
    },
});

const Form = styled.form({
    display: 'grid',
    gridAutoFlow: 'row',
    gap: '20px',
});

const TermsInfo = styled(BodyTextBase)({
    textAlign: 'center',
});

const FormTitle = styled(BodyTextBase)({});

const ProductInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 60px;
    width: 100%;
    @media (min-width: 769px) {
        align-items: flex-end;
        padding-top: 8vh;
        padding-bottom: 0;
    }
`;

const ProductInfoContent = styled.div`
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 440px;
    overflow: hidden;
    @media (min-width: 769px) {
        gap: 24px;
        align-items: flex-start;
        flex-grow: 1;
        justify-content: flex-start;
    }
`;

const WPLogo = styled.img({});

const ProductFeaturesInfo = styled.div`
    display: none;
    @media (min-width: 769px) {
        display: initial;
    }
`;

const FeatureList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0;
    margin: 0;
`;

const FeatureListItem = styled.li`
    margin: 0;
`;

const ProductImage = styled.img`
    width: 200px;
    border: 8px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 10px 30px 5px rgba(44, 57, 88, 0.25);
    border-radius: 10px;
    align-self: center;
    margin-top: 24px;

    display: none;
    @media (min-width: 769px) {
        display: initial;
    }
`;

export const Signup = () => {
    const [state, setState] = useState<
        | 'init'
        | 'validationFailed'
        | 'signingUp'
        | 'alreadySignedUp'
        | 'error'
        | 'success'
        | 'weakPassword'
        | 'invalidEmail'
        | 'wrongSignInMethod'
        | 'tooManyPopUps'
        | 'popUpBlocked'
        | 'popUpClosedByUser'
    >('init');
    const location = useLocation();
    const [email, setEmail] = useState((qs.parse(location.search, { ignoreQueryPrefix: true }).email as string) || '');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVerification, setPasswordVerification] = useState('');
    const passwordValidator = useMemo(() => new PasswordValidator().is().min(8), []);
    const history = useHistory();

    useEffect(() => {
        const error = (qs.parse(location.search, { ignoreQueryPrefix: true }).error as string) === 'true';
        if (error) {
            setState('error');
        }
    }, []);

    const onSubmit = (evt: FormEvent) => {
        if (
            !EmailValidator.validate(email) ||
            !passwordValidator.validate(password) ||
            password !== passwordVerification ||
            name.trim() === ''
        ) {
            setState('validationFailed');
        } else {
            setState('signingUp');
            auth.signUpV2(name, email, password).then(handleSignInAttempt);
        }
        evt.preventDefault();
    };

    const onSubmitWithProvider = async (evt: FormEvent, service: 'google' | 'fb') => {
        evt.preventDefault();
        setState('signingUp');
        auth.signInWithProviderV2(service).then(handleSignInAttempt);
    };

    const handleSignInAttempt = ({
        status,
    }: {
        status: auth.SignWithProviderStatus | auth.SignUpStatus;
        user?: firebase.User;
    }) => {
        setState(status);
        if (status === 'success') history.push('/onboarding');
    };

    // on first load, fetch user
    // while loading user, disable buttons
    // if loaded and no user, fine
    // if loaded and user, redirect

    // after signup
    // dont

    const theme = useTheme();

    const disabledButtons = state === 'signingUp';

    return (
        <Container>
            <ProductInfoContainer>
                <ProductInfoContent>
                    <WPLogo src={Logo} alt="Wavepaths" />
                    <BodyTextBase variant="body2">
                        Support your clients with person-centred music now by starting your 14 day free trial.
                    </BodyTextBase>
                    <ProductFeaturesInfo>
                        <FeatureList>
                            <FeatureListItem>
                                <BodyTextBase variant="body2">
                                    <b>Full customisation and real-time adaptation</b>
                                </BodyTextBase>
                                <BodyTextBase variant="body2">
                                    Design sessions and adapt emotionality, intensity and instrumentation to support the
                                    dynamic nature of music-assisted therapeutic work.
                                </BodyTextBase>
                            </FeatureListItem>
                            <FeatureListItem>
                                <BodyTextBase variant="body2">
                                    <b>Designed by experts</b>
                                </BodyTextBase>
                                <BodyTextBase variant="body2">
                                    Get started quickly with templates informed by Wavepaths’ research findings,
                                    optimised for a range of modalities.
                                </BodyTextBase>
                            </FeatureListItem>
                            <FeatureListItem>
                                <BodyTextBase variant="body2">
                                    <b>End-to-end support</b>
                                </BodyTextBase>
                                <BodyTextBase variant="body2">
                                    Receive expert support and discover a wide range of tools designed to complement
                                    your practice, including preparation and integration.
                                </BodyTextBase>
                            </FeatureListItem>
                            <FeatureListItem>
                                <BodyTextBase variant="body2">
                                    <b>Join a global community</b>
                                </BodyTextBase>
                                <BodyTextBase variant="body2">
                                    Access our Practitioner Community with regular educational events, live workshops,
                                    courses and forums, designed to grow your confidence and skill when working with
                                    music.
                                </BodyTextBase>
                            </FeatureListItem>
                        </FeatureList>
                    </ProductFeaturesInfo>
                    <BodyTextBase variant="body3">
                        Once your trial ends, your subscription will begin. You can cancel any time.
                    </BodyTextBase>
                    <ProductImage src={InSessionView} />
                </ProductInfoContent>
            </ProductInfoContainer>
            <FormContainer>
                <FormContent>
                    <FormTitle variant="h5">Create your Wavepaths account</FormTitle>
                    <BodyTextBase variant="body3">
                        After sign-up you'll receive your starter toolkit, learning resources and access to our
                        practitioner community to smoothly guide you through your first sessions.
                    </BodyTextBase>
                    {state !== 'init' && (
                        <div>
                            {state === 'alreadySignedUp' && (
                                <LoginError backgroundColor={theme.palette.error.main}>
                                    <Typography variant="body3" color="inherit">
                                        Looks like you've already created an account. Please{' '}
                                        <Link to="/login">log in</Link> instead.
                                    </Typography>
                                </LoginError>
                            )}
                            {state === 'validationFailed' && !EmailValidator.validate(email) && (
                                <LoginError backgroundColor={theme.palette.error.main}>
                                    <Typography variant="body3" color="inherit">
                                        Please enter a valid email.
                                    </Typography>
                                </LoginError>
                            )}
                            {state === 'validationFailed' && !passwordValidator.validate(password) && (
                                <LoginError backgroundColor={theme.palette.error.main}>
                                    <Typography variant="body3" color="inherit">
                                        Please enter a password of at least 8 characters.
                                    </Typography>
                                </LoginError>
                            )}
                            {state === 'validationFailed' && password !== passwordVerification && (
                                <LoginError backgroundColor={theme.palette.error.main}>
                                    <Typography variant="body3" color="inherit">
                                        Please make sure the passwords match
                                    </Typography>
                                </LoginError>
                            )}
                            {state === 'validationFailed' && name.trim() === '' && (
                                <LoginError backgroundColor={theme.palette.error.main}>
                                    <Typography variant="body3" color="inherit">
                                        Name cannot be empty
                                    </Typography>
                                </LoginError>
                            )}
                            {state === 'weakPassword' && (
                                <LoginError backgroundColor={theme.palette.error.main}>
                                    <Typography variant="body3" color="inherit">
                                        Please enter a stronger password
                                    </Typography>
                                </LoginError>
                            )}
                            {state === 'invalidEmail' && (
                                <LoginError backgroundColor={theme.palette.error.main}>
                                    <Typography variant="body3" color="inherit">
                                        Please enter a valid email address
                                    </Typography>
                                </LoginError>
                            )}
                            {state === 'error' && (
                                <LoginError backgroundColor={theme.palette.error.main}>
                                    <Typography variant="body3" color="inherit">
                                        Sorry, it looks like something went wrong during sign-up. Please try again or
                                        send us a message at{' '}
                                        <a href="mailto:support@wavepaths.com">support@wavepaths.com</a>.
                                    </Typography>
                                </LoginError>
                            )}
                            {state === 'wrongSignInMethod' && (
                                <LoginError backgroundColor={theme.palette.error.main}>
                                    <Typography variant="body3" color="inherit">
                                        Your email address is associated with a different login method. Please try again
                                        using the service you first logged in with.
                                    </Typography>
                                </LoginError>
                            )}
                            {state === 'tooManyPopUps' && (
                                <LoginError backgroundColor={theme.palette.error.main}>
                                    <Typography variant="body3" color="inherit">
                                        There is more than one pop-up window open. Please close all pop-up windows and
                                        try again.
                                    </Typography>
                                </LoginError>
                            )}
                            {state === 'popUpBlocked' && (
                                <LoginError backgroundColor={theme.palette.error.main}>
                                    <Typography variant="body3" color="inherit">
                                        The login pop-up window has been blocked by your browser. Please change your
                                        browser settings or use another sign-up method.
                                    </Typography>
                                </LoginError>
                            )}
                            {state === 'popUpClosedByUser' && (
                                <LoginError backgroundColor={theme.palette.error.main}>
                                    <Typography variant="body3" color="inherit">
                                        The login pop-up window was closed before the process of signing up was
                                        completed. Please try again.
                                    </Typography>
                                </LoginError>
                            )}
                            {inapp && inapp.isInApp && (
                                <LoginError backgroundColor={theme.palette.error.main}>
                                    <Typography variant="body3" color="inherit">
                                        Note: The app works better if you open it in your browser app (try pressing the
                                        ... menu - Open in Safari/Chrome)
                                    </Typography>
                                </LoginError>
                            )}
                        </div>
                    )}
                    <Form onSubmit={onSubmit}>
                        <InputGroup>
                            <InputLabel htmlFor="name">Name</InputLabel>
                            <StyledInput
                                id="name"
                                aria-label="name"
                                type="name"
                                name="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputLabel htmlFor="email">Email address</InputLabel>
                            <StyledInput
                                id="email"
                                aria-label="email"
                                type="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <StyledInput
                                id="password"
                                aria-label="password"
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </InputGroup>
                        <InputGroup>
                            <InputLabel htmlFor="passwordVerification">Confirm Password</InputLabel>
                            <StyledInput
                                id="passwordVerification"
                                aria-label="passwordVerification"
                                type="password"
                                name="passwordVerification"
                                value={passwordVerification}
                                onChange={(e) => setPasswordVerification(e.target.value)}
                            />
                        </InputGroup>
                        <TermsInfo variant="body3">
                            By creating a Wavepaths account you consent to{' '}
                            <FooterLink to="/terms">our terms</FooterLink>.
                        </TermsInfo>
                        <Button
                            disabled={disabledButtons}
                            type="submit"
                            size="m"
                            variant="solid-blue"
                            style={{ borderRadius: '50px', backgroundColor: '#2C3958' }}
                        >
                            Sign Up
                        </Button>
                    </Form>
                    {inapp && !inapp.isInApp && (
                        <Social>
                            <SocialButton
                                disabled={disabledButtons}
                                icon={<Icon src={GoogleIcon} />}
                                size="m"
                                variant="outlined"
                                onClick={(event) => {
                                    onSubmitWithProvider(event, 'google');
                                }}
                            >
                                Continue with Google
                            </SocialButton>
                            <SocialButton
                                disabled={disabledButtons}
                                icon={<Icon src={FacebookIcon} />}
                                size="m"
                                variant="outlined"
                                onClick={(event) => {
                                    onSubmitWithProvider(event, 'fb');
                                }}
                            >
                                Continue with Facebook
                            </SocialButton>
                        </Social>
                    )}
                    <Footer>
                        <FooterTypography variant="body3">
                            Already have an account? <FooterLink to="/login">Log In</FooterLink>
                        </FooterTypography>
                    </Footer>
                </FormContent>
            </FormContainer>
        </Container>
    );
};
