export enum FormElementType {
    STAR = 'star-rating',
    HEADER = 'header',
    DESCRIPTION = 'description',
    SLIDER = 'slider',
    FREE_TEXT = 'free-text',
    SESSION_AUDIO = 'session-audio',
}

export enum FormTemplateType {
    POST_INTEGRATION = 'postSessionIntegration',
}

export type FormTemplateElement = {
    id: string;
    text: string;
    type: FormElementType;
    min?: number;
    max?: number;
};

export type FormResponseElement = {
    id: string;
    text: string;
    type: FormElementType;
    min?: number;
    max?: number;
    value?: string | number;
};

export type FormTemplate = {
    id: string;
    name: string;
    description?: string;
    type: FormTemplateType;
    anonymous: boolean;
    availability: string[];
    elements: FormTemplateElement[];
};

export type FormResponse = {
    id?: string;
    templateReferences: string[];
    sessionId?: string;
    creatorId?: string;
    dateCreated?: Date;
    responderEmail?: string;
    type: FormTemplateType;
    anonymous?: boolean;
    elements: FormResponseElement[];
};
