import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { ActiveLayerDetails, LayerNumber, RequestType } from 'wavepaths-shared/core';

import { Connection, subscribeTick } from '../../../common/hooks/useSessionTick';
import UserEvents from '../../../UserEvents';
import { Queueable } from '../actionQueue/useActionQueue';

export function useActiveLayers(
    connection: Connection,
    queueFunction: (queueable: Queueable) => void,
):
    | {
          activeLayers: ActiveLayerDetails[];
          refreshActiveLayers: (layerNumbers: LayerNumber[], queueFunction?: () => void) => void;
      }
    | 'loading' {
    const existingConnection = !!connection;
    const [_activeLayers, _setActiveLayers] = useState<ActiveLayerDetails[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const refreshActiveLayers = useCallback(
        (layerNumbers: LayerNumber[], whenQueued?: () => void) => {
            const soundCount = layerNumbers.length;
            const soundNoun = layerNumbers.length > 1 ? 'sounds' : 'sound';
            queueFunction({
                description: `Replacing ${soundCount} ${soundNoun}`,
                callback: () => {
                    connection.sendRequest({
                        type: RequestType.RefreshLayers,
                        layerNumbers,
                    });
                    whenQueued && whenQueued();
                },
                onCancel: () => {
                    UserEvents.instrumentsRefreshCanceled(layerNumbers);
                },
                onSkipQueue: () => {
                    UserEvents.instrumentsRefreshSkippedQueue(layerNumbers);
                },
            });
            UserEvents.instrumentsRefreshed(
                _activeLayers.filter(({ layer }) => layerNumbers.includes(layer as LayerNumber)),
            );
        },
        [_activeLayers, connection],
    );

    useEffect(() => {
        return subscribeTick(connection, (tick) => {
            if (isLoading) setIsLoading(false);
            if (!isEqual(tick.musicalContent?.activeLayers, _activeLayers)) {
                _setActiveLayers(tick.musicalContent?.activeLayers);
            }
        });
    }, [existingConnection, connection, _activeLayers, isLoading]);

    if (isLoading) return 'loading';

    return {
        activeLayers: _activeLayers,
        refreshActiveLayers,
    };
}
