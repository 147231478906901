import React from 'react';

import { Typography } from '@/component-library';

import InfoButtonAndDialog from './InfoButtonAndDialog';

const SchedulingTypeInfoDialog: React.FC = () => (
    <InfoButtonAndDialog title="When is this session for?">
        <Typography variant="body2" color="textSecondary">
            <strong>Now</strong> will start the system in prelude mode which plays low intensity Stillness music for up
            to four hours. When in prelude mode you can start the session at any time.
        </Typography>
        <Typography variant="body2" color="textSecondary">
            <strong>Save for later</strong> will add the session to the ‘scheduled for later’ tab in your sessions
            dashboard.
        </Typography>
        <Typography variant="body2" color="textSecondary">
            <strong>A specific time</strong> will allow you to set a date and time for this session to start
            automatically.
        </Typography>
    </InfoButtonAndDialog>
);

export default SchedulingTypeInfoDialog;
