import { sample } from 'lodash';
import React, { useState } from 'react';
import {
    Acousticness,
    CoreEmotionalAtmosphere,
    PathScore,
    PathScoreModes,
    ScheduledExperimentalPathScore,
    ScheduledGenerativePathScore,
    ScheduledWavepath,
    SootheModes,
    TherapeuticDirection,
    Wavepath,
} from 'wavepaths-shared/core';

import { DropdownControl, SegmentedControl } from '@/component-library';
import { ControlOption } from '@/component-library/components/Control/types';

import * as infoCopy from './infoCopy';
import * as pathScoreFns from './pathScoreFns';

export interface SelectorState {
    mode?: PathScoreModes;
    emotion?: CoreEmotionalAtmosphere;
    intensity?: string;
    tone?: Acousticness;
    ambience: string;
    music?: string;
    duration: number | string;
}

const selectorStateDefault: SelectorState = {
    mode: 'Unstructured',
    emotion: undefined,
    intensity: undefined,
    tone: undefined,
    ambience: 'None',
    music: 'Any',
    duration: '',
};

export type SoothePathScore = (ScheduledGenerativePathScore | ScheduledExperimentalPathScore) & {
    therapeuticDirection: TherapeuticDirection.SOOTHE;
    mode: SootheModes;
};

interface SootheWaveSelectorProps {
    pathScores: SoothePathScore[];
    wavepath: ScheduledWavepath;
    onWavepathEditClear: () => void;
    onWavepathEdited: (wavepath: Wavepath) => void;
}

export const isSoothePathScore = (p: PathScore): p is SoothePathScore => p.direction === TherapeuticDirection.SOOTHE;

function SootheWaveSelector({
    pathScores,
    wavepath,
    onWavepathEdited,
    onWavepathEditClear,
}: SootheWaveSelectorProps): JSX.Element {
    const [selectorState, setSelectorState] = useState<SelectorState>({
        ...selectorStateDefault,
        ...(isSoothePathScore(wavepath.pathScore) && {
            mode: wavepath.pathScore.mode,
            emotion: typeof wavepath.pathScore.emotion === 'string' ? wavepath.pathScore.emotion : undefined,
            intensity: wavepath.pathScore.selectionCriteria?.emotionalIntensity,
            tone: 'acousticness' in wavepath.pathScore ? wavepath.pathScore.acousticness : undefined,
            ambience: 'ambience' in wavepath.pathScore ? wavepath.pathScore.ambience ?? '' : 'None',
            music: 'music' in wavepath.pathScore ? wavepath.pathScore.music : 'Generative',
            duration: wavepath.duration ?? pathScoreFns.AUTO_VALUE,
        }),
    });

    const modeFilteredScore = pathScoreFns.filterScoresByMode(pathScores, selectorState.mode);
    const emotionFilteredScores = pathScoreFns.filterScoresByEmotion(modeFilteredScore, selectorState.emotion);
    const intensityFilteredScores = pathScoreFns.filterScoresByIntensity(
        emotionFilteredScores,
        selectorState.intensity,
    );
    const toneColourFilteredScores = pathScoreFns.filterScoresByTone(intensityFilteredScores, selectorState.tone);
    const ambienceFilteredScores = pathScoreFns.filterScoresByAmbience(intensityFilteredScores, selectorState.ambience);

    const isStructured = selectorState.mode === 'Structured';

    const musicFilteredScores = pathScoreFns.filterScoresByMusic(ambienceFilteredScores, selectorState.music);

    // log

    const preDurationFilteredScores = isStructured ? toneColourFilteredScores : musicFilteredScores;

    const durationOptions: ControlOption<number | string>[] = [
        ...[...pathScoreFns.extractDurationsFromScores(preDurationFilteredScores)].map(
            (value): ControlOption<number | string> => ({
                label: value === pathScoreFns.AUTO_VALUE ? value : `${value} minutes`,
                value: value,
                disabled: false,
            }),
        ),
    ];

    const handleDurationChange = (duration: number | string) => {
        setSelectorState({
            ...selectorStateDefault,
            mode: selectorState.mode,
            emotion: selectorState.emotion,
            intensity: selectorState.intensity,
            tone: selectorState.tone,
            ambience: selectorState.ambience,
            duration,
        });
        const durationFilteredScores = pathScoreFns.filterScoresByDuration(preDurationFilteredScores, duration);

        const pathScore = sample(durationFilteredScores);
        if (pathScore) {
            onWavepathEdited({
                ...wavepath,
                duration: typeof duration === 'number' ? duration : undefined,
                pathScore,
                pathId: pathScore.id,
            });
        }
    };

    return (
        <>
            <SegmentedControl
                name="wave-mode"
                heading={'Mode'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    ['Unstructured', 'Structured'] as PathScoreModes[],
                    pathScoreFns.extractModesFromScores(pathScores),
                )}
                value={selectorState.mode}
                onChange={(value?: PathScoreModes) => {
                    setSelectorState({
                        ...selectorStateDefault,
                        mode: value,
                    });
                    onWavepathEditClear();
                }}
                info={infoCopy.sootheMode}
            />
            <SegmentedControl
                name="wave-emotion"
                heading={'Emotionality'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    [
                        CoreEmotionalAtmosphere.STILLNESS,
                        CoreEmotionalAtmosphere.VITALITY,
                        CoreEmotionalAtmosphere.BITTERSWEET,
                        CoreEmotionalAtmosphere.TENSION,
                    ],
                    pathScoreFns.extractEmotionsFromScores(pathScores),
                )}
                value={selectorState.emotion}
                onChange={(value?: CoreEmotionalAtmosphere) => {
                    setSelectorState({
                        ...selectorStateDefault,
                        mode: selectorState.mode,
                        emotion: value,
                    });
                    onWavepathEditClear();
                }}
                disabled={!selectorState.mode}
                info={infoCopy.emotion}
            />
            <SegmentedControl
                name="wave-intensity"
                heading={'Intensity'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    ['Low', 'Medium'],
                    pathScoreFns.extractIntensitiesFromScores(emotionFilteredScores),
                )}
                value={selectorState.intensity}
                onChange={(value) => {
                    setSelectorState({
                        ...selectorStateDefault,
                        mode: selectorState.mode,
                        emotion: selectorState.emotion,
                        intensity: value,
                    });
                    onWavepathEditClear();
                }}
                disabled={!selectorState.emotion}
                info={infoCopy.intensity}
            />
            {isStructured ? (
                <SegmentedControl
                    name="wave-tone"
                    heading={'Tone'}
                    canSave={false}
                    size="small"
                    colour="dark"
                    options={pathScoreFns.convertListToOptions(
                        ['Acoustic', 'Mixed', 'Electronic'] as Acousticness[],
                        pathScoreFns.extractToneColoursFromScores(
                            intensityFilteredScores as ScheduledExperimentalPathScore[],
                        ),
                    )}
                    value={selectorState.tone}
                    onChange={(value?: Acousticness) => {
                        setSelectorState({
                            ...selectorStateDefault,
                            mode: selectorState.mode,
                            emotion: selectorState.emotion,
                            intensity: selectorState.intensity,
                            tone: value,
                        });
                        onWavepathEditClear();
                    }}
                    disabled={!selectorState.intensity}
                    info={infoCopy.ambience}
                />
            ) : (
                <>
                    <SegmentedControl
                        name="wave-nature"
                        heading={'Ambience'}
                        canSave={false}
                        size="small"
                        colour="dark"
                        options={[
                            {
                                label: 'None',
                                value: 'None',
                                disabled: false,
                            },
                            ...[...pathScoreFns.extractNatureSoundsFromScore(intensityFilteredScores)].map(
                                (item: string) => ({
                                    label: item,
                                    value: item,
                                    disabled: false,
                                }),
                            ),
                        ]}
                        value={selectorState.ambience}
                        onChange={(value: string) => {
                            setSelectorState({
                                ...selectorStateDefault,
                                mode: selectorState.mode,
                                emotion: selectorState.emotion,
                                intensity: selectorState.intensity,
                                ambience: value,
                            });
                            onWavepathEditClear();
                        }}
                        disabled={!selectorState.intensity}
                        info={infoCopy.ambience}
                    />
                    <SegmentedControl
                        name="wave-music"
                        heading={'Music'}
                        canSave={false}
                        size="small"
                        colour="dark"
                        options={pathScoreFns.convertListToOptions(
                            [...pathScoreFns.extractMusicOptionsFromScores(ambienceFilteredScores)],
                            pathScoreFns.extractMusicOptionsFromScores(ambienceFilteredScores),
                        )}
                        value={selectorState.music}
                        onChange={(value?: string) => {
                            setSelectorState({
                                ...selectorStateDefault,
                                mode: selectorState.mode,
                                emotion: selectorState.emotion,
                                tone: selectorState.tone,
                                ambience: selectorState.ambience,
                                intensity: selectorState.intensity,
                                music: value,
                            });
                            onWavepathEditClear();
                        }}
                        disabled={!selectorState.ambience || !selectorState.intensity}
                        info={infoCopy.composition}
                    />
                </>
            )}
            <DropdownControl
                heading={'Duration'}
                name="wave-duration"
                canSave={false}
                size="small"
                colour="dark"
                options={durationOptions}
                value={durationOptions.length ? selectorState.duration : ''}
                onChange={handleDurationChange}
                disabled={!selectorState.intensity || (isStructured ? !selectorState.tone : !selectorState.ambience)}
                info={infoCopy.duration}
            />
        </>
    );
}

export default SootheWaveSelector;
