import React, { ReactElement, ReactNode } from 'react';

function SliderStepContainer({
    children,
    elementRef,
    ...props
}: {
    children: ReactNode | ReactNode[];
    style?: React.CSSProperties;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    elementRef: React.RefObject<HTMLDivElement>;
}): ReactElement {
    return (
        <div ref={elementRef} {...props}>
            {children}
        </div>
    );
}

export default SliderStepContainer;
