import firebase from 'firebase';
import { useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { Err, Ok, Result } from 'ts-results';

import {
    addSessionTemplate,
    deleteSessionTemplate,
    getSessionTemplates,
    IGetSessionTemplatesReturn,
    ISessionTemplate,
    SessionTemplateRequest,
} from '../api/sessionTemplatesApi';

const deleteTemplateFromArrayById = (templates: ISessionTemplate[], templateId: string) =>
    templates.filter((template) => template.id !== templateId);

interface IUserSessionTemplatesReturn {
    sessionTemplates: ISessionTemplate[];
    deleteTemplate: (templateId: string) => Promise<Result<undefined, Error>>;
    addTemplate: (template: SessionTemplateRequest) => Promise<Result<undefined, Error>>;
    error: Error | undefined;
    mutationPending: boolean;
}

export interface IUseSessionTemplatesProps {
    fbUser?: firebase.User;
}

const useSessionTemplates = ({ fbUser }: IUseSessionTemplatesProps): IUserSessionTemplatesReturn => {
    const [error, setError] = useState<Error | undefined>(undefined);
    const [mutationPending, setMutationPending] = useState<boolean>(false);
    const { mutate } = useSWRConfig();
    const { data, error: getSessionTemplatesError } = useSWR<IGetSessionTemplatesReturn, Error>(
        fbUser ? `/sessionTemplates` : null,
        fbUser ? () => getSessionTemplates({ fbUser }) : null,
    );

    const deleteTemplate = async (templateId: string): Promise<Result<undefined, Error>> => {
        if (!fbUser) return Err(new Error('No firebase user'));

        try {
            await mutate(
                '/sessionTemplates',
                async () => {
                    await deleteSessionTemplate({ fbUser, templateId });
                    return { entries: deleteTemplateFromArrayById(data?.entries ?? [], templateId) };
                },
                {
                    optimisticData: { entries: deleteTemplateFromArrayById(data?.entries ?? [], templateId) },
                    revalidate: true,
                },
            );
            return Ok(undefined);
        } catch (err: any) {
            setError(err);
            return Err(err);
        }
    };

    const addTemplate = async (template: SessionTemplateRequest): Promise<Result<undefined, Error>> => {
        if (!fbUser) return Err(new Error('No firebase user'));

        setMutationPending(true);
        try {
            await mutate('/sessionTemplates', async () => {
                const newTemplate = await addSessionTemplate({ fbUser, template });
                return { entries: [...(data?.entries ?? []), newTemplate] };
            });
            setMutationPending(false);
            return Ok(undefined);
        } catch (err: any) {
            setError(err);
            setMutationPending(false);
            return Err(err);
        }
    };

    return {
        mutationPending,
        sessionTemplates: data?.entries ?? [],
        deleteTemplate,
        addTemplate,
        error: error ?? getSessionTemplatesError,
    };
};

export default useSessionTemplates;
