import styled from '@emotion/styled';
import React from 'react';
import { AdministrationRoute, CoreEmotionalAtmosphere, DosageLevel, SessionScoreModality } from 'wavepaths-shared/core';

import TypographyV2 from '../typography/TypographyV2';
import EvaIcon from './EvaIcon';
import AdministrationIcon from './icons/AdministrationIcon';
import DosageIcon from './icons/DosageIcon';
import IntensityIcon, { IntensityIconProps } from './icons/IntensityIcon';
import ModalityIcon from './icons/ModalityIcon';
import TemplateArtwork from './TemplateArtwork';

export interface TemplateCardProps {
    onCardClick: (id: string) => void;
    imageUrl?: string;
    className?: string;
    id: string;
    title: string;
    subtitle: string;
    intensity: IntensityIconProps['intensity'];
    modality?: SessionScoreModality;
    administration?: AdministrationRoute;
    dosage?: DosageLevel;
    minDurationMins: number;
    maxDurationMins: number;
    emotionalities: {
        primary: CoreEmotionalAtmosphere;
        secondary: CoreEmotionalAtmosphere;
        tertiary: CoreEmotionalAtmosphere;
    };
}

const Container = styled.div`
    width: 200px;
    display: grid;
    grid-template-rows: repeat(2, min-content);
    cursor: pointer;
    &:hover .artwork {
        box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
        transform: scale(1.04) translateY(-5px);
    }
`;

const Artwork = styled.div`
    width: 200px;
    height: 200px;
    overflow: hidden;

    border-radius: 6px;
    background: #f9fafb; // Grey 50
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease, transform 0.2s ease;
`;

// const Image = styled.img`
//     display: block;
//     width: 100%;
//     height: 100%;
//     border-radius: 6px;
//     background: #f9fafb; // Grey 50
//     box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
//     transition: box-shadow 0.15s ease;
// `;

const Info = styled.div`
    display: grid;
    // gap: 4px;
    padding: 8px 0;
`;

const Description = styled.div`
    display: grid;
`;

const Meta = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;

const MetaItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
`;

const TemplateCard: React.FC<TemplateCardProps> = ({
    onCardClick,
    id,
    title,
    subtitle,
    intensity,
    minDurationMins,
    maxDurationMins,
    emotionalities,
    modality,
    administration,
    dosage,
    className,
}) => {
    return (
        <Container
            className={className}
            onClick={() => {
                onCardClick('id');
            }}
        >
            {/* <Artwork>
                <Image src={imageUrl} alt={title} />
            </Artwork> */}
            <Artwork className="artwork">
                <TemplateArtwork
                    templateId={id}
                    intensity={intensity}
                    primaryEmotion={emotionalities.primary}
                    secondaryEmotion={emotionalities.secondary}
                    tertiaryEmotion={emotionalities.tertiary}
                />
            </Artwork>
            <Info>
                <Description>
                    <TypographyV2 element={'h3'} display={'block'} size={'text-sm'} weight={'medium'} truncated>
                        {title}
                    </TypographyV2>
                    <TypographyV2 display={'block'} size={'text-xs'} weight={'regular'} color={'grey-600'} truncated>
                        {subtitle}
                    </TypographyV2>
                </Description>
                <Meta>
                    <MetaItem>
                        <IntensityIcon intensity={intensity} />
                    </MetaItem>
                    <MetaItem>
                        <EvaIcon name="clock-outline" size={16} iconStyle={{ fill: '#667085' }} />
                        <TypographyV2 display={'block'} size={'text-xs'} weight={'medium'} color={'grey-400'} truncated>
                            {minDurationMins}-{maxDurationMins} mins
                        </TypographyV2>
                    </MetaItem>
                    {modality && (
                        <MetaItem>
                            <ModalityIcon modality={modality} />
                        </MetaItem>
                    )}
                    {administration && (
                        <MetaItem>
                            <AdministrationIcon administration={administration} />
                        </MetaItem>
                    )}
                    {dosage && (
                        <MetaItem>
                            <DosageIcon dosage={dosage} />
                        </MetaItem>
                    )}
                </Meta>
            </Info>
        </Container>
    );
};

export default TemplateCard;
