import { clamp, find, findLast, flatMap, isObject, partition } from 'lodash';
import {
    MusicAttributes,
    MusicAttributesEnvelopeStep,
    PathScoreEmotion,
    PathScoreModes,
    PathScoreStage,
    Preset,
    ScheduledPathScore,
    ScheduledWavepath,
    Wavepath,
} from 'wavepaths-shared/core';
import { makeSimpleExpressionEvaluator } from 'wavepaths-shared/scoreExpressions';

import {
    getCEAColour,
    getCEAColourForValenceArousal,
    getColourForValenceArousalAndStage,
} from '../../../common/util/ceaColours';
import { findPathX } from './svgUtils';

export type TimelineWaveProperties = {
    wave: ScheduledWavepath;
    previousWavePathScore?: ScheduledPathScore;
    x: number;
    width: number;
    height: number;
};

export type TimelinePoint = {
    x: number;
    y: number;
    z: string;
};

const DEFAULT_WAVE_GAP_PX = 2.5;
const PERCENT_PER_STAGE_SOOTHE = 50 / 4;
const PERCENT_PER_STAGE_DEEPEN = 50 / 7;
const WAVE_START_FADE_PX = 6;

export const getProportionOfWaveGenerated = (audioGeneratedSecs: number, wavePlan: Wavepath['plan']) => {
    const audioGeneratedMs = audioGeneratedSecs * 1000;

    if (!wavePlan?.toTime || !wavePlan?.fromTime) return 1;

    const audioGeneratedPointInWave = audioGeneratedMs - wavePlan.fromTime;

    if (audioGeneratedMs > wavePlan.toTime) return 1;

    if (audioGeneratedMs < wavePlan.fromTime) return 0;

    const waveDurationMs = wavePlan.toTime - wavePlan.fromTime;
    const proportionGenerated = audioGeneratedPointInWave / waveDurationMs;

    return proportionGenerated;
};

const mapStageToY = (stage: string | number, direction: Preset, height: number): number => {
    const onePercentHeight = height / 100;
    // TODO change with adding 'TherapeuticDirection' on all stages
    if (direction === 'Release') {
        // release pre-mix fade out
        return mapStageToY('1', 'Soothe', height);
    } else if (direction.includes('Soothe')) {
        const pct = PERCENT_PER_STAGE_SOOTHE * Number(stage);
        const clampedPct = clamp(pct, 0, 100);
        return height - clampedPct * onePercentHeight;
    } else {
        // deepen has a greater depth than soothe
        const isRelease = Number(stage) === 8;
        const deepenBasePct = 50;
        const pct = isRelease ? PERCENT_PER_STAGE_SOOTHE * 3 : deepenBasePct + PERCENT_PER_STAGE_DEEPEN * Number(stage);
        const clampedPct = clamp(pct, 0, 100);
        return height - clampedPct * onePercentHeight;
    }
};

const mapArousalToY = (arousal: number, height: number) => {
    return height - height * ((arousal + 1) / 2);
};

const getPointsForGenerativePathScoreStage = (
    { stage, timing, contentSwitch, preset }: PathScoreStage,
    width: number,
    height: number,
    mode?: PathScoreModes,
): TimelinePoint[] => {
    const exprFn = makeSimpleExpressionEvaluator({ waveDuration: 1 });
    const startTime = timing ? exprFn(timing.from) + (exprFn(timing.to) - exprFn(timing.from)) * 0.1 : width / 2;
    const endTime = timing ? exprFn(timing.from) + (exprFn(timing.to) - exprFn(timing.from)) * 0.9 : width / 2;
    const { 'Valence Potential': valence, Arousal: arousal } = contentSwitch?.musicAttributeTargets ?? {
        'Valence Potential': 1,
        Arousal: -1,
    };
    const y = mapStageToY(stage, preset, height);
    const z = mode === 'Percussive' ? 'rgb(44, 57, 88)' : getColourForValenceArousalAndStage(valence, arousal, stage);
    return [
        {
            x: startTime * width,
            y,
            z,
        },
        {
            x: endTime * width,
            y,
            z,
        },
    ];
};

const getPointsForMusicAttributesEnvelope = (
    { timing, musicAttributesEnvelope }: PathScoreStage,
    waveDuration: number,
    width: number,
    height: number,
    emotion?: PathScoreEmotion,
): TimelinePoint[] => {
    if (!musicAttributesEnvelope)
        throw new Error('Trying to draw a music attributes envelope when there is no envelope in the path score');
    const exprFn = makeSimpleExpressionEvaluator({ waveDuration });
    const startTimeMins = timing ? exprFn(timing.from) : 0;
    const endTimeMins = timing ? exprFn(timing.to) : waveDuration;
    const totalDurationMins = endTimeMins - startTimeMins;
    return musicAttributesEnvelope
        .filter((s: MusicAttributesEnvelopeStep) => {
            const timeMins = s.timeOffset / 60;
            return timeMins >= 0 && timeMins < totalDurationMins;
        })
        .map((s: MusicAttributesEnvelopeStep) => {
            const timeMins = s.timeOffset / 60; // offset is in seconds
            const timeProportional = timeMins / waveDuration;
            const colour = getCuratedPathScoreStageStepColour(timeProportional, s.attributes, emotion);
            const { Arousal: arousal, Depth: depth } = s.attributes ?? {
                Arousal: -1,
            };
            return {
                x: timeProportional * width,
                y: depth > 0 ? mapStageToY(depth, 'Soothe', height) : mapArousalToY(arousal, height), // if depth is provided use for y-axis
                z: colour,
            };
        });
};

function getCuratedPathScoreStageStepColour(
    timeProportional: number,
    musicAttributes?: MusicAttributes,
    emotion?: PathScoreEmotion,
) {
    if (isObject(emotion)) {
        return timeProportional < 0.5 ? getCEAColour(emotion.from) : getCEAColour(emotion.to);
    } else if (emotion) {
        return getCEAColour(emotion);
    } else if (musicAttributes?.['Valence Potential']) {
        return getCEAColourForValenceArousal(musicAttributes['Valence Potential'], musicAttributes.Arousal ?? -1);
    } else {
        return 'rgba(0, 0, 0, 0.5)';
    }
}

export const mapPathScoreToPoints = (pathScore: ScheduledPathScore, width: number, height: number): TimelinePoint[] => {
    return flatMap(pathScore.stages, (s) => {
        if (s.musicAttributesEnvelope) {
            return getPointsForMusicAttributesEnvelope(
                s,
                pathScore.duration as number,
                width,
                height,
                pathScore.emotion,
            );
        } else {
            return getPointsForGenerativePathScoreStage(s, width, height, pathScore.mode);
        }
    });
};

const addStartAndEndContinuityToCurvePoints = (
    points: TimelinePoint[],
    width: number,
    prevPathPoints?: TimelinePoint[],
) => {
    const startPoints = addStartContinuityToCurvePoints(points, prevPathPoints);
    const lastPoint = points[points.length - 1];
    return [...startPoints, { z: lastPoint.z, y: lastPoint.y, x: width }];
};

export const addStartContinuityToCurvePoints = (points: TimelinePoint[], prevPathPoints?: TimelinePoint[]) => {
    if (prevPathPoints) {
        const prevEnd = prevPathPoints[prevPathPoints.length - 1];
        const [pointsDuringFade, pointsAfterFade] = partition(points, (p) => p.x <= WAVE_START_FADE_PX);
        const fadeTo = pointsDuringFade.length > 0 ? pointsDuringFade[pointsDuringFade.length - 1] : pointsAfterFade[0];
        return [{ ...prevEnd, x: 0 }, { ...fadeTo, x: WAVE_START_FADE_PX }, ...pointsAfterFade];
    } else {
        const firstPoint = points[0];
        if (firstPoint.x > 0) {
            return [{ ...firstPoint, x: 0 }, ...points];
        } else {
            return points;
        }
    }
};

export const addPaddingToCurvePoints = (points: TimelinePoint[], width: number, padding = DEFAULT_WAVE_GAP_PX) => {
    if (padding === 0) return points;
    const startXPadding = findPathX(padding, points);
    const startLhs = findLast(points, (p) => p.x < startXPadding);
    const startRhs = find(points, (p) => p.x >= startXPadding);
    if (startLhs && startRhs) {
        const startD = (startXPadding - startLhs.x) / (startRhs.x - startLhs.x);
        const startY = lerp(startLhs.y, startRhs.y, startD);
        points = [{ x: startXPadding, y: startY, z: startLhs.z }, ...points.filter((p) => p.x >= startXPadding)];
    }
    const endXPadding = width - findPathX(-padding, points);
    const endLhs = findLast(points, (p) => p.x <= width - endXPadding);
    const endRhs = find(points, (p) => p.x > width - endXPadding);
    if (endLhs && endRhs) {
        const endD = (width - endXPadding - endLhs.x) / (endRhs.x - endLhs.x);
        const endY = lerp(endLhs.y, endRhs.y, endD);
        points = [
            ...points.filter((p) => p.x <= width - endXPadding),
            { x: width - endXPadding, y: endY, z: endRhs.z },
        ];
    }
    return points;
};

export const lerp = (a: number, b: number, d: number) => {
    return a + d * (b - a);
};

export const getCurvePointsFromWave = (wave: TimelineWaveProperties, padding?: number): TimelinePoint[] => {
    const points = mapPathScoreToPoints(wave.wave.pathScore, wave.width, wave.height);
    const pointsWithStartEnd = addStartAndEndContinuityToCurvePoints(
        points,
        wave.width,
        wave.previousWavePathScore
            ? mapPathScoreToPoints(wave.previousWavePathScore, wave.width, wave.height)
            : undefined,
    );
    return addPaddingToCurvePoints(pointsWithStartEnd, wave.width, padding);
};

export const getWaveViewbox = (
    wave: TimelineWaveProperties,
    padding = DEFAULT_WAVE_GAP_PX,
): { left: number; top: number; width: number; height: number } => {
    const points = getCurvePointsFromWave(wave, padding);
    let minX = Number.MAX_SAFE_INTEGER,
        minY = Number.MAX_SAFE_INTEGER,
        maxX = -Number.MAX_SAFE_INTEGER,
        maxY = -Number.MAX_SAFE_INTEGER;
    for (const point of points) {
        minX = Math.min(minX, point.x);
        minY = Math.min(minY, point.y);
        maxX = Math.max(maxX, point.x);
        maxY = Math.max(maxY, point.y);
    }
    return {
        left: minX,
        top: minY,
        width: maxX - minX,
        height: maxY - minY,
    };
};
