import { sample } from 'lodash';
import React, { useState } from 'react';
import {
    Acousticness,
    BaseScheduledGenerativePathScore,
    isScheduledGenerativePathScore,
    PathScore,
    ScheduledExperimentalPathScore,
    ScheduledGenerativeDeepenPathScore,
    SessionScoreEmotionalIntensity,
    TherapeuticDirection,
    Wavepath,
} from 'wavepaths-shared/core';

import { DropdownControl, SegmentedControl } from '@/component-library';
import { ControlOption } from '@/component-library/components/Control/types';

import * as infoCopy from './infoCopy';
import * as pathScoreFns from './pathScoreFns';

export interface SelectorState {
    tone?: Acousticness;
    music?: string;
    intensity?: SessionScoreEmotionalIntensity;
    duration: number | string;
}

const selectorStateDefault: SelectorState = {
    tone: undefined,
    intensity: undefined,
    music: '',
    duration: '',
};

export type DeepenPathScore =
    | BaseScheduledGenerativePathScore
    | ScheduledGenerativeDeepenPathScore
    | ScheduledExperimentalPathScore;

interface PercDeepenWaveSelectorProps {
    pathScores: DeepenPathScore[];
    wavepath: Wavepath;
    onWavepathEdited: (wavepath: Wavepath) => void;
    onWavepathEditClear: () => void;
}

export const isDeepenPathScore = (p: PathScore): p is DeepenPathScore => p.direction === TherapeuticDirection.DEEPEN;
export const isPercussivePathScore = (pathScore: DeepenPathScore) => pathScore.mode === 'Percussive';

function PercDeepenWaveSelector({
    pathScores,
    wavepath,
    onWavepathEdited,
    onWavepathEditClear,
}: PercDeepenWaveSelectorProps): JSX.Element {
    const [selectorState, setSelectorState] = useState<SelectorState>({
        ...selectorStateDefault,
        ...(isDeepenPathScore(wavepath.pathScore) &&
            isPercussivePathScore(wavepath.pathScore) && {
                tone: 'acousticness' in wavepath.pathScore ? wavepath.pathScore.acousticness : undefined,
                intensity: wavepath.pathScore.selectionCriteria?.emotionalIntensity,
                music: 'music' in wavepath.pathScore ? wavepath.pathScore.music : '',
                duration: wavepath.duration ?? pathScoreFns.AUTO_VALUE,
            }),
    });

    const filteredPathScores = pathScores.filter(isScheduledGenerativePathScore);

    const toneColourFilteredScores = pathScoreFns.filterScoresByTone(filteredPathScores, selectorState.tone);
    const intensityFilteredScores = pathScoreFns.filterScoresByIntensity(
        toneColourFilteredScores,
        selectorState.intensity,
    );
    const postIntensityFilteredScores = pathScoreFns.filterScoresByMusic(intensityFilteredScores, selectorState.music);

    const durationOptions: ControlOption<number | string>[] = [
        ...[...pathScoreFns.extractDurationsFromScores(postIntensityFilteredScores)].map(
            (value): ControlOption<number | string> => ({
                label: value === pathScoreFns.AUTO_VALUE ? value : `${value} minutes`,
                value: value,
                disabled: false,
            }),
        ),
    ];

    const handleDurationChange = (duration: number | string) => {
        setSelectorState({
            ...selectorStateDefault,
            tone: selectorState.tone,
            intensity: selectorState.intensity,
            music: selectorState.music,
            duration,
        });
        const durationFilteredScores = pathScoreFns.filterScoresByDuration(postIntensityFilteredScores, duration);
        const pathScore = sample(durationFilteredScores);
        if (pathScore) {
            onWavepathEdited({
                ...wavepath,
                duration: typeof duration === 'number' ? duration : undefined,
                pathScore,
                pathId: pathScore.id,
            });
        }
    };

    return (
        <>
            <SegmentedControl
                name="wave-tone"
                heading={'Tone'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    ['Acoustic', 'Mixed', 'Electronic'] as Acousticness[],
                    pathScoreFns.extractToneColoursFromScores(filteredPathScores),
                )}
                value={selectorState.tone}
                onChange={(value?: Acousticness) => {
                    setSelectorState({
                        ...selectorStateDefault,
                        tone: value,
                    });
                    onWavepathEditClear();
                }}
                info={infoCopy.tone}
            />
            <SegmentedControl
                name="wave-intensity"
                heading={'Intensity'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    [
                        SessionScoreEmotionalIntensity.LOW,
                        SessionScoreEmotionalIntensity.MEDIUM,
                        SessionScoreEmotionalIntensity.HIGH,
                        SessionScoreEmotionalIntensity.ALL,
                    ],
                    pathScoreFns.extractIntensitiesFromScores(toneColourFilteredScores),
                )}
                value={selectorState.intensity}
                onChange={(value) => {
                    setSelectorState({
                        ...selectorStateDefault,
                        tone: selectorState.tone,
                        intensity: value,
                    });
                    onWavepathEditClear();
                }}
                disabled={!selectorState.tone}
                info={infoCopy.intensity}
            />
            <DropdownControl
                name="wave-music"
                heading={'Music'}
                canSave={false}
                size="small"
                colour="dark"
                options={pathScoreFns.convertListToOptions(
                    [...pathScoreFns.extractMusicOptionsFromScores(intensityFilteredScores)],
                    pathScoreFns.extractMusicOptionsFromScores(intensityFilteredScores),
                )}
                value={selectorState.music}
                onChange={(value?: string) => {
                    setSelectorState({
                        ...selectorStateDefault,
                        tone: selectorState.tone,
                        intensity: selectorState.intensity,
                        music: value,
                    });
                    onWavepathEditClear();
                }}
                disabled={!selectorState.tone}
                info={infoCopy.composition}
            />
            <DropdownControl
                name="wave-duration"
                heading={'Duration'}
                canSave={false}
                size="small"
                colour="dark"
                options={durationOptions}
                value={durationOptions.length ? selectorState.duration : ''}
                onChange={handleDurationChange}
                disabled={!selectorState.music}
                info={infoCopy.duration}
            />
        </>
    );
}

export default PercDeepenWaveSelector;
