import { makeStyles } from '@material-ui/core';
import MaterialButton from '@material-ui/core/Button';
import _ from 'lodash';
import React, { CSSProperties, ReactElement, ReactNode } from 'react';

import { FONT_SIZES } from '../typography/variables';

const useStyles = makeStyles({
    root: (props: IButtonProps) => {
        const baseStyles: CSSProperties = {
            padding: getPadding(props),
            minWidth: 0,
            backgroundColor:
                props.variant === 'solid-light'
                    ? 'rgb(255,255,255)'
                    : props.variant === 'solid-dark'
                    ? 'rgb(0,0,0)'
                    : props.variant === 'solid-blue'
                    ? 'rgb(44, 57, 88)'
                    : 'transparent',
            borderRadius: '50px',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: props.variant === 'outlined' ? 'rgba(0,0,0,0.12)' : 'transparent',
            fontFamily: 'Inter',
            fontSize:
                props.size === 'l' ? FONT_SIZES.medium : props.size === 'xs' ? FONT_SIZES.extraSmall : FONT_SIZES.small,

            fontWeight: props.variant === 'clear-underlined' || props.size === 'l' ? 500 : 600,
            textDecoration: props.variant === 'clear-underlined' ? 'underline' : 'none',
            textTransform: props.variant == 'clear-underlined' ? 'none' : 'capitalize',
            color: getColor(props),
            transition: '0.15s all',
            opacity: 1,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            '&:hover': {
                textDecoration: props.variant === 'clear-underlined' ? 'underline' : 'none',
                backgroundColor:
                    props.variant === 'solid-light'
                        ? 'rgb(255,255,255)'
                        : props.variant === 'solid-dark'
                        ? 'rgb(0,0,0)'
                        : props.variant === 'solid-blue'
                        ? 'rgb(44, 57, 88)'
                        : 'transparent',
                boxShadow:
                    props.variant === 'solid-dark' || props.variant === 'solid-blue' || props.variant === 'solid-light'
                        ? '0px 4px 8px rgba(0, 0, 0, 0.3)'
                        : 'none',
                borderColor:
                    props.variant === 'clear'
                        ? 'rgba(0,0,0,0.12)'
                        : props.variant === 'clear-light'
                        ? 'rgba(255,255,255,0.24)'
                        : props.variant === 'outlined'
                        ? 'rgba(0,0,0,0.24)'
                        : 'transparent',
            },
        };

        const disabledStyles = props.disabled
            ? {
                  '&:disabled': {
                      backgroundColor:
                          props.variant === 'solid-light'
                              ? '#FFFF'
                              : props.variant === 'solid-dark'
                              ? 'rgba(0, 0, 0, 0.3);'
                              : props.variant === 'solid-blue'
                              ? 'rgba(44, 57, 88, 0.5)'
                              : 'transparent',
                      color:
                          props.variant &&
                          ['solid-light', 'clear-underlined', 'clear', 'outlined'].includes(props.variant)
                              ? 'rgba(0, 0, 0, 0.3)'
                              : 'rgba(255, 255, 255, 0.3)',
                  },
                  opacity: 0.5,
                  cursor: 'default',
              }
            : {};

        return { ...baseStyles, ...disabledStyles };
    },
    label: (props: IButtonProps) => ({
        display: 'inline-grid',
        gridAutoFlow: 'column',
        gap: props.size === 'l' ? '8px' : '4px',
        alignContent: 'center',
    }),
});

interface StyleProps {
    variant?: 'clear-underlined' | 'clear' | 'clear-light' | 'solid-light' | 'solid-dark' | 'solid-blue' | 'outlined';
    size?: 'xs' | 's' | 'm' | 'l';
}

export interface IButtonProps extends StyleProps {
    children?: ReactNode | ReactNode[];
    icon?: ReactNode;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    style?: React.CSSProperties;
    type?: 'button' | 'submit' | 'reset';
    className?: string;
}

function getColor(props: IButtonProps) {
    switch (props.variant) {
        case 'solid-dark':
        case 'solid-blue':
        case 'clear-light':
            return '#FFFFFF';
        default:
            return '#000000';
    }
}

function getPadding(props: IButtonProps) {
    if (props.variant === 'clear-underlined') return '0px 0px';

    if (props.size === 'xs' && props.icon && props.children) return '4px 12px 4px 8px';
    if (props.size === 'xs' && props.icon && !props.children) return '4px 4px';
    if (props.size === 'xs') return '4px 12px';

    if (props.size === 's' && props.icon && props.children) return '4px 12px 4px 8px';
    if (props.size === 's' && props.icon && !props.children) return '4px 4px';
    if (props.size === 's') return '4px 12px';

    if (props.size === 'm' && props.icon && props.children) return '8px 16px 8px 12px';
    if (props.size === 'm' && props.icon && !props.children) return '8px 8px';
    if (props.size === 'm') return '8px 16px';
    Button;

    if (props.size === 'l' && props.icon && props.children) return '12px 24px 12px 18px';
    if (props.size === 'l' && props.icon && !props.children) return '12px 12px';
    if (props.size === 'l') return '12px 24px';

    return '4px 12px';
}

export default function Button(props: IButtonProps): ReactElement {
    // pull variant and size out explicitly to avoid conflict with material-ui buttons prop types
    const { icon, children, onClick, ...rest } = props;
    const classes = useStyles(props);
    return (
        <MaterialButton
            classes={{ root: classes.root, label: classes.label }}
            className={props.className}
            onClick={onClick}
            {..._.omit(rest, ['variant', 'size'])}
        >
            {icon}
            {children}
        </MaterialButton>
    );
}
