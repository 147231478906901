import { omitBy } from 'lodash';

export const getUtmTagsFromStorage = () => {
    const gamUserTrackingString = typeof window !== `undefined` && localStorage.getItem('gam_user_tracking');
    const gamUserTracking = gamUserTrackingString && JSON.parse(gamUserTrackingString);
    const { utm_campaign_first, utm_content_first, utm_medium_first, utm_source_first, utm_term_first } =
        gamUserTracking ?? {};
    const utmValues = {
        utm_campaign: utm_campaign_first,
        utm_content: utm_content_first,
        utm_medium: utm_medium_first,
        utm_source: utm_source_first,
        utm_term: utm_term_first,
    };

    return omitBy(utmValues, (x) => !x);
};
