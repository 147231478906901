import './VolumeMeter.scss';

import VolumeDownIcon from '@material-ui/icons/VolumeDownRounded';
import VolumeUpIcon from '@material-ui/icons/VolumeUpRounded';
import React, { useEffect, useRef } from 'react';

import { Typography } from '@/component-library';

import { ANALYSIS_FFT_SIZE } from '../../freudConnection/hlsUtils';
import { analyseVolume } from './analyseVolume';

interface VolumeMeterProps {
    analyserLeft?: AnalyserNode;
    analyserRight?: AnalyserNode;
    volume: number;
    onSetVolume?: (newVolume: number) => void;
}
export const VolumeMeter: React.FC<VolumeMeterProps> = React.memo(
    ({ analyserLeft, analyserRight, volume, onSetVolume }) => {
        const leftBar = useRef<HTMLDivElement>(null);
        const rightBar = useRef<HTMLDivElement>(null);

        useEffect(() => {
            const data = new Uint8Array(ANALYSIS_FFT_SIZE);
            let stillRunning = true;

            function frame() {
                if (!stillRunning || !analyserLeft || !analyserRight) return;
                const leftVol = analyseVolume(analyserLeft, data, ANALYSIS_FFT_SIZE);
                const rightVol = analyseVolume(analyserRight, data, ANALYSIS_FFT_SIZE);
                if (leftBar.current) {
                    leftBar.current.style.transform = `scale3d(${leftVol}, 1, 1)`;
                }
                if (rightBar.current) {
                    rightBar.current.style.transform = `scale3d(${rightVol}, 1, 1)`;
                }
                requestAnimationFrame(frame);
            }
            requestAnimationFrame(frame);

            return () => {
                stillRunning = false;
            };
        }, [analyserLeft, analyserRight]);

        return (
            <div className="volumeMeter">
                <Typography variant="body2">Volume (this device only)</Typography>
                <div className="volumeMeterContent">
                    <VolumeDownIcon />
                    <div className="volumeMeterControls">
                        <div className="levels">
                            {analyserLeft && <div className="level left" ref={leftBar}></div>}
                            {analyserRight && <div className="level right" ref={rightBar}></div>}
                        </div>
                        <input
                            className="slider"
                            type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={volume}
                            onChange={(e) => onSetVolume?.(+e.target.value)}
                        />
                    </div>
                    <VolumeUpIcon />
                </div>
            </div>
        );
    },
);
