import firebase from 'firebase';
import useSWR from 'swr';

import { getHomeSections, IGetHomeSectionsReturn } from '../../common/api/contentApi';

type IUseHomeContentProps = {
    fbUser?: firebase.User;
};

export function useHomeContent({ fbUser }: IUseHomeContentProps): IGetHomeSectionsReturn | undefined {
    const { data } = useSWR<IGetHomeSectionsReturn, Error>(
        fbUser ? `/my/home` : null,
        fbUser ? () => getHomeSections({ fbUser }) : null,
    );

    return data;
}
