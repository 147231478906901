import { Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { padStart } from 'lodash';
import React from 'react';

import { Button, Typography } from '@/component-library';

import { QueuedFunction } from './useActionQueue';

interface QueuedItemStylesProps {
    queueTime: number;
}
const useStyles = makeStyles<Theme, QueuedItemStylesProps>((theme) => ({
    queuedItemOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'blur(8px)',
        zIndex: 3,
        willChange: 'opacity',
        animationName: '$fadeIn',
        animationTimingFunction: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
        animationDuration: '0.3s',
        animationFillMode: 'forwards',
    },
    queuedItemOverlayLeaving: {
        animationName: '$fadeOut',
        animationDelay: '0.2s',
    },
    queuedItem: {
        position: 'fixed',
        top: 24,
        left: 24,
        width: 318,
        overflow: 'hidden',
        padding: '16px 16px 20px 16px',
        display: 'grid',
        gridTemplateColumns: '3fr 1fr',
        gridTemplateRows: 'auto',
        gridTemplateAreas: '"description secondsRemaining" "actions actions"',
        backgroundColor: 'black',
        color: theme.palette.primary.dark,
        zIndex: 4,
        transform: 'translateX(-120%)',
        willChange: 'transform',
        animationName: '$slideIn',
        animationTimingFunction: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
        animationDelay: '0.2s',
        animationDuration: '0.3s',
        animationFillMode: 'forwards',
    },
    queuedItemLeaving: {
        animationName: '$slideOut',
        animationDelay: '0s',
    },
    description: {
        gridArea: 'description',
        color: theme.palette.primary.dark,
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        lineHeight: '24px',
        maxHeight: '48px',
        textOverflow: 'ellipsis',
    },
    secondsRemaining: {
        gridArea: 'secondsRemaining',
        color: theme.palette.primary.dark,
        textAlign: 'right',
    },
    actions: {
        gridArea: 'actions',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 16,
    },
    progressBar: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        height: 4,
        borderRadius: 2,
        backgroundColor: theme.palette.success.main,
        willChange: 'transform',
        animationName: '$progress',
        animationTimingFunction: 'linear',
        animationDuration: (props) => `${props.queueTime}s`,
    },
    '@keyframes fadeIn': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
    '@keyframes fadeOut': {
        '0%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
    '@keyframes slideIn': {
        '0%': {
            transform: 'translateX(-120%)',
        },
        '100%': {
            transform: 'translateX(0)',
        },
    },
    '@keyframes slideOut': {
        '0%': {
            transform: 'translateX(0)',
        },
        '100%': {
            transform: 'translateX(-120%)',
        },
    },
    '@keyframes progress': {
        '0%': {
            transform: 'translateX(-100%)',
        },
        '100%': {
            transform: 'translateX(0)',
        },
    },
}));

export interface QueuedItemProps {
    queuedFunction?: null | QueuedFunction;
    onCancel: () => void;
    onSkipQueue: () => void;
}

const QueuedItem: React.FC<QueuedItemProps> = React.memo(
    ({ queuedFunction, onCancel, onSkipQueue }): JSX.Element | null => {
        const classes = useStyles({ queueTime: queuedFunction?.queueTime ?? 0 });
        const isLeaving = queuedFunction?.hasFinishedQueueing ?? false;
        return queuedFunction ? (
            <div className={classNames(classes.queuedItemOverlay, { [classes.queuedItemOverlayLeaving]: isLeaving })}>
                <Paper
                    elevation={6}
                    className={classNames(classes.queuedItem, { [classes.queuedItemLeaving]: isLeaving })}
                >
                    <Typography variant="subtitle2" className={classes.description}>
                        {queuedFunction.description} in&hellip;
                    </Typography>
                    <Typography variant="subtitleFixedWidth" className={classes.secondsRemaining}>
                        {getSecondsRemainingLabel(queuedFunction)}
                    </Typography>
                    <div className={classes.actions}>
                        <Button
                            style={{
                                border: '1px solid rgba(255, 255, 255, 0.2)',
                                color: 'rgba(255, 255, 255, 0.8)',
                            }}
                            variant="outlined"
                            onClick={onSkipQueue}
                        >
                            Submit Now
                        </Button>
                        <Button variant="solid-light" onClick={onCancel}>
                            Cancel This Change
                        </Button>
                    </div>
                    <div className={classes.progressBar} />
                </Paper>
            </div>
        ) : null;
    },
);

function getSecondsRemainingLabel(queuedFunction: QueuedFunction) {
    const secondsRemaining = Math.ceil(Math.max(0, queuedFunction.timeUntilExecutionInSeconds));
    return padStart('' + secondsRemaining, 2, '0');
}

export default QueuedItem;
