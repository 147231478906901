import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React from 'react';
import { isPostludePathScore, isPreludePathScore, isPrePostLudePathScore, PathScore } from 'wavepaths-shared/core';

import { Button, EvaIcon, Typography } from '@/component-library';

export const MOVE_CARD_UP_LABEL = 'Move Up';
export const MOVE_CARD_DOWN_LABEL = 'Move Down';
export const SKIP_WAVE_LABEL = 'Skip To This Wave';
export const ADD_WAVE_LABEL = 'Add';
export const DELETE_WAVE_LABEL = 'Delete';

const useStyles = makeStyles({
    barMenu: {
        width: '100%',
        display: 'grid',
        gridAutoFlow: 'column',
        gap: '4px',
        alignItems: 'center',
    },
    fiveCols: {
        gridTemplateColumns: 'repeat(5, auto)',
    },
    fourCols: {
        gridTemplateColumns: 'repeat(4, 1fr)',
    },
    barMenuBottom: {
        marginTop: '8px',
    },
    barMenuTop: {
        marginBottom: '8px',
    },
    barMenuWithEnterAnimation: {
        animation: '$barMenuEnter 0.2s 0.2s ease both',
    },
    '@keyframes barMenuEnter': {
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
    },
    barMenuSave: {
        justifyContent: 'end',
    },
    barMenuSingle: {
        gridTemplateColumns: '1fr',
    },
});

export interface IWaveCardTopBarProps {
    isOpen: boolean;
    isEdited: boolean;
    pathScore: PathScore;
    onAddWave: () => void;
    onSkipToWave?: () => void;
    onRemoveWave: () => void;
    onMoveWaveUp: () => void;
    onMoveWaveDown: () => void;
}

const WaveCardTopBar = ({
    isOpen,
    isEdited,
    pathScore,
    onMoveWaveUp,
    onMoveWaveDown,
    onSkipToWave,
    onRemoveWave,
    onAddWave,
}: IWaveCardTopBarProps): JSX.Element | null => {
    const classes = useStyles({ isOpen });

    if (!isOpen || isPreludePathScore(pathScore)) return null;

    if (!isEdited && !isPrePostLudePathScore(pathScore)) {
        return (
            <div
                className={classNames(
                    classes.barMenu,
                    classes.barMenuTop,
                    classes.barMenuWithEnterAnimation,
                    onSkipToWave ? classes.fiveCols : classes.fourCols,
                    'tour-waveActions',
                )}
            >
                <Button size="s" onClick={onMoveWaveUp} variant="clear">
                    <EvaIcon name="arrow-upward" size={16} />
                    <Typography variant="body2">{MOVE_CARD_UP_LABEL}</Typography>
                </Button>
                <Button size="s" onClick={onMoveWaveDown} variant="clear">
                    <EvaIcon name="arrow-downward" size={16} />
                    <Typography variant="body2">{MOVE_CARD_DOWN_LABEL}</Typography>
                </Button>
                {onSkipToWave ? (
                    <Button size="s" onClick={onSkipToWave} variant="clear">
                        <EvaIcon name="arrowhead-right" size={16} />
                        <Typography variant="body2">{SKIP_WAVE_LABEL}</Typography>
                    </Button>
                ) : null}
                <Button size="s" onClick={onRemoveWave} variant="clear">
                    <EvaIcon name="trash-2-outline" size={16} />
                    <Typography variant="body2">{DELETE_WAVE_LABEL}</Typography>
                </Button>
                <Button size="s" onClick={onAddWave} variant="clear">
                    <EvaIcon name="plus-outline" size={16} />
                    <Typography variant="body2">{ADD_WAVE_LABEL}</Typography>
                </Button>
            </div>
        );
    }

    if (!isEdited && isPostludePathScore(pathScore)) {
        return (
            <div className={classNames(classes.barMenu, classes.barMenuTop, classes.barMenuSingle)}>
                {onSkipToWave ? (
                    <Button
                        style={{ justifySelf: 'end', width: '173px' }}
                        size="s"
                        onClick={onSkipToWave}
                        variant="clear"
                    >
                        <EvaIcon name="arrowhead-right" size={16} />
                        <Typography variant="body2">{SKIP_WAVE_LABEL}</Typography>
                    </Button>
                ) : null}
                <Button style={{ justifySelf: 'end' }} size="s" onClick={onAddWave} variant="clear">
                    <EvaIcon name="plus-outline" size={16} />
                    <Typography variant="body2">{ADD_WAVE_LABEL}</Typography>
                </Button>
            </div>
        );
    }

    return <div style={{ height: '8px' }} />;
};

export default WaveCardTopBar;
