import { isEmpty } from 'lodash';
import React from 'react';
import { ReactElement } from 'react';
import { ValueType } from 'react-select';
import { SessionScoreAdministration, SessionScoreModality } from 'wavepaths-shared/core';

import { Typography } from '@/component-library';

import Select from '../../common/components/Inputs/Select';
import { getAdministrationsForMedicine } from '../../common/domain/modalitiesOld';
import AdministrationInfoDialog from './infodialogs/AdministrationInfoDialog';

type AdministrationOption = {
    label: string;
    value: SessionScoreAdministration;
};

interface AdministrationInputProps {
    administration: SessionScoreAdministration;
    modality: SessionScoreModality;
    onAdministrationChanged: (administration: SessionScoreAdministration) => void;
}

function AdministrationInput({
    administration,
    modality,
    onAdministrationChanged,
}: AdministrationInputProps): ReactElement {
    const administrations = getAdministrationsForMedicine(modality);

    if (isEmpty(administrations)) return <></>;

    const administrationOptions: AdministrationOption[] = administrations.map(({ id, name }) => ({
        value: id,
        label: name,
    }));
    const selectedAdministration = administrationOptions.find((option) => option.value === administration);

    return (
        <>
            <label htmlFor="administrationInput">
                <Typography variant="body2" component="span">
                    Administration
                    <AdministrationInfoDialog />
                </Typography>
            </label>
            <Select
                name="Administration"
                inputId="administrationInput"
                className="input inputSelect inputSelect--small"
                value={selectedAdministration}
                isSearchable={false}
                options={administrationOptions}
                onChange={(selection: ValueType<AdministrationOption>) => {
                    // TODO: update ReactSelect so we can avoid their legacy broken typings and remove the workaround
                    // https://github.com/JedWatson/react-select/issues/2902#issuecomment-864424022
                    const { value } = selection as AdministrationOption;
                    onAdministrationChanged(+value);
                }}
            />
        </>
    );
}

export default AdministrationInput;
