import Stripe from 'stripe';

import configs from '../../configs';

export type StripeError = { error: { message: string } };

const headers = { 'Content-Type': 'application/json' };

interface ICreateCustomer {
    name: string;
    email: string;
    uid: string;
}

export const createCustomer = async ({ name, email, uid }: ICreateCustomer): Promise<Stripe.Customer | undefined> => {
    try {
        const response = await fetch(`${configs.cloudFunctions.URL}/createStripeCustomer`, {
            method: 'post',
            headers,
            body: JSON.stringify({ name, uid, email }),
        });

        // The customer has been created
        const customer: Stripe.Customer = await response.json();
        return customer;
    } catch (error) {
        console.error(error);
        return undefined;
    }
};

interface ICreateCheckoutSession {
    priceId: string;
    uid: string;
    email: string;
    customerId?: string;
    trialEnd?: number;
    trialPeriodDays?: number;
}

export const createCheckoutSession = async ({
    priceId,
    customerId,
    uid,
    email,
    trialEnd,
    trialPeriodDays,
}: ICreateCheckoutSession): Promise<{ sessionId: string } | undefined> => {
    try {
        const response = await fetch(`${configs.cloudFunctions.URL}/createStripeCheckoutSession`, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                priceId,
                customerId,
                uid,
                email,
                ...(trialEnd && { trialEnd }),
                ...(trialPeriodDays && { trialPeriodDays }),
            }),
        });

        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        console.log(error);
    }
};

type CreateCustomerPortal = { uid?: string; customerId: string } | { uid: string; customerId?: string };

export const createCustomerPortal = async ({
    uid,
    customerId,
}: CreateCustomerPortal): Promise<{ url: string } | undefined> => {
    try {
        const response = await fetch(`${configs.cloudFunctions.URL}/createStripeCustomerPortal`, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                uid,
                customerId,
            }),
        });
        if (response.ok) {
            return await response.json();
        }
    } catch (error) {
        console.log(error);
    }
};

type ListSubscriptionForUserType =
    | {
          uid: string;
          customerId?: string;
          status?: Stripe.SubscriptionListParams.Status;
      }
    | {
          uid?: string;
          customerId: string;
          status?: Stripe.SubscriptionListParams.Status;
      };

export const listSubscriptionsForUser = async ({
    uid,
    customerId,
    status,
}: ListSubscriptionForUserType): Promise<{ subscriptions: Stripe.Subscription[] }> => {
    try {
        const response = await fetch(`${configs.cloudFunctions.URL}/listStripeSubscriptionsForUser`, {
            method: 'POST',
            headers,
            body: JSON.stringify({
                uid,
                customerId,
                status,
            }),
        });
        return await response.json();
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const hasGBPCurrency = (subscriptions: Stripe.Subscription[] = []): boolean =>
    subscriptions[0]?.items.data[0].price.currency === 'gbp';
