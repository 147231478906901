import firebase from 'firebase/app';
import { isUndefined } from 'lodash';
import { APISessionStatus, ISessionsEndpointResponseType } from 'wavepaths-shared/core';

import configs from '../../configs';
import axios from '../util/axios';

export const FREUD_BASE_URL = configs.freud.BASE_URL;

type IListSessions = {
    fbUser: firebase.User;
    sessionState: APISessionStatus;
    name?: string | undefined;
    type?: string | undefined;
    max?: number | undefined;
    page?: number | undefined;
};

export async function listSessions({
    fbUser,
    sessionState,
    name = undefined,
    type = undefined,
    max = undefined,
    page = undefined,
}: IListSessions): Promise<ISessionsEndpointResponseType> {
    const offset = max && !isUndefined(page) ? max * page : undefined;
    return axios(`${FREUD_BASE_URL}/v2/sessions/${sessionState}`, {
        headers: { Authorization: `idToken ${await fbUser.getIdToken()}` },
        params: { name: name ? name : undefined, type, max, offset }, // keeps name param out of request url when it is empty
    }).then((res) => (res.status === 200 ? res.data : null));
}
