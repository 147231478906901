import React from 'react';
import { useHistory } from 'react-router-dom';

// import UserEvents from '../../../UserEvents';
import IntroSessionCard from './ProductTourCard';

export function SessionPlannerTourCard(): JSX.Element {
    const history = useHistory();

    const onClickPlaySessionNow = () => {
        history.push('/session/new/oneOnOne?isTour=true');
        // UserEvents.
    };

    return (
        <>
            <IntroSessionCard
                title={'Planning a session'}
                info={`How to compose with Wavepaths`}
                duration={'3 mins'}
                onClickPlaySessionNow={onClickPlaySessionNow}
            />
        </>
    );
}
