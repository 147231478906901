import {
    addWeeks,
    differenceInCalendarDays,
    format,
    isPast,
    isSameWeek,
    isThisYear,
    isToday,
    isTomorrow,
    isYesterday,
} from 'date-fns';
import { padStart } from 'lodash';

export const weekStartsOn = 1; // Monday

export const formatDateTime = (ts?: Date | number): string => {
    if (!ts) return '';
    const date = new Date(ts);
    return `${format(new Date(ts), 'HH:mm')} ${formatDate(date)}`;
};

export const formatDate = (date: Date | number): string => {
    if (isToday(date)) {
        return 'today';
    } else if (isTomorrow(date)) {
        return 'tomorrow';
    } else if (isYesterday(date)) {
        return 'yesterday';
    } else if (isInTheLastXCalendarDays(date, 6)) {
        return `${format(date, 'eeee')}`; // weekday in full ie Monday
    } else if (isThisYear(date)) {
        return `${format(date, 'eee do MMM')}`; //abbreviated weekday day of month abbreviated month ie Mon 6th Jun
    } else {
        return `${format(date, 'eee do MMM yyyy')}`; // abbreviated weekday day of month abbreviated month full year ie Mon 6th Jun 2020
    }
};

// TODO: refactor this function because it is hard to read
export function formatDuration(ms: number) {
    const hours = Math.floor(ms / (60 * 60 * 1000));
    ms -= hours * 60 * 60 * 1000;
    const minutes = Math.floor(ms / (60 * 1000));
    ms -= minutes * 60 * 1000;
    const seconds = Math.floor(ms / 1000);

    const hoursString = hours > 0 ? padStart('' + hours, 2, '0') + ':' : '';
    const minutesString = padStart('' + minutes, 2, '0') + ':';
    const secondsString = padStart('' + seconds, 2, '0');

    return `${hoursString}${minutesString}${secondsString}`;
}

export const isInTheLastXCalendarDays = (date: Date | number, x: number): boolean =>
    isPast(date) && differenceInCalendarDays(new Date(), date) <= x;

export const isNextWeek = (date: Date | number): boolean =>
    isSameWeek(addWeeks(new Date(), 1), new Date(date), { weekStartsOn });

export const isLastWeek = (date: Date | number): boolean =>
    isSameWeek(addWeeks(new Date(), -1), new Date(date), { weekStartsOn });
