import { SvgIcon } from '@material-ui/core';
import React from 'react';
const CheckboxIcon = (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path
            d="M5,4.17A.83.83,0,0,0,4.17,5V15a.83.83,0,0,0,.83.83H15a.83.83,0,0,0,.83-.83V5A.83.83,0,0,0,15,4.17ZM15,17.5H5A2.5,2.5,0,0,1,2.5,15V5A2.5,2.5,0,0,1,5,2.5H15A2.5,2.5,0,0,1,17.5,5V15A2.5,2.5,0,0,1,15,17.5Z"
            fill="black"
            fillRule="evenodd"
        />
    </SvgIcon>
);

export default CheckboxIcon;
