import { millisecondsToMinutes } from 'date-fns';
import { isNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import io from 'socket.io-client';
import { SessionTick } from 'wavepaths-shared/core';

import * as api from '../api/sessionApi';

interface ISessionTimingState {
    timeElapsed?: number;
    totalDuration?: number;
    timeUntilStart?: number;
}

export const useSessionTiming = (broadcastId: string): ISessionTimingState => {
    const [timeElapsed, setTimeElapsed] = useState<number>();
    const [totalDuration, setTotalDuration] = useState<number>();
    const [timeUntilStart, setTimeUntilStart] = useState<number>();

    useEffect(() => {
        const socket = io(`${api.FREUD_BASE_URL}/broadcastMetadata/${broadcastId}`);
        socket.on('tick', (tick: SessionTick) => {
            unstable_batchedUpdates(() => {
                if (isNumber(tick.absoluteTime)) {
                    setTimeElapsed(Math.floor(millisecondsToMinutes(tick.absoluteTime)));
                }
                if (isNumber(tick.sessionDuration)) {
                    setTotalDuration(Math.floor(millisecondsToMinutes(tick.sessionDuration)));
                }
                if (isNumber(tick.timeUntilStart)) {
                    setTimeUntilStart(Math.floor(millisecondsToMinutes(tick.timeUntilStart)));
                }
            });
        });
        const connector = setInterval(() => {
            if (!socket.connected) {
                socket.open();
            } else {
                clearInterval(connector);
            }
        }, 1000);
        return () => {
            socket.disconnect();
            clearInterval(connector);
        };
    }, [broadcastId]);

    return { timeElapsed, totalDuration, timeUntilStart };
};
