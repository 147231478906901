import { useCallback } from 'react';
import useSWR from 'swr';

import useMySubscription from '@/hooks/useMySubscription';

import { useAuthContext } from '../../auth';
import { getFeaturesForUser } from './featuresApi';

const key = '/features/';

const options = {
    dedupingInterval: 24 * 60 * 60 * 1000,
};

export function useFeatures(): {
    isEnabled: (feature: string) => boolean;
    isLoading: boolean;
} {
    const { userData: user, isLoaded: userLoaded } = useAuthContext();
    const { data, error } = useSWR(
        userLoaded ? key + user?.uid : null, // only fetch features after user loaded
        async () => await getFeaturesForUser({ userId: user?.uid }),
        options,
    );

    const { subscription } = useMySubscription();
    const productFeatures = subscription && subscription.status !== 'inactive' ? subscription.features : [];
    const featuresLoading = !error && !data;

    const availableFeatures = [...(data ?? []), ...productFeatures];

    const isEnabled = useCallback((feature: string) => availableFeatures.includes(feature), [availableFeatures]);

    return {
        isEnabled,
        isLoading: !userLoaded || featuresLoading,
    };
}
