import firebase from 'firebase';
import qs from 'qs';
import {
    AdministrationRoute,
    CoreEmotionalAtmosphere,
    DosageLevel,
    PathScoreReference,
    ScoreVariables,
    SessionScoreEmotionalIntensity,
    SessionScoreModality,
    SessionTimingPlan,
    SessionType,
    VoiceoverStage,
} from 'wavepaths-shared/core';

import axios from '../util/axios';
import { FREUD_BASE_URL } from './sessionApiV2';

const SCORE_TEMPLATES_BASE_URL = `${FREUD_BASE_URL}/my/score-templates`;

const HOME_CONTENT_BASE_URL = `${FREUD_BASE_URL}/my/home`;

const ALL_SCORE_TEMPLATES_URL = `${SCORE_TEMPLATES_BASE_URL}/all`;

export type ScoreTemplate = {
    id: string;
    name: string;
    subtitle: string;
    description: string;
    intensity: SessionScoreEmotionalIntensity;
    modality: SessionScoreModality;
    administration?: AdministrationRoute;
    dosage?: DosageLevel;
    wavepaths: PathScoreReference[];
    sessionTypes: SessionType[];
    timingPlan?: SessionTimingPlan[];
    variables: ScoreVariables; // CY: trying to strangle these out, but Timeline currently needs them
    defaultDurationMins?: number;
    voiceover?: VoiceoverStage[];
    durationMins: {
        min: number;
        max: number;
    };
    emotionalities: {
        primary: CoreEmotionalAtmosphere;
        secondary: CoreEmotionalAtmosphere;
        tertiary: CoreEmotionalAtmosphere;
    };
};

export type ListScoreTemplatesItem = Pick<
    ScoreTemplate,
    'id' | 'name' | 'subtitle' | 'intensity' | 'durationMins' | 'emotionalities' | 'modality'
>;

export interface IListScoreTemplatesReturn {
    entries: ListScoreTemplatesItem[];
    offset: number;
    limit: number;
    totalCount: number;
}

export type FilterCriteria = {
    intensities?: SessionScoreEmotionalIntensity[] | null;
    emotionality?: CoreEmotionalAtmosphere[] | null;
    modalities?: SessionScoreModality[] | null;
    administrations?: AdministrationRoute[] | null;
    dosages?: DosageLevel[] | null;
    durationMins?: number | null;
};

export const getScoreTemplates = async ({
    fbUser,
    criteria,
    pagination,
}: {
    fbUser: firebase.User;
    criteria?: FilterCriteria;
    pagination?: {
        offset?: number;
        limit?: number;
    };
}): Promise<IListScoreTemplatesReturn> => {
    const fbIdToken = await fbUser.getIdToken();
    const response = await axios.get(ALL_SCORE_TEMPLATES_URL, {
        method: 'GET',
        headers: { authorization: `idToken ${fbIdToken}` },
        params: {
            durationMins: criteria?.durationMins,
            modality: criteria?.modalities,
            intensity: criteria?.intensities,
            dosages: criteria?.dosages,
            administrations: criteria?.administrations,
            limit: pagination?.limit,
            offset: pagination?.offset,
        },
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    });

    if (response.status === 200) return response.data;
    else throw new Error('Failed to get session templates');
};

export type IGetScoreTemplateReturn = ScoreTemplate;

export const getTemplateById = async ({
    fbUser,
    id,
}: {
    fbUser: firebase.User;
    id: string;
}): Promise<IGetScoreTemplateReturn> => {
    const fbIdToken = await fbUser.getIdToken();
    const response = await axios.get(SCORE_TEMPLATES_BASE_URL + `/${id}`, {
        method: 'GET',
        headers: { authorization: `idToken ${fbIdToken}` },
    });

    if (response.status === 200) return response.data;
    else throw new Error('Failed to get session templates');
};

export type Section = {
    title: string;
    resourceType: 'templates';
    resourceUrl: string;
};

export type IGetHomeSectionsReturn = { sections: Section[] };

export const getHomeSections = async ({ fbUser }: { fbUser: firebase.User }): Promise<IGetHomeSectionsReturn> => {
    const fbIdToken = await fbUser.getIdToken();
    const response = await axios.get(HOME_CONTENT_BASE_URL, {
        method: 'GET',
        headers: { authorization: `idToken ${fbIdToken}` },
    });

    if (response.status === 200) return response.data;
    else throw new Error('Failed to get home page');
};
