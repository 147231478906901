import './NotificationStack.scss';

import { Box } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import { useEvent, useMeasure } from 'react-use';
import { v4 as uuid } from 'uuid';
import { MidSessionFeedbackSubmitted, MidSessionFeedbackType, Timestamped } from 'wavepaths-shared/core';

import { FeedbackDialog } from './FeedbackDialog';
import { NotificationToast } from './NotificationToast';

type ToastOrDialog =
    | { type: 'toast'; key: string; event: string; content: string }
    | { type: 'dialog'; key: string; feedbackType: MidSessionFeedbackType };

export type TimestampedFeedbackEventType = {
    type: MidSessionFeedbackType;
    timestamp: number;
};

interface NotificationStackProps {
    feedback?: TimestampedFeedbackEventType;
    onSubmitFeedback: (fb: FeedbackEvent) => void;
    onCancel: () => void;
}

export type FeedbackEvent = Timestamped<MidSessionFeedbackSubmitted>;

const TOAST_VISIBILITY_TIME = 3000;

export const NotificationStack: React.FC<NotificationStackProps> = React.memo(
    ({ feedback, onCancel, onSubmitFeedback }) => {
        const [text, setFeedbackText] = useState<string>('');
        const [selectedTags, setFeedbackTags] = useState<string[]>([]);

        const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

        const stackItems: ToastOrDialog[] = feedbackSubmitted
            ? [{ type: 'toast', key: `confirm`, event: 'feedbackConcluded', content: 'Feedback complete, thank you' }]
            : feedback
            ? [{ type: 'dialog', key: `dialog=${feedback.type}-${feedback.timestamp}`, feedbackType: feedback.type }]
            : [];

        const [stackItemHeights, setStackItemHeights] = useState({} as { [key: string]: number });
        const onSetStackItemHeight = useCallback((key: string, height: number) => {
            setStackItemHeights((hs) => ({ ...hs, [key]: height }));
        }, []);

        const containerRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            if (feedbackSubmitted) {
                setTimeout(() => {
                    setFeedbackSubmitted(false);
                }, TOAST_VISIBILITY_TIME);
            }
        }, [feedbackSubmitted]);

        useEffect(() => {
            setFeedbackText('');
            setFeedbackTags([]);
        }, [feedback?.type]);

        // const activeEvent = state.find((item) => item.type === 'feedback') as ActiveFeedbackState | undefined;

        const handleClickOutside = (event: Event) => {
            if (!!feedback && containerRef.current && !containerRef.current.contains(event.target as Node)) {
                onCancel();
            }
        };

        const main = document.querySelector('main');

        useEvent('click', handleClickOutside, main);

        const itemsWithHeights = stackItems.map((item) => ({ item, height: stackItemHeights[item.key] ?? 0 }));

        const transition = useTransition(itemsWithHeights, {
            keys: (t: any) => t.item.key,
            from: { opacity: 0, height: 0 },
            enter: () => async (next) => {
                await new Promise((resolve) => setTimeout(resolve, 150));
                await next({ opacity: 1 });
            },
            update: (item) => ({ height: item.height }),
            leave: { opacity: 0 },
            trail: 200,
        });

        const onToggleFeedbackTag = (tag: string, toggleOn: boolean) => {
            if (toggleOn) {
                setFeedbackTags([...selectedTags, tag]);
            } else {
                setFeedbackTags(selectedTags.filter((t) => t !== tag));
            }
        };

        const onConcludeFeedback = () => {
            console.log('conclude', { feedback, text, tags: selectedTags });
            setFeedbackSubmitted(true);
            if (feedback) {
                onSubmitFeedback({
                    type: 'feedbackSubmitted',
                    feedbackType: feedback.type,
                    id: uuid(),
                    timestamp: feedback.timestamp,
                    text: text?.trim(),
                    tags: selectedTags,
                });
            }
        };

        return (
            <div ref={containerRef}>
                <Box position="absolute" zIndex="5" bottom="15px" width="250px" right="24px">
                    {transition((style, item) => (
                        <animated.div className="notificationStackItem" style={style as any}>
                            <NotificationStackItem
                                item={item.item}
                                feedbackText={text}
                                selectedTags={selectedTags}
                                onCancel={onCancel}
                                onSetHeight={onSetStackItemHeight}
                                onToggleFeedbackTag={onToggleFeedbackTag}
                                onSetFeedbackText={setFeedbackText}
                                onSubmitFeedback={onConcludeFeedback}
                            />
                        </animated.div>
                    ))}
                </Box>
            </div>
        );
    },
);

interface NotificationStackItemProps {
    item: ToastOrDialog;
    feedbackText: string;
    selectedTags: string[];
    onSetHeight: (key: string, height: number) => void;
    onToggleFeedbackTag: (tag: string, selected: boolean) => void;
    onSetFeedbackText: (text: string) => void;
    onSubmitFeedback: () => void;
    onCancel: () => void;
}
const NotificationStackItem: React.FC<NotificationStackItemProps> = ({
    item,
    selectedTags,
    feedbackText,
    onSetHeight,
    onSetFeedbackText,
    onToggleFeedbackTag,
    onSubmitFeedback,
    onCancel,
}) => {
    const [measureRef, { height }] = useMeasure<HTMLDivElement>();
    useEffect(() => {
        onSetHeight(item.key, height);
    }, [item.key, height, onSetHeight]);
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            className="notificationStackHeightCalc"
            ref={measureRef}
        >
            {item.type === 'toast' ? (
                <NotificationToast className={item.event} content={item.content} />
            ) : (
                <FeedbackDialog
                    headerContent={getDialogHeaderContent(item.feedbackType)}
                    feedbackTagOptions={getFeedbackTagOptions(item.feedbackType)}
                    selectedFeedbackTags={selectedTags}
                    onToggleTag={onToggleFeedbackTag}
                    text={feedbackText}
                    onSetFeedbackText={onSetFeedbackText}
                    onSubmit={onSubmitFeedback}
                    onCancel={onCancel}
                />
            )}
        </div>
    );
};

function getDialogHeaderContent(feedbackType: MidSessionFeedbackType): string {
    switch (feedbackType) {
        case 'helpfulMusic':
            return 'Why was the music helpful?';
        case 'unhelpfulMusic':
            return 'Why was the music unhelpful?';
        case 'note':
            return 'Leave a note for later';
        case 'issue':
            return 'What is the issue?';
        default:
            return '';
    }
}

function getFeedbackTagOptions(feedbackType: MidSessionFeedbackType): string[] {
    switch (feedbackType) {
        case 'helpfulMusic':
            return ['Music enjoyable', 'Music matches emotion well', 'Music supportive', 'Music quality high', 'Other'];
        case 'unhelpfulMusic':
            return ['Dislike music', 'Music irritating', 'Music unfriendly', 'Music quality low', 'Other'];
        case 'note':
            return ['Only for me', 'For myself and my client'];
        case 'issue':
            return [
                'Sound cut out unexpectedly',
                'Screen crashed/frozen',
                'Unable to perform action',
                'Something doesn’t look right',
                'Other',
            ];
        default:
            return ['Placeholder 1', 'Placeholder 2'];
    }
}
