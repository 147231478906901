import firebase from 'firebase';

import axios from '../util/axios';
import { FREUD_BASE_URL } from './sessionApiV2';

const MY_SESSION_USAGE_URL = `${FREUD_BASE_URL}/my/session-usage`;

export type IGetSessionUsageResponse = {
    sessionHours: number;
};

export const getSessionUsage = async ({
    fbUser,
    fromTimestamp,
    toTimestamp,
}: {
    fbUser: firebase.User;
    fromTimestamp: number;
    toTimestamp: number;
}): Promise<IGetSessionUsageResponse> => {
    const fbIdToken = await fbUser.getIdToken();
    const response = await axios(MY_SESSION_USAGE_URL, {
        method: 'GET',
        params: {
            fromTimestamp,
            toTimestamp,
        },
        headers: { authorization: `idToken ${fbIdToken}` },
    });

    if (response.status === 200) return response.data;
    else throw new Error('Failed to get session usage');
};
