import { IconTypeMap, makeStyles } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialSelect from '@material-ui/core/Select';
import React from 'react';

import Typography, { CustomTypographyProps } from '../typography/Typography';
import EvaIcon from './EvaIcon';
interface IOption<ValueType> {
    label: string;
    value: ValueType;
}

export interface ISelectProps<ValueType> {
    width?: string | number;
    height?: string | number;
    textVariant?: CustomTypographyProps['variant'];
    options: IOption<ValueType>[];
    selected?: ValueType;
    setSelected: (value: ValueType) => void;
}

interface IStyleProps {
    width: string | number;
    height: string | number;
}

const useStyles = makeStyles({
    root: { paddingLeft: 16 },
    select: {
        '&:focus': {
            background: 'none',
        },
    },
    input: ({ height, width }: IStyleProps) => ({
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        borderRadius: 14,
        width,
        height,
        position: 'relative',
        border: 'none',
    }),
    label: ({ width }) => ({
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: width === 'auto' ? 300 : typeof width === 'number' ? 2 * width : undefined,
    }),
});

const ArrowDownWrapper = (props: IconTypeMap['props']) => (
    <EvaIcon size={24} evaSize={16} name="arrow-down" iconStyle={{ marginRight: 8 }} {...props} />
);

const Select = <ValueType extends string | number | readonly string[]>({
    options,
    selected,
    setSelected,
    width = 210,
    height = 30,
    textVariant = 'body2',
}: ISelectProps<ValueType>): JSX.Element => {
    const classes = useStyles({ width, height });
    return (
        <MaterialSelect
            classes={{ root: classes.root, select: classes.select }}
            value={selected}
            onChange={(event) => setSelected(event.target.value as ValueType)}
            input={<InputBase className={classes.input} />}
            IconComponent={ArrowDownWrapper}
        >
            {options.map((option: IOption<ValueType>, index: number) => (
                <MenuItem key={`${index}-${option.label}`} value={option.value}>
                    <Typography className={classes.label} noWrap variant={textVariant}>
                        {option.label}
                    </Typography>
                </MenuItem>
            ))}
        </MaterialSelect>
    );
};

export default Select;
