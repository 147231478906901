import styled from '@emotion/styled';
import * as Sentry from '@sentry/browser';
import { compact, isNil, startCase, toString } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    ClientVariablesMusicPreference,
    PathType,
    ScoreLibrary,
    Session,
    SessionRenderType,
    SessionScoreAdministration,
    SessionScoreDosage,
    SessionScoreSessionUse,
    SessionType,
} from 'wavepaths-shared/core';
import * as Scores from 'wavepaths-shared/domain/scores';
import { isAdmin } from 'wavepaths-shared/domain/user';

import { Button, EvaIcon, Typography } from '@/component-library';
import { useFeatures } from '@/features';
import { useQueryParams } from '@/hooks/useQueryParams';
import useSessionTemplates from '@/hooks/useSessionTemplates';

import * as audio from '../../audio';
import { useAuthContext } from '../../auth';
import * as sessionApi from '../../common/api/sessionApi';
import { ISessionTemplate } from '../../common/api/sessionTemplatesApi';
import Snackbar from '../../common/components/Snackbar';
import SubscribeModal from '../../common/components/SubscribeModal';
import { Feature } from '../../common/features/Feature';
import { Features } from '../../common/features/features.local';
import { pickFirstFormTemplateAndPostFormResponse } from '../../formUtils/formUtils';
import UserEvents from '../../UserEvents';
import MedicineInputs from './ModalityInputs';
import { MusicalInputs } from './MusicalInputs';
import { PlannerProductTour } from './PlannerProductTour';
import SessionDuration from './SessionDuration';
import { SessionInputs } from './SessionInputs';
import { SessionNameInput } from './SessionNameInput';
import { SchedulingStyle, SessionSchedulingOptions } from './SessionSchedulingOptions';
import { SessionTemplate } from './SessionTemplate';
import { SubmitSession } from './SubmitSession';
import { useOverwriteConfirmation } from './useOverwriteConfirmation';
import useScorePlanner, { Input } from './useScorePlanner';

const Container = styled.div({
    display: 'grid',
    gridAutoFlow: 'row',
    justifyItems: 'center',
    gap: '40px',
    padding: '40px 0',
    overflowY: 'auto',
});

const InputWrapper = styled.div({
    position: 'relative',
    zIndex: 3,
    width: '100%',
    maxWidth: '688px',
});

const TimelineWrapper = styled.div({
    position: 'relative',
    zIndex: 2,
});

const AdminOptions = styled.div({
    display: 'grid',
    gridAutoFlow: 'row',
    gap: '16px',
});

const CheckboxGroup = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    gridTemplateColumns: 'min-content 1fr',
    gap: '8px',
});

const Label = styled.label({
    color: '#32C3958',
    marginBottom: '0',
});

const SubmitSessionWrapper = styled.div({
    display: 'grid',
    justifyContent: 'end',
    marginTop: 4,
});

export interface OneOnOneSessionPlannerProps {
    scoreLibrary: ScoreLibrary;
}

export const SAVE_BEFORE_COLLAPSE_LABEL = 'Please Cancel or Save your changes before Collapsing this window';
export const SAVE_AS_SESSION_TEMPLATE_LABEL = 'Save to Favourites';
export const MISSING_SESSION_TEMPLATE_NAME_ERROR_LABEL = 'Please provide a name to save these settings as a template';
export const ADDING_TEMPLATE_ERROR_LABEL = 'There was an error adding session template';
export const SESSION_CREATION_FAILURE_MESSAGE = 'Sorry, we were unable to create your session, please try again.';
export const SESSION_CREATION_TRAFFIC_TOO_HIGH_MESSAGE =
    'Sorry, we are experiencing an unusually high volume of sessions right now, please try again later.';

export const PLAYLIST_WARNING_MESSAGE =
    'We will soon be removing the ability to play sessions planned from this template; we recommend using a Bridging template instead.';

export function OneOnOneSessionPlanner({ scoreLibrary }: OneOnOneSessionPlannerProps): JSX.Element {
    const params = useQueryParams();
    const isTour = params.has('isTour');

    const sessionType = SessionType.ONE_ON_ONE;
    const history = useHistory<{ sessionTemplate?: ISessionTemplate }>();

    const { userData, firebaseUser } = useAuthContext();
    const {
        addTemplate,
        error: sessionTemplateError,
        mutationPending: sessionTemplateMutationPending,
    } = useSessionTemplates({ fbUser: firebaseUser });
    if (!userData || !firebaseUser) {
        throw new Error('this should be unreachable');
    }
    const sessionTemplate = history?.location?.state?.sessionTemplate;

    const [includeSubmitted, setIncludeSubmitted] = useState(false);
    const [includeApproved, setIncludeApproved] = useState(true);
    const [enableQuickFades, setEnableQuickFades] = useState(false);

    const initialAttributeInputs = {
        schedulingStyle: 'now' as SchedulingStyle,
        unlisted: sessionTemplate && !isNil(sessionTemplate.unlisted) ? sessionTemplate.unlisted : true,
        canClientStartEarly:
            sessionTemplate && !isNil(sessionTemplate.canClientStartEarly)
                ? sessionTemplate.canClientStartEarly
                : false,
    };
    const [sessionAttributeInputs, setSessionAttributeInputs] = useState<{
        unlisted: boolean;
        schedulingStyle: SchedulingStyle;
        canClientStartEarly: boolean;
        scheduledStart?: number;
    }>(initialAttributeInputs);

    const { isEnabled } = useFeatures();

    const [renderingType, setSessionRenderingType] = useState(
        isEnabled(Features.LIVE_SESSION_RENDERING)
            ? sessionTemplate?.renderType ?? SessionRenderType.REAL_TIME
            : SessionRenderType.PRE_RENDERED,
    );

    const [startingSession, setStartingSession] = useState(false);

    const [, setSelectedWaveIndex] = useState<number | undefined>(undefined);
    const [modalIsOpen, setModalIsOpen] = useState<boolean | undefined>(undefined);

    const {
        score,
        scoreTemplate,
        sessionVariableInputs,
        updateVariableInputs,
        missingVariableInputs,
        setSessionScorePreset,
        updatePathInScore,
        addPathToScore,
        movePathInScore,
        removePathFromScore,
        setMedicine,
        medicine,
        dirtyInputs,
    } = useScorePlanner(scoreLibrary, sessionType, sessionTemplate);

    const [snackbarContent, setSnackbarContent] = useState<string | null>(null);
    const closeSnackbar = useCallback(() => setSnackbarContent(null), []);
    const openMissingInputPrompt = useCallback(
        () => setSnackbarContent(`Please select ${missingVariableInputs.map(startCase).join(' and ')} before starting`),
        [missingVariableInputs],
    );
    const { confirmOverwrite, confirmOverwriteDialog } = useOverwriteConfirmation(dirtyInputs);

    const handleSaveSessionTemplate = async () => {
        if (!isEnabled(Features.SAVE_SESSION_TEMPLATES)) {
            setModalIsOpen(true);
            return;
        }

        UserEvents.sessionTemplateCreated();
        setSnackbarContent(null);
        const sessionTemplateName = toString(sessionVariableInputs.name);
        if (!sessionTemplateName) {
            setSnackbarContent(MISSING_SESSION_TEMPLATE_NAME_ERROR_LABEL);
            return;
        }
        const template = {
            name: sessionTemplateName,
            score,
            renderType: renderingType,
            variableInputs: sessionVariableInputs,
            canClientStartEarly: sessionAttributeInputs.canClientStartEarly,
        };

        const result = await addTemplate(template);
        if (result.ok) history.push('/');
    };

    useEffect(() => {
        if (sessionTemplateError) {
            setSnackbarContent(ADDING_TEMPLATE_ERROR_LABEL);
        } else {
            setSnackbarContent(null);
        }
    }, [sessionTemplateError, setSnackbarContent]);

    const startSession = async () => {
        setStartingSession(true);
        setSnackbarContent(null);

        if (!includeSubmitted && !includeApproved) {
            setSnackbarContent(
                'Approved and Submitted cannot be both checked out. You have to choose at least one of them',
            );
            setStartingSession(false);
            return;
        }

        if (score.wavepaths.some((wp) => wp.pathScore.type === PathType.CURATED)) {
            setSnackbarContent(PLAYLIST_WARNING_MESSAGE);
        }

        try {
            const filteredLayerIds = window.location.search
                ? window.location.search
                      .substring(1)
                      .split(',')
                      .map((id) => +id)
                : [];
            const attributeInputs: Partial<Session> = {
                ...sessionAttributeInputs,
                scheduledStart: getStartTime(
                    sessionAttributeInputs.schedulingStyle,
                    sessionAttributeInputs.scheduledStart,
                ),
            };

            const contentStatusesToInclude = compact([includeApproved && 'Approved', includeSubmitted && 'Submitted']);

            const createdSession = await sessionApi.startSession(
                sessionType,
                renderingType,
                score,
                sessionVariableInputs,
                attributeInputs,
                [],
                filteredLayerIds,
                firebaseUser,
                contentStatusesToInclude,
                enableQuickFades,
            );

            try {
                await pickFirstFormTemplateAndPostFormResponse(
                    createdSession.id,
                    'postSessionIntegration',
                    firebaseUser,
                );
            } catch (e) {
                Sentry.withScope((scope) => {
                    scope.setExtra('email', userData.email);
                    scope.setExtra('id', userData.uid);
                    scope.setExtra('sessionId', createdSession.id);
                    Sentry.captureException('Integration form creation failed');
                });
            }

            const streamStart = createdSession.scheduledStart;
            audio.audioCtx.resume();

            try {
                UserEvents.sessionCreated(createdSession, {
                    scheduleType: sessionAttributeInputs.schedulingStyle,
                    isTemplateCustom: !scoreTemplate,
                });
            } catch (e) {
                console.log(e);
            }

            if (streamStart && streamStart <= Date.now() + 1000 && renderingType === SessionRenderType.REAL_TIME) {
                history.push(`/session/${createdSession.id}${isTour ? '?isTour=true' : ''}`);
            } else if (renderingType === SessionRenderType.PRE_RENDERED) {
                history.push(`/session/precomposed/${createdSession.id}`);
            } else {
                history.push(`/`);
            }
        } catch (e: any) {
            console.error(e);
            if (e instanceof sessionApi.TooMuchTrafficError) {
                setSnackbarContent(SESSION_CREATION_TRAFFIC_TOO_HIGH_MESSAGE);
                setStartingSession(false);
                return;
            }
            setSnackbarContent(SESSION_CREATION_FAILURE_MESSAGE);
        }
        setStartingSession(false);
    };

    const handleStartSession = missingVariableInputs.length === 0 ? startSession : openMissingInputPrompt;

    const snackbarType = [PLAYLIST_WARNING_MESSAGE, SAVE_BEFORE_COLLAPSE_LABEL].includes(snackbarContent ?? '')
        ? 'warning'
        : 'error';

    return (
        <Container onClick={() => setSelectedWaveIndex(undefined)}>
            {score && (
                <>
                    <InputWrapper>
                        <MedicineInputs
                            availableSessionScores={scoreLibrary.sessionScores}
                            modality={medicine}
                            administration={sessionVariableInputs.administration as SessionScoreAdministration}
                            dosage={sessionVariableInputs.dosage as SessionScoreDosage}
                            onDosageChanged={confirmOverwrite(
                                [Input.Score, Input.Duration, Input.ScoreTemplate],
                                (dosage) => updateVariableInputs({ dosage }),
                            )}
                            onModalityChanged={confirmOverwrite(
                                [Input.Score, Input.Duration, Input.ScoreTemplate, Input.Dosage, Input.Administration],
                                (medicine) => setMedicine(medicine),
                            )}
                            onAdministrationChanged={confirmOverwrite(
                                [Input.Score, Input.Duration, Input.ScoreTemplate, Input.Dosage],
                                (administration) => updateVariableInputs({ administration }),
                            )}
                        />
                        <MusicalInputs
                            currentSessionTemplate={scoreTemplate}
                            availableSessionScores={Scores.filterScoresByCriteria(
                                scoreLibrary.sessionScores,
                                sessionType,
                                { medicine, dosage: sessionVariableInputs.dosage as SessionScoreDosage },
                            )}
                            onSessionScoreChange={confirmOverwrite([Input.Score, Input.Duration], (value) =>
                                setSessionScorePreset(value),
                            )}
                            musicalPreference={sessionVariableInputs.Acousticness as ClientVariablesMusicPreference}
                            onMusicalPreferenceChanged={(Acousticness: number) =>
                                updateVariableInputs({ Acousticness })
                            }
                        />
                        <SessionDuration
                            wavepaths={score.wavepaths}
                            pathScoreLibrary={scoreLibrary.pathScores}
                            template={scoreTemplate}
                            currentSessionDuration={sessionVariableInputs.totalDuration as number}
                            onSessionDurationChanged={(totalDuration: number) =>
                                updateVariableInputs({ totalDuration })
                            }
                        />
                    </InputWrapper>
                    <TimelineWrapper className="tour-scoreOverview">
                        <SessionTemplate
                            score={score}
                            variables={sessionVariableInputs}
                            snackbarContent={snackbarContent}
                            updatePathInScore={updatePathInScore}
                            addPathToScore={addPathToScore}
                            removePathFromScore={removePathFromScore}
                            movePathInScore={movePathInScore}
                            setSnackbarContent={setSnackbarContent}
                            sessionDuration={sessionVariableInputs.totalDuration as number}
                            openSubscribeModal={() => setModalIsOpen(true)}
                        />
                    </TimelineWrapper>
                    <InputWrapper>
                        <SessionNameInput
                            sessionName={sessionVariableInputs.name as string}
                            onSessionNameChanged={(name) => updateVariableInputs({ name })}
                        />
                        <SessionSchedulingOptions
                            renderingType={renderingType}
                            onRenderTypeChange={setSessionRenderingType}
                            schedulingStyle={sessionAttributeInputs.schedulingStyle}
                            preludeDuration={+sessionVariableInputs.preludeDuration}
                            scheduledStart={sessionAttributeInputs.scheduledStart}
                            canClientStartEarly={sessionAttributeInputs.canClientStartEarly}
                            onCanClientStartEarlyChange={(canClientStartEarly: boolean) =>
                                setSessionAttributeInputs({
                                    ...sessionAttributeInputs,
                                    canClientStartEarly,
                                    scheduledStart: canClientStartEarly
                                        ? sessionAttributeInputs.scheduledStart
                                        : Date.now(),
                                })
                            }
                            onScheduledStartChange={(scheduledStart: number | undefined) =>
                                setSessionAttributeInputs({
                                    ...sessionAttributeInputs,
                                    scheduledStart,
                                })
                            }
                            onSchedulingStyleChange={(schedulingStyle: SchedulingStyle) => {
                                setSessionAttributeInputs({
                                    ...sessionAttributeInputs,
                                    schedulingStyle,
                                    scheduledStart: schedulingStyle === 'specificTime' ? Date.now() : undefined,
                                    canClientStartEarly: false,
                                });
                            }}
                        />
                        <SessionInputs
                            sessionUse={sessionVariableInputs.sessionUse as SessionScoreSessionUse | undefined}
                            onSessionUseChanged={(sessionUse) => updateVariableInputs({ sessionUse })}
                            voiceover={sessionVariableInputs.voiceover as string | undefined}
                            onVoiceoverChanged={(voiceover) => updateVariableInputs({ voiceover })}
                        />
                        <Feature
                            name={Features.GUIDED_ONE_ON_ONE}
                            activeComponent={
                                <AdminOptions>
                                    <CheckboxGroup>
                                        <input
                                            type="checkbox"
                                            name="List Session"
                                            id="useEmotionalStateFormsInput"
                                            checked={eval(
                                                (sessionVariableInputs.useEmotionalStateForms || 'false') as string,
                                            )}
                                            onChange={() =>
                                                updateVariableInputs({
                                                    useEmotionalStateForms: (!eval(
                                                        (sessionVariableInputs.useEmotionalStateForms ||
                                                            'false') as string,
                                                    )).toString(),
                                                })
                                            }
                                        />
                                        <Label htmlFor="useEmotionalStateFormsInput">
                                            <Typography variant="body2">
                                                Use pre and post session Emotional State Forms?
                                            </Typography>
                                        </Label>
                                    </CheckboxGroup>
                                    {isAdmin(userData) && (
                                        <>
                                            <CheckboxGroup>
                                                <input
                                                    type="checkbox"
                                                    name="Include submitted content"
                                                    id="includeApprovedContent"
                                                    checked={includeApproved}
                                                    onChange={(event) => setIncludeApproved(event.target.checked)}
                                                />
                                                <Label htmlFor="includeApprovedContent">
                                                    <Typography variant="body2">Include approved content?</Typography>
                                                </Label>
                                            </CheckboxGroup>
                                            <CheckboxGroup>
                                                <input
                                                    type="checkbox"
                                                    name="Include submitted content"
                                                    id="includeSubmittedContent"
                                                    checked={includeSubmitted}
                                                    onChange={(event) => setIncludeSubmitted(event.target.checked)}
                                                />
                                                <Label htmlFor="includeSubmittedContent">
                                                    <Typography variant="body2">Include submitted content?</Typography>
                                                </Label>
                                            </CheckboxGroup>
                                            <CheckboxGroup>
                                                <input
                                                    type="checkbox"
                                                    name="Enable quick fades"
                                                    id="enableQuickFades"
                                                    checked={enableQuickFades}
                                                    onChange={(event) => setEnableQuickFades(event.target.checked)}
                                                />
                                                <Label htmlFor="enableQuickFades">
                                                    <Typography variant="body2">Enable quick fades?</Typography>
                                                </Label>
                                            </CheckboxGroup>
                                        </>
                                    )}
                                </AdminOptions>
                            }
                        />
                        <div className="tour-createSessionButton" style={{ display: 'inline-block', float: 'right' }}>
                            <SubmitSessionWrapper>
                                <SubmitSession
                                    renderType={renderingType}
                                    schedulingStyle={sessionAttributeInputs.schedulingStyle}
                                    isStartingSession={startingSession}
                                    disabled={false}
                                    onSubmit={handleStartSession}
                                />
                            </SubmitSessionWrapper>
                            <SubmitSessionWrapper>
                                <Button
                                    disabled={sessionTemplateMutationPending}
                                    size="s"
                                    variant="clear"
                                    style={{ opacity: isEnabled(Features.SAVE_SESSION_TEMPLATES) ? 1 : 0.5 }}
                                    icon={
                                        <Feature
                                            name={Features.SAVE_SESSION_TEMPLATES}
                                            activeComponent={<EvaIcon name="save-outline" size={16} fill={'#000'} />}
                                            inactiveComponent={<EvaIcon name="lock-outline" size={16} fill={'#000'} />}
                                        />
                                    }
                                    onClick={handleSaveSessionTemplate}
                                >
                                    {SAVE_AS_SESSION_TEMPLATE_LABEL}
                                </Button>
                            </SubmitSessionWrapper>
                        </div>
                    </InputWrapper>
                </>
            )}
            <SubscribeModal isOpen={!!modalIsOpen} closeModal={() => setModalIsOpen(false)} />
            <Snackbar
                type={snackbarType}
                isLongButton={false}
                message={snackbarContent ?? ''}
                confirmText={'OK'}
                open={snackbarContent !== null}
                closeSnackbar={closeSnackbar}
            />
            {confirmOverwriteDialog}
            {isTour && <PlannerProductTour />}
        </Container>
    );
}

function getStartTime(schedulingStyle: SchedulingStyle, scheduledStart: number | undefined) {
    if (schedulingStyle === 'now') {
        return new Date().getTime();
    }
    if (schedulingStyle === 'later') {
        return undefined;
    }
    if (!scheduledStart) {
        throw new Error('scheduledStart is undefined but schedulingStyle is specificTime');
    }
    return new Date(scheduledStart).getTime();
}

export function renderSessionType(type: SessionType): string {
    switch (type) {
        case 'groupGuided':
            return 'Guided Jung Session';
        case 'groupInfinite':
            return 'Infinite Jung Session';
        case 'oneOnOne':
            return 'Session';
        default:
            return 'Session';
    }
}
