import * as eva from 'eva-icons';
import { ReactElement, useEffect } from 'react';
import React from 'react';
import { SessionRenderType } from 'wavepaths-shared/core';

import { Button } from '@/component-library';

import { SchedulingStyle } from './SessionSchedulingOptions';

export interface SubmitSessionProps {
    schedulingStyle: SchedulingStyle;
    renderType: SessionRenderType;
    onSubmit: () => void;
    isStartingSession: boolean;
    disabled: boolean;
    error?: string;
}
export function SubmitSession({
    schedulingStyle,
    onSubmit,
    isStartingSession,
    disabled,
}: SubmitSessionProps): ReactElement {
    useEffect(() => eva.replace(), []);
    return (
        <>
            <Button
                variant="solid-blue"
                size="m"
                onClick={onSubmit}
                disabled={isStartingSession || disabled}
                icon={<i data-eva="arrow-forward" data-eva-fill="white" data-eva-height="22" data-eva-width="22" />}
            >
                {getStartButtonText(isStartingSession, schedulingStyle)}
            </Button>
        </>
    );
}

function getStartButtonText(startingSession: boolean, schedulingStyle: SchedulingStyle) {
    if (schedulingStyle === 'now') {
        if (startingSession) {
            return 'Starting session';
        } else {
            return 'Start session now';
        }
    }

    if (schedulingStyle === 'later') {
        if (startingSession) {
            return 'Saving session';
        } else {
            return 'Save session';
        }
    }

    if (startingSession) {
        return 'Scheduling session';
    } else {
        return `Schedule session`;
    }
}
