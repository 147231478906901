import firebase from 'firebase/app';
import { capitalize } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from '@/component-library';
import { Feature, Features } from '@/features';
import useMySubscription from '@/hooks/useMySubscription';
import useSessionUsage from '@/hooks/useSessionUsage';
import { createCustomerPortal } from '@/util/stripeUtil';

import { InputGroup, ProfileSection, Text } from './components';
import { UsageInfo } from './UsageInfo';

export type ISubscriptionInfoProps = {
    fbUser: firebase.User;
};

export function SubscriptionInfo({ fbUser }: ISubscriptionInfoProps) {
    const history = useHistory();

    const { subscription } = useMySubscription();
    const { sessionHours } = useSessionUsage({ fbUser });

    const handleSubscribe = () => {
        history.push('/subscriptions');
    };

    const handleManageSubscription = async () => {
        const response = await createCustomerPortal({
            uid: fbUser.uid,
        });
        const customerPortalUrl = response ? response.url : undefined;
        if (typeof window !== 'undefined' && customerPortalUrl) window.location.href = customerPortalUrl;
    };

    return (
        <Feature
            name={Features.FREE_ACCESS}
            inactiveComponent={
                <section>
                    <Text variant="h6">Subscription</Text>
                    <ProfileSection>
                        {subscription?.status === 'inactive' ? (
                            <>
                                <p>You do not currently have an active subscription to Wavepaths Guide Tool.</p>
                                <Button variant="solid-blue" size="m" onClick={handleSubscribe}>
                                    Subscribe
                                </Button>
                            </>
                        ) : (
                            <>
                                <InputGroup>
                                    <label>Tier</label>
                                    <Text variant="body1">{capitalize(subscription?.tier ?? 'Loading')}</Text>
                                </InputGroup>
                                <InputGroup>
                                    <UsageInfo usage={sessionHours} quota={subscription?.quotas.sessionHours} />
                                </InputGroup>
                                <Button variant="solid-blue" size="m" onClick={handleManageSubscription}>
                                    Manage subscription
                                </Button>
                            </>
                        )}
                    </ProfileSection>
                </section>
            }
        />
    );
}
