import { decomposeColor, makeStyles } from '@material-ui/core';
import BezierEasing from 'bezier-easing';
import { Node, Shaders } from 'gl-react';
import { Surface } from 'gl-react-dom';
import React, { useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { animated, SpringValue, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { CoreEmotionalAtmosphere } from 'wavepaths-shared/core';

import { getCEAColour } from '../../../common/util/ceaColours';
import shaderSource from './depthStateVisualiser.fragment.glsl';
import { MAX_UI_DEPTH, MIN_UI_DEPTH } from './depthUtils';

const useStyles = makeStyles({
    container: {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        pointerEvents: 'none',
    },
});

const shaders = Shaders.create({
    depth: { frag: shaderSource },
});
const depthEasingFn = BezierEasing(0, 0, 0.5, 1);

interface DepthStateVisualiserProps {
    currentDepth?: number;
    currentCea?: CoreEmotionalAtmosphere;
    transitionTimeSecs?: number;
}
export const DepthStateVisualiser: React.FC<DepthStateVisualiserProps> = ({
    currentDepth = 0,
    currentCea,
    transitionTimeSecs = 30,
}) => {
    const styles = useStyles();

    const currentCeaColour = decomposeColor(currentCea ? getCEAColour(currentCea) : '#D3D3D3').values as [
        number,
        number,
        number,
    ];

    const [containerRef, { width, height }] = useMeasure<HTMLDivElement>();
    const dpr = window.devicePixelRatio;
    const [depthSpring, updateDepthSpring] = useSpring(() => ({
        depth: currentDepth,
        red: currentCeaColour[0],
        green: currentCeaColour[1],
        blue: currentCeaColour[2],
    }));

    const depthTransitionTime = transitionTimeSecs;

    useEffect(() => {
        updateDepthSpring({
            depth: currentDepth,
            red: currentCeaColour[0],
            green: currentCeaColour[1],
            blue: currentCeaColour[2],
            config: { duration: depthTransitionTime * 1000, easing: depthEasingFn },
        });
    }, [currentDepth, currentCeaColour, depthTransitionTime]);

    const wavinessTime = useMemo(() => new SpringValue(0), []);
    const lastTime = useRef(-1);
    const arrowOfTimeSpring = useSpring({
        from: { time: 0 },
        to: { time: Number.MAX_SAFE_INTEGER / 1000 },
        config: { duration: Number.MAX_SAFE_INTEGER },
        onChange: (_, spring) => {
            const timeDelta = spring.get().time - lastTime.current;
            wavinessTime.set(wavinessTime.get() + (timeDelta * depthSpring.depth?.get() ?? 0) / MAX_UI_DEPTH);
            lastTime.current = spring.get().time;
        },
    });

    const backgroundContainer = document.getElementById('backgroundcontainer');
    if (!backgroundContainer) {
        throw new Error('Document does not have a #backgroundcontainer element for depth state visualiser');
    }

    return createPortal(
        <div ref={containerRef} className={styles.container}>
            <DepthStateVisualiserShader
                gradientTime={arrowOfTimeSpring.time}
                wavinessTime={wavinessTime}
                width={width}
                height={height}
                dpr={dpr}
                apex={depthSpring.depth?.to((d) => getDepthApex(d, height * dpr)) ?? 0}
                red={depthSpring.red}
                green={depthSpring.green}
                blue={depthSpring.blue}
                amplitude={depthSpring.depth?.to((d) => d / MAX_UI_DEPTH) ?? 0}
            />
        </div>,
        backgroundContainer,
    );
};

interface DepthStateVisualiserShaderProps {
    gradientTime: number;
    wavinessTime: number;
    width: number;
    height: number;
    dpr: number;
    apex: number;
    red: number;
    green: number;
    blue: number;
    amplitude: number;
}
const _DepthStateVisualiserShader: React.FC<DepthStateVisualiserShaderProps> = ({
    gradientTime,
    wavinessTime,
    width,
    height,
    dpr,
    apex,
    red,
    green,
    blue,
    amplitude,
}) => (
    <Surface width={width} height={height} webglContextAttributes={{ antialias: true }}>
        <Node
            shader={shaders.depth}
            uniforms={{
                gradientTime,
                wavinessTime,
                resolution: [width * dpr, height * dpr],
                apex,
                colour: [red / 255, green / 255, blue / 255],
                foregroundAlphaFrom: 0.7,
                foregroundAlphaTo: 0.8,
                backgroundAlphaFrom: 0.5,
                backgroundAlphaTo: 0.3,
                amplitude,
            }}
        />
    </Surface>
);
const DepthStateVisualiserShader = animated(_DepthStateVisualiserShader);

function getDepthApex(depth: number, heightPx: number) {
    const minVisibleUIDepth = MIN_UI_DEPTH + 1;
    const topAndBottomMarginPx = 125 * window.devicePixelRatio;
    if (depth < minVisibleUIDepth) {
        return topAndBottomMarginPx * (depth / minVisibleUIDepth);
    } else {
        const depthRel = (depth - minVisibleUIDepth) / (MAX_UI_DEPTH - minVisibleUIDepth);
        const heightWithoutMarginsPx = heightPx - topAndBottomMarginPx * 2;
        return topAndBottomMarginPx + depthRel * heightWithoutMarginsPx;
    }
}
